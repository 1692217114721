/* eslint-disable no-unused-vars */
export const saveCache = ({ pageId, edited, data }) => ({
  type: 'SAVE_CACHE',
  payload: {
    pageId,
    edited,
    data,
  },
});

export const deleteCache = ({ pageId }) => ({
  type: 'DELETE_CACHE',
  payload: {
    pageId,
  },
});

export const openSide = ({ pageId, isSideOpen }) => ({
  type: 'SPLIT_OPEN_SIDE',
  payload: {
    pageId,
    isSideOpen,
  },
});

export const selectItem = ({ pageId, selectedItem }) => ({
  type: 'SPLIT_SELECTED_ITEM',
  payload: {
    pageId,
    selectedItem,
  },
});

export const refreshPage = ({ pageId }) => ({
  type: 'SPLIT_REFRESH_PAGE',
  payload: {
    pageId,
    lastDateUpdated: new Date(),
  },
});

export const openExternalData = ({ pageId, externalData, settings }) => ({
  type: 'SPLIT_OPEN_EXTERNAL_DATA',
  payload: {
    pageId,
    externalData,
    settings,
  },
});

export const clearSettings = ({ pageId }) => ({
  type: 'CLEAR_SETTINGS',
  payload: {
    pageId,
  },
});
