const createMenu = ({
  isUserPage,
  isShowSave,
  isShowDelete,
  auxContextMenus = [],
}) => {
  const menus = [
    ...auxContextMenus,
    {
      icon: 'HiOutlineFolderAdd',
      title: 'EditQuery',
      prop: 'queryGet',
      type: 'queryEditor',
      visible: !!isUserPage,
      disabled: false,
    },
    /* {
      icon: 'GrDocumentPdf',
      title: 'PrintLayouts',
      type: 'list',
      prop: 'printLayouts',
      disabled: false,
      propertiesList: [
        {
          title: 'reportName',
          subTitle: 'description',
          auxInfo: 'reportCode',
        },
      ],
    }, */
    {
      icon: 'HiOutlineFolderAdd',
      title: 'AddComponent',
      prop: 'components',
      type: 'addComponent',
      visible: true,
      disabled: false,
    },
    {
      icon: 'MdSmartButton',
      title: 'AddFooterElement',
      type: 'array',
      prop: 'footerElements',
      disabled: false,
    },
    {
      icon: 'VscNewFile',
      title: 'DefaultData',
      type: 'code',
      prop: 'defaultData',
      disabled: false,
    },
    {
      type: 'separator',
    },
    {
      title: 'onLoadData',
      prop: 'onLoadData',
      type: 'event',
      visible: true,
      disabled: false,
    },
    {
      title: 'onSave',
      prop: 'onSave',
      type: 'event',
      visible: isShowSave,
      disabled: false,
    },
    {
      title: 'onDelete',
      prop: 'onDelete',
      type: 'event',
      visible: isShowDelete,
      disabled: false,
    },
  ];

  return menus;
};

export default createMenu;
