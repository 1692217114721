import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f0f0f0;
  overflow: auto;

  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    & tr {
      border: 1px solid #ddd;
      padding: 0 0.35em;
    }

    & tbody {
      overflow: auto;
    }
  }
`;

export const BatchSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  text-align: left;
  width: 100%;
  padding: 0 8px 0 15px;

  > span {
    color: #555;
    margin: 0;
    font-size: 0.9rem;
  }

  > input {
    text-align: left;
    width: 100%;
    height: 40px;

    border: 0;

    flex: 1;
  }
`;

export const BatchLine = styled.tr`
  display: ${props => props.display};
  background-color: ${props => props.lineColor};
  transition: 0.2s;

  input {
    background-color: ${props => lighten(0.03, props.lineColor())};
  }
`;

export const STLNumber = styled.div`
  color: ${props => props.fontColor};
`;
