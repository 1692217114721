import styled, { css } from 'styled-components';
import { colors, sizes, Input as InputBase } from '../../../Form/styles';

export const Container = styled.div`
  min-width: ${props => (props.minWidth ? props.minWidth : '160px')};
  border: 1px solid #e0e0e0;

  ${props =>
    props.isResponsible &&
    css`
      @media (max-width: 500px) {
        min-width: unset;
        width: 100%;
        border: none;
      }
    `}
`;

export const PanelInput = styled.div`
  flex: 1;
  border-radius: ${sizes.borderRadius};

  > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    background-color: ${props => (props.readOnly ? '#fafafa' : '#fff')};
    border-radius: ${sizes.borderRadius};
    
    border: ${props =>
      props.hideBorder ? 'none' : `1px solid ${colors.fieldBorder}`} !important;

    svg {
      margin: 5px;
    }

    input {
      background-color: ${props => (props.readOnly ? '#fafafa' : '#fff')};
      border: none !important;
    }

    &:focus-within {
      border: 1px solid ${colors.fieldFocus} !important;

      input {
        border: none !important;
      }
    }

    /* ${props =>
      props.readOnly &&
      !props.hideBorder &&
      css`
        border: 1px solid ${colors.fieldBorder} !important;
      `} */
  }
`;

export const SearchIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
  margin-left: 5px;
  cursor: pointer;
`;

export const LoadIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
  margin-left: 5px;

  @keyframes gira {
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    animation: gira 1s linear infinite;
  }
`;

export const Input = styled(InputBase)`
  border: none !important;
  &:focus {
    border: none !important;
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #ff9191 !important;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #ff9191 !important;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #ff9191 !important;
    opacity: 1;
  }
  &::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ff9191 !important;
  }
`;

export const FieldContainer = styled.div``;
