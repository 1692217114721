const createMenu = ({ auxContextMenus = [] }) => {
  const menus = [
    ...auxContextMenus,
    {
      icon: 'FaEdit',
      title: 'EditModel',
      type: 'action',
      prop: 'template',
      disabled: true,
      value: false,
    },
    {
      type: 'separator',
    },
    {
      title: 'onAddElement',
      prop: 'onAddElement',
      type: 'event',
      visible: true,
      disabled: true,
    },
  ];

  return menus;
};

export default createMenu;
