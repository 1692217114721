/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-new-func */
/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { isMobile } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import Icon from '~/easy-components/Icon';
import useFieldBase from '~/hooks/useField';
import CustomEvent from '~/easy-components/Form/components/CustomEvent';
import useUiDesigner from '~/hooks/useUiDesigner';
import Modal from '../../Modal';
import LinkIcon from '../../LinkIcon';

import {
  FieldContainer,
  Title,
  Input,
  UpdateIcon,
  PanelInput,
  TextArea,
} from '../styles';
import UserField from '../UserField';
import { sendEvent } from '../../HandlerEvent';
import FullScreenViewer from './FullScreenViewer';

const getInputDefaultValue = ({ unformDefaultValue, fieldDefaultValue }) => {
  if (unformDefaultValue !== null && unformDefaultValue !== undefined)
    return unformDefaultValue;

  if (typeof fieldDefaultValue === 'object') return '';

  return fieldDefaultValue;
};

/**
 * @typedef {Object} Props
 * @prop {string} [label]
 * @prop {import('react').MutableRefObject<import('@unform/core').FormHandles | undefined>} [formRef]
 * @prop {import('react').MutableRefObject<import('@unform/core').FormHandles | undefined>} [mainFormRef]
 * @prop {object} [settings]
 * @prop {string | number} [labelWidth]
 * @prop {bool} [readOnly]
 * @prop {(params: any) => void} [onChange]
 * @prop {any} [fieldDefaultValue]
 * @prop {boolean} [fullScreen]
 * @prop {object} [auxScope]
 * @prop {boolean} [disableUnform=false] Possibilita desabilitar o unform
 */

/**
 * @param {Props & import('react').InputHTMLAttributes} props
 */
function InputElement({
  baseName,
  name,
  label,
  hidden,
  formRef,
  mainFormRef,
  settings,
  labelWidth,
  readOnly: readOnlyProp,
  onChange,
  showLink,
  onLinkClick,
  fieldDefaultValue,
  fullScreen,
  auxScope,
  hasEmptyValue = false,
  labelPosition = 'left',
  disableUnform = false,
  required = false,
  isUserField = false,
  customTabName = null,
  ...rest
}) {
  const tooltipRef = useRef();
  const iconRef = useRef();
  const changed = useRef(false);
  const inputRef = useRef(null);

  const [isShowFullScreen, setIsShowFullScreen] = useState(false);
  const [isShowLink, setIsShowLink] = useState(showLink);

  const pageId = settings ? settings._route : '';

  const {
    viewMode,
    selfField,
    showContextMenu,
    HasEventComponent,
  } = useUiDesigner({
    pageId,
    componentType: 'field',
    baseName,
    name,
    title: label,
    isUserField,
    customTabName,
  });

  const userField =
    (settings && settings.userFields
      ? settings.userFields.find(f => {
          return f.name === name;
        })
      : {}) || {};

  const isFullScreen = !!(
    fullScreen ||
    !!userField.fullScreen ||
    !!selfField.fullScreen
  );

  const fieldTitle = selfField.title || label;

  const {
    fieldName,
    defaultValue: unformDefaultValue,
    registerField,
    error,
  } = useField(name);

  const { isHidden, isRequired, isReadOnly } = useFieldBase({
    hidden,
    readOnly: readOnlyProp,
    required,
    selfField,
    formRef,
    inputRef,
    name: fieldName,
    getLogData: () => {
      return {
        baseName,
        name: fieldName,
        value: inputRef.current.value,
      };
    },
  });

  const defaultValue = getInputDefaultValue({
    unformDefaultValue,
    fieldDefaultValue,
  });

  useEffect(() => {
    if (disableUnform) return;

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (el, v) => {
        const textValue = v === null || v === undefined ? '' : v;

        /* if (v === undefined)
          textValue = fieldDefaultValue || defaultValue || ''; */

        el.value = textValue;
      },
      getValue: el => {
        let returnValue = null;
        // Alguns campos realmente possuem um valor vazio ao invés de null,
        // Caso do ControlAccount, no banco esse valor é vazio e não nulo
        if (hasEmptyValue) {
          returnValue = el.value;
        } else {
          returnValue = el.value === '' ? null : el.value;
        }

        return returnValue;
      },
    });
  }, [isFullScreen]);

  const handlerOnChange = async params => {
    const response = await sendEvent({
      settings,
      eventName: 'onchange',
      params,
      run: params.run,
      element: params.element,
      formRef,
      mainFormRef,
      events: selfField.events,
      auxScope,
    });

    return response || params.value;
  };

  const onBlur = async params => {
    params.persist();

    if (changed.current || params.target.changed) {
      changed.current = false;

      let val = params.target.value;

      const beforeValue = await handlerOnChange({
        ...params,
        run: 'before',
        element: params,
      });

      if (beforeValue !== undefined) val = beforeValue;

      params.target.value = val;

      await onChange(params);

      val = params.target.value;

      const afterValue = await handlerOnChange({
        ...params,
        run: 'after',
        element: params,
      });
      if (afterValue !== undefined) val = afterValue;

      inputRef.current.value = val;
    }
  };

  const onChanged = async e => {
    await onBlur(e);
  };

  const onFocus = () => {
    const text = inputRef.current.value;
    inputRef.current.setSelectionRange(0, text.length);
  };

  useEffect(() => {
    setIsShowLink(showLink);
  }, [showLink]);

  useEffect(() => {
    inputRef.current.showLink = status => {
      setIsShowLink(status);
    };
    inputRef.current.addEventListener('changed', onChanged);
    inputRef.current.addEventListener('focus', onFocus);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (inputRef.current && inputRef.current.removeEventListener) {
        inputRef.current.removeEventListener('changed', onChanged);
        inputRef.current.removeEventListener('focus', onFocus);
      }
    };
  }, [selfField]);

  const nativeOnChange = e => {
    changed.current = true;

    if (isFullScreen) {
      tooltipRef.current.innerHTML = e.target.value;
    }
  };

  const tooltipText = useMemo(() => {
    if (!isFullScreen) return '';

    let def = '';

    if (typeof defaultValue === 'string') {
      def = defaultValue;
    }
    return (
      (inputRef && inputRef.current && inputRef.current.value
        ? inputRef.current.value
        : def) || ''
    );
  }, [inputRef.current, defaultValue, isShowFullScreen]);

  const handlerOnLinkClick = async ({ value }) => {
    await sendEvent({
      settings,
      eventName: 'onlinkclick',
      data: value,
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
      auxScope,
    });
  };

  async function onLink() {
    if (onLinkClick)
      onLinkClick({
        value: inputRef.current.value,
        selfField,
      });
    if (isUserField || customTabName) {
      await handlerOnLinkClick({ value: inputRef.current.value });
    }
  }

  let iconName = 'AiFillEdit';

  if (isReadOnly) iconName = 'AiOutlineExpandAlt';

  return (
    <>
      <FieldContainer
        hidden={isHidden}
        viewMode={!hidden || isUserField ? viewMode : 'normal'}
        onContextMenu={event => {
          showContextMenu({ event });
        }}
        labelPosition={labelPosition}
        readOnly={isReadOnly}
        style={
          isMobile
            ? {
                ...(selfField && selfField.style ? selfField.style : {}),
                ...(rest && rest.style ? rest.style : {}),
              }
            : {}
        }
      >
        {fieldTitle && (
          <Title
            highlight={isUserField && viewMode === 'design'}
            labelPosition={labelPosition}
            labelWidth={
              (selfField && selfField.labelWidth) || labelWidth || 130
            }
          >
            <div className="title">
              <div className="required-field">
                {fieldTitle}
                {isRequired && <b>*</b>}
              </div>
              <div className="title-icon">
                <CustomEvent
                  settings={settings}
                  selfField={selfField}
                  inputRef={inputRef}
                  formRef={formRef}
                  mainFormRef={mainFormRef}
                  auxScope={auxScope}
                />
              </div>
              {isShowLink && (
                <div>
                  <span>
                    <LinkIcon onClick={onLink} />
                  </span>
                </div>
              )}
              <HasEventComponent selfField={selfField} viewMode={viewMode} />
            </div>
            {error && <div className="error">{error}</div>}
          </Title>
        )}
        <PanelInput>
          <div className={isReadOnly ? 'disabled' : ''}>
            {isFullScreen ? (
              <TextArea
                ref={inputRef}
                rows="1"
                defaultValue={defaultValue}
                {...selfField}
                {...rest}
                hidden={false}
                onBlur={onBlur}
                onChange={nativeOnChange}
                readOnly={isReadOnly}
              />
            ) : (
              <Input
                ref={inputRef}
                defaultValue={defaultValue}
                {...selfField}
                {...rest}
                hidden={false}
                onBlur={onBlur}
                onChange={nativeOnChange}
                readOnly={isReadOnly}
                autoComplete="off"
              />
            )}
            {isFullScreen && (
              <>
                <UpdateIcon
                  ref={iconRef}
                  data-tip
                  data-for={`tooltip-text-${name}`}
                  className="hoverable"
                  readOnly={isReadOnly}
                  onClick={() => {
                    setIsShowFullScreen(true);
                  }}
                >
                  <Icon name={iconName} size={14} color="#aaa " />
                </UpdateIcon>
                <ReactTooltip
                  id={`tooltip-text-${name}`}
                  place="left"
                  effect="solid"
                  backgroundColor="#fff"
                  textColor="#303030"
                  multiline
                  delayShow={500}
                  className="input-textarea-tooltip"
                >
                  <article ref={tooltipRef}>{tooltipText}</article>
                </ReactTooltip>
              </>
            )}

            {isFullScreen && (
              <Modal isOpened={isShowFullScreen}>
                {isShowFullScreen ? (
                  <FullScreenViewer
                    name={name}
                    inputRef={inputRef}
                    title={fieldTitle}
                    onClose={() => {
                      setIsShowFullScreen(false);
                    }}
                    defaultValue={defaultValue}
                    {...selfField}
                    {...rest}
                    hidden={false}
                    onBlur={onBlur}
                    onChange={nativeOnChange}
                    readOnly={isReadOnly}
                  />
                ) : (
                  <></>
                )}
              </Modal>
            )}
          </div>
        </PanelInput>
      </FieldContainer>

      <UserField
        baseName={baseName}
        fieldName={fieldName}
        formRef={formRef}
        mainFormRef={mainFormRef}
        settings={settings}
        labelWidth={(selfField && selfField.labelWidth) || labelWidth || 130}
        auxScope={auxScope}
      />
    </>
  );
}

InputElement.propTypes = {
  formRef: PropTypes.shape(),
  onChange: PropTypes.func,
  baseName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hidden: PropTypes.bool,
  settings: PropTypes.shape(),
  // labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelWidth: PropTypes.any,
  readOnly: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  mainFormRef: PropTypes.shape(),
  fullScreen: PropTypes.bool,
};

InputElement.defaultProps = {
  formRef: null,
  baseName: null,
  settings: null,
  onChange: val => {
    return val;
  },
  label: null,
  labelWidth: null,
  readOnly: false,
  hidden: false,
  mainFormRef: {},
  fullScreen: false,
};

export default InputElement;
