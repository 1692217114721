import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;

  h1 {
    margin-bottom: 10px;
    color: #496774;
  }

  > div {
    margin-bottom: 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  textarea {
    resize: none;
    border: 1px solid #ddd;
    color: #333;
    padding: 10px 10px;
    background-color: #f0f0f0;

    height: 200px;

    @media (max-width: 500px) {
      height: 100%;
      width: 100%;
      border: none;
    }
  }

  button:last-child {
    margin-top: 8px;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
`;

export const Message = styled.div`
  padding: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: #777;
  color: #fff;
  font-size: 1.2rem;
`;
