/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import TreatError from '~/easy-components/TreatError';
import { syncDynamicFunction } from '../dynamicFunction';

window.React = React;

const MobileCardJSX = ({ jsxString, dynamicFunctionProp, onClick }) => {
  const fullFunctionString = `
    function DynamicComponent(scope) {
      ${jsxString}
    }
  `;
  let transpiledCode = 'function DynamicComponent() { return null; }';

  if (window.Babel) {
    try {
      const output = window.Babel.transform(fullFunctionString, {
        presets: ['react'],
      }).code;

      transpiledCode = output;
    } catch (error) {
      TreatError.showError(
        `Error transpiling JSX in MobileCardTemplateJSX. Full Description logged in console.`
      );
      // eslint-disable-next-line no-console
      console.error('Error transpiling JSX:', error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(
      'Babel is not defined. Make sure Babel Standalone is loaded.'
    );
  }

  const renderTranspiledCode = syncDynamicFunction({
    functionString: `
      ${transpiledCode}
      
      return DynamicComponent(scope);
    `,
    ...dynamicFunctionProp,
  });

  return (
    <div style={{ width: '100%' }} onClick={onClick}>
      {renderTranspiledCode}
    </div>
  );
};

export default MobileCardJSX;
