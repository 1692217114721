/* eslint-disable react/prop-types */
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import ModalEmail from '~/easy-components/ModalEmail';
import Toast from '~/easy-components/Toast';
import TreatError from '~/easy-components/TreatError';
import EmailService from '~/applications/CRM/services/EmailService';
import useCompany from '~/hooks/useCompany';
import { createAsyncFunctionByString } from '~/easy-components/AsyncFunctionString';
import { normalizeFileName } from '~/easy-components/Helpers/fileHandler';

// import { Container } from './styles';

/**
 * @typedef {Object} PrintLayoutParam
 * @property {string} paramFieldName
 * @property {string} [screenFieldName]
 * @property {any} [value]
 *
 * @typedef {Object} PrintLayout
 * @property {string} reportCode
 * @property {string} reportName
 * @property {string} description
 * @property {boolean} isDefault
 * @property {PrintLayoutParam[]} parameters
 *
 * @typedef {Object} EmailSettings
 * @property {PrintLayout[]} printLayouts
 * @property {string} attachmentsProp
 * @property {string} to
 * @property {string} copy
 * @property {string} subject
 * @property {string} body
 * @property {boolean} [enableAttachments]
 * @property {boolean} [uniqueFileName]
 * @property {string} [onNewAttachments]
 *
 * @typedef {Object} Props
 * @property {(text: string) => string} t
 * @property {(isLoading: boolean) => void} showLoading
 * @property {Object} settings
 * @property {import('react').MutableRefObject<import('@unform/core').FormHandles>} formRef
 * @property {Object} formData
 * @property {EmailSettings} emailSettings
 */

/** @type {React.FC<Props>} */
const EmailModalComponent = (
  { formRef, emailSettings, settings, showLoading, t, formData, preview },
  ref
) => {
  const [isOpen, setOpen] = useState(false);

  const { companySettings } = useCompany();

  const { userEmail } = companySettings;

  useImperativeHandle(ref, () => {
    return {
      show: () => setOpen(true),
      hide: () => setOpen(false),
    };
  });

  if (!isOpen) return null;

  let attachments = [];
  const formDataAttachments = formData[emailSettings.attachmentsProp];

  if (formDataAttachments && typeof formDataAttachments === 'string') {
    attachments = JSON.parse(formDataAttachments);
  } else if (formDataAttachments) {
    attachments = formDataAttachments;
  }

  /**
   * @param {string} reportCode
   * @param {Record<string, any>} data
   * @returns {Record<string, any>}
   */
  const transformCrystalParams = (reportCode, data) => {
    if (!reportCode) return data;

    const report = emailSettings.printLayouts.find(
      pl => pl.reportCode === reportCode
    );

    if (!report) return data;

    const newParams = { ...data };

    const crystalParams = [];

    for (let index = 0; index < report.parameters.length; index++) {
      const param = report.parameters[index];

      const paramValue = param.value || data[param.screenFieldName];

      crystalParams.push({ name: param.paramFieldName, value: paramValue });
    }

    newParams.params = crystalParams;

    return newParams;
  };

  return (
    <ModalEmail
      preview={preview}
      t={t}
      title={t('SendEmail')}
      settings={settings}
      data={formRef.current.getData()}
      emailSettings={{ ...emailSettings, toEmail: emailSettings.to }}
      onSendEmail={async ({ data: emailData }) => {
        try {
          const data = formRef.current.getData();

          await EmailService.sendEmail({
            from: userEmail,
            to: emailData.toEmail,
            body: emailData.body,
            copy: emailData.copy,
            data: transformCrystalParams(emailData.reportCode, data),
            subject: emailData.subject,
            reportCode: emailData.reportCode,
            attachments: emailData.attachments,
            fileName: emailData.fileName,
            uniqueFileName: emailData.uniqueFileName,
          });

          Toast.success(t('message.EmailSentSuccess'));
          setOpen(false);
        } catch (error) {
          TreatError.showError(error);
        } finally {
          showLoading(false);
        }
      }}
      onClose={() => setOpen(false)}
      enableAttachments={emailSettings.enableAttachments}
      attachments={attachments}
      onNewAttachments={async files => {
        const onNewAttachmentEvent = createAsyncFunctionByString({
          functionString: emailSettings.onNewAttachments,
        });

        /** @type {{fileName: string, sourcePath: string}[]} */
        const newAttachments = await onNewAttachmentEvent({
          ...settings.dynamicFunctionProps,
          data: files,
          formData,
          formRef,
        });

        if (
          !Array.isArray(newAttachments) ||
          newAttachments.some(attachment => !attachment.fileName) ||
          newAttachments.some(attachment => !attachment.sourcePath)
        ) {
          TreatError.showError(
            new Error(
              'Erro Interno - A função onNewAttachment deve retornar uma lista de objetos com as propriedades fileName e sourcePath'
            )
          );

          return [];
        }

        return newAttachments.map(na => ({
          ...na,
          fileName: normalizeFileName(na.fileName),
        }));
      }}
    />
  );
};

// @ts-ignore
const EmailModal = forwardRef(EmailModalComponent);

export default EmailModal;
