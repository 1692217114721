import BaseMasterDataService from './BaseMasterDataService';
import request from '../../Request';

class SignaturesService extends BaseMasterDataService {
  constructor() {
    super({ route: 'signatures', primaryKeyField: 'envelopeId' });
  }

  async send({ data }) {
    try {
      const response = await request({
        url: `APP/CRM/${this.route}/send`,
        method: 'POST',
        data,
      });

      return response;
    } catch (error) {
      throw new Error(error); // TODO: Tradução pendente
    }
  }

  async list() {
    return request({
      url: `APP/CRM/${this.route}/envelopes`,
    });
  }

  async get(primaryKeyField) {
    return request({
      url: `APP/CRM/${this.route}/${primaryKeyField}`,
    });
  }
}

export default new SignaturesService();
