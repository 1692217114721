/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import Modal from '~/easy-components/Modal';
import QueryService from '~/services/QueryService';
import FormParams from '../FormParams';

function Params({ title, t, settings, onCancel = () => {} }, ref) {
  const onConfirmRef = useRef();
  const canCancelRef = useRef(true);
  const formParamsRef = useRef();

  const [isShow, setIsShow] = useState(false);
  const [params, setParams] = useState([]);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  function isDataExists(data) {
    return Object.keys(data).length > 0;
  }

  async function getDefaultData(data = {}, parameters) {
    let defaultData = data;

    if (!isDataExists(data)) {
      for (let cont = 0; cont < parameters.length; cont++) {
        const param = parameters[cont];
        if (param.sqlDefault) {
          // eslint-disable-next-line no-await-in-loop
          const response = await QueryService.execute(1, param.sqlDefault);
          if (response.length > 0) {
            const dataResponse = response[0];
            defaultData = {
              ...defaultData,
              ...dataResponse,
            };
          }
        } else {
          defaultData[param.name] = param.defaultValue || param.value || null;
        }
      }
    }

    /* const onlyMultipleChoice = param => {
      const type = param.type || '';

      return type.toUpperCase() === 'MULTIPLECHOICE';
    };

    const multipleChoiceParams = parameters.filter(onlyMultipleChoice);

    if (multipleChoiceParams.length) {
      multipleChoiceParams.forEach(param => {
        const paramData = param.data || [];

        const isSelected = option => {
          const defaultDataOfParam = param.default || [];

          return defaultDataOfParam.find(opt => opt.value === option.value)
            ? 'Y'
            : 'N';
        };

        paramData.forEach(option => {
          defaultData[
            `_multipleChoice-${param.name}-${option.value}`
          ] = isSelected(option);
        });
      });
    } */

    return defaultData;
  }

  useImperativeHandle(ref, () => {
    return {
      show: async ({
        params: parameters,
        data,
        onConfirm,
        canCancel = true,
      }) => {
        onConfirmRef.current = onConfirm;
        canCancelRef.current = canCancel;

        const defaultData = await getDefaultData(data, parameters);

        setFormData(defaultData);

        setErrors({});

        setParams(parameters);

        const parametersLength = parameters.filter(
          param => param.type !== 'hidden'
        ).length;

        if (parametersLength > 0) setIsShow(true);
        else {
          const values = {};

          parameters.forEach(param => {
            values[param.name] = defaultData[param.name];
          });

          onConfirmRef.current(values);
        }
      },
      validate: () => {
        formParamsRef.current.validate();
      },
    };
  });

  return (
    <Modal isOpened={isShow} direction="right">
      <FormParams
        ref={formParamsRef}
        data={formData}
        title={title}
        params={params}
        settings={settings}
        onCancel={onCancel}
        setIsShow={setIsShow}
        isCancelModalParams={canCancelRef.current}
        onConfirm={onConfirmRef.current}
        t={t}
        errors={errors}
      />
    </Modal>
  );
}

export default forwardRef(Params);
