import { createStore } from 'redux';
import { persistStore } from 'redux-persist';

import persistedReducers from './persistReducers';
import rootReducer from './rootReducer';

const store = createStore(persistedReducers(rootReducer));
const persistor = persistStore(store);

export { store, persistor };
