/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable import/no-cycle */
/* eslint-disable no-case-declarations */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';
import EasyIcon from '~/easy-components/Icon';
import useOutClick from '~/hooks/useOutClick';
import {
  ContextMenu,
  Menus,
  Item,
  ItemIcon,
  Title,
  Separator,
  MenuTitle,
  Content,
} from './styles';

function PopupSettings(_, ref) {
  const popupElementRef = useRef({ x: 0, y: 0, menus: [] });
  const popupRef = useRef();
  const titleRef = useRef();

  const modalRoot = document.getElementById('modal');
  const el = document.createElement('div');

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el, modalRoot]);

  useOutClick(popupRef, isOpen, () => {
    setOpen(false);
  });

  useImperativeHandle(ref, () => {
    return {
      show: ({ x, y, data, title }) => {
        popupElementRef.current.x = x;
        popupElementRef.current.y = y;
        titleRef.current = title;
        popupElementRef.current.menus = data;
        setOpen(true);
      },
    };
  });

  return (
    <>
      {isOpen &&
        createPortal(
          <ContextMenu
            ref={popupRef}
            left={popupElementRef.current.x}
            top={popupElementRef.current.y}
          >
            <Menus>
              {titleRef.current && <MenuTitle>{titleRef.current}</MenuTitle>}
              <Content>
                {popupElementRef.current.menus.map((menu, idx) => {
                  const disabled =
                    menu.disabled === undefined ? false : menu.disabled;

                  const visibled =
                    menu.visible === undefined ? true : menu.visible;

                  const { auxInfo } = menu;

                  switch (menu.type) {
                    case 'separator':
                      return <Separator key={`separator-${idx}`} />;

                    default:
                      return (
                        <Item
                          key={menu.text}
                          disabled={disabled}
                          visible={visibled}
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();

                            if (!menu.disabled) {
                              setOpen(false);
                              menu.handler(e);
                            }
                          }}
                        >
                          {menu.icon && (
                            <ItemIcon>
                              <EasyIcon
                                name={menu.icon}
                                size={18}
                                color={
                                  menu.disabled
                                    ? '#AAA'
                                    : menu.iconColor || menu.color || '#444'
                                }
                              />
                            </ItemIcon>
                          )}
                          <Title changed={menu.changed}>
                            {menu.text} <h1>{auxInfo}</h1>
                          </Title>
                        </Item>
                      );
                  }
                })}
              </Content>
            </Menus>
          </ContextMenu>,
          el
        )}
    </>
  );
}

export default forwardRef(PopupSettings);
