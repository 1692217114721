/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import HtmlParser from 'react-html-parser';
import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';

import MenuButton from '~/easy-components/MenuButton';
import LinkIcon from '~/easy-components/LinkIcon';
import { replacePlaceholdersText } from '~/easy-components/Helpers/replacePlaceholdersText';
import { createAsyncFunctionByString } from '~/easy-components/AsyncFunctionString';

import colors from '~/styles/colors';
import { Container, Content, VStatus, CardContainer } from './styles';

import useCompany from '~/hooks/useCompany';
import useLocale from '~/hooks/useLocale';

function CardTemplate({
  template,
  data,
  format,
  formatters,
  readOnly = false,
  onClickDelete = () => {},
  isShowDelete = true,
  isShowMenus = false,
  customMenus = [],
  onShowContextMenu = () => {},
  idx = undefined,
  settings,
  auxScope = null,
  onClick = () => {},
  listColor,
  ...rest
}) {
  const { dateToString, numberToString, companySettings } = useCompany();
  const [status, setStatus] = useState('');
  const templateReplaced = replacePlaceholdersText({
    text: template,
    placeholderTemplate: '{?}',
    data,
    format,
    formatters,
    auxScope: {
      dateToString,
      numberToString,
      companySettings,
      ...auxScope,
    },
  });

  const t = useLocale('_Global');

  async function onClickLink(e, attribs) {
    e.stopPropagation();
    e.preventDefault();

    const dynamicFunction = createAsyncFunctionByString({
      functionString: attribs.handler,
    });

    await dynamicFunction({
      ...settings.dynamicFunctionProps,
      attributes: attribs,
    });
  }

  async function changeListColor() {
    const dynamicFunction = createAsyncFunctionByString({
      functionString: listColor,
    });

    const response = await dynamicFunction({ data });

    return response;
  }

  const getComponent = ({ attribs }) => {
    switch (attribs.componenttype) {
      case 'showLink':
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <LinkIcon
              onClick={async e => {
                await onClickLink(e, attribs);
              }}
            />
            <div>{attribs.text}</div>
          </div>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (!listColor) return;

    changeListColor().then(color => setStatus(color));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const myMenus = [];

  if (isShowMenus) {
    if (isShowDelete) {
      myMenus.push({
        visible: isShowDelete,
        disabled: false || readOnly,
        text: t('Delete'),
        handler: () => onClickDelete(idx),
      });
    }
    myMenus.push(...customMenus);
  }

  return (
    <CardContainer {...rest}>
      {listColor && <VStatus backgroundColor={status} />}
      <Container {...rest} onClick={onClick}>
        <Content>
          {HtmlParser(templateReplaced, {
            decodeEntities: true,
            // eslint-disable-next-line consistent-return
            transform: node => {
              const { attribs } = node;
              if (attribs && attribs.componenttype) {
                return getComponent({ attribs });
              }
            },
          })}
        </Content>
        {isShowMenus && (
          <MenuButton
            icon={FaEllipsisV}
            color={colors.primary}
            idx={idx}
            auxData={data}
            settings={settings}
            auxScope={auxScope}
            menus={myMenus}
            onBeforeShow={props => onShowContextMenu(props, { data, idx })}
          />
        )}
      </Container>
    </CardContainer>
  );
}

export default CardTemplate;
