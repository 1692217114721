/* eslint-disable no-restricted-syntax */
import React, {
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import QueryService from '~/services/QueryService';
import TreatError from '~/easy-components/TreatError';
import { Container } from './styles';
import Loader from '../Loader';
import Error from '../Error';

function Speedometer(
  { data, widgetSettings, params, errorRender, executeEvent },
  ref
) {
  const divRef = useRef(null);
  const updateTimeRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState(widgetSettings);

  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });

  const run = useCallback(async () => {
    try {
      setIsLoading(true);

      setError(null);

      const response = await QueryService.execute(
        1,
        widgetSettings.query,
        params
      );

      const value = response.length > 0 ? response[0].value : 0;

      const newSettings = await executeEvent({
        eventName: 'onGetSettings',
        data: { widgetSettings, data, params, value },
      });

      setSettings({
        ...widgetSettings,
        value,
        ...newSettings,
      });

      if (widgetSettings.updateTimeMinutes) {
        updateTimeRef.current = setTimeout(async () => {
          await run();
        }, widgetSettings.updateTimeMinutes * 1000 * 60);
      }
    } catch (e) {
      const errorDescription = TreatError.getDescription(e);
      setError(errorDescription);
    } finally {
      setIsLoading(false);
    }
  }, [data, executeEvent, params, widgetSettings]);

  useEffect(() => {
    run();

    return () => {
      clearTimeout(updateTimeRef.current);
    };
  }, [run, data]);

  useEffect(() => {
    setError(errorRender);
  }, [errorRender]);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        setDimension({
          width: Math.round(entry.contentRect.width),
          height: Math.round(entry.contentRect.height),
        });
      }
    });

    if (divRef.current) {
      observer.observe(divRef.current);
      setDimension({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getData: () => {
        return settings;
      },
    };
  });

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <Container ref={divRef}>
      {isLoading ? (
        <Loader />
      ) : (
        <ReactSpeedometer
          needleHeightRatio={0.7}
          needleTransitionDuration={2000}
          minValue={0}
          maxValue={0}
          {...settings}
          forceRender
          width={dimension.width}
          height={dimension.height}
        />
      )}
    </Container>
  );
}

export default forwardRef(Speedometer);

/*

        <ReactSpeedometer
          forceRender
          width={dimension.width}
          height={dimension.height}
          needleHeightRatio={0.7}
          needleTransitionDuration={2000}
          minValue={settings.minValue}
          maxValue={settings.maxValue}
          maxSegmentLabels={10}
          // segments={10}
          value={value}
          customSegmentStops={[0, 100, 200, 500]}
          startColor="#f0f0f0"
          endColor="#55ff99"
          // segmentColors={settings.segmentColors}
          textColor="#777777"
        />

*/
