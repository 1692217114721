/* eslint-disable react/prop-types */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';

import Button from '~/easy-components/Button';
import useLocale from '~/hooks/useLocale';
import ModalHandle from '../../ModalHandle';

import { Container, Header, Body, Footer } from './styles';

function SwipeModalButtons(props, ref) {
  const t = useLocale('_Global');
  const modalRef = useRef();

  const [state, setState] = useState({
    buttons: [],
  });

  useImperativeHandle(ref, () => {
    return {
      open: ({ buttons }) => {
        setState({
          ...state,
          buttons,
        });
        modalRef.current.handleOpen();
      },
      close: () => {
        modalRef.current.handleClose();
      },
    };
  });

  // const buttons = onGetButtons();
  return (
    <ModalHandle ref={modalRef} direction="right">
      <Container>
        <Header>{t('Menu')}</Header>
        <Body>
          {state.buttons &&
            state.buttons.map(button => {
              return button.content;
            })}
        </Body>
        <Footer>
          <Button
            onClick={() => {
              modalRef.current.handleClose();
            }}
          >
            {t('Close')}
          </Button>
        </Footer>
      </Container>
    </ModalHandle>
  );
}

export default forwardRef(SwipeModalButtons);
