/* eslint-disable no-multi-assign */
const INITIAL_STATE = [];

function updateField(state, pageId = '', field, value) {
  // eslint-disable-next-line no-case-declarations
  const idx = state.findIndex(
    pageInfo => pageInfo.pageId.toUpperCase() === (pageId || '').toUpperCase()
  );

  if (idx >= 0) {
    state[idx][field] = value;
  } else {
    const pageSettings = {
      pageId,
    };

    pageSettings[field] = value;

    state.push(pageSettings);
  }

  return [...state];
}

export default function pages(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'COMPANY_SELECTED':
    case 'LOGOFF_SUCCESS':
      return [...INITIAL_STATE];

    case 'SET_VIEW_MODE':
      return updateField(
        state,
        action.payload.pageId,
        'viewMode',
        action.payload.viewMode
      );

    case 'UPDATE_SETTINGS':
      return updateField(
        state,
        action.payload.pageId,
        'settings',
        action.payload.settings
      );

    default:
      return state;
  }
}
