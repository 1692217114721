import TextArea from './TextArea';
import Question from './Question';
import ShowContent from './ShowContent';
// eslint-disable-next-line import/no-cycle
import ShowReport from './ShowReport';
import Nothing from './Nothing';
import Options from './Options';

export default class Builder {
  constructor() {
    this.DEFAULT_TYPE = Nothing;

    this.ALLOWED_TYPES = {
      textarea: TextArea,
      question: Question,
      showContent: ShowContent,
      showReport: ShowReport,
      options: Options,
    };
  }

  build(type) {
    const typeClass = this.ALLOWED_TYPES[type];

    if (!typeClass) return this.DEFAULT_TYPE;

    return this.ALLOWED_TYPES[type];
  }
}
