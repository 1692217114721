/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Modal from '~/easy-components/Modal';
import Camera from './Camera';

import useLocale from '~/hooks/useLocale';

function ModalCamera(props, ref) {
  const t = useLocale('_Global');

  const [state, setState] = useState({
    title: t('Camera'),
    isShow: false,
    onConfirm: () => {},
    imageSrc: null,
    cropFullImage: false,
    errors: {},
  });

  function onClose() {
    setState({
      ...state,
      isShow: false,
    });
  }

  useImperativeHandle(ref, () => {
    return {
      show: ({
        title,
        facingMode = 'environment',
        onConfirm = () => {},
        imageSrc = null,
        cropFullImage = false,
      }) => {
        setState({
          ...state,
          onConfirm,
          title,
          isShow: true,
          facingMode,
          imageSrc,
          cropFullImage,
        });
      },
    };
  });

  return (
    <Modal isOpened={state.isShow}>
      <Camera
        t={t}
        title={state.title}
        facingMode={state.facingMode}
        onConfirm={properties => {
          state.onConfirm(properties);
          onClose();
        }}
        imageForCrop={state.imageSrc}
        cropFullImage={state.cropFullImage}
        onClose={onClose}
      />
    </Modal>
  );
}

export default forwardRef(ModalCamera);
