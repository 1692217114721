import React from 'react';
import PropTypes from 'prop-types';
import { Container, OptionView, OptionTitle } from './styles';
import Icon from '~/easy-components/Icon';

/**
 * @typedef {{title: string, icon: string, handler: string}} Option
 *
 *
 * @typedef {Object} Settings
 * @property {Object} dynamicFunctionProps
 *
 *
 * @typedef {Object} Props
 * @property {Option[]} options
 * @property {(optionSelected: Option) => Promise<any>} [onOptionSelect]
 */

/**
 * @param {Props} params
 * @returns {React.FC<Props>}
 */
function Options({ options, onOptionSelect }) {
  return (
    <Container>
      {options.map(option => (
        <OptionView type="button" onClick={() => onOptionSelect(option)}>
          <OptionTitle>{option.title}</OptionTitle>
          <Icon name={option.icon} size={24} />
        </OptionView>
      ))}
    </Container>
  );
}

Options.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      handler: PropTypes.string.isRequired,
    })
  ),
  onOptionSelect: PropTypes.func,
};

Options.defaultProps = {
  options: [],
  onOptionSelect: () => {},
};

export default Options;
