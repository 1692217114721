/* eslint-disable no-restricted-syntax */
import request from '../../Request';

class UnitMeasureGroupService {
  async list({ filter, itemCode, uomType }) {
    return request({
      url: `APP/CRM/UnitMeasureGroup/${uomType}/${encodeURIComponent(
        itemCode
      )}?$filter=${filter || ''}`,
    });
  }

  async unitMeasureGroupBase({ itemCode, uomEntryCode, UomType }) {
    return request({
      url: `APP/CRM/UnitMeasureGroup/${encodeURIComponent(
        itemCode
      )}/UnitMeasureQuantity/${uomEntryCode}/${UomType}`,
    });
  }

  async unitMeasureGroupsSearch({ filter }) {
    return request({
      url: `APP/CRM/UnitMeasureGroup?$filter=${filter || ''}`,
    });
  }
}
export default new UnitMeasureGroupService();
