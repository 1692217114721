/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import request from '~/applications/Request';
import RequestService from './RequestService';
import CompanyService from './CompanyService';

class ApplicationService {
  async list({ filter, auxFilters }) {
    let filters = '';

    for (const prop in auxFilters) {
      filters += `&${prop}=${auxFilters[prop]}`;
    }
    const response = await RequestService.request({
      url: `applications?$filter=${filter || ''}${filters}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async getUserApplications(token) {
    const response = await RequestService.request({
      url: `user_applications`,
      method: 'GET',
      token,
    });
    return response;
  }

  async get(applicationId) {
    const response = await RequestService.request({
      url: `applications/${applicationId}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response.length > 0 ? response[0] : null;
  }

  async getByCode(route) {
    const response = await RequestService.request({
      url: `applications/route/${route}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response.length > 0 ? response[0] : null;
  }

  async getByRoute(route, hash) {
    const token = CompanyService.getCompanyToken();
    let response;
    if (hash) {
      response = await RequestService.request({
        url: `applications/UserPages/${hash}/settings`,
        method: 'GET',
        token,
      });
    } else {
      response = await RequestService.request({
        url: `applications/${route}/settings`,
        method: 'GET',
        token,
      });
    }
    // eslint-disable-next-line consistent-return
    return response;
  }

  async downloadQueries() {
    const token = await CompanyService.getCompanyToken();

    window.open(
      `${process.env.REACT_APP_SERVER_URL}/files/applications/download/${token}/customApplications`
    );
  }

  async attachFile({ file, fileName }) {
    const response = await request({
      method: 'POST',
      url: `applications/applicationsPage/uploadZip`,
      data: {
        fileName,
      },
      file,
    });

    return response;
  }
}

export default new ApplicationService();
