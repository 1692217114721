const createMenu = ({ isUserPage, queryCode }) => {
  const menus = [
    {
      title: 'EditQuery',
      prop: 'queryList',
      type: 'queryEditor',
      visible: !!isUserPage,
      disabled: false,
    },
    {
      type: 'query',
      title: 'EditQuery',
      prop: queryCode,
      defaultValue: false,
      visible: !!queryCode,
    },
    {
      icon: 'MdOutlineFilterAlt',
      title: 'AuxFilters',
      type: 'list',
      prop: 'auxFilters',
      disabled: false,
      value: false,
      propertiesList: [
        {
          title: 'label',
          subTitle: 'type',
          auxInfo: 'name',
        },
      ],
    },
    {
      icon: 'FaEdit',
      title: 'EditModel',
      type: 'action',
      prop: 'template',
      disabled: true,
      value: false,
    },
  ];

  return menus;
};

export default createMenu;
