/* eslint-disable react/prop-types */
import React, { useRef, useImperativeHandle, forwardRef } from 'react';

import { TextBox, Container } from './style';

function TextAreaComponent({ message, onConfirm }, ref) {
  const textRef = useRef();

  useImperativeHandle(ref, () => ({
    onConfirm: () => onConfirm({ message: textRef.current.value }),
  }));

  return (
    <Container>
      <span>{message}</span>
      <TextBox ref={textRef} />
    </Container>
  );
}

export default forwardRef(TextAreaComponent);
