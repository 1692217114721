/* eslint-disable import/no-cycle */
class GeoLocationService {
  async getLocation({ t }) {
    const geoLocation = await new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(t('message.GeolocationNotSupportedBrowser'));
      } else {
        try {
          navigator.geolocation.getCurrentPosition(
            position => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
            },
            errorApi => {
              reject(errorApi.message);
            },
            {
              enableHighAccuracy: true,
              maximumAge: 3000,
              timeout: 30000,
            }
          );
        } catch (error) {
          reject(new Error('message.UnableRetrieveLocation'));
        }
      }
    });

    return geoLocation;
  }
}

export default new GeoLocationService();
