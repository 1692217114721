/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
import { Decimal } from 'decimal.js';
import request from '../../Request';
import AttachmentService from './AttachmentService';
import ItemService from './ItemService';
import TaxCodeDeterminationService from './TaxCodeDeterminationService';
import TaxCodeService from './TaxCodeService';
import UnitMeasureGroupService from './UnitMeasureGroupService';
import BusinessPartnerService from './BusinessPartnerService';
import QueryService from '../../../services/QueryService';

class DocumentService extends AttachmentService {
  constructor(serviceName) {
    super(serviceName);
    this.serviceName = serviceName;
  }

  async getSettings() {
    const response = await request({
      url: `DocumentSettings`,
    });

    return response;
  }

  async cancelDocument(docEntry) {
    const response = await request({
      url: `APP/CRM/${this.serviceName}/${docEntry}/cancel`,
      method: 'POST',
    });

    return response;
  }

  async closeDocument(docEntry) {
    const response = await request({
      url: `APP/CRM/${this.serviceName}/${docEntry}/close`,
      method: 'POST',
    });

    return response;
  }

  async list(filter, auxFilters) {
    let filters = '';

    // eslint-disable-next-line guard-for-in
    for (const prop in auxFilters) {
      filters += `&${prop}=${auxFilters[prop]}`;
    }

    return request({
      url: `APP/CRM/${this.serviceName}?$filter=${filter}${filters}`,
      data: auxFilters,
    });
  }

  async get(docEntry) {
    const response = await request({
      url: `APP/CRM/${this.serviceName}/${docEntry}`,
    });
    return response;
  }

  async save(data) {
    if (!data.DocEntry) {
      return request({
        url: `APP/CRM/${this.serviceName}`,
        method: 'POST',
        largeData: data,
      });
    }

    return request({
      url: `APP/CRM/${this.serviceName}/${data.DocEntry}`,
      method: 'PUT',
      largeData: data,
    });
  }

  async getTaxesValues({ docEntry, lineNum }) {
    return request({
      url: `APP/CRM/${this.serviceName}/${docEntry}/TaxesValues/${lineNum}`,
      method: 'GET',
    });
  }

  async getMeasureGroupValues({ line, uomEntry, documentType }) {
    const salesRoutes = [
      'SalesOrder',
      'SalesQuotation',
      'ReturnRequest',
      'SalesInvoice',
    ];

    if (uomEntry) {
      let uomType = 'P';

      if (salesRoutes.includes(documentType)) {
        uomType = 'S';
      }

      const responseUnitMeasure = await UnitMeasureGroupService.unitMeasureGroupBase(
        {
          uomEntryCode: uomEntry,
          itemCode: line.ItemCode,
          UomType: uomType,
        }
      );

      if (responseUnitMeasure) {
        const quantity = new Decimal(line.Quantity || 0);
        const weitght = quantity.mul(responseUnitMeasure.Weight);

        return {
          _Weight1: responseUnitMeasure.Weight,
          Weight1: weitght,
          UnitQuantity: responseUnitMeasure.BaseQty,
          UnitName: responseUnitMeasure.UomCode,
        };
      }
    }

    return {
      _Weight1: null,
      Weight1: null,
      UnitQuantity: 1,
      UnitName: null,
    };
  }

  getLineTotalsValues({ documentRate, line }) {
    const quantity = new Decimal(line.Quantity);

    const inventoryQuantity = quantity.mul(line.UnitQuantity);

    const unitPrice = new Decimal(line.UnitPrice);

    const discountPercent = new Decimal(line.DiscountPercent);

    const discountValue = discountPercent.div(100).mul(unitPrice);

    const unitPriceAfterDiscount = unitPrice.minus(discountValue);

    const lineTotal = unitPriceAfterDiscount
      .mul(quantity)
      .div(documentRate || 1)
      .mul(line.Rate || 1);

    const totalsValues = {
      discountValue,
      unitPriceAfterDiscount,
      lineTotal,
      inventoryQuantity,
    };

    return totalsValues;
  }

  getTotalWithoutDiscount({ DocumentLines, DocRate }) {
    let taxSum = new Decimal(0);
    let total = new Decimal(0);

    let lines = [];

    if (DocumentLines) {
      if (typeof DocumentLines === 'string') {
        lines = JSON.parse(DocumentLines);
      } else {
        lines = DocumentLines;
      }
    }

    if (lines.length > 0) {
      for (const line of lines) {
        const LineTotalInCurrencyLine = this.getTotalLine({
          item: line,
          documentRate: +DocRate || 1,
        });

        total = total.plus(LineTotalInCurrencyLine);

        taxSum = taxSum.plus(+line.VatSum || 0).div(+DocRate || 1);
      }
    }

    return { taxSum, total };
  }

  getWithholdingTaxToReduce({ DocumentLines = [] }) {
    const lines =
      typeof DocumentLines === 'string'
        ? JSON.parse(DocumentLines)
        : DocumentLines;

    let reduceValue = new Decimal(0);

    lines.forEach(line => {
      if (line._WithholdingTaxLines) {
        const withholdingTaxLines = JSON.parse(line._WithholdingTaxLines) || [];

        withholdingTaxLines.forEach(wtLine => {
          if (wtLine.Category === 'I') {
            const wtAmount = new Decimal(
              wtLine.WTAmount || wtLine.WTAmountFC || 0
            );
            reduceValue = reduceValue.plus(wtAmount);
          }
        });
      }
    });
    return reduceValue;
  }

  getAdditionalExpensesTotals(expenses) {
    const additionalExpenses = JSON.parse(expenses || '[]');

    let expenseTaxSum = new Decimal(0);
    let expenseTotal = new Decimal(0);

    additionalExpenses.forEach(exp => {
      expenseTaxSum = expenseTaxSum.plus(+exp.TaxSum);
      expenseTotal = expenseTotal.plus(+exp.LineTotal);
    });

    return {
      expenseTaxSum,
      expenseTotal,
    };
  }

  async getTaxCode({ formData, lineData, documentType }) {
    const response = await TaxCodeDeterminationService.get({
      document: formData,
      line: lineData,
      documentType,
    });

    return response;
  }

  async getLineInformationByItemCode({
    document,
    line,
    isRefreshTaxCode,
    settings,
    documentType,
    auxLineData,
    isCalculateValues = true,
  }) {
    const itemCode = line.ItemCode;
    const lineQuantity = line.Quantity;
    const cardCode = line.CardCode || document.CardCode;
    const usageId = line.UsageId || document.MainUsageId;
    const usageName = line.UsageName || document._MainUsageName;
    const branchId = document.BranchId;

    const formData = document;

    let newLine = {
      ...line,
      ItemCode: itemCode,
      Quantity: lineQuantity || 1,
      CardCode: cardCode,
      UsageId: usageId,
      UsageName: usageName,
      UgpEntry: null,
      SUoMEntry: null,
      PUoMEntry: null,
      Currency: line.Currency,
      ItemName: null,
      TreeType: null,
      BatchNumbers: null,
      _NCMCode: null,
      _Image: null,
      _IsManagedByBatch: null,
      WarehouseCode: line.WarehouseCode,
      _Weight1: null,
      Wght1Unit: null,
      Weight1: null,
      UnitId: null,
      UnitName: null,
      _UoMEntry: null,
      UoMEntry: null,
      UnitQuantity: 1,
      InventoryQuantity: 1,
      UnitPrice: null,
      UnitPriceAfterDiscount: 0,
      LineTotal: 0,
      DiscountValue: 0,
      DiscountPercent: 0,
      Status: 1,
      TaxCode: null,
      VatSum: 0,
      _Taxes: null,
      MainSupplier: null,
      _LineVendor: null,
      LineVendor: null,
      ...auxLineData,
    };

    if (itemCode) {
      const item = await ItemService.get(itemCode);

      if (item) {
        const currencyId = formData.DocCurId;

        newLine.UgpEntry = item.UgpEntry;
        newLine.SUoMEntry = item.SUoMEntry;
        newLine.PUoMEntry = item.PUoMEntry;
        newLine.Currency = newLine.Currency || currencyId;
        newLine.Rate = newLine.Rate || 1;
        newLine.ItemName = item.ItemName;
        newLine.TreeType = item.TreeType;
        newLine.BatchNumbers = item.BatchNumbers || [];
        newLine._NCMCode = item._NCMCode;
        newLine._Image = item.Image;
        newLine._IsManagedByBatch = item._IsManagedByBatch;

        if (item.DfltWH) {
          newLine.WarehouseCode = item.DfltWH;
        } else {
          const warehouseDefault = await ItemService.getWarehouseDefault({
            itemCode,
            branchId,
            documentType,
          });

          newLine.WarehouseCode = warehouseDefault;
        }

        if (documentType === 'PurchaseRequest' && item.Mainsupplier) {
          const supplier = await BusinessPartnerService.get(item.Mainsupplier);

          newLine.LineVendor = supplier.CardCode;
          newLine._LineVendor = supplier.CardName;
          newLine.PriceListId = supplier.PriceListId;
        }

        switch (documentType) {
          case 'SalesOrder':
          case 'SalesQuotation':
          case 'ReturnRequest':
          case 'SalesInvoice':
            newLine._Weight1 = item.SWeight1;
            newLine.Wght1Unit = item._SWght1Unit;
            newLine.Weight1 = newLine.Quantity * item.SWeight1;
            newLine.UnitId = item._SUoMEntry || item._SalUnitMsr;
            newLine.UnitName = item._SUoMEntry || item._SalUnitMsr;
            newLine._UoMEntry =
              item.UgpEntry !== -1 ? item._SUoMEntry : 'Manual';
            newLine.UoMEntry = item.UgpEntry !== -1 ? item.SUoMEntry : '-1';
            newLine.UnitQuantity = item.NumInSale || 1;
            break;

          case 'PurchaseOrder':
          case 'PurchaseOrderImport':
          case 'PurchaseQuotation':
          case 'PurchaseRequest':
          case 'PurchaseInvoice':
          case 'PurchaseDownPayments':
            newLine._Weight1 = item.BWeight1;
            newLine.Wght1Unit = item._BWght1Unit;
            newLine.Weight1 = newLine.Quantity * item.BWeight1;
            newLine.UnitId = item._PUoMEntry || item._BuyUnitMsr;
            newLine.UnitName = item._PUoMEntry || item._BuyUnitMsr;
            newLine._UoMEntry =
              item.UgpEntry !== -1 ? item._PUoMEntry : 'Manual';
            newLine.UoMEntry = item.UgpEntry !== -1 ? item.PUoMEntry : '-1';
            newLine.UnitQuantity = item.NumInBuy || 1;
            break;

          default:
            break;
        }

        const unitQuantity = newLine.UnitQuantity;

        if (item.UgpEntry !== -1) {
          const salesRoutes = [
            'SalesOrder',
            'SalesQuotation',
            'ReturnRequest',
            'SalesInvoice',
          ];

          let uomEntry = item.PUoMEntry;

          if (salesRoutes.includes(documentType)) {
            uomEntry = item.SUoMEntry;
          }

          const measugeGroupValues = await this.getMeasureGroupValues({
            line: newLine,
            uomEntry,
            documentType,
          });

          newLine = {
            ...newLine,
            ...measugeGroupValues,
          };

          newLine.DiscountValue = 0;
          newLine.DiscountPercent = 0;
        } else {
          const inventoryQuantity = unitQuantity * newLine.Quantity;
          newLine.InventoryQuantity = inventoryQuantity;
          newLine.UoMEntry = -1;
        }

        if (isCalculateValues) {
          if (!newLine.UnitPrice) {
            const businessPartnerCode =
              formData.CardCode || newLine.LineVendor || newLine.CardCode;

            const newUnitPrice = await ItemService.getUnitPrice({
              // formRef,
              documentType,
              settings,
              itemCode: newLine.ItemCode,
              priceListId: formData.PriceListId || newLine.PriceListId,
              businessPartnerCode,
              unitId: newLine.UnitId,
              quantity: newLine.InventoryQuantity,
              state: formData.ShipStateId,
              currencyId: newLine.Currency,
              uomEntry: newLine.UoMEntry,
            });

            newLine.UnitPrice = newUnitPrice;
          }

          const totals = this.getLineTotalsValues({
            documentRate: formData.DocRate,
            line: newLine,
          });

          newLine.UnitPriceAfterDiscount = totals.unitPriceAfterDiscount;
          newLine.LineTotal = totals.lineTotal;
          newLine.DiscountValue = totals.discountValue;
          newLine.Status = 1;

          if (isRefreshTaxCode) {
            const taxCode = await this.getTaxCode({
              formData,
              lineData: newLine,
              documentType,
            });

            newLine.TaxCode = taxCode;
          }

          const response = await TaxCodeService.calculateLine({
            line: newLine,
          });

          newLine._Taxes = JSON.stringify(response);
          newLine.VatSum = response.vatSum;
        }

        newLine.WTLiable = item.WTLiable || 'N';
      }
    }

    return newLine;
  }

  getDiscountValueLine(item) {
    const discountValue = new Decimal(0);
    return discountValue
      .plus(+item.UnitPrice || 0)
      .mul(+item.DiscountPercent || 0)
      .div(100);
  }

  getTotalLine({ item, documentRate }) {
    const total = new Decimal(0);

    const discountValue = this.getDiscountValueLine(item);

    const rounded = +total
      .plus(+item.UnitPrice || 0)
      .minus(discountValue)
      .div(+documentRate || 1)
      .mul(+item.Rate || 1)
      .mul(+item.Quantity)
      .toNumber()
      .toFixed(2);

    return new Decimal(rounded);
  }

  calculateTaxSum({ value, documentRate, totalBeforeDiscount, documentLines }) {
    let vatSum = new Decimal(0);

    documentLines.forEach(line => {
      const lineTotal = this.getTotalLine({
        item: line,
        documentRate,
      });

      const lineVatSum = new Decimal(line.VatSum);

      const percentDistributed =
        totalBeforeDiscount === 0
          ? new Decimal(0)
          : lineTotal.div(totalBeforeDiscount);

      const distributedValue = percentDistributed.mul(value);

      const percent =
        lineTotal.toNumber() === 0 ? new Decimal(0) : lineVatSum.div(lineTotal);

      const vatSumLine = percent.mul(distributedValue);

      vatSum = vatSum.plus(vatSumLine);
    });

    return vatSum.toNumber();
  }

  getVatSumLine({ item, documentRate }) {
    const total = new Decimal(0);
    return total.plus(+item.VatSum || 0).div(documentRate || 1);
  }

  async mountDocumentToCopy({ baseDocEntry, baseObjectType, destination }) {
    const documentData = await this.get(baseDocEntry);

    const docNum = documentData.DocNum;

    delete documentData.DocNum;
    delete documentData.DocEntry;
    delete documentData.DocDate;
    delete documentData.Status;
    delete documentData._Status;

    switch (destination) {
      case 'SalesInvoices':
      case 'PurchaseInvoices':
        delete documentData.DocDueDate;
        break;

      default:
        break;
    }

    const Lines = documentData.DocumentLines.filter(
      line => line.LineStatus === 'O'
    );

    const DocumentLines = Lines.map((line, idx) => {
      return {
        ...line,
        Quantity: line.OpenQuantity,
        LineNum: idx,
        BaseLine: line.LineNum,
        BaseEntry: baseDocEntry,
        BaseType: baseObjectType,
        BaseDocNum: docNum,
        TargetEntry: null,
        TargetDocNum: null,
        TargetType: null,
        VisualOrder: null,
        OpenQuantity: null,
      };
    });

    documentData.DocumentLines = DocumentLines;

    const AdditionalExpenses = documentData.DocumentAdditionalExpenses.map(
      (line, idx) => {
        return {
          ...line,
          BaseDocEntry: baseDocEntry,
          BaseDocLine: idx,
          BaseDocType: baseObjectType,
          BaseDocumentReference: docNum,
        };
      }
    );

    documentData.DocumentAdditionalExpenses = AdditionalExpenses;

    return documentData;
  }

  async getAttachmentsPath() {
    const result = await QueryService.execute(
      1,
      `SELECT "AttachPath" as "sourcePath" FROM OADP`
    );

    if (!result.length) return '';

    return result[0].sourcePath;
  }

  async getAttachmentsByDocument({ docEntry }) {
    return request({
      url: `APP/CRM/${this.serviceName}/${docEntry}/attachments`,
      method: 'GET',
    });
  }

  async sendEmail(data) {
    const newData = { ...data, serviceName: this.serviceName };
    return request({
      url: `APP/CRM/${this.serviceName}/sendEmail`,
      method: 'POST',
      data: newData,
    });
  }

  async getEmailConfigurations({ DocEntry }) {
    const response = await request({
      url: `APP/CRM/${this.serviceName}/${DocEntry}/emailConfig`,
    });

    return response;
  }
}

export default DocumentService;
