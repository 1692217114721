import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 5px;
  }
`;
