/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Container, Leg, Legs, Title, Totals } from './styles';

function Information({ t }, ref) {
  const divRef = useRef();
  const [information, setInformation] = useState({
    totals: {
      duration: 0,
      distance: 0,
    },
    legs: [],
  });

  const calculateTotals = useCallback(legs => {
    let distance = 0;
    let duration = 0;

    legs.forEach(leg => {
      distance += leg.distance.value;
      duration += leg.duration.value;
    });

    return { distance, duration };
  }, []);

  const distanceText = distance => {
    if (distance >= 9999) {
      return `${Math.round(distance / 1000)} KM`;
    }

    if (distance > 999) {
      return `${distance / 1000} KM`;
    }
    return `${distance} M`;
  };

  const timeText = timeInSeconds => {
    const ONE_MINUTE = 60;
    const ONE_HOUR = ONE_MINUTE * 60;
    const ONE_DAY = ONE_HOUR * 24;

    if (timeInSeconds >= ONE_DAY) {
      return `${Math.round(timeInSeconds / ONE_HOUR)} H`;
    }

    if (timeInSeconds >= ONE_HOUR) {
      const timeInMinutes = timeInSeconds / 60;

      const hours = Math.floor(timeInMinutes / 60);

      const minutes = Math.round(timeInMinutes % 60);

      return `${hours} H ${minutes} Min`;
    }

    if (timeInSeconds >= ONE_MINUTE) {
      return `${Math.round(timeInSeconds / 60)} Min`;
    }

    return `${timeInSeconds} S`;
  };

  useImperativeHandle(ref, () => {
    return {
      element: divRef.current,
      setData: data => {
        const totals = calculateTotals(data.routes[0].legs);
        setInformation({
          totals,
          legs: data.routes[0].legs,
        });
      },
    };
  });

  return (
    <Container ref={divRef}>
      <Title>{t('RouteInformation')}</Title>
      <Legs>
        {information.legs.map((leg, idx) => {
          return (
            <Leg key={idx}>
              <div>
                <b>{t('Source')}</b>
                {leg.start_address}
              </div>
              <div>
                <b>{t('Destiny')}</b>
                {leg.end_address}
              </div>
              <div>
                <b>{t('Time')}</b>
                {leg.duration.text}
              </div>
              <div>
                <b>{t('Distance')}</b>
                {leg.distance.text}
              </div>
            </Leg>
          );
        })}
      </Legs>
      <Totals>
        <div>
          {timeText(information.totals.duration)} (
          {distanceText(information.totals.distance)})
        </div>
        <div>{}</div>
      </Totals>
    </Container>
  );
}

export default forwardRef(Information);
