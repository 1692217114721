/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HiOutlineDocumentReport as ReportIcon } from '@react-icons/all-files/hi/HiOutlineDocumentReport';
import { format as formatDt } from 'date-fns';

import { RiDeleteBin6Line as CloseIcon } from '@react-icons/all-files/ri/RiDeleteBin6Line';

import { RiCloseCircleFill as ErrorIcon } from '@react-icons/all-files/ri/RiCloseCircleFill';

import { Container, Content } from './styles';

import { addTaskResult, addTaskError } from '~/store/modules/tasks/actions';
import Toast from '~/easy-components/Toast';
import TreatError from '~/easy-components/TreatError';
import useCompany from '~/hooks/useCompany';

function Task({ id, name, task, onRemove, executionDate, onHideTasks }) {
  const { companySettings } = useCompany();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [store, setStore] = useState({
    start: null,
  });

  useEffect(() => {
    async function exec() {
      try {
        const dateStart = formatDt(
          new Date(),
          `${companySettings.DateFormat} HH:mm`
        );

        setStore({
          start: dateStart,
        });

        if (!task.error) {
          const response = await task.method(...task.params);
          dispatch(addTaskResult(id, response));
          setIsLoading(false);
          if (task.isShowToast) Toast.info(`Relatório "${name}" concluído`);
        }
      } catch (error) {
        const message = TreatError.getDescription(error);
        const errorObject = { message, error, task };

        task.onError(errorObject);
        TreatError.showError(error);

        dispatch(addTaskError(id, errorObject));
        setErrorMessage(message);
        setIsLoading(false);
      }
    }

    exec();
  }, [dispatch, id, name, task, executionDate, companySettings.DateFormat]);

  function getMessage() {
    if (isLoading) {
      return 'Carregando...';
    }

    if (errorMessage) {
      return errorMessage;
    }

    return 'Concluído';
  }

  return (
    <Container
      onClick={() => {
        if (!isLoading) {
          task.onClick(task);
          onHideTasks();
        }
      }}
    >
      {errorMessage ? (
        <ErrorIcon color="#DD6774" size={24} />
      ) : (
        <ReportIcon color="#496774" size={24} />
      )}

      <Content>
        {name}
        <div style={{ color: errorMessage ? '#DD6774' : null }}>
          {getMessage()}
        </div>

        <h2>{store.start}</h2>
      </Content>
      {!isLoading && (
        <>
          <button
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              onRemove(id);
            }}
          >
            <CloseIcon color="#DD6774" size={16} />
          </button>
        </>
      )}
    </Container>
  );
}

export default Task;
