/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FiCircle } from '@react-icons/all-files/fi/FiCircle';
import { FiCheckCircle } from '@react-icons/all-files/fi/FiCheckCircle';
import { RiFilterLine as FilterOnIcon } from '@react-icons/all-files/ri/RiFilterLine';
import { RiFilterOffLine as FilterOffIcon } from '@react-icons/all-files/ri/RiFilterOffLine';

import compareRender from '~/easy-components/Helpers/compareRender';
import DebounceEvent from '~/easy-components/DebounceEvent';
import Input from '~/easy-components/Form/Input';
import Linker from '~/easy-components/Form/Linker';
import ColorBar from '~/easy-components/ColorBar';
import AuxFilterModal from '~/easy-components/ListPage/AuxFilterModal';

import Icon from '~/easy-components/Icon';
import {
  Container,
  User,
  Image,
  Content,
  Header,
  Information,
  SearchPanel,
  AuxFilter,
} from './styles';

export const userTypes = {
  user_1: {
    value: 1,
    color: '#f1e958',
    label: 'Super',
    icon: 'SiSuperuser',
  },
  user_2: {
    value: 2,
    color: '#449dc1',
    label: 'Administrador',
    icon: 'GrUserSettings',
  },
  user_3: {
    value: 3,
    color: '#c0c0c0',
    label: 'Usuário',
    icon: 'FiUser',
  },
  user_4: {
    value: 4,
    color: '#ffc178',
    label: 'Externo',
    icon: 'FaRegUserCircle',
  },
};

export const getUserTypes = () => {
  const userTypesArray = Object.values(userTypes).map(({ value, label }) => ({
    value,
    label,
  }));

  return userTypesArray;
};

function Users({ permissionType, users, systemUsers, onChange, formRef, t }) {
  const modalFilterRef = useRef(null);
  const selectedColor = permissionType === 1 ? '#54ae54' : '#ea7e7e';

  const title = permissionType === 1 ? t('ReleasedUsers') : t('BlockedUsers');

  const [listUser, setListUser] = useState(systemUsers || []);
  const [filterParams, setFilterParams] = useState(null);

  useEffect(() => {
    const userIds = users.map(user => user.id);
    if (filterParams && filterParams.isSelected === 1) {
      const userSelected = systemUsers.filter(sysUser =>
        userIds.includes(sysUser.user.id)
      );
      setListUser(userSelected);
    } else if (filterParams && filterParams.isSelected === 0) {
      const userNotSelected = systemUsers.filter(sysUser => {
        return !userIds.includes(sysUser.userId);
      });
      setListUser(userNotSelected);
    } else {
      setListUser(systemUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  const refresh = useCallback(() => {
    setFilterParams(null);

    const filter = (
      formRef.current.getFieldValue('_UserSearch') || ''
    ).toUpperCase();

    const userType = formRef.current.getFieldValue('userType');

    const usersFiltered = systemUsers.filter(sysUser => {
      return (
        (sysUser.user.name.toUpperCase().indexOf(filter) >= 0 ||
          sysUser.user.email.toUpperCase().indexOf(filter) >= 0 ||
          (sysUser.description &&
            sysUser.description.toUpperCase().indexOf(filter) >= 0)) &&
        (sysUser.user.userType === userType ||
          userType === 0 ||
          userType === null)
      );
    });

    setListUser(usersFiltered);
  }, [formRef, systemUsers]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const fields = [
    {
      name: 'isSelected',
      title,
      labelWidth: 200,
      type: 'fixedData',
      data: [
        {
          value: 1,
          label: t('Yes'),
        },
        {
          value: 0,
          label: t('No'),
        },
      ],
    },
  ];

  return (
    <Container>
      <Header>
        <h1>{title}</h1>
        <SearchPanel>
          <AuxFilter
            onClick={evt => {
              evt.stopPropagation();
              evt.preventDefault();

              if (filterParams) {
                modalFilterRef.current.show(filterParams);
              } else {
                const params = {};

                // eslint-disable-next-line no-restricted-syntax
                for (const field of fields) {
                  Object.keys(field).forEach(prop => {
                    if (prop === 'defaultValue') {
                      params[field.name] = field[prop];
                    }
                  });
                }

                modalFilterRef.current.show(params);
              }
            }}
          >
            {filterParams ? (
              <FilterOnIcon size={18} color="#496774" />
            ) : (
              <FilterOffIcon size={18} color="#496774" />
            )}
          </AuxFilter>
          <Linker
            label={t('UserType')}
            name="userType"
            valueField="userType"
            formRef={formRef}
            fixedData={[
              { value: 1, label: userTypes.user_1.label },
              { value: 2, label: userTypes.user_2.label },
              { value: 3, label: userTypes.user_3.label },
              { value: 4, label: userTypes.user_4.label },
            ]}
            labelWidth={80}
            onChange={() => {
              refresh();
            }}
          />
          <Input
            label={t('UserSearch')}
            labelWidth={100}
            name="_UserSearch"
            onKeyUp={DebounceEvent(() => {
              refresh();
            })}
            style={{
              flex: 1,
              height: '44px',
              paddingLeft: '20px',
              border: '0',
            }}
          />
        </SearchPanel>
      </Header>
      <Content>
        {listUser.map(systemUser => {
          const user = users.find(u => u.id === systemUser.user.id);
          const status = user ? 1 : 0;

          const type = userTypes[`user_${systemUser.user.userType}`];

          return (
            <User
              key={systemUser.user.id}
              onClick={() => onChange(systemUser.user, status)}
              color={type.color}
            >
              <ColorBar color={type.color} type="vertical" />
              <Icon name={type.icon} size={32} color={type.color} />
              <Information>
                <h1>{systemUser.user.name}</h1>
                <h2>{systemUser.user.email}</h2>
                <h2>{systemUser.description}</h2>
                <h2>
                  <b>{type.label}</b>
                  {systemUser.cardCode && (
                    <>
                      <b> ({systemUser.cardCode}</b> - {systemUser.contactName})
                    </>
                  )}
                </h2>
              </Information>
              <Image>
                {status === 1 ? (
                  <FiCheckCircle size={24} color={selectedColor} />
                ) : (
                  <FiCircle size={24} color="#AAA" />
                )}
              </Image>
            </User>
          );
        })}
      </Content>
      <AuxFilterModal
        ref={modalFilterRef}
        fields={fields}
        settings={{}}
        onConfirm={params => {
          setFilterParams(params);
        }}
      />
    </Container>
  );
}

export default compareRender(Users);
