import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import TreatError from '../easy-components/TreatError';
// eslint-disable-next-line import/named, import/no-cycle
import { PageContext } from '../routes/app';

function useHelpers() {
  const dispatch = useDispatch();

  const globalContext = useContext(PageContext);

  const process = async (callback, errorHandler) => {
    // const timeout = setTimeout(() => {
    globalContext.dynamicFunctionProps.showLoading(true);
    // }, 1000);

    try {
      return await callback();
    } catch (error) {
      if (errorHandler) {
        errorHandler(error);
      } else {
        TreatError.showError(error);
      }
      return null;
    } finally {
      // clearTimeout(timeout);
      globalContext.dynamicFunctionProps.showLoading(false);
    }
  };

  return {
    ...globalContext,
    process,
    dispatch,
  };
}

export default useHelpers;
