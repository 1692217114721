/* eslint-disable import/no-cycle */
import { useContext } from 'react';
import { CompanyContext } from '~/CompanyProvider';

function useCompany() {
  const { settings, setSettings, ...rest } = useContext(CompanyContext);
  return {
    companySettings: settings,
    setCompanySettings: setSettings,
    ...rest,
  };
}

export default useCompany;
