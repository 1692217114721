/* eslint-disable react/prop-types */
// @ts-ignore
import React from 'react';

import { Container, Title } from './styles';

/**
 * @typedef {Object} Props
 * @property {boolean?} isChecked
 * @property {string} title
 * @property {(isChecked: boolean) => void} onChange
 */

/** @type {React.FC<Props>} */
const SelectAll = ({ isChecked, onChange, title }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <input
        type="checkbox"
        onChange={e => {
          onChange(e.target.checked);
        }}
        checked={isChecked}
      />
    </Container>
  );
};

export default SelectAll;
