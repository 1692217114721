/* eslint-disable react/prop-types */
import React from 'react';

import { BsPencil } from '@react-icons/all-files/bs/BsPencil';
import { Container, Content, Line, Status } from './styles';

const getIcon = isQueryCustom => {
  if (isQueryCustom) {
    return (
      <Line>
        <div />
        <BsPencil size={16} color="#00AAC6" />{' '}
      </Line>
    );
  }
  return null;
};

const getStatus = status => {
  if (status)
    return {
      color: '#00AAC6',
    };

  return {
    color: '#b7b7b7',
  };
};

function AppItem({ data }) {
  const { route, applicationSetting, translatedRoute } = data;

  let isConfigCustom = false;
  if (applicationSetting && applicationSetting[0])
    isConfigCustom = !!applicationSetting[0].settings;

  const status = getStatus(isConfigCustom);

  return (
    <Container>
      <Status color={status.color} />
      <Content>
        <Line>
          <h1>{translatedRoute}</h1>
        </Line>
        <Line>
          <h2>{route}</h2>
        </Line>
        {getIcon(isConfigCustom)}
      </Content>
    </Container>
  );
}

export default AppItem;
