/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { BiTrash as DeleteIcon } from '@react-icons/all-files/bi/BiTrash';
import { Decimal } from 'decimal.js';

import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle';

import PageHeader from '~/easy-components/PageHeader';
import FlexSpace from '~/easy-components/FlexSpace';
import Button from '~/easy-components/Button';
import ModalFooter from '~/easy-components/ModalFooter';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Panel from '~/easy-components/Panel';
import Toast from '~/easy-components/Toast';
import TreatError from '~/easy-components/TreatError';
import Modal from '~/easy-components/Modal';

import useCompany from '~/hooks/useCompany';

import Linker from '~/easy-components/Form/Linker';
import Form from '~/easy-components/Form';
import Input from '~/easy-components/Form/Input';
import Number from '~/easy-components/Form/Number';
import BinLocationService from '~/applications/CRM/services/BinLocationService';
import {
  Container,
  ContainerModal,
  Content,
  ItemsContent,
  ItemLine,
  Title,
  ContentModal,
} from './styles';
import { HeaderCell, ColumnCell } from '../styles';

function BinPageReceive({
  title,
  isReadOnly,
  data,
  batchNumber,
  loteLineIndex,
  direction,
  onClose,
  onConfirm,
  t,
}) {
  const quantityInputsRefs = useRef([]);

  const formRef = useRef();
  const mainFormRef = useRef();

  const binLocationAttributedRef = useRef([]);
  const charPermitted = '0123456789,.';

  const { numberToString, stringToNumber } = useCompany();

  const [binLocations, setBinLocations] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [quantityTotal, setQuantityTotal] = useState(Decimal(0));

  const quantityReceive = Decimal(data.Quantity);
  const unitQuantityReceive = Decimal(data.UnitQuantity);
  const valueRequired = quantityReceive.div(unitQuantityReceive);

  const [state, setState] = useState({
    valueSelected: Decimal(0),
    valueRequired,
  });

  let qtdNecessary = Decimal(data.Quantity).div(Decimal(data.UnitQuantity));
  const qtdNecessaryString = numberToString(qtdNecessary);
  qtdNecessary = stringToNumber(qtdNecessaryString);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const whsCode = data._ToWarehouseCode;

  const getSelectedTotal = () => {
    let selectedTotal = new Decimal(0);

    binLocations.forEach(binDataLine => {
      let binQuantity = 0;

      for (let i = 0; i < quantityInputsRefs.current.length; i++) {
        const el = quantityInputsRefs.current[i];

        if (el.name == binDataLine.AbsEntry) {
          binQuantity = el.value;
        }
      }
      const binQuantityDec = new Decimal(binQuantity);
      selectedTotal = selectedTotal.plus(binQuantityDec);
    });
    return selectedTotal;
  };

  const handleFocus = e => e.target.select();

  const attributedBinLocations = ({ name, value, _BinCode }) => {
    const qtd = value;

    const binLocationAttributed = binLocationAttributedRef.current.filter(
      bin => {
        return (
          bin.BinAbsEntry == name &&
          bin.SerialAndBatchNumbersBaseLine == loteLineIndex
        );
      }
    );

    if (binLocationAttributed.length === 0) {
      const binAllocationLine = {
        _BinCode,
        SerialAndBatchNumbersBaseLine: loteLineIndex,
        BinAbsEntry: stringToNumber(name),
        Quantity: qtd,
        BinActionType:
          direction === 'from' ? 'batFromWarehouse' : 'batToWarehouse',
        _direction: direction,
      };

      if (qtd > 0) binLocationAttributedRef.current.push(binAllocationLine);
    } else if (qtd > 0) {
      binLocationAttributed[0].Quantity = qtd;
    } else {
      binLocationAttributedRef.current.pop(binLocationAttributed[0]);
    }
  };

  function handleOnBlur({ name, value, _BinCode }) {
    const qtd = stringToNumber(value);

    if (qtd > qtdNecessary || qtd > valueRequired) {
      Toast.warn(t('QuantityLTNecessary'));
    } else {
      attributedBinLocations({
        name,
        value,
        _BinCode,
      });
    }
    const newTotal = getSelectedTotal();
    setQuantityTotal(newTotal);
  }

  const handleRefs = (el, quantity) => {
    if (el && !quantityInputsRefs.current.includes(el)) {
      el.value = quantity;
      quantityInputsRefs.current.push(el);
    }
  };

  const openModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleConfirmModal = modalData => {
    const hasBin = binLocations.find(bin => bin.AbsEntry == modalData.AbsEntry);
    if (hasBin) return;

    const totalSelected = stringToNumber(getSelectedTotal());
    const qtdRelease = Decimal(modalData.Quantity);
    const total = totalSelected.plus(qtdRelease);

    if (totalSelected >= qtdNecessary) {
      Toast.warn(t('message.RequiredQuantityAlreadySelected'));
      return;
    }

    if (qtdRelease > qtdNecessary) {
      Toast.warn(t('message.AmountNeededExceedsNeeds'));
      return;
    }

    if (total > qtdNecessary) {
      Toast.warn(t('QuantityGreaterNecessary'));
      return;
    }

    setQuantityTotal(total);

    attributedBinLocations({
      name: modalData.AbsEntry,
      value: modalData.Quantity,
      _BinCode: modalData.BinCode,
    });

    const lines = [];

    lines.push({
      AbsEntry: modalData.AbsEntry,
      BinCode: modalData.BinCode,
      _CachedQuantity: modalData.Quantity,
    });

    setBinLocations([...binLocations, ...lines]);
  };

  useEffect(() => {
    setState({
      valueSelected: quantityTotal,
      valueRequired: qtdNecessary - quantityTotal,
    });
    mainFormRef.current.setFieldValue('_Selected', quantityTotal);
    mainFormRef.current.setFieldValue(
      '_Required',
      qtdNecessary - quantityTotal
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityTotal]);

  useEffect(() => {
    let stockTransferLines = data.StockTransferLinesBinAllocations;

    if (typeof stockTransferLines === 'string') {
      stockTransferLines = JSON.parse(data.StockTransferLinesBinAllocations);
    }
    const stbLinesToWarehouse = stockTransferLines.filter(
      s =>
        s.BinActionType === 'batToWarehouse' &&
        s.SerialAndBatchNumbersBaseLine == loteLineIndex
    );
    if (stbLinesToWarehouse.length > 0) {
      const linesReceive = [];
      let total = Decimal(0);
      stbLinesToWarehouse.forEach(stbLine => {
        linesReceive.push({
          AbsEntry: stbLine.BinAbsEntry,
          BinCode: stbLine._BinCode,
          _CachedQuantity: stbLine.Quantity,
        });
        attributedBinLocations({
          name: stbLine.BinAbsEntry,
          value: stbLine.Quantity,
          _BinCode: stbLine._BinCode,
        });
        total = total.plus(Decimal(stbLine.Quantity));
      });
      setQuantityTotal(total);
      setBinLocations([...binLocations, ...linesReceive]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const automaticSelection = async () => {
    for (let i = 0; i < quantityInputsRefs.current.length; i++) {
      const total = getSelectedTotal();
      const inputElement = quantityInputsRefs.current[i];
      const inputValue = inputElement.value || 0;
      inputElement.value = qtdNecessary - (total - inputValue);
    }
    const total = getSelectedTotal();
    setQuantityTotal(total);
  };

  const deleteAll = () => {
    setQuantityTotal(Decimal(0));
    quantityInputsRefs.current = [];
    setBinLocations([]);
  };

  const clear = () => {
    for (let i = 0; i < quantityInputsRefs.current.length; i++) {
      const inputElement = quantityInputsRefs.current[i];
      inputElement.value = 0;
    }
    setQuantityTotal(Decimal(0));
  };

  const fieldProps = { formRef, labelWidth: 150, isReadOnly };
  const mainProps = { labelWidth: 150, isReadOnly: true };

  return (
    <Container>
      {title && <PageHeader>{title}</PageHeader>}
      <Form ref={mainFormRef} data={data} errors={errors}>
        <Content>
          <ColumnLayout>
            <Panel width="400px">
              {batchNumber && (
                <Input
                  label={t('BatchNumber')} /* Lote */
                  name="BatchNumber"
                  fieldDefaultValue={batchNumber}
                  {...mainProps}
                />
              )}

              <Input
                label={t('WhsCode')} /* Código do Depósito */
                name="WhsCode"
                fieldDefaultValue={whsCode}
                {...mainProps}
              />

              <Input label={t('ItemCode')} name="ItemCode" {...mainProps} />
            </Panel>
            <Panel width="400px">
              <Number
                label={t('Quantity')}
                name="_Quantity"
                fieldDefaultValue={qtdNecessary}
                {...mainProps}
              />

              <Number
                label={t('Selected')}
                name="_Selected"
                fieldDefaultValue={state.valueSelected}
                {...mainProps}
              />

              <Number
                label={t('Required')}
                name="_Required"
                fieldDefaultValue={state.valueRequired}
                {...mainProps}
              />
            </Panel>
          </ColumnLayout>
          <Title>{t('Position')}</Title>
          <header>
            <div>
              <button type="button" onClick={() => openModal()}>
                <FaPlusCircle size={24} color="#75b575" />
              </button>
            </div>
          </header>
          <ItemsContent>
            <table>
              <thead>
                <tr>
                  <HeaderCell>{t('WarehousePosition')}</HeaderCell>
                  <HeaderCell contentType="number">
                    {t('fieldAttributedName')}
                  </HeaderCell>
                  <HeaderCell contentType="number" />
                </tr>
              </thead>
              <tbody>
                {binLocations.map((item, idx) => {
                  const quantity = item._CachedQuantity || 0;

                  return (
                    <ItemLine
                      key={idx}
                      display="table-row"
                      lineColor={() => {
                        const lineColor = '#f9f9f9';
                        return lineColor;
                      }}
                    >
                      <ColumnCell>{item.BinCode}</ColumnCell>
                      <ColumnCell contentType="number" input>
                        <input
                          name={item.AbsEntry}
                          ref={el => handleRefs(el, quantity)}
                          onBlur={e => {
                            const { value } = e.target;
                            if (
                              value === null ||
                              value === undefined ||
                              value === ''
                            ) {
                              e.target.value = 1;
                            }
                            handleOnBlur({
                              value: e.target.value,
                              name: e.target.name,
                              _BinCode: item.BinCode,
                            });
                          }}
                          onKeyPress={e => {
                            if (charPermitted.indexOf(e.key) < 0) {
                              e.preventDefault();
                            }
                          }}
                          onFocus={handleFocus}
                        />
                      </ColumnCell>
                      <ColumnCell contentType="number">
                        <DeleteIcon
                          size={20}
                          color="#ff7777"
                          onClick={() => {
                            const bins = binLocations.filter(
                              bin => bin.AbsEntry !== item.AbsEntry
                            );

                            const binLocationAttributed = binLocationAttributedRef.current.filter(
                              bin => {
                                return (
                                  bin.BinAbsEntry == item.AbsEntry &&
                                  bin.SerialAndBatchNumbersBaseLine ==
                                    loteLineIndex
                                );
                              }
                            );

                            binLocationAttributedRef.current.pop(
                              binLocationAttributed
                            );

                            setBinLocations(bins);

                            const total =
                              getSelectedTotal() - item._CachedQuantity;
                            setQuantityTotal(total);
                          }}
                        />
                      </ColumnCell>
                    </ItemLine>
                  );
                })}
              </tbody>
            </table>
          </ItemsContent>
        </Content>
      </Form>

      <ModalFooter>
        {!isReadOnly && (
          <>
            <Button
              buttonType="Default"
              onClick={async () => {
                await automaticSelection();
              }}
              style={{ marginLeft: '0px' }}
            >
              {t('AutoSelect')}
            </Button>
            <Button
              buttonType="Default"
              onClick={() => {
                clear();
              }}
            >
              {t('Clear')}
            </Button>

            <Button
              buttonType="Reject"
              onClick={() => {
                deleteAll();
              }}
            >
              {t('DeleteAll')}
            </Button>
            <FlexSpace />
          </>
        )}
        <Button
          buttonType="Default"
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        >
          {t('Cancel')}
        </Button>

        {!isReadOnly && (
          <Button
            buttonType="Emphasized"
            onClick={e => {
              e.stopPropagation();

              const selectedValue = mainFormRef.current.getFieldValue(
                '_Selected'
              );

              if (qtdNecessary !== selectedValue) {
                Toast.warn(
                  t(
                    'error.NecessaryAmountDifferentSelectedOne'
                  ) /* A quantidade necessária é diferente da selecionada */
                );
              } else {
                onConfirm({
                  direction,
                  binLocationsAttributed: binLocationAttributedRef.current,
                });
                onClose();
              }
            }}
          >
            {t('Confirm')}
          </Button>
        )}
      </ModalFooter>
      <Modal isOpened={isOpenModal}>
        <ContainerModal>
          <PageHeader>{t('AddPosition')}</PageHeader>
          <ContentModal>
            <Form ref={formRef} data={formData} errors={errors}>
              <ColumnLayout>
                <Panel>
                  <Linker
                    autoFocus
                    label={t('BinCode')}
                    name="BinCode"
                    valueField="AbsEntry"
                    queryCode="BinLocations_Destination"
                    {...fieldProps}
                    method={async filter => {
                      try {
                        const bins = await BinLocationService.getBinLocations({
                          whsCode,
                          filter,
                        });
                        return bins;
                      } catch (error) {
                        TreatError.showError(error);
                        return [];
                      }
                    }}
                  />
                  <Number
                    label={t('Quantity')}
                    name="Quantity"
                    fieldDefaultValue={state.valueRequired}
                    {...fieldProps}
                    onKeyPress={e => {
                      if (charPermitted.indexOf(e.key) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Panel>
              </ColumnLayout>
            </Form>
          </ContentModal>

          <ModalFooter>
            <Button
              buttonType="Default"
              onClick={e => {
                e.stopPropagation();
                setIsOpenModal(false);
              }}
            >
              {t('Cancel')}
            </Button>

            <Button
              buttonType="Emphasized"
              onClick={e => {
                e.stopPropagation();
                setIsOpenModal(false);
                const formDataModal = formRef.current.getData();
                if (
                  formDataModal.AbsEntry &&
                  formDataModal.Quantity &&
                  formDataModal.Quantity > 0
                ) {
                  handleConfirmModal(formDataModal);
                }
              }}
            >
              {t('Confirm')}
            </Button>
          </ModalFooter>
        </ContainerModal>
      </Modal>
    </Container>
  );
}

export default BinPageReceive;
