import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

export const Content = styled.div`
  padding: 40px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #dedede;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 500px) {
    box-shadow: none;
    border: none;
  }

  img {
    align-self: center;
    width: 140px;
    margin-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    input {
      border: 1px solid #ddd;
      padding: 0 15px;
      margin: 0 0 10px;
      height: 44px;
      border-radius: 5px;
      color: #555;

      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    input:focus {
      border: 2px solid #3b9eff;
      color: #000;
    }
  }

  button {
    margin-top: 5px;
    height: 44px;
    background: #3b9eff;
    color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background 0.5s, width 0.5s;

    &:hover {
      background: ${darken(0.1, '#3b9eff')};
    }

    svg {
      color: #fff;
      fill: #fff;
    }

    &.CustomDefault {
      background-color: transparent;
      color: #3b9eff;
      border: 1px solid #3b9eff;
    }
  }

  a:link {
    color: #aaa;
  }

  /* link que foi visitado */
  a:visited {
    color: #aaa;
  }

  /* mouse over */
  a:hover {
    color: #888;
  }

  /* link selecionado */
  a:active {
    color: #888;
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  margin: 20px 0px;
`;

export const Message = styled.p`
  margin-bottom: 20px;
`;
