import request from '../applications/Request';

class MentionService {
  async listUsers({ filter }) {
    const response = await request({
      url: `app/mentions/users/${filter || ' '}`,
    });

    return response;
  }

  async listMessages({ objectCode, keyValue }) {
    const response = await request({
      url: `app/mentions/messages/${objectCode}/${keyValue}`,
    });

    return response;
  }

  async sendMessage({
    objectCode,
    keyValue,
    keyText,
    message,
    mentionsInsideMessage,
  }) {
    const response = await request({
      url: `app/mentions/messages`,
      method: 'POST',
      data: {
        objectCode,
        keyValue,
        keyText,
        message,
        mentionsInsideMessage,
      },
    });

    return response;
  }
}

export default new MentionService();
