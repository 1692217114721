/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useLayoutEffect,
  useState,
  useCallback,
  createElement,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendData } from '~/store/modules/log/actions';

import { Container } from './styles';

function Icon({ name, size, color, ...rest }) {
  const [isLoadedLib, setIsLoadedLib] = useState(false);
  const [lib, setLib] = useState(null);
  const showLog = useSelector(({ log }) => log.isShow);
  const dispatch = useDispatch();

  function getLibName(text) {
    if (text.indexOf('IoIos') === 0) {
      return 'io4';
    }
    if (text.indexOf('IoMd') === 0) {
      return 'iomd';
    }

    return text.replace(/^([A-Z][^A-Z0-9]*).+$/, '$1').toLowerCase();
  }

  const loadIconLib = useCallback(async libName => {
    let module = null;

    switch (libName) {
      case 'ai':
        module = await import('react-icons/ai');
        break;

      case 'bs':
        module = await import('react-icons/bs');
        break;

      case 'bi':
        module = await import('react-icons/bi');
        break;

      case 'di':
        module = await import('react-icons/di');
        break;

      case 'fc':
        module = await import('react-icons/fc');
        break;

      case 'fa':
        module = await import('react-icons/fa');
        break;

      case 'fa6':
        module = await import('react-icons/fa6');
        break;

      case 'gi':
        module = await import('react-icons/gi');
        break;

      case 'go':
        module = await import('react-icons/go');
        break;

      case 'gr':
        module = await import('react-icons/gr');
        break;

      case 'hi':
        module = await import('react-icons/hi');
        const hi2 = await import('react-icons/hi2');
        module = { ...module, ...hi2 };
        break;

      case 'hi2':
        module = await import('react-icons/hi2');
        break;

      case 'im':
        module = await import('react-icons/im');
        break;

      case 'io4':
        module = await import('react-icons/io');
        break;

      case 'io':
        module = await import('react-icons/io5');
        break;

      case 'iomd':
        module = await import('react-icons/io');
        break;

      case 'md':
        module = await import('react-icons/md');
        break;

      case 'ri':
        module = await import('react-icons/ri');
        break;

      case 'fi':
        module = await import('react-icons/fi');
        break;

      case 'si':
        module = await import('react-icons/si');
        break;

      case 'ti':
        module = await import('react-icons/ti');
        break;

      case 'vsc':
        module = await import('react-icons/vsc');
        break;

      case 'wi':
        module = await import('react-icons/wi');
        break;

      case 'cg':
        module = await import('react-icons/cg');
        break;

      case 'ci':
        module = await import('react-icons/ci');
        break;

      case 'lia':
        module = await import('react-icons/lia');
        break;

      case 'lu':
        module = await import('react-icons/lu');
        break;

      case 'pi':
        module = await import('react-icons/pi');
        break;

      case 'rx':
        module = await import('react-icons/rx');
        break;

      case 'sl':
        module = await import('react-icons/sl');
        break;

      case 'tb':
        module = await import('react-icons/tb');
        break;

      case 'tfi':
        module = await import('react-icons/tfi');
        break;

      default:
        throw new Error('Icon not found');
    }

    setLib(module);
    setIsLoadedLib(true);
  }, []);

  useLayoutEffect(() => {
    if (!name) return;

    loadIconLib(getLibName(name))
      .then()
      .catch(e => {
        //TODO: error
        console.error('Lib error', getLibName(name));
        console.error('Name', name);
        console.error(e);
      });
  }, [name]);

  if (!name) return null;

  if (isLoadedLib === false) {
    return null;
  }

  if (!lib[name]) {
    return null;
  }
  return (
    <Container
      isClickable={!!(rest && rest.onClick)}
      size={size}
      color={color}
      {...rest}
      onMouseOver={() => {
        if (showLog) {
          dispatch(
            sendData({
              baseName: null,
              name: 'Icon',
              value: name,
            })
          );
        }
      }}
    >
      {createElement(lib[name])}
    </Container>
  );
}

export default Icon;
