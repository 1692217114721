/* eslint-disable react/prop-types */
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { MdKeyboardArrowRight } from '@react-icons/all-files/md/MdKeyboardArrowRight';
import Modal from '../../../../Modal';
import LinkIcon from '../../../../LinkIcon';

// eslint-disable-next-line import/no-cycle
import Detail from './Detail';

import { Container, Text } from './styles';

const MobileView = (
  {
    t,
    text,
    fixedData,
    method,
    renderItem,
    readOnly,
    onConfirm,
    title,
    showLink,
    onLink,
    forceSelection,
  },
  ref
) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    openSearch: () => {
      setIsOpen(true);
    },
  }));

  return (
    <>
      {readOnly ? (
        <>
          {showLink && <LinkIcon onClick={onLink} />}
          <Text readOnly>{text}</Text>
        </>
      ) : (
        <Container
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          {showLink && text && <LinkIcon onClick={onLink} />}
          <Text>{text}</Text>
          <MdKeyboardArrowRight size={22} color="#333" />
          <Modal isOpened={isOpen}>
            <Detail
              t={t}
              title={title}
              text={text}
              onClose={() => setIsOpen(false)}
              method={method}
              fixedData={fixedData}
              renderItem={renderItem}
              forceSelection={forceSelection}
              onConfirm={value => {
                onConfirm(value);
                setIsOpen(false);
              }}
            />
          </Modal>
        </Container>
      )}
    </>
  );
};

export default forwardRef(MobileView);
