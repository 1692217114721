/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { format, getMonth, getYear, parseISO } from 'date-fns';

import styled from 'styled-components';
import useLocale from '~/hooks/useLocale';
import LocalStorage from '~/services/LocalStorage';
import request from './applications/Request';
import Loading from './components/Loading';
import Toast from './easy-components/Toast';
import usePasswordExpired from './hooks/usePasswordExpired';

export const CompanyContext = createContext();

const defaultValues = {};

export const Error = styled.div`
  z-index: 9;
  background: #f75c51;
  /* width: 400px; */
  right: 0;
  top: 0;
  position: fixed;
  padding: 4px 10px;
  color: #f0f0f0;
  text-align: right;
`;

function CompanyProvider({ logRef, children }) {
  const t = useLocale('_Global');

  const companyUrlLinkIdRef = useRef();

  const companyToken = useSelector(({ companyToken: tk }) => tk);

  const [settings, setSettings] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);

  function removeRightZeros({ number, decimalSeparator }) {
    const [int, decimals] = number.split(decimalSeparator);

    let newDecimal = '';

    if (decimals) {
      const numbers = decimals.split('');

      for (let pos = numbers.length - 1; pos >= 0; pos -= 1) {
        const value = numbers[pos];
        if (value !== '0') {
          newDecimal = decimals.substring(0, pos + 1);
          break;
        }
      }
    }

    let newValue = `${int}${decimalSeparator}${newDecimal}`;

    if (newValue.indexOf(decimalSeparator) === newValue.length - 1) {
      newValue = newValue.substring(0, newValue.length - 1);
    }

    return newValue;
  }

  function formatNumber(
    amount,
    decimalLength,
    decimalSeparator = '.',
    thousandsSeparator = ',',
    isRemoveRightZeros = false
  ) {
    try {
      if (amount === null) return '';

      decimalLength = Math.abs(decimalLength);
      // eslint-disable-next-line no-restricted-globals
      decimalLength = isNaN(decimalLength) ? 2 : decimalLength;

      const negativeSign = amount < 0 ? '-' : '';

      // eslint-disable-next-line radix
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalLength))
      ).toString();

      const j = i.length > 3 ? i.length % 3 : 0;

      const formattedData =
        negativeSign +
        (j ? i.substr(0, j) + thousandsSeparator : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousandsSeparator}`) +
        (decimalLength
          ? decimalSeparator +
            Math.abs(amount - i)
              .toFixed(decimalLength)
              .slice(2)
          : '');

      if (isRemoveRightZeros) {
        return removeRightZeros({ number: formattedData, decimalSeparator });
      }
      return formattedData;
    } catch (e) {
      return 0;
    }
  }

  function getDateType(stringDate) {
    if (stringDate.indexOf(':') >= 0) return 'timezone';

    if (stringDate.indexOf('-') >= 0) return 'sql';

    if (stringDate.indexOf('/') >= 0) return 'display';

    if (stringDate.length < 4) return 'display';

    return 'undefined';
  }

  function getDateFormatPositions() {
    const positions = [];

    const datePartsRef = settings.DateFormat.split('/');

    datePartsRef.forEach((part, position) => {
      const ref = part.substring(0, 1).toUpperCase();
      switch (ref) {
        case 'D':
          positions.push({
            position,
            ref,
            value: null,
            convert: date => {
              const day = +format(date, 'd');
              return day;
            },
          });
          break;

        case 'M':
          positions.push({
            position,
            ref,
            value: null,
            convert: date => {
              const month = getMonth(date);
              return month + 1;
            },
          });
          break;

        default:
          // Y
          positions.push({
            position,
            ref,
            value: null,
            convert: getYear,
          });
      }
    });

    return positions.sort((itemA, itemB) => {
      if (itemA.position < itemB.position) {
        return -1;
      }
      if (itemA.position > itemB.position) {
        return 1;
      }
      return 0;
    });
  }

  function formatStringDateToBar(stringDate) {
    let stringFormatted = '';

    if (!stringDate.includes('/')) {
      switch (stringDate.length) {
        case 3:
          stringFormatted = `${stringDate.slice(0, 2)}/0${stringDate.slice(
            -1
          )}`;
          return stringFormatted;

        case 4:
          stringFormatted = `${stringDate.slice(0, 2)}/${stringDate.slice(-2)}`;
          return stringFormatted;

        case 5:
        case 7:
          const date = new Date();
          stringFormatted = `${stringDate.slice(0, 2)}/${stringDate.slice(
            2,
            4
          )}/${date.getFullYear()}`;
          return stringFormatted;

        case 6:
          stringFormatted = `${stringDate.slice(0, 2)}/${stringDate.slice(
            2,
            4
          )}/20${stringDate.slice(-2)}`;
          return stringFormatted;

        case 8:
          stringFormatted = `${stringDate.slice(0, 2)}/${stringDate.slice(
            2,
            4
          )}/${stringDate.slice(4, 8)}`;
          return stringFormatted;

        default:
          return stringDate;
      }
    } else {
      return stringDate;
    }
  }

  const stringToDate = useCallback(
    stringDate => {
      try {
        if (stringDate) {
          if (typeof stringDate === 'object') {
            return stringDate;
          }

          let rawDate = formatStringDateToBar(stringDate);
          let newDate = null;

          const dateType = getDateType(rawDate);

          switch (dateType) {
            case 'timezone':
              rawDate = rawDate.replace('Z', '');

              newDate = parseISO(rawDate);

              break;

            case 'sql':
              rawDate = rawDate.split('-');

              newDate = new Date(rawDate[0], +rawDate[1] - 1, rawDate[2]);

              break;

            case 'display':
              const dateRef = new Date();

              const dateParts = rawDate.split('/');

              const datePositions = getDateFormatPositions();

              let firstParam = null;
              let secondParam = null;
              let thirdParam = null;

              switch (dateParts.length) {
                case 1:
                  firstParam = +dateParts[0];
                  secondParam = datePositions[1].convert(dateRef);
                  thirdParam = datePositions[2].convert(dateRef);
                  break;

                case 2:
                  firstParam = +dateParts[0];
                  secondParam = +dateParts[1];
                  thirdParam = datePositions[2].convert(dateRef);
                  break;

                default:
                  firstParam = +dateParts[0];
                  secondParam = +dateParts[1];
                  thirdParam = +dateParts[2];
                  break;
              }

              datePositions[0].value = firstParam;
              datePositions[1].value = secondParam;
              datePositions[2].value = thirdParam;

              const year = datePositions.find(d => d.ref === 'Y');
              const month = datePositions.find(d => d.ref === 'M');
              const day = datePositions.find(d => d.ref === 'D');

              newDate = new Date(year.value, month.value - 1, day.value);
              break;

            default:
              newDate = null;
              break;
          }

          return newDate;
        }

        return null;
      } catch (e) {
        return null;
      }
    },
    [getDateFormatPositions]
  );

  const dateToString = useCallback(
    (date, dateFormat) => {
      const newDate = stringToDate(date);

      if (!newDate) return null;

      if (dateFormat || settings.DateFormat) {
        try {
          return format(newDate, dateFormat || settings.DateFormat);
        } catch (e) {
          return null;
        }
      }

      return newDate.toLocaleDateString();
    },
    [settings.DateFormat, stringToDate]
  );

  const numberToString = useCallback(
    (value, type, digits = null) => {
      if (value === null || value === undefined || value === '') {
        return '';
      }

      const valueType = typeof value;

      let numberValue = value;

      let valueFormatted = '';

      switch (valueType) {
        case 'object':
          if (typeof value.toNumber === 'function')
            numberValue = value.toNumber();
          else numberValue = null;
          break;

        case 'string':
          if (Number.isNaN(value)) throw new Error('Valor não é numérico');
          numberValue = +value;
          break;

        default:
          break;
      }

      const hasDigits = digits !== null && digits !== undefined;

      switch (type) {
        case 'integer':
          valueFormatted = formatNumber(
            numberValue,
            hasDigits ? digits : 0,
            settings.DecSep,
            settings.ThousSep
          );
          return valueFormatted;

        case 'quantity':
          valueFormatted = formatNumber(
            numberValue,
            hasDigits ? digits : settings.QtyDec,
            settings.DecSep,
            settings.ThousSep,
            true
          );
          return valueFormatted;

        case 'measure':
          valueFormatted = formatNumber(
            numberValue,
            hasDigits ? digits : settings.MeasureDec,
            settings.DecSep,
            settings.ThousSep,
            true
          );
          return valueFormatted;

        case 'percent':
          valueFormatted = formatNumber(
            numberValue,
            hasDigits ? digits : settings.PercentDec,
            settings.DecSep,
            settings.ThousSep,
            true
          );
          return valueFormatted;

        case 'value':
          valueFormatted = formatNumber(
            numberValue,
            hasDigits ? digits : settings.ValueDec,
            settings.DecSep,
            settings.ThousSep
          );
          return valueFormatted;

        case 'price':
          valueFormatted = formatNumber(
            numberValue,
            hasDigits ? digits : settings.PriceDec,
            settings.DecSep,
            settings.ThousSep
          );
          return valueFormatted;

        case 'rate':
          valueFormatted = formatNumber(
            numberValue,
            hasDigits ? digits : settings.RateDec,
            settings.DecSep,
            settings.ThousSep
          );
          return valueFormatted;

        default:
          valueFormatted = formatNumber(
            numberValue,
            hasDigits ? digits : settings.ValueDec,
            settings.DecSep,
            settings.ThousSep,
            true
          );
          return valueFormatted;
      }
    },
    [settings]
  );

  const stringToNumber = useCallback(
    value => {
      if (typeof value === 'string') {
        if (value === '') {
          return null;
        }

        let val = value.split(settings.ThousSep).join('');
        val = val.split(settings.DecSep).join('.');
        val = +val;

        return val;
      }

      return value;
    },
    [settings]
  );

  const setCompanyUrlLinkId = useCallback(companyUrlLinkId => {
    companyUrlLinkIdRef.current = companyUrlLinkId;
  }, []);

  const getCompanyUrlLinkId = useCallback(() => {
    return companyUrlLinkIdRef.current;
  }, []);

  const getSettings = useCallback(token => {
    setIsLoading(true);

    request({
      url: `GlobalContext`,
      companyToken: token,
    })
      .then(response => {
        setSettings(response);
        document.title = `${process.env.REACT_APP_TITLE} - ${response.companyName}`;
        setIsLoading(false);
      })
      .catch(error => {
        Toast.error(t('NoCompanyContext'));
        setIsLoading(false);
      });
  }, []);

  const refreshSettings = useCallback(token => {
    getSettings(token);
  }, []);

  useEffect(() => {
    if (companyToken) {
      getSettings(companyToken);
    }
  }, [companyToken]);

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  return (
    <CompanyContext.Provider
      value={{
        setSettings,
        settings,
        dateToString,
        stringToDate,
        stringToNumber,
        numberToString,
        refreshSettings,
        setCompanyUrlLinkId,
        getCompanyUrlLinkId,
        logRef,
      }}
    >
      {settings.errorCompanyReadContext && (
        <Error>{settings.errorCompanyReadContext}</Error>
      )}
      {children}
    </CompanyContext.Provider>
  );
}

export default CompanyProvider;
