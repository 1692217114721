/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
// @ts-check
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import Schedule from '~/easy-components/Schedule';
import useSchedule from '~/easy-components/hooks/useSchedule';
import isValidJson from '~/easy-components/Helpers/isValidJson';

import { Container } from './styles';
/**
 * @typedef {Omit<import('~/easy-components/Schedule').Props, 'events'>} TreatedProps
 *
 * @typedef {Object} ExtraProps
 * @property {string} name
 * @property {object} settings
 * @property {object[]} clientEvents
 * @property {() => any} [refreshPage]
 * @property {(string: string) => Date} stringToDate
 * @property {string} eventTemplate
 * @property {import('react').MutableRefObject<import('@unform/core').FormHandles>} formRef
 *
 * @param {TreatedProps & ExtraProps} props
 */
function TabSchedule(props) {
  const {
    name,
    settings,
    clientEvents,
    refreshPage,
    stringToDate,
    eventTemplate,
    formRef,
  } = props;

  const { registerField, fieldName } = useField(name);

  const [formEvents, setEvents] = useState([]);

  const {
    events,
    onAddEvent,
    onEventTemplate,
    onSelectEvent,
    updateRangeRef,
    executeEvent,
    viewRange,
  } = useSchedule({
    applicationSettings: settings,
    clientEvents,
    events: formEvents,
    refreshPage,
    stringToDate,
    eventTemplate,
    formRef,
  });

  const loadEvents = useCallback(async range => {
    await executeEvent({
      eventName: 'onLoadData',
      data: { range },
      run: 'before',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scheduleRef = useRef({
    value: [],
    loadEvents: evts => {
      if (typeof evts === 'string' && isValidJson(evts)) {
        scheduleRef.current.value = JSON.parse(evts);
        setEvents(JSON.parse(evts));
        return;
      }

      scheduleRef.current.value = evts;
      setEvents(evts);
    },
    refreshData: () => {
      loadEvents(viewRange);
    },
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: scheduleRef.current,
      getValue: ref => {
        const refValue = ref.value;

        if (typeof refValue === 'string' && isValidJson(refValue)) {
          return JSON.parse(refValue);
        }

        return refValue;
      },
      setValue: (ref, value) => {
        if (!value) return;

        if (typeof value === 'string' && isValidJson(value)) {
          ref.value = JSON.parse(value);
          setEvents(JSON.parse(value));
          return;
        }

        ref.value = value;
        setEvents(value);
      },
    });

    return () => {};
  }, [fieldName, registerField]);

  useEffect(() => {
    loadEvents(viewRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Schedule
        {...props}
        onAddEvent={onAddEvent}
        onEventTemplate={onEventTemplate()}
        onSelectEvent={onSelectEvent}
        events={events}
        onRangeChange={async range => {
          updateRangeRef(range);
          loadEvents(range);
        }}
      />
    </Container>
  );
}

export default TabSchedule;
