import React, { Suspense } from 'react';
import Loading from '~/easy-components/Loading';
import Route from './route';

function AsyncRoute(props) {
  return (
    <Suspense fallback={<Loading isLoading />}>
      <Route {...props} />
    </Suspense>
  );
}

export default AsyncRoute;
