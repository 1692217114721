import styled from 'styled-components';

export const Container = styled.div`
  width: 20%;
  background-color: #111;
`;

export const SearchPanel = styled.div`
  display: flex;
  padding: 5px 10px;
  background-color: #222;
  border-bottom: none;

  input {
    flex: 1;
    border: none;
    padding: 5px 10px;
    color: #ffffff;
    background-color: #ffffff10;
    border-radius: 5px;

    &::placeholder {
      color: #777777;
    }
  }

  button {
    margin-left: 10px;
    background-color: transparent;
    border: none;
  }
`;

export const Functions = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Item = styled.div`
  border-bottom: 1px solid #555;
  background-color: ${props => (props.selected ? '#555' : '#333 ')};
  padding: 20px;
  color: #f0f0f0;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    svg:hover {
      cursor: pointer;
    }
  }

  &:hover {
    background-color: #ffffff40;
    cursor: pointer;
  }
`;
