const createMenu = ({ auxContextMenus = [], fieldName, isUserField }) => {
  const menus = [
    ...auxContextMenus,
    {
      title: 'EditQuery',
      prop: 'queryAux',
      type: 'queryEditor',
      visible: isUserField,
      disabled: false,
      fieldName,
    },
  ];

  return menus;
};

export default createMenu;
