import styled from 'styled-components';

export const Container = styled.div`
  /* position: absolute;
  bottom: 0;
  left: 0; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #ededed;
  border-top: 1px solid #dedede;
  padding: 5px 20px;
  align-items: center;
  justify-content: space-between;
  /*z-index: 99999;*/

  @keyframes showDiv {
    from {
      opacity: 0;
      bottom: -40px;
    }
    to {
      opacity: 1;
      bottom: 0px;
    }
  }
  animation: showDiv 0.5s, left 0.5s;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;

export const FieldName = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;
export const Field = styled.div`
  margin-right: 5px;
  display: flex;
  flex-direction: row;
`;
