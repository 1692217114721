import React from 'react';
import { TiDownload as IconDownload } from '@react-icons/all-files/ti/TiDownload';
import PropTypes from 'prop-types';
import { IconPanel } from './styles';
import downloadLink from '~/easy-components/Helpers/downloadLink';

function DownloadButton({ fileName, onGetToken }) {
  const onDownload = async () => {
    const token = await onGetToken();
    const url = `files/${token}/${fileName}`;
    downloadLink({ url, fileName });
  };

  return (
    <IconPanel onClick={() => onDownload()}>
      <IconDownload size={14} color="#3B8FB1" />
    </IconPanel>
  );
}

DownloadButton.propTypes = {
  onGetToken: PropTypes.func,
  fileName: PropTypes.string,
};

DownloadButton.defaultProps = {
  onGetToken: async () => '',
  fileName: '',
};

export default DownloadButton;
