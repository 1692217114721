import styled, { css } from 'styled-components';

export const SimpleListItem = styled.div`
  padding: 20px;
  line-height: 20px;
  transition: 0.2s;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;

  ${props =>
    props.selected &&
    css`
      background-color: #deeff6;
    `}

  &:hover {
    background-color: #deeff6;
  }
`;

export const CommandPanel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;
  background-color: #555;

  > div {
    overflow: auto;
    flex: 1;
    flex-direction: column;
    display: flex;
  }

  > div:first-child {
    margin-right: 2px;
  }
`;

export const ModalBody = styled.div`
  padding: 10px 20px;

  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f0f0f0;

  span {
    margin-bottom: ${props => (props.type === 'question' ? '25px' : 0)};
    color: #888;
  }

  @media (max-width: 500px) {
    padding: 20px;
  }
`;

export const Footer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  margin-left: 10px;
`;
