/* eslint-disable no-loop-func */
/* eslint-disable no-shadow */
/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/* eslint-disable no-new */
import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import { FaBuilding } from '@react-icons/all-files/fa/FaBuilding';

function Map({ center, zoom, onReady }, ref) {
  const faBuildingIcon = FaBuilding();
  const faBuildingIconPath = faBuildingIcon.props.children[0].props.d;

  const mapRef = useRef();
  let markers = [];

  const directionsService = new window.google.maps.DirectionsService();
  const directionsRenderer = new window.google.maps.DirectionsRenderer();

  const divHtmlStart = `<div  style="font-size: 0.8rem; font-family: 'Arial'; width: 120px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">`;

  const divHtmlEnd = `<div>`;

  /*  const generateIcon = ({
    start = null,
    end = null,
    color = null,
    number = null,
  }) => {
    if (start) {
      return new window.google.maps.MarkerImage(
        `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blue.png`,
        new window.google.maps.Size(32, 40),
        new window.google.maps.Point(0, 0),
        new window.google.maps.Point(22, 32)
      );
    }
    if (end) {
      return new window.google.maps.MarkerImage(
        `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_green.png`,
        new window.google.maps.Size(44, 40),
        new window.google.maps.Point(0, 0),
        new window.google.maps.Point(22, 32)
      );
    }

    return new window.google.maps.MarkerImage(
      `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_${color}${number}.png`,
      new window.google.maps.Size(22, 40),
      new window.google.maps.Point(0, 0),
      new window.google.maps.Point(22, 32)
    );
  }; */

  const deleteMarkers = (map = null) => {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  };

  async function getDirection({ directionsService, route }) {
    const direction = await directionsService.route(route);
    return direction;
  }

  const partsOfWaypoints = ({ route, maxWayPoints }) => {
    const parts = [];
    let waypointGroup = [];

    let lineCont = 1;

    for (let i = 0; i < route.waypoints.length; i++) {
      const wayPoint = route.waypoints[i];

      if (lineCont <= maxWayPoints) {
        waypointGroup.push(wayPoint);
        lineCont++;
      }

      if (lineCont > maxWayPoints || i === route.waypoints.length - 1) {
        parts.push(JSON.parse(JSON.stringify(waypointGroup)));
        waypointGroup = [];
        lineCont = 1;
      }
    }

    return parts;
  };

  // TODO: Ponto de customização
  function makeContentMaker({ auxInfo, index }) {
    const data = auxInfo.find(info => info.index === index);
    const content = `${divHtmlStart}
    <b>${data.docNum}</b> ${data.cardCode}
    <br/>${data.cardName}
    ${divHtmlEnd}`;
    return content;
  }

  function makeMarker(position, icon = null, content, map, label = null) {
    const marker = new window.google.maps.Marker({
      position,
      map,
      icon,
      label: label ? `${label}` : null,
    });

    markers.push(marker);

    // eslint-disable-next-line func-names
    window.google.maps.event.addListener(marker, 'click', function() {
      const infowindow = new window.google.maps.InfoWindow({
        content,
        position,
      });
      infowindow.open(map);
    });
  }

  function legsMarker(route, directions, map) {
    const { auxInfo } = route;
    if (auxInfo === null || auxInfo === undefined) {
      return;
    }

    const makerOriginPosition = directions.routes[0].legs[0].start_location;

    const iconStartEnd = {
      path: faBuildingIconPath,
      fillColor: '#5086c1',
      fillOpacity: 0.8,
      strokeWeight: 0.7,
      rotation: 0,
      scale: 0.07,
      strokeColor: '#fff',
      anchor: new window.google.maps.Point(220, 320),
    };

    makeMarker(
      makerOriginPosition,
      iconStartEnd,
      `${divHtmlStart}<b>${route.BPLName}</b></div>`,
      map
    );

    const { legs } = directions.routes[0];

    const legsFiltered = legs;

    let number = 2;

    for (let i = 0; i < route.waypoints.length; i++) {
      const info = makeContentMaker({ auxInfo, index: i });

      // const icon = generateIcon({ color: 'orange', number });

      const location = legsFiltered[i].end_location;

      makeMarker(location, null, info, map, number);

      number++;
    }

    makeMarker(
      legsFiltered[legsFiltered.length - 1].end_location,
      iconStartEnd,
      `${divHtmlStart}<b>${route.BPLName}</b>${divHtmlEnd}`,
      map
    );
  }

  async function renderAllPartsOfMap({
    directionsService,
    directionsRenderer,
    route,
    maxWayPoints,
    map,
  }) {
    const waypointsDivisibled = partsOfWaypoints({ route, maxWayPoints });

    let directions = null;

    if (waypointsDivisibled.length === 0) {
      const direction = await getDirection({
        directionsService,
        route,
      });

      directions = direction;
    } else {
      const waypointGroups = waypointsDivisibled.map((waypointGroup, idx) => {
        // Verifica quem vai ser o primeiro ponto

        if (idx === 0) {
          waypointGroup.unshift({
            location: route.origin.trim(),
            stopover: true,
          });
        }
        // Verifica qual é o ultimo ponto do grupo de waypoint

        if (idx < waypointsDivisibled.length - 1) {
          const nextWaypointGroup = waypointsDivisibled[idx + 1];

          waypointGroup.push(nextWaypointGroup[0]);
        } else {
          waypointGroup.push({
            location: route.destination.trim(),
            stopover: true,
          });
        }

        return waypointGroup;
      });

      for (let i = 0; i < waypointGroups.length; i++) {
        const waypointGroup = waypointGroups[i];

        const origin = waypointGroup.shift().location;
        const destination = waypointGroup.pop().location;

        const newRoutePoint = {
          origin,
          destination,
          waypoints: waypointGroup,
          optimizeWaypoints: false,
          travelMode: 'DRIVING',
        };
        // eslint-disable-next-line no-await-in-loop
        const direction = await getDirection({
          directionsService,
          route: newRoutePoint,
        });

        if (directions === null) {
          directions = direction;
        } else {
          direction.geocoded_waypoints.forEach(geo => {
            directions.geocoded_waypoints.push(geo);
          });

          direction.routes[0].legs.forEach(leg => {
            directions.routes[0].legs.push(leg);
          });

          let lastId = directions.routes[0].waypoint_order.length;

          direction.routes[0].waypoint_order.forEach(() => {
            directions.routes[0].waypoint_order.push(lastId);
            lastId++;
          });
        }
      }
    }

    legsMarker(route, directions, map);

    const showMarkers = !!(
      route.auxInfo === null || route.auxInfo === undefined
    );

    directionsRenderer.setOptions({
      suppressMarkers: !showMarkers,
    });

    directionsRenderer.setDirections(directions);

    return directions;
  }

  const initMap = useCallback(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      center,
      zoom,
    });

    directionsRenderer.setMap(map);

    if (onReady) onReady(ref.current);
  }, [center, directionsRenderer, onReady, ref, zoom]);

  useEffect(() => {
    initMap();
  }, [initMap]);

  useImperativeHandle(ref, () => {
    return {
      setRoute: async route => {
        const map = new window.google.maps.Map(mapRef.current, {
          center,
          zoom,
        });

        directionsRenderer.setMap(map);

        const data = await renderAllPartsOfMap({
          directionsService,
          directionsRenderer,
          route,
          maxWayPoints: 25,
          map,
        });

        return data;
      },
      clearRoute: async () => {
        directionsRenderer.setMap(null);
        deleteMarkers();
        markers = [];
      },
    };
  });

  return <div ref={mapRef} id="googleMapId" />;
}

export default forwardRef(Map);
