const createMenu = () => {
  const menus = [
    {
      title: 'onSelectEvent',
      type: 'event',
      prop: 'onSelectEvent',
      disabled: true,
      value: false,
    },
    {
      title: 'onNewEvent',
      prop: 'onNewEvent',
      type: 'event',
      visible: true,
      disabled: true,
    },
  ];

  return menus;
};

export default createMenu;
