import React, {
  useContext,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { isDesktop } from 'react-device-detect';
import { Html5Qrcode } from 'html5-qrcode';
import { useDispatch, useSelector } from 'react-redux';

import Collector from '../Collector';
import Manual from '../Manual';
import MobileCam from '../MobileCam4';
import TabContent from '~/easy-components/TabContent';
import ComponentContext from '../../context';
import Switch from '~/easy-components/Switch';
import { setConfigurations } from '~/store/modules/configurations/actions';

import { ComputerModal } from './styles';

let html5QrCode;

const songs = require.context('./songs', true);

function ModalContent(_props, ref) {
  const dispatch = useDispatch();
  const modalCaptureConfigurations = useSelector(
    ({ configurations }) => configurations.modalCapture
  );

  const { inputManualRef, inputCollectorRef, t, confirm } = useContext(
    ComponentContext
  );

  const mobileCamRef = useRef();

  const focusOnCollector = useCallback(() => {
    if (inputCollectorRef.current) {
      inputCollectorRef.current.focus();
    }
  }, [inputCollectorRef]);

  const focusOnManual = useCallback(() => {
    if (inputManualRef.current) {
      inputManualRef.current.focus();
    }
  }, [inputManualRef]);

  if (isDesktop) {
    setTimeout(focusOnManual, 400);
  }

  const play = type => {
    let audio;
    let song;

    switch (type) {
      case 'error':
        audio = songs(`./beepError.wav`);
        song = new Audio(audio);
        song.play();

        break;

      default:
        audio = songs(`./beepA.wav`);
        song = new Audio(audio);
        song.play();
        break;
    }

    /* const context = new AudioContext();
    const oscillator = context.createOscillator();
    const contextGain = context.createGain();

    oscillator.type = type;
    oscillator.connect(contextGain);
    contextGain.connect(context.destination);
    oscillator.start(0);

    contextGain.gain.exponentialRampToValueAtTime(
      0.00001,
      context.currentTime + 1
    ); */
  };

  const startCamera = () => {
    const square = { fps: 10, qrbox: { width: 300, height: 300 } };

    // if (!html5QrCode) {
    html5QrCode = new Html5Qrcode('reader');
    // }

    setTimeout(async () => {
      html5QrCode.start(
        { facingMode: 'environment' },
        square,
        async (decodedText, _decodedResult) => {
          if (window.navigator && window.navigator.vibrate) {
            window.navigator.vibrate(800);
          }

          play('capture');

          html5QrCode
            .stop()
            // eslint-disable-next-line no-unused-vars
            .then(res => {
              html5QrCode.clear();
            })
            .catch(() => {
              play('error');
            });

          await confirm({
            tagValue: decodedText,
            elementRef: inputCollectorRef,
            isCamera: true,
          });
        }
      );
    }, 100);
  };

  useImperativeHandle(ref, () => {
    return {
      focus: () => {
        focusOnCollector();
        focusOnManual();
      },
      startCamera: () => {
        setTimeout(() => {
          startCamera();
        }, 1000);
      },
      songError: () => {
        play('error');
      },
    };
  });

  const onMobileCamCapture = d => {
    alert(d);
  };

  const setCacheTabIndex = tabIndex => {
    dispatch(
      setConfigurations({
        modalCapture: {
          activeTab: tabIndex,
        },
      })
    );
  };

  return (
    <Switch
      mobile={
        <TabContent
          activeTab={modalCaptureConfigurations.activeTab}
          onTabClick={(tab, idx) => {
            setCacheTabIndex(idx);

            const { name } = tab;

            switch (name) {
              case 'ManualTab':
                setTimeout(focusOnManual, 100);

                break;

              case 'CollectorTab':
                setTimeout(focusOnCollector, 100);
                break;

              case 'MobileCamTab':
                // eslint-disable-next-line no-case-declarations
                startCamera();
                break;

              default:
                break;
            }
          }}
          onChangeTab={tab => {
            const { name } = tab;

            switch (name) {
              case 'ManualTab':
                setTimeout(focusOnManual, 400);
                break;

              case 'CollectorTab':
                setTimeout(focusOnCollector, 400);
                break;

              default:
                break;
            }
          }}
        >
          <Collector
            name="CollectorTab"
            title={t('Collector')}
            icon="RiSignalTowerFill"
          />
          <Manual name="ManualTab" title={t('Manual')} icon="FaRegKeyboard" />
          <MobileCam
            ref={mobileCamRef}
            name="MobileCamTab"
            title={t('MobileCam')}
            icon="GoDeviceMobile"
            onConfirm={onMobileCamCapture}
          />
        </TabContent>
      }
      computer={
        <ComputerModal>
          <Manual title={t('Manual')} />
        </ComputerModal>
      }
    />
  );
}

export default forwardRef(ModalContent);
