import styled from 'styled-components';

export const Container = styled.div`
  padding: '10px 20px';

  @media (max-width: 500px) {
    padding: 20px;
  }
`;

export const TextBox = styled.textarea`
  margin-top: 10px;
  padding: 10px;
  border-radius: 2px;
  width: 100%;
  min-height: 150px;

  border: none;
  resize: none;
  background-color: #fff;

  @media (max-width: 500px) {
    flex: 1;
  }
`;
