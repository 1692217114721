/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import createGlobalFunctions from '~/easy-components/Helpers/createGlobalFunctions';
import request from '../../Request';
import CompanyService from '../../../services/CompanyService';

class ReportQueriesService {
  async get(hash) {
    return request({
      url: `APP/CRM/ReportQueries/${hash}`,
    });
  }

  async list(filter, auxFilters) {
    let filters = '';

    for (const prop in auxFilters) {
      filters += `&${prop}=${
        auxFilters[prop] !== null && auxFilters[prop] !== undefined
          ? auxFilters[prop]
          : ''
      }`;
    }
    return request({
      url: `APP/CRM/ReportQueries?$filter=${filter}${filters}`,
    });
  }

  async create({
    name,
    icon,
    description,
    menu,
    menuId,
    visible,
    command,
    outputType,
    settings,
    permissionType,
    users,
    reportType,
    reportFileName,
    reportFile,
    reportBaseId,
    applicationId,
    systemQueryCode,
    layouts,
    widgets,
    emailSettings,
  }) {
    const response = await request({
      url: `APP/CRM/ReportQueries`,
      method: 'POST',
      largeData: {
        name,
        description,
        menu,
        menuId,
        visible,
        command,
        settings,
        outputType,
        icon,
        permissionType,
        users,
        reportType,
        reportFileName,
        reportBaseId,
        applicationId,
        systemQueryCode,
        layouts,
        widgets,
        emailSettings,
      },
      file: reportFile,
    });

    return response;
  }

  async update({
    hash,
    icon,
    name,
    description,
    menu,
    menuId,
    visible,
    command,
    outputType,
    settings,
    permissionType,
    users,
    reportType,
    reportFileName,
    reportFile,
    reportBaseId,
    applicationId,
    systemQueryCode,
    layouts,
    widgets,
    emailSettings,
  }) {
    const response = await request({
      url: `APP/CRM/ReportQueries/${hash}`,
      method: 'PUT',
      largeData: {
        name,
        description,
        menu,
        menuId,
        visible,
        command,
        settings,
        outputType,
        icon,
        permissionType,
        users,
        reportType,
        reportFileName,
        reportBaseId,
        applicationId,
        systemQueryCode,
        layouts,
        widgets,
        emailSettings,
      },
      file: reportFile,
    });

    return response;
  }

  async createOrUpdate({
    hash,
    name,
    menu,
    description,
    menuId,
    visible,
    command,
    settings,
    outputType,
    icon,
    permissionType,
    users,
    reportType,
    reportFileName,
    reportFile,
    reportBaseId,
    applicationId,
    systemQueryCode,
    layouts,
    widgets,
    emailSettings,
  }) {
    if (hash === '' || hash === null || hash === undefined)
      return this.create({
        name,
        description,
        menuId,
        menu,
        visible,
        command,
        settings,
        outputType,
        icon,
        permissionType,
        users,
        reportType,
        reportFileName,
        reportFile,
        reportBaseId,
        applicationId,
        systemQueryCode,
        layouts,
        widgets,
        emailSettings,
      });

    return this.update({
      hash,
      name,
      menu,
      description,
      menuId,
      visible,
      command,
      settings,
      outputType,
      icon,
      permissionType,
      users,
      reportType,
      reportFileName,
      reportFile,
      reportBaseId,
      applicationId,
      systemQueryCode,
      layouts,
      widgets,
      emailSettings,
    });
  }

  async getReportParams(hash) {
    return request({
      url: `APP/CRM/ReportQueries/${hash}/report_params`,
    });
  }

  async getParameters(hash) {
    return request({
      url: `APP/CRM/ReportQueries/${hash}/parameters`,
    });
  }

  async getSettings(hash) {
    const settings = await request({
      url: `APP/CRM/ReportQueries/${hash}/settings`,
    });

    createGlobalFunctions(settings || {});

    return settings;
  }

  async execute(hash, params) {
    return request({
      url: `APP/CRM/ReportQueries/${hash}/execute`,
      method: 'POST',
      data: {
        params,
      },
    });
  }

  async check_permission(hash) {
    return request({
      url: `APP/CRM/ReportQueries/${hash}/check_permission`,
    });
  }

  async delete(hash) {
    return request({
      url: `APP/CRM/ReportQueries/${hash}`,
      method: 'DELETE',
    });
  }

  async downloadCrystalReport(hash) {
    const token = await CompanyService.getCompanyToken();

    window.open(
      `${process.env.REACT_APP_SERVER_URL}/files/reports/download/${token}/crystalReport/${hash}`
    );
  }

  async sendEmail({ hash, emailData, params }) {
    return request({
      url: `APP/CRM/ReportQueries/${hash}/SendMail`,
      method: 'POST',
      data: { emailData, params },
    });
  }

  async listKanban(filter, auxFilters, queryCode) {
    let filters = '';

    for (const prop in auxFilters) {
      filters += `&${prop}=${auxFilters[prop]}`;
    }
    return request({
      url: `APP/CRM/ReportQueries/listKanban?$queryCode=${queryCode}&$filter=${filter}${filters}`,
    });
  }

  async listPrintLayouts(filter) {
    return request({
      method: 'GET',
      url: `APP/CRM/ReportQueries/PrintLayouts?$filter=${filter}`,
    });
  }
}

export default new ReportQueriesService();
