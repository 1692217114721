import styled from 'styled-components';

export const Container = styled.div`
  /* width: 300px; */
  border-bottom: 1px solid #f0f0f0;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;

  svg {
    width: 30px;
    margin: 20px;
    align-self: center;
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 20px;

  div {
    /* white-space: nowrap;*/
    text-overflow: ellipsis;
    overflow: hidden;
  }
  h1 {
    font-size: 1.1rem;
    color: #888;
    font-weight: 600;
  }

  h2 {
    font-size: 1rem;
    font-weight: 600;
    color: #888;
  }

  span {
    white-space: nowrap;
    font-size: 0.9rem;
    color: #888;
  }
`;

export const VStatus = styled.div`
  width: 6px;
  background-color: ${props => props.backgroundColor};
`;

export const Body = styled.div`
  padding: 20px;
  flex: 1;
  word-wrap: break-word;
`;

export const Menu = styled.div`
  padding: 20px;
`;

export const Span = styled.span`
  width: 200px;
  white-space: nowrap; 
  overflow:hidden;
  text-overflow: ellipsis;
`;