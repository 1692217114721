export const SET_CELL_READ_ONLY_TYPE = 'SET_CELL_READ_ONLY';

const setCellReadOnly = (rowKeyValue, columnKey, isReadOnly) => ({
  type: SET_CELL_READ_ONLY_TYPE,
  payload: {
    columnKey,
    rowKeyValue,
    isReadOnly,
  },
});

export default setCellReadOnly;
