/* eslint-disable import/no-cycle */
import RequestService from './RequestService';
import CompanyService from './CompanyService';
import request from '../applications/Request';

class QueryService {
  async create(data) {
    const response = await RequestService.request({
      url: `queries`,
      method: 'POST',
      data,
      token: CompanyService.getCompanyToken(),
    });

    return response;
  }

  async list() {
    const response = await RequestService.request({
      url: `queries`,
      method: 'GET',
      token: CompanyService.getCompanyToken(),
    });

    return response;
  }

  async get(id) {
    const response = await RequestService.request({
      url: `queries/${id}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async getByCode(code) {
    const response = await RequestService.request({
      url: `queries/code/${code}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async getCommand(queryId, connectionTypeId) {
    const queryConnection = await RequestService.request({
      url: `query_connection_types?$filter=queryId eq ${queryId} and connectionTypeId eq ${connectionTypeId}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    if (queryConnection.length > 0) {
      const companyQueries = await RequestService.request({
        url: `company_queries?$filter=queryConnectionId eq ${queryConnection[0].id}`,
        method: 'GET',
        token: await CompanyService.getCompanyToken(),
      });

      return {
        queryConnectionType: queryConnection[0],
        customCommand:
          companyQueries.response.length > 0 ? companyQueries.response[0] : '',
        code: companyQueries.code || '',
      };
    }

    return null;
  }

  /**
   * Executa uma consulta SQL
   * @param {Number} applicationGroupId ID do grupo de aplicação (CRM = 1)
   * @param {String} command Comando SQL
   * @param {Object} dataToReplace Dados para serem substituidos na consulta
   * @param {Object} props Contexto atual
   * @param {String} props.companyToken Token da companhia
   * @param {String} props.appToken Token da aplicação
   * @returns {Promise} resultado da consulta
   */
  async execute(applicationGroupId, command, dataToReplace, props = {}) {
    const { companyToken } = props;

    const response = await RequestService.request({
      url: `query_execution/${applicationGroupId}`,
      method: 'POST',
      largeData: { command, dataToReplace },
      token: companyToken || CompanyService.getCompanyToken(),
    });

    return response;
  }

  /**
   * Avalia uma WHERE CLAUSE
   * @param {Number} applicationGroupId ID do grupo de aplicação (CRM = 1)
   * @param {String} whereClause Expressão WHERE
   * @param {Object} dataToReplace Dados para serem substituidos na consulta
   * @param {Object} props Contexto atual
   * @param {String} props.companyToken Token da companhia
   * @param {String} props.appToken Token da aplicação
   * @returns {Promise<boolean>} resultado da expressão
   */
  async evaluateWhereClause(
    applicationGroupId,
    whereClause,
    dataToReplace,
    props = {}
  ) {
    const { companyToken } = props;

    const response = await RequestService.request({
      url: `query_execution/${applicationGroupId}/where_clause`,
      method: 'POST',
      data: { whereClause, dataToReplace },
      token: companyToken || CompanyService.getCompanyToken(),
    });

    return response;
  }

  /**
   * Executa uma string de select
   * @param {Number} applicationGroupId ID do grupo de aplicação (CRM = 1)
   * @param {String} select Expressão SELECT
   * @param {Object} dataToReplace Dados para serem substituidos na consulta
   * @param {Object} props Contexto atual
   * @param {String} props.companyToken Token da companhia
   * @param {String} props.appToken Token da aplicação
   * @returns {Promise<any[]>} resultado da expressão
   */
  async executeSelect(applicationGroupId, select, dataToReplace, props = {}) {
    const { companyToken } = props;

    const response = await RequestService.request({
      url: `query_execution/${applicationGroupId}/select`,
      method: 'POST',
      data: { select, dataToReplace },
      token: companyToken || CompanyService.getCompanyToken(),
    });

    return response;
  }

  /**
   * Executa uma consulta do portal através de um código.
   * @template ResponseData
   * @param {String} queryCode Código da consulta
   * @param {{[key: string]: number | string}} params Dados para serem substituidos na Consulta
   * @returns {Promise<ResponseData[]>} Promise do retorno da consulta
   */
  async executeByCode(queryCode, params) {
    const response = await request({
      url: `query_execution/1/code/${queryCode}`,
      method: 'post',
      data: params,
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async getCommandByCode(queryCode) {
    const response = await request({
      url: `query_execution/1/code/${queryCode}`,
      method: 'get',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }
}

export default new QueryService();
