import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left !important;

  label {
    color: #8197a5;
    margin: 5px 0;
  }
`;

export const InputElement = styled.input`
  background-color: ${props => (props.readOnly ? '#ebebe4' : '')};
`;
