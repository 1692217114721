/* eslint-disable no-useless-catch */
/* eslint-disable no-case-declarations */
/* eslint-disable no-empty */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useContext, useEffect } from 'react';
import {
  Container,
  Content,
  WaitingReader,
  Message,
  TouchHere,
} from './styles';
import ComponentContext from '../../context';
// import DebounceEvent from '~/easy-components/DebounceEvent';

function Collector() {
  const {
    confirm,
    t,
    inputCollectorRef,
    message,
    auxInfo,
    backgroundColor,
  } = useContext(ComponentContext);

  useEffect(() => {
    setTimeout(() => {
      if (inputCollectorRef.current) {
        inputCollectorRef.current.focus();
      }
    }, 400);
  }, [inputCollectorRef]);

  return (
    <Container backgroundColor={backgroundColor}>
      <Content
        onClick={() => {
          if (inputCollectorRef.current) {
            inputCollectorRef.current.value = '';
            inputCollectorRef.current.focus();
          }
        }}
      >
        <WaitingReader
          onClick={() => {
            if (inputCollectorRef.current) {
              inputCollectorRef.current.value = '';
              inputCollectorRef.current.focus();
            }
          }}
        >
          <TouchHere />
          <Message>
            {t('WaitingForReading')}
            <br />
            {message}
          </Message>
        </WaitingReader>
        <textarea
          ref={inputCollectorRef}
          tabIndex={0}
          onFocus={() => {
            if (inputCollectorRef.current) {
              inputCollectorRef.current.value = '';
              const element = inputCollectorRef.current;
              setTimeout(() => {
                element.setAttribute('inputmode', 'none');
              }, 5);
            }
          }}
          onBlur={() => {
            if (inputCollectorRef.current) {
              const element = inputCollectorRef.current;
              element.setAttribute('inputmode', '');
            }
          }}
          onKeyDown={async e => {
            switch (e.keyCode) {
              case 13: // ENTER
                e.preventDefault();

                const tagValue = inputCollectorRef.current.value.trim();

                // TODO: Deixando capturar mais de uma vez antes de obter o resultado da primeira tentantiva

                if (tagValue != null && tagValue !== '') {
                  try {
                    // inputCollectorRef.current.readOnly = true;
                    await confirm({ tagValue, elementRef: inputCollectorRef });
                  } catch (err) {
                    throw err;
                  } finally {
                    // inputCollectorRef.current.readOnly = false;
                  }
                }

                if (inputCollectorRef.current) {
                  inputCollectorRef.current.value = '';
                }
                return false;

              default:
                return true;
            }
          }}
        />
      </Content>
      {auxInfo}
    </Container>
  );
}

export default Collector;
