const baseDocuments = [
  {
    value: '23',
    label: 'SalesQuotations',
  },
  {
    value: '17',
    label: 'SalesOrders',
  },
  {
    value: '15',
    label: 'Deliveries', // 'Entrega de mercadoria'
  },
  {
    value: '234000031',
    label: 'ReturnRequests',
  },
  {
    value: '16',
    label: 'Returns', // 'Devoluções'
  },
  {
    value: '13',
    label: 'SalesInvoices',
  },
  {
    value: '14',
    label: 'CreditMemo', // 'Devs. Nota Fiscais de Saida'
  },
  {
    value: '203',
    label: 'SalesDownPayment', // 'Adiantamento de cliente',
  },
  {
    value: '1470000113',
    label: 'PurchaseRequests',
  },
  {
    value: '540000006',
    label: 'PurchaseQuotations',
  },
  {
    value: '22',
    label: 'PurchaseOrders',
  },
  {
    value: '20',
    label: 'GoodsReceiptPO', // 'Recebimento de mercadorias',
  },
  {
    value: '234000032',
    label: 'GoodsReturnRequest', // 'Pedido de devolução de mercadorias',
  },
  {
    value: '21',
    label: 'GoodsReturn', // 'Devolução de mercadorias',
  },
  {
    value: '18',
    label: 'PurchaseInvoices', // 'Notas Fiscais de Entrada',
  },
  {
    value: '19',
    label: 'CreditMemos', // 'Devolução da nota fiscal de entrada',
  },
  {
    value: '69',
    label: 'LandedCosts', // 'Despesas de importação',
  },
  {
    value: '204',
    label: 'PurchaseDownPayment', // 'Adiantamento para fornecedor',
  },
  {
    value: '24',
    label: 'IncomingPayments', // 'Contas a receber',
  },
  {
    value: '46',
    label: 'OutgoingPayments', // 'Contas a pagar',
  },
  {
    value: '25',
    label: 'Deposits', // 'Depósitos',
  },
  {
    value: '57',
    label: 'ChecksPayment', // 'Cheques para pagamento',
  },
  {
    value: '30',
    label: 'JournalEntries', // 'Lançamentos contábeis manuais',
  },
  {
    value: '1250000001',
    label: 'InventoryTransferRequests', // 'Pedido de transferência de estoque',
  },
  {
    value: '67',
    label: 'InventoryTransfer', // 'Transferência de estoque',
  },
  {
    value: '60',
    label: 'GoodsIssue', // 'Saída de mercadorias',
  },
  {
    value: '59',
    label: 'GoodsReceipt', // 'Entrada de mercadoria',
  },
  {
    value: '162',
    label: 'InventoryRevaluation', // 'Reavaliação do estoque',
  },
  {
    value: '202',
    label: 'ProductionOrders', // 'Ordens de produção',
  },
  {
    value: '4',
    label: 'Items',
  },
  {
    value: '1320000012',
    label: 'CampaignManagement', // 'Gerenciamento de campanhas',
  },
  {
    value: '1250000025',
    label: 'BlanketAgreements', // 'Contrato guarda-chuva de vendas',
  },
  {
    value: '97',
    label: 'SalesOpportunities', // 'Oportunidade de vendas',
  },
];
export const getDocumentLabelFromCode = code => {
  const document = baseDocuments.filter(doc => doc.value === code);
  const label = document.length === 0 ? 'None' : document[0].label;
  return label;
};
