/* eslint-disable import/no-cycle */
/* eslint-disable react/button-has-type */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';
import ModalHandle from '~/easy-components/ModalHandle';
import { Container } from './styles';
import HeaderPage from '~/easy-components/HeaderPage';
import Button from '~/easy-components/Button';
import FlexSpace from '~/easy-components/FlexSpace';
import FooterPage from '~/easy-components/FooterPage';
import colors from '~/styles/colors';
import useLocale from '~/hooks/useLocale';
import Fields from './Fields';

// eslint-disable-next-line no-unused-vars
function UserfieldModal(_, ref) {
  const t = useLocale('_Global');

  const modalRef = useRef();
  const fieldsRef = useRef();

  const [data, setData] = useState({});

  useImperativeHandle(ref, () => {
    return {
      show: ({ title, menu, target, onConfirm }) => {
        setData({ title, menu, target, onConfirm });

        modalRef.current.handleOpen();
      },
      close: () => {
        modalRef.current.handleClose();
      },
    };
  });

  return (
    <ModalHandle
      ref={modalRef}
      contentStyle={{ style: { width: 'auto', height: 'auto' } }}
    >
      <Container>
        <HeaderPage
          backgroundColor={colors.headerBg}
          color={colors.headerTitle}
          hideMainMenu
        >
          {data.title}
        </HeaderPage>
        <Fields ref={fieldsRef} data={data} />
        <FooterPage>
          <FlexSpace />
          <Button
            onClick={() => {
              modalRef.current.handleClose();
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            buttonType="Emphasized"
            onClick={async () => {
              const userField = fieldsRef.current.getValue();
              await data.onConfirm(userField);
              modalRef.current.handleClose();
            }}
          >
            {t('Add')}
          </Button>
        </FooterPage>
      </Container>
    </ModalHandle>
  );
}

export default forwardRef(UserfieldModal);
