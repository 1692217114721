import { v1 as uuidv1 } from 'uuid';
import { isMobile } from 'react-device-detect';
import history from '~/routes/history';
import { openExternalData, openSide } from '~/store/modules/pages/actions';
import { showMenu, setRoute } from '~/store/modules/mainMenu/actions';

export const showPage = ({
  route,
  externalData,
  dispatch,
  showList,
  settings,
}) => {
  history.push(`/${route}`);

  // TODO: Não desestruturar o externalData pois quando ele é uma string, ele transforma em um array.

  setTimeout(() => {
    if (externalData) {
      dispatch(openExternalData({ pageId: route, externalData, settings }));
    }

    if (isMobile && externalData) {
      dispatch(showMenu(false));
      dispatch(
        openSide({
          pageId: route,
          isSideOpen: !!showList,
        })
      );
    }
  }, 100);
};

export const generateExecutionHash = () => uuidv1();

export const showReport = ({ reportCode, externalData, dispatch }) => {
  const executionHash = generateExecutionHash();
  const reportRoute = `ExecuteReport/${reportCode}`;
  const route = `${reportRoute}/${executionHash}`;

  dispatch(
    openExternalData({
      pageId: route,
      externalData,
    })
  );

  history.push(`/${route}`);
  dispatch(setRoute(reportRoute));
};
