/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import request from '../../Request';

class ImplementationProjectService {
  async get(id) {
    return request({
      url: `APP/CRM/ImplementationProjects/${id}`,
    });
  }

  async list(filter, auxFilters) {
    let filters = '';

    for (const prop in auxFilters) {
      filters += `&${prop}=${
        auxFilters[prop] !== null && auxFilters[prop] !== undefined
          ? auxFilters[prop]
          : ''
      }`;
    }
    return request({
      url: `APP/CRM/ImplementationProjects?$filter=${filter}${filters}`,
    });
  }

  /**
   * @param {object} param
   * @param {string} param.type
   * @param {string} param.keyCode
   * @returns {string}
   */
  async getImplementation({ type, keyCode }) {
    return request({
      url: `APP/CRM/ImplementationProjects/${type}/implementation/${keyCode}`,
    });
  }

  /**
   * @param {object} param
   * @param {string} param.type
   * @param {string} param.keyCode
   * @returns {string}
   */
  async exists({ type, keyCode }) {
    return request({
      url: `APP/CRM/ImplementationProjects/${type}/exists/${keyCode}`,
    });
  }

  async create(data) {
    const response = await request({
      url: `APP/CRM/ImplementationProjects`,
      method: 'POST',
      largeData: data,
    });

    return response;
  }

  async update(id, data) {
    const response = await request({
      url: `APP/CRM/ImplementationProjects/${id}`,
      method: 'PUT',
      largeData: data,
    });

    return response;
  }

  async delete(id) {
    await request({
      url: `APP/CRM/ImplementationProjects/${id}`,
      method: 'DELETE',
    });
  }

  async createOrUpdate(data) {
    const { id, ...rest } = data;

    if (id === '' || id === null || id === undefined) {
      return this.create(rest);
    }
    return this.update(id, rest);
  }

  async execute(data) {
    await request({
      url: `APP/CRM/ImplementationProjects/execute`,
      method: 'POST',
      largeData: data,
    });
  }

  async listToLink() {
    return request({
      url: `APP/CRM/ImplementationProjects/Link`,
    });
  }

  async addItem(implementationProjectId, { type, keyCode, code }) {
    const response = await request({
      url: `APP/CRM/ImplementationProjects/${implementationProjectId}/Items`,
      method: 'POST',
      largeData: {
        type,
        keyCode,
        code,
      },
    });

    return response;
  }
}

export default new ImplementationProjectService();
