/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import RequestService from './RequestService';
import CompanyService from './CompanyService';
import request from '../applications/Request';
// import LocalStorage from './LocalStorage';

class ApplicationGroupService {
  async list() {
    const response = await RequestService.request({
      url: `application_groups`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async get(applicationGroupId) {
    const response = await RequestService.request({
      url: `application_groups/${applicationGroupId}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async create(data) {
    const response = await RequestService.request({
      url: `company_groups`,
      method: 'POST',
      data,
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async update(id, data) {
    const response = await RequestService.request({
      url: `company_groups/${id}`,
      method: 'PUT',
      data,
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async getApprovalsRulesFields() {
    return request({
      url: `application_groups/approvals_rules_fields`,
    });
  }

  async getQueries({ filter, auxFilters }) {
    let filters = '';

    for (const prop in auxFilters) {
      filters += `&${prop}=${auxFilters[prop]}`;
    }

    const response = await RequestService.request({
      url: `application_group_queries?$filter=${filter || ''}${filters}`,
      data: auxFilters,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }
}

export default new ApplicationGroupService();
