import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - 45px);
  overflow-y: hidden;

  > div {
    align-items: initial;
    flex: 1;
  }
`;

export const ElementContent = styled.div`
  padding: 10px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    max-width: 100%;

    h1 {
      max-width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > div {
      display: none;
      align-items: center;
      justify-content: center;

      @media (max-width: 500px) {
        display: flex;
      }
    }
  }

  &:hover {
    header {
      > div {
        display: inline;
      }
    }
  }
`;

export const Status = styled.div`
  width: 5px;
  min-width: 5px;
  background-color: ${props => props.color || '#AAA'};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  svg {
    cursor: pointer;
    margin-right: 10px;
  }
`;
