/* eslint-disable import/no-cycle */
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import Icon from '~/easy-components/Icon';
import { Container, SearchPanel, Functions, Item } from './styles';
import useHelpers from '~/hooks/useHelpers';
import useLocale from '~/hooks/useLocale';
import { normalizeName } from '~/hooks/useUiDesigner';
import debounceEvent from '~/easy-components/DebounceEvent';

function List(
  { selectedFunctionName, globalFunctions, onSelectedItem, onAdd, onDelete },
  ref
) {
  const t = useLocale('_Global');

  const [data, setData] = useState(globalFunctions);
  const [filteredData, setFilteredData] = useState(globalFunctions);

  const {
    dynamicFunctionProps: { showModalFields },
  } = useHelpers();

  const handlerAdd = () => {
    showModalFields({
      title: t('NewFunction'),
      fields: [
        {
          name: 'name',
          label: t('FunctionName'),
          type: 'text',
        },
      ],
      onConfirm: async ({ name }) => {
        if (name) {
          const functionName = normalizeName(name);

          const newFunction = {
            name: functionName,
            handler: '',
          };

          onAdd(newFunction);
        }
      },
    });
  };

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return data;
    },
  }));

  useEffect(() => {
    setData(globalFunctions);
    setFilteredData(globalFunctions);
  }, [globalFunctions]);

  const onSearch = event => {
    const value = event.target.value.toLowerCase();
    const filtered = data.filter(f => f.name.toLowerCase().includes(value));
    setFilteredData(filtered);
  };
  return (
    <Container>
      <SearchPanel>
        <input
          type="text"
          placeholder={t('Search')}
          onChange={debounceEvent(onSearch, 500)}
        />
        <button type="button" onClick={handlerAdd}>
          <Icon name="MdAddCircleOutline" size={24} color="#fff" />
        </button>
      </SearchPanel>
      <Functions>
        {filteredData.map(f => {
          return (
            <Item
              selected={selectedFunctionName === f.name}
              key={f.name}
              onClick={() => {
                onSelectedItem(f);
              }}
            >
              {f.name}
              <button
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();

                  onDelete(f);
                }}
              >
                <Icon name="TiDeleteOutline" size={24} color="#995555" />
              </button>
            </Item>
          );
        })}
      </Functions>
    </Container>
  );
}

export default forwardRef(List);
