import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f0f0f0;
  justify-content: center;

  svg {
    width: 30px;
    margin: 20px 0px 20px 20px;
    align-self: center;
  }

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  padding: 20px;
  flex: 1;
`;

export const Status = styled.div`
  width: 6px;
  background-color: ${props => props.color};
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 0px;

  h1 {
    overflow-wrap: anywhere;
    font-weight: 600;
  }

  span {
    font-size: 0.9rem;
    color: #888;
  }
`;
