/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';

import { MdChevronRight } from '@react-icons/all-files/md/MdChevronRight';
import { MdChevronLeft } from '@react-icons/all-files/md/MdChevronLeft';

import MasterDetail from '~/components/Pages/MasterDetail';

import Form from '~/easy-components/Form';
import Button from '~/easy-components/Button';
import Icon from '~/easy-components/Icon';
import ConfirmDialog from '~/easy-components/ConfirmDialog';
import TreatError from '~/easy-components/TreatError';

import useLocale from '~/hooks/useLocale';
import { Content, SimpleListItem } from './styles';

function Wizard(
  {
    route,
    keyField,
    onFinish,
    pages = [],
    settings,
    isReadOnly,
    isShowCancel,
    onCancel,
    showLoading,
    onChange,
    shareRoute,
    defaultData,
    executionPage,
    validateFinish,
    validatePrevious,
    executionFooterButtons,
    sideWidth,
    menus,
  },
  ref
) {
  const tapp = useLocale('Applications');
  const t = useLocale(shareRoute || route, settings.translations);
  const masterDetailRef = useRef();

  const disablePreviousPage = settings.disablePreviousPage || false;

  let data = defaultData;

  if (
    !data &&
    settings &&
    settings.defaultData &&
    JSON.stringify(settings.defaultData) !== '{}'
  ) {
    data = settings.defaultData;
  }

  const formRef = useRef();

  const [showExecutionPage, setShowExecutionPage] = useState(false);

  const [selectedPageIdx, setPageIdx] = useState(0);

  const [formData] = useState(data);
  const [, refreshForm] = useState(null);

  function setSelectedPageIdx(idx) {
    setPageIdx(idx);
    if (onChange) onChange({ idx });
  }

  const onSearch = async () => {
    return pages.map((page, index) => ({ ...page, index })) || [];
  };

  // eslint-disable-next-line no-unused-vars
  const onSelectedItem = ({ selectedItem }) => {
    return selectedItem;
  };

  const onSave = () => {};

  const onLoadExternalData = () => {
    return {};
  };

  const onGetDataToSave = () => {
    return {};
  };

  const nextPage = () => {
    if (selectedPageIdx < pages.length) {
      setSelectedPageIdx(selectedPageIdx + 1);
      masterDetailRef.current.executeSelectionItem(pages[selectedPageIdx + 1]);
    }
  };

  const goToPage = idx => {
    setShowExecutionPage(false);
    if (pages[idx]) {
      setSelectedPageIdx(idx);
      masterDetailRef.current.executeSelectionItem(pages[idx]);
    }
  };

  const previousPage = async () => {
    try {
      showLoading(true);
      if (validatePrevious) {
        const formFinishData = formRef.current.getData();
        await validatePrevious({
          data: formFinishData,
          form: formRef.current,
        });
      }

      setSelectedPageIdx(selectedPageIdx - 1);

      masterDetailRef.current.executeSelectionItem(pages[selectedPageIdx - 1]);
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
  };

  const finish = async () => {
    try {
      showLoading(true);

      const formFinishData = formRef.current.getData();

      const lastPage = pages[pages.length - 1];

      if (lastPage.onChange) {
        await lastPage.onChange({
          data: formFinishData,
          finalize: true,
        });
      }

      if (lastPage.onValidate) {
        const isValid = await lastPage.onValidate({
          data: formFinishData,
          formRef,
        });

        if (isValid !== undefined && !isValid) {
          return;
        }
      }
      showLoading(false);

      await onFinish({ data: formFinishData, formRef, lastPage });

      showLoading(true);
      if (validateFinish) {
        await validateFinish({ data: formFinishData, formRef });
      }

      setShowExecutionPage(true);
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
  };

  const refresh = () => {
    refreshForm(new Date());
  };

  const startFinish = () => {
    ConfirmDialog({
      title: 'Finalizar processo',
      message: 'Deseja realmente finalizar?',
      buttons: [
        {
          label: 'Sim',
          // eslint-disable-next-line no-use-before-define
          onClick: finish,
        },
        {
          label: 'Não',
        },
      ],
    });
  };

  async function onNextPage() {
    if (selectedPageIdx === pages.length - 1) return startFinish();
    try {
      showLoading(true);
      const nextData = formRef.current.getData();

      const page = pages[selectedPageIdx];

      if (page.onValidate) {
        const isValid = await page.onValidate({
          formRef,
          data: nextData,
        });

        if (!isValid) {
          return false;
        }
      }

      if (page.onChange) {
        await page.onChange({ formRef, data: nextData });
      }
      nextPage();
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
    return true;
  }

  async function onPreviousPage() {
    try {
      showLoading(true);

      const page = pages[selectedPageIdx];

      if (page.onPrevious) {
        await page.onPrevious({ formRef });
      }
      previousPage();
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
    return true;
  }

  async function setStep(step) {
    try {
      showLoading(true);
      const nextData = formRef.current.getData();

      const oldPage = pages[step - 1];

      if (oldPage && oldPage.onChange) {
        await oldPage.onChange({ formRef, data: nextData });
      }
      goToPage(step);
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
    return true;
  }

  useImperativeHandle(ref, () => {
    return {
      refresh,
      nextPage: async () => {
        await onNextPage();
      },
      previousPage,
      finish,
      setStep,
    };
  });

  useEffect(() => {
    masterDetailRef.current.executeSelectionItem(pages[0]);
  }, [pages]);

  return (
    <MasterDetail
      ref={masterDetailRef}
      formRef={formRef}
      customBarIcon={props => {
        return props.isSideOpen ? (
          <MdChevronLeft {...props} />
        ) : (
          <MdChevronRight {...props} />
        );
      }}
      showLoading={showLoading}
      settings={settings}
      isReadOnly={isReadOnly}
      title={tapp(route)}
      keyField={keyField}
      route={route}
      sideWidth={sideWidth || 350}
      onSelectedItem={onSelectedItem}
      onSearch={onSearch}
      listBackground="#627e8d"
      onRenderListItem={(item, idx, selected) => {
        return (
          <SimpleListItem selected={selected}>
            <section>
              <Icon name={item.icon} size={28} color="#fff" />
              <h1>{item.title}</h1>
            </section>
          </SimpleListItem>
        );
      }}
      onGetDataToSave={onGetDataToSave}
      onLoadExternalData={onLoadExternalData}
      onSave={onSave}
      showSearchPanel={false}
      isClickable={false}
      isShowNew={false}
      isShowSave={false}
      isShowDelete={false}
      isShowPrint={false}
      isExecutionPage={showExecutionPage}
      footerElements={() => {
        if (isReadOnly) return null;

        if (showExecutionPage && !executionFooterButtons) return null;

        if (showExecutionPage && executionFooterButtons)
          return executionFooterButtons();

        return (
          <>
            {isShowCancel && (
              <Button type="button" onClick={onCancel} buttonType="Reject">
                {t('Cancel')}
              </Button>
            )}

            <Button
              disabled={selectedPageIdx === 0 || disablePreviousPage}
              type="button"
              onClick={onPreviousPage}
            >
              {t('Previous')}
            </Button>

            <Button
              disabled={selectedPageIdx === pages.length - 1}
              buttonType="Emphasized"
              type="button"
              onClick={onNextPage}
            >
              {t('Next')}
            </Button>

            <Button
              disabled={selectedPageIdx !== pages.length - 1}
              buttonType="Emphasized"
              type="button"
              onClick={startFinish}
            >
              {t('Finish')}
            </Button>
          </>
        );
      }}
      menus={menus}
    >
      <Form ref={formRef} data={formData} selectedPageIdx={selectedPageIdx}>
        {pages.map((page, idx) => {
          return (
            <Content
              key={idx}
              isVisible={!showExecutionPage && idx === selectedPageIdx}
            >
              <page.content
                formRef={formRef}
                formData={formData}
                refresh={refresh}
                pageIsVisible={idx === selectedPageIdx}
              />
            </Content>
          );
        })}
        {showExecutionPage && executionPage && executionPage({ formRef })}
      </Form>
    </MasterDetail>
  );
}

export default forwardRef(Wizard);
