/* eslint-disable import/no-cycle */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Button from '~/easy-components/Button';

function ViewButtons({ panelRef, t }, ref) {
  const [viewType, setViewType] = useState('code');

  useImperativeHandle(ref, () => ({
    setViewType,
  }));

  return (
    <>
      <Button
        buttonType={viewType === 'code' ? 'Emphasized' : 'Default'}
        onClick={() => {
          panelRef.current.showComponent('code');
          setViewType('code');
        }}
      >
        {t('Code')}
      </Button>
      <Button
        buttonType={viewType === 'globalFunctions' ? 'Emphasized' : 'Default'}
        onClick={() => {
          panelRef.current.showComponent('globalFunctions');
          setViewType('globalFunctions');
        }}
      >
        {t('GlobalFunctions')}
      </Button>
      <Button
        buttonType={viewType === 'query' ? 'Emphasized' : 'Default'}
        onClick={() => {
          panelRef.current.showComponent('query');
          setViewType('query');
        }}
      >
        {t('Queries')}
      </Button>
    </>
  );
}

export default forwardRef(ViewButtons);
