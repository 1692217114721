/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useMemo,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
} from 'react';

import QueryService from '~/services/QueryService';
import TreatError from '~/easy-components/TreatError';

import CardTemplate from '~/easy-components/CardTemplate';

import { createSyncFunctionByString } from '~/easy-components/AsyncFunctionString';

import { Container } from './styles';
import Error from '../Error';
import Loader from '../Loader';

function Custom({ settings, data, widgetSettings, params, executeEvent }, ref) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState({});
  const updateTimeRef = useRef();

  const run = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await QueryService.execute(
        1,
        widgetSettings.query,
        params
      );

      setInfo(response);

      if (widgetSettings.updateTimeMinutes) {
        updateTimeRef.current = setTimeout(async () => {
          await run();
        }, widgetSettings.updateTimeMinutes * 1000 * 60);
      }
    } catch (e) {
      const errorDescription = TreatError.getDescription(e);
      setError(errorDescription);
    } finally {
      setIsLoading(false);
    }
  }, [params, widgetSettings.query, widgetSettings.updateTimeMinutes]);

  useEffect(() => {
    run();

    return () => {
      clearTimeout(updateTimeRef.current);
    };
  }, [run, data]);

  const template = useMemo(() => {
    try {
      const dynamicFunction = createSyncFunctionByString({
        functionString: widgetSettings.template,
      });
      const response = dynamicFunction({
        ...settings.dynamicFunctionProps,
        data: info,
      });

      return response;
    } catch (e) {
      const errorDesc = TreatError.getDescription(e);
      setError(errorDesc);
      return null;
    }
  }, [info, settings.dynamicFunctionProps, widgetSettings.template]);

  useImperativeHandle(ref, () => {
    return {
      getData: () => {
        return info;
      },
    };
  });

  const onClick = async () => {
    await executeEvent({
      eventName: 'onClick',
      data: info,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <Container onClick={onClick}>
      <CardTemplate
        template={template}
        data={info}
        style={{ border: 0, padding: 0 }}
      />
    </Container>
  );
}

export default forwardRef(Custom);
