/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
/* eslint-disable func-names */
/* eslint-disable no-new-func */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useRef,
  memo,
  useCallback,
  useState,
  cloneElement,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useField } from '@unform/core';
import { isAndroid, isMobile } from 'react-device-detect';
import useCompany from '~/hooks/useCompany';
import CustomEvent from '~/easy-components/Form/components/CustomEvent';
import useUiDesigner from '~/hooks/useUiDesigner';
import useFieldBase from '~/hooks/useField';
import { FieldContainer, Title } from '../styles';
import { Input, Content } from './styles';
import TreatError from '../../TreatError';
import Modal from '../../Modal';
import LinkIcon from '../../LinkIcon';
// eslint-disable-next-line import/no-cycle
import UserField from '../UserField';
import Symbol from './components/Symbol';
import { sendEvent } from '../../HandlerEvent';

function NumberElement(
  {
    baseName,
    name,
    label,
    hidden,
    formRef,
    settings,
    labelWidth,
    readOnly: readOnlyProp,
    symbol,
    enableChangeSymbol = false,
    onSearchSymbol,
    onSelectedSymbol = () => {},
    type = 'value',
    showLink: showLinkProp = false,
    onLinkClick,
    modal,
    mainFormRef = {},
    fieldDefaultValue,
    onChange = () => {},
    auxScope,
    autoFocus = false,
    labelPosition = 'left',
    numberValueDigits = null,
    allowNegative = false,
    required = false,
    isUserField,
    customTabName,
    ...rest
  },
  ref
) {
  const { numberToString, stringToNumber } = useCompany();

  const numberValueRef = useRef();
  const changed = useRef(false);
  const panelRef = useRef(null);

  const [modalShow, setModalShow] = useState(false);
  const [symbolInputValue, setSymbolInputValue] = useState('');
  const [, refreshHender] = useState(false);
  const [showLink, setShowLink] = useState(showLinkProp);
  let charPermitted = '0123456789.,';

  const {
    selfField,
    viewMode,
    showContextMenu,
    HasEventComponent,
  } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'field',
    settings,
    baseName,
    name,
    title: label,
    isUserField,
    customTabName,
  });

  delete selfField.type;

  if (allowNegative || selfField?.allowNegative)
    charPermitted = charPermitted.concat('-');

  const auxRef = ref;

  const inputRef = useRef(null);

  const fieldTitle = selfField.title || label;

  const getInputDefaultValue = ({
    unformDefaultValue,
    fieldDefaultValue: fieldDefaultValue2,
  }) => {
    if (numberValueRef.current === null) {
      return null;
    }

    if (unformDefaultValue !== null && unformDefaultValue !== undefined)
      return unformDefaultValue;

    if (typeof fieldDefaultValue2 === 'object') return '';

    return fieldDefaultValue2;
  };

  const {
    fieldName,
    defaultValue: unformDefaultValue,
    registerField,
    error,
  } = useField(name);

  const { isHidden, isRequired, isReadOnly } = useFieldBase({
    hidden,
    readOnly: readOnlyProp,
    required,
    selfField,
    formRef,
    inputRef,
    name,
    getLogData: () => {
      return {
        baseName,
        name: fieldName,
        // value: `${mask(inputRef.current.value)}`,
        value: numberValueRef.current,
      };
    },
  });

  const defaultValue = getInputDefaultValue({
    unformDefaultValue,
    fieldDefaultValue,
  });

  const mask = useCallback(value => {
    return numberToString(value, type);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue: el => {
        el.value = null;
        numberValueRef.current = null;
      },
      setValue: (inputRefCurrent, value) => {
        let numberValue = value;

        /* if (numberValue === undefined) {
          numberValue = fieldDefaultValue || defaultValue || null;
        } */

        if (numberValue !== null && numberValue !== undefined) {
          numberValue = +numberValue;
        }

        inputRefCurrent.value = mask(
          numberValue === null || numberValue === undefined ? '' : numberValue
        );

        if ((numberValueDigits || numberValueDigits === 0) && numberValue) {
          numberValue = Number(numberValue.toFixed(numberValueDigits));
        }

        numberValueRef.current = numberValue;
      },
      getValue: () => {
        const value =
          numberValueRef.current === '' ||
          numberValueRef.current === undefined ||
          numberValueRef.current === null
            ? null
            : numberValueRef.current;

        return value;
      },
    });

    numberValueRef.current = defaultValue;
    const defaultValueFormatted = mask(numberValueRef.current);
    inputRef.current.value = defaultValueFormatted;
  }, []);

  const handlerOnChange = async params => {
    const response = await sendEvent({
      settings,
      eventName: 'onchange',
      params,
      run: params.run,
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
      auxScope,
    });

    return response || params.value;
  };

  const handlerOnLinkClick = async ({ value }) => {
    await sendEvent({
      settings,
      eventName: 'onlinkclick',
      // run,
      data: value,
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
      auxScope,
    });
  };

  async function handlerChange(event) {
    try {
      changed.current = false;

      const { value } = event.target;

      let numberValue = stringToNumber(value);

      numberValueRef.current =
        numberValue !== undefined && numberValue !== null ? numberValue : '';

      const newValue = await handlerOnChange({
        value: numberValue,
        run: 'before',
      });

      if (newValue !== undefined) numberValue = newValue;

      await onChange(numberValue);

      await handlerOnChange({
        value: numberValue,
        run: 'after',
      });

      inputRef.current.value = mask(
        numberValue !== undefined && numberValue !== null ? numberValue : ''
      );
    } catch (e) {
      TreatError.showError(e);
    }
  }

  const onChanged = async e => {
    await handlerChange(e);
  };

  function onFocus() {
    const text = inputRef.current.value;
    if (inputRef && inputRef.current && inputRef.current.setSelectionRange) {
      inputRef.current.setSelectionRange(0, text.length);
    }
  }

  useEffect(() => {
    inputRef.current.setSymbol = acronym => {
      setSymbolInputValue(acronym);
    };

    inputRef.current.showLink = status => {
      setShowLink(status);
    };

    inputRef.current.addEventListener('changed', onChanged);
    inputRef.current.addEventListener('focus', onFocus);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (inputRef.current && inputRef.current.removeEventListener) {
        inputRef.current.removeEventListener('changed', onChanged);
        inputRef.current.removeEventListener('focus', onFocus);
      }
    };
  }, [selfField]);

  const defaultValueFormatted = mask(numberValueRef.current);

  /* if (inputRef.current) inputRef.current.value = defaultValueFormatted; */

  async function onLink() {
    if (onLinkClick)
      onLinkClick({
        setModalShow,
        value: inputRef.current.value,
        selfField,
      });
    else if (isUserField || !!customTabName) {
      await handlerOnLinkClick({ value: inputRef.current.value });
    } else {
      setModalShow(true);
    }
  }

  useImperativeHandle(auxRef, () => {
    return {
      setSymbol: acronym => {
        setSymbolInputValue(acronym);
      },
      symbolInputValue,
      input: inputRef.current,
      refreshHender,
    };
  });

  useEffect(() => {
    setSymbolInputValue(symbol);
  }, [symbol]);

  let isEnabledChangeSymbol = enableChangeSymbol;

  if (isReadOnly) {
    isEnabledChangeSymbol = false;
  }

  const onUpdated = ({ e, key, ctrlKey, metaKey }) => {
    const inputValue = inputRef.current.value;

    if (key) {
      if (charPermitted.indexOf(key) < 0) {
        switch (key) {
          case 'Backspace':
          case 'Delete':
            changed.current = true;
            return true;

          case 'Tab':
          case 'Meta':
          case 'Control':
          case 'Shift':
            return true;

          case 'c':
            if (metaKey || ctrlKey) {
              return true;
            }
            e.preventDefault();
            return false;

          case 'v':
            if (metaKey || ctrlKey) {
              changed.current = true;
              return true;
            }

            e.preventDefault();
            return false;

          default:
            e.preventDefault();
            return false;
        }
      } else {
        changed.current = true;
        return true;
      }
    } else {
      const reg = new RegExp(`([${charPermitted}])`, 'g');
      let validatedValue = inputValue.match(reg);

      if (validatedValue) {
        validatedValue = validatedValue.join('');
      }

      if (validatedValue === inputValue) {
        changed.current = true;
        return true;
      }

      if (validatedValue === null && inputValue === '') {
        changed.current = true;
        return true;
      }

      // alert(JSON.stringify({ validatedValue }));
      inputRef.current.value = validatedValue;
      // inputRef.current.value = validatedValue ? validatedValue.join('') : null;

      return false;
    }
  };

  return (
    <>
      <FieldContainer
        ref={panelRef}
        hidden={isHidden}
        viewMode={!hidden || isUserField ? viewMode : 'normal'}
        onContextMenu={event => showContextMenu({ event })}
        readOnly={isReadOnly}
        labelPosition={labelPosition}
        style={
          isMobile
            ? {
                ...(selfField && selfField.style ? selfField.style : {}),
                ...(rest && rest.style ? rest.style : {}),
              }
            : {}
        }
      >
        {fieldTitle && (
          <Title
            highlight={isUserField && viewMode === 'design'}
            labelPosition={labelPosition}
            labelWidth={
              (selfField && selfField.labelWidth) || labelWidth || 130
            }
          >
            <div className="title">
              <div className="required-field">
                {fieldTitle}
                {isRequired && <b>*</b>}
              </div>
              <div className="title-icon">
                <CustomEvent
                  settings={settings}
                  selfField={selfField}
                  inputRef={inputRef}
                  formRef={formRef}
                  mainFormRef={mainFormRef}
                  auxScope={auxScope}
                />
                {showLink && (
                  <div>
                    <span>
                      <LinkIcon onClick={onLink} />
                    </span>
                  </div>
                )}
              </div>
              <HasEventComponent selfField={selfField} viewMode={viewMode} />
            </div>
            {error && <div className="error">{error}</div>}
          </Title>
        )}
        <Content readOnly={isReadOnly}>
          {symbolInputValue && (
            <Symbol
              title={fieldTitle}
              enableChange={isEnabledChangeSymbol}
              symbol={symbolInputValue}
              onSearch={onSearchSymbol}
              onSelected={selectedSymbol => {
                setSymbolInputValue(selectedSymbol);
                const result = onSelectedSymbol(selectedSymbol);
                return result;
              }}
            />
          )}
          <Input
            ref={inputRef}
            defaultValue={defaultValueFormatted}
            autoFocus={autoFocus}
            inputMode="decimal"
            autoComplete="off"
            {...selfField}
            {...rest}
            hidden={false}
            readOnly={isReadOnly}
            onKeyUp={e => {
              if (isAndroid) {
                onUpdated({
                  e,
                  key: null,
                  ctrlKey: false,
                  metaKey: false,
                });
              }
            }}
            onKeyDown={e => {
              if (!isAndroid) {
                onUpdated({
                  e,
                  key: e.key,
                  ctrlKey: e.ctrlKey,
                  metaKey: e.metaKey,
                });
              }
            }}
            onBlur={async e => {
              e.persist();
              if (changed.current || e.target.changed) await handlerChange(e);
            }}
          />
        </Content>

        {modal && (
          <Modal isOpened={modalShow}>
            {modalShow &&
              cloneElement(modal, { onClose: () => setModalShow(false) })}
          </Modal>
        )}
      </FieldContainer>

      <UserField
        baseName={baseName}
        fieldName={fieldName}
        formRef={formRef}
        mainFormRef={mainFormRef}
        settings={settings}
        labelWidth={(selfField && selfField.labelWidth) || labelWidth || 130}
        auxScope={auxScope}
      />
    </>
  );
}

export default memo(forwardRef(NumberElement));
