/* eslint-disable import/no-cycle */
/* eslint-disable eqeqeq */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
// import { format } from 'date-fns';
import { isMobile, isSafari } from 'react-device-detect';
// import { b64ToBlob } from '~/easy-components/Helpers';

import ClientEventHandler from '~/applications/ClientEventHandler';
import Dashboard from '~/applications/CRM/components/Dashboard';
import Kanban from '~/applications/CRM/components/KanbanPage';
import Schedule from '~/applications/CRM/components/SchedulePage';
import Signature from '~/applications/CRM/components/Signature';
import ParamsPanel from '~/applications/CRM/pages/ReportQueries/components/ParamsPanel';
import SimplePage from '~/components/Pages/SimplePage';
import { createAsyncFunctionByString } from '~/easy-components/AsyncFunctionString';
import Button from '~/easy-components/Button';
import FlexSpace from '~/easy-components/FlexSpace';
import IFrame from '~/easy-components/IFrame';
import JsonTable from '~/easy-components/JsonTable';
import ModalEmail from '~/easy-components/ModalEmail';
import { colors } from '~/easy-components/styles';
import SystemFooterElements from '~/easy-components/SystemFooterElements';
import Toast from '~/easy-components/Toast';
import TreatError from '~/easy-components/TreatError';
import useCompany from '~/hooks/useCompany';
import useLocale from '~/hooks/useLocale';
import history from '~/routes/history';
import AttachmentService from '~/services/AttachmentService';
import QueryService from '~/services/QueryService';
import { addTask } from '~/store/modules/tasks/actions';
import useHelpers from '~/hooks/useHelpers';
import ParamsModal from '../../applications/CRM/pages/ReportQueries/components/Params';
import ReportQueriesService from '../../applications/CRM/services/ReportQueriesService';
import GanttReport from './GanttReport';

import {
  BackButton,
  Container,
  Content,
  ErrorScreen,
  Footer,
  Loading,
  MessageFile,
  PanelSide,
  ReportPage,
} from './styles';
import TimelineReport from './TimelineReport';

function ExecuteReport({
  computedMatch,
  settings,
  hideToolbar = false,
  isModal = false,
}) {
  const { dynamicFunctionProps } = useHelpers();

  const { showLoading } = dynamicFunctionProps;

  const { hash, executionHash } = computedMatch.params;

  if (
    executionHash === undefined ||
    executionHash === null ||
    executionHash === ''
  ) {
    const newExecutionHash = uuidv1();
    history.replace(`/ExecuteReport/${hash}/${newExecutionHash}`);
  }

  const t = useLocale('_Global');

  const componentRef = useRef();
  const simplePageRef = useRef();
  const { companySettings } = useCompany();

  const externalData = useSelector(redux => {
    const pageData =
      redux.pages.find(page => {
        return (
          (page.pageId || '').toUpperCase() ===
          computedMatch.url.substring(1).toUpperCase()
        );
      }) || {};

    return pageData.externalData;
  });

  const dispatch = useDispatch();

  const id = `${hash}/${executionHash}`;

  const companyName = companySettings.name;

  let resultTask = useSelector(({ tasks }) => {
    const task = tasks.find(tk => tk.id.toString() === id.toString());
    return task ? task.result : null;
  });

  const task = useSelector(({ tasks }) => {
    const selectedTask = tasks.find(tk => tk.id.toString() === id.toString());
    return selectedTask || null;
  });

  const ParamsModalRef = useRef();
  const ParamsPanelRef = useRef();
  const reportSettingsRef = useRef();
  const reportRef = useRef({ name: '', description: '' });
  const paramsRef = useRef({});

  const [report, setReport] = useState({
    data: [],
    columns: [],
  });

  const [isShowParamsPanel, setIsShowParamsPanel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [urlIframe, setUrlIframe] = useState(null);
  const [errorInformation, setErrorInformation] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [message, setMessage] = useState('Aguardando execução do relatório');
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);

  const executeEvent = async ({
    eventName,
    run = 'before',
    data,
    reportSettings,
    ...rest
  }) => {
    if (reportSettings.events) {
      const event = reportSettings.events.find(
        evt =>
          evt.name.toUpperCase() === eventName.toUpperCase() &&
          (evt.run || 'before') === run
      );

      if (event) {
        const dynamicFunction = createAsyncFunctionByString({
          functionString: event.handler,
        });

        return dynamicFunction({
          data,
          ...rest,
          ...dynamicFunctionProps,
          componentRef: componentRef.current,
          // eslint-disable-next-line no-use-before-define
          refresh: onRefresh,
        });
      }

      return null;
    }

    return null;
  };

  async function executeReport(params, isShowToast = true) {
    paramsRef.current = params;

    try {
      setIsLoading(true);

      await ReportQueriesService.check_permission(hash);

      const reportSettings = await ReportQueriesService.getSettings(hash);

      dispatch(
        addTask({
          id,
          name: reportRef.current.name,
          data: {
            report: reportRef.current,
            params: paramsRef.current,
            settings: reportSettings,
            isShowToast,
          },
          method: async () => {
            if (
              componentRef.current &&
              componentRef.current.beforeExecuteQuery
            ) {
              await componentRef.current.beforeExecuteQuery({ params, hash });
            }

            await executeEvent({
              eventName: 'onExecuteQuery',
              run: 'before',
              data: { params, hash },
              reportSettings,
            });

            const response = await ReportQueriesService.execute(hash, params);
            return response;
          },
          params: [hash, params],
          onError: error => {
            setErrorInformation(error);
            setIsLoading(false);
          },
          onClick: data => {
            history.push(`/ExecuteReport/${data.id}`);
          },
        })
      );
    } catch (error) {
      const errorDescription = TreatError.getDescription(error);
      setErrorInformation({
        task: {
          name: reportRef.current.name,
        },
        message: errorDescription,
      });
      setIsLoading(false);
    }
  }

  function itsPossibleExecuteReport({ parameters, requiredFields = [] }) {
    if (parameters) {
      // eslint-disable-next-line no-restricted-syntax
      for (let cont = 0; cont < requiredFields.length; cont++) {
        const field = requiredFields[cont];

        let fieldName = field.name;

        switch (field.type.toUpperCase()) {
          case 'EXPORTTYPE':
            fieldName = 'rptExportType';
            break;

          case 'MULTIPLECHOICE':
            fieldName = field.valueField;
            break;

          default:
            break;
        }

        const paramSettings = reportRef.current.parameters.find(
          param => param.name === fieldName
        );

        if (
          parameters[fieldName] === null ||
          parameters[fieldName] === undefined ||
          (paramSettings &&
            paramSettings.defaultValue === parameters[fieldName] &&
            paramSettings.files &&
            paramSettings.files.length)
        ) {
          return false;
        }
      }
    }

    return true;
  }

  function getRequiredParams() {
    return reportRef.current.parameters.filter(
      param =>
        param.required ||
        (param && param.type && param.type.toUpperCase() === 'EXPORTTYPE')
    );
  }

  async function execute() {
    reportSettingsRef.current = await ReportQueriesService.getSettings(hash);

    const requiredFields = getRequiredParams();

    let parameters = null;

    if (task && task.data && task.data.params) {
      parameters = task.data.params;
    } else {
      if (reportRef.current.parameters.length > 0) {
        parameters = {};

        reportRef.current.parameters.forEach(param => {
          let fieldName = param.name;
          const fieldValue = param.valueField || null;

          if (
            param &&
            param.type &&
            param.type.toUpperCase() === 'EXPORTTYPE'
          ) {
            fieldName = 'rptExportType';
          }

          if (externalData) {
            parameters[fieldName] =
              externalData[fieldName] === null ||
              externalData[fieldName] === undefined
                ? param.defaultValue === null ||
                  param.defaultValue === undefined
                  ? null
                  : param.defaultValue
                : externalData[fieldName];

            if (fieldValue) {
              parameters[fieldValue] =
                externalData[fieldValue] === null ||
                externalData[fieldValue] === undefined
                  ? param.defaultValue === null ||
                    param.defaultValue === undefined
                    ? null
                    : param.defaultValue
                  : externalData[fieldValue];
            }
          } else {
            // TESTE

            if (
              param &&
              param.type &&
              param.type.toUpperCase() === 'MULTIPLECHOICE'
            ) {
              parameters[fieldValue] = param.default || null;

              param.data.forEach(paramData => {
                let selected = 'N';

                if (param.default) {
                  if (param.default.find(d => d == paramData.value)) {
                    selected = 'Y';
                  }
                }

                parameters[
                  `_multipleChoice-${param.name}-${paramData.value}`
                ] = selected;
              });
            } else {
              parameters[fieldName] =
                param.defaultValue === null || param.defaultValue === undefined
                  ? null
                  : param.defaultValue;

              if (fieldValue) {
                parameters[fieldValue] =
                  param.defaultValue === null ||
                  param.defaultValue === undefined
                    ? null
                    : param.defaultValue;
              }
            }
          }
        });
      } else {
        parameters = externalData;
      }

      const data = {
        paramsData: parameters,
        requiredFields,
        fields: reportRef.current.parameters,
      };

      await executeEvent({
        eventName: 'onLoad',
        run: 'before',
        data,
        reportSettings: reportSettingsRef.current,
      });

      parameters = data.paramsData;
    }

    paramsRef.current = parameters;

    const itsPosibleExecute = itsPossibleExecuteReport({
      parameters,
      requiredFields,
    });

    if (itsPosibleExecute) {
      await executeReport(parameters);
    } else if (isMobile) {
      ParamsModalRef.current.show({
        params: reportRef.current.parameters,
        data: paramsRef.current,
        onConfirm: async params => {
          await executeReport(params);
        },
      });
    } else {
      setIsLoading(false);

      setIsShowParamsPanel(true);

      ParamsPanelRef.current.setData({
        data: paramsRef.current,
      });
    }
  }

  async function loadReport() {
    try {
      if (externalData) {
        const reportParameters = await ReportQueriesService.getParameters(hash);
        reportRef.current = reportParameters;
        await execute();
      } else if (task && resultTask) {
        reportRef.current = task.data.report;
        paramsRef.current = task.data.params;
        if (
          resultTask.reportSettings &&
          resultTask.reportSettings.alwaysRefresh
        ) {
          resultTask = null;
          execute(false);
        }
      } else if (task && task.error) {
        reportRef.current = task.data.report;
        paramsRef.current = task.data.params;
        setErrorInformation(task.error);
        setIsLoading(false);
      } else {
        const reportParameters = await ReportQueriesService.getParameters(hash);

        reportRef.current = reportParameters;

        await execute();
      }
    } catch (error) {
      const messageError = TreatError.getDescription(error);

      setErrorInformation({
        task: {
          name: reportRef.current.name,
        },
        message: messageError,
      });

      setIsLoading(false);
    }
  }

  async function sendClientEvent({ eventName, data, run }) {
    const clientResponse = await ClientEventHandler({
      eventName,
      run,
      data,
      params: {
        data,
        ...settings.dynamicFunctionProps,
        settings: resultTask.reportSettings,
      },
    });

    return clientResponse;
  }

  async function onRefresh() {
    setErrorInformation(null);

    const requiredFields = getRequiredParams();

    const canExecuteReport = itsPossibleExecuteReport({
      parameters: paramsRef.current,
      requiredFields,
    });

    if (!canExecuteReport) {
      setIsShowParamsPanel(true);
    }

    setTimeout(() => {
      if (
        !isMobile &&
        reportRef.current.parameters.length > 0 &&
        !canExecuteReport
      ) {
        ParamsPanelRef.current.validate();
      } else {
        // TODO: Verificar como fazer quando for mobile
        executeReport(paramsRef.current, false);
      }
    }, 300);
  }

  async function refreshData() {
    await execute(false);
  }

  function onShowParameters() {
    if (isMobile) {
      ParamsModalRef.current.show({
        params: reportRef.current.parameters,
        data: paramsRef.current,
        onConfirm: async params => {
          await executeReport(params);
        },
      });
    } else {
      setIsShowParamsPanel(!isShowParamsPanel);
    }
  }

  function isShowButtonParameters() {
    if (reportRef.current && reportRef.current.parameters) {
      return reportRef.current.parameters.some(
        param => param.type !== 'hidden'
      );
    }

    return false;
  }

  const getDownloadToken = ({ fileName, sourcePath }) => {
    const attachmentService = new AttachmentService();

    return attachmentService.getToken({ fileName, sourcePath });
  };

  const callQueryCode = ({ filter, queryCode, sql, rowKeyValue, rowData }) => {
    if (queryCode) {
      return QueryService.executeByCode(queryCode, {
        filter,
        rowKeyValue,
        ...rowData,
      });
    }

    if (sql) {
      return QueryService.execute(1, sql, { filter, rowKeyValue, rowData });
    }

    return [];
  };

  function isShowButtonSendEmail() {
    if (report && report.emailSettings) {
      if (report.emailSettings.enableButtonEmail === undefined) return true;

      return report.emailSettings.enableButtonEmail;
    }

    return false;
  }

  const renderSendEmailModal = () => {
    if (report && report.emailSettings) {
      return (
        <ModalEmail
          preview={report.emailSettings.preview || false}
          t={t}
          showLoading={showLoading}
          title={t('SendEmail')}
          settings={settings}
          data={{ params: paramsRef.current, reportData: report.data }}
          emailSettings={report.emailSettings}
          onSendEmail={async ({ data }) => {
            try {
              showLoading(true);

              await ReportQueriesService.sendEmail({
                hash,
                params: paramsRef.current,
                emailData: data,
              });

              Toast.success(t('message.EmailSentSuccess'));
              setIsOpenEmailModal(false);
            } catch (error) {
              TreatError.showError(error);
            } finally {
              showLoading(false);
            }
          }}
          onClose={() => setIsOpenEmailModal(false)}
        />
      );
    }

    return null;
  };

  const setPanelShow = async () => {
    const reportSettings = await ReportQueriesService.getSettings(hash);
    const showSideParams =
      reportSettings && reportSettings.showSideParams
        ? reportSettings.showSideParams
        : false;

    setIsShowParamsPanel(showSideParams);
  };

  const { reportSettings } = resultTask || {};

  const { footerElements = [] } = reportSettings || {};

  const systemFooterElements = footerElements.map((button, idx) => {
    return {
      name: button.name || `Custom_${idx}`,
      type: button.type || 'Default',
      onClick: button.onClick,
      text: button.text,
      iconName: button.iconName || null,
      toolTip: button.toolTip || null,
      size: button.size || null,
      color: button.color || null,
      hidden: button.hidden || null,
      position: button.position || null,
    };
  });

  function getParamsPanelWidth() {
    const defaultValue = 250;

    if (
      reportRef.current.reportType === 'dashboard' &&
      task &&
      task.data &&
      task.data.settings
    ) {
      const paramsPanelWidth =
        task.data.settings.paramsPanelWidth || defaultValue;
      return paramsPanelWidth;
    }

    if (
      report &&
      report.reportSettings &&
      report.reportSettings.paramsPanelWidth
    ) {
      return report.reportSettings.paramsPanelWidth;
    }

    return defaultValue;
  }

  useEffect(() => {
    loadReport();
    setPanelShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resultTask) {
      switch (reportRef.current.reportType) {
        case 'dashboard':
          setIsLoading(false);
          break;

        case 'schedule':
          setIsLoading(false);
          break;

        case 'kanban':
          setIsLoading(false);
          break;

        case 'gantt':
          setIsLoading(false);
          break;

        case 'signature':
          const urlIFrame = `${process.env.REACT_APP_SERVER_URL}/${resultTask.url}`;
          setIsLoading(false);
          setUrlIframe(urlIFrame);

          break;

        case 'rpt':
          const serverUrl = process.env.REACT_APP_SERVER_URL;

          const { url, emailSettings } = resultTask;

          if (isModal) {
            setPdf(`${serverUrl}/${url}`);
            setMessage(null);
          } else {
            if (isSafari) {
              const a = document.createElement('a');
              document.body.appendChild(a);
              a.style = 'display: none';
              a.href = `${serverUrl}/${url}`;
              a.click();
              document.body.removeChild(a);
            } else window.open(`${serverUrl}/${url}`);

            setReport({
              emailSettings,
            });

            setMessage('Arquivo gerado com sucesso!');

            setTimeout(() => {
              history.goBack();
            }, 500);
          }

          setIsLoading(false);

          break;

        default:
          // query
          // TODO: Traduzir
          if (resultTask.data.length === 0)
            setMessage('Nenhum registro encontrado');

          sendClientEvent({
            eventName: 'onRender',
            data: resultTask,
            run: 'before',
          }).then(() => {
            setReport({
              ...resultTask,
              pdfFontSize: resultTask.reportSettings
                ? resultTask.reportSettings.pdfFontSize
                : 8,
              pdfOrientation: resultTask.reportSettings
                ? resultTask.reportSettings.pdfOrientation
                : 'portrait',
              groups: resultTask.reportSettings
                ? resultTask.reportSettings.groups
                : [],
            });

            setTimeout(() => {
              sendClientEvent({
                eventName: 'onRender',
                data: resultTask,
                run: 'after',
              });
            }, 200);

            setIsLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultTask]);

  const reportDataMap = {
    schedule: resultTask ? resultTask.events || [] : [],
    kanban: resultTask ? resultTask.events || [] : [],
    dashboard: resultTask ? resultTask.widgets || [] : [],
    signature: resultTask ? resultTask.events || [] : [],
    query: report.data,
  };

  // TODO: Traduzir
  return (
    <SimplePage
      ref={simplePageRef}
      route={`ExecuteReport/${hash}`}
      title={
        errorInformation
          ? `${errorInformation.task.name}`
          : `${reportRef.current.name}`
      }
    >
      <ReportPage>
        <Loading isLoading={isLoading}>
          <FaSpinner size={32} color="#496774" />
          <h1>{t('message.GeneratingReport')}</h1>
          <h2>{reportRef.current.name}</h2>
          <h3>{reportRef.current.description}</h3>
        </Loading>
        <Container>
          <Content>
            {errorInformation ? (
              <ErrorScreen>
                <h2>Não foi possível carregar o relatório</h2>
                <span>{errorInformation.message}</span>
              </ErrorScreen>
            ) : reportRef.current.reportType === 'schedule' ? (
              <Schedule
                ref={componentRef}
                t={t}
                settings={settings}
                refresh={onRefresh}
                refreshData={refreshData}
                report={report}
                events={resultTask ? resultTask.events || [] : []}
                params={paramsRef.current}
                reportSettings={resultTask ? resultTask.reportSettings : {}}
                onRangeChange={() => {
                  executeReport(paramsRef.current);
                }}
              />
            ) : reportRef.current.reportType === 'kanban' ? (
              <Kanban
                simplePageRef={simplePageRef}
                ref={componentRef}
                readOnly
                t={t}
                settings={settings}
                report={report}
                events={resultTask ? resultTask.events || [] : []}
                params={paramsRef.current}
                reportSettings={resultTask ? resultTask.reportSettings : {}}
              />
            ) : reportRef.current.reportType === 'dashboard' ? (
              <Dashboard
                ref={componentRef}
                readOnly
                settings={settings}
                reportSettings={resultTask ? resultTask.reportSettings : {}}
                widgets={resultTask ? resultTask.widgets || [] : []}
                layouts={resultTask ? resultTask.layouts || [] : []}
                params={paramsRef.current}
                t={t}
              />
            ) : reportRef.current.reportType === 'gantt' ? (
              <GanttReport
                ref={componentRef}
                settings={settings}
                reportSettings={
                  resultTask ? resultTask.reportSettings || {} : null
                }
                params={paramsRef.current}
                tasks={resultTask ? resultTask.data : []}
                t={t}
              />
            ) : reportRef.current.reportType === 'timeline' ? (
              <TimelineReport
                ref={componentRef}
                settings={settings}
                reportSettings={
                  resultTask ? resultTask.reportSettings || {} : null
                }
                params={paramsRef.current}
                data={resultTask ? resultTask.data : []}
                t={t}
              />
            ) : reportRef.current.reportType === 'signature' ? (
              <Signature
                t={t}
                settings={settings}
                reportSettings={reportSettingsRef.current}
                events={resultTask ? resultTask.events || [] : []}
                url={urlIframe}
                reportData={reportRef.current || {}}
                params={paramsRef.current}
                showLoading={showLoading}
              />
            ) : pdf ? (
              <>
                {message && <MessageFile>{message}</MessageFile>}
                <IFrame src={pdf} isBase64 />
              </>
            ) : report.data && report.data.length > 0 ? (
              <JsonTable
                ref={componentRef}
                title={reportRef.current.name}
                subTitle={companyName}
                fileName={reportRef.current.name}
                data={report.data}
                columns={report.columns}
                rowKeyField={report.rowKeyField}
                pdfFontSize={report.pdfFontSize}
                pdfOrientation={report.pdfOrientation}
                t={t}
                hideToolbar={hideToolbar}
                hasFilter={
                  report.reportSettings && report.reportSettings.hasFilter
                }
                groups={report.groups}
                onRefresh={onRefresh}
                showLoading={showLoading}
                settings={settings}
                reportSettings={resultTask ? resultTask.reportSettings : {}}
                paramsModal={ParamsModalRef.current}
                appDispatch={dispatch}
                onGetDownloadToken={getDownloadToken}
                onOpenInputLinkerInLine={callQueryCode}
                auxScope={{
                  params: paramsRef.current,
                  getParams: () => {
                    /* if (ParamsPanelRef.current) {
                      return ParamsPanelRef.current.getData();
                    } */

                    return paramsRef.current;
                  },
                }}
              />
            ) : task && resultTask ? (
              <>{message && <MessageFile>{message}</MessageFile>}</>
            ) : null}
          </Content>
          <Footer>
            <BackButton>
              <MdArrowBack
                size={22}
                color={colors.main}
                onClick={() => history.goBack()}
              />
            </BackButton>
            {isShowButtonSendEmail() && (
              <Button
                buttonType="Default"
                onClick={() => {
                  setIsOpenEmailModal(true);
                }}
              >
                {t('SendEmail')}
              </Button>
            )}
            <Button buttonType="Emphasized" onClick={onRefresh}>
              {t('Refresh')}
            </Button>
            {isShowButtonParameters() && (
              <Button onClick={onShowParameters}>{t('Parameters')}</Button>
            )}

            <SystemFooterElements
              data={systemFooterElements}
              buttonClickScope={{
                ...settings.dynamicFunctionProps,
                globalFunctions: settings.globalFunctions,
                pageRefresh: refreshData,
                data: reportDataMap[reportRef.current.reportType],
              }}
            />

            <FlexSpace />
          </Footer>
          {isOpenEmailModal && renderSendEmailModal()}
        </Container>
        <PanelSide>
          <ParamsModal
            ref={ParamsModalRef}
            title={t('ReportParameterTitle')}
            t={t}
            settings={settings}
            onCancel={() => {
              if (!resultTask) {
                history.goBack();
              }
            }}
          />
          <ParamsPanel
            ref={ParamsPanelRef}
            title={t('ReportParameterTitle')}
            t={t}
            visible={isShowParamsPanel && !isMobile}
            settings={settings}
            params={reportRef.current.parameters}
            paramsData={paramsRef.current}
            paramsPanelWidth={getParamsPanelWidth()}
            onConfirm={async params => {
              if (
                componentRef.current &&
                componentRef.current.clearAllFilters
              ) {
                await componentRef.current.clearAllFilters();
              }
              await executeReport(params);
            }}
            onCancel={() => {
              if (!resultTask) {
                history.goBack();
              }
            }}
          />
        </PanelSide>
      </ReportPage>
    </SimplePage>
  );
}

export default ExecuteReport;
