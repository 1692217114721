import RequestService from './RequestService';
import CompanyService from './CompanyService';

class ApplicationGroupService {
  async list(userId) {
    const response = await RequestService.request({
      url: `APP/CRM/user_groups/${userId}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async save(data) {
    const { id, ...rest } = data;

    if (id === '') {
      const response = await RequestService.request({
        url: `APP/CRM/user_groups/${rest.userId}`,
        method: 'POST',
        data: {
          ...rest,
        },
        token: await CompanyService.getCompanyToken(),
      });

      return response;
    }

    const response = await RequestService.request({
      url: `APP/CRM/user_groups/${rest.userId}`,
      method: 'PUT',
      data: { ...rest },
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }
}

export default new ApplicationGroupService();
