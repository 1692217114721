/* eslint-disable import/no-cycle */
/* eslint-disable react/button-has-type */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  memo,
} from 'react';
import ModalHandle from '~/easy-components/ModalHandle';
import { Container } from './styles';
import Button from '~/easy-components/Button';
import FlexSpace from '~/easy-components/FlexSpace';
import FooterPage from '~/easy-components/FooterPage';
import useLocale from '~/hooks/useLocale';
import useHelpers from '~/hooks/useHelpers';
import Editor from './Editor';
import HeaderPage from '~/easy-components/HeaderPage';
import colors from '~/styles/colors';
import Icon from '~/easy-components/Icon';

// eslint-disable-next-line no-unused-vars
function EditorModal(_, ref) {
  const t = useLocale('_Global');
  const panelRef = useRef();

  const [state, setState] = useState({
    isShow: false,
    menu: {
      title: '',
    },
    title: null,
  });

  const editorRef = useRef();
  const modalRef = useRef();
  const querySettingRef = useRef();

  const {
    process,
    dynamicFunctionProps: { getApplicationSettings, logRef },
  } = useHelpers();

  useEffect(() => {
    if (state.isShow) {
      modalRef.current.handleOpen();
    } else {
      modalRef.current.handleClose();
    }
  }, [state.isShow]);

  const close = () => {
    setState({
      ...state,
      isShow: false,
    });
    logRef.current.removeEvent({
      title: state.title,
      eventName: state.menu.prop,
    });
  };

  useImperativeHandle(ref, () => {
    return {
      setVisible: async visible => {
        modalRef.current.setVisible(visible);
      },
      show: async ({
        title,
        code,
        onConfirm,
        menu,
        language,
        isShowMinimalize,
      }) => {
        await process(async () => {
          querySettingRef.current =
            (await getApplicationSettings({
              pageRoute: 'Queries',
            })) || {};

          setState({
            isShow: true,
            title,
            code,
            onConfirm,
            menu: menu || {},
            language,
            isShowMinimalize,
          });
        });
      },
      close,
    };
  });

  const onSave = async () => {
    const code = editorRef.current.getValue();

    state.onConfirm({
      code,
      isClose: false,
    });
  };

  const onSaveAndClose = async () => {
    const code = editorRef.current.getValue();

    state.onConfirm({ code, isClose: true });

    close();
  };

  const onKeyDown = event => {
    const char_S = 83;
    const char_Q = 81;
    const char_M = 77;
    const char_J = 74;
    const char_ESC = 27;

    if (event.ctrlKey) {
      switch (event.keyCode) {
        case char_S:
          event.stopPropagation();
          event.preventDefault();

          if (event.shiftKey) {
            onSaveAndClose();
          } else {
            onSave();
          }
          break;

        case char_M:
          modalRef.current.setVisible(false);
          break;

        case char_Q:
          panelRef.current.showComponent('query');
          break;

        case char_J:
          panelRef.current.showComponent('code');
          break;

        default:
          break;
      }
    }

    switch (event.keyCode) {
      case char_ESC:
        close();
        break;

      default:
        break;
    }
  };

  const title = `${state.title} ${(state.menu.title
    ? ` | ${state.menu.title}`
    : null) || ''}`;

  return (
    <ModalHandle
      ref={modalRef}
      contentStyle={{
        style: {
          width: '95%',
          height: '95%',
        },
      }}
      onClose={close}
    >
      {state.isShow && (
        <Container>
          <HeaderPage
            backgroundColor={colors.headerBg}
            color={colors.headerTitle}
            hideMainMenu
          >
            {title}
            {state.isShowMinimalize && (
              <Icon
                name="FaRegWindowMinimize"
                hidden
                size={18}
                color="#FFFFFF"
                onClick={() => {
                  modalRef.current.setVisible(false);
                }}
              />
            )}
          </HeaderPage>
          <Editor
            ref={editorRef}
            language={state.language}
            onKeyDown={onKeyDown}
            code={state.code}
          />

          <FooterPage>
            <Button
              buttonType="Emphasized"
              onClick={() => {
                panelRef.current.showComponent('code');
              }}
            >
              {t('Code')}
            </Button>
            <Button
              buttonType="Accept"
              onClick={() => {
                panelRef.current.showComponent('query');
              }}
            >
              {t('Queries')}
            </Button>
            <FlexSpace />
            <Button onClick={close}>{t('Cancel')}</Button>
            <Button buttonType="Emphasized" onClick={onSave}>
              {t('Save')}
            </Button>
            <Button buttonType="Emphasized" onClick={onSaveAndClose}>
              {t('SaveAndClose')}
            </Button>
          </FooterPage>
        </Container>
      )}
    </ModalHandle>
  );
}

export default memo(forwardRef(EditorModal));
