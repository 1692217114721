// @ts-check
import { useRef, useState, useCallback } from 'react';

/**
 * @template V
 * @typedef {V | ((oldState: V) => V)} SetValueAction
 */

/**
 * @template T
 * @param {T} object
 */
function useComponentState(object) {
  const objectRef = useRef(object);

  const [, setState] = useState({ ...object });

  const updateComponent = useCallback(() => {
    setState({ ...objectRef.current });
  }, []);

  /**
   * @template {keyof T} K
   * @param {K} key
   * @param {SetValueAction<T[K]>} value
   * @param {boolean} [forceUpdate]
   * @returns {void}
   */
  const setFunction = (key, value, forceUpdate = false) => {
    if (typeof value === 'function') {
      // @ts-ignore
      const newValue = value(objectRef.current[key]);
      objectRef.current[key] = newValue;
    } else {
      objectRef.current[key] = value;
    }

    if (forceUpdate) {
      updateComponent();
    }
  };

  const set = useCallback(setFunction, [updateComponent]);

  const get = useCallback(() => {
    return objectRef.current;
  }, []);

  /** @type {[T, typeof set, typeof updateComponent, typeof get]} */
  const response = [objectRef.current, set, updateComponent, get];

  return response;
}

export default useComponentState;
