import BaseMasterDataService from './BaseMasterDataService';
import request from '../../Request';

class UserPageService extends BaseMasterDataService {
  constructor() {
    super({ route: 'UserPages', primaryKeyField: 'id', isLargeData: true });
  }

  async getSettings({ hash }) {
    const settings = await request({
      url: `APP/CRM/UserPages/${hash}/Settings`,
    });
    return settings;
  }

  async getByHash({ hash }) {
    const settings = await request({
      url: `APP/CRM/UserPages/hash/${hash}`,
    });
    return settings;
  }

  async checkPermissions({ hash }) {
    const settings = await request({
      url: `APP/CRM/UserPages/${hash}/checkPermissions`,
    });
    return settings;
  }
}

export default new UserPageService();
