/* eslint-disable import/no-cycle */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Icon from '~/easy-components/Icon';
import Button from '~/easy-components/Button';
import FlexSpace from '~/easy-components/FlexSpace';
import systemColors from '~/styles/colors';

// eslint-disable-next-line no-new-func
const AsyncFunction = new Function(
  `return Object.getPrototypeOf(async function(){}).constructor`
)();

/**
 * @param {Object} props
 * @param {FooterElement[]} props.data
 * @param {Object} props.buttonClickScope
 * @param {React.RefObject<import('@unform/core').FormHandles>} [props.buttonFormRef]
 */
function SystemFooterElements({
  data,
  buttonClickScope,
  buttonFormRef,
  settings,
}) {
  const mountButton = (footerElement, idx) => {
    const onClickElement = new AsyncFunction('scope', footerElement.onClick);

    if (footerElement.type === 'icon') {
      return (
        <Button
          name={footerElement.name || null}
          buttonType="Icon"
          data-tip={footerElement.toolTip}
          data-for="footerElementsTooltip"
          formRef={buttonFormRef}
          key={footerElement.name || idx}
          isUserField={footerElement.isUserField || false}
          hidden={footerElement.hidden}
          settings={settings}
        >
          <Icon
            name={footerElement.iconName}
            size={footerElement.size || 25}
            color={footerElement.color || systemColors.blue}
            onClick={() => onClickElement(buttonClickScope)}
            settings={settings}
          />
        </Button>
      );
    }

    return (
      <Button
        isUserField={footerElement.isUserField || false}
        key={footerElement.name || idx}
        name={footerElement.name || null}
        data-tip={footerElement.toolTip}
        data-for="footerElementsTooltip"
        formRef={buttonFormRef}
        buttonType={footerElement.type}
        color={footerElement.color}
        hidden={footerElement.hidden}
        onClick={() => onClickElement(buttonClickScope)}
        settings={settings}
      >
        {footerElement.text}
      </Button>
    );
  };

  const getLeftButtons = () => {
    const leftButtons = data.filter(button => {
      return (
        (button.position && button.position === 'left') || !button.position
      );
    });

    return leftButtons.map((button, idx) => {
      return mountButton(button, idx);
    });
  };

  const getRightButtons = () => {
    const leftButtons = data.filter(button => {
      return button.position && button.position === 'right';
    });

    return leftButtons.map((button, idx) => {
      return mountButton(button, idx);
    });
  };

  const leftButtonList = getLeftButtons();
  const rightButtonList = getRightButtons();

  return (
    <>
      {leftButtonList.length > 0 && (
        <>
          {leftButtonList.map(FooterButton => {
            return FooterButton;
          })}
          <FlexSpace />
        </>
      )}
      {rightButtonList.length > 0 && (
        <>
          <FlexSpace />
          {rightButtonList.map(FooterButton => {
            return FooterButton;
          })}
        </>
      )}
      <ReactTooltip
        id="footerElementsTooltip"
        place="top"
        type="light"
        effect="solid"
        className="toolbarTooltip"
      />
    </>
  );
}

export default SystemFooterElements;
