/* eslint-disable react/prop-types */
import React from 'react';

import { Container, Content } from './styles';

export default function ColumnLayout({
  children,
  hidden = false,
  labelPosition,
  ...rest
}) {
  return (
    <Container hidden={hidden} {...rest}>
      <Content labelPosition={labelPosition}>{children}</Content>
    </Container>
  );
}
