import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;

  /* display: grid;
  row-gap: 10rem; */

  .rbc-button-link {
    color: #5d7a89;
  }

  .rbc-header {
    padding: 10px;
    color: #5d7a89;
  }

  .rbc-month-row {
    border-color: #dadddf !important;
  }
  .rbc-month-view {
    border-color: #dadddf !important;
  }
  .rbc-day-bg {
    border-color: #dadddf !important;
  }

  .rbc-show-more {
    margin-top: 8px;
    background-color: transparent;
  }

  .rbc-calendar {
    background-color: #fff;
    padding: 16px;
  }
`;
