export const CrystalExportTypes = [
  {
    value: 2,
    label: 'RichText',
    extension: 'rtf',
  },
  {
    value: 3,
    label: 'Word',
    extension: 'doc',
  },
  {
    value: 4,
    label: 'Excel',
    extension: 'xls',
  },
  {
    value: 5,
    label: 'PDF',
    extension: 'pdf',
  },
  {
    value: 8,
    label: 'ExcelRecord',
    extension: 'xls',
  },
  {
    value: 9,
    label: 'Text',
    extension: 'txt',
  },
  {
    value: 10,
    label: 'CharacterSeparatedValues',
    extension: 'csv',
  },
  {
    value: 11,
    label: 'TabSeperatedText',
    extension: 'csv',
  },
  {
    value: 12,
    label: 'EditableRTF',
    extension: 'rtf',
  },
  {
    value: 13,
    label: 'XML',
    extension: 'xml',
  },
  {
    value: 15,
    label: 'ExcelWorkbook',
    extension: 'xlsx',
  },
];
