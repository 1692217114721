/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, { useEffect, useRef } from 'react';
import { AiOutlineLoading } from '@react-icons/all-files/ai/AiOutlineLoading';
import dynamicFunction from '../../dynamicFunction';
import { LoadIcon } from './styles';
import CompareRender from '../../../Helpers/compareRender';

function CellSummary({
  columnKey,
  columnData,
  functionString,
  scope,
  settings,
}) {
  const divRef = useRef();

  useEffect(() => {
    if (functionString !== undefined && functionString !== null) {
      dynamicFunction({
        functionString,
        settings,
        params: scope,
      }).then(html => {
        if (divRef.current) {
          divRef.current.innerHTML = html;
        }
      });
    }
  }, [columnData, columnKey, functionString, scope, settings]);

  if (!functionString) {
    return null;
  }

  return (
    <div ref={divRef}>
      <LoadIcon>
        <AiOutlineLoading size={12} color="#aaa" />
      </LoadIcon>
    </div>
  );
}

export default CompareRender(CellSummary, ['columnData', 'scope.data']);
