/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import useLocale from '~/hooks/useLocale';
import useUiDesigner from '~/hooks/useUiDesigner';
import MasterDetailLayout from './MasterDetailLayout';
import UserTable from '../../UserTable';
import { JsonPageProvider } from './context';

function JsonPage({
  showLoading,
  route,
  // settings,
  isReadOnly,
  dispatch,
  showReport,
  hash,
  isUserPage,
  isListOpen,
}) {
  const { settings } = useUiDesigner({
    pageId: route,
  });

  const t = useLocale('_Global');

  let content = (
    <MasterDetailLayout isUserPage={isUserPage} settings={settings} />
  );

  if (settings._type === 'userTable') {
    content = (
      <UserTable
        isUserPage={isUserPage}
        title={settings._title}
        tableName={settings._userTable}
        data={settings.tableSettings.data}
        columns={settings.tableSettings.columns}
        reportSettings={settings.tableSettings.reportSettings}
        showLoading={showLoading}
        t={t}
      />
    );
  }

  return (
    <JsonPageProvider
      showLoading={showLoading}
      route={route}
      settings={settings}
      isReadOnly={isReadOnly}
      dispatch={dispatch}
      showReport={showReport}
      t={t}
      hash={hash}
      isUserPage={isUserPage}
      isListOpen={
        isListOpen === null || isListOpen === undefined
          ? settings.isListDefaultOpen
          : isListOpen
      }
    >
      {content}
    </JsonPageProvider>
  );
}

export default JsonPage;
