/* eslint-disable import/no-cycle */
import { selected } from '~/store/modules/company/actions';
import RequestService from './RequestService';
import StorageService from './StorageService';
// import * as DataConnectorActions from '~/store/modules/dataConnector/actions';
// import TreatError from '~/easy-components/TreatError';

class CompanyService {
  async list(authorizationToken) {
    const response = await RequestService.request({
      url: `user_companies`,
      method: 'GET',
      token: authorizationToken,
    });

    const companiesSorted = response.sort((itemA, itemB) => {
      if (itemA.company.name < itemB.company.name) {
        return -1;
      }
      if (itemA.company.name > itemB.company.name) {
        return 1;
      }
      return 0;
    });

    return companiesSorted;
  }

  async getToken(companyId) {
    const authToken = StorageService.get('authorizationToken');

    const token = await RequestService.request({
      url: `company_token/${companyId}`,
      method: 'POST',
      token: authToken,
    });

    return token || null;
  }

  async create({ data, file }) {
    const authToken = StorageService.get('authorizationToken');

    const response = await RequestService.request({
      url: `companies`,
      method: 'POST',
      token: authToken,
      data,
      file,
    });

    return response;
  }

  getCompanyToken() {
    const companyToken = StorageService.get('companyToken');
    return companyToken;
  }

  getDataConnectorToken() {
    const { token } = StorageService.get('dataConnector');
    return token;
  }

  async getDataConnectorInformations() {
    const token = this.getCompanyToken();

    const dataConnectorInformations = await RequestService.request({
      url: `Companies/DataConnector`,
      method: 'GET',
      token,
    });

    return dataConnectorInformations;
  }

  async update({ id, data }) {
    const companyToken = await this.getCompanyToken();

    const response = await RequestService.request({
      url: `companies/${id}`,
      method: 'PUT',
      largeData: data,
      token: companyToken,
    });

    return response;
  }

  async saveDataConnectorSettings({ dataConnectorUrl, dataConnectorToken }) {
    const companyToken = await this.getCompanyToken();

    const response = await RequestService.request({
      url: `data_connector`,
      method: 'PUT',
      data: { dataConnectorUrl, dataConnectorToken },
      token: companyToken,
    });

    return response;
  }

  async get(id) {
    const token = this.getCompanyToken();

    const company = await RequestService.request({
      url: `companies/${id}`,
      method: 'GET',
      token,
    });

    return company;
  }

  async delete(id) {
    const companyToken = this.getCompanyToken();
    await RequestService.request({
      url: `companies/${id}`,
      method: 'DELETE',
      token: companyToken,
    });
  }

  async listUsers() {
    const companyToken = this.getCompanyToken();
    const response = await RequestService.request({
      url: `company_users`,
      method: 'GET',
      token: companyToken,
    });

    return response;
  }

  async connectDataConnector(companyToken) {
    const response = await RequestService.request({
      url: `data_connector`,
      method: 'POST',
      token: companyToken,
    });

    return response;
  }

  async getBranchInfoByToken({ authToken, token, dataConnectorUrl }) {
    const response = await RequestService.request({
      url: `data_connector/branch`,
      method: 'POST',
      token: authToken,
      data: { token, dataConnectorUrl },
    });

    return response;
  }

  async reconnect() {
    const companyToken = StorageService.get('companyToken');

    const token = await RequestService.request({
      url: `data_connector/reconnect`,
      method: 'POST',
      token: companyToken,
    });

    return token || null;
  }

  async connect({ dispatch, companyId, companyName, companyTokenOut }) {
    const companyToken = companyTokenOut || (await this.getToken(companyId));

    dispatch(
      selected({
        companyToken,
        companyId,
        companyName,
      })
    );

    return companyToken;
  }

  async getStartPage({ companyToken }) {
    const response = await RequestService.request({
      url: `company_users/startPage`,
      token: companyToken,
    });

    return response;
  }

  async shouldApplyPasswordPolicies({ companyToken }) {
    const response = await RequestService.request({
      url: `companies/shouldApplyPasswordPolicies`,
      token: companyToken,
    });

    return response;
  }
}

export default new CompanyService();
