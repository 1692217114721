/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { isMobile } from 'react-device-detect';
import { MdOutlineFlashOn as FlashIcon } from 'react-icons/md';
import Toast from '~/easy-components/Toast';
import CustomEvent from '~/easy-components/Form/components/CustomEvent';
import useLocale from '~/hooks/useLocale';
import useUiDesigner from '~/hooks/useUiDesigner';
import useFieldBase from '~/hooks/useField';
import { FieldContainer, Title } from '../styles';
// eslint-disable-next-line import/no-cycle
import UserField from '../UserField';
import { sendEvent } from '../../HandlerEvent';
import { Input, ButtonIcon, PanelInput } from './styles';

function TimeField({
  baseName,
  name,
  label,
  hidden,
  formRef,
  mainFormRef,
  settings,
  labelWidth,
  readOnly: readOnlyProp,
  onChange,
  labelPosition = 'left',
  auxScope,
  required = false,
  isUserField,
  customTabName,
  ...rest
}) {
  const charPermitted = '0123456789:';
  const changed = useRef(false);
  const inputRef = useRef(null);

  const { selfField, viewMode, showContextMenu } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'field',
    settings,
    baseName,
    name,
    title: label,
    isUserField,
    customTabName,
  });
  const t = useLocale('_Global');

  const fieldTitle = selfField.title || label;

  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  const { isHidden, isRequired, isReadOnly } = useFieldBase({
    hidden,
    readOnly: readOnlyProp,
    required,
    selfField,
    formRef,
    inputRef,
    getLogData: () => {
      return {
        baseName,
        name: fieldName,
        value: inputRef.current.value,
      };
    },
  });

  function formatHourMask(value) {
    if (value) {
      const strValue = value.toString().padStart(4, '0');

      if (strValue.indexOf(':') !== -1) {
        return strValue;
      }

      const hour = strValue.slice(0, 2);

      const min = strValue.slice(-2);

      const newTime = `${hour}:${min}`;

      return newTime;
    }
    return null;
  }

  function formatHour(hour) {
    if (!hour.includes('_')) return hour;

    return `0${hour.replace(/_/, '')}`;
  }

  function formatMinutes(min) {
    if (min.indexOf('_') === -1) return min;

    if (min === '__') return '00';

    return `0${min.replace(/_/, '')}`;
  }

  const convertHour = useCallback(time => {
    if (time) {
      const [hour, min] = time.split(':');

      const newHour = formatHour(hour);

      if (newHour >= 0 && newHour < 24) {
        const newMin = formatMinutes(min);

        if (newMin >= 0 && newMin < 60) {
          const newTime = newHour + newMin;

          const intTime =
            newTime.slice(0, 2) !== '00' ? parseInt(newTime, 10) : newTime;

          return intTime;
        }
        return null;
      }
    }
    return null;
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue: el => {
        el.value = null;
      },
      setValue: (el, v) => {
        const newValue = formatHourMask(v);
        el.setInputValue(newValue || v || '');
      },
      getValue: el => {
        const hour = formatHourMask(convertHour(el.value));

        return el.value === '' ? null : hour;
      },
    });
  }, [convertHour, fieldName, registerField]);

  function formatDateToTime(date) {
    const hour = date.getHours().toString();
    const minutes = date.getMinutes().toString();

    const fullHour = hour.padStart(2, '0');
    const fullMinutes = minutes.padStart(2, '0');

    const strTime = `${fullHour}:${fullMinutes}`;

    return strTime;
  }

  async function handlerOnSetCurrentValue({ run, currentTime }) {
    const dynamicFunctionProps = settings
      ? settings.dynamicFunctionProps
      : null;

    return sendEvent({
      settings,
      eventName: 'onSetCurrentValue',
      run,
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
      auxScope: {
        ...dynamicFunctionProps,
        currentTime,
      },
    });
  }

  const handlerOnChange = useCallback(
    async params => {
      const response = await sendEvent({
        settings,
        eventName: 'onchange',
        params,
        run: params.run,
        element: inputRef.current,
        formRef,
        mainFormRef,
        events: selfField.events,
      });

      return response || params.value;
    },
    [formRef, mainFormRef, selfField.events, settings]
  );

  const handlerBlur = useCallback(async () => {
    if (changed.current || inputRef.current.changed) {
      changed.current = false;

      let val = inputRef.current.value;

      const validHour = convertHour(val);

      if (!validHour && val) {
        inputRef.current.setInputValue('');

        Toast.warn(t('message.ErrorValidTime'));
      }
      if (validHour) {
        val = formatHourMask(validHour);

        const beforeValue = await handlerOnChange({
          value: val,
          run: 'before',
        });

        if (beforeValue !== undefined) val = beforeValue;

        inputRef.current.value = val;

        if (onChange) {
          await onChange({ value: val });
        }

        const afterValue = await handlerOnChange({
          run: 'after',
        });

        if (afterValue !== undefined) val = afterValue;

        inputRef.current.setInputValue(val);
      }
    }
  }, [convertHour, handlerOnChange, onChange, t]);

  async function onSetCurrentTime() {
    const date = new Date();
    const currentTime = formatDateToTime(date);

    const eventResponse = await handlerOnSetCurrentValue({
      run: 'before',
      currentTime,
    });

    inputRef.current.setInputValue(eventResponse || currentTime);

    changed.current = true;

    await handlerOnSetCurrentValue({ run: 'after', currentTime });

    await handlerBlur();
  }

  const onBlur = useCallback(
    async e => {
      e.persist();

      await handlerBlur();
    },
    [handlerBlur]
  );

  function onFocus() {
    const text = inputRef.current.value;
    inputRef.current.getInputDOMNode().setSelectionRange(0, text.length);
  }

  const onChanged = useCallback(
    async e => {
      e.persist();
      await onBlur(e);
    },
    [onBlur]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.getInputDOMNode().addEventListener('changed', onChanged);
      inputRef.current.getInputDOMNode().addEventListener('focus', onFocus);
    }

    const inputRefCopy = inputRef.current;

    return () => {
      if (inputRefCopy) {
        inputRefCopy
          .getInputDOMNode()
          .removeEventListener('changed', onChanged);

        inputRefCopy.getInputDOMNode().removeEventListener('focus', onFocus);
      }
    };
  }, [isRequired, onChanged, selfField]);

  return (
    <>
      <FieldContainer
        hidden={isHidden}
        viewMode={!hidden || isUserField ? viewMode : 'normal'}
        onContextMenu={event => showContextMenu({ event })}
        readOnly={isReadOnly}
        labelPosition={labelPosition}
        style={
          isMobile
            ? {
                ...(selfField && selfField.style ? selfField.style : {}),
                ...(rest && rest.style ? rest.style : {}),
              }
            : {}
        }
      >
        {fieldTitle && (
          <Title
            highlight={isUserField && viewMode === 'design'}
            labelPosition={labelPosition}
            labelWidth={
              (selfField && selfField.labelWidth) || labelWidth || 130
            }
          >
            <div className="title">
              <div className="required-field">
                {fieldTitle}
                {isRequired && <b>*</b>}
              </div>
              <div className="title-icon">
                <CustomEvent
                  settings={settings}
                  selfField={selfField}
                  inputRef={inputRef}
                  formRef={formRef}
                  mainFormRef={mainFormRef}
                  auxScope={auxScope}
                />
              </div>
            </div>
            {error && <div className="error">{error}</div>}
          </Title>
        )}

        <PanelInput readOnly={isReadOnly || selfField.disabled}>
          <div>
            <Input
              ref={inputRef}
              defaultValue={defaultValue || ''}
              mask="99:99"
              {...selfField}
              {...rest}
              hidden={false}
              onBlur={onBlur}
              onChange={() => {
                changed.current = true;
              }}
              readOnly={isReadOnly}
              onKeyUp={({ key }) => {
                const isControlKey =
                  key === 'Backspace' ||
                  key === 'Delete' ||
                  key === 'Shift' ||
                  key === 'Tab' ||
                  key === 'Control';

                const isCharPermitted = charPermitted.includes(key);

                if (!isControlKey && !isCharPermitted) {
                  const date = new Date();
                  const currentTime = formatDateToTime(date);
                  inputRef.current.setInputValue(currentTime);
                }
              }}
            />

            {isMobile && !isReadOnly && !(selfField.disabled || false) && (
              <ButtonIcon
                onClick={onSetCurrentTime}
                style={{ width: '30px', height: '40px' }}
              >
                <FlashIcon size={22} color="#a7cee7 " />
              </ButtonIcon>
            )}
          </div>
        </PanelInput>
      </FieldContainer>

      <UserField
        baseName={baseName}
        fieldName={fieldName}
        formRef={formRef}
        mainFormRef={mainFormRef}
        settings={settings}
        // readOnly={readOnly}
        labelWidth={(selfField && selfField.labelWidth) || labelWidth || 130}
      />
    </>
  );
}

export default TimeField;
