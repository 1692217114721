/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import QueryService from '~/services/QueryService';
import TreatError from '~/easy-components/TreatError';
import Loader from '../Loader';
import Error from '../Error';
import { Container } from './styles';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

function ComponentBar(
  { data, widgetSettings, params, getColor, executeEvent, numberToString },
  ref
) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState({});
  const chartRef = useRef();
  const updateTimeRef = useRef();
  const [settings, setSettings] = useState(widgetSettings);

  const createDatasets = useCallback(
    firstLine => {
      const datasets = [];
      let counter = 0;
      for (const prop in firstLine) {
        if (widgetSettings.referenceField !== prop) {
          if (prop.indexOf('_') !== 0) {
            const color = getColor(counter);
            counter++;
            datasets.push({
              label: prop,
              data: [],
              backgroundColor: `${color}`,
              borderColor: color,
              borderWidth: 2,
              borderRadius: 5,
              borderSkipped: false,
            });
          }
        }
      }

      return datasets;
    },
    [getColor, widgetSettings.referenceField]
  );

  const createDataInfo = useCallback(
    ({ datasets, lines }) => {
      const labels = [];

      lines.forEach(line => {
        labels.push(line[widgetSettings.referenceField]);
        datasets.forEach(dataset => {
          dataset.data.push(line[dataset.label]);
        });
      });

      return {
        labels,
        datasets,
        lines,
      };
    },
    [widgetSettings.referenceField]
  );

  const mountData = useCallback(
    response => {
      const datasets = createDatasets(response[0]);

      const newInfo = createDataInfo({ datasets, lines: response });

      setInfo(newInfo);
    },
    [createDataInfo, createDatasets]
  );

  const run = useCallback(async () => {
    try {
      setError(null);
      setIsLoading(true);

      const response = await QueryService.execute(
        1,
        widgetSettings.query,
        params
      );

      const newSettings = await executeEvent({
        eventName: 'onGetSettings',
        data: { widgetSettings, data, params, value: response },
      });

      setSettings({
        ...widgetSettings,
        value: response,
        ...newSettings,
      });

      mountData(response);

      if (widgetSettings.updateTimeMinutes) {
        updateTimeRef.current = setTimeout(async () => {
          await run();
        }, widgetSettings.updateTimeMinutes * 1000 * 60);
      }
    } catch (e) {
      const errorDescription = TreatError.getDescription(e);
      setError(errorDescription);
    } finally {
      setIsLoading(false);
    }
  }, [data, executeEvent, mountData, params, widgetSettings]);

  useEffect(() => {
    run();

    return () => {
      clearTimeout(updateTimeRef.current);
    };
  }, [run, data]);

  useImperativeHandle(ref, () => {
    return {
      getData: () => {
        return info;
      },
    };
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  const onClick = async event => {
    const elements = getElementAtEvent(chartRef.current, event);
    if (elements.length > 0) {
      const { element } = elements[0];

      const line = info.lines[element.$context.index];

      await executeEvent({
        eventName: 'onClick',
        data: line,
        chart: chartRef.current,
        charData: info.lines,
      });
    }
  };

  return (
    <Container>
      <Bar
        ref={chartRef}
        data={info}
        onClick={onClick}
        options={{
          maintainAspectRatio: false,
          layout: {
            padding: 20,
          },
          plugins: {
            ChartDataLabels,
            datalabels: {
              align: 'end',
              anchor: 'end',
              backgroundColor(context) {
                return `${context.dataset.borderColor}30`;
              },
              borderRadius: 4,
              color(context) {
                return context.dataset.borderColor;
              },
              font: {
                weight: 'bold',
              },
              formatter: numberToString,
              padding: 6,
            },
            legend:
              info.datasets.length > 1
                ? {
                    position: 'bottom',
                  }
                : null,
          },
          ...settings.options,
        }}
      />
    </Container>
  );
}

export default forwardRef(ComponentBar);
