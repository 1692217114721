import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 20px;
  div {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      flex-direction: row;
    }
  }
`;
