import styled from 'styled-components';

export const Container = styled.div`
  width: 250px;
  flex-direction: column;
  display: flex;
  background-color: #dfe7e9;

  border-left: 1px solid #aaa;

  @media (max-width: 500px) {
    flex: 1;
    width: 100%;
    display: none;
    border-left: none;
  }
`;

export const Leg = styled.div`
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 10px 15px;
  color: #555;
  font-size: 0.8rem;

  & > div {
    margin-top: 4px;

    &:first-child {
      margin-top: 0px;
    }

    b {
      margin-right: 4px;
    }
  }
`;

export const Legs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  overflow: auto;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #72b2cb;
  padding: 5px 10px;
  color: #fff;
`;

export const Totals = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #72b2cb;
  padding: 5px 10px;
  color: #fff;
`;
