/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Toast from '~/easy-components/Toast';
import Modal from '~/easy-components/Modal';
import Icon from '~/easy-components/Icon';
import HeaderPage from '~/easy-components/HeaderPage';
import FooterPage from '~/easy-components/FooterPage';
import FlexSpace from '~/easy-components/FlexSpace';
import Button from '~/easy-components/Button';
import Loading from '~/easy-components/Loading';
import TreatError from '~/easy-components/TreatError';
import colors from '~/styles/colors';
import useLocale from '~/hooks/useLocale';
import tagSignatureService from '~/applications/CRM/services/TagSignatureService';
import ComponentContext from './context';
import ModalAuxFields from './components/ModalAuxFields';

// import MobileCam from './components/MobileCam';

import { Container, Content } from './styles';
import ModalContent from './components/ModalContent';

let html5QrCode;

function ModalCapture(_props = {}, ref) {
  const t = useLocale('_Global');

  const inputManualRef = useRef();
  const inputCollectorRef = useRef();
  const loadingRef = useRef();
  const modalAuxFieldsRef = useRef();
  const modalContentRef = useRef();

  const [state, setState] = useState({
    isShow: false,
    onValidate: async () => {
      return true;
    },
    auxFields: [],
    auxData: null,
    auxInfo: null,
    footer: null,
    backgroundColor: null,
    onConfirm: async () => {},
    onClose: async () => {},
  });

  function handlerClose(e) {
    e.stopPropagation();

    e.preventDefault();

    setState({
      ...state,
      isShow: false,
    });

    // elementRef.current.value = '';

    state.onClose({ modalRef: ref });
  }

  async function getTagData({ tagValue }) {
    const newTagValue = await state.onGetTagSignatureDataBefore({ tagValue });

    const tagInformation = await tagSignatureService.getData({
      tagValue: newTagValue || tagValue,
    });

    const newTagInformation = await state.onGetTagSignatureDataAfter({
      tagValue,
      tagInformation,
    });

    return newTagInformation || tagInformation;
  }

  async function readConfirm({ tagValue, tagData, elementRef }) {
    await state.onConfirm({
      tagData: {
        ...state.auxData,
        ...tagData,
      },
      modalRef: ref,
      tagValue,
    });

    if (elementRef.current) {
      elementRef.current.value = '';

      elementRef.current.focus();
    }
  }

  async function confirm({ tagValue, elementRef, isCamera = false }) {
    try {
      if (state.isCheckTagSignature) {
        loadingRef.current.show();

        const tagData = await getTagData({ tagValue });

        loadingRef.current.hide();

        if (tagData) {
          const isValid = await state.onValidate({ tagData, tagValue });

          if (isValid) {
            await readConfirm({ tagData, elementRef, tagValue });
          } else {
            if (elementRef.current) {
              elementRef.current.value = '';
            }

            if (isCamera) {
              modalContentRef.current.startCamera();
              modalContentRef.current.songError();
            }
          }
        } else {
          Toast.warn(t('message.TagNotFound'));
          if (elementRef.current) {
            elementRef.current.value = '';
          }
          if (isCamera) {
            modalContentRef.current.startCamera();
            modalContentRef.current.songError();
          }
        }
      } else {
        await readConfirm({ tagData: null, elementRef, tagValue });
      }
    } catch (error) {
      if (elementRef.current) {
        elementRef.current.value = '';
      }
      TreatError.showError(error);
      if (isCamera) {
        modalContentRef.current.startCamera();
        modalContentRef.current.songError();
      }
    }
  }

  useImperativeHandle(ref, () => {
    return {
      show: ({
        title = t('Capture'),
        message = null,
        auxFields = [],
        auxData = null,
        auxInfo = null,
        footer = null,
        backgroundColor = null,
        onConfirm = async () => {},
        onClose = async () => {},
        isCheckTagSignature = true,
        onGetTagSignatureDataBefore = async () => {},
        onGetTagSignatureDataAfter = async () => {},
        onValidate = async () => {
          return true;
        },
      }) => {
        setState({
          ...state,
          title,
          message,
          auxFields,
          auxData,
          auxInfo,
          footer,
          backgroundColor,
          onConfirm,
          onClose,
          onValidate,
          isShow: true,
          onGetTagSignatureDataBefore,
          onGetTagSignatureDataAfter,
          isCheckTagSignature,
        });
      },
      hide: () => {
        setState({
          ...state,
          isShow: false,
        });
      },
      focus: () => {
        modalContentRef.current.focus();
      },
    };
  });

  const onShowAuxFields = () => {
    modalAuxFieldsRef.current.show({
      params: state.auxData,
      auxInfo: state.auxInfo,
    });
  };

  return (
    <>
      <Modal isOpened={state.isShow}>
        <ComponentContext.Provider
          value={{
            t,
            confirm,
            inputManualRef,
            inputCollectorRef,
            message: state.message,
            auxInfo: state.auxInfo,
            backgroundColor: state.backgroundColor,
            html5QrCode,
          }}
        >
          <Container>
            <Loading ref={loadingRef} />
            <HeaderPage
              backgroundColor={colors.headerBg}
              color={colors.headerTitle}
              hideMainMenu
            >
              {state.title}
              {state.auxFields.length > 0 && (
                <button type="button" onClick={onShowAuxFields}>
                  <Icon name="MdInput" color="#fff" />
                </button>
              )}
            </HeaderPage>
            <Content>
              <ModalContent ref={modalContentRef} />
            </Content>
            <FooterPage>
              {state.footer}
              <FlexSpace />
              <Button buttonType="Emphasized" onClick={handlerClose}>
                {t('Close')}
              </Button>
            </FooterPage>
          </Container>
        </ComponentContext.Provider>
      </Modal>
      <ModalAuxFields
        t={t}
        ref={modalAuxFieldsRef}
        fields={state.auxFields}
        onClose={() => {
          modalContentRef.current.focus();
        }}
        onConfirm={async params => {
          const isValid = await state.onValidate({ tagData: params });

          if (isValid) {
            await state.onConfirm({ tagData: params, modalRef: ref });
          }
        }}
      />
    </>
  );
}

export default forwardRef(ModalCapture);
