/* eslint-disable import/no-cycle */
import CompanyService from '../services/CompanyService';
import RequestService from '../services/RequestService';

/**
 * @param {Object} params
 * @param {string} params.url
 * @param {string} params.method
 * @param {Object} params.data
 * @returns {Promise<any>}
 */
const request = async ({
  formRef = null,
  eventAlias = null,
  settings = null,
  url,
  method = 'get',
  data = null,
  largeData = null,
  file = null,
  timeout = 0 /** ate receber retorno */,
  headers = {},
  companyToken,
  cancelToken,
}) => {
  return RequestService.request({
    formRef,
    eventAlias,
    settings,
    url,
    token: companyToken || CompanyService.getCompanyToken(),
    method,
    data,
    largeData,
    file,
    timeout,
    headers: {
      ...headers,
    },
    cancelToken,
  });
};

export default request;
