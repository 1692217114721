import React from 'react';
import Timeline from '~/easy-components/Form/Timeline';

function TimelineTab({
  name,
  isReadOnly,
  settings,
  formRef,
  auxScope: externalScope,
}) {
  return <Timeline name={name} settings={settings} formRef={formRef} />;
}

export default TimelineTab;
