/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import JsonTable from '~/easy-components/JsonTable';
import TableMountService from '~/applications/CRM/services/TableMountService';

/**
 * @param {object} props
 * @param {string} props.title
 * @param {object[]} props.data
 * @param {object} props.settings
 * @param {object} props.reportSettings
 * @param {(tableProps: import('ka-table').IKaTableProps) => void} [props.onChange]
 * @param {(key: string, route: Object) => string} props.t
 * @param {Function} [props.header]
 * @param {Function} [props.onOpenInputLinkerInLine]
 * @param {object} [props.auxScope]
 * @param {Function} [props.showLoading]
 * @param {Function} [props.onEmpty]
 */
function ShowReport(
  {
    title,
    data,
    settings: appSettings,
    reportSettings: settings,
    onChange = () => {},
    onOpenInputLinkerInLine,
    auxScope,
    showLoading,
    t,
    header = null,
    onEmpty,
  },
  ref
) {
  const [report, setReport] = useState(null);
  const tableRef = useRef();

  const getReportData = useCallback(async () => {
    let parsedData = data;

    if (typeof data === 'string') {
      parsedData = JSON.parse(data);
    }

    const reportData = await TableMountService.execute({
      data: parsedData,
      settings,
    });

    setReport(reportData);

    return null;
  }, [data, settings]);

  useEffect(() => {
    getReportData();
  }, [getReportData]);

  useImperativeHandle(ref, () => ({
    ...tableRef.current,
    getData: () => tableRef.current.getData(),
  }));

  return (
    <>
      {report && (
        <JsonTable
          ref={tableRef}
          hideToolbar
          title={title}
          subTitle=""
          fileName=""
          data={report.data}
          columns={report.columns}
          rowKeyField={report.rowKeyField}
          groups={[]}
          t={t}
          settings={appSettings}
          hasFilter={report.reportSettings && report.reportSettings.hasFilter}
          reportSettings={report.reportSettings}
          onChange={onChange}
          onOpenInputLinkerInLine={onOpenInputLinkerInLine}
          auxScope={auxScope}
          showLoading={showLoading}
          header={header}
          // appDispatch={dispatch}
          onEmpty={onEmpty}
        />
      )}
    </>
  );
}

export default forwardRef(ShowReport);
