/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { sendData } from '~/store/modules/log/actions';

// Biblioteca para controle de area de transferência use-clippy
import Modal from '~/easy-components/Modal';
import Switch from '~/easy-components/Switch';
import EasyIcon from '~/easy-components/Icon';
import useLocale from '~/hooks/useLocale';
import { createAsyncFunctionByString } from '~/easy-components/AsyncFunctionString';

import useOutClick from '~/hooks/useOutClick';
import { Container, Content, Menus, Item, ItemIcon } from './styles';

import MenuMobile from './Mobile';

function MenuButton({
  menus: systemMenus,
  icon: Icon,
  color,
  position = null,
  size = null,
  onOpen = null,
  settings = {},
  children = null,
  onBeforeShow = async () => {},
  auxScope = null,
  auxData = null,
  ...rest
}) {
  const t = useLocale('_Global');

  const dispatch = useDispatch();

  const showLog = useSelector(({ log }) => log.isShow);

  const modalRef = useRef();
  const [isOpen, setOpen] = useState(false);
  const [isOpenMobile, setOpenMobile] = useState(false);
  const [clipboard, setClipboard] = useState();
  const [menus, setMenus] = useState([]);

  useOutClick(modalRef, isOpen, () => {
    setOpen(false);
  });

  function onClick(e) {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  }

  const setTextToClipboard = async text => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    }
  };

  const handlerOpen = useCallback(async () => {
    // e.stopPropagation();
    // e.preventDefault();

    const response = await onBeforeShow([...menus]);

    if (Array.isArray(response) && response.length === 0) {
      setMenus(menus.map(menu => ({ ...menu, disabled: true })));
      setOpen(true);
      return;
    }

    if (Array.isArray(response)) {
      setMenus(response);
    }

    let text = '';

    // TODO: Clipboard not work firefox

    if (navigator.clipboard) {
      text = await navigator.clipboard.readText();
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (onOpen) {
      onOpen({ menuList: menus, clipboard: text, setTextToClipboard });
    }

    setClipboard(text);
    setOpen(true);
  }, [menus, onBeforeShow, onOpen]);

  useEffect(() => {
    if (
      (auxScope && auxScope.form !== undefined) ||
      auxScope === null ||
      auxScope === undefined
    ) {
      const newMenus = [];

      systemMenus.forEach(menu => {
        if (typeof menu.handler === 'string') {
          const dynamicFunction = createAsyncFunctionByString({
            functionString: menu.handler,
          });

          menu.handler = async (props, auxProps) => {
            return dynamicFunction({
              e: props,
              ...auxProps,
            });
          };

          const id = format(new Date(), 'yyyyMMdd-HHmmss.SSSSS');

          newMenus.push({
            _id: id,
            ...menu,
          });
        } else {
          newMenus.push(menu);
        }
      });

      if (newMenus.length > 0) {
        setMenus(newMenus);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemMenus, auxScope]);

  const scopeMenu = {
    clipboard,
    setTextToClipboard,
    ...settings.dynamicFunctionProps,
    ...auxScope,
    ...auxData,
    ...rest,
  };

  return (
    <Container
      ref={modalRef}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Switch
        mobile={
          <>
            <Content
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setOpenMobile(true);
              }}
            >
              {children || <Icon size={size} color={color} />}
            </Content>
            <Modal isOpened={isOpenMobile} direction="right">
              <MenuMobile
                menus={menus}
                onClose={() => setOpenMobile(false)}
                settings={settings}
                scopeMenu={scopeMenu}
                t={t}
              />
            </Modal>
          </>
        }
        computer={
          <Popup
            trigger={
              <Content onMouseDown={onClick}>
                {children || <Icon size={size} color={color} />}
              </Content>
            }
            open={isOpen}
            position={position}
            onOpen={handlerOpen}
            onClose={e => {
              if (e) {
                e.stopPropagation();
                e.preventDefault();
              }
              setOpen(false);
            }}
          >
            <Menus>
              {menus.map(menu => {
                let disabled =
                  menu.disabled === undefined ? false : menu.disabled;
                let visibled = menu.visible === undefined ? true : menu.visible;

                if (settings) {
                  if (settings.menus) {
                    const menuSettings = settings.menus.find(
                      mnu => mnu.name === menu.id
                    );
                    if (menuSettings) {
                      disabled = menuSettings.disabled || disabled;

                      if (menuSettings.hidden) visibled = false;
                    }
                  }
                }

                if (!visibled) return null;

                return (
                  <Item
                    key={menu.text}
                    disabled={disabled}
                    visible={visibled}
                    onMouseDown={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setOpen(false);

                      if (!menu.disabled) {
                        menu.handler(e, scopeMenu);
                      }
                    }}
                    onMouseEnter={() => {
                      if (showLog)
                        dispatch(sendData({ name: menu.id, value: menu.text }));
                    }}
                  >
                    {menu.icon && (
                      <ItemIcon>
                        <EasyIcon
                          name={menu.icon}
                          size={18}
                          color={menu.color || '#555'}
                        />
                      </ItemIcon>
                    )}
                    <div> {menu.text} </div>
                  </Item>
                );
              })}
            </Menus>
          </Popup>
        }
      />
    </Container>
  );
}

MenuButton.propTypes = {
  icon: PropTypes.func.isRequired,
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      handler: PropTypes.func,
    })
  ).isRequired,
  position: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  onOpen: PropTypes.func,
};

MenuButton.defaultProps = {
  position: 'left center',
  color: '#333',
  size: 18,
  onOpen: null,
};

export default MenuButton;
