import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoIosNotifications as NotificationIcon } from '@react-icons/all-files/io/IoIosNotifications';

import { Container, Content, Button, Tasks } from './styles';
import Task from './Task';
import useLocale from '~/hooks/useLocale';
import { removeTask } from '~/store/modules/tasks/actions';
import useOutClick from '~/hooks/useOutClick';

function AsyncTask() {
  const dispatch = useDispatch();

  const wrapperRef = useRef(null);

  const [visibleTasks, setVisibleTasks] = useState(false);
  const [visible, setVisible] = useState(false);

  const t = useLocale('_Global');

  useOutClick(wrapperRef, visible, () => {
    setVisibleTasks(false);
  });

  const taskList = useSelector(({ tasks }) => tasks);

  function onRemove(id) {
    dispatch(removeTask(id));
  }

  function onHideTasks() {
    setVisibleTasks(false);
  }

  useEffect(() => {
    if (taskList.length > 0) {
      setVisible(true);
    } else {
      setVisibleTasks(false);
      setVisible(false);
    }
  }, [taskList]);

  return (
    <Container ref={wrapperRef} visible={visible}>
      <Button onClick={() => setVisibleTasks(!visibleTasks)}>
        <NotificationIcon color="#fff" size={20} />
      </Button>

      <Content visible={visibleTasks}>
        <h1>{t('Notifications')}</h1>
        <Tasks>
          {taskList.map(task => {
            return (
              <Task
                key={task.id}
                id={task.id}
                name={task.name}
                executionDate={task.__date}
                task={task}
                onRemove={onRemove}
                onHideTasks={onHideTasks}
              />
            );
          })}
        </Tasks>
      </Content>
    </Container>
  );
}

export default AsyncTask;
