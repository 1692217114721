/* eslint-disable import/no-cycle */
/* eslint-disable react/button-has-type */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';
import ModalHandle from '~/easy-components/ModalHandle';
import Button from '~/easy-components/Button';
import FlexSpace from '~/easy-components/FlexSpace';
import FooterPage from '~/easy-components/FooterPage';
import useLocale from '~/hooks/useLocale';
import { Container } from './styles';
import ApplicationPage from '~/pages/Applications';
import UserPages from '~/applications/CRM/pages/UserPages';
import useHelpers from '~/hooks/useHelpers';

// eslint-disable-next-line no-unused-vars
function ApplicationModal(_, ref) {
  const t = useLocale('_Global');

  const {
    dynamicFunctionProps: { getApplicationSettings },
  } = useHelpers();

  const modalRef = useRef();

  const [properties, setProperties] = useState(null);

  useImperativeHandle(ref, () => {
    return {
      show: async props => {
        const isUserPage =
          props.initialData.toUpperCase().indexOf('USERPAGE') > -1;

        const settings =
          (await getApplicationSettings({
            pageRoute: isUserPage ? 'UserPages' : 'Applications',
          })) || {};

        setProperties({
          ...props,
          settings,
          _isUserPage: isUserPage,
        });

        modalRef.current.handleOpen();
      },
      close: () => {
        modalRef.current.handleClose();
      },
    };
  });

  //
  return (
    <ModalHandle
      ref={modalRef}
      contentStyle={{ style: { width: '95%', height: '95%' } }}
    >
      {properties && (
        <Container>
          {properties._isUserPage ? (
            <UserPages {...properties} />
          ) : (
            <ApplicationPage {...properties} />
          )}

          <FooterPage>
            <FlexSpace />
            <Button
              onClick={() => {
                modalRef.current.handleClose();
              }}
            >
              {t('Close')}
            </Button>
          </FooterPage>
        </Container>
      )}
    </ModalHandle>
  );
}

export default forwardRef(ApplicationModal);
