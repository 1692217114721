import { useDispatch } from 'react-redux';
import { setVisibleMenu as setVisibleMenuAction } from '~/store/modules/mainMenu/actions';

export default function useMainManu() {
  const dispatch = useDispatch();

  const setVisibleMenu = status => {
    dispatch(setVisibleMenuAction(status));
  };

  return { setVisibleMenu };
}
