/* eslint-disable import/no-cycle */
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '~/easy-components/Button';
import { showLog } from '~/store/modules/log/actions';
import { setViewMode } from '~/store/modules/uiDesign/actions';
import useLocale from '~/hooks/useLocale';
import useUiDesigner from '..';
import useHelpers from '~/hooks/useHelpers';

function FinishButton({ pageId }) {
  const t = useLocale('_Global');

  const {
    dynamicFunctionProps: { showDisplayModal, logRef },
  } = useHelpers();

  const dispatch = useDispatch();

  const { viewMode } = useUiDesigner({
    pageId,
    name: 'designFinishButton',
    componentType: 'button',
    enableDesignMode: true,
  });

  const onClose = () => {
    dispatch(setViewMode({ pageId, viewMode: 'normal' }));
    dispatch(showLog(false));
  };

  return (
    <Button
      hidden={viewMode !== 'design'}
      buttonType="Emphasized"
      onClick={() => {
        const hasChanges = logRef.current.hasChanges();
        if (hasChanges) {
          showDisplayModal({
            type: 'question',
            title: t('EventsWillBeLost'),
            message: t('message.EventsWillBeLost'),
            onConfirm: () => {
              logRef.current.clearEvents();
              onClose();
            },
          });
        } else {
          onClose();
        }
      }}
      style={{ backgroundColor: '#00000050' }}
    >
      {t('DesignFinish')}
    </Button>
  );
}

export default FinishButton;
