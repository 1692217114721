import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  @media (max-width: 500px) {
    min-height: 100vh;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;
