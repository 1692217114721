/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Panel from '~/easy-components/Panel';
import Input from '~/easy-components/Form/Input';
import InputIcon from '~/easy-components/Form/InputIcon';
import Linker from '~/easy-components/Form/Linker';
import { PageContext } from '../index';
import MainMenuService from '~/services/MainMenuService';
import ApplicationService from '~/services/ApplicationService';
import UserTableService from '~/services/UserTableService';

function GeneralTab() {
  const {
    settings,
    formRef,
    isReadOnly,
    refreshHeader,
    t,
    tMenus,
    state,
    setState,
    setIsSettingsReadOnly,
    setPageSettings,
    defaultSettings,
  } = useContext(PageContext);

  const fieldProps = {
    settings,
    formRef,
    readOnly: isReadOnly,
    labelWidth: 150,
  };

  return (
    <ColumnLayout>
      <Panel width="330px">
        <Input name="id" {...fieldProps} hidden />
        <Input name="hash" {...fieldProps} hidden />
        <Input
          label={t('Name')}
          name="name"
          {...fieldProps}
          onChange={() => {
            refreshHeader();
          }}
        />
        <Linker
          label={t('Type')}
          name="type"
          valueField="type"
          {...fieldProps}
          fixedData={[
            {
              value: 'custom',
              label: t('Custom'),
            },
            {
              value: 'internal',
              label: t('InternalUrl'),
            },
            {
              value: 'external',
              label: t('ExternalUrl'),
            },
            {
              value: 'userTable',
              label: t('UserTable'),
            },
            {
              value: 'udo',
              label: t('UDO'),
            },
          ]}
          onChange={data => {
            formRef.current.setFieldValue('internalUrl', null);
            formRef.current.setFieldValue('externalUrl', null);

            const value = data ? data.value : null;

            switch (value) {
              case 'custom':
                setState({
                  external_hidden: true,
                  internal_hidden: true,
                  userTableHidden: true,
                  udoHidden: true,
                });
                setPageSettings(defaultSettings);
                break;

              case 'internal':
                setState({
                  external_hidden: true,
                  internal_hidden: false,
                  userTableHidden: true,
                  udoHidden: true,
                });
                setPageSettings(defaultSettings);
                break;

              case 'external':
                setState({
                  external_hidden: false,
                  internal_hidden: true,
                  userTableHidden: true,
                  udoHidden: true,
                });
                formRef.current.setFieldValue('openMode', 'external');
                setPageSettings('{}');
                setIsSettingsReadOnly(true);
                break;
              case 'userTable':
                setState({
                  external_hidden: true,
                  internal_hidden: true,
                  userTableHidden: false,
                  udoHidden: true,
                });
                setPageSettings('{}');
                setIsSettingsReadOnly(true);
                break;

              case 'udo':
                setState({
                  external_hidden: true,
                  internal_hidden: true,
                  userTableHidden: true,
                  udoHidden: false,
                });
                setPageSettings('{}');
                setIsSettingsReadOnly(true);
                break;

              default:
                break;
            }
          }}
        />
        <Input
          hidden={state.external_hidden}
          label={t('ExternalUrl')}
          name="externalUrl"
          {...fieldProps}
        />
        <Linker
          hidden={state.external_hidden}
          label={t('openMode')}
          name="openMode"
          valueField="openMode"
          {...fieldProps}
          fixedData={[
            {
              value: 'internal',
              label: t('Internal'),
            },
            {
              value: 'external',
              label: t('External'),
            },
          ]}
        />
        <Linker
          label={t('InternalUrl')}
          name="internalUrl"
          valueField="internalUrl"
          hidden={state.internal_hidden}
          {...fieldProps}
          method={async filter => {
            const appList = await ApplicationService.list({
              filter,
            });
            return appList;
          }}
          propFieldLabel="route"
          propFieldValue="route"
        />
        <Linker
          label={t('UserTable')}
          hidden={state.userTableHidden}
          name="_userTable"
          valueField="userTable"
          {...fieldProps}
          method={async filter => {
            const userTables = await UserTableService.getNoObjectSearchList({
              filter,
            });

            return userTables;
          }}
        />
        <Linker
          label={t('UDO')}
          hidden={state.udoHidden}
          name="_udo"
          valueField="udo"
          {...fieldProps}
          method={async () => {
            return [];
          }}
        />

        <InputIcon label={t('Icon')} name="icon" {...fieldProps} />

        <Linker
          label={t('Menu')}
          name="menu.name"
          valueField="menu.id"
          {...fieldProps}
          method={async filter => {
            const menus = await MainMenuService.list(filter);

            const translatedMenus = menus.map(menu => {
              return {
                name: tMenus(menu.name),
                id: menu.id,
              };
            });
            return translatedMenus;
          }}
          propFieldLabel="name"
          propFieldValue="id"
        />

        <Linker
          name="status"
          valueField="status"
          label={t('Status')}
          {...fieldProps}
          fixedData={[
            { value: '1', label: t('Available') },
            { value: '0', label: t('Unavailable') },
          ]}
          onChange={() => {
            refreshHeader();
          }}
        />
      </Panel>
    </ColumnLayout>
  );
}

export default GeneralTab;
