import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

// eslint-disable-next-line react/prop-types
function Element({ children, onDragStart, onDragEnd, isReadOnly, ...rest }) {
  const handlerDragStart = useCallback(
    e => {
      e.target.classList.add('is-dragging');
      onDragStart(e);
    },
    [onDragStart]
  );

  const handlerDragEnd = useCallback(
    e => {
      e.target.classList.remove('is-dragging');
      onDragEnd(e);
    },
    [onDragEnd]
  );

  return (
    <Container
      {...rest}
      draggable={!isReadOnly}
      onDragStart={handlerDragStart}
      onDragEnd={handlerDragEnd}
    >
      <div>{children}</div>
    </Container>
  );
}

Element.propType = {
  children: PropTypes.element.isRequired,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

Element.defaultProps = {
  onDragStart: () => {},
  onDragEnd: () => {},
  isReadOnly: false,
};

export default Element;
