import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Container } from './styles';

function Cropper({ src, cropFullImage }, ref) {
  const [crop, setCrop] = useState({});

  const [completedCrop, setCompletedCrop] = useState();

  const imageRef = useRef();

  useImperativeHandle(ref, () => {
    return {
      getCroppedArea: () => {
        return { crop: completedCrop, image: imageRef.current };
      },
    };
  });

  function centerAspectCrop(mediaWidth, mediaHeight, percent) {
    const cropWidth = mediaWidth * percent;
    const cropHeight = mediaHeight * percent;

    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: cropWidth,
          height: cropHeight,
        },
        mediaWidth / mediaHeight,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    const cropPercent = 0.8;

    let onImageLoadCrop;
    if (!cropFullImage) {
      onImageLoadCrop = centerCrop(
        makeAspectCrop(
          {
            unit: 'px',
            width,
          },
          1 / 2,
          width - 70,
          height - 40
        ),
        width,
        height
      );
    } else {
      onImageLoadCrop = centerAspectCrop(width, height, cropPercent);
    }

    setCrop(onImageLoadCrop);
    setCompletedCrop(onImageLoadCrop);
  }

  return (
    <Container>
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => {
          setCrop(percentCrop);
        }}
        keepSelection
        onComplete={c => {
          setCompletedCrop(c);
        }}
      >
        <img
          ref={imageRef}
          alt="Crop me"
          src={src}
          onLoad={onImageLoad}
          style={{ transform: `scale(${1}) rotate(${0}deg)` }}
        />
      </ReactCrop>
    </Container>
  );
}

export default forwardRef(Cropper);
