import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const Content = styled.div`
  background-color: #000;
  flex: 1;
  display: flex;
  flex-direction: column;

  video {
    height: 100%;
  }

  /* Estilização da barra de rolagem */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #333;
  }

  &::-webkit-scrollbar-thumb {
    background: #555;
  }
`;
