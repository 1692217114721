const createMenu = ({ tabName, isCustomTab }) => {
  const menus = [
    {
      type: 'boolean',
      prop: 'hidden',
      defaultValue: false,
      options: [
        {
          icon: 'FaEye',
          title: 'ShowField',
          value: false,
        },
        {
          icon: 'FaEyeSlash',
          title: 'HiddenField',
          value: true,
        },
      ],
      tabName,
      disabled: true,
      visible: false,
    },
    {
      icon: 'TiFolderDelete',
      title: 'RemoveTab',
      prop: 'components',
      type: 'deleteTab',
      iconColor: '#ff9999',
      tabName,
      disabled: !isCustomTab,
    },
  ];

  return menus;
};

export default createMenu;
