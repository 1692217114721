/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
import request from '../../Request';

class BaseMasterDataService {
  constructor({ route, primaryKeyField = 'Code', isLargeData = false }) {
    this.route = route;
    this.primaryKeyField = primaryKeyField;
    this.isLargeData = isLargeData;
  }

  async get(key) {
    return request({
      url: `APP/CRM/${this.route}/${key}`,
    });
  }

  async save({ settings, data }) {
    const { file, ...dataToSave } = data;

    if (data[this.primaryKeyField]) {
      return request({
        url: `APP/CRM/${this.route}/${data[this.primaryKeyField]}`,
        method: 'PUT',
        settings,
        data: dataToSave,
        largeData: this.isLargeData ? data : null,
        file: file || null,
      });
    }

    return request({
      url: `APP/CRM/${this.route}`,
      method: 'POST',
      settings,
      data: dataToSave,
      largeData: this.isLargeData ? data : null,
      file: file || null,
    });
  }

  async delete(key) {
    return request({
      url: `APP/CRM/${this.route}/${key}`,
      method: 'DELETE',
    });
  }

  async list({ filter, auxFilters }) {
    let filters = '';

    for (const prop in auxFilters) {
      filters += `&${prop}=${auxFilters[prop]}`;
    }

    return request({
      url: `APP/CRM/${this.route}?$filter=${filter || ''}${filters}`,
    });
  }

  async search({ filter, auxFilters }) {
    let filters = '';

    for (const prop in auxFilters) {
      filters += `&${prop}=${auxFilters[prop]}`;
    }

    return request({
      url: `APP/CRM/${this.route}/search?$filter=${filter || ''}${filters}`,
    });
  }
}

export default BaseMasterDataService;
