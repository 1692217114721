/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Modal from '../../Modal';

// import useLocale from '~/hooks/useLocale';

import Detail from './Detail';
import { Container } from './styles';

function ErrorInformation({ errors, translateRoute }) {
  // const t = useLocale(translateRoute);

  const [isOpen, setIsOpen] = useState(false);

  const errorCount = errors ? Object.keys(errors).length : 0;

  function onShowErrors() {
    setIsOpen(true);
  }

  return (
    <>
      {errorCount > 0 && (
        <Container onClick={onShowErrors}>
          Verifique os erros e tente novamente. Clique aqui para maiores
        </Container>
      )}
      <Modal isOpened={isOpen}>
        <Detail errors={errors} onClose={() => setIsOpen(false)} />
      </Modal>
    </>
  );
}

export default ErrorInformation;
