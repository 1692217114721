import React from 'react';
import PropTypes from 'prop-types';

import { Container, Subject } from './styles';

function DefaultEventView({ event, titleAccessor }) {
  return (
    <Container>
      <Subject>{event[titleAccessor]}</Subject>
    </Container>
  );
}

DefaultEventView.propTypes = {
  event: PropTypes.shape({
    Subject: PropTypes.string.isRequired,
  }).isRequired,
};

export default DefaultEventView;
