import React from 'react';
import isValidJson from '~/easy-components/Helpers/isValidJson';
import { FakeInput } from '../styles';

/**
 * @typedef {Object} Props
 * @property {string | number} value - Valor para ser exibido. Essa propriedade
 * possui 5 variações:
 *
 * 1. Quando o valor for apenas uma string e não existir a prop fixedData,
 * apenas exibe o valor
 *
 * 2. Quando o valor for uma string em formato JSON, exibe
 * o valor da propriedade label dentro do objeto JSON
 *
 * 3. Quando o valor vier no formato "[value]+[label]", exibe o label
 *
 * 4. Quando o valor for uma string simples e existir a prop fixedData,
 * significa que o valor é o value do linker e a prop fixedData contém o label
 *
 * 5. Quando o valor for vazio, exibe null
 *
 * @property {{value: any, label: string}[]} [fixedData]
 * @property {object} [style]
 * @property {boolean} [readOnly]
 */

/**
 * Componente que renderiza apenas o label de um linker
 * @param {Props} props
 * @return {JSX.Element}
 */
const ReadOnlyLinker = ({ value, fixedData = [], style, readOnly }) => {
  if (!value) {
    return (
      <FakeInput
        minWidth={style && style.width}
        style={style}
        readOnly={readOnly}
      />
    );
  }

  if (isValidJson(value) && Object.keys(JSON.parse(value)).includes('label')) {
    return (
      <FakeInput
        minWidth={style && style.width}
        style={style}
        readOnly={readOnly}
      >
        {JSON.parse(value).label || JSON.parse(value).value}
      </FakeInput>
    );
  }

  if (value.includes('+ ')) {
    const [, label] = value.split('+');
    return (
      <FakeInput
        minWidth={style && style.width}
        style={style}
        readOnly={readOnly}
      >
        {label}
      </FakeInput>
    );
  }

  if (fixedData.length) {
    const { label } = fixedData.find(({ value: id }) => id === value) || {};

    if (label)
      return (
        <FakeInput
          minWidth={style && style.width}
          style={style}
          readOnly={readOnly}
        >
          {label}
        </FakeInput>
      );
  }

  return (
    <FakeInput
      minWidth={style && style.width}
      style={style}
      readOnly={readOnly}
    >
      {value}
    </FakeInput>
  );
};

export default ReadOnlyLinker;
