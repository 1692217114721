/* eslint-disable react/prop-types */
import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import Monaco from '@monaco-editor/react';
import { createIntelisense } from '~/hooks/useUiDesigner/intelisenses/dynamicFunctions';
import { Container, TBar } from './styles';

function Editor(
  {
    tbar,
    value,
    onChange,
    language,
    hidden = false,
    onCtrlS,
    onKeyDown,
    auxIntelisenses,
  },
  ref
) {
  const monacoRef = useRef();
  const monRef = useRef();
  const monacoMountRef = useRef();

  const keyDownHandler = event => {
    if (onKeyDown) {
      onKeyDown(event);
      return;
    }

    const char_S = 83;
    const char_E = 69;
    const char_ESC = 27;
    const char_R = 82;

    /* const input = inputRef.current._input;
    const start = input.selectionStart;
    const end = input.selectionEnd; */

    if (event.ctrlKey) {
      switch (event.keyCode) {
        case char_S:
          event.stopPropagation();
          event.preventDefault();

          if (onCtrlS) {
            onCtrlS({ value, event });
            // setFocus(start, end);
          }
          break;

        case char_E:
          /* if (editorType === 'sql') {
            event.stopPropagation();
            event.preventDefault();
            executeSql(value);
            setIsShowSqlConsole(true);
            setFocus(start, end);
          } */
          break;

        case char_R:
          /* if (editorType === 'sql') {
            event.stopPropagation();
            event.preventDefault();

            setIsShowSqlConsole(!isShowSqlConsole);
            setFocus(start, end);
          } */
          break;

        default:
          break;
      }
    }

    switch (event.keyCode) {
      case char_ESC:
        /* setExpanded(!isExpanded);
        setFocus(start, end); */
        break;

      default:
        break;
    }
  };

  const handleEditorWillMount = monaco => {
    /* monaco.languages.typescript.javascriptDefaults.addExtraLib(`
      declare function executeLucas(sql: string, data: object): Promise<any>;
    `); */

    const intelisense = createIntelisense(auxIntelisenses);

    monaco.languages.typescript.javascriptDefaults.setExtraLibs([]);

    monaco.languages.typescript.javascriptDefaults.addExtraLib(intelisense);
  };

  useImperativeHandle(ref, () => {
    return {
      getEditor: () => {
        return monacoRef.current;
      },
      focus: () => {
        setTimeout(() => {
          if (monacoMountRef.current) {
            monacoMountRef.current.focus();
          }
        }, 300);
      },
    };
  });

  return (
    <>
      {tbar && !hidden && <TBar>{tbar}</TBar>}
      <Container
        style={{ display: hidden ? 'none' : 'flex', flex: 1 }}
        onKeyDown={keyDownHandler}
      >
        <Monaco
          ref={monacoRef}
          theme="vs-dark"
          options={{
            selectOnLineNumbers: true,
          }}
          language={language}
          defaultLanguage={language}
          value={value || ''}
          onChange={onChange}
          beforeMount={handleEditorWillMount}
          onMount={(editor, monaco) => {
            monacoMountRef.current = editor;

            monRef.current = monaco;

            /* const intelisense = createIntelisense(auxIntelisenses);

            monRef.current.languages.typescript.javascriptDefaults.setExtraLibs(
              []
            );

            monRef.current.languages.typescript.javascriptDefaults.addExtraLib(
              intelisense
            ); */

            // editor.focus();
          }}
        />
      </Container>
    </>
  );
}

export default forwardRef(Editor);
