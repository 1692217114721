import React from 'react';
import { useSelector } from 'react-redux';

import Loading from '~/easy-components/Loading';

function LoadingWrapper() {
  const isLoading = useSelector(({ loading }) => loading.status);
  return <Loading isLoading={isLoading} />;
}

export default LoadingWrapper;
