import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;

  * {
    font-size: 0.8rem;
  }

  .ka-cell {
    line-height: normal;
  }

  .ka-thead-cell {
    height: 10px !important;
    padding: 10px 20px !important;
  }

  td {
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
