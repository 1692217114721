export default function normalizeFieldValue(field) {
  let fieldValue = null;

  if (field == null) {
    fieldValue = null;
  } else if (typeof field === 'object') {
    fieldValue = field.value;
  } else if (typeof field === 'string' && field.indexOf('{') >= 0) {
    fieldValue = JSON.parse(field).value;
  } else {
    fieldValue = field;
  }

  return fieldValue;
}
