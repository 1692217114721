import React, { useRef, useEffect } from 'react';
import Form from '~/easy-components/Form';
import Gantt from '~/easy-components/Form/Gantt';

function GanttReport({ settings, reportSettings, params, tasks }) {
  const formRef = useRef();

  useEffect(() => {
    if (formRef.current && reportSettings) {
      formRef.current.getFieldRef('report').setData(tasks);
    }
  }, [tasks, reportSettings]);

  if (reportSettings === undefined || reportSettings === null) {
    return null;
  }

  return (
    <Form ref={formRef} settings={reportSettings}>
      <Gantt
        name="report"
        formRef={formRef}
        auxScope={{ params, settings }}
        settings={{
          components: [
            {
              fieldName: 'report',
              type: 'tabGantt',
              ...reportSettings,
            },
          ],
        }}
      />
    </Form>
  );
}

export default GanttReport;
