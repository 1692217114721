/* eslint-disable import/no-cycle */
/* eslint-disable no-await-in-loop */
import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import useCompany from '~/hooks/useCompany';
import TreatError from '~/easy-components/TreatError';
import Icon from '~/easy-components/Icon';
import AuxInfo from '~/easy-components/AuxInfo';
import { sendEvent } from '../../HandlerEvent/index';
// import useUiDesigner from '~/hooks/useUiDesigner';
import { Container, Content, Text, Info, Element, ElementInfo } from './styles';
import 'react-vertical-timeline-component/style.min.css';

function Timeline({
  name,
  formRef,
  settings,
  statusSettings,
  onClick,
  hasSeconds,
  // isUserField,
  // customTabName,
}) {
  /* const { showContextMenu } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'field',
    baseName: null,
    name,
    title: null,
    isUserField,
    customTabName,
  }); */

  const { components, fields } = settings || {};

  let selfField;

  if (components) {
    selfField = components.find(
      component =>
        component.type === 'tabTimeline' &&
        component.fieldName.toUpperCase() === name.toUpperCase()
    );
  }

  if (!selfField) {
    selfField = (fields ? fields.find(f => f.name === name) : {}) || {};
  }

  const { dateToString } = useCompany();
  const elementRef = useRef();
  const dataRef = useRef();

  const [data, setData] = useState([]);

  const statusList = selfField.statusSettings || statusSettings || [];

  const hasTimeSeconds = selfField.hasSeconds || hasSeconds || false;

  const { events } = selfField;

  let isClickable = !!onClick;

  if (events) {
    const onClickEvent = events.find(event => event.name === 'onClick');
    isClickable = !!onClickEvent || isClickable;
  }

  const { fieldName, defaultValue = '', registerField } = useField(name);

  // eslint-disable-next-line no-shadow
  const handleClick = async ({ element, list, statusList }) => {
    try {
      await sendEvent({
        settings,
        formRef,
        eventName: 'onClick',
        run: 'before',
        mainFormRef: null,
        events: selfField.events,
        list,
        data: {
          element,
          statusList,
        },
      });

      if (onClick) {
        await onClick({ element, list, statusList });
      }

      await sendEvent({
        settings,
        formRef,
        eventName: 'onClick',
        run: 'after',
        mainFormRef: null,
        events: selfField.events,
        list,
        data: {
          element,
          statusList,
        },
      });
    } catch (error) {
      TreatError.showError(error);
    }
  };

  const formatTime = numberTime => {
    if (numberTime) {
      let padParameter = 4;

      if (hasTimeSeconds) padParameter = 6;

      const time = numberTime.toString().padStart(padParameter, '0');

      const hours = time.substring(0, 2);
      const minutes = time.substring(2, 4);
      const seconds = time.substring(4, 6);

      let timeFormatted = '';

      if (hasSeconds) {
        timeFormatted = `${hours}:${minutes}:${seconds}`;
      } else {
        timeFormatted = `${hours}:${minutes}`;
      }
      return timeFormatted;
    }

    return '';
  };

  const formatToRender = async list => {
    for (let cont = 0; cont < list.length; cont++) {
      const item = list[cont];

      const status =
        statusList.find(
          // eslint-disable-next-line eqeqeq
          sts => sts.reference == item.Status
        ) || {};

      const statusInformation = status.settings || {};

      item.statusInformation = statusInformation;

      item.actionDate = dateToString(item.CreatedAt);

      item.actionDateTime = formatTime(item.TimeAt);

      await sendEvent({
        settings,
        eventName: 'onRenderElement',
        run: 'before',
        mainFormRef: null,
        events: selfField.events,
        formRef,
        list: dataRef.current,
        data: {
          item,
        },
      });
    }

    return list;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: elementRef.current,
      path: 'value',
      clearValue: el => {
        el.value = null;

        setData([]);

        dataRef.current = [];
      },
      setValue: async (r, v) => {
        r.value = v;

        if (v) {
          const list = typeof v === 'string' ? JSON.parse(v) : v;
          await formatToRender(list);
          setData(list);
          dataRef.current = list;
        } else {
          setData([]);
          dataRef.current = [];
        }
      },
      getValue: () => {
        return dataRef.current;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <Container>
      <input
        hidden
        type="hidden"
        ref={elementRef}
        id={fieldName}
        defaultValue={defaultValue}
      />
      <Content>
        <VerticalTimeline>
          {data.map(element => {
            const { statusInformation = {} } = element;
            return (
              <Element arrowColor={statusInformation.backgroundColor}>
                <VerticalTimelineElement
                  key={element.id}
                  className="vertical-timeline-element--work"
                  date={
                    <Info>
                      <div>{element.actionDate}</div>
                      <div>{element.actionDateTime}</div>
                    </Info>
                  }
                  contentStyle={{
                    backgroundColor: statusInformation.backgroundColor,
                    padding: 0,
                  }}
                  iconStyle={statusInformation.iconStyle}
                  position={element.Position}
                  icon={
                    <Icon
                      color={statusInformation.iconColor || '#444'}
                      name={statusInformation.icon}
                      size={24}
                    />
                  }
                >
                  <ElementInfo
                    style={{
                      cursor: isClickable ? 'pointer' : 'default',
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      handleClick({ element, list: data, statusList });
                    }}
                  >
                    <Text
                      style={{
                        color: statusInformation.color,
                      }}
                    >
                      {element.text || statusInformation.text}
                    </Text>
                    <AuxInfo
                      text={element.AuxInfo || element.auxInfo}
                      style={{
                        color: statusInformation.color,
                      }}
                    />
                  </ElementInfo>
                </VerticalTimelineElement>
              </Element>
            );
          })}
        </VerticalTimeline>
      </Content>
    </Container>
  );
}

export default Timeline;
