/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import TextArea from '~/easy-components/Form/TextArea';
import { Container, Comments } from '../styles';

// eslint-disable-next-line import/no-cycle
import { PageContext } from '../index';

export default function RemarksTab() {
  const { settings, formRef, isReadOnly } = useContext(PageContext);

  return (
    <Container flexible>
      <Comments>
        <TextArea
          name="remarks"
          settings={settings}
          formRef={formRef}
          readOnly={isReadOnly}
        />
      </Comments>
    </Container>
  );
}
