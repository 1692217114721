/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import { Container, InputElement } from './styles';

export default function Input({
  name,
  label,
  hidden,
  formRef,
  events,
  settings,
  readOnly: readOnlyProp,
  ...rest
}) {
  const { userFields, fields } = settings || {};
  const selfField = fields ? fields[name] : null;

  const inputRef = useRef(null);

  const [readOnlyElement, setReadOnlyElement] = useState(false);

  const readOnly = readOnlyProp || readOnlyElement;

  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    const element = inputRef.current;

    element.setReadOnly = status => {
      setReadOnlyElement(status);
    };

    if (events) {
      events.map(event => {
        // eslint-disable-next-line no-new-func
        const dynamicFunction = new Function('data', event.handler);

        // subscribe event
        element.addEventListener(event.name, e => {
          dynamicFunction({
            form: formRef.current,
            element: e.target,
          });
        });

        return event;
      });
    }

    return () => {
      if (events) {
        events.map(event => {
          // eslint-disable-next-line no-new-func
          const dynamicFunction = new Function('data', event.handler);

          // subscribe event
          element.removeEventListener(event.name, e => {
            dynamicFunction({
              // eslint-disable-next-line react-hooks/exhaustive-deps
              form: formRef.current,
              element: e.target,
            });
          });

          return event;
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getUserField() {
    if (userFields) {
      const elements = userFields.filter(field => field.target === fieldName);
      const response = elements.map(target => {
        return (
          <Input
            key={target.name}
            label={target.label}
            name={target.name}
            formRef={formRef}
            readOnly={target.readOnly}
            settings={settings}
          />
        );
      });

      return response;
    }

    return null;
  }

  return (
    <Container>
      {label && !hidden && <label htmlFor={fieldName}>{label}</label>}

      <InputElement
        ref={inputRef}
        id={fieldName}
        defaultValue={defaultValue}
        hidden={hidden}
        readOnly={(selfField && selfField.readOnly) || readOnly}
        {...selfField}
        {...rest}
      />

      {error && !hidden && <span style={{ color: '#f00' }}>{error}</span>}

      {getUserField()}
    </Container>
  );
}
