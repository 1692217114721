import request from '../../Request';

class TableMountService {
  async execute({ data, settings }) {
    const response = await request({
      url: `APP/CRM/TableMount/execute`,
      method: 'POST',
      data: {
        data,
        settings,
      },
    });

    return response;
  }

  /**
   * @typedef {Object} TableColumn
   * @property {string} key
   * @property {string} dataType
   * @property {Object} settings
   * @property {string} onClick
   *
   * @typedef {Object} TableSettings
   * @property {TableColumn[]} column
   * @property {Object[]} data
   * @property {string} rowKeyField
   * @property {Object} reportSettings
   *
   * @param {Object} config
   * @param {string} config.application
   * @param {string} config.tableName
   * @returns {Promise<TableSettings>}
   */
  async getOnlySettings({ application, tableName }) {
    const response = await request({
      url: `APP/CRM/TableMount/OnlySettings/${application}/${tableName}`,
      method: 'GET',
    });

    return response;
  }
}

export default new TableMountService();
