/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';

import Board from './Board';
import Element from './Element';
import Switch from '../Switch';

import Modal from '~/easy-components/Modal';
import Icon from '~/easy-components/Icon';
import MobileBoards from './MobileBoards';
import useLocale from '~/hooks/useLocale';

import { Container, ElementContent, Status, Buttons } from './styles';

function Kanban({
  mobileTitle,
  renderTitle,
  boards,
  isCardTemplate,
  isBoardTemplate,
  elements,
  propBoardKey,
  propTitle,
  propPercent,
  renderElement,
  propElementLinkBoard,
  propElementKey,
  onDrop,
  onDrag,
  onClick,
  actions,
  onAdd,
  enableAdd,
  elementTitle,
  elementStatusColor,
  isReadOnly,
  cardButtons,
  // onGetMenus,
  // showLoading,
}) {
  const t = useLocale('_Global');

  const elementRef = useRef(null);
  const [selectedKey, setSelectedKey] = useState(null); // 5

  const [menuInfo, setMenuInfo] = useState({
    status: false,
    element: null,
  });

  const getElementsByBoard = board => {
    return elements.filter(
      e => e[propElementLinkBoard] === board[propBoardKey]
    );
  };

  const getBoardByKey = (boardKey, isMobile) => {
    if (boards.length === 0) return null;

    let board = boards.find(b => b[propBoardKey] === boardKey);

    if (!board) {
      // eslint-disable-next-line prefer-destructuring
      board = boards[0];
      setSelectedKey(board[propBoardKey]);
    }
    const title = renderTitle ? renderTitle(board) : null;
    return (
      <Board
        isMobile={isMobile}
        key={board[propBoardKey]}
        title={title || board[propTitle]}
        mobileTitle={mobileTitle}
        percent={propPercent && board[propPercent]}
        propPercent={propPercent}
        boards={boards}
        renderTitle={renderTitle}
        isBoardTemplate={isBoardTemplate}
        setSelectedKey={setSelectedKey}
        propBoardKey={propBoardKey}
        actions={actions}
        enableAdd={enableAdd}
        board={board}
        onAdd={onAdd}
        onDrop={({ dropzone, elementDragged }) => {
          dropzone.querySelector('.content').appendChild(elementDragged);

          onDrop({
            board,
            element: elementRef.current,
            elementDragged,
            isMobile: false,
          });
        }}
      >
        <>
          {getElementsByBoard(board).map(element => {
            return (
              <Element
                style={{ backgroundColor: element._BgColor }}
                key={element[propElementKey]}
                isReadOnly={isReadOnly}
                cardButtons={cardButtons}
                onDragStart={() => {
                  elementRef.current = element;
                  if (onDrag) onDrag(element);
                }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClick(element);
                }}
              >
                {isCardTemplate ? (
                  renderElement(element)
                ) : (
                  <>
                    <Status color={element[elementStatusColor]} />
                    <ElementContent>
                      <header>
                        <h1>{element[elementTitle]}</h1>
                        <Modal isOpened={menuInfo.status} direction="right">
                          <MobileBoards
                            title={t('MoveTo')}
                            propPercent={propPercent}
                            mobileTitle={t('MoveTo')}
                            boards={boards}
                            renderTitle={renderTitle}
                            onClose={() => {
                              setMenuInfo({
                                ...menuInfo,
                                status: false,
                              });
                            }}
                            setSelectedKey={key => {
                              const selectedBoard = boards.find(
                                b => b[propBoardKey] === key
                              );

                              onDrop({
                                element: menuInfo.element,
                                board: selectedBoard,
                                isMobile: true,
                              });
                            }}
                            propBoardKey={propBoardKey}
                          />
                        </Modal>
                      </header>
                      {renderElement(element)}
                      {cardButtons && (
                        <Buttons>
                          {cardButtons.map(button => {
                            return (
                              <Icon
                                name={button.icon}
                                color={button.color || '#5699FF'}
                                size={24}
                                onClick={async e => {
                                  e.stopPropagation();
                                  e.preventDefault();

                                  await button.handler({
                                    card: element,
                                    board,
                                  });
                                }}
                              />
                            );
                          })}
                        </Buttons>
                      )}
                    </ElementContent>
                  </>
                )}
              </Element>
            );
          })}
        </>
      </Board>
    );
  };

  useEffect(() => {
    if (boards && boards.length > 0) setSelectedKey(boards[0][propBoardKey]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boards]);

  return (
    <Container>
      <Switch
        computer={boards.map(board => {
          return getBoardByKey(board[propBoardKey], false);
        })}
        mobile={getBoardByKey(selectedKey, true)}
      />
    </Container>
  );
}

export default Kanban;
