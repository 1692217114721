/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-new-func */
/* eslint-disable func-names */
import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  useMemo,
  forwardRef,
  useCallback,
  memo,
} from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import isEqual from 'lodash/isEqual';
import * as datefns from 'date-fns';
import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';
import { IoMdClose as CloseMentionsIcon } from '@react-icons/all-files/io/IoMdClose';
import { MdSearch } from '@react-icons/all-files/md/MdSearch';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { MdArrowBack } from '@react-icons/all-files/md/MdArrowBack';
import { MdPrint } from '@react-icons/all-files/md/MdPrint';
import Modal from '~/easy-components/Modal';
import HashtagMention from '~/components/HastagMention';
import ClientEventHandler from '~/applications/ClientEventHandler';
import Page from '~/components/Page';
import { colors } from '~/easy-components/styles';
import Icon from '~/easy-components/Icon';
import systemColors from '~/styles/colors';
import { showReport } from '~/applications/ControlPage';
import QueryService from '~/services/QueryService';
import useLocale from '~/hooks/useLocale';
import Title from '~/easy-components/Title';
import HeaderPage from '~/easy-components/HeaderPage';
import Card from '~/easy-components/Card';
import MenuButton from '~/easy-components/MenuButton';
import Toast from '~/easy-components/Toast';
import ConfirmDialog from '~/easy-components/ConfirmDialog';
import Button from '~/easy-components/Button';
import FooterPage from '~/easy-components/FooterPage';
import FlexSpace from '~/easy-components/FlexSpace';
import TreatError from '~/easy-components/TreatError';
import { createAsyncFunctionByString } from '~/easy-components/AsyncFunctionString';
import SystemFooterElements from '~/easy-components/SystemFooterElements';

import {
  saveCache,
  openSide,
  clearSettings,
} from '~/store/modules/pages/actions';
import history from '~/routes/history';
import useUiDesigner from '~/hooks/useUiDesigner';
import HelpButton from '~/applications/CRM/components/HelpButton';
import DesignFinishButton from '~/hooks/useUiDesigner/FinishButton';
import List from './List';
import { ListElement, Close, LogsButton, Container } from './styles';
// eslint-disable-next-line no-empty-function
const AsyncFunction = new Function(
  `return Object.getPrototypeOf(async function(){}).constructor`
)();

function MasterDetail(
  {
    isModal = false,
    settings,
    isReadOnly,
    keyField,
    forceTitle,
    route,
    shareRoute,
    menus,
    listLastDateUpdated,
    sideWidth,
    isFollowUp = false,
    isShowNew = true,
    isShowSave = true,
    isShowDelete = true,
    isShowPrint = false,
    footerElements: FooterElements,
    footerListElements: FooterListElements,
    onGetDefault,
    onRenderListItem,
    onSelectedItem,
    onGetDataToSave,
    onSearch,
    onLoadData,
    onLoadExternalData,
    onBeforeSave,
    onSave,
    onPrint,
    onDelete,
    children,
    formRef,
    showLoading,
    showSearchPanel,
    isClickable,
    onValidate,
    isFlexSpace: isFlexible = true,
    isShowSearch = true,
    customBarIcon,
    isExecutionPage,
    listBackground,
    enableMentions,
    mentionObjectCode,
    mentionKeyValue,
    mentionKeyText,
    isShowMaskLoading = true,
    baseService = null,
    onSaveError,
    t: tLocal = null,
    isShowSystemLogs,
    systemLogsBtnClick,
    systemLogsRef,
    systemLogskey,
    showPage,
    auxScope,
    showTemplatesModal,
    ignorePropsToSaveTemplate,
    auxTitle,
    subTitleProp,
    formData,
    showDisplayModal,
    enableDesignMode = false,
    queryCodeMaster,
    queryCodeList,
    auxContextEvents,
    helpUrl,
    isListOpen = true,
    initialData,
    isShowLog,
    showBackButtom = true,
    isUserPage,
    showFooter = true,
    disableList = false,
  },
  ref
) {
  const tapp = useLocale('applications');

  const pageUrlId = useMemo(
    () => window.location.pathname.substring(1) + window.location.search,
    []
  );

  const pageId = settings && settings._route ? settings._route : pageUrlId;

  let isFlexSpace = isFlexible;

  const dispatch = useDispatch();
  const dataNotChangedRef = useRef(null);
  const listRef = useRef(null);
  const onGetDataToSaveRef = useRef();
  const firstRenderRef = useRef(true);

  const troute = useLocale(shareRoute || route, settings.translations);
  const t = tLocal || troute;

  const pageTitle = useMemo(() => {
    const newTitle = settings._title || forceTitle || tapp(route);

    if (auxTitle) {
      return `${newTitle} | ${auxTitle}`;
    }

    if (subTitleProp && formData) {
      if (formData[subTitleProp]) {
        return `${newTitle} | ${formData[subTitleProp]}`;
      }
    }

    return newTitle;
  }, [auxTitle, formData]);

  const { viewMode, showContextMenu, getDesignMenus } = useUiDesigner({
    pageId,
    enableDesignMode,
    componentType: 'page',
    baseName: null,
    name: null,
    title: pageTitle,
    auxContextEvents,
    contextProps: {
      isShowSave,
      isShowDelete,
      isUserPage,
    },
  });

  const designMenus = getDesignMenus();

  onGetDataToSaveRef.current = onGetDataToSave;

  const externalData = useSelector(redux => {
    const pageExt =
      redux.pages.find(
        page => page.pageId.toUpperCase() === (pageId || '').toUpperCase()
      ) || {};

    return pageExt.externalData;
  });

  const cachedData = useSelector(redux => {
    return (
      redux.pages.find(
        page => page.pageId.toUpperCase() === (pageId || '').toUpperCase()
      ) || {}
    ).data;
  });

  // TODO: Verificar carregamento de dados para conseguir voltar para a tela de origem

  const [selectedItem, setSelectedItem] = useState({});
  const [dateCache, setDateCache] = useState(null);
  const [systemMenus, setSystemMenus] = useState([]);
  const [lastDateUpdated, setLastDateUpdated] = useState(null);
  const [isShowAuxPanel, setIsShowAuxPanel] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  let { isListDefaultOpen = isListOpen } = settings || {
    isListDefaultOpen: isListOpen,
  };

  if (isMobile) isListDefaultOpen = false;

  settings.footerElements = settings.footerElements || [];

  const systemFooterElements = settings.footerElements.map((button, idx) => {
    return {
      name: button.name || `Custom_${idx}`,
      type: button.type || 'Default',
      onClick: button.onClick,
      text: button.text,
      iconName: button.iconName || null,
      toolTip: button.toolTip || null,
      size: button.size || null,
      color: button.color || null,
      hidden: button.hidden || null,
      position: button.position || null,
      isUserField: true,
    };
  });

  if (systemFooterElements.length > 0) {
    isFlexSpace = false;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDefault = async () => {
    let defaultData = {};

    if (settings) {
      if (typeof settings.defaultData === 'string') {
        // eslint-disable-next-line no-new-func
        const dynamicFunction = new AsyncFunction(
          'scope',
          settings.defaultData
        );

        const dynamicFunctionProps = settings && settings.dynamicFunctionProps;

        const response = await dynamicFunction({
          ...dynamicFunctionProps,
          form: formRef ? formRef.current : null,
        });

        defaultData = response;
      } else {
        defaultData = {
          ...settings.defaultData,
        };
      }
    }

    if (onGetDefault) {
      const dataDefault = await onGetDefault(defaultData);
      return dataDefault;
    }

    return defaultData;
  };

  const pageRefresh = async () => {
    // eslint-disable-next-line no-use-before-define
    await Promise.all([refreshDetail(), listRef.current.refresh()]);
  };

  const sendClientEvent = async ({ eventName, data, run, ...props }) => {
    if (!!data && typeof data === 'object' && !Array.isArray(data)) {
      if (data._auxSettings) {
        if (data._auxSettings.events) {
          const outEvent = data._auxSettings.events.find(
            evt =>
              evt.name.toUpperCase() === eventName.toUpperCase() &&
              (run || 'before') === (evt.run || 'before')
          );

          if (outEvent) {
            const outEventFunction = createAsyncFunctionByString({
              functionString: outEvent.handler,
            });

            await outEventFunction({
              ...settings.dynamicFunctionProps,
              globalFunctions: settings.globalFunctions,
              auxData: outEvent.auxData,
              data,
              route,
              form: formRef ? formRef.current : null,
              element: formRef,
              formData:
                formRef && formRef.current ? formRef.current.getData() : data,
              ...auxScope,
              ...props,
            });
          }
        }
      }
    }

    const clientResponse = await ClientEventHandler({
      eventName,
      run,
      data,
      params: {
        route,
        form: formRef ? formRef.current : null,
        element: formRef,
        formData: formRef && formRef.current ? formRef.current.getData() : data,
        settings,
        pageRefresh,
        ...props,
        ...auxScope,
      },
    });

    return clientResponse;
  };

  const getDataToSave = async () => {
    let rawData = null;

    await sendClientEvent({
      eventName: 'onGetDataToSave',
      run: 'before',
      data: null,
    });

    if (onGetDataToSave) rawData = onGetDataToSave();
    else {
      rawData = formRef && formRef.current ? formRef.current.getData() : null;
    }

    const dataClient = await sendClientEvent({
      eventName: 'onGetDataToSave',
      run: 'after',
      data: rawData,
    });

    return dataClient;
  };

  const storeCache = async () => {
    // Aguarda 100ms para carregar os dados na tela
    await new Promise(resolve => setTimeout(resolve, 400));

    const cachedRawData = await getDataToSave();

    await sendClientEvent({
      eventName: 'onLoadData',
      run: 'after',
      data: cachedRawData,
    });

    dataNotChangedRef.current = JSON.stringify(cachedRawData);

    dispatch(
      saveCache({
        pageId,
        data: { ...cachedRawData },
      })
    );
  };

  const executeAuxQueries = async ({ auxQueries, data }) => {
    if (auxQueries) {
      await Promise.all(
        auxQueries.map(async query => {
          if (query.prop) {
            const response = await QueryService.execute(
              1,
              query.sql || query.query,
              data
            );
            data[query.prop] = response;
          } else {
            const responseScalar = await QueryService.execute(
              1,
              query.sql || query.query,
              data
            );

            const auxData =
              responseScalar.length > 0 ? responseScalar[0] : null;

            for (const prop in auxData) {
              data[prop] = auxData[prop];
            }
          }
        })
      );
    }
  };

  const isSideOpen = useSelector(redux => {
    const pageSettings =
      redux.pages.find(
        page => page.pageId.toUpperCase() === (pageId || '').toUpperCase()
      ) || {};

    if (pageSettings.isSideOpen === undefined) pageSettings.isSideOpen = true;

    return pageSettings.isSideOpen;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadData = async (formDataProp, isAuxQueries = false) => {
    dispatch(
      clearSettings({
        pageId,
      })
    );

    if (isAuxQueries) {
      await executeAuxQueries({
        auxQueries: settings.queryAux,
        data: formDataProp,
      });
    }

    const newFormData = await sendClientEvent({
      eventName: 'onLoadData',
      run: 'before',
      data: formDataProp,
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const prop in newFormData) {
      if (Array.isArray(newFormData[prop]))
        newFormData[prop] = JSON.stringify(newFormData[prop]);
    }

    if (formDataProp && formDataProp._formProps) {
      const { filter } = formDataProp._formProps;

      if (filter && listRef.current) {
        setTimeout(() => {
          listRef.current.refresh(filter);
        }, 300);

        listRef.current.target.value = filter;
      }

      delete formDataProp._formProps;
    }

    if (onLoadData) await onLoadData({ data: newFormData, loadData });

    await storeCache();

    if (newFormData) {
      if (newFormData[keyField]) {
        setSelectedItem(formDataProp);
      }
    }

    setDateCache(new Date());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSave = async props => {
    async function execSave(dataToSave) {
      try {
        showLoading(true);

        let beforeSaveData = dataToSave;

        if (onBeforeSave) {
          const dataReplaced = await onBeforeSave({
            data: beforeSaveData,
          });

          if (dataReplaced) {
            beforeSaveData = dataReplaced;
          }
        }

        beforeSaveData = await sendClientEvent({
          eventName: 'onSave',
          run: 'before',
          data: beforeSaveData,
        });

        const auxSettings = beforeSaveData._auxSettings || null;

        if (beforeSaveData && beforeSaveData._auxSettings) {
          delete beforeSaveData._auxSettings;
        }

        let response = null;

        if (onSave) {
          response = await onSave({
            data: beforeSaveData,
            loadData,
          });
        } else {
          try {
            response = await baseService.save({
              settings,
              data: beforeSaveData,
            });
          } catch (error) {
            if (onSaveError && typeof onSaveError === 'function') {
              onSaveError({
                error,
                settings,
                data: beforeSaveData,
              });
            } else {
              throw error;
            }
          }
        }

        // Quando response null no método save, houve erro tratado na própria chamada do save
        if (response) {
          if (auxSettings) {
            response._auxSettings = auxSettings;
          }

          const afterSaveData = await sendClientEvent({
            eventName: 'onSave',
            run: 'after',
            data: response,
            oldData: beforeSaveData,
          });

          if (afterSaveData) {
            Toast.success(t('message.SystemMessageSuccess'));

            await loadData(afterSaveData);

            if (isListDefaultOpen || isSideOpen) {
              setLastDateUpdated(new Date());
            }
          }
        }
      } catch (error) {
        TreatError.showError(error);
      } finally {
        showLoading(false);
      }
    }

    try {
      let isValid = true;
      const rawData = await getDataToSave();

      if (onValidate) {
        isValid = await onValidate({ data: rawData });
      }

      if (isValid) {
        let hasQuestion = true;

        // eslint-disable-next-line no-prototype-builtins
        if (props && props.hasOwnProperty('hasQuestion')) {
          hasQuestion = props.hasQuestion;
        }

        if (hasQuestion) {
          await new Promise(resolve => {
            ConfirmDialog({
              title: pageTitle,
              message: t('message.SaveMessageQuestion'),
              buttons: [
                {
                  label: t('Yes'),
                  onClick: async () => {
                    await execSave(rawData);
                    resolve();
                  },
                },
                {
                  label: t('No'),
                  onClick: async () => {
                    resolve();
                  },
                },
              ],
            });
          });
        } else {
          await execSave(rawData);
        }
      }
    } catch (error) {
      TreatError.showError(error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePrint = async () => {
    ConfirmDialog({
      title: pageTitle,
      message: t('message.PrintMessageQuestion'),
      buttons: [
        {
          label: t('Yes'),
          onClick: async () => {
            onPrint();
          },
        },
        {
          label: t('No'),
        },
      ],
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSideOpen = async () => {
    dispatch(
      openSide({
        pageId,
        isSideOpen: !isSideOpen,
      })
    );

    if (!isListDefaultOpen && !isSideOpen) listRef.current.refresh();
  };

  const onCloseSide = async () => {
    dispatch(
      openSide({
        pageId,
        isSideOpen: false,
      })
    );
  };

  const listOnSearch = async (filter, auxFilters) => {
    if (!isListDefaultOpen && firstRenderRef.current) return [];

    if (settings.queryList) {
      const responseSql = await QueryService.execute(1, settings.queryList, {
        filter,
        ...auxFilters,
      });
      return responseSql;
    }
    if (onSearch) {
      return onSearch({ filter, auxFilters });
    }

    return baseService.list({ filter, auxFilters });
  };

  const refreshDetail = async () => {
    try {
      showLoading(true);

      let rawData = null;

      if (onGetDataToSave) rawData = onGetDataToSave();
      else {
        rawData = formRef ? formRef.current.getData() : null;
      }

      let response = null;

      if (baseService) {
        response = await baseService.get(rawData[keyField]);
      } else {
        response = await onSelectedItem({ selectedItem: rawData });
      }

      await loadData(response, true);
    } catch (err) {
      TreatError.showError(err);
    } finally {
      showLoading(false);
    }
  };

  const executeSelectionItem = async item => {
    try {
      showLoading(true);

      let data = null;

      if (!onSelectedItem) {
        data = await baseService.get(item[keyField]);
      } else {
        data = await onSelectedItem({ selectedItem: item });
      }

      if (isMobile) onCloseSide();

      await loadData(data, true);
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSelectItem = async item => {
    const rawData = await getDataToSave();
    const dataTest = JSON.stringify(rawData);

    if (!isEqual(dataNotChangedRef.current, dataTest)) {
      ConfirmDialog({
        title: pageTitle,
        message: t('message.SystemMessageQuestion'),
        buttons: [
          {
            label: t('Yes'),
            onClick: async () => {
              executeSelectionItem(item);
            },
          },
          {
            label: t('No'),
          },
        ],
      });
    } else {
      executeSelectionItem(item);
    }
  };

  const newData = async () => {
    try {
      showLoading(true);

      setSelectedItem({});

      formRef.current.setData(null);

      const dataDefault = await getDefault();

      loadData(dataDefault);
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onNew = async () => {
    const confirmation = await new Promise(resolve => {
      ConfirmDialog({
        title: pageTitle,
        message: t('message.NewMessageQuestion'),
        buttons: [
          {
            label: t('Yes'),
            onClick: () => resolve(true),
          },
          {
            label: t('No'),
            onClick: () => resolve(false),
          },
        ],
      });
    });

    if (confirmation) {
      await newData();
    }
  };

  const followUpData = async () => {
    try {
      showLoading(true);

      const currentDate = new Date();
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');

      const time = `${hours}:${minutes}:00`;

      const endDate = datefns.add(currentDate, { minutes: 15 });

      const endHours = String(endDate.getHours()).padStart(2, '0');
      const endMinutes = String(endDate.getMinutes()).padStart(2, '0');

      const endTime = `${endHours}:${endMinutes}:00`;

      const { ActivityCode, ...rawData } = formRef.current.getData();

      setSelectedItem(ActivityCode);

      loadData({
        ...rawData,
        StartDate: currentDate,
        StartTime: time,
        EndDueDate: currentDate,
        EndTime: endTime,
        DurationType: 'S',
        Duration: 900,
        _DurationFormatted: '15',
        Details: `${t('Continuation')}: ${rawData.Details || ''}`,
        PreviousActivity: ActivityCode,
      });
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
  };

  const onFollowUp = async () => {
    ConfirmDialog({
      title: pageTitle,
      message: t('message.FollowMessageQuestion'),
      buttons: [
        {
          label: t('Yes'),
          onClick: followUpData,
        },
        {
          label: t('No'),
        },
      ],
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDelete = async () => {
    ConfirmDialog({
      title: pageTitle,
      message: t('message.DeleteMessageQuestion'),
      buttons: [
        {
          label: t('Yes'),
          onClick: async () => {
            try {
              showLoading(true);

              const rawData = await getDataToSave();

              await sendClientEvent({
                eventName: 'onDelete',
                run: 'before',
                data: rawData,
              });

              let response = true;

              if (onDelete) {
                response = await onDelete({ data: rawData });
              } else {
                await baseService.delete(rawData[keyField]);
              }

              if (response === true) {
                await newData();

                if (isListDefaultOpen || isSideOpen) {
                  setLastDateUpdated(new Date());
                }

                await sendClientEvent({
                  eventName: 'onDelete',
                  run: 'after',
                  data: null,
                });
              }

              showLoading(false);
            } catch (error) {
              showLoading(false);
              TreatError.showError(error);
            }
          },
        },
        {
          label: t('No'),
        },
      ],
    });
  };

  const handlerLoadExternalData = async ({ data, defaultData }) => {
    try {
      showLoading(true);

      if (typeof data === 'object') {
        loadData({ ...defaultData, ...data });
      } else {
        const newInfo = await baseService.get(data);
        loadData(newInfo, true);
      }
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
  };

  const loadExternalData = async data => {
    try {
      showLoading(true);

      const defaultData = await getDefault();

      if (onLoadExternalData) {
        await onLoadExternalData({
          data,
          defaultData,
          loadData,
        });
      } else {
        await handlerLoadExternalData({ data, defaultData });
      }

      showLoading(false);
    } catch (error) {
      TreatError.showError(error);
      showLoading(false);
    }
  };

  const refreshMenu = useCallback(async () => {
    const allMenus = [];

    allMenus.push(...designMenus);

    if (menus) {
      allMenus.push(...menus);
    }

    if (settings.reports) {
      settings.reports.forEach(report => {
        allMenus.push({
          id: report.hash,
          visible: true,
          disabled: false,
          text: report.name,
          icon: report.icon,
          handler: async () => {
            let rawData = null;

            if (onGetDataToSave) rawData = onGetDataToSave();
            else {
              rawData = formRef ? formRef.current.getData() : null;
            }

            showReport({
              reportCode: report.hash,
              dispatch,
              externalData: rawData,
            });
          },
        });
      });
    }

    if (showTemplatesModal) {
      allMenus.push({
        id: 'openTemplate',
        visible: true,
        disabled: false,
        type: 'O',
        text: t('OpenTemplate'),
        icon: 'LuBookTemplate',
        handler: async () => {
          showTemplatesModal({
            title: t('OpenTemplate'),
            type: 'O',
            showLoading,
            loadData,
            route,
          });
        },
      });

      allMenus.push({
        id: 'saveTemplate',
        visible: true,
        disabled: false,
        text: t('SaveTemplate'),
        icon: 'GoRepoTemplate',
        handler: async () => {
          const formDataRet = await getDataToSave();

          showTemplatesModal({
            title: t('SaveTemplate'),
            type: 'S',
            showLoading,
            formData: formDataRet,
            ignorePropsToSaveTemplate,
            route,
          });
        },
      });
    }
    await sendClientEvent({
      eventName: 'onRefreshMenu',
      run: 'before',
      data: allMenus,
    });

    setSystemMenus(allMenus);
  }, [dispatch, formRef, menus, settings.reports]);

  useEffect(() => {
    if (dateCache) {
      refreshMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateCache, refreshMenu, formRef]);

  useEffect(() => {
    if (externalData) {
      loadExternalData(externalData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalData]);

  const load = async () => {
    if (!externalData) {
      showLoading(true);
      if (initialData !== undefined) {
        if (initialData === null) {
          const dataDefault = await getDefault();
          await loadData(dataDefault);
        } else {
          await loadExternalData(initialData);
        }
      } else if (cachedData) {
        await loadData(cachedData);
        setSelectedItem(cachedData);
      } else {
        const dataDefault = await getDefault();
        await loadData(dataDefault);
      }
      showLoading(false);
    }
  };

  useEffect(() => {
    if (!isListDefaultOpen) {
      onCloseSide();
      firstRenderRef.current = false;
    } else {
      dispatch(
        openSide({
          pageId,
          isSideOpen: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      await load();
      setIsLoaded(true);
    }, 100);

    return async () => {
      const filter = listRef.current && listRef.current.target.value;
      const form = formRef && formRef.current;

      let dataToCache = null;

      if (onGetDataToSave) {
        dataToCache = await onGetDataToSave();
      } else {
        dataToCache = formRef && form.getData();
      }

      dispatch(
        saveCache({
          pageId,
          data: {
            ...dataToCache,
            _formProps: {
              filter,
            },
          },
        })
      );
    };
  }, []);

  useImperativeHandle(ref, () => {
    return {
      loadData,
      save: handleSave,
      refreshDetail: async () => {
        await refreshDetail();
      },
      refreshList: async filterValue => {
        if (!isModal) {
          if (filterValue) {
            listRef.current.target.value = filterValue;
          }
          await listRef.current.refresh();
        }
      },
      refresh: async () => {
        await Promise.all([refreshDetail, listRef.current.refresh]);
      },
      removeListItem: identifier => {
        const { current = {} } = listRef;

        const { removeItems = () => {} } = current;

        removeItems(identifier);
      },
      addListItem: item => {
        const { current = {} } = listRef;

        const { addItem = () => {} } = current;

        addItem(item);
      },
      executeSelectionItem: async item => {
        await executeSelectionItem(item);
      },
      getDefault,
      refreshMenu,
    };
  });

  const mentionPanel = useMemo(() => {
    if (!enableMentions) return null;

    return (
      <HashtagMention
        objectCode={mentionObjectCode}
        keyValue={mentionKeyValue}
        keyText={mentionKeyText}
      />
    );
  }, [enableMentions, mentionKeyText, mentionKeyValue, mentionObjectCode]);

  const getBarIcon = customIcon => {
    const iconProps = {
      size: 22,
      color: systemColors.headerTitle,
      onClick: onSideOpen,
      style: {
        cursor: 'pointer',
      },
    };

    if (customIcon) {
      return customIcon(iconProps);
    }

    return <MdSearch {...iconProps} />;
  };

  const modalLog = (logRefs, logKey) => {
    const options = logRefs.map(log => {
      return {
        icon: 'LuUser2',
        title: t(log),
        handler: () => {
          showPage({
            dispatch,
            route: `SystemLogs/${log}?$key=${selectedItem[logKey] || ''}`,
          });
        },
      };
    });

    showDisplayModal({
      type: 'options',
      title: t('Logs'),
      options,
    });
  };
  /* useEffect(() => {
    const errorHandler = error => {
      console.error(error);
    };

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []); */

  return (
    <Container>
      <Page
        isModal={isModal}
        route={route}
        isSidePageOpen={isSideOpen}
        showSide={() => {
          if (!isModal) {
            onSideOpen();
          }
        }}
        sideWidth={sideWidth}
        isExecutionPage={isExecutionPage}
        isShowLog={isShowLog}
        sidePage={
          <>
            <List
              isUserPage={isUserPage}
              queryCodeList={queryCodeList}
              ref={listRef}
              settings={settings}
              backgroundColor={listBackground || systemColors.side}
              lastDateUpdated={lastDateUpdated || listLastDateUpdated}
              isShowMaskLoading={isShowMaskLoading}
              onSelect={onSelectItem}
              selectedItem={selectedItem[keyField]}
              renderItem={(...args) => {
                // eslint-disable-next-line eqeqeq
                const selected = selectedItem[keyField] == args[0][keyField];

                return (
                  <ListElement selected={selected}>
                    {onRenderListItem(...args, selected)}
                  </ListElement>
                );
              }}
              onSearch={listOnSearch}
              onClose={onCloseSide}
              onNew={onNew}
              disableList={disableList}
              showSearchPanel={showSearchPanel}
              isClickable={isClickable}
            />

            {FooterListElements && (
              <FooterListElements listRef={listRef.current} />
            )}
          </>
        }
      >
        <Card
          width="900px"
          height="900px"
          isLoading={false}
          style={{ display: isLoaded ? 'flex' : 'none' }}
          onContextMenu={event => {
            showContextMenu({
              event,
              auxContextMenus: [
                {
                  title: 'EditQuery',
                  prop: queryCodeMaster,
                  type: 'query',
                  visible: !!queryCodeMaster,
                },
              ],
            });
          }}
        >
          <HeaderPage
            backgroundColor={systemColors.headerBg}
            color={systemColors.headerTitle}
            viewMode={viewMode}
          >
            {!isModal &&
              isShowSearch &&
              !isExecutionPage &&
              getBarIcon(customBarIcon)}
            <Title>{pageTitle}</Title>
            <div
              style={{
                // width: 60,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {!isModal && isShowSystemLogs && (
                <>
                  <LogsButton
                    data-tip="Logs"
                    data-for="systemLogsTooltip"
                    type="button"
                  >
                    <FaInfoCircle
                      size={22}
                      color={systemColors.headerTitle}
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={async () => {
                        if (typeof systemLogsRef === 'string') {
                          if (systemLogsBtnClick) {
                            await systemLogsBtnClick({
                              systemLogsRef,
                              selectedItem,
                              systemLogskey,
                            });
                          } else {
                            showPage({
                              dispatch,
                              route: `SystemLogs/${systemLogsRef}?$key=${selectedItem[
                                systemLogskey
                              ] || ''}`,
                            });
                          }
                        } else {
                          modalLog(systemLogsRef, systemLogskey);
                        }
                      }}
                    />
                  </LogsButton>
                  <ReactTooltip
                    id="systemLogsTooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                    className="toolbarTooltip"
                  />
                </>
              )}
              <DesignFinishButton pageId={pageId} />
              {systemMenus && systemMenus.length > 0 && (
                <MenuButton
                  menus={systemMenus}
                  settings={settings}
                  icon={FaEllipsisV}
                  color={systemColors.headerTitle}
                  size={16}
                  position="bottom right"
                />
              )}
              {enableMentions && (
                <Icon
                  size={20}
                  color="#fff"
                  name="MdChat"
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() => {
                    setIsShowAuxPanel(!isShowAuxPanel);
                  }}
                />
              )}
              <HelpButton url={settings.helpUrl} customUrl={helpUrl} />
            </div>
          </HeaderPage>
          {children}
          {showFooter && (
            <FooterPage>
              {!isModal && showBackButtom && (
                <div
                  style={{
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: `2px solid ${colors.main}`,
                    cursor: 'pointer',
                    marginRight: '10px',
                  }}
                >
                  <MdArrowBack
                    size={22}
                    color={colors.main}
                    onClick={() => history.goBack()}
                  />
                </div>
              )}
              <Button
                name="Follow"
                settings={settings}
                formRef={formRef}
                style={{ marginLeft: '10px' }}
                onClick={onFollowUp}
                hidden={!(isFollowUp && !isReadOnly)}
              >
                {t('Follow')}
              </Button>
              {isFlexSpace && <FlexSpace />}
              {FooterElements && <FooterElements formRef={formRef} />}
              {systemFooterElements.length > 0 && (
                <SystemFooterElements
                  data={systemFooterElements}
                  settings={settings}
                  buttonClickScope={{
                    ...settings.dynamicFunctionProps,
                    globalFunctions: settings.globalFunctions,
                    form: formRef.current,
                    formData:
                      formRef && formRef.current && formRef.current.getData(),
                    pageRefresh,
                    ...auxScope,
                  }}
                  buttonFormRef={formRef}
                />
              )}

              <Button
                name="Delete"
                settings={settings}
                formRef={formRef}
                buttonType="Reject"
                onClick={handleDelete}
                hidden={!isShowDelete || isReadOnly}
              >
                {t('Delete')}
              </Button>

              <Button
                name="New"
                settings={settings}
                formRef={formRef}
                onClick={onNew}
                hidden={!(isShowNew && !isReadOnly)}
              >
                {t('New')}
              </Button>

              <Button
                buttonType="Emphasized"
                name="Save"
                settings={settings}
                formRef={formRef}
                onClick={handleSave}
                hidden={!(isShowSave && !isReadOnly)}
              >
                {t('Save')}
              </Button>

              {isShowPrint && (
                <MdPrint
                  size={28}
                  color={colors.main}
                  onClick={handlePrint}
                  style={{ marginLeft: '10px' }}
                />
              )}
            </FooterPage>
          )}
        </Card>
      </Page>
      {enableMentions && (
        <>
          {isMobile ? (
            <Modal isOpened={isShowAuxPanel} direction="right">
              <Close
                onClick={() => {
                  setIsShowAuxPanel(false);
                }}
              >
                <CloseMentionsIcon size={16} color="#fff" />
              </Close>
              {mentionPanel}
            </Modal>
          ) : isShowAuxPanel ? (
            mentionPanel
          ) : null}
        </>
      )}
    </Container>
  );
}

export default memo(forwardRef(MasterDetail));
