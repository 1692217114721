/* eslint-disable react/prop-types */
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import Button from '~/easy-components/Button';

const ConfirmButton = forwardRef(({ onClick }, ref) => {
  const [isDisabled, disable] = useState(false);

  useImperativeHandle(ref, () => ({
    disable,
  }));

  return (
    <Button onClick={onClick} disabled={isDisabled} buttonType="Emphasized">
      Confirmar
    </Button>
  );
});

export default ConfirmButton;
