/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Container, Content, Label } from './styles';

function Toggle({ name, size, onClick, checked, label = '' }, ref) {
  const [state, setState] = useState(checked);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {},
    };
  });

  return (
    <Container>
      <Content size={size}>
        <input
          type="checkbox"
          name={name}
          id={name}
          onClick={() => {
            setState(oldState => !oldState);
            if (onClick) onClick(!state);
          }}
          checked={state}
        />
        <label htmlFor={name} />
      </Content>
      <Label>{label}</Label>
    </Container>
  );
}

export default forwardRef(Toggle);
