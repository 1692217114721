import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 15px 20px;
  flex: 1;
  background-color: ${props => props.color};
`;

export const SelectionContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  padding: 10px 5px 10px 10px;
`;

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #777;
  background-color: ${props =>
    props.color ? `${props.color} !important` : `transparent`};
  line-height: 0.9rem;

  .small {
    * {
      font-size: 0.7rem;
    }
  }

  .medium {
    * {
      font-size: 0.8rem;
    }
  }

  .large {
    * {
      font-size: 1.1rem;
    }
  }

  h3 {
    font-weight: normal;
    font-size: 0.9rem;
    color: #777;
  }

  line-height: 20px;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: break-spaces;
    line-height: 1.1rem;

    * {
      font-size: 0.9rem;
    }

    /* > div:last-child {
      margin-left: 20px;
    } */
  }
`;
