import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Form from '~/easy-components/Form';
import Timeline from '~/easy-components/Form/Timeline';

function TimelineReport({ settings, reportSettings, params, data }, ref) {
  const formRef = useRef();

  useEffect(() => {
    if (formRef.current && reportSettings) {
      formRef.current.setFieldValue('report', data);
    }
  }, [data, reportSettings]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      return formRef.current.getFieldValue('report');
    },
  }));

  if (reportSettings === undefined || reportSettings === null) {
    return null;
  }

  return (
    <Form ref={formRef} settings={reportSettings}>
      <Timeline
        name="report"
        formRef={formRef}
        auxScope={{ params, settings }}
        settings={{
          components: [
            {
              fieldName: 'report',
              type: 'tabTimeline',
              ...reportSettings,
            },
          ],
        }}
      />
    </Form>
  );
}

export default forwardRef(TimelineReport);
