import request from '~/applications/Request';
/**
 * @typedef {Object} UserTable
 * @property {string} id
 * @property {string} name
 */

class UserTableService {
  /**
   * @param {object} params
   * @param {string} params.filter
   * @returns {Promise<UserTable[]>}
   */
  async getNoObjectSearchList({ filter }) {
    return request({
      url: `user_tables/no_object?filter=${filter}`,
      method: 'GET',
    });
  }

  /**
   * @param {object} params
   * @param {string} params.tableName
   * @param {object} params.data
   * @param {object[]} params.data.newLines
   * @param {object[]} params.data.updatedLines
   * @param {object[]} params.data.deletedLines
   */
  async updateData({ tableName, data }) {
    return request({
      url: `user_tables/${tableName}/data`,
      method: 'POST',
      data,
    });
  }

  /**
   * @param {object} params
   * @param {string} params.tableName
   */
  async getData({ tableName }) {
    return request({
      url: `user_tables/${tableName}/data`,
      method: 'GET',
    });
  }

  /**
   * Obtém as configurações com os dados
   * @param {object} params
   * @param {string} params.tableName
   */
  async getSettings({ tableName }) {
    return request({
      url: `user_tables/${tableName}/settings`,
      method: 'GET',
    });
  }
}

export default new UserTableService();
