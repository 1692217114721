import request from '../../Request';

class TaxCodeService {
  async list({ filter }) {
    return request({
      url: `APP/CRM/TaxCodes?$filter=${filter || ''}`,
    });
  }

  async calculateLine({ line, conversionFactor }) {
    return request({
      url: `APP/CRM/TaxCodes/CalculateLine`,
      method: 'POST',
      data: {
        line,
        conversionFactor,
      },
    });
  }
}

export default new TaxCodeService();
