/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
} from 'react';
import { Table } from 'ka-table';
import QueryService from '~/services/QueryService';
import TreatError from '~/easy-components/TreatError';

import { createSyncFunctionByString } from '~/easy-components/AsyncFunctionString';

import Error from '../Error';
import Loader from '../Loader';

import { Container } from './styles';

/* 
    TYPES:

    Boolean = "boolean",
    Date = "date",
    Number = "number",
    Object = "object",
    String = "string"

*/

function TableComponent(
  { settings, widgetSettings, params, executeEvent, data },
  ref
) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState([]);
  const updateTimeRef = useRef();

  const run = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await QueryService.execute(
        1,
        widgetSettings.query,
        params
      );

      setInfo(response);

      if (widgetSettings.updateTimeMinutes) {
        updateTimeRef.current = setTimeout(async () => {
          await run();
        }, widgetSettings.updateTimeMinutes * 1000 * 60);
      }
    } catch (e) {
      const errorDescription = TreatError.getDescription(e);
      setError(errorDescription);
    } finally {
      setIsLoading(false);
    }
  }, [params, widgetSettings.query, widgetSettings.updateTimeMinutes]);

  useEffect(() => {
    run();

    return () => {
      clearTimeout(updateTimeRef.current);
    };
  }, [run]);

  useImperativeHandle(ref, () => {
    return {
      getData: () => {
        return info;
      },
    };
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  const tableProps = {
    data: info,
    columns: widgetSettings.columns || [],
    rowKeyField: widgetSettings.rowKeyField || 'key',
    format: ({ column, value }) => {
      try {
        if (widgetSettings.columns) {
          const col = widgetSettings.columns.find(c => c.key === column.key);
          if (col) {
            if (col.format) {
              const dynamicFunction = createSyncFunctionByString({
                functionString: col.format,
              });

              const response = dynamicFunction({
                data: value,
                ...settings.dynamicFunctionProps,
              });

              return response;
            }
          }
        }

        return value;
      } catch (err) {
        TreatError.showError(
          `Erro ao formatar coluna ${column.title} da tabela ${
            data.title
          } - ${err.toString()}`
        );
        return null;
      }
    },
  };

  /** @type {ChildComponents} */
  const childComponents = {
    dataRow: {
      elementAttributes: ({ rowData }) => {
        // TODO: Incluir possibilidade de estilizar a linha nesse ponto

        let isClickable = false;

        if (widgetSettings.events) {
          const event = widgetSettings.events.find(
            evt => evt.name.toUpperCase() === 'onClick'.toUpperCase()
          );

          if (event) {
            isClickable = true;
          }
        }

        return {
          style: {
            cursor: isClickable ? 'pointer' : 'text',
          },
          onClick: async () => {
            await executeEvent({
              eventName: 'onClick',
              data: {
                tableData: info,
                line: rowData,
              },
            });
          },
        };
      },
    },
  };

  return (
    <Container>
      <Table {...tableProps} childComponents={childComponents} />
    </Container>
  );
}

export default forwardRef(TableComponent);
