/* eslint-disable import/no-cycle */
/* eslint-disable react/button-has-type */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import ModalHandle from '~/easy-components/ModalHandle';
import Button from '~/easy-components/Button';
import FlexSpace from '~/easy-components/FlexSpace';
import FooterPage from '~/easy-components/FooterPage';
import useLocale from '~/hooks/useLocale';
import { Container } from './styles';
import ExecuteReport from '~/pages/ExecuteReport';
import useHelpers from '~/hooks/useHelpers';
import { generateExecutionHash } from '~/applications/ControlPage';
import { openExternalData } from '~/store/modules/pages/actions';

// eslint-disable-next-line no-unused-vars
function ReportModal(_, ref) {
  const t = useLocale('_Global');
  const dispatch = useDispatch();

  const {
    process,
    dynamicFunctionProps: { getApplicationSettings },
  } = useHelpers();

  const modalRef = useRef();

  const [properties, setProperties] = useState(null);

  useImperativeHandle(ref, () => {
    return {
      show: async ({ hash, data, ...rest }) => {
        await process(async () => {
          const settings = await getApplicationSettings({
            pageRoute: 'EXECUTEREPORT',
          });

          const executionHash = generateExecutionHash();

          const reportRoute = `ExecuteReport/${hash}`;

          const route = `${reportRoute}/${executionHash}`;

          if (data) {
            dispatch(
              openExternalData({
                pageId: route,
                externalData: data,
              })
            );
          }

          setProperties({
            hideToolbar: true,
            isModal: true,
            settings,
            ...rest,
            computedMatch: {
              url: `/${route}`,
              params: {
                hash,
                executionHash,
              },
            },
          });

          modalRef.current.handleOpen();
        });
      },
      close: () => {
        modalRef.current.handleClose();
      },
    };
  });

  return (
    <ModalHandle
      ref={modalRef}
      contentStyle={{ style: { width: '95%', height: '95%' } }}
    >
      <Container>
        <ExecuteReport {...properties} />
        <FooterPage>
          <FlexSpace />
          <Button
            onClick={() => {
              modalRef.current.handleClose();
              if (properties.onClose) {
                properties.onClose();
              }
            }}
          >
            {t('Close')}
          </Button>
        </FooterPage>
      </Container>
    </ModalHandle>
  );
}

export default forwardRef(ReportModal);
