/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { deselectRow } from 'ka-table/actionCreators';

import { isMobile } from 'react-device-detect';
import { Input, FieldContainer } from './styles';

import validateAndSelectRow from '../../CustomActions/validateAndSelectRow';
import dynamicFunction from '../../dynamicFunction';
import { PageContext } from '../../index';
import useInputNavigation from '../../hooks/useInputNavigation';

function InputTimer({
  column,
  rowKeyValue,
  value,
  dispatch,
  rowData,
  onRefresh,
  style,
  cellProps,
  getSelectedData,
  readOnly,
  items,
  rawItems,
  isFocused,
  onAddNewLine,
  reportSettings,
}) {
  const {
    auxScope,
    setRowFieldValue,
    removeLine,
    settings,
    tableProps,
    setRowFieldReadOnly,
    refreshTable,
  } = useContext(PageContext);

  const inputRef = useRef();
  const inputChanged = useRef(false);

  const charPermitted = '0123456789:';

  const {
    inputNavigationOnKeyDown,
    inputNavigationOnFocus,
  } = useInputNavigation({
    columnKey: column.key,
    rowKeyValue,
    tableProps,
    isFocused,
    dispatch,
    onAddNewLine,
    currentInputRef: inputRef,
  });

  const { hasSeconds } = column.settings;

  function formatHour(hour) {
    if (!hour.includes('_')) return hour;

    return `0${hour.replace(/_/, '')}`;
  }

  function formatMinutes(min) {
    if (min.indexOf('_') === -1) return min;

    if (min === '__') return '00';

    return `0${min.replace(/_/, '')}`;
  }

  function formatZerosInTime(hour, min, secs) {
    const fullHour = hour.padStart(2, '0');
    const fullMinutes = min.padStart(2, '0');

    let fullSeconds = '';
    if (hasSeconds && secs) {
      fullSeconds = secs.padStart(2, '0');
    }

    let strTime = `${fullHour}:${fullMinutes}`;
    if (hasSeconds && secs) {
      strTime += `:${fullSeconds}`;
    }

    return strTime;
  }

  function formatDateToTime(date) {
    const hour = date.getHours().toString();
    const minutes = date.getMinutes().toString();

    let secs = null;
    if (hasSeconds) {
      secs = date.getSeconds().toString();
    }

    return formatZerosInTime(hour, minutes, secs);
  }

  function intTimeToString(time) {
    if (time) {
      if (time instanceof Date) {
        return formatDateToTime(time);
      }

      let strValue = time.toString().padStart(4, '0');
      if (hasSeconds) {
        strValue = time.toString().padStart(6, '0');
      }

      if (strValue.indexOf(':') !== -1) {
        const [hour, min, secs] = time.split(':');
        return formatZerosInTime(hour, min, secs);
      }

      const hour = strValue.slice(0, 2);
      const min = strValue.slice(2, 4);

      let sec = '';
      if (hasSeconds) {
        sec = strValue.slice(-2);
      }

      let newTime = `${hour}:${min}`;

      if (hasSeconds && sec) {
        newTime += `:${sec}`;
      }

      return newTime;
    }
    return null;
  }

  function stringToTime(str, date) {
    if (str) {
      const [hour, min, secs] = str.split(':');

      let newHour = formatHour(hour);

      if (+newHour >= 24) newHour = +newHour - 24;

      if (newHour >= 0 && newHour <= 23) {
        let newMin = formatMinutes(min);
        if (+newMin >= 60) newMin = +newMin - 60;

        if (newMin >= 0 && newMin <= 59) {
          let newSec = 0;
          if (hasSeconds && secs) {
            newSec = formatMinutes(secs);
            if (+newSec >= 60) newSec = +newSec - 60;
          }

          let newTime =
            newHour.toString().padStart(2, '0') +
            newMin.toString().padStart(2, '0');

          if (hasSeconds && secs) {
            newTime += newSec.toString().padStart(2, '0');
          }

          const intTime =
            newTime.slice(0, 2) !== '00' ? parseInt(newTime, 10) : newTime;

          if (date) {
            return new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              newHour,
              newMin,
              newSec,
              0
            );
          }

          return intTime;
        }
        return formatDateToTime(new Date());
      }
      return formatDateToTime(new Date());
    }
    return null;
  }

  const stringValue = intTimeToString(value);
  const [editorValue, setValue] = useState(stringValue);

  useEffect(() => {
    setValue(stringValue);
  }, [stringValue]);

  useEffect(() => {
    if (isFocused && !inputChanged.current) {
      inputRef.current.getInputDOMNode().focus();
      inputRef.current.getInputDOMNode().select();
    }
  }, [isFocused]);

  return (
    <FieldContainer
      readOnly={readOnly}
      minWidth={
        column &&
        column.settings &&
        column.settings.style &&
        column.settings.style.width
      }
    >
      <Input
        ref={inputRef}
        type="text"
        inputType="inputNumber"
        readOnly={readOnly}
        style={style}
        mask={hasSeconds ? '99:99:99' : '99:99'}
        value={editorValue}
        disabled={column.settings.disabled || false}
        onClick={e => {
          e.stopPropagation();

          if (isMobile) {
            inputRef.current.focus();
          }
        }}
        onFocus={inputNavigationOnFocus}
        onChange={event => {
          inputChanged.current = true;
          setValue(event.currentTarget.value);
        }}
        onBlur={async () => {
          if (readOnly) return;

          if (!inputChanged.current) return;

          const strTimeValue = stringToTime(editorValue);
          const timeValue = intTimeToString(strTimeValue);
          const timeValueDate = stringToTime(editorValue, new Date());

          setRowFieldValue(rowKeyValue, column.key, timeValue, false);

          if (column.settings && column.settings.onChange) {
            await dynamicFunction({
              settings,
              functionString: column.settings.onChange,
              dispatch,
              params: {
                ...auxScope,
                currentValue: timeValue,
                currentValueInDate: timeValueDate,
                formatDateToTime,
                intTimeToString,
                stringToTime,
                items,
                rawItems,
                rows: items,
                thisRow: rowData,
                getSelectedData,
                column,
                line: rowData,
                value,
                rowKeyValue,
                refresh: onRefresh,
                refreshTable,
                setFieldValue: (columnKey, newValue, forceUpdate) => {
                  setRowFieldValue(
                    rowKeyValue,
                    columnKey,
                    newValue,
                    forceUpdate
                  );
                },
                setRowFieldValue,
                setReadOnly: (columnKey, isCellReadOnly = false) => {
                  setRowFieldReadOnly(rowKeyValue, columnKey, isCellReadOnly);
                },
                selectRow: ({ force = false }) => {
                  dispatch(
                    validateAndSelectRow({
                      callingFromCell: cellProps,
                      forceSelection: force,
                    })
                  );
                },
                deselectRow: () => {
                  dispatch(deselectRow(rowKeyValue));
                },
                removeLine,
                reportSettings,
              },
            });
          }

          inputChanged.current = false;
          refreshTable();
        }}
        // eslint-disable-next-line consistent-return
        onKeyPress={e => {
          if (charPermitted.indexOf(e.key) < 0) {
            e.preventDefault();

            if (e.key === 'Enter' || e.key === 'Tab') {
              return false;
            }
            setValue(formatDateToTime(new Date()));
            setRowFieldValue(
              rowKeyValue,
              column.key,
              formatDateToTime(new Date()),
              false
            );
            refreshTable();
          }
        }}
        onKeyDown={e => {
          inputNavigationOnKeyDown(e);
        }}
      />
    </FieldContainer>
  );
}

export default InputTimer;
