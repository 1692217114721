/**
 * @typedef {Object} ValidateAndSelectActionPayload
 * @property {React.PropsWithChildren<import('ka-table/props').ICellTextProps>} callingFromCell
 * @property {boolean} forceSelection
 */

/**
 * @param {ValidateAndSelectActionPayload} params
 * @returns {{ type: 'validateAndSelectRow', payload: ValidateAndSelectActionPayload   }}
 */
const validateAndSelectAction = ({
  callingFromCell,
  forceSelection = false,
}) => ({
  type: 'validateAndSelectRow',
  payload: {
    callingFromCell,
    forceSelection,
  },
});
export default validateAndSelectAction;
