import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { format } from 'date-fns';
import HeaderPage from '~/easy-components/HeaderPage';
import FooterPage from '~/easy-components/FooterPage';
import FlexSpace from '~/easy-components/FlexSpace';
import Button from '~/easy-components/Button';
import Icon from '~/easy-components/Icon';
import colors from '~/styles/colors';

import { Container, Content } from './styles';
import Cropper from '../Cropper';

const TO_RADIANS = Math.PI / 180;

function Camera({
  t,
  title,
  onConfirm,
  facingMode,
  onClose,
  imageForCrop = null,
  cropFullImage = false,
}) {
  const webcamRef = useRef();
  const cropRef = useRef();

  const [state, setState] = useState({
    title,
    isShow: false,
    imageSrc: imageForCrop,
    cropFullImage,
    facingMode,
    errors: {},
  });

  function handlerClose(e) {
    e.stopPropagation();

    e.preventDefault();

    onClose();
  }

  function generateImageName() {
    const hashTime = format(new Date(), 'yyyyMMddHHmmss');

    return `IMG${hashTime}.jpg`;
  }

  const getCroppedImg = () => {
    const { crop, image } = cropRef.current.getCroppedArea();

    return new Promise(resolve => {
      const canvas = document.createElement('canvas');

      const ctx = canvas.getContext('2d');

      if (!ctx) {
        throw new Error('No 2d context');
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio;

      if (crop.unit === '%') {
        canvas.width = (image.naturalWidth * crop.width) / 100;
        canvas.height = (image.naturalHeight * crop.height) / 100;
      } else {
        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
        ctx.scale(pixelRatio, pixelRatio);
      }

      ctx.imageSmoothingQuality = 'high';

      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;

      const rotate = 0;

      const rotateRads = rotate * TO_RADIANS;

      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;

      ctx.save();

      ctx.translate(-cropX, -cropY);
      ctx.translate(centerX, centerY);
      ctx.rotate(rotateRads);
      ctx.scale(1, 1);
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );

      ctx.restore();

      canvas.toBlob(blobFile => {
        const croppedImageUrl = URL.createObjectURL(blobFile);
        resolve({ file: blobFile, src: croppedImageUrl });
        URL.revokeObjectURL(croppedImageUrl);
      }, 'image/jpeg');
    });
  };

  async function handlerConfirm(e) {
    e.stopPropagation();
    e.preventDefault();

    const { file, src: imageSrc } = await getCroppedImg();

    file.originalname = generateImageName();

    onConfirm({ image: imageSrc, file });
  }

  const onNew = () => {
    setState({
      ...state,
      imageSrc: null,
    });
  };

  const onCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setState({
      ...state,
      imageSrc,
    });
  };

  const switchCamera = () => {
    const currentFacingMode = state.facingMode;
    let newFacingMode = 'user';

    if (currentFacingMode === 'user') {
      newFacingMode = 'environment';
    }

    setState({
      ...state,
      facingMode: newFacingMode,
    });
  };

  return (
    <Container>
      <HeaderPage
        backgroundColor={colors.headerBg}
        color={colors.headerTitle}
        hideMainMenu
      >
        {state.title}
      </HeaderPage>
      <Content>
        {state.imageSrc ? (
          <Cropper
            ref={cropRef}
            src={state.imageSrc}
            cropFullImage={cropFullImage}
          />
        ) : (
          <Webcam
            audio={false}
            ref={webcamRef}
            forceScreenshotSourceSize={false}
            style={{
              flex: 1,
            }}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode: state.facingMode }}
          />
        )}
      </Content>
      <FooterPage>
        {!state.imageSrc && (
          <>
            <Icon
              name="IoCameraReverseSharp"
              size={35}
              color={colors.mainActiveTab}
              style={{
                marginRight: '10px',
              }}
              onClick={switchCamera}
            />
          </>
        )}
        <FlexSpace />

        <Button type="button" onClick={handlerClose}>
          {t('Close')}
        </Button>

        {!state.imageSrc && (
          <>
            <Button buttonType="Emphasized" type="button" onClick={onCapture}>
              {t('Capture')}
            </Button>
          </>
        )}

        {state.imageSrc && (
          <>
            <Button type="button" onClick={onNew}>
              {t('New')}
            </Button>
            <Button
              buttonType="Emphasized"
              type="button"
              onClick={e => {
                handlerConfirm(e);
              }}
            >
              {t('Confirm')}
            </Button>
          </>
        )}
      </FooterPage>
    </Container>
  );
}

export default Camera;
