/* eslint-disable no-restricted-syntax */
import request from '../../Request';

class ItemService {
  async getInformationsToImport(items) {
    const itemCodes = items
      .reduce(
        (accumulator, currentValue) =>
          `${accumulator},'${currentValue.ItemCode}'`,
        ''
      )
      .substring(1);

    return request({
      url: `APP/CRM/Items/import_informations`,
      method: 'POST',
      data: { itemCodes },
    });
  }

  async get(itemCode) {
    return request({
      url: `APP/CRM/Items/${encodeURIComponent(itemCode)}`,
    });
  }

  async list({ filter, auxFilters }) {
    let filters = '';

    // eslint-disable-next-line guard-for-in
    for (const prop in auxFilters) {
      filters += `&${prop}=${auxFilters[prop]}`;
    }

    return request({
      url: `APP/CRM/Items?$filter=${filter || ''}${filters}`,
    });
  }

  async listToDocument({ filter, cardCode, branchId, documentType }) {
    return request({
      url: `APP/CRM/Items/document/${documentType}?$filter=${filter ||
        ''}&$cardCode=${cardCode}&$branchId=${branchId}`,
    });
  }

  async warehouseList({ filter, itemCode, documentType, branchId }) {
    return request({
      url: `APP/CRM/Items/${encodeURIComponent(
        itemCode
      )}/Warehouses?$filter=${filter ||
        ''}&$documentType=${documentType}&$branchId=${branchId}`,
    });
  }

  async getUnitPrice({
    formRef,
    settings,
    priceListId,
    itemCode,
    documentType,
    businessPartnerCode,
    unitId,
    quantity,
    state,
    currencyId,
    uomEntry,
  }) {
    let route = '';
    switch (documentType) {
      case 'SalesOrder':
      case 'SalesQuotation':
      case 'SalesInvoice':
      case 'ReturnRequest':
        route = 'SalesUnitPrice';
        break;

      default:
        route = 'PurchaseUnitPrice';
        break;
    }

    const query = {
      priceListId,
      businessPartnerCode,
      unitId,
      quantity,
      state,
      currencyId,
      uomEntry,
    };

    const queryFields = [];

    for (let keyIndex = 0; keyIndex < Object.keys(query).length; keyIndex++) {
      const key = Object.keys(query)[keyIndex];

      const value = query[key];

      if (value) queryFields.push(`$${key}=${value}`);
    }

    const queryString = queryFields.join('&');

    const unitPrice = await request({
      formRef,
      eventAlias: 'onGetUnitPrice',
      settings,
      url: `APP/CRM/Items/${encodeURIComponent(
        itemCode
      )}/${route}?${queryString}`,
    });

    return unitPrice || 0;
  }

  async getWarehouseDefault({ itemCode, branchId, documentType }) {
    return request({
      url: `APP/CRM/Items/${encodeURIComponent(
        itemCode
      )}/DefaultWarehouse?$branchId=${branchId}&$documentType=${documentType}`,
    });
  }
}

export default new ItemService();
