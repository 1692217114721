const INITIAL_STATE = {
  status: false,
};

export default function loading(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_INACTIVITY_STATUS':
      return {
        status: action.payload.status,
      };

    case 'LOGIN_SUCCESS':
    case 'OUTSIGN_SUCCESS':
      return {
        ...INITIAL_STATE,
      };

    case 'COMPANY_SELECTED':
      return {
        ...INITIAL_STATE,
      };

    case 'LOGOFF_SUCCESS':
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
}
