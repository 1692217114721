/* eslint-disable react/prop-types */
import React, { useImperativeHandle, forwardRef } from 'react';
import { Container } from './styles';

function Question({ message, onConfirm }, ref) {
  useImperativeHandle(ref, () => ({
    onConfirm: () => onConfirm({ message: '' }),
  }));

  return (
    <Container>
      <span>{message}</span>
    </Container>
  );
}

export default forwardRef(Question);
