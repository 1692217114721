/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Modal from '~/easy-components/Modal';
import Form from '~/easy-components/Form';
import HeaderPage from '~/easy-components/HeaderPage';
import FooterPage from '~/easy-components/FooterPage';
import FlexSpace from '~/easy-components/FlexSpace';
import Button from '~/easy-components/Button';
import Panel from '~/easy-components/Panel';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Field from '~/easy-components/Form/UserField/Field';
import colors from '~/styles/colors';

import { Container, Content } from './styles';

function ModalAuxFields({ fields, onConfirm, onClose, t }, ref) {
  const formRef = useRef();

  const [state, setState] = useState({
    isShow: false,
    auxInfo: null,
    params: {},
    errors: {},
  });

  function handleClose(e) {
    e.stopPropagation();
    e.preventDefault();

    setState({
      ...state,
      isShow: false,
    });

    if (onClose) {
      onClose();
    }
  }

  const removeParamsNull = params => {
    const newParams = {};
    for (const param in params) {
      if (param.indexOf('_') !== 0) {
        const data = params[param];
        if (data !== null && data !== '') {
          newParams[param] = data;
        }
      }
    }

    return newParams;
  };

  const handlerConfirm = () => {
    // e.stopPropagation();
    // e.preventDefault();

    setState({
      isShow: false,
    });

    const params = formRef.current.getData();

    const paramsNormalized = removeParamsNull(params);

    onConfirm(paramsNormalized);
  };

  useImperativeHandle(ref, () => {
    return {
      show: ({ params, auxInfo }) => {
        setState({
          ...state,
          params,
          auxInfo,
          isShow: true,
        });
      },
      hide: () => {
        setState({
          ...state,
          auxInfo: null,
          isShow: false,
        });
      },
    };
  });

  return (
    <Modal isOpened={state.isShow}>
      <Container>
        <HeaderPage
          backgroundColor={colors.headerBg}
          color={colors.headerTitle}
        >
          {t('ManualSelection')}
        </HeaderPage>
        <Content>
          <Form ref={formRef} data={state.params || {}} errors={state.errors}>
            <ColumnLayout>
              <Panel width="330px">
                {fields.map(field => {
                  return (
                    <Field
                      key={`${field.name}`}
                      target={field}
                      formRef={formRef}
                      autoFocus={field.autoFocus}
                    />
                  );
                })}
              </Panel>
            </ColumnLayout>
          </Form>
        </Content>
        {state.auxInfo}
        <FooterPage>
          <FlexSpace />
          <Button
            buttonType="Emphasized"
            type="button"
            onClick={handlerConfirm}
          >
            {t('Confirm')}
          </Button>
          <Button type="button" onClick={handleClose}>
            {t('Close')}
          </Button>
        </FooterPage>
      </Container>
    </Modal>
  );
}

export default forwardRef(ModalAuxFields);
