/* eslint-disable import/no-cycle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MdArrowBack } from '@react-icons/all-files/md/MdArrowBack';
import JsonPage from '~/components/Pages/JsonPage';
import useLocale from '~/hooks/useLocale';

import history from '~/routes/history';
import systemColors from '~/styles/colors';
import { colors } from '~/easy-components/styles';
import { FooterPage, HeaderPage, Title, Toast } from '~/easy-components';
import Card from '~/easy-components/Card';
import Page from '~/components/Page';
import UserPageService from '../../services/UserPageService';
import LoadInformations from './components/LoadInformations';
import { Frame, Permission } from './styles';

function UserPage(props) {
  const [canExecute, setCanExecute] = useState(false);
  const t = useLocale('_Global');

  const { hash } = props.computedMatch.params;

  const pageId = useMemo(
    () =>
      props.computedMatch.url ||
      window.location.pathname.substring(1) + window.location.search,
    [props.computedMatch.url]
  );

  const pageExternalData = useSelector(redux => {
    const pageExt =
      redux.pages.find(
        page => page.pageId.toUpperCase() === (pageId || '').toUpperCase()
      ) || {};

    return pageExt.externalData;
  });

  const loadAllowExecution = async () => {
    const canExecuteUserPage = await UserPageService.checkPermissions({ hash });

    if (!canExecuteUserPage) {
      history.goBack();
      Toast.error(t('message.NoPermission'));
    } else {
      setCanExecute(true);
    }
  };

  if (!props.settings) {
    return <LoadInformations t={t} />;
  }

  if (!canExecute) {
    loadAllowExecution();
    return (
      <Permission>
        <h2>{t('message.CheckingPermission')}</h2>
      </Permission>
    );
  }

  return pageExternalData && pageExternalData.showInternalUrl ? (
    <Page isExecutionPage isUserPage>
      <Card width="900px" height="900px" isLoading={false}>
        <HeaderPage
          backgroundColor={systemColors.headerBg}
          color={systemColors.headerTitle}
        >
          <Title>{pageExternalData.title}</Title>
        </HeaderPage>
        <Frame src={pageExternalData.url} title={pageExternalData.name} />
        <FooterPage>
          <div
            style={{
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: `2px solid ${colors.main}`,
              cursor: 'pointer',
              marginRight: '10px',
            }}
          >
            <MdArrowBack
              size={22}
              color={colors.main}
              onClick={() => history.goBack()}
            />
          </div>
        </FooterPage>
      </Card>
    </Page>
  ) : (
    <JsonPage {...props} route={props.settings._route} hash={hash} isUserPage />
  );
}

export default UserPage;
