/* eslint-disable no-unused-vars */
export const setViewMode = ({ pageId, viewMode }) => ({
  type: 'SET_VIEW_MODE',
  payload: {
    pageId,
    viewMode,
  },
});

export const updateSettings = ({ pageId, settings }) => ({
  type: 'UPDATE_SETTINGS',
  payload: {
    pageId,
    settings,
  },
});
