import { combineReducers } from 'redux';
import crmReducers from '~/applications/CRM/store/crmReducers';
import log from './modules/log/reducer';
import mainMenu from './modules/mainMenu/reducer';
import pages from './modules/pages/reducers';
import loading from './modules/loading/reducer';
import tasks from './modules/tasks/reducer';
import alertMessage from './modules/alertMessage/reducer';
import configurations from './modules/configurations/reducer';
import authorizationToken from './modules/authorizationToken/reducer';
import companyToken from './modules/companyToken/reducer';
import uiDesign from './modules/uiDesign/reducer';
import inactivity from './modules/inactivity/reducer';

export default combineReducers({
  authorizationToken,
  companyToken,
  log,
  mainMenu,
  pages,
  loading,
  tasks,
  alertMessage,
  configurations,
  uiDesign,
  inactivity,
  ...crmReducers,
});
