import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${colors.blue};
  cursor: pointer;
`;
