/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, {
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  memo,
} from 'react';
import { createPortal } from 'react-dom';

import { Container, Content, ModalBody, TextBox } from './styles';

import Button from '~/easy-components/Button';

import PageHeader from '~/easy-components/PageHeader';
import ModalFooter from '~/easy-components/ModalFooter';
import useLocale from '~/hooks/useLocale';

function PromptModal({ direction }, ref) {
  const t = useLocale('_Global');

  const [state, setState] = useState({
    open: false,
    title: '',
    message: '',
    type: 'textarea',
    labelButtons: 'confirm-cancel', // 'yes-no' || 'confirm-cancel',
    onConfirm: () => {},
    onCancel: () => {},
  });

  const textRef = useRef(null);

  const modalRoot = document.getElementById('modal');
  const el = document.createElement('div');

  function handleClose() {
    setState({
      ...state,
      open: false,
    });
  }

  useImperativeHandle(ref, () => {
    return {
      show: async ({
        type,
        labelButtons,
        title,
        message,
        onConfirm,
        onCancel,
      }) => {
        setState({
          type,
          labelButtons,
          open: true,
          title,
          message,
          onConfirm,
          onCancel,
        });
      },
      handleClose,
    };
  });

  useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el, modalRoot]);

  function getComponentType() {
    switch (state.type) {
      case 'textarea':
        return (
          <>
            <span>{state.message}</span>
            <TextBox ref={textRef} />
          </>
        );

      case 'question':
        return (
          <>
            <span>{state.message}</span>
          </>
        );

      default:
        return <></>;
    }
  }

  function getConfirmLabel() {
    switch (state.labelButtons) {
      case 'yes-no':
        return t('Yes');

      case 'confirm-cancel':
        return t('Confirm');

      default:
        return t('Confirm');
    }
  }

  function getCancelLabel() {
    switch (state.labelButtons) {
      case 'yes-no':
        return t('No');

      case 'confirm-cancel':
        return t('Cancel');

      default:
        return t('Close');
    }
  }

  return (
    <>
      {state.open &&
        createPortal(
          <Container direction={direction} onMouseDown={handleClose}>
            <Content
              onMouseDown={e => {
                e.stopPropagation();
                // não incluir o e.preventDefault(); que os inputs param de funcionar
              }}
            >
              <PageHeader>{state.title}</PageHeader>
              <ModalBody type={state.type}>{getComponentType()}</ModalBody>

              <ModalFooter>
                <Button
                  buttonType="Emphasized"
                  onClick={() => {
                    // O Close deve ser fechado antes de qualquer ação
                    handleClose();
                    if (state.type === 'textarea')
                      state.onConfirm({ message: textRef.current.value });
                    else state.onConfirm({ message: '' });
                  }}
                >
                  {getConfirmLabel()}
                </Button>
                <Button
                  onClick={() => {
                    // O Close deve ser fechado antes de qualquer ação
                    handleClose();

                    if (state.onCancel) state.onCancel();
                  }}
                >
                  {getCancelLabel()}
                </Button>
              </ModalFooter>
            </Content>
          </Container>,
          el
        )}
    </>
  );
}

export default memo(forwardRef(PromptModal));
