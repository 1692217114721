import React from 'react';
import { CgSmileNone as Icon } from '@react-icons/all-files/cg/CgSmileNone';

import { Container } from './styles';

function Disabled() {
  return (
    <Container>
      <Icon size={42} color="#AAA" />
      <div>
        No momento <br />
        nenhuma mensagem pode ser enviada.
        <br />
        Selecione um item na lista
      </div>
    </Container>
  );
}

export default Disabled;
