/* eslint-disable react/prop-types */
import React from 'react';

import { Container } from './styles';
import Button from '~/easy-components/Button';

function AddNew({ handle, title }) {
  return (
    <Container>
      <Button onClick={handle}>{title}</Button>
    </Container>
  );
}

export default AddNew;
