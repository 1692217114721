export default function normalizeData(data) {
  const normalized = {};
  Object.entries(data).forEach(([key, value]) => {
    if (!key.startsWith('_')) {
      if (Array.isArray(value)) {
        const array = value.map(item => normalizeData(item));
        normalized[key] = array;
      } else normalized[key] = value;
    }
  });

  return normalized;
}
