/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import { isMobile } from 'react-device-detect';
import PageHeader from '~/easy-components/PageHeader';
import PageBody from '~/easy-components/PageBody';
import PageFooter from '~/easy-components/PageFooter';
import Button from '~/easy-components/Button';
import Linker from '~/easy-components/Form/Linker';
import Checkbox from '~/easy-components/Form/Checkbox';
import Form from '~/easy-components/Form';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Panel from '~/easy-components/Panel';
import Toast from '~/easy-components/Toast';
import { CrystalExportTypes } from '~/enum/CrystalExportTypes';
import { Container, Separator, TitleMultipleChoice } from './styles';
import { createAsyncFunctionByString } from '~/easy-components/AsyncFunctionString';
import Field from '~/easy-components/Form/UserField/Field';

function FormParams(
  {
    title,
    t,
    data: formData,
    isCancelModalParams = false,
    onConfirm,
    setIsShow,
    errors,
    params,
    paramsPanelWidth,
    settings,
    onCancel = () => {},
  },
  ref
) {
  const formRef = useRef();
  const requiredFields = useRef([]);
  const [formParamsData, setFormParamsData] = useState();

  const getMultipleChoice = (param, props, handleChange) => {
    const data = param.data || [];

    return data.map(option => {
      return (
        <Checkbox
          valueChecked="Y"
          valueUnchecked="N"
          // {...props}
          // {...param}
          name={`_multipleChoice-${param.name}-${option.value}`}
          label={option.label}
          initialFilterMobileText={false}
          fixedData2={[
            { label: 'Sim', value: 'Y' },
            { label: 'Não', value: 'N' },
          ]}
          onChange={handleChange}
        />
      );
    });
  };

  const getLabel = param => {
    let { label } = param;

    if (param.translateRoute) {
      label = t(param.translateKey || param.name, param.translateRoute);
    }

    return label;
  };

  function getField(param) {
    const label = getLabel(param);

    const dynamicOnChange = param.onChange
      ? createAsyncFunctionByString({
          functionString: param.onChange,
        })
      : null;

    const props = {
      key: param.name,
      labelWidth: 200,
      width: 330,
      formRef,
      label,
      required: param.required,
    };

    const handleChange = async data => {
      if (dynamicOnChange) {
        await dynamicOnChange({
          data,
          form: formRef.current,
          formData: formRef.current.getData(),
          ...settings.dynamicFunctionProps,
        });
      }
    };

    const { type, ...fieldParam } = param;

    if (param.required) {
      requiredFields.current.push(param.valueField || param.name);
    }

    if (type.toUpperCase() === 'DATE') {
      props.returnFormat = 'yyyy-MM-dd';
    }

    switch (type.toUpperCase()) {
      case 'EXPORTTYPE':
        const EXPORT_TYPE_NAME = 'rptExportType';

        requiredFields.current.push(EXPORT_TYPE_NAME);

        if (!param.files) {
          Toast.error(t('message.FillFilesTypesToExport'));
          return null;
        }
        // eslint-disable-next-line no-case-declarations
        const crystalExportTypes = [];
        param.files.forEach(typeFile => {
          CrystalExportTypes.forEach(ceTypes => {
            if (ceTypes.extension.toUpperCase() === typeFile.toUpperCase()) {
              const hasType = crystalExportTypes.find(
                c =>
                  c.extension === ceTypes.extension && c.label === ceTypes.label
              );
              if (!hasType) {
                crystalExportTypes.push({
                  value: ceTypes.value,
                  label: t(ceTypes.label),
                  extension: ceTypes.extension,
                });
              }
            }
          });
        });

        return (
          <Linker
            {...props}
            valueField={EXPORT_TYPE_NAME}
            fixedData={crystalExportTypes}
            name={EXPORT_TYPE_NAME}
            {...fieldParam}
            label={t(props.label)}
            onChange={handleChange}
          />
        );
      case 'MULTIPLECHOICE':
        return (
          <>
            <TitleMultipleChoice>
              {param.label || param.title || ''}
            </TitleMultipleChoice>
            {getMultipleChoice(param, props, handleChange)}
          </>
        );

      case 'SEPARATOR':
        return <Separator>{param.text || ''}</Separator>;

      default:
        return (
          <Field
            {...props}
            {...fieldParam}
            target={param}
            onChange={handleChange}
          />
        );
    }
  }

  const validateParams = data => {
    let isValid = true;

    for (
      let requiredFieldIndex = 0;
      requiredFieldIndex < requiredFields.current.length;
      requiredFieldIndex++
    ) {
      const field = requiredFields.current[requiredFieldIndex];

      if (data[field] === undefined || data[field] === null) {
        formRef.current.setFieldError(
          field,
          t('message.GenericRequiredField', '_Global', { field })
        );

        isValid = false;
      } else {
        formRef.current.setFieldError(field, null);
      }
    }

    return isValid;
  };

  const putMultipleChoicesInData = (data = {}) => {
    const treatedData = { ...data };

    const multipleChoicesFields = params.filter(param => {
      const type = param.type || '';

      return type.toUpperCase() === 'MULTIPLECHOICE';
    });

    multipleChoicesFields.forEach(field => {
      const allEntries = Object.entries(data);

      const allEntriesMapped = allEntries.map(entry => {
        const [key, value] = entry;
        return { key, value };
      });

      const allOptions = allEntriesMapped.filter(entryObject => {
        const key = entryObject.key || '';

        const belongsToField = key.startsWith(`_multipleChoice-${field.name}`);

        return belongsToField;
      });

      const allSelectedOptions = allOptions.filter(
        option => option.value === 'Y'
      );

      const allSelectedOptionsValues = allSelectedOptions.map(option => {
        return option.key.slice(`_multipleChoice-${field.name}-`.length);
      });

      treatedData[field.valueField] = allSelectedOptionsValues;
    });

    Object.keys(treatedData).forEach(key => {
      if (typeof key !== 'string') return false;

      /* if (key.startsWith('_multipleChoice')) {
        delete treatedData[key];
      } */

      return true;
    });

    return treatedData;
  };

  const onClickConfirm = () => {
    const data = formRef.current.getData();

    const dataWithUnifiedMultipleChoices = putMultipleChoicesInData(data);

    const isValid = validateParams(dataWithUnifiedMultipleChoices);

    if (!isValid) {
      return Toast.warn(t('message.FillAllMandatoryFields'));
    }

    onConfirm(dataWithUnifiedMultipleChoices);

    setIsShow(false);

    return true;
  };

  useImperativeHandle(ref, () => {
    return {
      validate: () => {
        onClickConfirm();
      },
    };
  });

  params.forEach(param => {
    if (!isMobile) param.labelPosition = 'top';
  });

  useEffect(() => {
    setFormParamsData(formData);
  }, [formData, params]);

  return (
    <Container
      paramsPanelWidth={paramsPanelWidth}
      labelPosition={
        params.find(e => e.labelPosition === 'top') !== undefined
          ? 'top'
          : 'left'
      }
    >
      {isMobile && <PageHeader>{title}</PageHeader>}
      <PageBody>
        <Container>
          <Form ref={formRef} data={formParamsData} errors={errors}>
            <div style={{ overflowY: 'scroll' }}>
              <ColumnLayout
                labelPosition={
                  params.find(e => e.labelPosition === 'top') !== undefined
                    ? 'top'
                    : 'left'
                }
              >
                <Panel
                  width="100%"
                  labelPosition={
                    params.find(e => e.labelPosition === 'top') !== undefined
                      ? 'top'
                      : 'left'
                  }
                >
                  {params.map(param => {
                    return getField(param);
                  })}
                </Panel>
              </ColumnLayout>
            </div>
          </Form>
        </Container>
      </PageBody>
      <PageFooter style={{ flex: 'initial' }}>
        <Button
          style={{ marginLeft: '0px' }}
          buttonType="Default"
          onClick={() => {
            formRef.current.reset();
          }}
        >
          {t('Clear')}
        </Button>
        <Button buttonType="Emphasized" onClick={onClickConfirm}>
          {t('Confirm')}
        </Button>
        {isCancelModalParams && (
          <Button
            onClick={() => {
              setIsShow(false);
              onCancel();
            }}
          >
            {t('Cancel')}
          </Button>
        )}
      </PageFooter>
    </Container>
  );
}

export default forwardRef(FormParams);
