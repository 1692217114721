/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-cycle */
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import Linker from '~/easy-components/Form/Linker';

import ColumnLayout from '~/easy-components/ColumnLayout';
import Panel from '~/easy-components/Panel';

import useStateCache from '~/hooks/useStateCache';
import CompanyUserService from '../../../../services/CompanyUserService';
import Users from './Users';
import { Permissions } from './styles';

function UsersPermissions({ settings, formRef, isReadOnly, t }, ref) {
  const [systemUsers, setSystemUsers] = useState([]);
  const [users, setUsers, getUsers] = useStateCache([]);
  const [permissionType, setPermissionType] = useState(1);

  useEffect(() => {
    async function loadUsers() {
      const companyUserList = await CompanyUserService.list();
      setSystemUsers(companyUserList);
    }

    loadUsers();
  }, []);

  function changePermission(data, status) {
    if (status === 1) {
      const userList = users.filter(user => user.id !== data.id);
      setUsers(userList);
    } else {
      const usersRaw = JSON.stringify(users);
      const list = JSON.parse(usersRaw) || [];

      list.push({
        id: data.id,
        status: 1,
      });

      setUsers(list);
    }
  }

  const fieldProps = {
    settings,
    formRef,
    readOnly: isReadOnly,
  };

  useImperativeHandle(ref, () => {
    return {
      setPermissionType: type => {
        setPermissionType(type);
        formRef.current.setFieldValue('permissionType', type);
      },
      setUsers: userList => {
        setUsers(userList);
      },
      getUsers: () => {
        return getUsers();
      },
    };
  });

  return (
    <Permissions>
      <ColumnLayout style={{ flex: '0' }}>
        <Panel width="330px">
          <Linker
            label={t('permissionType')}
            name="permissionType"
            valueField="permissionType"
            {...fieldProps}
            fixedData={[
              { value: 0, label: t('permissionType_NoExceptions') },
              { value: 1, label: t('permissionType_Allow') },
              { value: 2, label: t('permissionType_Blocked') },
            ]}
            onChange={({ value }) => {
              setPermissionType(value);
            }}
          />
        </Panel>
      </ColumnLayout>
      {(permissionType == 1 || permissionType == 2) && (
        <Users
          t={t}
          permissionType={permissionType}
          formRef={formRef}
          users={users}
          systemUsers={systemUsers}
          onChange={(data, status) => {
            changePermission(data, status);
          }}
        />
      )}
    </Permissions>
  );
}

export default forwardRef(UsersPermissions);
