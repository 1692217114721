/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import Html5QrcodePlugin from './Html5QrcodePlugin';

import { Container, Content, Message } from './styles';
import ComponentContext from '../../context';

function MobileCam() {
  const { message, t } = useContext(ComponentContext);

  return (
    <Content>
      <Message>
        <h1>{t('WaitingForReading')}</h1>
        {message}
      </Message>
      <Container>
        <Html5QrcodePlugin />
      </Container>
    </Content>
  );
}

export default MobileCam;
