import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Toast } from '~/easy-components';

const useSocket = url => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(url);

    newSocket.on('connect', () => {
      // console.log('Connected to socket server');
    });

    newSocket.on('msgFromServer', data => {
      // console.log('recebido do servidor', data);
      Toast.success(data);
    });

    newSocket.on('disconnect', () => {
      // console.log('Disconnected from socket server');
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [url]);

  return socket;
};

export default useSocket;
