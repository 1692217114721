/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import useLocale from '~/hooks/useLocale';
import useCompany from '../../../../hooks/useCompany';
import Input from '../../Input';
import Checkbox from '../../Checkbox';
import Number from '../../Number';
import Linker from '../../Linker';
import Tag from '../../Tag';
import QueryField from '../../QueryField';
import DateField from '../../DateField';
import Display from '../../Display';
import Attachment from '../../Attachment';
import TimeField from '../../TimeField';
import MaskField from '../../MaskField';

function Field({
  baseName,
  target,
  formRef,
  mainFormRef,
  settings,
  readOnly,
  auxScope,
  autoFocus = false,
  onChange = () => {},
  ...props
}) {
  const t = useLocale('_Global');
  const [data, setData] = useState([]);
  const [componentType, setComponentType] = useState(null);
  const [fieldDefault, setFieldDefault] = useState(null);
  const { companySettings } = useCompany();

  const [label, setLabel] = useState(' ');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadData = ({ info }) => {
    if (info && info.length > 0) {
      const {
        fieldLabel,
        defaultValue,
        fieldType,
        editType,
        validationType,
      } = info[0];

      setLabel(fieldLabel);
      setFieldDefault(defaultValue || props.defaultValue);

      switch (validationType) {
        case 'ValidValues':
          setComponentType('Combo');
          // eslint-disable-next-line no-case-declarations
          const listItems = info.map(line => ({
            value: line.value,
            label: line.label ? line.label : line.value,
          }));

          setData(listItems);
          break;

        case 'None':
          switch (fieldType) {
            case 'B':
              switch (editType) {
                case 'P':
                  setComponentType('Price');
                  break;

                case 'Q':
                  setComponentType('Quantity');
                  break;

                case '%':
                  setComponentType('Percent');
                  break;

                case 'R':
                  setComponentType('Rate');
                  break;

                case 'S':
                  setComponentType('Value');
                  break;

                case 'M':
                  setComponentType('Measury');
                  break;

                default:
                  break;
              }
              break;

            case 'N':
              setComponentType('Number');
              break;

            case 'D':
              setComponentType('Date');
              break;

            default:
              setComponentType('Text');
              break;
          }
          break;

        default:
          setComponentType('NotFound');
          setFieldDefault(null);
          break;
      }
    } else {
      setComponentType('NotFound');
      setLabel(t('NotIdentified'));
      setFieldDefault(null);
    }
  };

  useEffect(() => {
    const type = target.type || 'userField';

    switch (type) {
      case 'userField':
        loadData({
          info: settings.userFieldsInfo
            ? settings.userFieldsInfo[target.name.substring(2)]
            : null,
        });
        break;

      default:
        setComponentType(type);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (label && componentType) {
    let value = target.value === undefined ? `${target.name}` : target.value;
    let name = target.value === undefined ? `_${target.name}` : target.name;

    if (target.fieldValue || target.valueField) {
      value = target.fieldValue || target.valueField;
      name = target.name;
    }

    if (props.type && props.type.toUpperCase() !== 'PASSWORD') {
      delete props.type;
    }

    switch (componentType.toUpperCase()) {
      case 'COMBO':
        // eslint-disable-next-line no-case-declarations
        const fixedData = target.data || data || [];
        return (
          <>
            {fixedData.length > 0 ? (
              <Linker
                {...props}
                baseName={baseName}
                label={target.title || target.label || label || ' '}
                labelWidth={target.labelWidth || props.labelWidth}
                name={`${target.name}`}
                valueField={`${target.name}`}
                settings={settings}
                formRef={formRef}
                mainFormRef={mainFormRef}
                readOnly={readOnly || target.readOnly}
                hidden={target.hidden}
                fixedData={fixedData}
                queryCode={target.queryCode}
                fieldDefaultValue={target.defaultValue || fieldDefault}
                auxScope={auxScope}
                autoFocus={autoFocus}
                onChange={onChange}
              />
            ) : null}
          </>
        );

      case 'DATE':
        return (
          <DateField
            {...props}
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            returnFormat={target.returnFormat}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'TIMER':
        return (
          <TimeField
            {...props}
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            mask="99:99"
            auxScope={auxScope}
            onChange={onChange}
          />
        );

      case 'NUMBER':
        return (
          <Number
            {...props}
            showLink={target.showLink}
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            type="value"
            auxScope={auxScope}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'INTEGER':
        return (
          <Number
            {...props}
            showLink={target.showLink}
            type="integer"
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'QUANTITY':
        return (
          <Number
            {...props}
            showLink={target.showLink}
            type="quantity"
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'MEASURE':
        return (
          <Number
            {...props}
            showLink={target.showLink}
            type="quantity"
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'PRICE':
        return (
          <Number
            {...props}
            showLink={target.showLink}
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={props ? props.hidden : null}
            symbol={companySettings.CurrencyCode}
            type="price"
            enableChangeSymbol={props ? props.enableChangeSymbol : null}
            onSearchSymbol={props ? props.onSearchSymbol : null}
            onSelectedSymbol={props ? props.onSelectedSymbol : null}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'PERCENT':
        return (
          <Number
            {...props}
            showLink={target.showLink}
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            symbol="%"
            type="percent"
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'RATE':
        return (
          <Number
            {...props}
            showLink={target.showLink}
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            symbol=""
            dtype="percent"
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'VALUE':
        return (
          <Number
            {...props}
            showLink={target.showLink}
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            symbol=""
            type="value"
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'QUERYFIELD':
        return (
          <QueryField
            {...props}
            showLink={target.showLink}
            baseName={baseName}
            label={target.title || target.label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={name}
            valueField={value}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            settings={settings}
            formRef={formRef}
            mainFormRef={mainFormRef}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            sql={target.sql}
            queryCode={target.queryCode}
            auxScope={auxScope}
            forceSelection={target.forceSelection}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'TAG':
        return (
          <Tag
            {...props}
            baseName={baseName}
            label={target.title || target.label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={target.name}
            settings={settings}
            formRef={formRef}
            mainFormRef={mainFormRef}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            sql={target.sql}
            fixedData={target.fixedData}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            separator={target.separator}
            auxScope={auxScope}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'FIXEDDATA':
        return (
          <Linker
            {...props}
            showLink={target.showLink}
            baseName={baseName}
            label={target.title || target.label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={value}
            valueField={value}
            settings={settings}
            formRef={formRef}
            fixedData={target.data || target.fixedData || []}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            queryCode={target.queryCode}
            mainFormRef={mainFormRef}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            auxScope={auxScope}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'DISPLAY':
        return (
          <Display
            {...props}
            showLink={target.showLink}
            baseName={baseName}
            label={target.title || target.label || label || ''}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            onChange={onChange}
          />
        );

      case 'CHECKBOX':
        return (
          <Checkbox
            {...props}
            baseName={baseName}
            disableRenderMobile={target.disableRenderMobile}
            valueChecked={target.valueChecked}
            valueUnchecked={target.valueUnchecked}
            label={target.title || target.label || label || ''}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            hidden={target.hidden}
            height={target.height}
            onChange={onChange}
          />
        );

      case 'ATTACHMENT':
        return (
          <Attachment
            {...props}
            baseName={baseName}
            label={target.title || target.label || label || ''}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            onChange={onChange}
          />
        );

      case 'HIDDEN':
        return (
          <Input
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            fullScreen={target.fullScreen}
          />
        );

      case 'MASKFIELD':
        return (
          <MaskField
            {...props}
            showLink={target.showLink}
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            mask={target.mask}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            fullScreen={target.fullScreen}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );

      case 'CUSTOMELEMENT':
        return props.element;

      default:
        return (
          <Input
            {...props}
            showLink={target.showLink}
            baseName={baseName}
            label={target.title || target.label || label || ' '}
            labelWidth={target.labelWidth || props.labelWidth}
            name={`${target.name}`}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            readOnly={readOnly || target.readOnly}
            hidden={target.hidden}
            fieldDefaultValue={target.defaultValue || fieldDefault}
            auxScope={auxScope}
            fullScreen={target.fullScreen}
            autoFocus={autoFocus}
            onChange={onChange}
          />
        );
    }
  }

  return (
    <Input
      {...props}
      baseName={baseName}
      label={label || ' '}
      labelWidth={target.labelWidth || props.labelWidth}
      name={`${target.name}`}
      formRef={formRef}
      mainFormRef={mainFormRef}
      settings={settings}
      readOnly={readOnly || target.readOnly}
      hidden={target.hidden}
      fieldDefaultValue={target.defaultValue || fieldDefault}
      auxScope={auxScope}
      fullScreen={target.fullScreen}
      onChange={onChange}
    />
  );
}

export default Field;
