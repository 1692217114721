/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useCallback } from 'react';

import { FiSave as SaveIcon } from '@react-icons/all-files/fi/FiSave';
import { FaRegCopy } from '@react-icons/all-files/fa/FaRegCopy';
import { AiOutlineEyeInvisible } from '@react-icons/all-files/ai/AiOutlineEyeInvisible';
import { AiOutlineEye } from '@react-icons/all-files/ai/AiOutlineEye';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import Form from '~/easy-components/Form';
import Toast from '~/easy-components/Toast';
import ModalHandle from '~/easy-components/ModalHandle';
import Button from '~/easy-components/Button';
import PageHeader from '~/easy-components/PageHeader';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Panel from '~/easy-components/Panel';
import TreatError from '~/easy-components/TreatError';
import Input from '~/easy-components/Form/Input';
import ModalFooter from '~/easy-components/ModalFooter';
import JsonTable from '~/easy-components/JsonTable';
import ApplicationService from '~/services/ApplicationService';
import ApplicationSettingsService from '~/services/ApplicationSettingsService';
import colors from '~/styles/colors';
import MasterDetail from '~/components/Pages/MasterDetail';
import useLocale from '~/hooks/useLocale';
import CodeEditor from '~/easy-components/CodeEditor';
import useStateCache from '~/hooks/useStateCache';
import useProjectLinker from '~/easy-components/Helpers/linkProject';
import useHelpers from '~/hooks/useHelpers';
import { updateSettings as updateSettingsAction } from '~/store/modules/uiDesign/actions';
import AppItem from './AppItem';
import { CommandPanel, Footer, ModalBody } from './styles';
import Upload from './components/Upload/index';

export default function Edit({ settings = {}, ...props }) {
  const dispatch = useDispatch();

  const { dynamicFunctionProps } = useHelpers();
  const { showLoading, showPage, showModalFields } = dynamicFunctionProps;
  const t = useLocale('ApplicationsPage', settings.translate);
  const tapp = useLocale('Applications');

  const formRef = useRef();
  const masterDetailRef = useRef();

  const uploadModalRef = useRef();
  const uploadResultModalRef = useRef();
  const [contentUploaded, setContentUploaded] = useState({
    data: [],
    columns: [],
    reportSettings: {},
    rowKeyField: null,
  });

  const defaultSettingsRef = useRef();
  const customSettingsRef = useRef();

  const [isDefaultVisible, setIsDefaultVisible] = useState(true);
  const [applicationId, setApplicationId] = useState(null);
  const [defaultSettings, setDefaultSettings] = useState('');
  const [customSettings, setCustomSettings] = useState('');
  const [customSettingsId, setCustomSettingsId] = useState(null);
  const [
    applicationInfo,
    setApplicationInfo,
    getApplicationInfo,
  ] = useStateCache({});

  const [menus, setMenus] = useState([]);

  const { linkProject } = useProjectLinker({
    type: 'application',
    showModalFields,
    showLoading,
    keyCode: '',
  });

  const fileInfo = useRef({
    fileName: null,
    file: null,
  });

  const fieldProps = {
    formRef,
    settings,
  };

  const clearFileInfo = useCallback(() => {
    fileInfo.current = {
      fileName: null,
      file: null,
    };
  }, []);

  const splitFileName = useCallback(fileName => {
    const idx = fileName.lastIndexOf('.');

    const name = fileName.substring(0, idx);

    const extension = fileName.substring(idx + 1);

    return {
      name,
      extension,
    };
  }, []);

  async function exportCustomApps() {
    await ApplicationService.downloadQueries();
  }

  function handleUploadClick() {
    uploadModalRef.current.handleOpen();
  }

  async function onSearch({ filter, auxFilters }) {
    const response = await ApplicationService.list({ filter, auxFilters });
    return response;
  }

  async function onSelectApplication({ selectedItem }) {
    const responseSettings = await ApplicationService.get(selectedItem.id);

    const responseCustom = await ApplicationSettingsService.getSettings(
      selectedItem.id
    );

    setIsDefaultVisible(
      responseCustom
        ? responseCustom.settings === null || responseCustom.settings === ''
        : true
    );

    return {
      id: selectedItem.id,
      route: selectedItem.route,
      name: selectedItem.name,
      translatedRoute: selectedItem.translatedRoute,
      defaultSettings: responseSettings.defaultSettings || '',
      settings: responseCustom ? responseCustom.settings || '' : '',
      customSettingsId: responseCustom ? responseCustom.id : null,
    };
  }

  function onLoadData({ data }) {
    setApplicationId(data.id);
    setDefaultSettings(data.defaultSettings);
    setCustomSettings(data.settings);
    setCustomSettingsId(data.customSettingsId);
    setApplicationInfo(data);

    defaultSettingsRef.current = {
      defaultSettings: data.defaultSettings,
      applicationId: data.id,
      customSettingsId: data.customSettingsId,
      route: data.route,
    };

    setIsDefaultVisible(
      data ? data.settings === null || data.settings === '' : true
    );

    customSettingsRef.current.input.focus();

    setMenus([
      {
        id: 'mnuExportTemplate',
        icon: 'CiImport',
        text: t('ExportCustomApps'),
        handler: exportCustomApps,
      },
      {
        id: 'mnuImportTemplate',
        icon: 'CiExport',
        text: t('UploadZipFile'),
        handler: handleUploadClick,
      },
      {
        id: 'mnuLinkToProject',
        icon: 'FaFileImport',
        disabled: !data.id,
        text: t('mnuLinkToProject'),
        handler: () => {
          linkProject(data.route);
        },
      },
    ]);
  }

  function onGetDataToSave() {
    const info = getApplicationInfo();
    return {
      id: defaultSettingsRef.current.applicationId,
      route: defaultSettingsRef.current.route,
      defaultSettings: defaultSettingsRef.current.defaultSettings,
      settings: customSettingsRef.current.input.value,
      customSettingsId: defaultSettingsRef.current.customSettingsId,
      name: info.name,
      translatedRoute: info.translatedRoute,
    };
  }

  async function onSave() {
    try {
      showLoading(true);

      if (!applicationId) {
        throw new Error(t('SelectApplication'));
      }

      const response = await ApplicationSettingsService.createOrUpdate({
        applicationId,
        settings: customSettings,
        id: customSettingsId,
      });

      const newOnLoadData = {
        route: response.route,
        id: defaultSettingsRef.current.applicationId,
        defaultSettings: defaultSettingsRef.current.defaultSettings,
        settings: response.settings,
        customSettingsId: response.id,
      };

      onLoadData({ data: newOnLoadData });

      dispatch(
        updateSettingsAction({
          pageId: newOnLoadData.route,
          settings: response._settings,
        })
      );

      return newOnLoadData;
    } catch (error) {
      TreatError.showError(error);
      return null;
    } finally {
      showLoading(false);
    }
  }

  async function onLoadExternalData({ data }) {
    try {
      const application = await ApplicationService.getByCode(data);
      await masterDetailRef.current.executeSelectionItem(application);
      setTimeout(async () => {
        await masterDetailRef.current.refreshList(application.route);
      }, 200);
    } catch (error) {
      TreatError.showError(error);
    }
  }

  function handleClose(modalRef) {
    modalRef.current.handleClose();
  }

  function isUploadModalToOpen(customApps) {
    for (let index = 0; index < customApps.length; index++) {
      const customAppStatusCode = customApps[index].statusCode;

      if (customAppStatusCode !== 'S') {
        return true;
      }
      index++;
    }
    return false;
  }

  function handleUploadModalOpen(customApps) {
    const openUploadModal = isUploadModalToOpen(customApps);
    if (openUploadModal) uploadResultModalRef.current.handleOpen();
  }

  async function onUpload() {
    try {
      showLoading(true);

      const response = await ApplicationService.attachFile({
        fileName: fileInfo.current.fileName,
        file: fileInfo.current.file,
      });

      clearFileInfo();
      handleClose(uploadModalRef);

      Toast.success(t('message.SystemMessageSuccess'));

      setContentUploaded(response);

      const customQueries = response.data;

      handleUploadModalOpen(customQueries);

      masterDetailRef.current.refreshList();
    } catch (err) {
      Toast.error(err);
    } finally {
      showLoading(false);
    }
  }

  return (
    <MasterDetail
      isShowLog={false}
      enableDesignMode={false}
      ref={masterDetailRef}
      formRef={formRef}
      menus={menus}
      formData={applicationInfo}
      auxTitle={
        applicationInfo && applicationInfo.route
          ? tapp(applicationInfo.route)
          : ''
      }
      showLoading={showLoading}
      route="ApplicationsPage"
      keyField="id"
      settings={{
        auxFilters: [
          {
            name: 'customSettings',
            title: t('CustomSettings'),
            labelWidth: 200,
            type: 'fixedData',
            data: [
              {
                value: 2,
                label: t('All'),
              },
              {
                value: 1,
                label: t('Yes'),
              },
              {
                value: 0,
                label: t('No'),
              },
            ],
          },
        ],
      }}
      sideWidth={350}
      onSelectedItem={onSelectApplication}
      onRenderListItem={app => {
        return <AppItem data={app} />;
      }}
      onSearch={onSearch}
      onGetDataToSave={onGetDataToSave}
      onLoadData={onLoadData}
      onLoadExternalData={onLoadExternalData}
      onSave={onSave}
      isShowSave
      isShowDelete={false}
      isShowNew={false}
      isShowSystemLogs
      systemLogsRef="ApplicationSetting"
      systemLogskey="route"
      isFlexSpace={false}
      showPage={showPage}
      footerElements={() => {
        return (
          <Footer>
            <></>
          </Footer>
        );
      }}
      {...props}
    >
      <Form ref={formRef}>
        <CommandPanel>
          <div>
            <CodeEditor
              ref={customSettingsRef}
              title={t('CustomSettings')}
              editorType="json"
              value={customSettings || ''}
              onChange={value => {
                setCustomSettings(value);
              }}
              onCtrlS={async () => {
                await masterDetailRef.current.save({ hasQuestion: false });
              }}
              buttons={[
                <div
                  key="save"
                  data-tip={t('Save')}
                  data-for="ToolTipApplications"
                  onClick={async () => {
                    await masterDetailRef.current.save();
                  }}
                >
                  <SaveIcon size={24} color={colors.mainMenuIcons} />
                </div>,
                <div
                  key="visible"
                  onClick={() => setIsDefaultVisible(!isDefaultVisible)}
                >
                  {isDefaultVisible ? (
                    <div
                      data-tip={t('HideDefaultSetting')}
                      data-for="ToolTipApplications"
                    >
                      <AiOutlineEyeInvisible
                        size={24}
                        color={colors.mainMenuIcons}
                      />
                    </div>
                  ) : (
                    <div
                      data-tip={t('ShowDefaultSetting')}
                      data-for="ToolTipApplications"
                    >
                      <AiOutlineEye size={24} color={colors.mainMenuIcons} />
                    </div>
                  )}
                </div>,
              ]}
            />
          </div>
          <ReactTooltip
            id="ToolTipApplications"
            place="bottom"
            type="light"
            effect="solid"
            className="toolbarTooltip"
          />

          {isDefaultVisible && (
            <div>
              <CodeEditor
                title={t('DefaultSettings')}
                readOnly
                editorType="json"
                value={defaultSettings || ''}
                onChange={value => {
                  setDefaultSettings(value);
                }}
                buttons={[
                  <div
                    key="default"
                    onClick={() => setCustomSettings(defaultSettings || '')}
                  >
                    <FaRegCopy size={20} color={colors.mainMenuIcons} />
                  </div>,
                ]}
              />
            </div>
          )}
        </CommandPanel>

        <ModalHandle ref={uploadModalRef}>
          <PageHeader>{t('UploadZipFile')}</PageHeader>
          <ModalBody>
            <ColumnLayout>
              <Panel width="400px">
                <Input
                  label={t('File')}
                  name="FileName"
                  {...fieldProps}
                  hidden
                />
                <Input
                  label={t('FilExtension')}
                  name="FileExt"
                  {...fieldProps}
                  hidden
                />
                <Upload
                  message={t('message.SelectFile')}
                  accept=".zip"
                  onUpload={files => {
                    const file = files[0];
                    fileInfo.current = {
                      fileName: file.name,
                      file,
                    };

                    const fileInformation = splitFileName(file.name);

                    formRef.current.setFieldValue(
                      'FileName',
                      fileInformation.name
                    );
                    formRef.current.setFieldValue(
                      'FileExt',
                      fileInformation.extension
                    );
                  }}
                  isReadOnly={false}
                />
              </Panel>
            </ColumnLayout>
          </ModalBody>
          <ModalFooter>
            <Button
              buttonType="Emphasized"
              onClick={async () => {
                await onUpload();
              }}
            >
              Confirmar
            </Button>

            <Button
              onClick={() => {
                handleClose(uploadModalRef);
              }}
            >
              Fechar
            </Button>
          </ModalFooter>
        </ModalHandle>

        <ModalHandle ref={uploadResultModalRef}>
          <PageHeader>{t('UploadResult')}</PageHeader>
          <ModalBody>
            <JsonTable
              title="Uploaded Files"
              showToolbar={false}
              columns={contentUploaded.columns || []}
              data={contentUploaded.data || []}
              t={t}
              reportSettings={contentUploaded.reportSettings || {}}
              groups={
                contentUploaded.reportSettings
                  ? contentUploaded.reportSettings.groups
                  : null
              }
              settings={settings}
              showLoading={showLoading}
              hideToolbar
            />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                handleClose(uploadResultModalRef);
              }}
            >
              Fechar
            </Button>
          </ModalFooter>
        </ModalHandle>
      </Form>
    </MasterDetail>
  );
}
