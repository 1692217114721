/* eslint-disable import/no-cycle */
/* eslint-disable react/button-has-type */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';
import ModalHandle from '~/easy-components/ModalHandle';
import Button from '~/easy-components/Button';
import FlexSpace from '~/easy-components/FlexSpace';
import FooterPage from '~/easy-components/FooterPage';
import useLocale from '~/hooks/useLocale';
import { Container } from './styles';
import QueryPage from '~/pages/Queries';

// eslint-disable-next-line no-unused-vars
function QueryModal(_, ref) {
  const t = useLocale('_Global');
  const modalRef = useRef();

  const [properties, setProperties] = useState(null);

  useImperativeHandle(ref, () => {
    return {
      show: props => {
        setProperties(props);
        modalRef.current.handleOpen();
      },
      close: () => {
        modalRef.current.handleClose();
      },
    };
  });

  const pageSettings = {};

  return (
    <ModalHandle
      ref={modalRef}
      contentStyle={{ style: { width: '95%', height: '95%' } }}
    >
      <Container>
        <QueryPage settings={pageSettings} {...properties} />
        <FooterPage>
          <FlexSpace />
          <Button
            onClick={() => {
              modalRef.current.handleClose();
              if (properties.onClose) {
                properties.onClose();
              }
            }}
          >
            {t('Close')}
          </Button>
        </FooterPage>
      </Container>
    </ModalHandle>
  );
}

export default forwardRef(QueryModal);
