/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import GlobalStyle from '~/styles/global';
import VersionControl from '~/easy-components/VersionControl';
import GoogleApi from '~/easy-components/GoogleApi';
import QAInformation from '~/easy-components/QAInformation';
import RenderMain from '~/easy-components/RenderMain';
import Loading from '~/components/Loading';
import AsyncTask from '~/components/AsyncTask';
import useSocket from './hooks/useSocket';
// eslint-disable-next-line import/no-named-as-default
import Routes from './routes';
import { store, persistor } from './store';
import InactivityBlockApplication from './components/InactivityBlockApplication';
import CompanyProvider from './CompanyProvider';

function App() {
  // TODO: Criar o método de tradução (t)
  const socket = useSocket(process.env.REACT_APP_SERVER_URL_SOCKET);

  const t = text => text;

  useEffect(() => {
    if (socket) {
      // eslint-disable-next-line no-unused-vars
      socket.on('message', data => {
        // Faça algo com os dados recebidos do servidor de socket
      });

      // Exemplo de envio de mensagem para o servidor de socket
      socket.emit('sendMessage', 'Hello from client!');
    }
  }, [socket]);

  return (
    <>
      <RenderMain>
        <Suspense fallback={<h1 />}>
          <VersionControl>
            <Provider store={store}>
              <PersistGate persistor={persistor}>
                <GoogleApi t={t}>
                  <CompanyProvider>
                    <GlobalStyle />
                    <AsyncTask />
                    <InactivityBlockApplication>
                      <Routes />
                    </InactivityBlockApplication>
                    <Loading />
                  </CompanyProvider>
                </GoogleApi>
              </PersistGate>
            </Provider>
          </VersionControl>
        </Suspense>
      </RenderMain>
      <QAInformation />
    </>
  );
}

export default App;
