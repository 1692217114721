import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLocale from '~/hooks/useLocale';

import { logoffSuccess } from '~/store/modules/authentication/actions';
import { setInactivityStatus } from '~/store/modules/inactivity/actions';
import history from '~/routes/history';
import AuthenticationService from '~/services/AuthenticationService';

import Loading from '~/easy-components/Loading';
import Icon from '~/easy-components/Icon';
import Form from '~/easy-components/Form';
import Input from '~/components/Forms/Input';
import TreatError from '~/easy-components/TreatError';
import { Container, Content, Title, Message } from './styles';

const DisconnectedPage = () => {
  const t = useLocale('_Global');
  const dispatch = useDispatch();
  const authToken = useSelector(({ authorizationToken }) => authorizationToken);

  const [isLoading, setIsLoading] = useState(false);

  const handleExit = async () => {
    await AuthenticationService.logoff();
    dispatch(logoffSuccess());
    history.push('/login');
  };

  const handleEnter = async values => {
    const { password } = values;

    try {
      setIsLoading(true);

      await AuthenticationService.inactivityValidatePassword({
        token: authToken,
        password,
      });

      dispatch(setInactivityStatus(false));
    } catch (error) {
      TreatError.showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Content>
        <Loading isLoading={isLoading} />
        <Icon name="TbLock" size={70} color="#ff9999" />
        <Title>{t('Disconnected')}</Title>
        <Message>{t('TypePassword')}</Message>
        <Form onSubmit={handleEnter}>
          <Input
            name="password"
            placeholder={t('Password')}
            type="password"
            required
          />
          <button type="submit">{t('Reconnect')}</button>
          <button type="button" onClick={handleExit}>
            {t('Logout')}
          </button>
        </Form>
      </Content>
    </Container>
  );
};

export default DisconnectedPage;
