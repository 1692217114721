import styled from 'styled-components';

export const ModalAbsolute = styled.div`
  background-color: rgba(0, 0, 0, 0.6);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden !important;
`;

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
    position: fixed;
  }
`;

export const Main = styled.div`
  display: flex;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  background-color: #f0f0f0;

  @media (max-width: 500px) {
    padding: 0px;
  }
`;

export const HideHeader = styled.div`
  height: 2px;
  background-color: #e8e8e8;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 999;

  > button {
    width: 30px;
    height: 30px;
    background-color: #5d7a89;
    border-radius: 50%;
    border: 2px solid #e8e8e8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;
  }

  @media (max-width: 500px) {
    display: flex;
  }
`;

export const ShowAttachmentButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  right: 20px;
  cursor: pointer;

  svg {
    cursor: pointer;
  }
`;
