/* eslint-disable no-restricted-syntax */
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import QueryService from '~/services/QueryService';
import TreatError from '~/easy-components/TreatError';
import Loader from '../Loader';
import Error from '../Error';
import { Container, Content, Trapezio, Information } from './styles';

function Funil(
  { data, widgetSettings, params, getColor, errorRender, executeEvent },
  ref
) {
  const divRef = useRef(null);
  const updateTimeRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState(widgetSettings);

  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });

  /*
  Alterado a maneiro que clica no menu que era o onclick agora é o onMouseDown, 
  ver se funcionara para o menu mobile
  */

  const items = settings.value || [];

  const getColors = ({ item, idx }) => {
    let { color, backgroundColor } = item;

    if (!color) {
      if (settings.colors) {
        color = settings.colors[idx % settings.colors.length];
      } else {
        color = getColor(idx);
      }
    }

    if (!backgroundColor) {
      if (settings.backgroundColors) {
        backgroundColor =
          settings.backgroundColors[idx % settings.backgroundColors.length];
      } else {
        backgroundColor = getColor(idx);
      }
    }

    return {
      color,
      backgroundColor,
    };
  };

  const run = useCallback(async () => {
    try {
      setIsLoading(true);

      setError(null);

      const response = await QueryService.execute(
        1,
        widgetSettings.query,
        params
      );

      const newSettings = await executeEvent({
        eventName: 'onGetSettings',
        data: { widgetSettings, data, params, value: response },
      });

      setSettings({
        ...widgetSettings,
        value: response,
        ...newSettings,
      });

      if (widgetSettings.updateTimeMinutes) {
        updateTimeRef.current = setTimeout(async () => {
          await run();
        }, widgetSettings.updateTimeMinutes * 1000 * 60);
      }
    } catch (e) {
      const errorDescription = TreatError.getDescription(e);
      setError(errorDescription);
    } finally {
      setIsLoading(false);
    }
  }, [data, executeEvent, params, widgetSettings]);

  useEffect(() => {
    run();

    return () => {
      clearTimeout(updateTimeRef.current);
    };
  }, [run, data]);

  useEffect(() => {
    setError(errorRender);
  }, [errorRender]);

  const onClick = async ({ item }) => {
    await executeEvent({
      eventName: 'onClick',
      data: item,
    });
  };

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        setDimension({
          width: Math.round(entry.contentRect.width) - 20,
          height: Math.round(entry.contentRect.height) - 20,
        });
      }
    });

    if (divRef.current) {
      observer.observe(divRef.current);
      setDimension({
        width: divRef.current.offsetWidth - 20,
        height: divRef.current.offsetHeight - 20,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getData: () => {
        return settings;
      },
    };
  });

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <Container ref={divRef}>
      {isLoading ? (
        <Loader />
      ) : (
        items.map((item, idx) => {
          const discount = dimension.height / (items.length + 2);
          const elementWidth = dimension.width - idx * discount;
          // eslint-disable-next-line radix
          const elementHeight = parseInt(dimension.height / items.length);

          const customColor = getColors({ item, idx });

          return (
            <Content>
              <Trapezio
                width={elementWidth}
                height={elementHeight}
                discount={discount}
                color={customColor.color}
                backgroundColor={customColor.backgroundColor}
                onMouseDown={async e => {
                  e.stopPropagation();
                  e.preventDefault();

                  onClick({ item });
                }}
              >
                <Information>
                  <h1>{item.label}</h1>
                  <h2>{item.value}</h2>
                </Information>
              </Trapezio>
            </Content>
          );
        })
      )}
    </Container>
  );
}

export default forwardRef(Funil);
