/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, { useMemo, useEffect, memo } from 'react';
import List from '~/easy-components/Form/List';
import CardTemplate from '~/easy-components/CardTemplate';
import ConfirmDialog from '~/easy-components/ConfirmDialog';
import useLocale from '~/hooks/useLocale';
import {
  createAsyncFunctionByString,
  createSyncFunctionByString,
} from '~/easy-components/AsyncFunctionString';
import CustomTab from '../CustomTab';

function ListTab({
  title,
  name,
  isReadOnly,
  settings,
  listTemplate,
  isListTemplateFunction = false,
  formRef,
  columns,
  auxScope: externalScope,
  isDraggable = false,
}) {
  const t = useLocale('_Global');

  const { components } = settings || {};

  let selfField = null;

  if (components) {
    selfField = components.find(
      component =>
        component.type === 'tabList' &&
        component.fieldName.toUpperCase() === name.toUpperCase()
    );
  }

  function onDelete({ item, idx }) {
    ConfirmDialog({
      title: t('Delete'),
      message: t('message.DeleteMessageQuestion'),
      buttons: [
        {
          label: t('Yes'),
          onClick: async () => {
            let formData = formRef.current.getData();

            await sendEvent({
              eventName: 'onDelete',
              data: item,
              auxData: { idx, form: formRef.current, formData },
            });

            const newList = formData[name].filter((c, index) => index !== idx);

            formRef.current.setFieldValue(name, JSON.stringify(newList));

            formData = formRef.current.getData();

            await sendEvent({
              eventName: 'onDelete',
              data: item,
              run: 'after',
              auxData: { idx, form: formRef.current, formData },
            });
          },
        },
        {
          label: t('No'),
        },
      ],
    });
  }

  function onGetDefault() {
    if (selfField) {
      return selfField.defaultData;
    }

    return null;
  }

  const defaultData = onGetDefault();

  async function sendEvent({ eventName, run = 'before', data, auxData }) {
    if (selfField.events) {
      const event = selfField.events.find(
        evt =>
          evt.name.toUpperCase() === eventName.toUpperCase() && evt.run === run
      );

      if (event) {
        const dynamicFunction = createAsyncFunctionByString({
          functionString: event.handler,
        });
        await dynamicFunction({
          ...settings.dynamicFunctionProps,
          ...auxData,
          data,
        });
      }
    }
  }

  async function onShowContextMenu(menus, { data, idx }) {
    await sendEvent({
      eventName: 'onShowContextMenu',
      run: 'before',
      data: {
        menus,
        item: data,
        idx,
        form: formRef.current,
        formData: formRef.current.getData(),
      },
    });
  }

  const customMenus = useMemo(() => {
    return selfField.contextMenu || [];
  }, [selfField.contextMenu]);

  function formatTemplate({ data, value, propName }) {
    if (selfField && selfField.listItemTemplateFormat) {
      const dynamicFunction = createSyncFunctionByString({
        functionString: selfField.listItemTemplateFormat,
      });

      return dynamicFunction({
        data,
        value,
        propName,
        ...settings.dynamicFunctionProps,
      });
    }
    return value;
  }

  async function onBeforeAddItem(newData) {
    const response = await sendEvent({
      eventName: 'onConfirm',
      run: 'before',
      data: newData,
      auxData: {
        form: formRef.current,
        formData: formRef.current.getData(),
      },
    });

    return response || newData;
  }

  async function onAfterAddItem(newData) {
    await sendEvent({
      eventName: 'onConfirm',
      run: 'after',
      data: newData,
      auxData: {
        form: formRef.current,
        formData: formRef.current.getData(),
      },
    });
  }

  function addFieldWithEventsToSettings() {
    if (!selfField.events || selfField.events.length === 0) return;

    const field = {
      name: selfField.fieldName,
      events: selfField.events,
    };

    settings.fields.push(field);
  }

  function mergeFieldAndComponentEvents(fieldIndex) {
    if (
      !selfField.events ||
      selfField.events.length === 0 ||
      !settings.fields[fieldIndex].events
    )
      return;

    selfField.events.forEach(event => {
      settings.fields[fieldIndex].events.push(event);
    });
  }

  function mergeOrAddFieldWithEvents() {
    let hasFieldSameNameAsComponentIndex = null;

    if (settings.fields) {
      hasFieldSameNameAsComponentIndex = settings.fields.findIndex(
        field => field.name === selfField.fieldName
      );
    }

    if (hasFieldSameNameAsComponentIndex !== -1) {
      mergeFieldAndComponentEvents(hasFieldSameNameAsComponentIndex);
    } else {
      addFieldWithEventsToSettings();
    }
  }

  useEffect(() => {
    mergeOrAddFieldWithEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List
      name={name}
      editPageTitle={title}
      settings={settings}
      readOnly={isReadOnly}
      showAddButton={selfField.showAddButton !== false}
      defaultData={defaultData}
      renderItem={(item, idx) => {
        const auxScope = {
          ...settings.dynamicFunctionProps,
          data: item,
          form: formRef.current,
          formData: formRef.current ? formRef.current.getData() : null,
          idx,
          ...externalScope,
        };

        let template = listTemplate;

        if (isListTemplateFunction) {
          const dynamicFunction = createSyncFunctionByString({
            functionString: listTemplate,
          });

          template = dynamicFunction(auxScope);
        }

        return (
          <CardTemplate
            key={idx}
            template={template}
            data={item}
            style={{ backgroundColor: '#fff', ...selfField.style }}
            readOnly={isReadOnly}
            idx={idx}
            settings={settings}
            customMenus={customMenus}
            onShowContextMenu={onShowContextMenu}
            isShowMenus
            auxScope={auxScope}
            format={formatTemplate}
            onClickDelete={() => {
              onDelete({ item, idx });
            }}
          />
        );
      }}
      formRef={formRef}
      editPage={({ pageRef }) => {
        return (
          <CustomTab
            baseName={name}
            columns={columns}
            mainFormRef={formRef}
            formRef={pageRef}
            settings={settings}
            isReadOnly={isReadOnly}
          />
        );
      }}
      onBeforeAddItem={onBeforeAddItem}
      onAfterAddItem={onAfterAddItem}
      // eslint-disable-next-line no-unused-vars
      footer={({ pageRef }) => {
        return null;
      }}
      isDraggable={isDraggable}
    />
  );
}

export default memo(ListTab);
