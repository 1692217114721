/* eslint-disable react/prop-types */
import React, { useImperativeHandle, forwardRef } from 'react';
import CustomHtml from '~/easy-components/CustomHtml';
import { Container } from './styles';

function ShowContent(
  { htmlContent, dataToReplace, iconsContent, onConfirm },
  ref
) {
  useImperativeHandle(ref, () => ({
    onConfirm: () => onConfirm({ message: '' }),
  }));

  return (
    <Container>
      <CustomHtml
        htmlText={htmlContent}
        htmlData={dataToReplace}
        iconsContent={iconsContent}
      />
    </Container>
  );
}

export default forwardRef(ShowContent);
