/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import { createSyncFunctionByString } from '~/easy-components/AsyncFunctionString';
import { UploadMessage } from './styles';
import useLocale from '~/hooks/useLocale';

function Upload({
  message,
  accept,
  onUpload,
  isReadOnly,
  isSetFileName,
  onValidateFile,
  settings,
  name,
  auxScope,
}) {
  const t = useLocale('_Global');

  const [displayText, setDisplayText] = useState('');

  const { fields } = settings || {};

  const selfField =
    (fields
      ? fields.find(f => {
          return f.name === name;
        })
      : {}) || {};

  function renderDragMessage(isDragActive, isDragReject, fileRejections) {
    if (fileRejections.length) {
      const [{ file = {}, errors = [] }] = fileRejections;
      const [{ message: errorMessage }] = errors;

      return (
        <UploadMessage type="error">{`${file.path}: ${errorMessage}`}</UploadMessage>
      );
    }

    if (!isDragActive) return <UploadMessage>{displayText}</UploadMessage>;

    if (isDragReject)
      return (
        <UploadMessage type="error">
          {t('message.UnsupportedFile')}
        </UploadMessage>
      );

    return (
      <UploadMessage type="success">
        {t('message.DropTheFilesHere')}
      </UploadMessage>
    );
  }

  useEffect(() => {
    setDisplayText(message);
  }, [message]);

  const sendEvent = ({ eventName, run = 'before', data }) => {
    const event = selfField.events
      ? selfField.events.find(
          evt => evt.name === eventName && (evt.run || 'before') === run
        )
      : null;

    if (event) {
      const dynamicFunction = createSyncFunctionByString({
        functionString: event.handler,
      });

      return dynamicFunction({ data, ...auxScope });
    }

    return null;
  };

  return (
    <section>
      {!isReadOnly && (
        <Dropzone
          accept={accept === '*' ? undefined : accept}
          onDropAccepted={files => {
            const newFiles = files.map(file => {
              const dotIndex = file.name.lastIndexOf('.');

              const fileName = file.name.substring(0, dotIndex);
              const fileExtension = file.name.substring(dotIndex + 1);

              /* const fileNewName = onRename
              ? onRename({ file, fileName, fileExtension })
              : file.name; */

              const fileNewName =
                sendEvent({
                  eventName: 'onRename',
                  run: 'before',
                  data: { file, fileName, fileExtension, ...auxScope },
                }) || file.name;

              return new File([file], fileNewName, {
                type: file.type,
                lastModified: file.lastModified,
              });
            });

            if (isSetFileName) {
              const file = newFiles[0];
              setDisplayText(file.name);
            }

            onUpload(newFiles);

            /* if (isSetFileName) {
              const file = files[0];
              setDisplayText(file.name);
            }
            onUpload(files); */
          }}
          maxFiles={1}
          validator={onValidateFile}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject,
            fileRejections,
          }) => (
            <div {...getRootProps()}>
              <input id="uploadReceiptFile" {...getInputProps()} />
              {renderDragMessage(isDragActive, isDragReject, fileRejections)}
            </div>
          )}
        </Dropzone>
      )}
    </section>
  );
}

Upload.propTypes = {
  message: PropTypes.string,
  accept: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isSetFileName: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  file: PropTypes.shape({
    fileName: PropTypes.string,
    file: PropTypes.shape(),
  }),
  onValidateFile: PropTypes.func,
};

Upload.defaultProps = {
  message: 'Selecione o arquivo',
  accept: null,
  isReadOnly: false,
  file: {
    fileName: null,
    file: null,
  },
  isSetFileName: true,
  onValidateFile: () => null,
  // accept: 'image/*',
};

export default Upload;
