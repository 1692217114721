/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';
import { updateFilterRowValue } from 'ka-table/actionCreators';
import DebounceEvent from '~/easy-components/DebounceEvent';

function TextFilter({ column, dispatch, disabled = false, value }) {
  const inputRef = useRef();
  function onChange(event) {
    const targetValue = event.target.value;
    dispatch(updateFilterRowValue(column.key, targetValue));
  }

  useEffect(() => {
    inputRef.current.value = value || '';
  }, [value]);

  return (
    <input
      ref={inputRef}
      disabled={disabled}
      onChange={DebounceEvent(onChange, 1000)}
    />
  );
}

export default TextFilter;
