import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Sidebar from 'react-sidebar';
import { isMobile } from 'react-device-detect';
import { setRoute } from '~/store/modules/mainMenu/actions';
import { Container, Main, PageBody, Content } from './styles';

function Page({
  isModal,
  sidePage,
  showSide,
  children,
  isSidePageOpen,
  isExecutionPage,
  sideWidth,
  maxWidth,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    let pageRoute = (
      window.location.pathname.substring(1) + window.location.search
    ).toUpperCase();

    if (pageRoute.indexOf('EXECUTEREPORT') === 0) {
      const urlSplit = pageRoute.split('/');
      pageRoute = `${urlSplit[0]}/${urlSplit[1]}`;
    }

    dispatch(setRoute(pageRoute));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Container>
      <Main>
        <PageBody>
          {!isModal && sidePage && !isExecutionPage ? (
            <Sidebar
              shadow={false}
              sidebar={sidePage}
              open={isSidePageOpen}
              onSetOpen={showSide}
              defaultSidebarWidth={380}
              docked={isSidePageOpen && !isMobile}
              styles={{
                content: {
                  display: 'flex',
                },
                sidebar: {
                  width: isMobile ? '100%' : sideWidth,
                  background: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
            >
              <Content>{children}</Content>
            </Sidebar>
          ) : (
            <Content maxWidth={maxWidth}>{children}</Content>
          )}
        </PageBody>
      </Main>
    </Container>
  );
}

Page.propTypes = {
  sidePage: PropTypes.element,
  children: PropTypes.node.isRequired,
  isSidePageOpen: PropTypes.bool,
  isExecutionPage: PropTypes.bool,
  maxWidth: PropTypes.string,
  showSide: PropTypes.any,
  sideWidth: PropTypes.number,
};

Page.defaultProps = {
  isSidePageOpen: true,
  isExecutionPage: false,
  sidePage: null,
  sideWidth: 350,
  maxWidth: null,
  showSide: () => {},
};

export default Page;
