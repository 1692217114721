/* eslint-disable react/prop-types */
import React from 'react';

// eslint-disable-next-line import/no-cycle
import Button from '~/easy-components/Button';
import { Container, Content, ReportInfo, Footer } from './styles';

function LayoutReportsViewer({ onConfirm, reports }) {
  const reportDefault = reports.find(
    report => report.isDefault || report.default
  );

  return (
    <Container>
      <Content>
        {reports.map(report => {
          return (
            <ReportInfo
              key={report.reportCode}
              onClick={() => onConfirm({ report })}
              isDefault={
                reportDefault && reportDefault.reportCode === report.reportCode
              }
            >
              <h1>{report.reportName}</h1>
              <h2>{report.description}</h2>
            </ReportInfo>
          );
        })}
      </Content>
      <Footer>
        <Button
          buttonType="Emphasized"
          type="button"
          onClick={() => onConfirm({ report: null })}
        >
          Fechar
        </Button>
      </Footer>
    </Container>
  );
}

export default LayoutReportsViewer;
