import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const Number = styled.div`
  padding: 10px;
  position: fixed;
  bottom: 30px;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    color: ${props => props.color};
  }
`;

export const Value = styled.div`
  font-size: 4rem;
  margin-left: 10px;
`;

export const Aux = styled.div`
  font-size: 1rem;
  color: #aaa;
  position: fixed;
  bottom: 25px;
  right: 20px;
`;
