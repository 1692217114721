import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  background-color: #fff;
  flex-direction: column;
  display: flex;
  overflow: auto;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #f0f0f0;
`;

export const Element = styled.div`
  padding: 10px 20px 20px 20px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
`;

export const Header = styled.div`
  height: 44px;
  background-color: #ddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #333;
  padding: 0 20px;
`;

export const Footer = styled.div`
  height: 44px;
  background-color: #ddd;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
