import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Content = styled.div`
  flex: 1;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;

  > h1 {
    height: 45px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #777;
  }

  > div {
    display: flex;
    flex-direction: row;

    > div {
      margin-left: 10px;
    }
  }
`;

export const Information = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;

  h1 {
    font-weight: 500;
    font-size: 1.1rem;
    color: #555;
  }

  h2 {
    color: #999;
    font-weight: 400;
  }
`;

export const User = styled.div`
  // eslint-disable-next-line eqeqeq
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row;

  &:hover {
    cursor: pointer;
    background-color: #deeff6;
  }

  svg {
    margin-left: 20px;
  }
`;

export const Image = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

export const AuxFilter = styled.div`
  margin: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const SearchPanel = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;

  @media (max-width: 500px) {
    height: 50px;
  }
`;
