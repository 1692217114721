/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import TreatError from '~/easy-components/TreatError';
import { createSyncFunctionByString } from '~/easy-components/AsyncFunctionString';
import Icon from '../Icon';

window.React = React;

const TemplateJSX = ({
  jsxString,
  auxScope,
  onClick = () => {},
  onContextMenu,
  ...rest
}) => {
  const fullFunctionString = `
    function DynamicComponent(scope) {
      ${jsxString}
    }
  `;
  let transpiledCode = 'function DynamicComponent() { return null; }';

  if (window.Babel) {
    try {
      const output = window.Babel.transform(fullFunctionString, {
        presets: ['react'],
      }).code;
      transpiledCode = output;
    } catch (error) {
      TreatError.showError(
        `Error transpiling JSX in TemplateJSX. Full Description logged in console.`
      );
      // eslint-disable-next-line no-console
      console.error('Error transpiling JSX:', error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(
      'Babel is not defined. Make sure Babel Standalone is loaded.'
    );
  }

  const functionByTranspiledCode = ({ functionString, dynamicProps }) => {
    if (!functionString) return null;

    const dynamicFunction = createSyncFunctionByString({
      functionString,
    });

    const response = dynamicFunction(dynamicProps);

    return response;
  };

  const renderTranspiledCode = functionByTranspiledCode({
    functionString: `
      ${transpiledCode}

        return DynamicComponent(scope);`,
    dynamicProps: { ...auxScope, Icon },
  });

  return (
    <div
      style={{ width: '100%' }}
      onClick={onClick}
      onContextMenu={e => {
        if (onContextMenu) {
          onContextMenu(e);
        }
      }}
      {...rest}
    >
      {renderTranspiledCode}
    </div>
  );
};

export default TemplateJSX;
