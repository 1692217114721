import styled from 'styled-components';
import { darken } from 'polished';

export const ContainerModal = styled.div`
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  width: 600px;

  @media (max-width: 500px) {
    width: 100vw;
    height: 100vh;
  }
`;

export const Container = styled.div`
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 900px;

  @media (max-width: 500px) {
    width: 100vw;
    height: 100vh;
  }

  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px 5px 10px;

    > div {
      display: flex;
      justify-content: right;

      button {
        border: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  background-color: #f0f0f0;
`;

export const Title = styled.div`
  height: 44px;
  background-color: #496774;
  color: #fff;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-weight: 600;
`;

export const ItemsContent = styled.div`
  flex-direction: column;
  display: flex;
  overflow: auto;
  padding: 5px 10px 5px 10px;
  max-height: 300px;

  table {
    border: 1px solid #ccc;
    position: relative;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  & thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  & tbody {
    overflow: auto;
  }

  & tr {
    table-layout: fixed;
    border: 1px solid #ddd;
    padding: 0 0.35em;
  }
`;

export const ItemLine = styled.tr`
  background-color: ${props => (props.selected ? '#deeff6' : props.lineColor)};
  transition: 0.2s;

  &:hover {
    background-color: ${darken(0.01, '#deeff6')};
    cursor: pointer;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  flex: 1;
  background-color: #f0f0f0;
`;
