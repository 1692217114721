export const showLog = status => {
  return {
    type: 'LOG_SHOW',
    payload: {
      status,
    },
  };
};

export const sendData = data => {
  return {
    type: 'LOG_SEND_DATA',
    payload: {
      type: 1,
      data,
    },
  };
};
