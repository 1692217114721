import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 900px;

  @media (max-width: 500px) {
    width: 100vw;
    height: 100vh;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: auto;
  background-color: #f0f0f0;

  h1 {
    background-color: #496774;
    color: #fff;
    font-weight: 500;

    text-align: center;
    padding: 10px;
    width: 100%;
  }

  span {
    font-size: 1.2em;
    padding: 10px;
    margin: 10px;
  }
`;

export const ItemsContent = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  overflow: auto;

  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  & tr {
    border: 1px solid #ddd;
    padding: 0 0.35em;
  }

  & tbody {
    overflow: auto;
  }
`;

export const ItemLine = styled.tr`
  background-color: ${props => (props.selected ? '#deeff6' : props.lineColor)};
  transition: 0.2s;

  &:hover {
    background-color: ${darken(0.01, '#deeff6')};
    cursor: pointer;
  }
`;
