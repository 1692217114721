/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useCallback } from 'react';
import { AiOutlineEyeInvisible } from '@react-icons/all-files/ai/AiOutlineEyeInvisible';
import { AiOutlineEye } from '@react-icons/all-files/ai/AiOutlineEye';
import { FiSave as SaveIcon } from '@react-icons/all-files/fi/FiSave';
import { FaRegCopy } from '@react-icons/all-files/fa/FaRegCopy';
import ReactTooltip from 'react-tooltip';
import ColumnLayout from '~/easy-components/ColumnLayout';
import ModalHandle from '~/easy-components/ModalHandle';
import Form from '~/easy-components/Form';
import Button from '~/easy-components/Button';
import Panel from '~/easy-components/Panel';
import Toast from '~/easy-components/Toast';
import PageHeader from '~/easy-components/PageHeader';
import TreatError from '~/easy-components/TreatError';
import Input from '~/easy-components/Form/Input';
import ModalFooter from '~/easy-components/ModalFooter';
import JsonTable from '~/easy-components/JsonTable';
import colors from '~/styles/colors';
import ApplicationGroupService from '~/services/ApplicationGroupService';
import CompanyQueryService from '~/services/CompanyQueryService';
import QueryService from '~/services/QueryService';
import MasterDetail from '~/components/Pages/MasterDetail';
import CodeEditor from '~/easy-components/CodeEditor';
import useLocale from '~/hooks/useLocale';
import useCompany from '~/hooks/useCompany';
import useStateCache from '~/hooks/useStateCache';
import useProjectLinker from '~/easy-components/Helpers/linkProject';
import useHelpers from '~/hooks/useHelpers';
import { QueriesPanel, Footer, ModalBody } from './styles';
import Query from './Query';
import Upload from './components/UploadZip/index';

export default function Queries({ settings, ...props }) {
  const { dynamicFunctionProps } = useHelpers();
  const { showLoading, showPage, showModalFields } = dynamicFunctionProps;

  const t = useLocale('SystemQueries', settings.translate);
  const tc = useLocale('Company_Queries');
  const tg = useLocale('_Global');

  const formRef = useRef();
  const masterDetailRef = useRef();
  const uploadModalRef = useRef();
  const uploadResultModalRef = useRef();
  const [contentUploaded, setContentUploaded] = useState({
    data: [],
    columns: [],
    reportSettings: {},
    rowKeyField: null,
  });
  const customCommandRef = useRef();
  const queryConnectionTypeRef = useRef();
  const [isDefaultVisible, setIsDefaultVisible] = useState(true);
  const [defaultSettings, setDefaultSettings] = useState('');
  const [customSettings, setCustomSettings] = useState('');
  const [customSettingsId, setCustomSettingsId] = useState(null);
  const [queryConnectionId, setQueryConnectionId] = useState(null);
  const [queryInfo, setQueryInfo, getQueryInfo] = useStateCache({
    name: null,
    code: null,
  });
  const [menus, setMenus] = useState([]);

  const { linkProject } = useProjectLinker({
    type: 'query',
    showModalFields,
    showLoading,
    keyCode: '',
  });

  const { companySettings } = useCompany();

  const { connectionType } = companySettings;
  const fileInfo = useRef({
    fileName: null,
    file: null,
  });

  const fieldProps = {
    formRef,
    settings,
  };

  const clearFileInfo = useCallback(() => {
    fileInfo.current = {
      fileName: null,
      file: null,
    };
  }, []);

  const splitFileName = useCallback(fileName => {
    const idx = fileName.lastIndexOf('.');

    const name = fileName.substring(0, idx);

    const extension = fileName.substring(idx + 1);

    return {
      name,
      extension,
    };
  }, []);

  async function exportCustomQueries() {
    await CompanyQueryService.downloadQueries();
  }

  function handleUploadClick() {
    uploadModalRef.current.handleOpen();
  }

  async function onSearch({ filter, auxFilters }) {
    const response = await ApplicationGroupService.getQueries({
      filter,
      auxFilters,
    });
    return response;
  }

  async function onSelectQuery({ selectedItem }) {
    const response = await QueryService.getCommand(
      selectedItem.id,
      connectionType
    );

    customCommandRef.current.showConsole(false);

    return {
      ...response,
      queryName: selectedItem.name,
      queryCode: selectedItem.code,
      id: response.queryConnectionType.queryId,
    };
  }

  function onGetDataToSave() {
    const info = getQueryInfo();
    return {
      id: queryConnectionTypeRef.current.queryId,
      queryCode: info.queryCode,
      queryName: info.queryName,
      customCommand: {
        command: customCommandRef.current
          ? customCommandRef.current.getValue()
          : '',
        id: queryConnectionTypeRef.current.customSettingsId,
      },
      queryConnectionType: queryConnectionTypeRef.current.queryConnectionType,
    };
  }

  function onLoadData({ data }) {
    const { customCommand, queryConnectionType } = data;

    const customId = customCommand ? customCommand.id : null;

    queryConnectionTypeRef.current = {
      queryConnectionType,
      customSettingsId: customId,
      queryId: data.id,
    };

    if (queryConnectionType && queryConnectionType.id) {
      setIsDefaultVisible(customCommand ? customCommand.command === '' : true);
    } else {
      setIsDefaultVisible(false);
    }
    setQueryConnectionId(queryConnectionType ? queryConnectionType.id : null);
    setCustomSettings(customCommand ? customCommand.command || '' : '');
    setCustomSettingsId(customId);
    setDefaultSettings(queryConnectionType ? queryConnectionType.command : ``);
    setQueryInfo(data);

    customCommandRef.current.input.focus();

    setMenus([
      {
        id: 'mnuExportTemplate',
        icon: 'CiImport',
        text: tc('mnuExportCustomQueries'),
        handler: exportCustomQueries,
      },
      {
        id: 'mnuImportTemplate',
        icon: 'CiExport',
        text: tc('mnuImportTemplate'),
        handler: handleUploadClick,
      },
      {
        id: 'mnuLinkToProject',
        icon: 'FaFileImport',
        disabled: !data.id,
        text: tc('mnuLinkToProject'),
        handler: () => {
          linkProject(data.queryCode);
        },
      },
    ]);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLoadExternalData = async ({ data }) => {
    try {
      const query = await QueryService.getByCode(data);
      await masterDetailRef.current.executeSelectionItem(query);
      setTimeout(async () => {
        if (masterDetailRef.current) {
          await masterDetailRef.current.refreshList(query.code);
        }
      }, 200);
    } catch (error) {
      TreatError.showError(error);
    }
  };

  async function onSave() {
    try {
      showLoading(true);

      if (!queryConnectionId) throw new Error(tg('message.NoRegisterFound'));

      const response = await CompanyQueryService.createOrUpdate({
        queryConnectionId,
        id: customSettingsId,
        command: customSettings,
      });

      const newOnLoadData = {
        customCommand: response,
        id: queryConnectionTypeRef.current.queryId,
        queryConnectionType: queryConnectionTypeRef.current.queryConnectionType,
        queryCode: response.queryCode,
        queryName: response.queryName,
      };

      onLoadData({ data: newOnLoadData });

      return newOnLoadData;
    } catch (error) {
      TreatError.showError(error);
      return null;
    } finally {
      showLoading(false);
    }
  }

  function handleClose(modalRef) {
    modalRef.current.handleClose();
  }

  function isUploadModalToOpen(customQueries) {
    for (let index = 0; index < customQueries.length; index++) {
      const customQueryStatus = customQueries[index].statusCode;

      if (customQueryStatus !== 'S') {
        return true;
      }
      index++;
    }
    return false;
  }

  function handleUploadModalOpen(customQueries) {
    const openUploadModal = isUploadModalToOpen(customQueries);
    if (openUploadModal) uploadResultModalRef.current.handleOpen();
  }

  async function onUpload() {
    try {
      showLoading(true);

      const response = await CompanyQueryService.attachFile({
        fileName: fileInfo.current.fileName,
        file: fileInfo.current.file,
      });
      clearFileInfo();
      handleClose(uploadModalRef);

      Toast.success(t('message.SystemMessageSuccess'));

      setContentUploaded(response);

      const customQueries = response.data;

      handleUploadModalOpen(customQueries);

      masterDetailRef.current.refreshList();
    } catch (err) {
      Toast.error(err);
    } finally {
      showLoading(false);
    }
  }

  /* useEffect(() => {
    if (initialData) {
      onLoadExternalData(initialData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]); */

  return (
    <MasterDetail
      enableDesignMode={false}
      ref={masterDetailRef}
      formRef={formRef}
      menus={menus}
      formData={queryInfo}
      auxTitle2={queryInfo.queryName}
      subTitleProp="queryName"
      showLoading={showLoading}
      route="SystemQueries"
      keyField="id"
      settings={{
        auxFilters: [
          {
            name: 'customQuery',
            title: t('SystemQueries'),
            labelWidth: 200,
            type: 'fixedData',
            data: [
              {
                value: 2,
                label: t('All'),
              },
              {
                value: 1,
                label: t('Yes'),
              },
              {
                value: 0,
                label: t('No'),
              },
            ],
          },
        ],
      }}
      sideWidth={380}
      onSelectedItem={onSelectQuery}
      onRenderListItem={query => <Query key={query.id} data={query} />}
      onSearch={onSearch}
      onGetDataToSave={onGetDataToSave}
      onLoadData={onLoadData}
      onLoadExternalData={onLoadExternalData}
      onSave={onSave}
      isShowSave={
        queryConnectionTypeRef.current &&
        !!queryConnectionTypeRef.current.queryId
      }
      isShowDelete={false}
      isFlexSpace={false}
      footerElements={() => {
        return (
          <Footer>
            <></>
          </Footer>
        );
      }}
      isShowSystemLogs
      systemLogsRef="CompanyQuery"
      systemLogskey="queryCode"
      showPage={showPage}
      isShowLog={false}
      {...props}
    >
      <Form
        ref={formRef}
        /* defaultSettings={defaultSettings}
        customSettings={customSettings}
        isDefaultVisible={isDefaultVisible} */
      >
        <QueriesPanel>
          <div>
            <CodeEditor
              t={t}
              ref={customCommandRef}
              title={t('CustomSettings')}
              editorType="sql"
              value={customSettings || ''}
              onChange={value => {
                setCustomSettings(value);
              }}
              onCtrlS={async () => {
                await masterDetailRef.current.save({ hasQuestion: false });
              }}
              buttons={[
                <div
                  key="save"
                  data-tip={t('Save')}
                  data-for="toolbarTooltipQueries"
                  onClick={async () => {
                    await masterDetailRef.current.save();
                  }}
                >
                  <SaveIcon size={24} color={colors.mainMenuIcons} />
                </div>,
                <div
                  key="visible"
                  data-tip={t('DefaultSettings')}
                  data-for="toolbarTooltipQueries"
                  onClick={() => setIsDefaultVisible(!isDefaultVisible)}
                >
                  {isDefaultVisible ? (
                    <div
                      data-tip={t('HideDefaultSetting')}
                      data-for="toolbarTooltipQueries"
                    >
                      <AiOutlineEyeInvisible
                        size={24}
                        color={colors.mainMenuIcons}
                      />
                    </div>
                  ) : (
                    <div
                      data-tip={t('ShowDefaultSetting')}
                      data-for="toolbarTooltipQueries"
                    >
                      <AiOutlineEye size={24} color={colors.mainMenuIcons} />
                    </div>
                  )}
                </div>,
              ]}
            />
          </div>
          {isDefaultVisible && (
            <div>
              <CodeEditor
                title={t('DefaultSettings')}
                readOnly
                editorType="sql"
                value={defaultSettings || ''}
                onChange={value => {
                  setDefaultSettings(value);
                }}
                buttons={[
                  <div
                    data-tip={t('Copy')}
                    data-for="toolbarTooltipQueries"
                    key="default"
                    onClick={() => {
                      setCustomSettings(defaultSettings || '');
                      customCommandRef.current.input.focus();
                      setIsDefaultVisible(false);
                    }}
                  >
                    <FaRegCopy size={20} color={colors.mainMenuIcons} />
                  </div>,
                ]}
              />
              <ReactTooltip
                id="toolbarTooltipQueries"
                place="bottom"
                type="light"
                effect="solid"
                className="toolbarTooltip"
              />
            </div>
          )}
        </QueriesPanel>

        <ReactTooltip
          id="toolbarTooltipQueries"
          place="bottom"
          type="light"
          effect="solid"
          className="toolbarTooltip"
        />

        <ModalHandle ref={uploadModalRef}>
          <PageHeader>{tc('UploadZipFile')}</PageHeader>
          <ModalBody>
            <ColumnLayout>
              <Panel width="400px">
                <Input
                  label={t('File')}
                  name="FileName"
                  {...fieldProps}
                  hidden
                />
                <Input
                  label={t('FileExtension')}
                  name="FileExt"
                  {...fieldProps}
                  hidden
                />
                <Upload
                  message={t('message.SelectFile')}
                  accept=".zip"
                  onUpload={files => {
                    const file = files[0];
                    fileInfo.current = {
                      fileName: file.name,
                      file,
                    };

                    const fileInformation = splitFileName(file.name);

                    formRef.current.setFieldValue(
                      'FileName',
                      fileInformation.name
                    );
                    formRef.current.setFieldValue(
                      'FileExt',
                      fileInformation.extension
                    );
                  }}
                  isReadOnly={false}
                />
              </Panel>
            </ColumnLayout>
          </ModalBody>
          <ModalFooter>
            <Button
              buttonType="Emphasized"
              onClick={async () => {
                await onUpload();
              }}
            >
              {t('Confirm')}
            </Button>

            <Button
              onClick={() => {
                handleClose(uploadModalRef);
              }}
            >
              {t('Close')}
            </Button>
          </ModalFooter>
        </ModalHandle>

        <ModalHandle ref={uploadResultModalRef}>
          <PageHeader>{t('UploadResult')}</PageHeader>
          <ModalBody>
            <JsonTable
              title="Uploaded Files"
              showToolbar={false}
              t={t}
              columns={contentUploaded.columns || []}
              data={contentUploaded.data || []}
              reportSettings={contentUploaded.reportSettings || {}}
              groups={
                contentUploaded.reportSettings
                  ? contentUploaded.reportSettings.groups
                  : null
              }
              settings={settings}
              showLoading={showLoading}
              hideToolbar
            />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                handleClose(uploadResultModalRef);
              }}
            >
              {t('Close')}
            </Button>
          </ModalFooter>
        </ModalHandle>
      </Form>
    </MasterDetail>
  );
}
