import { memo } from 'react';

export default function CompareRender(
  element,
  propsToCompare = [],
  propsNotCompare = []
) {
  return memo(element, (prev, next) => {
    let isEqual = true;
    let fields = [];

    if (propsToCompare.length <= 0) {
      Object.keys(prev).forEach(prop => {
        fields.push(prop);
      });
    } else {
      fields = propsToCompare;
    }

    fields.forEach(prop => {
      let prevValue = prev[prop];
      let nextValue = next[prop];

      if (prop.includes('.')) {
        const props = prop.split('.');

        try {
          prevValue = props.reduce((p, curr) => p[curr], prev);
          nextValue = props.reduce((n, curr) => n[curr], next);
        } catch (err) {
          isEqual = false;
        }
      }

      const propNotCompare = propsNotCompare.find(p => p === prop);

      if (propNotCompare) return;

      switch (typeof prevValue) {
        case 'function':
          if (prevValue !== nextValue) {
            isEqual = false;
          }
          break;

        case 'object':
          if (prop !== 'children') {
            const jsonPrev = JSON.stringify(prevValue);
            const jsonNext = JSON.stringify(nextValue);
            if (jsonPrev !== jsonNext) {
              isEqual = false;
            }
          }
          break;

        default:
          if (prevValue !== nextValue) {
            isEqual = false;
          }
          break;
      }
    });

    return isEqual;
  });
}
