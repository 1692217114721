/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { createPortal } from 'react-dom';
import { FaSpinner } from '@react-icons/all-files/fa/FaSpinner';
import { Container } from './styles';
import CompareRender from '../Helpers/compareRender';

/**
 * @typedef {Object} Props
 * @prop {boolean} [isLoading]
 * @prop {React.ReactNode} [children]
 */

/**
 * @param {Props} props
 * @param {React.RefObject} ref
 */
function Loading({ isLoading = false, children }, ref) {
  const [isShowLoading, setIsShowLoading] = useState();

  const modalRoot = document.getElementById('loading');
  const el = document.createElement('div');

  useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el, modalRoot]);

  useEffect(() => {
    setIsShowLoading(isLoading);
  }, [isLoading]);

  useImperativeHandle(ref, () => {
    return {
      show: () => {
        setIsShowLoading(true);
      },
      hide: () => {
        setIsShowLoading(false);
      },
    };
  });

  return (
    <>
      {isShowLoading &&
        createPortal(
          <Container>
            <FaSpinner size={32} color="#496774" />
            {children}
          </Container>,
          el
        )}
    </>
  );
}

export default CompareRender(forwardRef(Loading), ['isLoading']);
