import request from '../../Request';

export default class AttachmentService {
  constructor(resource) {
    this.resource = resource;
  }

  async attachFile({ keyCode, file, fileName, isDraft }) {
    if (isDraft) {
      return request({
        method: 'POST',
        url: `APP/CRM/Attachments/Drafts/${keyCode}`,
        data: {
          fileName,
        },
        file,
      });
    }

    return request({
      method: 'POST',
      url: `APP/CRM/Attachments/${this.resource}/${keyCode}`,
      data: {
        fileName,
      },
      file,
    });
  }

  async unattachedFile({ keyCode, fileLine, isDraft }) {
    if (isDraft) {
      return request({
        method: 'DELETE',
        url: `APP/CRM/Attachments/Drafts/${keyCode}/${fileLine}`,
      });
    }

    return request({
      method: 'DELETE',
      url: `APP/CRM/Attachments/${this.resource}/${keyCode}/${fileLine}`,
    });
  }
}
