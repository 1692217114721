/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { isEqual } from 'date-fns';
import { MdSend as SendIcon } from '@react-icons/all-files/md/MdSend';
import { v4 as uuidv4 } from 'uuid';

import DebounceEvent from '~/easy-components/DebounceEvent';
import TreatError from '~/easy-components/TreatError';
import MentionService from '~/services/MentionService';
import useLocale from '~/hooks/useLocale';
import useCompany from '~/hooks/useCompany';
import { Container, MessageText } from './styles';
import Messages from './Messages';
import Mentions from './Mentions';
import Disabled from './Disabled';
import { STATUS } from './Status';
import ActivityToolbar from './ActivityToolbar';
import PanelControls from './PanelControls';

function HastagMention({ objectCode, keyValue, keyText }) {
  const t = useLocale('Mentions');
  const { stringToDate } = useCompany();

  const panelControlRef = useRef();
  const messagePanelRef = useRef();
  const messageInputRef = useRef();
  const mentionIsShowRef = useRef(false);
  const mentionSearchRef = useRef();
  const mentionsInsideMessage = useRef([]);

  const [messages, setMessages] = useState([]);

  const [state, setState] = useState({
    mentionIsLoading: false,
    mentionIdxSelected: null,
    messageIsLoading: false,
    messagesError: null,
    mentions: [],
  });

  const onChangePanel = useCallback(
    ({ name }) => {
      switch (name) {
        case 'mentions':
          setState({
            ...state,
            mentionIdxSelected: 0,
            mentions: [],
          });
          break;

        default:
          break;
      }
    },
    [state]
  );

  const getWord = useCallback(input => {
    const startPoint = input.selectionEnd;

    let initialPoint = 0;
    let finalPoint = input.value.length;

    let letter = '';

    for (let cont = startPoint - 1; cont > 0; cont--) {
      letter = input.value.charAt(cont);
      if (letter === ' ') {
        initialPoint = cont + 1;
        cont = 0;
      }
    }

    for (let pos = startPoint; pos < input.value.length; pos++) {
      letter = input.value.charAt(pos);
      if (letter === ' ') {
        finalPoint = pos;
        pos = input.value.length;
      }
    }

    const word = input.value.substring(initialPoint, finalPoint).trim();
    return { word, initialPoint, finalPoint };
  }, []);

  const getOccurrenceCount = useCallback(text => {
    const occurrenceCount = (text.match(new RegExp('@', 'g')) || []).length;
    return occurrenceCount;
  }, []);

  const showMentionsPanel = useCallback(
    status => {
      if (status) {
        if (!mentionIsShowRef.current) {
          setState({
            ...state,
            mentions: [],
          });

          mentionIsShowRef.current = true;
          panelControlRef.current.setPanel('mentions');
        }
      } else if (mentionIsShowRef.current) {
        setState({
          ...state,
          mentions: [],
        });

        mentionSearchRef.current = null;
        mentionIsShowRef.current = false;
        panelControlRef.current.setPanel('messages');
      }
    },
    [state]
  );

  const search = useCallback(
    async word => {
      try {
        const indexKeyChar = word.indexOf('@');

        const text = word.substring(indexKeyChar + 1);

        if (mentionSearchRef.current !== text) {
          mentionSearchRef.current = text;

          setState({
            ...state,
            mentionIdxSelected: -1,
            mentionIsLoading: true,
          });

          const mentions = await MentionService.listUsers({ filter: text });

          setState({
            ...state,
            mentionIsLoading: false,
            mentionIdxSelected: mentions.length > 0 ? 0 : -1,
            mentions,
          });
        }
      } catch (error) {
        TreatError.showError(error);
        setState({
          ...state,
          mentionIsLoading: false,
          mentionIdxSelected: -1,
          mentions: [],
        });
      }
    },
    [state]
  );

  const getMentionSelected = useCallback(() => {
    if (state.mentionIdxSelected >= 0) {
      const mention = state.mentions[state.mentionIdxSelected];
      return mention;
    }
    return null;
  }, [state.mentionIdxSelected, state.mentions]);

  const selectMention = useCallback(
    mention => {
      const text = messageInputRef.current.value;

      const { initialPoint, finalPoint } = getWord(messageInputRef.current);

      const beforeText = text.substring(0, initialPoint);
      const afterText = text.substring(finalPoint);

      const mentionExists = mentionsInsideMessage.current.find(
        messageMention => messageMention.UserCode === mention.UserCode
      );

      if (!mentionExists) mentionsInsideMessage.current.push(mention);

      const newText = `${beforeText}@${mention.UserName} ${afterText}`;
      messageInputRef.current.value = newText;

      messageInputRef.current.selectionEnd =
        initialPoint + mention.UserName.length + 2;

      showMentionsPanel(false);
    },
    [getWord, showMentionsPanel]
  );

  const moveMention = useCallback(
    (e, moveType) => {
      if (mentionIsShowRef.current) {
        e.preventDefault();
        if (moveType === 'up') {
          if (state.mentionIdxSelected === 0) {
            setState({
              ...state,
              mentionIdxSelected: state.mentions.length - 1,
            });
          } else {
            setState({
              ...state,
              mentionIdxSelected: state.mentionIdxSelected - 1,
            });
          }
        } else if (state.mentionIdxSelected === state.mentions.length - 1) {
          setState({
            ...state,
            mentionIdxSelected: 0,
          });
        } else {
          setState({
            ...state,
            mentionIdxSelected: state.mentionIdxSelected + 1,
          });
        }
      }
    },
    [state]
  );

  const verifyInsideTags = useCallback(text => {
    mentionsInsideMessage.current = mentionsInsideMessage.current.filter(
      mention => {
        const exists = text.indexOf(`@${mention.UserName}`) >= 0;
        return exists;
      }
    );
  }, []);

  const onSendMessage = useCallback(() => {
    // validar se todas as hashtags existem no banco de dados na hora de inserir
    // Adicionar a mensagem como enviando e quando enviar colocar o check de enviado
    // Validar se existe alguma mensagem digitada e não é só um espaço

    if (!mentionIsShowRef.current) {
      const messageText = messageInputRef.current.value.trim();

      if (messageText.length > 0) {
        const today = new Date();

        verifyInsideTags(messageText);

        const message = {
          objectCode,
          keyValue,
          keyText,
          Code: uuidv4(),
          IsOwner: true,
          Status: STATUS.TO_SEND,
          Message: messageText,
          MentionsInsideMessage: [...mentionsInsideMessage.current],
        };

        const newMessages = [...messages];
        const messageDay = newMessages.find(({ day }) => {
          let dayFind = day;

          if (typeof day === 'string') {
            dayFind = new Date(day);
          }

          const status = isEqual(stringToDate(dayFind), stringToDate(today));

          return status;
        });

        if (messageDay) {
          messageDay.messages.push(message);
        } else {
          newMessages.push({
            day: today,
            messages: [message],
          });
        }

        setMessages(newMessages);

        messageInputRef.current.value = '';

        mentionsInsideMessage.current = [];
      }
    }
  }, [verifyInsideTags, objectCode, keyValue, keyText, messages, stringToDate]);

  const onKeyPress = useCallback(
    e => {
      const { word } = getWord(e.target);

      switch (e.keyCode) {
        case 50: // @
          showMentionsPanel(true);
          break;

        default:
          if (word.indexOf('@') < 0) showMentionsPanel(false);
          break;
      }
    },
    [getWord, showMentionsPanel]
  );

  const onChangeText = useCallback(
    e => {
      const { word } = getWord(e.target);
      if (word.indexOf('@') < 0) showMentionsPanel(false);
      else {
        showMentionsPanel(true);
        search(word);
      }
    },
    [getWord, search, showMentionsPanel]
  );

  const onMentionSelected = useCallback(
    ({ mention, idx }) => {
      setState({
        ...state,
        mentionIdxSelected: idx,
      });

      // const mention = getMentionSelected();
      selectMention(mention);
    },
    [selectMention, state]
  );

  const onKeyDown = useCallback(
    e => {
      switch (e.keyCode) {
        case 50: // @
          const { word } = getWord(e.target);
          const occurrenceCount = getOccurrenceCount(word);
          if (occurrenceCount > 0) {
            e.preventDefault();
          }
          break;

        case 40: // Pra baixo
          e.preventDefault();
          moveMention(e, 'down');
          break;

        case 38: // Pra cima
          e.preventDefault();
          moveMention(e, 'up');
          break;

        case 9: // Tab
        case 13: // enter
          if (mentionIsShowRef.current) {
            const mention = getMentionSelected();
            e.preventDefault();

            if (mention) {
              selectMention(mention);
            } else {
              showMentionsPanel(false);
            }
          } else if (e.keyCode === 13) {
            e.preventDefault();
            onSendMessage();
          }

          break;

        default:
          break;
      }
    },
    [
      getMentionSelected,
      getOccurrenceCount,
      getWord,
      moveMention,
      onSendMessage,
      selectMention,
      showMentionsPanel,
    ]
  );

  const loadMessages = useCallback(async () => {
    try {
      setState({
        ...state,
        // messageIsLoading: true,
        messagesError: null,
      });

      const responseMessages = await MentionService.listMessages({
        objectCode,
        keyValue,
      });

      setMessages(responseMessages);
      setState({
        ...state,
        // messageIsLoading: false,
        messagesError: null,
      });
    } catch (error) {
      // TreatError.showError(error);
      const errorMessage = TreatError.getDescription(error);

      setState({
        ...state,
        messageIsLoading: false,
        messagesError: errorMessage,
      });
    } finally {
      // setTimeout(reloadMessages, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyValue, objectCode]);

  const panelEnabled = useMemo(() => {
    const enabled = !(keyValue === null || keyValue === undefined);
    if (panelControlRef.current) {
      if (enabled) {
        panelControlRef.current.setPanel('messages');
      } else {
        panelControlRef.current.setPanel('disabled');
      }
    }
    return enabled;
  }, [keyValue]);

  useEffect(() => {
    if (panelControlRef && panelControlRef.current)
      panelControlRef.current.setScrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (panelEnabled) {
      loadMessages();
    } else {
      setMessages([]);
    }
  }, [loadMessages, panelEnabled]);

  return (
    <Container>
      <ActivityToolbar
        keyValue={keyValue}
        isVisible={panelEnabled && state.messagesError === null}
      />

      <PanelControls
        ref={panelControlRef}
        mainPanel={panelEnabled ? 'messages' : 'disabled'}
        onChangePanel={onChangePanel}
      >
        <Messages
          ref={messagePanelRef}
          name="messages"
          isLoading={state.messageIsLoading}
          messages={messages}
          errorMessage={state.messagesError}
          objectCode={objectCode}
          keyValue={keyValue}
          keyText={keyText}
        />
        <Mentions
          name="mentions"
          isLoading={state.mentionIsLoading}
          selectedIdx={state.mentionIdxSelected}
          mentions={state.mentions}
          onSelected={onMentionSelected}
        />
        <Disabled name="disabled" />
      </PanelControls>

      <MessageText isVisible={panelEnabled && state.messagesError === null}>
        <textarea
          ref={messageInputRef}
          wrap="on"
          style={{ resize: 'none' }}
          placeholder={t('message.EnterMessage')}
          onChange={DebounceEvent(onChangeText, 1000)}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyPress}
        />
        <button type="button" onClick={onSendMessage}>
          <SendIcon size={24} color="#449dc1" />
        </button>
      </MessageText>
    </Container>
  );
}

export default HastagMention;
