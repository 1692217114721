import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Footer = styled.div`
  display: flex;
  padding: 10px 20px;
  justify-content: flex-end;
  background-color: #fff;
`;
