import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const Content = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;

  .vertical-timeline-element-content {
    padding: 20px !important;
  }

  .vertical-timeline-element-date {
    padding: 10px !important;
  }

  .vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
`;

export const Info = styled.div`
  div {
    color: #444 !important;
  }
`;
export const ElementInfo = styled.div``;

export const Element = styled.div`
  padding: 30px 0;

  .vertical-timeline-element-content-arrow {
    border-right-color: ${props => props.arrowColor};
  }
`;

export const Text = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  /* padding-left: 10px; */
`;
