/* eslint-disable import/no-cycle */
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { normalizeName } from '~/hooks/useUiDesigner';
import { Container, FieldName, Field, Buttons, Message } from './styles';
import EventButton from './EventButton';

function getFieldDescription(data) {
  if (Array.isArray(data)) return data;

  return [{ ...data }];
}

const Log = (_, ref) => {
  const [events, setEvents] = useState([]);
  const buttonReferences = useRef([]);

  const isShow = useSelector(({ log }) => log.isShow);
  const type = useSelector(({ log }) => log.type);
  const data = useSelector(({ log }) => log.data);

  useImperativeHandle(ref, () => {
    return {
      clearEvents: () => {
        setEvents([]);
      },
      removeEvent: ({ title, eventName }) => {
        if (eventName) {
          const newEvents = events.filter(
            b =>
              !(
                b.name === normalizeName(title) &&
                b.eventName.toUpperCase() === eventName.toUpperCase()
              )
          );

          setEvents(newEvents);
        }
      },
      hasChanges: () => {
        return events.length > 0;
      },
      addEvent: event => {
        const eventIndex = events.findIndex(
          b =>
            b.name === normalizeName(event.title) &&
            b.eventName.toUpperCase() === event.menu.prop.toUpperCase()
        );

        if (eventIndex >= 0) {
          buttonReferences.current[eventIndex].current.setVisible(true);
        } else {
          buttonReferences.current[events.length] = React.createRef();
          setEvents([
            ...events,
            {
              name: normalizeName(event.title),
              eventName: event.menu.prop,
              title: event.title,
              event,
            },
          ]);
        }
      },
    };
  });

  const informations = getFieldDescription(data);

  let message = '';

  switch (type) {
    case 1:
      message = (
        <>
          {informations.map(d => {
            return (
              <Field key={`${d.name}-${d.value}`}>
                <FieldName>
                  {d.baseName ? `${d.baseName}.` : ''}
                  {d.name}{' '}
                </FieldName>
                [ {d.value} ] {'  '}
              </Field>
            );
          })}
        </>
      );
      break;

    default:
      break;
  }

  return (
    <>
      {isShow && (
        <Container>
          <Message>{message}</Message>

          <Buttons>
            {events.map((event, index) => {
              return (
                <EventButton
                  key={event.name}
                  title={event.title}
                  eventName={event.eventName}
                  event={event}
                  ref={buttonReferences.current[index]}
                />
              );
            })}
          </Buttons>
        </Container>
      )}
    </>
  );
};

export default forwardRef(Log);
