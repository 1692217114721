import styled from 'styled-components';

export const Container = styled.button`
  cursor: pointer;
  padding: 4px 10px;
  margin: 0 4px;
  color: #ffffff;
  background-color: #49746590;
  border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    font-weight: bold;
  }

  h2 {
    margin-left: 5px;
    font-size: 0.8rem;
    color: #fff;
    font-weight: normal;
  }
`;
