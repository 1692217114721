/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React, {
  forwardRef,
  useImperativeHandle,
  // useState,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendData } from '~/store/modules/log/actions';
import useUiDesigner from '~/hooks/useUiDesigner';
// import { createSyncFunctionByString } from '~/easy-components/AsyncFunctionString';
import useFieldBase from '~/hooks/useField';
import { sendEvent } from '../HandlerEvent';
import TreatError from '../TreatError';
import { Container } from './styles';
import LoadIcon from '../LoadIcon';

/**
 * @typedef {Object} Props
 * @property {string} [name]
 * @property {string} [baseName]
 * @property {import('../..').Settings} [settings]
 * @property {React.MutableRefObject<import('@unform/core').FormHandles>} [mainFormRef]
 * @property {React.MutableRefObject<import('@unform/core').FormHandles>} [formRef]
 * @property {React.ReactNode} [children]
 * @property {boolean} [hidden]
 * @property {string} [buttonType]
 * @property {string} [type]
 * @property {React.ButtonHTMLAttributes} [rest]
 */

/**
 * @typedef {Object} Ref
 * @property {(isVisible: boolean) => void} setIsVisible
 */

/**
 * @param {Props} props
 * @param {React.Ref<Ref>} ref
 */
function Button(
  {
    name = ' ',
    baseName = '',
    settings,
    mainFormRef,
    formRef,
    auxScope,
    buttonType = 'Default',
    type = 'button',
    children,
    onClick,
    hidden = false,
    isLoading = false,
    isUserField = false,
    color,
    auxContextEvents = [],
    ...rest
  },
  ref
) {
  const dispatch = useDispatch();

  const buttonRef = useRef({});

  const showLog = useSelector(({ log }) => log.isShow);

  if (name.indexOf('btn') === -1) {
    name = `btn${name}`;
  }

  const fullName = baseName ? `${baseName}.${name}` : `${name}`;

  const pageId = settings ? settings._route : '';

  const { selfField, viewMode, showContextMenu } = useUiDesigner({
    pageId,
    componentType: 'button',
    // settings,
    baseName,
    isUserField,
    name,
  });

  const { fields } = settings || {};

  const buttonSettings =
    (fields
      ? fields.find(f => {
          return f.name === fullName;
        })
      : {}) || {};

  /* let initialHidden = hidden;

  if (buttonSettings && typeof buttonSettings.hidden === 'string') {
    const hiddenFunction = createSyncFunctionByString({
      functionString: buttonSettings.hidden,
    });

    initialHidden = !!(
      hiddenFunction({ formRef, selfField: buttonSettings }) || hidden
    );
  }

  if (buttonSettings && typeof buttonSettings.hidden === 'boolean') {
    initialHidden = buttonSettings.hidden || hidden;
  }

  const [hiddenEvent, setHiddenEvent] = useState(initialHidden); */

  const { isHidden, setIsHidden } = useFieldBase({
    hidden,
    selfField,
    formRef,
    inputRef: buttonRef,
    name,
    getLogData: () => {
      return {
        baseName,
        name,
      };
    },
  });

  const props = {
    ...rest,
    ...buttonSettings,
  };

  const sendClientEvent = async ({ eventName, params, run }) => {
    await sendEvent({
      settings,
      eventName,
      params,
      run,
      mainFormRef,
      formRef,
      events: buttonSettings.events,
      auxScope,
    });
  };

  const handleClick = async e => {
    try {
      if (onClick) {
        await onClick(e);

        await sendClientEvent({
          eventName: 'onClick',
          run: 'after',
          data: null,
        });
      }
    } catch (error) {
      TreatError.showError(error);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      setVisible: status => {
        setIsHidden(!status);
      },
      ...buttonRef.current,
    };
  });

  useEffect(() => {
    if (formRef && formRef.current && formRef.current.addButton) {
      formRef.current.addButton({
        name: fullName,
        element: {
          ...buttonRef.current,
          setHidden: status => {
            setIsHidden(status);
          },
          setVisible: status => {
            setIsHidden(!status);
          },
        },
      });
    }
  }, [formRef, fullName, name, setIsHidden]);

  /* useEffect(() => {
    setHiddenEvent(
      buttonSettings.hidden === true ||
        buttonSettings.hidden === 'true' ||
        hidden ||
        false
    );
  }, [buttonSettings.hidden, hidden]); */

  /* useEffect(() => {
    setHiddenEvent(initialHidden);
  }, []); */

  return (
    <>
      <Container
        onContextMenu={event => {
          showContextMenu({ event, auxContextEvents });
        }}
        type={type}
        onMouseOver={() => {
          if (showLog) dispatch(sendData({ baseName, name }));
        }}
        {...props}
        hidden={false}
        className={buttonType}
        viewMode={viewMode}
        isUserField={isUserField}
        hiddenProp={isHidden}
        onClick={handleClick}
        color={color}
      >
        {isLoading ? <LoadIcon size={16} /> : children}
      </Container>
    </>
  );
}

/** @type {ReturnType<typeof forwardRef<Ref, Props & React.ButtonHTMLAttributes<HTMLButtonElement>>>} */
const ButtonComponent = forwardRef(Button);

export default ButtonComponent;
