/* eslint-disable import/no-cycle */
/* eslint-disable no-new-func */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import { useDispatch } from 'react-redux';

import LinkIcon from '~/easy-components/LinkIcon';

import { Linker } from '../styles';
import dynamicFunction from '../dynamicFunction';

import { PageContext } from '../index';

function LinkerCell({
  column,
  rowData,
  value,
  settings,
  style: customStyle,
  items,
  rawItems,
}) {
  const dispatch = useDispatch();

  const { auxScope, removeLine } = useContext(PageContext);
  return (
    <Linker>
      {value && (
        <LinkIcon
          onClick={async () => {
            if (column.settings && column.settings.type === 'link') {
              await dynamicFunction({
                functionString: column.settings.onClick,
                settings,
                dispatch,
                params: {
                  ...auxScope,
                  column,
                  line: rowData,
                  value,
                  removeLine,
                  items,
                  rawItems,
                },
              });
            }
          }}
        />
      )}
      <span
        style={{
          maxWidth:
            customStyle && customStyle.maxWidth
              ? customStyle.maxWidth
              : '600px',
          ...customStyle,
        }}
      >
        {value}
      </span>
    </Linker>
  );
}

export default LinkerCell;
