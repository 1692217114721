import styled from 'styled-components';

export const Container = styled.div`
  width: 1100px;
  height: 700px;

  label {
    margin: 0px 0px 5px 5px;
    color: '#555';
    display: block;
  }
`;

export const TableContainer = styled.div`
  background-color: #e9edef;
  height: 100%;
  flex: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  overflow: auto;

  > form {
    height: 100%;
  }
`;

export const Text = styled.h3`
  color: ${props => (props.dark ? '#555' : ' #728591')};
  font-size: ${props => (props.fontSize ? props.fontSize : '18px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  padding: 2px 2px 2px 2px;
`;

export const Spacer = styled.div`
  padding: ${props => (props.padding ? props.padding : '5px 5px 5px 5px')};
`;

export const Grid = styled.div`
  width: 100%;
  padding: 0px 15px 0px 15px;
  margin: 0px auto 0px auto;
  box-sizing: border-box;
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
`;

export const Row = styled.div`
  width: 100%;
  height: auto;
  float: left;
  box-sizing: border-box;
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
`;

function getGridWidth(value) {
  if (!value) {
    return '';
  }
  const width = (value / 12) * 100;
  return `width: ${width}%;`;
}

export const Column = styled.div`
  float: left;
  width: ${props => (props.width ? props.width : '100%')};
  padding: ${props => (props.padding ? props.padding : '10px')};
  min-height: 1px;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    ${({ mobile }) => mobile && getGridWidth(mobile)}
  }
  @media only screen and (min-width: 768px) {
    ${({ tablet }) => tablet && getGridWidth(tablet)}
  }
  @media only screen and (min-width: 1000px) {
    ${({ desktop }) => desktop && getGridWidth(desktop)}
  }
`;
