import styled from 'styled-components';

export const Container = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #449dc1;
`;
export const Subject = styled.h1`
  font-weight: 400;
`;
