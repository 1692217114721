const INITIAL_STATE = null;

export default function authorizationToken(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'OUTSIGN_SUCCESS':
      return action.payload.authorizationToken;

    case 'LOGIN_SUCCESS':
      return action.payload.authorizationToken;

    case 'LOGOFF_SUCCESS':
      return INITIAL_STATE;

    default:
      return state;
  }
}
