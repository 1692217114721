/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import colors from '~/styles/colors';

import { Container } from './styles';

const getStatus = (status, t) => {
  const tStatus = t || (() => {});
  switch (status) {
    case 1:
      return {
        text: tStatus('Available'),
        color: colors.green,
      };

    default:
      return {
        text: tStatus('Unavailable'),
        color: colors.gray,
      };
  }
};

function DocumentStatus({ status, t }) {
  const { color, text } = getStatus(status, t);

  return <Container color={color}>{text}</Container>;
}

DocumentStatus.propTypes = {
  status: PropTypes.string,
};

DocumentStatus.defaultProps = {
  status: null,
};

export default DocumentStatus;

export const getStatusInfo = getStatus;
