import styled from 'styled-components';

export const TitleColumn = styled.div`
  padding: 10px;
  color: #fff;
  background-color: #49746570;
  font-size: 0.9rem;
  border-radius: 10px;
  margin-bottom: 10px !important;
  font-weight: 700;
`;
