/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { MdArrowBack } from '@react-icons/all-files/md/MdArrowBack';
import history from '../../routes/history';

import { Container } from './styles';

import colors from '~/styles/colors';

export default function BackCircleButton() {
  const onClick = () => {
    history.goBack();
  };
  return (
    <Container>
      <MdArrowBack size={22} color={colors.blue} onClick={onClick} />
    </Container>
  );
}
