/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';
import { updateFilterRowValue } from 'ka-table/actionCreators';
import DebounceEvent from '~/easy-components/DebounceEvent';

function NumberFilter({ column, dispatch, value }) {
  const charPermitted = '0123456789.,';
  const inputRef = useRef();
  function onChange(event) {
    const targetValue = event.target.value || null;
    dispatch(updateFilterRowValue(column.key, targetValue));
  }

  useEffect(() => {
    inputRef.current.value = value || '';
  }, [value]);

  return (
    <input
      ref={inputRef}
      inputMode="decimal"
      onChange={DebounceEvent(onChange, 1000)}
      onKeyPress={e => {
        if (charPermitted.indexOf(e.key) < 0) {
          e.preventDefault();
        }
      }}
    />
  );
}

export default NumberFilter;
