/* eslint-disable react/prop-types */
/* eslint-disable default-case */
/* eslint-disable no-undef */
import React from 'react';

import { Wrapper, Status } from '@googlemaps/react-wrapper';

import Loading from '~/components/Loading';

function GoogleApi({ t, children }) {
  const render = status => {
    switch (status) {
      case Status.LOADING:
        return <Loading />;

      case Status.FAILURE:
        return (
          <div>
            <h1>{t('message.UnexpectedErrorOccurred')}</h1>
          </div>
        );

      case Status.SUCCESS:
        return <div>{t('message.SuccessStatusRenderMethod')}</div>;

      default:
        return null;
    }
  };

  if (!process.env.REACT_APP_GOOGLE_API_KEY) {
    return children;
  }

  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      libraries={['geometry']}
      render={render}
    >
      {children}
    </Wrapper>
  );
}

export default GoogleApi;
