import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  margin-right: 10px;
  color: #666;
  overflow: hidden;

  canvas {
    flex: 1;
    height: 100% !important;
    width: 100% !important;
  }
`;
