import styled from 'styled-components';
import { lighten } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  background-color: #ffffff;
  max-width: 300px;
  max-height: 500px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  border: 1px solid #f7f7f7;
  border-radius: 5px;

  @media (max-width: 500px) {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
`;

export const Content = styled.div`
  overflow: auto;
  flex: 1;
`;

export const Footer = styled.div`
  height: 44px;
  background-color: #f5f5f5;
  border-top: 1px solid #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px;
`;

export const ReportInfo = styled.div`
  padding: 20px 15px;
  border-bottom: 1px solid #f7f7f7;
  line-height: 18px;

  h2 {
    font-weight: normal;
    color: #777;
  }

  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: linear;

  background-color: ${props =>
    props.isDefault ? lighten(0.6, colors.secundary) : 'transparent'};

  &:hover {
    background-color: ${lighten(0.55, colors.secundary)};
  }
`;
