// @ts-check

import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
  startOfMonth,
  format,
  previousMonday,
  nextSunday,
  endOfMonth,
} from 'date-fns';
import {
  createAsyncFunctionByString,
  createSyncFunctionByString,
} from '~/easy-components/AsyncFunctionString';
import CardTemplate from '~/easy-components/CardTemplate';

/**
 * Helpers para utilizar o componente de agenda em qualquer página do portal
 *
 * @typedef {{StartDate: string, EndDate: string, [key: string]: any}} Event
 * @typedef {{StartDate: Date, EndDate: Date, [key: string]: any}} TreatedEvent
 *
 * @typedef {Object} ClientEvent
 * @property {string} name
 * @property {"before" | "after"} run
 * @property {string} handler
 *
 * @typedef {Object} Props
 * @property {Event[]} [events] Configurações dos eventos da página
 * @property {ClientEvent[]} [clientEvents] Configurações dos eventos da página
 * @property {{dynamicFunctionProps: object}} [applicationSettings] Configurações da página
 * @property {() => any} [refreshPage] Função que da reload na página
 * @property {string} [eventTemplate] Template configurado pelo delivery
 * @property {(string: string) => Date} [stringToDate] Função de transformação de string em date
 * @property {import('react').MutableRefObject<import('@unform/core').FormHandles>} [formRef] Formulário da página para exportar aos eventos
 *
 * @typedef {Object} ScheduleHelpers
 * @property {TreatedEvent[]} [events]
 * @property {(params: {start: Date}) => void} onAddEvent
 * @property {(event: object) => Promise<any>} onSelectEvent
 * @property {{start: string, end: string}} viewRange
 * @property {(props: {start: string, end: string}) => void} updateRangeRef
 * @property {() => (props: {event: object}) => JSX.Element} onEventTemplate
 * @property {(props: { [x: string]: any, eventName: string, run?: "before" | "after", data?: any }) => Promise<any>} executeEvent
 *
 *
 * @param {Props} props
 * @returns {ScheduleHelpers}
 */
const useSchedule = ({
  events = [],
  clientEvents = [],
  applicationSettings = { dynamicFunctionProps: {} },
  refreshPage = () => {},
  eventTemplate,
  stringToDate,
  formRef,
}) => {
  const { dynamicFunctionProps } = applicationSettings;

  /** @type {import('react').MutableRefObject<{start?: string, end?: string}>} */
  const rangeDateRef = useRef({});

  const { start: rangeStart } = rangeDateRef.current;

  if (!rangeStart) {
    const firstOfMonth = startOfMonth(new Date());

    rangeDateRef.current.start = format(
      previousMonday(firstOfMonth),
      'yyyy-MM-dd'
    );

    const lastOfMonth = endOfMonth(new Date());

    rangeDateRef.current.end = format(nextSunday(lastOfMonth), 'yyyy-MM-dd');
  }

  const eventsTreated = useMemo(() => {
    return events.map(event => {
      /** @type {Date} */
      const StartDate = stringToDate(event.StartDate);
      return {
        ...event,
        StartDate,
        EndDate: stringToDate(event.EndDate || event.StartDate),
      };
    });
  }, [events, stringToDate]);

  const [visibleEvents, showEvents] = useState(eventsTreated);

  const executeEvent = async ({
    eventName,
    run = 'before',
    data = undefined,
    ...rest
  }) => {
    if (clientEvents.length) {
      const event = clientEvents.find(
        evt =>
          evt.name.toUpperCase() === eventName.toUpperCase() &&
          (evt.run || 'before') === run
      );

      if (event) {
        const dynamicFunction = createAsyncFunctionByString({
          functionString: event.handler,
        });

        return dynamicFunction({
          data,
          ...rest,
          ...dynamicFunctionProps,
          refresh: refreshPage,
          form: formRef && formRef.current,
          formData: formRef && formRef.current.getData(),
        });
      }

      return null;
    }

    return null;
  };

  const onAddEvent = ({ start }) => {
    executeEvent({
      eventName: 'onNewEvent',
      run: 'before',
      data: { start },
    });
  };

  const onSelectEvent = event => {
    return executeEvent({
      eventName: 'onSelectEvent',
      run: 'before',
      data: { event },
    });
  };

  const onEventTemplate = () => {
    if (!eventTemplate) return null;

    const mountCardTemplate = ({ event }) => {
      const dynamicFunction = createSyncFunctionByString({
        functionString: eventTemplate,
      });

      const template = dynamicFunction({
        data: event,
        ...dynamicFunctionProps,
      });

      return (
        <CardTemplate
          template={template}
          data={event}
          style={{ padding: 0 }}
          settings={applicationSettings}
          format={undefined}
          formatters={undefined}
          listColor={undefined}
        />
      );
    };
    return mountCardTemplate;
  };

  useEffect(() => {
    showEvents(eventsTreated);
  }, [eventsTreated]);

  return {
    onAddEvent,
    onSelectEvent,
    events: visibleEvents,
    viewRange: {
      start: rangeDateRef.current.start,
      end: rangeDateRef.current.end,
    },
    updateRangeRef: props => {
      showEvents([]);
      rangeDateRef.current.start = props.start;
      rangeDateRef.current.end = props.end;
    },
    onEventTemplate,
    executeEvent,
  };
};

export default useSchedule;
