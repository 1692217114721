export const SET_COLUMN_READ_ONLY_TYPE = 'SET_COLUMN_READ_ONLY';

const setColumnReadOnly = (columnKey, isReadOnly) => ({
  type: SET_COLUMN_READ_ONLY_TYPE,
  payload: {
    columnKey,
    isReadOnly,
  },
});

export default setColumnReadOnly;
