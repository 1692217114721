/* eslint-disable import/no-cycle */
import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';
import QueryPage from '~/pages/Queries';
import HeaderPage from '~/easy-components/HeaderPage';
import colors from '~/styles/colors';
import Icon from '~/easy-components/Icon';
import Editor from './Editor';
import GlobalFunctions from './panels/GlobalFunctions';

function Panel(
  {
    events,
    runType,
    eventName,
    menu,
    title,
    querySettings,
    onMinimalize,
    onKeyDown,
    settings,
    onSaveGlobalFunction,
    code,
    auxIntelisenses,
  },
  ref
) {
  const editorRef = useRef();
  const globalFunctionsRef = useRef();
  const [componentView, setComponentView] = useState('code');

  useImperativeHandle(ref, () => ({
    showComponent: async view => {
      setComponentView(view);
    },
    getValue: () => {
      if (code === undefined || code === null) {
        return {
          code: null,
          events: editorRef.current.getValue(),
          globalFunctions: globalFunctionsRef.current.getValue(),
        };
      }

      return {
        code: editorRef.current.getValue(),
        events: null,
        globalFunctions: globalFunctionsRef.current.getValue(),
      };
    },
    codeFocus: () => {
      editorRef.current.focus();
    },
  }));

  return (
    <>
      <div
        style={{
          flex: 1,
          height: '100%',
          overflow: 'auto',
          display: componentView === 'code' ? 'flex' : 'none',
          flexDirection: 'column',
          backgroundColor: 'black',
        }}
      >
        <>
          <HeaderPage
            backgroundColor={colors.headerBg}
            color={colors.headerTitle}
            hideMainMenu
          >
            {title}
            <Icon
              name="FaRegWindowMinimize"
              size={18}
              color="#FFFFFF"
              onClick={onMinimalize}
            />
          </HeaderPage>
          <Editor
            ref={editorRef}
            events={events}
            runType={runType}
            eventName={eventName}
            menu={menu}
            onKeyDown={onKeyDown}
            code={code}
            auxIntelisenses={auxIntelisenses}
          />
        </>
      </div>

      <GlobalFunctions
        ref={globalFunctionsRef}
        componentView={componentView}
        settings={settings}
        onSave={onSaveGlobalFunction}
      />

      <div
        style={{
          flex: 1,
          height: '100%',
          overflow: 'auto',
          display: componentView === 'query' ? 'flex' : 'none',
          flexDirection: 'column',
          backgroundColor: 'black',
          animation: 'fadeIn 0.5s',
        }}
      >
        <QueryPage
          settings={querySettings}
          isListOpen={false}
          initialData={null}
          showBackButtom={false}
        />
      </div>
    </>
  );
}

export default forwardRef(Panel);
