import styled from 'styled-components';

export const Container = styled.div`
  min-width: 500px;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
  color: #555;

  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: linear;

  &:hover {
    background-color: #deeff6;
  }

  h2 {
    font-size: 13.35px;
    font-weight: 500;
  }

  span {
    font-size: 13.35px;
    font-weight: 400;
    color: #848484;
  }
`;

export const IconButton = styled.button`
  margin-left: 10px;
  svg {
    margin-left: 0;
  }
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
`;
