/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-case-declarations */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { FaBars } from '@react-icons/all-files/fa/FaBars';
import { AiOutlineLoading } from '@react-icons/all-files/ai/AiOutlineLoading';
import { isMobile } from 'react-device-detect';

import Toast from '~/easy-components/Toast';
import useLocale from '~/hooks/useLocale';
import Modal from '../../../Modal';
import Switch from '../../../Switch';
import TreatError from '../../../TreatError';
import SelectItem from '../../../SelectItem';
import { sendEvent } from '../../../HandlerEvent';

import {
  Input,
  PanelInput,
  SearchIcon,
  LoadIcon,
  Container,
  FieldContainer,
} from './styles';
import MobileView from './MobileView';
import Detail from './MobileView/Detail';

const Linker = forwardRef(
  (
    {
      title,
      settings,
      method: clientMethod,
      onChange,
      fixedData,
      onLinkClick,
      showLink,
      value,
      label,
      propFieldValue = 'value',
      propFieldLabel = 'label',
      renderItem,
      auxScope,
      forceSelection = true,
      onStartLoading,
      onStopLoading,
      readOnly,
      isResponsible = true,
      onFocus,
      onKeyDown,
      minWidth,
      ...rest
    },
    ref
  ) => {
    const t = useLocale('_Global');

    const renderized = useRef(false);

    useEffect(() => {
      renderized.current = true;
      return () => {
        renderized.current = false;
      };
    }, []);

    const [isShowSearchViewer, setIsShowSearchViewer] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [mobileText, setMobileText] = useState('');
    const [error, setError] = useState('');
    const shouldCallEnterAfterSelection = useRef({
      value: false,
      event: null,
    });

    const inputRef = useRef(null);
    const valueRef = useRef(null);
    const mobileRef = useRef(null);

    const commitLoading = useCallback(loading => {
      if (onStartLoading && typeof onStartLoading === 'function' && loading)
        onStartLoading();

      setIsLoading(loading);

      if (onStopLoading && typeof onStopLoading === 'function' && !loading)
        onStopLoading();
    }, []);

    const handlerEvent = async (eventName, params, run) => {
      const response = await sendEvent({
        settings,
        eventName,
        params,
        run,
        element: inputRef.current,
        events: [],
        auxScope,
      });

      return response || params;
    };

    async function handleChange(data) {
      try {
        const newValue = await handlerEvent('onchange', data, 'before');
        await onChange(newValue, inputRef, valueRef);
        await handlerEvent('onchange', data, 'after');
      } catch (e) {
        TreatError.showError(e);
      }
    }

    useEffect(() => {
      if (Array.isArray(fixedData) && fixedData.length > 0 && value) {
        const selectedData = fixedData.find(
          d => d.value.toString() === value.toString()
        );

        valueRef.current.value = selectedData
          ? selectedData[propFieldValue]
          : null;
        inputRef.current.value = selectedData
          ? selectedData[propFieldLabel]
          : null;

        setMobileText(inputRef.current.value);
      } else if (value) {
        valueRef.current.value = value;

        inputRef.current.value = label;

        setMobileText(inputRef.current.value);
      } else {
        valueRef.current.value = null;
        inputRef.current.value = null;
      }
    }, [value]);

    async function onSelect(data, setFocus = true) {
      setIsShowSearchViewer(false);
      commitLoading(false);

      if (inputRef.current) {
        inputRef.current.changed = false;
        setError(null);

        if (data) {
          inputRef.current.value = data[propFieldLabel];
          valueRef.current.value = data[propFieldValue];
        } else {
          inputRef.current.value = null;
          valueRef.current.value = null;
        }

        if (setFocus) inputRef.current.focus();

        if (shouldCallEnterAfterSelection.current.value) {
          onKeyDown(shouldCallEnterAfterSelection.current.event);

          shouldCallEnterAfterSelection.current.value = false;
          shouldCallEnterAfterSelection.current.event = null;
        }

        await handleChange(data);
      }
    }

    async function onBlur(e) {
      if (readOnly) {
        return false;
      }

      e.persist();

      const textChanged = e.target.changed;
      const searchTextInput = e.target.value;

      if (textChanged && isLoading === false) {
        if (searchTextInput === '' || searchTextInput === null) {
          valueRef.current.value = null;
          setError(null);
          e.target.changed = false;
          await handleChange(null);
        } else {
          commitLoading(true);

          let response = [];

          const fixData = fixedData;

          if (fixData) {
            response = fixData.filter(
              d =>
                d[propFieldLabel]
                  .toString()
                  .toUpperCase()
                  .indexOf(inputRef.current.value.toString().toUpperCase()) >= 0
            );
          } else {
            response = await method(searchTextInput);
          }

          switch (response.length) {
            case 0:
              setError(t('error.InvalidValue'));
              valueRef.current.value = null;
              inputRef.current.value = '';
              inputRef.current.focus();
              await handleChange(null);
              commitLoading(false);

              if (shouldCallEnterAfterSelection.current.value) {
                shouldCallEnterAfterSelection.current.event.stopPropagation();

                shouldCallEnterAfterSelection.current.value = false;
                shouldCallEnterAfterSelection.current.event = null;
              }
              break;

            case 1:
              if (response[0].optionDisabled === 'Y') {
                setError(t('error.InvalidValue'));
                valueRef.current.value = null;
                inputRef.current.focus();
                await handleChange(null);
              } else {
                await onSelect(response[0], false);

                if (shouldCallEnterAfterSelection.current.value) {
                  onKeyDown(shouldCallEnterAfterSelection.current.event);

                  shouldCallEnterAfterSelection.current.value = false;
                  shouldCallEnterAfterSelection.current.event = null;
                }
              }
              commitLoading(false);
              break;

            default:
              setSearchData(response);
              setIsShowSearchViewer(true);
              setSearchText(searchTextInput);
              setError(t('error.InvalidValue'));
              await handleChange(null);
              break;
          }
        }
      }

      if (shouldCallEnterAfterSelection.current.value && !textChanged) {
        onKeyDown(shouldCallEnterAfterSelection.current.event);

        shouldCallEnterAfterSelection.current.value = false;
        shouldCallEnterAfterSelection.current.event = null;
      }

      return true;
    }

    function onLink() {
      onLinkClick({
        value: valueRef.current.value,
        label: valueRef.current.value,
      });
    }

    async function method(props) {
      const clientResponse = await clientMethod(props);

      if (!forceSelection) {
        if (props && props !== '') {
          clientResponse.unshift({
            value: props,
            label: props,
            _isDraftItem: true,
          });
        }
      }

      return clientResponse;
    }

    function handleRenderItem(data) {
      return (
        <SelectItem
          auxInfo={data.auxInfo || data.AuxInfo}
          header={data[propFieldLabel]}
        />
      );
    }

    useImperativeHandle(ref, () => ({
      openSearch: () => {
        if (isMobile) {
          return mobileRef.current.openSearch();
        }
        return setIsShowSearchViewer(true);
      },
      focus: () => {
        inputRef.current.focus();
      },
      select: () => {
        inputRef.current.select();
      },
      addEventListener: (eventName, callback) => {
        inputRef.current.addEventListener(eventName, callback);
      },
      blur: () => inputRef.current.blur(),
    }));

    const IconButton = () => {
      if (readOnly) {
        return null;
      }

      if (isLoading) {
        return (
          <LoadIcon>
            <AiOutlineLoading size={12} color="#aaa" />
          </LoadIcon>
        );
      }

      return (
        <SearchIcon
          onClick={() => {
            setIsShowSearchViewer(true);
          }}
        >
          <FaBars size={12} color="#aaa" />
        </SearchIcon>
      );
    };

    try {
      return (
        <Container isResponsible={isResponsible} minWidth={minWidth}>
          <input ref={valueRef} hidden />

          <FieldContainer style={isMobile ? rest && rest.style : {}}>
            <Switch
              mobile={
                <>
                  <input ref={inputRef} style={{ display: 'none' }} />
                  <MobileView
                    t={t}
                    ref={mobileRef}
                    showLink={showLink}
                    title={title}
                    text={mobileText}
                    fixedData={fixedData}
                    method={method}
                    renderItem={renderItem || handleRenderItem}
                    {...rest}
                    propFieldLabel={propFieldLabel}
                    propFieldValue={propFieldValue}
                    forceSelection={forceSelection}
                    onLink={onLink}
                    readOnly={readOnly}
                    onConfirm={async data => {
                      valueRef.current.value = data
                        ? data[propFieldValue]
                        : null;
                      inputRef.current.value = data
                        ? data[propFieldLabel]
                        : null;
                      setMobileText(inputRef.current.value || '');
                      await handleChange(data);
                    }}
                  />
                </>
              }
              computer={
                <PanelInput readOnly={readOnly}>
                  <div>
                    <Input
                      ref={inputRef}
                      placeholder={error}
                      readOnly={readOnly}
                      onBlur={e => {
                        onBlur(e);
                      }}
                      onFocus={onFocus}
                      autoComplete="off"
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          shouldCallEnterAfterSelection.current.value = true;
                          shouldCallEnterAfterSelection.current.event = {
                            keyCode: 13,
                            shiftKey: e.shiftKey,
                            ctrlKey: false,
                            altKey: false,
                            target: e.target,
                            stopPropagation: () => {
                              e.stopPropagation();
                            },
                          };

                          e.target.blur();
                          return;
                        }

                        switch (e.keyCode) {
                          case 27:
                          case 9:
                          case 20:
                          case 16:
                          case 17:
                          case 18:
                          case 91:
                          case 93:
                          case 37:
                          case 38:
                          case 39:
                            break;

                          case 40: // Seta para baixo
                          case 115: // F4
                            if (e.target.changed) {
                              e.target.changed = false;

                              if (e.target.value) setError('Valor inválido');
                            }

                            setSearchData(null);
                            setSearchText('');
                            setIsShowSearchViewer(true);
                            break;

                          default:
                            valueRef.current.value = null;
                            e.target.changed = true;
                            break;
                        }

                        onKeyDown(e);
                      }}
                    />
                    <IconButton />
                  </div>
                  <Modal isOpened={isShowSearchViewer}>
                    {isShowSearchViewer ? (
                      <Detail
                        t={t}
                        searchData={searchData}
                        method={method}
                        onClose={() => {
                          setIsShowSearchViewer(false);
                          commitLoading(false);
                          inputRef.current.focus();
                        }}
                        onConfirm={onSelect}
                        title={title}
                        text={searchText}
                        fixedData={fixedData}
                        renderItem={renderItem || handleRenderItem}
                      />
                    ) : (
                      <></>
                    )}
                  </Modal>
                </PanelInput>
              }
            />
          </FieldContainer>
        </Container>
      );
    } catch (e) {
      if (renderized.current) {
        Toast.error(e.message);
      } else {
        Toast.warn(e.message);
      }
      return null;
    }
  }
);

Linker.prototypes = {
  onChange: PropTypes.func,
  renderItem: PropTypes.func,
  onStartLoading: PropTypes.func,
  onStopLoading: PropTypes.func,
  showLink: PropTypes.bool,
  mainFormRef: PropTypes.shape(),
};

Linker.defaultProps = {
  mainFormRef: {},
  onChange: () => {},
  onLinkClick: () => {},
  showLink: false,
};

export default Linker;
