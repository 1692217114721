/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
} from 'react';
import { RiArrowUpSFill as Up } from '@react-icons/all-files/ri/RiArrowUpSFill';
import { RiArrowDownSFill as Down } from '@react-icons/all-files/ri/RiArrowDownSFill';
import { FaCircle as Equal } from '@react-icons/all-files/fa/FaCircle';

import QueryService from '~/services/QueryService';
import TreatError from '~/easy-components/TreatError';

import { Container, Number, Value, Unit, Aux, Error } from './styles';
import Loader from '../Loader';

function Kpi({ data, widgetSettings, params, executeEvent }, ref) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState({});
  const updateTimeRef = useRef();

  const run = useCallback(async () => {
    try {
      setIsLoading(true);

      setError(null);

      const response = await QueryService.execute(
        1,
        widgetSettings.query,
        params
      );

      if (response.length > 0) {
        const line = response[0];

        const positiveColor = widgetSettings.positiveColor || '#a9dca9';
        const negativeColor = widgetSettings.negativeColor || '#dca9a9';
        const equalColor = widgetSettings.equalColor || '#ADADAD';

        let color = null;
        let icon = null;

        if (+line.Value === +line.ReferenceValue) {
          color = equalColor;
          icon = <Equal size={12} color={equalColor} />;
        } else if (+line.Value > +line.ReferenceValue) {
          color = positiveColor;
          icon = <Up size={48} color={positiveColor} />;
        } else {
          color = negativeColor;
          icon = <Down size={48} color={negativeColor} />;
        }

        setInfo({
          color,
          icon,
          ...line,
        });
      } else {
        setInfo({});
      }

      if (widgetSettings.updateTimeMinutes) {
        updateTimeRef.current = setTimeout(async () => {
          await run();
        }, widgetSettings.updateTimeMinutes * 1000 * 60);
      }
    } catch (e) {
      const errorDescription = TreatError.getDescription(e);
      setError(errorDescription);
    } finally {
      setIsLoading(false);
    }
  }, [
    params,
    widgetSettings.equalColor,
    widgetSettings.negativeColor,
    widgetSettings.positiveColor,
    widgetSettings.query,
    widgetSettings.updateTimeMinutes,
  ]);

  useEffect(() => {
    run();

    return () => {
      clearTimeout(updateTimeRef.current);
    };
  }, [run, data]);

  useImperativeHandle(ref, () => {
    return {
      getData: () => {
        return info;
      },
    };
  });

  const onClick = async () => {
    await executeEvent({
      eventName: 'onClick',
      data: info,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <Container onClick={onClick}>
      <Number color={info.color}>
        {info.icon}
        <Value>{info.Value}</Value>
        {info.Unit && <Unit>{info.Unit}</Unit>}
      </Number>
      {info.AuxInfo && <Aux>{info.AuxInfo}</Aux>}
    </Container>
  );
}

export default forwardRef(Kpi);
