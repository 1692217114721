import { useRef, useState } from 'react';

function useStateCache(defaultValue) {
  const valueRef = useRef(defaultValue);

  const [, setState] = useState(defaultValue);

  const set = value => {
    let newValue = value;

    if (typeof value === 'function') {
      newValue = value(valueRef.current);
    }

    valueRef.current = newValue;

    setState(newValue);
  };

  const get = () => {
    return valueRef.current;
  };

  return [valueRef.current, set, get];
}

export default useStateCache;
