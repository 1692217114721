// import storage from 'redux-persist/lib/storage';
import storage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import crmPersists from '~/applications/CRM/store/crmPersists';
import { app_key } from '../config/application';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: app_key,
      storage,
      whitelist: [
        'authorizationToken',
        'companyToken',
        // 'authentication',
        // 'company',
        'log',
        'mainMenu',
        'pages',
        'uiDesign',
        // 'dataConnector',
        // 'user',
        // 'settings',
        // 'loading',
        // 'tasks',
        'inactivity',
        ...crmPersists,
      ],
    },
    reducers
  );

  return persistedReducer;
};
