import React, { useEffect, useState } from 'react';
import TemplateJSX from '~/easy-components/TemplateJSX';
import { Container, Content } from './styles';

function Tooltip({ onGetPosition, jsx, tooltipFieldName, settings, data }) {
  const [tooltip, setTooltip] = useState({ top: 0, left: 0, opacity: 0 });

  useEffect(() => {
    const position = onGetPosition();
    setTooltip({ ...position, opacity: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      style={{
        top: `${tooltip.top + 40}px`,
        left: `${tooltip.left}px`,
        opacity: tooltip.opacity,
      }}
    >
      {jsx ? (
        <TemplateJSX jsxString={jsx} auxScope={{ ...settings, data }} />
      ) : (
        <Content>{data[tooltipFieldName]}</Content>
      )}
    </Container>
  );
}

export default Tooltip;
