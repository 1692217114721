import { app_key } from '~/config/application';

class LocalStorage {
  setItem(key, value) {
    sessionStorage.setItem(`${app_key}_${key}`, value);
    // localStorage.setItem(`${app_key}_${key}`, value);
  }

  getItem(key, defaultValue) {
    const value = sessionStorage.getItem(`${app_key}_${key}`);
    // const value = localStorage.getItem(`${app_key}_${key}`);
    return value === null || value === undefined || value === ''
      ? defaultValue
      : value;
  }

  remove(key) {
    sessionStorage.removeItem(`${app_key}_${key}`);
  }
}

export default new LocalStorage();
