export default function getCoordinates(url) {
  const regex = /@(-?\d+(?:\.\d+)?),(-?\d+(?:\.\d+)?)/;
  const match = url.match(regex);

  if (match) {
    const latitude = parseFloat(match[1]);
    const longitude = parseFloat(match[2]);
    return { latitude, longitude };
  } else {
    return null;
  }
}
