import request from '~/applications/Request';

class EmailService {
  /**
   * @typedef {Object} Attachment
   * @property {String} fileName Nome
   * @property {String} sourcePath Caminho
   *
   * @typedef {Object} ReqBody
   * @property {string} from
   * @property {string} to
   * @property {string} [copy]
   * @property {string} subject
   * @property {string} body
   * @property {Record<string, string>} [data]
   * @property {Attachment[]} [attachments]
   * @property {string} [reportCode]
   * @property {string} [fileName]
   * @property {boolean} [uniqueFileName]
   *
   * @param {ReqBody} params
   */
  sendEmail({
    attachments,
    subject,
    body,
    copy,
    data,
    from,
    to,
    reportCode,
    fileName,
    uniqueFileName = true,
  }) {
    return request({
      url: 'APP/CRM/Emails',
      method: 'POST',
      largeData: {
        from,
        to,
        copy,
        subject,
        body,
        attachments,
        data,
        reportCode,
        fileName,
        uniqueFileName,
      },
    });
  }

  async getEmailsDistribuitionList() {
    return request({
      url: `APP/CRM/Emails/DistribuitionList`,
      method: 'GET',
    });
  }
}

export default new EmailService();
