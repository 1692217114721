/* eslint-disable react/prop-types */
import React from 'react';

import { Container } from './styles';

function LoadInformations({ t }) {
  return (
    <Container>
      <h1>{t('Wait')}</h1>
      <h2>{t('LoadingInformations')}</h2>
    </Container>
  );
}

export default LoadInformations;
