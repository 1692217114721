/* eslint-disable react/prop-types */
// @ts-check

import React, { useImperativeHandle, forwardRef } from 'react';
import Schedule from '~/easy-components/Schedule';

import useSchedule from '~/easy-components//hooks/useSchedule';
import { Container } from './styles';
import useCompany from '~/hooks/useCompany';

function SchedulePage(
  { settings, events, refresh, reportSettings, onRangeChange },
  ref
) {
  const { stringToDate } = useCompany();

  const {
    events: treatedEvents,
    onAddEvent,
    onSelectEvent,
    viewRange,
    updateRangeRef,
    onEventTemplate,
  } = useSchedule({
    clientEvents: reportSettings.events,
    applicationSettings: settings,
    events,
    refreshPage: refresh,
    eventTemplate: reportSettings.eventTemplate,
    stringToDate,
  });

  useImperativeHandle(ref, () => {
    return {
      beforeExecuteQuery: ({ params }) => {
        const { start, end } = viewRange;

        params.StartDate = start;
        params.EndDate = end;
      },
    };
  });

  const locale = localStorage.getItem('i18nextLng') || 'pt-BR';

  return (
    <Container>
      <Schedule
        events={treatedEvents}
        readOnly={reportSettings.readOnly}
        defaultView={reportSettings.defaultView}
        keyField={reportSettings.keyField}
        titleAccessor={reportSettings.displayField}
        startAccessor="StartDate"
        endAccessor="EndDate"
        startTimeAccessor="StartTime"
        endTimeAccessor="EndTime"
        onEventTemplate={onEventTemplate()}
        onAddEvent={onAddEvent}
        onSelectEvent={onSelectEvent}
        isDraggable={false}
        locale={locale}
        onRangeChange={props => {
          updateRangeRef(props);
          onRangeChange(props);
        }}
      />
    </Container>
  );
}

export default forwardRef(SchedulePage);
