import React, { useContext } from 'react';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Panel from '~/easy-components/Panel';
import Input from '~/easy-components/Form/Input';
import Linker from '~/easy-components/Form/Linker';

import PageContext from '../context';

function GeneralTab() {
  const { settings, formRef, isReadOnly, t } = useContext(PageContext);

  const fieldProps = {
    settings,
    formRef,
    readOnly: isReadOnly,
    labelWidth: 150,
  };

  return (
    <ColumnLayout>
      <Panel width="320px">
        <Input name="i" label="id" {...fieldProps} readOnly />
        <Input name="title" label={t('Title')} {...fieldProps} />
        <Linker
          name="type"
          valueField="type"
          label={t('Type')}
          {...fieldProps}
          fixedData={[
            { value: 'bar', label: t('Bar') },
            { value: 'line', label: t('Line') },
            { value: 'Pie', label: t('Pie') },
            { value: 'kpi', label: t('KPI') },
            { value: 'custom', label: t('Custom') },
            { value: 'information', label: t('Information') },
            // { value: 'report', label: t('Report') },
            { value: 'table', label: t('Table') },
            { value: 'speedometer', label: t('Speedometer') },
            { value: 'funnel', label: t('Funnel') },
            { value: 'doughnut', label: t('Doughnut') },
          ]}
        />
      </Panel>
    </ColumnLayout>
  );
}
export default GeneralTab;
