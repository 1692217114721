import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 1.5rem;
    font-weight: 400;
    color: #777;
  }

  h1 {
    color: #555;
    font-size: 2rem;
  }
`;
