/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
/* eslint-disable react/button-has-type */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  memo,
} from 'react';
import ModalHandle from '~/easy-components/ModalHandle';
import Button from '~/easy-components/Button';
import FlexSpace from '~/easy-components/FlexSpace';
import FooterPage from '~/easy-components/FooterPage';
import useLocale from '~/hooks/useLocale';
import useHelpers from '~/hooks/useHelpers';
import { Container } from './styles';
import Panel from './Panel';
import ViewButtons from './ViewButtons';

// import useCompany from '~/hooks/useCompany';

// eslint-disable-next-line no-unused-vars
function EventModal(_, ref) {
  // const { logRef } = useCompany();

  const t = useLocale('_Global');
  const panelRef = useRef();

  /* const {
    dynamicFunctionProps: { showModalQuery },
  } = useHelpers(); */

  const [state, setState] = useState({
    isShow: false,
    updated: null,
    auxIntelisenses: null,
  });

  const modalRef = useRef();
  const eventsRef = useRef();
  const runTypeRef = useRef();
  const confirmRef = useRef();
  const confirmGlobalFunctionsRef = useRef();
  const settingsRef = useRef();
  const titleRef = useRef('');
  const menuRef = useRef({});
  const querySettingRef = useRef();
  const codeRef = useRef();
  const viewButtonsRef = useRef();

  const {
    process,
    dynamicFunctionProps: { getApplicationSettings, logRef },
  } = useHelpers();

  useEffect(() => {
    if (state.isShow) {
      modalRef.current.handleOpen();
      setTimeout(() => {
        panelRef.current.codeFocus();
      }, 500);
    } else {
      modalRef.current.handleClose();
    }
  }, [state.isShow]);

  const createIntelisense = globalFunctions => {
    if (globalFunctions) {
      const intelliSense = [];

      intelliSense.push(`
        globalFunctions: {
      `);

      for (const propriedade in globalFunctions) {
        const functionName = propriedade;

        const descricao = `/** Essa é a função global ${functionName} */`;

        const assinatura = `${functionName}(): Promise<any>,`;

        intelliSense.push(descricao, assinatura);
      }

      intelliSense.push(`
        }
      `);

      return intelliSense.join('\n');
    }

    return null;
  };

  useImperativeHandle(ref, () => {
    return {
      setVisible: async visible => {
        modalRef.current.setVisible(visible);
      },
      show: async ({
        title,
        runType,
        events,
        onConfirm,
        menu,
        settings,
        onConfirmGlobalFunctions,
        code,
      }) => {
        await process(async () => {
          titleRef.current = title;

          confirmRef.current = onConfirm;

          confirmGlobalFunctionsRef.current = onConfirmGlobalFunctions;

          menuRef.current = menu;

          eventsRef.current = events;

          runTypeRef.current = menu.run || runType;

          settingsRef.current = settings;

          codeRef.current = code;

          const auxIntelisenses = createIntelisense(settings.globalFunctions);

          querySettingRef.current =
            (await getApplicationSettings({
              pageRoute: 'Queries',
            })) || {};

          setState({
            isShow: true,
            auxIntelisenses,
          });
        });
      },
      close: () => {
        setState({
          isShow: false,
        });
        logRef.current.removeEvent({
          title: titleRef.current,
          eventName: menuRef.current.prop,
        });
      },
    };
  });

  const onSave = async () => {
    const { events, globalFunctions, code } = panelRef.current.getValue();

    confirmRef.current({
      code,
      newEvents: events,
      globalFunctions,
      isClose: false,
    });
  };

  const onSaveAndClose = async () => {
    const { events, globalFunctions, code } = panelRef.current.getValue();

    confirmRef.current({
      code,
      newEvents: events,
      globalFunctions,
      isClose: true,
    });

    logRef.current.removeEvent({
      title: titleRef.current,
      eventName: menuRef.current.prop,
    });

    setState({
      isShow: false,
    });
  };

  const onKeyDown = event => {
    const char_S = 83;
    const char_Q = 81;
    const char_M = 77;
    const char_J = 74;
    const char_F = 70;
    const char_ESC = 27;

    if (event.ctrlKey) {
      switch (event.keyCode) {
        case char_S:
          event.stopPropagation();
          event.preventDefault();

          if (event.shiftKey) {
            onSaveAndClose();
          } else {
            onSave();
          }
          break;

        case char_M:
          modalRef.current.setVisible(false);
          break;

        case char_F:
          panelRef.current.showComponent('globalFunctions');
          viewButtonsRef.current.setViewType('globalFunctions');
          break;

        case char_Q:
          panelRef.current.showComponent('query');
          viewButtonsRef.current.setViewType('query');
          break;

        case char_J:
          panelRef.current.showComponent('code');
          viewButtonsRef.current.setViewType('code');
          break;

        default:
          break;
      }
    }

    switch (event.keyCode) {
      case char_ESC:
        setState({
          isShow: false,
        });
        logRef.current.removeEvent({
          title: titleRef.current,
          eventName: menuRef.current.prop,
        });
        break;

      default:
        break;
    }
  };

  const onSaveGlobalFunction = async () => {
    const { globalFunctions } = panelRef.current.getValue();
    confirmGlobalFunctionsRef.current(globalFunctions);
  };

  return (
    <ModalHandle
      ref={modalRef}
      contentStyle={{
        style: {
          width: '95%',
          height: '95%',
        },
      }}
      onClose={() => {
        setState({
          isShow: false,
        });
        // menuRef.current = {};
        logRef.current.removeEvent({
          title: titleRef.current,
          eventName: menuRef.current.prop,
        });
      }}
      onKeyDown={onKeyDown}
    >
      {state.isShow && (
        <>
          <Container>
            <Panel
              ref={panelRef}
              settings={settingsRef.current}
              events={eventsRef.current}
              runType={runTypeRef.current}
              eventName={menuRef.current.prop}
              menu={menuRef.current}
              querySettings={querySettingRef}
              title={`${titleRef.current} | ${menuRef.current.title}`}
              onKeyDown={onKeyDown}
              onSaveGlobalFunction={onSaveGlobalFunction}
              code={codeRef.current}
              auxIntelisenses={state.auxIntelisenses}
              onMinimalize={() => {
                modalRef.current.setVisible(false);
              }}
            />

            <FooterPage>
              <ViewButtons ref={viewButtonsRef} panelRef={panelRef} t={t} />
              <FlexSpace />
              <Button
                onClick={() => {
                  setState({
                    isShow: false,
                  });
                  // menuRef.current = {};
                  logRef.current.removeEvent({
                    title: titleRef.current,
                    eventName: menuRef.current.prop,
                  });
                }}
              >
                {t('Cancel')}
              </Button>
              <Button buttonType="Emphasized" onClick={onSave}>
                {t('Save')}
              </Button>
              <Button buttonType="Emphasized" onClick={onSaveAndClose}>
                {t('SaveAndClose')}
              </Button>
            </FooterPage>
          </Container>
        </>
      )}
    </ModalHandle>
  );
}

export default memo(forwardRef(EventModal));
