const createMenu = ({ columnIndex, column }) => {
  const menus = [
    {
      icon: 'LuTextCursorInput',
      title: 'AddCustomField',
      prop: 'userFields',
      type: 'newField',
      columnIndex,
      column,
    },
  ];

  return menus;
};

export default createMenu;
