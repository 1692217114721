/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import request from '../../Request';

class EmailTemplatesService {
  async get(hash) {
    return request({
      url: `APP/CRM/EmailTemplates/${hash}`,
    });
  }

  async list(filter, auxFilters) {
    let filters = '';

    for (const prop in auxFilters) {
      filters += `&${prop}=${
        auxFilters[prop] !== null && auxFilters[prop] !== undefined
          ? auxFilters[prop]
          : ''
      }`;
    }
    return request({
      url: `APP/CRM/EmailTemplates?$filter=${filter}${filters}`,
    });
  }

  async create({ hash, name, subject, body, settings, from, to, cc, co }) {
    const response = await request({
      url: `APP/CRM/EmailTemplates`,
      method: 'POST',
      data: {
        hash,
        name,
        subject,
        body,
        settings,
        from,
        to,
        cc,
        co,
      },
    });

    return response;
  }

  async update({ hash, name, subject, body, settings, from, to, cc, co }) {
    const response = await request({
      url: `APP/CRM/EmailTemplates/${hash}`,
      method: 'PUT',
      data: {
        name,
        subject,
        body,
        settings,
        from,
        to,
        cc,
        co,
      },
    });

    return response;
  }

  async createOrUpdate({
    hash,
    name,
    subject,
    body,
    settings,
    from,
    to,
    cc,
    co,
  }) {
    if (hash === '' || hash === null || hash === undefined) {
      return this.create({
        hash,
        name,
        subject,
        body,
        settings,
        from,
        to,
        cc,
        co,
      });
    }
    return this.update({
      hash,
      name,
      subject,
      body,
      settings,
      from,
      to,
      cc,
      co,
    });
  }

  async delete(id) {
    return request({
      url: `APP/CRM/EmailTemplates/${id}`,
      method: 'DELETE',
    });
  }
}

export default new EmailTemplatesService();
