/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
} from 'react';

import QueryService from '~/services/QueryService';
import TreatError from '~/easy-components/TreatError';

import { Container, Number, Value, Aux } from './styles';
import Error from '../Error';
import Loader from '../Loader';

function Information({ data, widgetSettings, params, executeEvent }, ref) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState({});
  const updateTimeRef = useRef();

  const run = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await QueryService.execute(
        1,
        widgetSettings.query,
        params
      );

      if (response.length > 0) {
        const line = response[0];

        const color = widgetSettings.color || '#ADADAD';

        setInfo({
          color,
          ...line,
        });
      } else {
        setInfo({});
      }

      if (widgetSettings.updateTimeMinutes) {
        updateTimeRef.current = setTimeout(async () => {
          await run();
        }, widgetSettings.updateTimeMinutes * 1000 * 60);
      }
    } catch (e) {
      const errorDescription = TreatError.getDescription(e);
      setError(errorDescription);
    } finally {
      setIsLoading(false);
    }
  }, [
    params,
    widgetSettings.color,
    widgetSettings.query,
    widgetSettings.updateTimeMinutes,
  ]);

  useEffect(() => {
    run();

    return () => {
      clearTimeout(updateTimeRef.current);
    };
  }, [run, data]);

  useImperativeHandle(ref, () => {
    return {
      getData: () => {
        return info;
      },
    };
  });

  const onClick = async () => {
    await executeEvent({
      eventName: 'onClick',
      data: info,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <Container onClick={onClick}>
      <Number color={info.color}>
        <Value style={widgetSettings.style}>{info.Value}</Value>
      </Number>
      {info.AuxInfo && <Aux>{info.AuxInfo}</Aux>}
    </Container>
  );
}

export default forwardRef(Information);
