/* eslint-disable no-use-before-define */
/* eslint-disable valid-typeof */
/* eslint-disable no-loop-func */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import Icon from '~/easy-components/Icon';
import useLocale from '~/hooks/useLocale';
import ReactTooltip from 'react-tooltip';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Gantt } from 'gantt-task-react';
import { useField } from '@unform/core';
import ErrorBoundary from '~/easy-components/ErrorBoundary';
import { sendEvent } from '~/easy-components/HandlerEvent';
import {
  createSyncFunctionByString,
  createAsyncFunctionByString,
} from '../../AsyncFunctionString';
import {
  Container,
  Header,
  Body,
  ElementHeader,
  Element,
  RenderText,
  Empty,
  ContentHeader,
  Actions,
  ButtonIcon,
} from './styles';
import Tooltip from './tooltip';
import ElementView from './ElementView';
import ViewMode from './ViewMode';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'gantt-task-react/dist/index.css';

const isHidden = (item, fatherCollapsedList, data) => {
  if (!item) return false;
  if (!item.father) return false;

  const fatherFilter = fatherCollapsedList.find(
    father => father.id === item.father
  );
  if (fatherFilter) return true;

  const parent = data.find(parentItem => parentItem.id === item.father);
  return isHidden(parent, fatherCollapsedList, data);
};

function GanttElement({ name, settings, formRef, tasks, columns, auxScope }) {
  const t = useLocale('_Global');

  const elementRef = useRef();

  const [data, setData] = useState(tasks || []);
  const [filteredData, setFilteredData] = useState([]);
  const [fatherCollapsedList, setFatherCollapsedList] = useState([]);
  const tooltipRef = useRef({ top: null, left: null });

  const { components, fields } = settings || {};

  let selfField = null;

  if (components) {
    selfField = components.find(
      component =>
        component.type === 'tabGantt' &&
        component.fieldName.toUpperCase() === name.toUpperCase()
    );
  }

  if (!selfField) {
    selfField = (fields ? fields.find(f => f.name === name) : {}) || {};
  }

  const [viewMode, setViewMode] = useState(selfField.defaultView || 'Day');

  const onClick = async ({ col, line }) => {
    if (line.type === 'folder' && (col.treeView === 'true' || col.treeView)) {
      const fatherCollapsed = fatherCollapsedList.find(
        item => item.id === line.id
      );

      if (fatherCollapsed) {
        setFatherCollapsedList(
          fatherCollapsedList.filter(item => item.id !== line.id)
        );
      } else {
        setFatherCollapsedList([...fatherCollapsedList, line]);
      }
    }

    if (col.onClick) {
      const dynamic = createAsyncFunctionByString({
        functionString: col.onClick,
      });

      await dynamic({
        ...settings.dynamicFunctionProps,
        form: formRef.current,
        formData: formRef.current ? formRef.current.getData() : null,
        data: {
          line,
          value: line[col.name],
          col: col.name,
        },
        ...newAuxScope,
      });
    }
  };

  const onContextClick = async ({ col, line, event }) => {
    if (col.onContextMenu) {
      event.preventDefault();
      event.stopPropagation();

      const dynamic = createAsyncFunctionByString({
        functionString: col.onContextMenu,
      });

      await dynamic({
        ...settings.dynamicFunctionProps,
        form: formRef.current,
        formData: formRef.current ? formRef.current.getData() : null,
        data: {
          event,
          line,
          value: line[col.name],
          col: col.name,
        },
        ...newAuxScope,
      });
    }
  };

  const getCellStyle = ({ col, line }) => {
    if (col.cellStyle) {
      const dynamic = createSyncFunctionByString({
        functionString: col.cellStyle,
      });

      const response = dynamic({ col, line });
      return response;
    }
    return null;
  };

  const getIconName = ({ line }) => {
    const iconName = line.type === 'folder' ? 'FaFolder' : 'BiTask';

    switch (line.type) {
      case 'folder':
        return iconName;

      case 'milestone':
        return 'BsDiamondFill';

      default:
        return 'BiTask';
    }
  };

  const renderElement = ({ line, col, paddingLeft }) => {
    // const colStyle = col.cellStyle ? col.cellStyle({ col, line }) : {};
    const colStyle = getCellStyle({ col, line });

    if (col.treeView === 'true' || col.treeView === true) {
      let iconName = getIconName({ line });

      if (line.type === 'folder') {
        const elementCollapsed = fatherCollapsedList.find(
          item => item.id === line.id
        );

        iconName = elementCollapsed ? 'FaFolder' : 'FaFolderOpen';
      }

      return (
        <RenderText
          style={{
            backgroundColor: line.type === 'folder' ? '#e8eff3' : '#ffffff',
            ...colStyle,
            cursor: col.onClick ? 'pointer' : 'default',
            paddingLeft,
            fontWeight: line.type === 'folder' ? 'bold' : 'normal',
          }}
          onClick={event => onClick({ line, col, event })}
          onContextMenu={event => {
            onContextClick({ line, col, event });
          }}
        >
          <Icon
            size={22}
            color="#a7cee7"
            name={iconName}
            style={{ marginRight: '10px', marginLeft: '5px' }}
          />
          {line[col.name]}
        </RenderText>
      );
    }

    switch (col.type) {
      default:
        return (
          <ElementView
            line={line}
            col={col}
            colStyle={colStyle}
            onClick={onClick}
            onContextMenu={onContextClick}
          />
        );
    }
  };

  const { fieldName, defaultValue = '', registerField } = useField(name);

  const getStyleColors = item => {
    switch (item.type) {
      case 'folder':
        return {
          backgroundColor: item.backgroundColor || '#e0e0e0',
          progressColor: item.progressColor || '#449dc1',
          backgroundSelectedColor: item.backgroundSelectedColor || '#b0b0b0',
          progressSelectedColor: item.progressSelectedColor || '#449dc1',
        };

      case 'milestone':
        return {
          backgroundColor: item.backgroundColor || '#449dc1',
          backgroundSelectedColor: item.backgroundSelectedColor || '#3e6c7b',
        };

      default:
        return {
          backgroundColor: item.backgroundColor || '#e0e0e0',
          progressColor: item.progressColor || '#a7cee7',
          backgroundSelectedColor: item.backgroundSelectedColor || '#b0b0b0',
          progressSelectedColor: item.progressSelectedColor || '#3e6c7b',
        };
    }
  };

  const formatDependencies = dependencies => {
    if (!dependencies) return [];

    if (typeof dependencies === 'string') {
      return dependencies.split(',').map(item => item.trim());
    }

    return dependencies;
  };

  const formatListData = useCallback(listData => {
    const newValue = listData.map(item => {
      const styles = getStyleColors(item);

      const dependencies = formatDependencies(item.dependencies);

      return {
        ...item,
        start: typeof item.start === 'date' ? item.start : new Date(item.start),
        end: typeof item.end === 'date' ? item.end : new Date(item.end),
        dependencies,
        styles,
      };
    });

    return newValue;
  }, []);

  const onExpandAll = async () => {
    setFatherCollapsedList([]);
  };

  const onCollapseAll = async () => {
    setFatherCollapsedList(data);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: elementRef.current,
      path: 'value',
      clearValue: el => {
        el.value = null;
        setData([]);
        // dataRef.current = [];
      },
      setValue: async (r, v) => {
        r.value = v;

        if (v) {
          const list = typeof v === 'string' ? JSON.parse(v) : v;
          const newValue = formatListData(list);

          setData(newValue);
          // dataRef.current = list;
        } else {
          setData([]);
          // dataRef.current = [];
        }
      },
      getValue: () => {
        // return dataRef.current;
        return data;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, data]);

  useEffect(() => {
    const filtered = data.filter(
      item => !isHidden(item, fatherCollapsedList, data)
    );
    setFilteredData(filtered);
  }, [data, fatherCollapsedList]);

  const handleSetData = useCallback(
    newData => {
      const formattedData = formatListData(newData || []);
      setData(formattedData);
    },
    [formatListData]
  );

  const handleAddItem = useCallback(
    (targetId, newItem) => {
      const listClone = JSON.parse(JSON.stringify(data));

      const index = data.findIndex(item => item.id === targetId);
      if (index === -1) {
        // TODO: Traduzir
        throw new Error('TargetId not found');
      }

      listClone.splice(index + 1, 0, newItem);

      const formattedData = formatListData(listClone || []);

      setData(formattedData);
    },
    [data, formatListData]
  );

  const handlerUpdateItem = useCallback(
    (id, newItem) => {
      const listClone = JSON.parse(JSON.stringify(data));

      const index = data.findIndex(item => item.id === id);
      if (index === -1) {
        // TODO: Traduzir
        throw new Error('Id not found');
      }

      listClone[index] = newItem;

      const formattedData = formatListData(listClone || []);

      setData(formattedData);
    },
    [data, formatListData]
  );

  const handlerRemoveItem = useCallback(
    id => {
      const newList = data.filter(item => item.id !== id);

      const formattedData = formatListData(newList || []);

      setData(formattedData);
    },
    [data, formatListData]
  );

  const handleChangeViewMode = useCallback(mode => {
    setViewMode(mode);
  }, []);

  /* useEffect(() => {
    elementRef.current.changeViewMode = mode => {
      setViewMode(mode);
    };

    elementRef.current.setData = newData => {
      const formattedData = formatListData(newData || []);
      setData(formattedData);
    };

    elementRef.current.updateItem = (id, newItem) => {
      const listClone = JSON.parse(JSON.stringify(data));

      const index = data.findIndex(item => item.id === id);
      if (index === -1) {
        // TODO: Traduzir
        throw new Error('Id not found');
      }

      listClone[index] = newItem;

      const formattedData = formatListData(listClone || []);

      setData(formattedData);
    };

    elementRef.current.addItem = (targetId, newItem) => {
      const listClone = JSON.parse(JSON.stringify(data));

      const index = data.findIndex(item => item.id === targetId);
      if (index === -1) {
        // TODO: Traduzir
        throw new Error('TargetId not found');
      }

      listClone.splice(index + 1, 0, newItem);

      const formattedData = formatListData(listClone || []);

      setData(formattedData);
    };

    elementRef.current.removeItem = id => {
      const newList = data.filter(item => item.id !== id);

      const formattedData = formatListData(newList || []);

      setData(formattedData);
    };
  }, [data, formatListData]); */

  useEffect(() => {
    elementRef.current.changeViewMode = handleChangeViewMode;
    elementRef.current.setData = handleSetData;
    elementRef.current.updateItem = handlerUpdateItem;
    elementRef.current.addItem = handleAddItem;
    elementRef.current.removeItem = handlerRemoveItem;
  }, [
    formatListData,
    handleAddItem,
    handleChangeViewMode,
    handleSetData,
    handlerRemoveItem,
    handlerUpdateItem,
  ]);

  const getDepth = (item, elementData) => {
    let depth = 0;
    let current = item;

    while (current && current.father) {
      depth++;
      current = elementData.find(
        parentItem => parentItem.id === current.father
      );
    }

    return depth;
  };

  const ganttColumns = selfField.columns || columns || [];

  const locale = localStorage.getItem('i18nextLng') || 'pt-BR';

  const newAuxScope = {
    ...auxScope,
    element: {
      getData: () => data,
      changeViewMode: handleChangeViewMode,
      setData: handleSetData,
      updateItem: handlerUpdateItem,
      addItem: handleAddItem,
      removeItem: handlerRemoveItem,
    },
  };

  return (
    <ErrorBoundary>
      <Container
        onMouseOver={event => {
          // const tooltipX = event.screenX; // Ajuste conforme necessário
          // const tooltipY = event.screenY;
          const tooltipX = event.clientX;
          const tooltipY = event.clientY;

          tooltipRef.current = {
            top: tooltipY,
            left: tooltipX,
          };
        }}
      >
        <input
          hidden
          type="hidden"
          ref={elementRef}
          id={fieldName}
          defaultValue={defaultValue}
        />
        <ContentHeader>
          <Actions>
            <ButtonIcon
              data-tip={t('ExpandAll')}
              data-for="GanttTooltips"
              onClick={onExpandAll}
            >
              <Icon
                size={22}
                color="#a7cee7"
                name="BsArrowsExpand"
                style={{
                  cursor: 'pointer',
                }}
              />
            </ButtonIcon>
            <ButtonIcon
              data-tip={t('CollapseAll')}
              data-for="GanttTooltips"
              onClick={onCollapseAll}
            >
              <Icon
                size={22}
                color="#a7cee7"
                name="BsArrowsCollapse"
                style={{
                  cursor: 'pointer',
                }}
              />
            </ButtonIcon>
          </Actions>
          <ViewMode
            views={selfField.views}
            selectedView={viewMode}
            onChange={view => {
              setViewMode(view);
            }}
          />
        </ContentHeader>
        {filteredData.length === 0 ? (
          <Empty>{t('Empty')}</Empty>
        ) : (
          <Gantt
            tasks={filteredData}
            locale={locale}
            viewMode={viewMode}
            columnWidth={90}
            TooltipContent={({ task }) => {
              return (
                <Tooltip
                  settings={settings}
                  data={task}
                  jsx={selfField.tooltipJsx}
                  tooltipFieldName={selfField.tooltipFieldName}
                  onGetPosition={() => {
                    return tooltipRef.current;
                  }}
                />
              );
            }}
            onClick={async task => {
              await sendEvent({
                settings,
                events: selfField.events,
                eventName: 'onTaskClick',
                formRef,
                run: 'after',
                data: task,
                newAuxScope,
              });
            }}
            TaskListHeader={tast => {
              return (
                <Header>
                  {ganttColumns.map(col => {
                    return (
                      <ElementHeader
                        key={col.name}
                        style={{
                          height: `${tast.headerHeight}px`,
                          width: col.width,
                        }}
                      >
                        {col.title}
                      </ElementHeader>
                    );
                  })}
                </Header>
              );
            }}
            TaskListTable={tasList => {
              return tasList.tasks.map((line, idx) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Body key={`${line.id}-${idx}`}>
                    {ganttColumns.map(col => {
                      let paddingLeft = '10px';

                      if (col.treeView === 'true' || col.treeView === true) {
                        const depth = getDepth(line, data);

                        paddingLeft = `${depth * 30 + 10}px`;
                      }

                      return (
                        <Element
                          key={col.name}
                          style={{
                            height: `${tasList.rowHeight}px`,
                            width: col.width,
                          }}
                        >
                          {renderElement({ line, col, paddingLeft })}
                        </Element>
                      );
                    })}
                  </Body>
                );
              });
            }}
            onDateChange={async task => {
              await sendEvent({
                settings,
                events: selfField.events,
                eventName: 'onDateChange',
                formRef,
                run: 'after',
                data: task,
                newAuxScope,
              });
            }}
            onProgressChange={async task => {
              await sendEvent({
                settings,
                events: selfField.events,
                eventName: 'onProgressChange',
                formRef,
                run: 'after',
                data: task,
                newAuxScope,
              });
            }}
          />
        )}
      </Container>
      <ReactTooltip
        id="GanttTooltips"
        place="bottom"
        type="light"
        effect="solid"
        className="toolbarTooltip"
        delayShow={1000}
      />
    </ErrorBoundary>
  );
}

export default GanttElement;
