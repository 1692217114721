import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 0 auto;
`;

export const Panel = styled.div`
  display: flex;
  flex: 1;
`;

export const TitleKanban = styled.div`
  display: flex;
  flex: 1;
  max-width: 42%;
  align-items: center;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 500px) {
    max-width: 270px;
  }
`;
