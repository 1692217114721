import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    > div {
      display: flex;
      flex-direction: row;

      svg {
        cursor: pointer;
      }
    }

    h1 {
      color: #afbcbe;
      font-weight: 500;
    }
  }

  aside {
    flex: 1;
    display: flex;
  }
`;
