/* eslint-disable import/no-cycle */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

import HeaderPage from '~/easy-components/HeaderPage';
import colors from '~/styles/colors';
import useLocale from '~/hooks/useLocale';
import { Container, Content, Code, Empty } from './styles';
import List from './List';
import MonacoEditor from '~/easy-components/Editor';
import Icon from '~/easy-components/Icon';
import toast from '~/easy-components/Toast';
import ConfirmDialog from '~/easy-components/ConfirmDialog';

function GlobalFunctions({ componentView, settings, onSave }, ref) {
  const t = useLocale('_Global');

  const listRef = useRef();
  const monacoRef = useRef();

  const [value, setValue] = useState('');
  const [selectedFunction, setSelectedFunction] = useState({});
  const [globalFunctions, setGlobalFunctions] = useState([]);

  const onSelectedItem = selectedItem => {
    setValue(selectedItem.handler);
    setSelectedFunction(selectedItem);
    setTimeout(() => {
      monacoRef.current.focus();
    }, 300);
  };

  useEffect(() => {
    if (settings.globalFunctions) {
      const myFunctions = Object.entries(settings.globalFunctions).map(
        ([name, handler]) => {
          return { name, handler };
        }
      );
      setGlobalFunctions(myFunctions);
    }
  }, [settings.globalFunctions]);

  useImperativeHandle(ref, () => ({
    getValue: () => {
      const response = listRef.current.getValue();

      const globalFuncs = response.reduce((acc, { name, handler }) => {
        acc[name] = handler;
        return acc;
      }, {});

      return globalFuncs;
    },
  }));

  const handleSave = async () => {
    const globalFunctionIndex = globalFunctions.findIndex(
      f => f.name === selectedFunction.name
    );

    const newFunction = {
      name: selectedFunction.name,
      handler: value,
    };

    const newGlobalFunctions = [...globalFunctions];

    newGlobalFunctions[globalFunctionIndex] = newFunction;

    setGlobalFunctions(newGlobalFunctions);

    setTimeout(async () => {
      await onSave();

      toast.success(t('message.MessageSavedSuccessfully'));
    }, 300);
  };

  return (
    <Container componentView={componentView}>
      <HeaderPage
        backgroundColor={colors.headerBg}
        color={colors.headerTitle}
        hideMainMenu
      >
        {t('GlobalFunctions')}
      </HeaderPage>

      <Content>
        <List
          ref={listRef}
          selectedFunctionName={selectedFunction.name}
          onSelectedItem={onSelectedItem}
          globalFunctions={globalFunctions}
          onAdd={newFunction => {
            setGlobalFunctions(old => {
              return [...old, newFunction];
            });

            setSelectedFunction(newFunction);
          }}
          onDelete={async item => {
            ConfirmDialog({
              title: t('Delete'),
              message: t('message.DeleteMessageQuestion'),
              buttons: [
                {
                  label: t('Yes'),
                  onClick: () => {
                    const newGlobalFunctions = globalFunctions.filter(
                      f => f.name !== item.name
                    );

                    if (item.name === selectedFunction.name) {
                      setSelectedFunction({});
                    }

                    setGlobalFunctions(newGlobalFunctions);

                    setTimeout(async () => {
                      await onSave();

                      toast.success(t('message.SystemMessageSuccess'));
                    });
                  },
                },
                {
                  label: t('No'),
                },
              ],
            });
          }}
        />
        <Code>
          {selectedFunction.name ? (
            <MonacoEditor
              ref={monacoRef}
              tbar={[
                <div style={{ flex: 1 }} />,
                <Icon
                  name="MdSave"
                  size={24}
                  color="#fff"
                  onClick={handleSave}
                />,
              ]}
              language="javascript"
              value={value || ''}
              onChange={setValue}
              onCtrlS={handleSave}
            />
          ) : (
            <Empty>{t('message.SelectFunction')}</Empty>
          )}
        </Code>
      </Content>
    </Container>
  );
}

export default forwardRef(GlobalFunctions);
