import React from 'react';

import { Container } from './styles';

function QAInformation() {
  const isQA =
    process.env.REACT_APP_QA_NAME !== undefined &&
    process.env.REACT_APP_QA_NAME !== '';

  return (
    <Container isQA={isQA}>
      <b>Você está no ambiente de testes {process.env.REACT_APP_QA_NAME}</b>
      <br /> Os dados das aplicações, configurações, relatórios entre outros,
      são independentes do ambiente de produção.
    </Container>
  );
}

export default QAInformation;
