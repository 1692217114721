import styled from 'styled-components';

export const HeaderCell = styled.th`
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #555;
  height: 40px;

  text-align: ${props => (props.contentType === 'number' ? 'right' : 'left')};

  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
`;

export const ColumnCell = styled.td`
  color: #555;
  height: 40px;

  text-align: ${props => (props.contentType === 'number' ? 'right' : 'left')};
  background-color: transparent;

  input {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 10px 20px;

    text-align: right;
    cursor: initial;
  }

  padding-left: 15px;
  padding-right: 15px;
  &:last-child {
    padding-right: ${props => (props.input ? '0' : '15px')};
    & input {
      padding-right: 15px;
    }
  }
`;
