/* eslint-disable no-multi-assign */
const INITIAL_STATE = [];

function updateField(state, pageId, field, value) {
  // eslint-disable-next-line no-case-declarations
  const idx = state.findIndex(
    pageInfo => pageInfo.pageId.toUpperCase() === (pageId || '').toUpperCase()
  );

  if (idx >= 0) {
    state[idx][field] = value;
  } else {
    const pageSettings = {
      pageId: pageId.toUpperCase(),
    };

    pageSettings[field] = value;
    state.push(pageSettings);
  }

  return [...state];
}

export default function pages(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'DELETE_CACHE':
      return [...state.filter(page => page.id !== action.pageId)];
    case 'COMPANY_SELECTED':
    case 'LOGOFF_SUCCESS':
      return [...INITIAL_STATE];

    case 'CLEAR_SETTINGS':
      return updateField(state, action.payload.pageId, 'settings', null);

    case 'SPLIT_OPEN_EXTERNAL_DATA':
      updateField(
        state,
        action.payload.pageId,
        'externalData',
        action.payload.externalData
      );

      return updateField(
        state,
        action.payload.pageId,
        'settings',
        action.payload.settings
      );

    case 'SPLIT_REFRESH_PAGE':
      return updateField(
        state,
        action.payload.pageId,
        'lastDateUpdated',
        action.payload.lastDateUpdated
      );

    case 'SPLIT_SELECTED_ITEM':
      return updateField(
        state,
        action.payload.pageId,
        'selectedItem',
        action.payload.selectedItem
      );

    case 'SPLIT_OPEN_SIDE':
      return updateField(
        state,
        action.payload.pageId,
        'isSideOpen',
        action.payload.isSideOpen
      );

    case 'SAVE_CACHE':
      // eslint-disable-next-line no-case-declarations
      let newState = updateField(
        state,
        action.payload.pageId,
        'data',
        action.payload.data
      );

      newState = updateField(
        newState,
        action.payload.pageId,
        'edited',
        action.payload.edited
      );

      return updateField(newState, action.payload.pageId, 'externalData', null);

    default:
      return state;
  }
}
