// @ts-check

import React from 'react';
import PageHeader from '../../../PageHeader';

// import { Container } from './styles';

/**
 * @param {object} props
 * @param {Object} props.reportSettings
 * @param {string} props.reportSettings.title
 * @param {string} props.reportSettings.translateKey
 * @param {Object} props.reportSettings.translateRoute
 * @param {boolean} props.reportSettings.showTitle
 * @param {(props: {title: string}) => React.ReactNode} props.RenderProp
 * @param {(key: string, route: Object) => string} props.t
 */
function Header({ t, reportSettings, RenderProp = null }) {
  const title = reportSettings.translateKey
    ? t(reportSettings.translateKey, reportSettings.translateRoute)
    : reportSettings.title;

  if (RenderProp) {
    return <RenderProp title={title} />;
  }

  if (!reportSettings.showTitle) {
    return null;
  }

  return <PageHeader>{title}</PageHeader>;
}

export default Header;
