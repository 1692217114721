/* eslint-disable import/no-cycle */
import request from '~/applications/Request';

export default class AttachmentService {
  async getToken({
    sourcePath,
    fileName,
    url = 'APP/CRM/Attachments/generateURL',
  }) {
    const response = await request({
      method: 'POST',
      url,
      data: {
        sourcePath,
        fileName,
      },
    });

    return response.token;
  }

  uploadFile({ fileData, url = 'APP/CRM/Attachments/File' }) {
    return request({
      method: 'POST',
      url,
      data: {
        fileName: fileData.name,
      },
      file: fileData,
    });
  }

  deleteFile({ url }) {
    return request({
      method: 'DELETE',
      url,
    });
  }
}
