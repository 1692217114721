import styled from 'styled-components';

export const Container = styled.div`
  border-left: ${props => (props.hasDivision ? '1px solid #e5e5e5' : 'none')};
  display: flex;
  margin-left: 10px;
  padding-left: 10px;
`;

export const ClickableArea = styled.button`
  outline: none;
  border: none;
  cursor: pointer;

  div,
  svg {
    cursor: pointer !important;
  }

  &:last-child {
    margin-right: 0;
  }
`;
