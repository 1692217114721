/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import useLocale from '~/hooks/useLocale';

import { Container, Group, Tile, GroupContainer, TileTitle } from './styles';

export default function Dashboard({ tileGroups, onClick, color1, color2 }) {
  const tmenu = useLocale('MainMenu');

  return (
    <Container color1={color1} color2={color2}>
      {tileGroups.map(group => {
        if (group.items.length === 0) return null;

        return (
          <Group key={group.name} color={color2}>
            <h1>{group.title || tmenu(group.name)}</h1>
            <GroupContainer>
              {group.items.map((tile, idx) => {
                return (
                  <Tile
                    color={color2}
                    key={`${group.name}-${tile.id}-${idx}`}
                    onClick={() => {
                      onClick({ group, tile });
                    }}
                  >
                    <TileTitle>{tile.name}</TileTitle>
                    {tile.icon && (
                      <Icon name={tile.icon} size={36} color={color2} />
                    )}
                  </Tile>
                );
              })}
            </GroupContainer>
          </Group>
        );
      })}
    </Container>
  );
}

Dashboard.propTypes = {
  tileGroups: PropTypes.arrayOf(PropTypes.shape()),
  onClick: PropTypes.func.isRequired,
  color1: PropTypes.string,
  color2: PropTypes.string,
};

Dashboard.defaultProps = {
  color1: '#55a5ad',
  color2: '#316c89',
  tileGroups: [],
};
