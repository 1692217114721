import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;

  textarea {
    resize: none;
    color: #f0f0f0;
    padding: 10px 10px;
    font-size: 1.3rem;
    height: 200px;
    width: 300px;
    background-color: #fff;

    &:focus-within {
      background-color: ${props => props.backgroundColor || '#555'};
    }

    transition: all 1s;

    @media (max-width: 500px) {
      height: 100%;
      width: 100%;
      border: none;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const WaitingReader = styled.div`
  position: absolute;
  background-color: #5e727d99;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Message = styled.div`
  color: #fff;
  animation: animate 1.5s linear infinite;
  font-size: 1.4rem;
  text-align: center;

  @keyframes animate {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
`;

export const TouchHere = styled.div`
  color: #f00;
  font-size: 2rem;
`;
