/* eslint-disable import/no-cycle */
/* eslint-disable no-new-func */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import useUiDesigner from '~/hooks/useUiDesigner';
import useFieldBase from '~/hooks/useField';
import { Label, TextArea, Container } from './styles';
import UserField from '../UserField';
import { sendEvent } from '../../HandlerEvent';

function TextAreaElement({
  baseName,
  name,
  label,
  hidden,
  formRef,
  mainFormRef,
  settings,
  readOnly: readOnlyProp,
  onChange,
  fieldDefaultValue,
  isUserField,
  customTabName,
  required = false,
  labelStyle,
  ...rest
}) {
  const changed = useRef(false);

  const { selfField, viewMode, showContextMenu } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'field',
    settings,
    baseName,
    name,
    title: label,
    isUserField,
    customTabName,
  });

  delete selfField.type;

  const inputRef = useRef(null);

  // const fieldTitle = selfField.title || label;

  const {
    fieldName,
    defaultValue = fieldDefaultValue,
    registerField,
    // error,
  } = useField(name);

  const { isHidden, isReadOnly } = useFieldBase({
    hidden,
    readOnly: readOnlyProp,
    required,
    selfField,
    formRef,
    inputRef,
    getLogData: () => {
      return {
        baseName,
        name: fieldName,
        value: inputRef.current.value,
      };
    },
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (el, v) => {
        const textValue = v === null || v === undefined ? '' : v;
        el.value = textValue;
      },
      getValue: el => {
        return el.value === '' ? null : el.value;
      },
    });
  }, []);

  const handlerOnChange = async params => {
    const response = await sendEvent({
      settings,
      eventName: 'onchange',
      params,
      run: params.run,
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
    });

    return response || params.value;
  };

  async function onBlur(params) {
    params.persist();

    if (changed.current || params.target.changed) {
      changed.current = false;

      let val = params.target.value;

      const beforeValue = await handlerOnChange({
        ...params,
        run: 'before',
      });

      if (beforeValue !== undefined) val = beforeValue;

      params.target.value = val;

      await onChange(params);

      val = params.target.value;

      const afterValue = await handlerOnChange({ ...params, run: 'after' });
      if (afterValue !== undefined) val = afterValue;

      inputRef.current.value = val;
    }
  }

  const onChanged = async e => {
    await onBlur(e);
  };

  function onFocus() {
    const text = inputRef.current.value;
    inputRef.current.setSelectionRange(0, text.length);
  }

  useEffect(() => {
    inputRef.current.addEventListener('changed', onChanged);
    inputRef.current.addEventListener('focus', onFocus);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (inputRef.current && inputRef.current.removeEventListener)
        inputRef.current.removeEventListener('changed', onChanged);
    };
  }, [selfField]);

  const fieldTitle = selfField.title || label;

  return (
    <>
      <Container
        hidden={isHidden}
        viewMode={!hidden || isUserField ? viewMode : 'normal'}
        onContextMenu={event => showContextMenu({ event })}
      >
        {fieldTitle && <Label style={labelStyle}>{label}</Label>}
        <TextArea
          wrap="soft"
          ref={inputRef}
          defaultValue={defaultValue}
          {...selfField}
          {...rest}
          hidden={false}
          style={{ resize: 'none' }}
          readOnly={isReadOnly}
          onBlur={onBlur}
          onChange={() => {
            changed.current = true;
          }}
        />
      </Container>
      <UserField
        baseName={baseName}
        fieldName={fieldName}
        formRef={formRef}
        mainFormRef={mainFormRef}
        settings={settings}
      />
    </>
  );
}

TextAreaElement.propTypes = {
  formRef: PropTypes.shape(),
  onChange: PropTypes.func,
  baseName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hidden: PropTypes.bool,
  settings: PropTypes.shape(),
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  fieldDefaultValue: PropTypes.string,
  mainFormRef: PropTypes.shape(),
};

TextAreaElement.defaultProps = {
  formRef: null,
  baseName: null,
  settings: null,
  onChange: val => {
    return val;
  },
  label: null,
  labelWidth: null,
  readOnly: false,
  hidden: false,
  fieldDefaultValue: '',
  mainFormRef: {},
};

/** @type {React.MemoExoticComponent} */
const TextAreaComponent = memo(TextAreaElement);

export default TextAreaComponent;
