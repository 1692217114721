/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, {
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import ErrorInformation from './ErrorInformation';
import SettingsField from './SettingsField';
import { Container } from './styles';

function FormElement(
  {
    enableAuxSettings = false,
    errors,
    data,
    children,
    translateRoute,
    onOpen = () => {},
    setPageReadOnly = () => {},
    ...rest
  },
  ref
) {
  const unformRef = useRef();
  const buttonsRefs = useRef([]);
  const [, setRefreshDate] = useState(null);

  useEffect(() => {
    unformRef.current.reset();
    unformRef.current.setData(data || {});
  }, [data]);

  useEffect(() => {
    unformRef.current.setErrors(errors || {});
  }, [errors]);

  useImperativeHandle(ref, () => {
    return {
      ...unformRef.current,
      setPageReadOnly: props => {
        setPageReadOnly(props);
      },
      addButton: button => {
        buttonsRefs.current.push(button);
      },
      getButton: buttonName => {
        const buttonRef = buttonsRefs.current.find(
          button => button.name === buttonName
        );
        return buttonRef ? buttonRef.element : null;
      },
      refresh: () => {
        setRefreshDate(new Date());
      },
    };
  });

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <>
      <Container ref={unformRef} {...rest} initialData={data}>
        {enableAuxSettings && <SettingsField name="_auxSettings" />}
        {children}
      </Container>
      <ErrorInformation errors={errors} translateRoute={translateRoute} />
    </>
  );
}
export default forwardRef(FormElement);
