import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSyncFunctionByString } from '~/easy-components/AsyncFunctionString';
import { sendData } from '~/store/modules/log/actions';
import useStateCache from './useStateCache';

function useField({
  hidden,
  readOnly,
  required,
  selfField,
  formRef,
  inputRef,
  name,
  getLogData = () => {},
}) {
  const dispatch = useDispatch();

  const [isHidden, setIsHidden, getIsHidden] = useStateCache(null);
  const [isReadOnly, setIsReadOnly, getIsReadOnly] = useStateCache(null);
  const [isRequired, setIsRequired, getIsRequired] = useStateCache(null);

  const showLog = useSelector(({ log }) => log.isShow);

  const getPropValue = useCallback(
    // eslint-disable-next-line no-unused-vars
    ({ prop, systemValue, stateValue }) => {
      if (prop === 'hidden') {
        if (systemValue === true) {
          return true;
        }
      }

      if (prop === 'readOnly') {
        if (systemValue === true) {
          return true;
        }
      }

      let newPropValue =
        stateValue === undefined || stateValue === null
          ? systemValue
          : stateValue;

      switch (typeof selfField[prop]) {
        case 'string':
          if (selfField[prop].substring(0, 1) !== '<') {
            if (selfField[prop] === 'false' || selfField[prop] === 'true') {
              selfField[prop] = selfField[prop] === 'true';
              newPropValue = selfField[prop];
            } else {
              const dynamicFunction = createSyncFunctionByString({
                functionString: selfField[prop],
              });

              newPropValue = !!(
                dynamicFunction({ formRef, selfField }) || systemValue
              );
            }
          }
          break;

        case 'boolean':
          newPropValue = selfField[prop] || systemValue;
          break;

        default:
          /* newPropValue =
            systemValue === undefined || systemValue === null
              ? stateValue
              : systemValue; */
          break;
      }

      return newPropValue;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formRef, name, selfField, hidden, readOnly, required]
  );

  useEffect(() => {
    const hiddenValue = getPropValue({
      prop: 'hidden',
      systemValue: hidden,
      stateValue: getIsHidden(),
    });

    setIsHidden(hiddenValue);

    const reacOnlyValue = getPropValue({
      prop: 'readOnly',
      systemValue: readOnly,
      stateValue: getIsReadOnly(),
    });

    setIsReadOnly(reacOnlyValue);

    const requiredValue = getPropValue({
      prop: 'required',
      systemValue: required,
      stateValue: getIsRequired(),
    });

    setIsRequired(requiredValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfField, hidden, readOnly, required]);

  useEffect(() => {
    setIsHidden(hidden);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  useEffect(() => {
    setIsReadOnly(readOnly);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly]);

  useEffect(() => {
    setIsRequired(required);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  const setIsReadOnlyHandler = status => {
    // if (readOnly === false) {
    setIsReadOnly(status);
    // }
  };

  useEffect(() => {
    inputRef.current.setReadOnly = status => setIsReadOnlyHandler(status);

    inputRef.current.setIsReadOnly = status => setIsReadOnlyHandler(status);

    inputRef.current.setVisible = status => {
      setIsHidden(!status);
    };

    inputRef.current.setIsRequired = status => {
      setIsRequired(status);
    };

    if (showLog) {
      inputRef.current.onmouseover = () => {
        const logData = getLogData();
        dispatch(sendData(logData));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current, showLog, hidden, readOnly, required]);

  return {
    isHidden,
    isReadOnly,
    isRequired,
    setIsReadOnly: setIsReadOnlyHandler,
    setIsHidden,
  };
}

export default useField;
