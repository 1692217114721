import styled from 'styled-components';

export const Container = styled.div`
  min-height: 44px;
  background-color: #496774;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  font-weight: 600;
`;
