import React, { useState, useImperativeHandle, forwardRef } from 'react';

import useLocale from '~/hooks/useLocale';
import Modal from '~/easy-components/Modal';
import Toast from '~/easy-components/Toast';

import BinPage from './BinPage';
import BinPageReceive from './BinPageReceive';

function BinLocationSelection(props, ref) {
  const t = useLocale('BinLocationSelection');

  const [state, setState] = useState({
    title: '',
    isReadOnly: false,
    data: null,
    isOpen: false,
    direction: '',
    batchNumber: null,
    loteLineIndex: null,
    onConfirm: () => {},
  });

  useImperativeHandle(ref, () => {
    return {
      show: ({
        title,
        isReadOnly,
        data,
        direction,
        batchNumber,
        loteLineIndex,
        onConfirm,
      }) => {
        try {
          setState({
            title,
            isReadOnly,
            isOpen: true,
            data,
            direction,
            onConfirm,
            batchNumber,
            loteLineIndex,
          });
        } catch (error) {
          Toast.error(t(error));
        }
      },
    };
  });
  return (
    <Modal isOpened={state.isOpen}>
      {state.direction === 'from' ? (
        <BinPage
          t={t}
          isReadOnly={state.isReadOnly}
          title={state.title}
          data={state.data}
          batchNumber={state.batchNumber}
          loteLineIndex={state.loteLineIndex}
          direction={state.direction}
          onClose={() => setState({ ...state, isOpen: false })}
          onConfirm={state.onConfirm}
        />
      ) : (
        <BinPageReceive
          t={t}
          isReadOnly={state.isReadOnly}
          title={state.title}
          data={state.data}
          batchNumber={state.batchNumber}
          loteLineIndex={state.loteLineIndex}
          direction={state.direction}
          onClose={() => setState({ ...state, isOpen: false })}
          onConfirm={state.onConfirm}
        />
      )}
    </Modal>
  );
}

export default forwardRef(BinLocationSelection);
