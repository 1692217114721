export default function normalizeFieldLabel(field) {
  if (field === null ) {
    return null;
  }
  
  if (typeof field === 'object') {
    return field.label;
  }
  
  if (typeof field === 'string' && field.indexOf('{') === 0 ) {
    return JSON.parse(field).label;
  }
  
  return field;
}
