/* eslint-disable react/prop-types */
// @ts-check
import React from 'react';

import { Container, Title } from './styles';

/**
 * @typedef {Object} Props
 * @property {boolean} isChecked
 * @property {string} fileName
 * @property {(isChecked: boolean) => void} onChange
 */

/** @type {React.FC<Props>} */
const AttachmentView = ({ isChecked, onChange, fileName }) => {
  return (
    <Container>
      <Title>{fileName}</Title>
      <input
        type="checkbox"
        onChange={e => {
          onChange(e.target.checked);
        }}
        checked={isChecked}
      />
    </Container>
  );
};

export default AttachmentView;
