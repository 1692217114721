import React from 'react';

import { Container } from './styles';

function Loader() {
  return (
    <Container>
      <span className="loader" />
    </Container>
  );
}

export default Loader;
