import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: 0.4s;
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: calc(100% - 5px);
  }

  @media (max-width: 500px) {
    margin: 5px 15px;
  }

  &:hover {
    border: 1px solid #0074a080;
    /* margin-bottom: 20px; */
    /* margin-top: 10px; */
    cursor: pointer;

    /* @media (max-width: 500px) {
      margin: 5px 15px;
    } */
  }

  &.is-dragging {
    border: none !important;
    > div {
      opacity: 0.6;
    }
  }

  &.processing::after {
    content: 'Processando';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #c7d8e1b8;
    height: 100%;
    border-radius: 5px;
    font-weight: bold;
  }
`;
