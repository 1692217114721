export const splitFileName = (fileName = '') => {
  if (!fileName) return '';

  const idx = fileName.lastIndexOf('\\');
  const FileName = fileName.substring(idx + 1, fileName.length);
  return FileName;
};

export const splitSourcePath = (fileName = '') => {
  const idx = fileName.lastIndexOf('\\');

  const pathSource = fileName.substring(0, idx + 1);
  return pathSource;
};

const splitFileNameAndExtension = fileName => {
  const idx = fileName.lastIndexOf('.');

  const name = fileName.substring(0, idx);

  const extension = fileName.substring(idx + 1);

  return {
    name,
    extension,
  };
};

export const normalizeFileName = fileName => {
  const splitedName = splitFileNameAndExtension(fileName);

  const newFileName = splitedName.name
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9 ]/g, '');

  return `${newFileName}.${splitedName.extension}`;
};

export const convertBase64ToImage = base64String => {
  const typeMatch = base64String.match(/^data:(image\/[a-zA-Z+-.]+);base64,/);
  const imageType = typeMatch ? typeMatch[1] : 'image/jpeg';
  const base64WithoutHeader = base64String.split(',')[1];
  const byteCharacters = atob(base64WithoutHeader);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: imageType });

  const imageUrl = URL.createObjectURL(blob);

  return imageUrl;
};

export const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(',')[1]; // Obtém a string base64 sem o cabeçalho "data:image/jpeg;base64,"

      resolve(base64String);
    };

    reader.onerror = () => {
      reject(new Error('Erro ao ler o arquivo'));
    };

    reader.readAsDataURL(file);
  });
};

export const getRandomFileName = file => {
  const extension = file.name.split('.').pop();
  const randomString = `${Math.random().toString(18)}${Math.random().toString(
    36
  )}`;
  const newName = `${randomString.replace(/[^\w]/gi, '')}.${extension}`; // Remove caracteres especiais e espaços da string aleatória
  return newName;
};

export const getImageDimensions = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = event => {
      const img = new Image();

      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };

      img.onerror = () => {
        reject(new Error('Erro ao carregar a imagem'));
      };

      img.src = event.target.result;
    };

    reader.onerror = () => {
      reject(new Error('Erro ao ler o arquivo'));
    };

    reader.readAsDataURL(file);
  });
};
