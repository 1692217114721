import React, { useContext } from 'react';
import MobileCardJSX from '../MobileCardJSX';
// eslint-disable-next-line import/no-cycle
import { PageContext } from '../index';

// import { Container } from './styles';

function JSXCell(props) {
  const { column } = props;

  const {
    settings,
    appDispatch,
    promptRef,
    customModalRef,
    auxScope,
  } = useContext(PageContext);

  return (
    <MobileCardJSX
      jsxString={column?.settings?.jsx}
      dynamicFunctionProp={{
        dispatch: appDispatch,
        settings,
        promptRef,
        customModalRef,
        params: {
          ...auxScope,
          ...props,
        },
      }}
      onClick={() => {}}
    />
  );
}

export default JSXCell;
