/* eslint-disable no-restricted-syntax */
// import { buildYup } from 'json-schema-to-yup';
import request from '../../Request';
import AttachmentService from './AttachmentService';

class BusinessPartnerService extends AttachmentService {
  constructor() {
    super('BusinessPartners');
  }

  async list(filterValue) {
    let filters = '';
    let filterText = '';

    let route = 'BusinessPartners';

    if (typeof filterValue === 'object') {
      const { filter, auxFilters, isPre } = filterValue;

      if (isPre) {
        route += '/Pre';
      }

      filterText = filter;

      // eslint-disable-next-line guard-for-in
      for (const prop in auxFilters) {
        filters += `&${prop}=${auxFilters[prop]}`;
      }
    } else {
      filterText = filterValue;
    }

    return request({
      url: `APP/CRM/${route}?$filter=${filterText}${filters}`,
    });
  }

  async get(cardCode) {
    return request({
      url: `APP/CRM/BusinessPartners/${cardCode}`,
    });
  }

  async listShipAddresses({ cardCode, addressType, filter }) {
    return request({
      url: `APP/CRM/BusinessPartners/${cardCode}/Addresses?$filter=${filter ||
        ''}&$addressType=${addressType}`,
    });
  }

  async listCurrencies({ cardCode, filter }) {
    return request({
      url: `APP/CRM/BusinessPartners/${cardCode}/Currencies?$filter=${filter ||
        ''}`,
    });
  }

  async listPaymentMethods({ cardCode, filter }) {
    return request({
      url: `APP/CRM/BusinessPartners/${cardCode}/PaymentMethods?$filter=${filter ||
        ''}`,
    });
  }

  async listContacts({ cardCode, filter }) {
    return request({
      url: `APP/CRM/BusinessPartners/${cardCode}/Contacts?$filter=${filter ||
        ''}`,
    });
  }

  async save({ auxRoute, settings, data }) {
    const { CreateDate, CardCode } = data;
    if (CreateDate) {
      return request({
        eventAlias: 'onSave',
        settings,
        url: `APP/CRM/BusinessPartners/${CardCode}`,
        method: 'PUT',
        data,
        headers: {
          auxRoute: auxRoute || 'BusinessPartners',
        },
      });
    }

    return request({
      eventAlias: 'onSave',
      settings,
      url: `APP/CRM/BusinessPartners`,
      method: 'POST',
      data,
      headers: {
        auxRoute,
      },
    });
  }

  async delete(cardCode) {
    return request({
      url: `APP/CRM/BusinessPartners/${cardCode}`,
      method: 'DELETE',
    });
  }

  async getCnpj(cnpj) {
    return request({
      url: `APP/CRM/BusinessPartners/Cnpj/${cnpj}`,
    });
  }

  async attachFile({ cardCode, file, fileName }) {
    return super.attachFile({ keyCode: cardCode, file, fileName });
  }

  async unattachFile({ cardCode, fileLine }) {
    return super.unattachedFile({ keyCode: cardCode, fileLine });
  }

  async listFeature() {
    return request({
      url: `APP/CRM/BusinessPartners/feature`,
    });
  }

  async getContactInfo({ cardCode, contactCode }) {
    return request({
      url: `APP/CRM/BusinessPartners/${cardCode}/Contacts/${contactCode}`,
    });
  }

  async createContact({ cardCode, contactData }) {
    return request({
      url: `APP/CRM/BusinessPartners/${cardCode}/Contacts`,
      method: 'POST',
      data: { contactData },
    });
  }
}

export default new BusinessPartnerService();
