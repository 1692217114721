import styled from 'styled-components';

import { colors, sizes } from '~/easy-components/Form/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;
  position: relative;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const ModalContainer = styled.div`
  background-color: #fff;
  flex: 1;
  width: 600px;
  display: flex;
  flex-direction: column;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  background-color: #f0f0f0;

  h1 {
    margin-bottom: 10px;
    font-weight: 600;
  }

  input,
  textarea {
    height: 40px;
    background-color: #fff;
    border: ${`1px solid ${colors.fieldBorder}`};
    margin-bottom: 4px;
    padding: ${sizes.padding};
    color: ${colors.fieldColor};
    border-radius: ${sizes.borderRadius};

    &::placeholder {
      color: #aaa;
    }
  }

  textarea {
    height: 170px;
    resize: none;

    @media (max-width: 500px) {
      height: 100%;
    }
  }
`;

export const ModalBody = styled.div`
  background-color: #0f0;
`;

export const Footer = styled.div`
  width: 100%;
  height: 44px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;

  @media (max-width: 500px) {
    background-color: #f7f7f7;
  }

  button {
    margin-left: 10px;
  }
`;
