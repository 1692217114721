import styled from 'styled-components';
import systemColors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  background-color: rgb(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: 2;
  justify-content: center;
  align-items: center;
  position: fixed;

  @media (max-width: 500px) {
    @keyframes showDiv {
      from {
        left: ${props => (props.direction === 'right' ? '500px' : '-500px')};
      }
      to {
        left: 0px;
      }
    }
    animation: showDiv 0.5s, left 0.5s;
  }
  @media (min-width: 501px) {
    @keyframes showDiv {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    animation: showDiv 0.5s;
  }
`;

export const Content = styled.div`
  background-color: ${systemColors.background};
  overflow: auto;
  border-radius: 2px;
  width: 400px;

  @media (max-width: 500px) {
    width: 100vw;
    height: 100vh;
  }

  .footer {
    width: 100%;
    display: flex;

    @media (max-width: 500px) {
      bottom: 0;
      position: fixed;
    }
  }
`;

export const PageHeader = styled.div`
  background-color: ${systemColors.blue};
  color: white;
  padding: 10px 20px;
`;

export const ModalBody = styled.div`
  padding: 10px 20px;
`;
