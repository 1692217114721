/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import TabContent from '~/easy-components/TabContent';
import PageHeader from '~/easy-components/PageHeader';
import PageFooter from '~/easy-components/ModalFooter';
import Form from '~/easy-components/Form';
import Button from '~/easy-components/Button';
import PageContext from './context';
import { Container, Content } from './styles';

import GeneralTab from './tabs/General';
import SettingsTab from './tabs/Settings';

function Page({ t, data, onClose, onConfirm, onCtrlSave }) {
  const formRef = useRef();

  const [widgetSettings, setWidgetSettings] = useState(data.widgetSettings);
  const [query, setQuery] = useState(``);

  const handleConfirm = () => {
    const formData = formRef.current.getData();

    onConfirm({
      dataChanged: {
        ...formData,
        widgetSettings,
      },
    });
  };

  const handleCtrlSave = () => {
    const formData = formRef.current.getData();

    onCtrlSave({
      dataChanged: {
        ...formData,
        widgetSettings,
      },
    });
  };

  return (
    <PageContext.Provider
      value={{
        formRef,
        t,
        widgetSettings,
        setWidgetSettings,
        query,
        setQuery,
        onCtrlSave: handleCtrlSave,
      }}
    >
      <Container>
        <PageHeader>Widget</PageHeader>
        <Content>
          <Form ref={formRef} data={data} errors={{}}>
            <TabContent formRef={formRef}>
              <GeneralTab
                title={t('General')}
                name="GeneralTab"
                icon="FaInfoCircle"
              />
              <SettingsTab
                title={t('Settings')}
                name="SettingsTab"
                icon="FaCode"
              />
            </TabContent>
          </Form>
        </Content>
        <PageFooter>
          <Button onClick={handleConfirm}>{t('Confirm')}</Button>
          <Button onClick={onClose}>{t('Close')}</Button>
        </PageFooter>
      </Container>
    </PageContext.Provider>
  );
}

export default Page;
