/* eslint-disable react/prop-types */
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import DebounceEvent from '~/easy-components/DebounceEvent';
import { Container } from './styles';
import useLocale from '~/hooks/useLocale';

function Search({ isMobile, onSearch, children }, ref) {
  const inputRef = useRef();

  const t = useLocale('Search');

  useImperativeHandle(ref, () => {
    return {
      setFilterText: text => {
        inputRef.current.value = text;
      },
    };
  });

  return (
    <Container isMobile={isMobile}>
      {children}
      <input
        ref={inputRef}
        placeholder={t('Search')}
        onChange={DebounceEvent(e => onSearch(e.target.value))}
      />
    </Container>
  );
}

export default forwardRef(Search);
