/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useMemo, useContext } from 'react';
import HtmlParser from 'react-html-parser';

import CellContent from '../Components/CellContent';
import { Container, TemplateContainer, SelectionContainer } from './styles';
import { PageContext } from '../index';

function Card({
  templateFields,
  getRowColor,
  data,
  settings,
  reportSettings,
  onClick,
  onRefresh,
  props: rowProps,
  items,
  style,
  containerStyle,
}) {
  const {
    dispatch,
    getSelectedData,
    paramsModal,
    showLoading,
    tableProps,
  } = useContext(PageContext);
  const rawData = JSON.parse(JSON.stringify(data));

  const fields = new Map();

  templateFields.forEach(field => {
    const { column } = field;
    const { settings: settingsCol } = column || {};
    const { type } = settingsCol || {};

    const config = {
      column,
      dispatch,
      field: field.prop,
      getSelectedData,
      items,
      onRefresh,
      paramsModal,
      props: rowProps,
      rowData: data,
      rowKeyValue: rowProps.rowKeyValue,
      reportSettings,
      settings,
      showLoading,
      style,
      value: rawData[field.prop],
      isModal: false,
    };

    const component = <CellContent {...config} />;

    fields.set(field.prop, { component, type });
  });

  const template = HtmlParser(reportSettings.mobileCardTemplate, {
    transform: ({ data: content }) => {
      if (content && typeof content === 'string' && content.startsWith('{')) {
        const [placeholder] = content.match(/{[^}]+}/);

        const propName = placeholder.slice(1, -1);

        const { component, type } = fields.get(propName);

        return type !== 'selection' ? component : null;
      }

      return content;
    },
  });

  const color = useMemo(() => {
    if (getRowColor) {
      const value = getRowColor({ data, rowProps });
      return value;
    }
    return null;
  }, [data, getRowColor, rowProps]);

  const [selectionCheckbox] = templateFields
    .filter(tf => {
      const { column } = tf || {};
      const { settings: settingsCol } = column || {};
      const { type } = settingsCol || {};

      return type === 'selection';
    })
    .map(field => {
      const { column } = field;

      const config = {
        column,
        dispatch,
        field: field.prop,
        getSelectedData,
        items,
        onRefresh,
        paramsModal,
        props: rowProps,
        rowData: data,
        rowKeyValue: rowProps.rowKeyValue,
        reportSettings,
        settings,
        showLoading,
        style,
        value: rawData[field.prop],
        isModal: false,
        isSelectedRow: tableProps.selectedRows
          ? tableProps.selectedRows.includes(rowProps.rowKeyValue)
          : false,
      };
      return <CellContent {...config} />;
    });

  return (
    <Container color={color} onClick={() => onClick()} style={containerStyle}>
      {!!selectionCheckbox && (
        <SelectionContainer>{selectionCheckbox}</SelectionContainer>
      )}
      <TemplateContainer>{template}</TemplateContainer>
    </Container>
  );
}

export default Card;
