/* eslint-disable guard-for-in */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useContext, useMemo, useRef, useEffect } from 'react';
import { updateCellValue, deselectRow } from 'ka-table/actionCreators';
import isDate from 'date-fns/isDate';

import { PageContext } from '../index';
import Linker from '../Components/Linker';
import isValidJson from '../../Helpers/isValidJson';

import dynamicFunction from '../dynamicFunction';
import validateAndSelectRow from '../CustomActions/validateAndSelectRow';

import useInputNavigation from '../hooks/useInputNavigation';

/**
 * @param {string} string
 * @returns {boolean}
 */
const isValidObjectJson = string => {
  let parsed;
  try {
    parsed = JSON.parse(string);
  } catch (e) {
    return false;
  }

  if (typeof parsed === 'object' && !Array.isArray(parsed) && parsed !== null) {
    const { value } = parsed;

    return !!value;
  }

  return false;
};

function isSqlInput(value) {
  const sqlInputLinkerFormat = new RegExp(/(.+\+.+)/, 'g');

  const boolean = sqlInputLinkerFormat.test(value);
  return boolean;
}

const isValueBeforeTransformation = value => {
  if (isValidObjectJson(value)) {
    return false;
  }

  if (isSqlInput(value)) {
    return true;
  }

  if (value === '' || value === undefined || value === null) {
    return false;
  }

  return !['string', 'number', 'boolean'].includes(typeof value);
};

/**
 * @param {string | number | boolean | null | undefined} value
 * @returns {{label: string, value: any}}
 */
const getParsedValue = value => {
  const allowedTypes = ['string', 'number', 'boolean'];

  if (
    !allowedTypes.includes(typeof value) &&
    value !== undefined &&
    value !== null
  ) {
    throw new Error(
      `Invalid value in inputLinker - Can be null, undefined, number, boolean, empty string, string or a Object JSON string, get ${value} instead.`
    );
  }

  if (['number', 'boolean'].includes(typeof value)) {
    return { value, label: value };
  }

  if (isValidObjectJson(value)) {
    const object = JSON.parse(value);

    if (!object.label) {
      object.label = object.value;
    }

    return object;
  }

  if (isSqlInput(value)) {
    const [valueField, labelField] = value.split('+');

    return { label: labelField, value: valueField };
  }

  if (value === '' || value === undefined || value === null) {
    return { value: null, label: '' };
  }

  return { value, label: value };
};

function InputLinkerCell({
  field,
  column,
  rowKeyValue,
  value,
  rowData,
  getSelectedData,
  onRefresh,
  cellProps,
  readOnly,
  items,
  rawItems,
  isFocused,
  onAddNewLine,
  reportSettings,
}) {
  const {
    dispatch,
    dispatchOnOpenInputLinkerInLine,
    auxScope,
    setRowFieldValue,
    setRowFieldReadOnly,
    removeLine,
    isResponsible,
    settings,
    t,
    tableProps,
    refreshTable,
  } = useContext(PageContext);

  const linkerRef = useRef();

  const {
    inputNavigationOnKeyDown,
    inputNavigationOnFocus,
  } = useInputNavigation({
    columnKey: column.key,
    rowKeyValue,
    tableProps,
    isFocused,
    dispatch,
    onAddNewLine,
    currentInputRef: linkerRef,
  });

  const parsedValue = getParsedValue(value);

  if (isValueBeforeTransformation(value) && rowKeyValue) {
    dispatch(updateCellValue(rowKeyValue, field, JSON.stringify(parsedValue)));
  }

  const { label: linkerLabel = '', value: linkerValue = null } =
    parsedValue || {};

  function getMergeData(filter) {
    const rowDataObjectReplaced = JSON.parse(JSON.stringify(rowData));
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in rowDataObjectReplaced) {
      const v = rowDataObjectReplaced[prop];

      if (typeof v === 'string' && v.startsWith('{') && isValidJson(v)) {
        rowDataObjectReplaced[prop] = (JSON.parse(v) || {}).value || null;
      } else if (isDate(v)) {
        rowDataObjectReplaced[prop] = v.toISOString();
      }
    }

    const mergeData = {
      filter,
      ...rowDataObjectReplaced,
    };

    return mergeData;
  }

  function replacePlaceholders(filter, commandQuery = '') {
    let command = commandQuery;

    const re2 = new RegExp(`#<`, 'g');
    command = command.replace(re2, '%<');

    const re3 = new RegExp(`>#`, 'g');
    command = command.replace(re3, '>%');

    const mergeData = getMergeData(filter);

    const commandParams = command.match(/<[^>]+>/g) || [];

    commandParams.forEach(param => {
      const paramNotBlackets = param.substring(1, param.length - 1);

      if (mergeData[paramNotBlackets] || paramNotBlackets === 'filter') {
        const re = new RegExp(`<${paramNotBlackets}>`, 'gi');

        command = command.replace(
          re,
          mergeData[paramNotBlackets] ||
            (paramNotBlackets === 'filter' ? '' : null)
        );
      }
    });

    // Altera a string 'null' para um valor null
    const reNull = new RegExp(`'null'`, 'g');
    command = command.replace(reNull, null);

    return command;
  }

  const { settings: columnSettings = {} } = column;

  const { queryCode, sql } = columnSettings;

  const linkerTitle = useMemo(() => {
    const byTitle = column && column.settings && column.settings.title;

    if (byTitle) {
      return byTitle;
    }

    return t(
      column &&
        column.settings &&
        (column.settings.translateKey || column.settings.name),
      column && column.settings && column.settings.translateRoute
    );
  }, [column, t]);

  const isReadOnly =
    readOnly || (column && column.settings && column.settings.readOnly);

  useEffect(() => {
    if (isFocused) {
      linkerRef.current.focus();
      linkerRef.current.select();
    }
  });

  // return 'a';
  return (
    <Linker
      ref={linkerRef}
      isResponsible={isResponsible}
      onKeyDown={e => {
        inputNavigationOnKeyDown(e);
      }}
      onFocus={inputNavigationOnFocus}
      readOnly={isReadOnly}
      value={linkerValue}
      label={linkerLabel}
      title={linkerTitle}
      onChange={async optionSelected => {
        const newTableValue = optionSelected || { label: '', value: null };

        setRowFieldValue(
          rowKeyValue,
          field,
          newTableValue.value === null ? null : JSON.stringify(newTableValue),
          false
        );

        if (column.settings && column.settings.onChange) {
          await dynamicFunction({
            functionString: column.settings.onChange,
            settings,
            dispatch,
            params: {
              ...auxScope,
              items,
              rawItems,
              formData: auxScope && auxScope.form && auxScope.form.getData(),
              getSelectedData,
              column,
              line: rowData,
              value,
              rowKeyValue,
              refresh: onRefresh,
              currentValue: optionSelected || {},
              setFieldValue: (columnKey, newValue, forceUpdate) => {
                setRowFieldValue(rowKeyValue, columnKey, newValue, forceUpdate);
              },
              setRowFieldValue,
              refreshTable,
              setReadOnly: (columnKey, isCellReadOnly = false) => {
                setRowFieldReadOnly(rowKeyValue, columnKey, isCellReadOnly);
              },
              setRowFieldReadOnly,
              selectRow: ({ force = false }) => {
                dispatch(
                  validateAndSelectRow({
                    callingFromCell: cellProps,
                    forceSelection: force,
                  })
                );
              },
              deselectRow: () => {
                dispatch(deselectRow(rowKeyValue));
              },
              removeLine,
              reportSettings,
            },
          });
        }

        refreshTable();
      }}
      auxScope={auxScope}
      settings={column.settings}
      fixedData={column && column.settings && column.settings.data}
      minWidth={
        column &&
        column.settings &&
        column.settings.style &&
        column.settings.style.width
      }
      method={filter => {
        return dispatchOnOpenInputLinkerInLine({
          filter,
          column,
          rowKeyValue,
          rowData,
          queryCode,
          sql: replacePlaceholders(filter, sql),
          items,
        });
      }}
    />
  );
}

export default InputLinkerCell;
