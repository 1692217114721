import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 20px 5px 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  :last-child {
    margin-bottom: 0;
  }

  input {
    width: 20px;
  }

  > p {
    color: #8197a5;
    font-weight: 500;
  }
`;
export const Title = styled.p`
  color: #555;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;
