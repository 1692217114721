/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { deselectRow } from 'ka-table/actionCreators';

import useCompany from '~/hooks/useCompany';

import { isMobile } from 'react-device-detect';
import { Input } from '../../styles';
import { FieldContainer } from './styles';
import dynamicFunction from '../../dynamicFunction';
import validateAndSelectRow from '../../CustomActions/validateAndSelectRow';
import { PageContext } from '../../index';
import Symbol from '../../Components/Symbol';
import useInputNavigation from '../../hooks/useInputNavigation';

function InputNumber({
  column,
  rowKeyValue,
  value,
  dispatch,
  rowData,
  onRefresh,
  style,
  cellProps,
  getSelectedData,
  readOnly,
  items,
  rawItems,
  isFocused,
  onAddNewLine,
  reportSettings,
}) {
  const {
    auxScope,
    setRowFieldValue,
    removeLine,
    t,
    settings,
    tableProps,
    setRowFieldReadOnly,
    refreshTable,
  } = useContext(PageContext);

  const inputRef = useRef();
  const inputChanged = useRef(false);

  let charPermitted = '0123456789,.';

  if (column.settings && column.settings.allowNegative)
    charPermitted = charPermitted.concat('-');

  const {
    inputNavigationOnKeyDown,
    inputNavigationOnFocus,
  } = useInputNavigation({
    columnKey: column.key,
    rowKeyValue,
    tableProps,
    isFocused,
    dispatch,
    onAddNewLine,
    currentInputRef: inputRef,
  });

  const { numberToString, stringToNumber } = useCompany();

  const stringValue = numberToString(
    value,
    column.settings.numberType ? column.settings.numberType : 'value',
    column.settings.digits
  );

  const [editorValue, setValue] = useState(stringValue);

  useEffect(() => {
    setValue(stringValue);
  }, [stringValue]);

  useEffect(() => {
    if (isFocused && !inputChanged.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  });

  return (
    <FieldContainer
      readOnly={readOnly}
      minWidth={
        column &&
        column.settings &&
        column.settings.style &&
        column.settings.style.width
      }
    >
      {column && column.settings && column.settings.symbol && (
        <Symbol
          title={
            column &&
            column.settings &&
            (column.settings.title ||
              t(
                column.settings.translateKey || column.settings.name,
                column.settings.translateRoute
              ))
          }
          enableChange={false}
          symbol={column.settings.symbol}
          onSearch={() => {}}
          onSelected={() => {}}
        />
      )}
      <Input
        ref={inputRef}
        type="text"
        inputMode="decimal"
        style={style}
        readOnly={readOnly}
        value={editorValue}
        inputType="inputNumber"
        disabled={column.settings.disabled || false}
        onClick={e => {
          e.stopPropagation();

          if (isMobile) {
            inputRef.current.focus();
          }
        }}
        onFocus={inputNavigationOnFocus}
        onChange={event => {
          inputChanged.current = true;
          setValue(event.currentTarget.value);
        }}
        onBlur={async () => {
          if (readOnly) return;

          if (!inputChanged.current) return;

          const numberValue = stringToNumber(editorValue);

          const formattedValue = numberToString(
            numberValue,
            (column.settings && column.settings.numberType) || 'value',
            column.settings.digits
          );
          const formattedNumberValue = stringToNumber(formattedValue);

          setRowFieldValue(
            rowKeyValue,
            column.key,
            formattedNumberValue,
            false
          );

          if (column.settings && column.settings.onChange) {
            await dynamicFunction({
              settings,
              functionString: column.settings.onChange,
              dispatch,
              params: {
                ...auxScope,
                items,
                rawItems,
                rows: items,
                thisRow: rowData,
                getSelectedData,
                column,
                line: rowData,
                value,
                rowKeyValue,
                refresh: onRefresh,
                currentValue: formattedNumberValue,
                refreshTable,
                reportSettings,
                setFieldValue: (columnKey, newValue, forceUpdate) => {
                  setRowFieldValue(
                    rowKeyValue,
                    columnKey,
                    newValue,
                    forceUpdate
                  );
                },
                setRowFieldValue,
                setReadOnly: (columnKey, isCellReadOnly = false) => {
                  setRowFieldReadOnly(rowKeyValue, columnKey, isCellReadOnly);
                },
                selectRow: ({ force = false }) => {
                  dispatch(
                    validateAndSelectRow({
                      callingFromCell: cellProps,
                      forceSelection: force,
                    })
                  );
                },
                deselectRow: () => {
                  dispatch(deselectRow(rowKeyValue));
                },
                removeLine,
              },
            });
          }

          inputChanged.current = false;
          refreshTable();
        }}
        onKeyPress={e => {
          if (charPermitted.indexOf(e.key) < 0) {
            e.preventDefault();
          }
        }}
        onKeyDown={e => {
          inputNavigationOnKeyDown(e);
        }}
      />
    </FieldContainer>
  );
}

export default InputNumber;
