// eslint-disable-next-line no-unused-vars
const debounceEvent = (fn, wait = 1000, time, evt) => (...args) => {
  args[0].persist();
  if (evt) {
    evt(...args);
  }
  clearTimeout(time, (time = setTimeout(() => fn(...args), wait)));
};

export default debounceEvent;
