import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  background-color: #ff0;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  background-color: #f0f0f0;
  flex-direction: row;
  overflow: auto;
`;

export const CommandPanel = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: row;
  overflow: auto;
  background-color: #555;

  > div {
    height: 100%;
    overflow: auto;
    flex: 1;
    flex-direction: column;
    display: flex;
  }

  > div:first-child {
    margin-right: 2px;
  }
`;
