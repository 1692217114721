/* eslint-disable import/no-cycle */
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from 'react';

import Button from '~/easy-components/Button';
import useLocale from '~/hooks/useLocale';
import MonacoEditor from '~/easy-components/Editor';

function Editor(
  {
    code,
    events = [],
    runType,
    eventName,
    menu,
    hidden = false,
    onKeyDown,
    auxIntelisenses,
  },
  ref
) {
  const t = useLocale('_Global');

  const [value, setValue] = useState('');
  const [run, setRun] = useState(runType);

  const eventsRef = useRef(events);
  const monacoRef = useRef();

  useEffect(() => {
    if (code !== undefined && code !== null) {
      setValue(code);
    } else {
      let event = eventsRef.current.find(e => e.run === run);

      if (!event) {
        event = {
          name: eventName,
          run,
          handler: '',
        };

        eventsRef.current.push(event);
      }

      setValue(event.handler);
    }
  }, [eventName, menu.run, run, runType, code]);

  const [eventsState, setEventsState] = useState(events);

  useEffect(() => {
    setEventsState(events || []);
  }, [events]);

  useEffect(() => {
    if (code === undefined || code === null) {
      const evt = eventsState.find(e => e.run === run);
      if (evt) {
        const functionCode = evt ? evt.handler : '';
        setValue(functionCode);
      } else {
        setEventsState(old => {
          return [
            ...old,
            {
              name: menu.run || eventName,
              run,
              handler: '',
            },
          ];
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsState]);

  const updateEvent = () => {
    setEventsState(old => {
      const evt = old.find(e => e.run === run);
      evt.handler = value;
      return [...old];
    });
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        if (code !== undefined && code !== null) {
          return value;
        }

        const evt = eventsRef.current.find(e => e.run === run);
        evt.handler = value;
        return eventsRef.current;
      },
      focus: () => {
        monacoRef.current.focus();
      },
    };
  });

  let beforeButtonType = 'Emphasized';
  let afterButtonType = 'Default';

  if (run === 'after') {
    beforeButtonType = 'Default';
    afterButtonType = 'Emphasized';
  }

  const tbar = !menu.run && [
    <div style={{ flex: 1 }} />,
    <h1 style={{ marginRight: '10px', color: '#D0D0D0' }}>{t('Execution')}</h1>,
    <Button
      buttonType={beforeButtonType}
      style={{
        borderRadius: '5px 0 0 5px',
        width: '90px',
        textAlign: 'center',
        justifyContent: 'center',
      }}
      onClick={() => {
        updateEvent();
        setRun('before');
      }}
    >
      {t('before')}
    </Button>,
    <Button
      buttonType={afterButtonType}
      style={{
        borderRadius: '0 5px 5px 0',
        width: '90px',
        textAlign: 'center',
        justifyContent: 'center',
      }}
      onClick={() => {
        updateEvent();
        setRun('after');
      }}
    >
      {t('after')}
    </Button>,
  ];

  return (
    <MonacoEditor
      ref={monacoRef}
      hidden={hidden}
      tbar={code === undefined || code === null ? tbar : null}
      language="javascript"
      value={value || ''}
      onChange={setValue}
      onKeyDown={onKeyDown}
      auxIntelisenses={auxIntelisenses}
    />
  );
}

export default forwardRef(Editor);
