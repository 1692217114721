/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

// eslint-disable-next-line import/no-cycle
import { formatCell } from '../Helpers/formatCell';

import { DisplayCell, FakeInput } from '../styles';

function CustomCells({
  value,
  column,
  rowData,
  style: customStyle,
  settings,
  isFakeInput,
  readOnly,
  auxScope,
}) {
  const [text, typeInCell] = useState('');

  useEffect(() => {
    formatCell({
      value,
      column,
      rowData,
      appSettings: settings,
      auxScope,
    }).then(textFormatted => typeInCell(textFormatted));
  }, [auxScope, column, rowData, settings, value]);

  const { style } = column.settings;

  const Content = () => (
    <span
      style={{
        maxWidth: style && style.maxWidth ? style.maxWidth : '600px',
        ...customStyle,
      }}
    >
      {text}
    </span>
  );

  return (
    <DisplayCell>
      {isFakeInput ? (
        <FakeInput style={style} readOnly={readOnly}>
          <Content />
        </FakeInput>
      ) : (
        <Content />
      )}
    </DisplayCell>
  );
}

export default CustomCells;
