/* eslint-disable react/prop-types */
import React, { useRef, useImperativeHandle, forwardRef } from 'react';

import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';
import MenuButton from '~/easy-components/MenuButton';

import systemColors from '~/styles/colors';
import Search from '../Search';
import { Container, Panel, TitleKanban } from './styles';

function Title({ title, onSearch, menus, auxFilter }, ref) {
  const searchRef = useRef();

  useImperativeHandle(ref, () => {
    return {
      setFilterText: text => {
        searchRef.current.setFilterText(text);
      },
    };
  });
  return (
    <Container>
      <Panel>
        <TitleKanban>
          <div>{title}</div>
        </TitleKanban>

        <Search isMobile={false} onSearch={onSearch} ref={searchRef}>
          {auxFilter}
        </Search>
      </Panel>

      {menus && (
        <MenuButton
          menus={menus}
          icon={FaEllipsisV}
          color={systemColors.headerTitle}
          size={16}
          position="bottom right"
        />
      )}
    </Container>
  );
}

export default forwardRef(Title);
