/* eslint-disable react/prop-types */
import React from 'react';
import Icon from '~/easy-components/Icon';
import { sendEvent } from '~/easy-components/HandlerEvent';
import { Container } from './styles';

function CustomEvent({
  settings,
  inputRef,
  formRef,
  mainFormRef,
  auxScope,
  selfField,
}) {
  const onCustomEventClick = async () => {
    await sendEvent({
      settings,
      eventName: 'onCustomEvent',
      params: {
        value: inputRef.current.value,
      },
      run: 'before',
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
      auxScope,
    });
  };

  if (selfField.enableCustomEvent !== true) {
    return null;
  }

  return (
    <>
      {selfField.enableCustomEvent ? (
        <Container>
          <Icon
            name={selfField.customEventIcon || 'MdInfoOutline'}
            size={selfField.customEventIconSize || 22}
            color={selfField.customEventIconColor || '#8197a'}
            style={{ marginRight: '4px' }}
            onClick={onCustomEventClick}
          />
        </Container>
      ) : null}
    </>
  );
}

export default CustomEvent;
