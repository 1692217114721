import styled from 'styled-components';

import colors from '~/styles/colors';

export const Container = styled.section`
  display: flex;
  flex: 1;
  overflow: auto;
  color: #333;
  background-color: ${colors.background};
`;

export const Main = styled.aside`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  animation: fadeInFromNone 0.5s ease-out;

  @-webkit-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @-moz-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @-o-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }
`;

export const PageBody = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
  position: relative;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: ${colors.background};
`;
