import request from '../../Request';

class BinLocationService {
  async getBinLocales({ itemCode, batchNum, whsCode, filter }) {
    return request({
      url: `APP/CRM/BinLocation/Quantities/${itemCode}?$whsCode=${whsCode}&$batchNum=${batchNum}&$filter=${filter}`,
    });
  }

  async getBinLocations({ whsCode, filter }) {
    return request({
      url: `APP/CRM/BinLocation/list?$whsCode=${whsCode}&$filter=${filter}`,
    });
  }
}
export default new BinLocationService();
