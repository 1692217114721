/* eslint-disable import/no-cycle */
import ReportQueriesService from '~/applications/CRM/services/ReportQueriesService';
import createGlobalFunctions from '~/easy-components/Helpers/createGlobalFunctions';

import { JsonParse } from '~/easy-components/Helpers';

async function getReportSettings(hash) {
  const { settings } = await ReportQueriesService.get(hash);

  const objectSettings = JsonParse(settings);

  // await createControllers(objectSettings);

  createGlobalFunctions(objectSettings);

  return objectSettings;
}

export default getReportSettings;
