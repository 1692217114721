/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import ListPage from '~/easy-components/ListPage';
import TreatError from '~/easy-components/TreatError';
import CompareRender from '~/easy-components/Helpers/compareRender';

function List(
  {
    onSearch,
    onSelect,
    renderItem,
    onClose,
    onNew,
    lastDateUpdated,
    backgroundColor,
    showSearchPanel,
    isClickable,
    settings,
    isShowMaskLoading = true,
    queryCodeList,
    isUserPage,
    disableList = false,
  },
  ref
) {
  const auxFilters = (settings && settings.auxFilters) || [];

  return (
    <ListPage
      isUserPage={isUserPage}
      queryCode={queryCodeList}
      ref={ref}
      lastUpdatedDate={lastDateUpdated}
      renderItem={renderItem}
      onSearch={onSearch}
      onClose={onClose}
      onNew={onNew}
      onSelect={onSelect}
      onError={error => TreatError.showError(error)}
      backgroundColor={backgroundColor}
      auxFilters={auxFilters}
      showSearchPanel={showSearchPanel}
      disableList={disableList}
      isClickable={isClickable}
      isShowMaskLoading={isShowMaskLoading}
      settings={settings}
    />
  );
}

export default CompareRender(forwardRef(List));
