import React from 'react';
import Button from '~/easy-components/Button';

import { Container, Content, Element, Footer, Header } from './styles';
import { Bar } from '../Board/styles';

function MobileBoards({
  mobileTitle,
  propPercent,
  renderTitle,
  boards,
  onClose,
  setSelectedKey,
  propBoardKey,
}) {
  return (
    <Container>
      <Header>
        <h1>{mobileTitle}</h1>
      </Header>
      <Content>
        {boards.map(board => {
          return (
            <Element
              key={board[propBoardKey]}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();

                setSelectedKey(board[propBoardKey]);
                onClose();
              }}
            >
              {renderTitle(board)}
              {board[propPercent] && <Bar percent={board[propPercent]} />}
            </Element>
          );
        })}
      </Content>
      <Footer>
        <Button
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();

            onClose();
          }}
          style={{ marginLeft: '10px' }}
          buttonType="Emphasized"
          type="button"
        >
          Fechar
        </Button>
      </Footer>
    </Container>
  );
}

export default MobileBoards;
