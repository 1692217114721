import styled from 'styled-components';

export const Container = styled.section`
  padding: 20px;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Mention = styled.div`
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  background-color: ${props => (props.isSelected ? '#deeff6' : '#fff')};
  border-radius: 5px;
  line-height: 20px;
  padding: 10px;
  box-shadow: 0px 1px 2px #00000017;

  display: flex;
  flex-direction: row;
  overflow: auto;

  &:hover {
    cursor: pointer;
    background-color: #deeff6;
  }

  > section {
    flex: 1;

    h1 {
      color: #659ede;
      font-size: 1rem;
      font-weight: 500;
    }

    h2 {
      color: #798a9d;
      font-weight: 400;
      font-size: 0.9rem;
    }

    h3 {
      margin-top: 5px;
      color: #798a9d;
      font-weight: 400;
    }
  }
`;

export const Image = styled.div`
  width: 60px;
  border-radius: 50%;
  height: 60px;
  background-color: #ddd;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
