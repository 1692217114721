/* eslint-disable react/prop-types */
import React from 'react';

import { BsPencil } from '@react-icons/all-files/bs/BsPencil';
import { Container, Content, Line, Status } from './styles';

const getIcon = isQueryCustom => {
  if (isQueryCustom) {
    return <BsPencil size={16} color="#00AAC6" />;
  }
  return null;
};

const getStatus = status => {
  if (status)
    return {
      color: '#00AAC6',
    };

  return {
    color: '#b7b7b7',
  };
};

export default function query({ data, ...rest }) {
  const { code, name, description, queryConnectionType } = data;

  let isQueryCustom = false;
  if (queryConnectionType && queryConnectionType[0].companyQuery[0])
    isQueryCustom = !!queryConnectionType[0].companyQuery[0].command;

  const status = getStatus(isQueryCustom);

  return (
    <Container {...rest}>
      <Status color={status.color} />
      <Content>
        <Line>
          <h1>{name}</h1>
        </Line>
        <Line>
          <h2>{code}</h2>
        </Line>
        <Line>
          <div>{description}</div>
        </Line>
        <Line>
          <div />
          {getIcon(isQueryCustom)}
        </Line>
      </Content>
    </Container>
  );
}
