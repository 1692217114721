import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  // width: ${props => props.width}px;
  // height: ${props => props.height}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Trapezio = styled.div`
  margin-top: 1px;
  width: ${props => props.width}px;
  border-top: ${props => props.height}px solid ${props =>
  props.backgroundColor};
  border-left: ${props => props.discount / 2}px solid transparent;
  border-right: ${props => props.discount / 2}px solid transparent;
  height: 0;
  transition: margin 0.2s, border 0.5s;
  border-radius: 10px;
  color: ${props => props.color};

  &:hover {
    /* border-top: ${props => props.height}px solid ${props =>
  props.focusColor}; */
    margin-left: 10px;
  }
`;

export const Content = styled.div`
  position: relative;
  transition: margin 0.2s, border 0.5s;

  &:hover {
    margin-left: 10px;
    cursor: pointer;
    /* margin: 8px 0; */
  }
`;

export const Information = styled.div`
  position: absolute;
  width: 200px;
  height: 20px;
  background-color: transparent;
  line-height: 14px;
  top: calc(50% - 10px);
  left: calc(50% - 100px);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-weight: bold;
  }
`;
