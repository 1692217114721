/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import ModalEmail from '~/easy-components/ModalEmail';
import useLocale from '~/hooks/useLocale';
import TreatError from '~/easy-components/TreatError';
import { replacePlaceholdersText } from '~/easy-components/Helpers/replacePlaceholdersText';
import { setLoading } from '~/store/modules/loading/actions';
import request from '~/applications/Request';
import JsonParse from '~/easy-components/Helpers/JsonParse';
import EmailService from '../../services/EmailService';
import EmailTemplatesService from '../../services/EmailTemplatesService';

function ModalEmailTemplates({ dynamicFunctionProps }, ref) {
  const dispatch = useDispatch();
  const t = useLocale('_Global');
  const tEmailTemplates = useLocale('EmailTemplates');

  const showLoading = status => {
    return dispatch(setLoading(status));
  };

  const [state, setState] = useState({
    template: null,
    data: null,
    emailSettings: null,
    title: '',
    settings: null,
    preview: true,
    isOpen: false,
    onSendEmail: () => {},
    onClose: () => {},
  });

  const onSendEmailFnc = async ({ data: emailSettingsTemplate }) => {
    try {
      await EmailService.sendEmail({
        from: emailSettingsTemplate.from || state.from,
        to: emailSettingsTemplate.toEmail || state.toEmail,
        body: emailSettingsTemplate.body || state.body,
        copy: emailSettingsTemplate.copy || state.copy || null,
        data: state.data || emailSettingsTemplate.data,
        subject: emailSettingsTemplate.subject || state.subject,
        reportCode: emailSettingsTemplate.reportCode || null,
        attachments: emailSettingsTemplate.attachments || [],
        fileName: emailSettingsTemplate.fileName || null,
        uniqueFileName: emailSettingsTemplate.uniqueFileName || null,
      });

      state.onClose();
    } catch (error) {
      TreatError.showError(error);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      show: async ({
        template,
        data,
        toEmail,
        from,
        copy,
        subject,
        body,
        emailSettings,
        title,
        onSendEmail,
        preview,
        onClose,
      }) => {
        try {
          let emailSettingsTemplate = emailSettings || null;

          const userSettings = await request({ url: 'ownersettings' });

          const onSendEmailTemplates = () => {};

          const onCloseTemplate = () => setState({ ...state, isOpen: false });

          if (template) {
            const dataTemplate = await EmailTemplatesService.get(template);

            const dataTemplateSettings = dataTemplate.settings
              ? JsonParse(dataTemplate.settings)
              : null;

            let formatters = null;

            if (dataTemplateSettings) {
              formatters = dataTemplateSettings.formatters;
            }

            emailSettingsTemplate = {
              body: body || dataTemplate.body,
              from:
                from || dataTemplate.from || userSettings.environmentUserEmail,
              toEmail: toEmail || dataTemplate.to,
              copy: copy || dataTemplate.cc,
              subject: subject || dataTemplate.subject,
              formatters,
            };
          }

          const emailFrom =
            emailSettingsTemplate !== null && emailSettingsTemplate.from
              ? emailSettingsTemplate.from
              : userSettings !== null && userSettings.environmentUserEmail
              ? userSettings.environmentUserEmail
              : '';

          const objEmail = {
            template,
            toEmail,
            copy,
            subject,
            body,
            data: {
              ...userSettings,
              ...data,
            },
            settings: {
              dynamicFunctionProps,
            },
            title: title || tEmailTemplates('SendEmail'),
            isOpen: true,
            onSendEmail: onSendEmail || onSendEmailTemplates,
            onClose: onClose || onCloseTemplate,
            preview,
            ...emailSettingsTemplate,
            from: emailFrom,
          };

          if (!preview) {
            const { settings, formatters, body: emailBody } = objEmail;

            const replacedBody = replacePlaceholdersText({
              text: emailBody || '',
              placeholderTemplate: '{?}',
              data,
              formatters,
              auxScope:
                settings && settings.dynamicFunctionProps
                  ? settings.dynamicFunctionProps
                  : null,
            });

            objEmail.body = replacedBody;

            await onSendEmailFnc({ data: objEmail });
          } else {
            setState(objEmail);
          }
        } catch (error) {
          TreatError.showError(error);
        }
      },
    };
  });

  return (
    state.isOpen && (
      <ModalEmail
        t={t}
        title={state.title}
        settings={state.settings}
        data={state.data}
        emailSettings={state}
        formatters={state.formatters}
        onSendEmail={onSendEmailFnc}
        onClose={state.onClose}
        showLoading={showLoading}
      />
    )
  );
}

export default forwardRef(ModalEmailTemplates);
