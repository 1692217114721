/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-cycle */
/* eslint-disable no-case-declarations */
/* eslint-disable no-new-func */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, memo } from 'react';
import { useField } from '@unform/core';

import { isMobile } from 'react-device-detect';
import { FieldContainer, Input } from '../styles';

function EventField({ name, settings, ...rest }) {
  const inputRef = useRef(null);
  const eventsRef = useRef(null);

  const { fields } = settings || {};
  const selfField = (fields ? fields.find(f => f.name === name) : {}) || {};

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue: el => {
        el.value = null;
      },
      setValue: (el, v) => {
        if (v !== undefined && v !== null) {
          if (typeof v === 'object') {
            eventsRef.current = v;
          } else {
            eventsRef.current = JSON.parse(v);
          }
        } else {
          eventsRef.current = null;
        }
      },
      getValue: () => {
        return eventsRef.current;
      },
    });
  }, []);

  return (
    <>
      <FieldContainer
        style={
          isMobile
            ? {
                ...(selfField && selfField.style ? selfField.style : {}),
                ...(rest && rest.style ? rest.style : {}),
              }
            : {}
        }
      >
        <Input ref={inputRef} {...selfField} {...rest} hidden />
      </FieldContainer>
    </>
  );
}

export default memo(EventField);
