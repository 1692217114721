/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { CompanyContext } from '~/CompanyProvider';

import useMainManu from '~/hooks/useMainManu';

import App from './app';

function isEmptyObject(objeto) {
  return Object.keys(objeto).length === 0;
}

function mountRedirectTo({ path, params, loginLink }) {
  let redirectTo = `${loginLink}`;

  if (path === 'COMPANIES') return redirectTo;

  if (
    path === 'EXECUTEREPORT' ||
    path === 'USERPAGE' ||
    path === 'EXTERNALPAGE'
  ) {
    const { hash } = params;
    redirectTo += `?${path}=Y&key=${hash}`;
  } else if (isEmptyObject(params)) {
    redirectTo += `?page=${path}`;
  } else {
    redirectTo += `?page=${path}&key=${Object.values(params)[0]}`;
  }

  return redirectTo;
}

function RouteWrapper({
  component: Component,
  isPrivate,
  isShowMainMenu = true,
  ...rest
}) {
  const { getCompanyUrlLinkId, settings } = useContext(CompanyContext);

  const { userType } = settings;

  const loginLink = getCompanyUrlLinkId()
    ? `/Login/${getCompanyUrlLinkId()}`
    : '/Login';

  const authorizationToken = useSelector(
    ({ authorizationToken: authToken }) => authToken
  );

  const companyToken = useSelector(({ companyToken: token }) => token);

  const { location, computedMatch } = rest;

  const { setVisibleMenu } = useMainManu();
  setVisibleMenu(isShowMainMenu);

  const fullPath = location.pathname.toUpperCase().split('/');
  const path = fullPath[1].replace('/', '');

  if (path === '') {
    return <Redirect to={`${loginLink}${rest.location.search}`} />;
  }

  if (path === 'LOGIN') {
    if (!authorizationToken) {
      return <Route {...rest} component={Component} />;
    }

    return <Redirect to={`/Companies${rest.location.search}`} />;
  }

  if (path === 'NEWCOMPANY') {
    if (companyToken && userType && userType !== 1) {
      return <Redirect to="/Workspace" />;
    }
    if (!companyToken) {
      return <Redirect to="/Companies" />;
    }
  }

  if (
    path === 'CHANGEPASSWORD' ||
    path === 'USERPROFILE' ||
    path === 'WORKSPACE'
  ) {
    if (authorizationToken) {
      if (path === 'USERPROFILE') {
        return <App route="Global" {...rest} Component={Component} />;
      }

      return <Route {...rest} component={Component} />;
    }
    return <Redirect to={loginLink} />;
  }

  if (path === 'OUTSIGN') {
    return <Route {...rest} component={Component} />;
  }

  if (!isPrivate) {
    return <Route {...rest} component={Component} />;
  }

  if (path !== 'LOGIN') {
    if (!authorizationToken) {
      const { params } = computedMatch;

      const redirectTo = mountRedirectTo({ path, params, loginLink });

      return <Redirect to={redirectTo} />;
    }
  }

  if (!companyToken && path !== 'COMPANIES' && path !== 'NEWCOMPANY')
    return <Redirect to="/Companies" />;

  if (path === 'USERPAGE') {
    const { hash } = computedMatch.params;
    return <App route="UserPage" hash={hash} Component={Component} {...rest} />;
  }

  return <App route={path} Component={Component} {...rest} />;
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
};

RouteWrapper.defaultProps = {
  isPrivate: true,
};

export default RouteWrapper;
