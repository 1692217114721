import React, { useState, useRef } from 'react';
import JsonTable from '~/easy-components/JsonTable';
import Button from '~/easy-components/Button';

import { Container, Footer } from './styles';
import UserTableService from '~/services/UserTableService';
import Toast from '~/easy-components/Toast';
import TreatError from '~/easy-components/TreatError';
import FlexSpace from '~/easy-components/FlexSpace';
import BackCircleButton from '~/components/BackCircleButton';
import HeaderPage from '~/easy-components/HeaderPage';
import Title from '~/easy-components/Title';

import systemColors from '~/styles/colors';

/**
 * @typedef {Object} Props
 * @property {string} tableName
 * @property {Object[]} columns
 * @property {Object[]} data
 * @property {Object} reportSettings
 * @property {(isLoading: boolean) => void} showLoading
 * @property {(text: string) => string} t
 */

/**
 * @param {Props} props
 */
function UserTable({
  title,
  tableName,
  columns,
  reportSettings,
  data: dataProp,
  t,
  showLoading,
}) {
  const [data, setData] = useState(dataProp);
  const dataCacheRef = useRef(data);
  const [currentData, setCurentData] = useState(data);
  const tableRef = useRef();

  const updateUserTableData = async () => {
    const newData = await UserTableService.getData({
      tableName,
    });

    setData(newData);
    dataCacheRef.current = newData;
  };

  const { rowKeyField } = reportSettings;

  const oldIds = dataCacheRef.current.map(row => row[rowKeyField]);
  const newIds = currentData.map(row => row[rowKeyField]);

  const newLines = currentData.filter(row => {
    return !oldIds.includes(row[rowKeyField]);
  });

  const deletedLines = dataCacheRef.current.filter(row => {
    return !newIds.includes(row[rowKeyField]);
  });

  const deletedIds = deletedLines.map(row => row[rowKeyField]);

  const updatedLines = currentData.filter(row => {
    const oldRow = dataCacheRef.current.find(
      r => r[rowKeyField] === row[rowKeyField]
    );

    if (!oldRow) return false;

    if (deletedIds.includes(oldRow[rowKeyField])) return false;

    const rowEntries = Object.entries(row);

    const entriesWithoutEasyLineId = rowEntries.filter(
      ([key]) => key !== 'EasyLineId'
    );

    const rowToCompare = Object.fromEntries(entriesWithoutEasyLineId);

    return JSON.stringify(oldRow) !== JSON.stringify(rowToCompare);
  });

  const onSave = async () => {
    const removeEasyLineId = row => {
      const rowEntries = Object.entries(row);

      const entriesWithoutEasyLineId = rowEntries.filter(
        ([key]) => key !== 'EasyLineId'
      );

      return Object.fromEntries(entriesWithoutEasyLineId);
    };

    try {
      showLoading(true);

      await UserTableService.updateData({
        tableName,
        data: {
          newLines: newLines.map(removeEasyLineId),
          updatedLines: updatedLines.map(removeEasyLineId),
          deletedLines: deletedLines.map(removeEasyLineId),
        },
      });

      await updateUserTableData();

      Toast.success(t('message.SystemMessageSuccess'));
    } catch (err) {
      TreatError.showError(err);
    } finally {
      showLoading(false);
    }
  };

  const noUpdates =
    newLines.length === 0 &&
    updatedLines.length === 0 &&
    deletedLines.length === 0;

  return (
    // @ts-ignore

    <Container>
      <HeaderPage
        backgroundColor={systemColors.headerBg}
        color={systemColors.headerTitle}
      >
        <Title>{`${title} - @${tableName}`}</Title>
      </HeaderPage>

      <JsonTable
        t={t}
        ref={tableRef}
        data={data}
        columns={columns}
        reportSettings={reportSettings}
        showLoading={showLoading}
        onChange={tableProps => setCurentData(tableProps.data)}
      />
      <Footer>
        <BackCircleButton />
        <FlexSpace />
        <Button disabled={noUpdates} onClick={onSave} buttonType="Emphasized">
          {t('Save')}
        </Button>
      </Footer>
    </Container>
  );
}

export default UserTable;
