import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex: 1;
  border-bottom: ${props => (props.isHeader ? 'none' : '1px solid #f0f0f0')};
`;

export const Container = styled.div`
  padding: ${props => (props.isHeader ? '0px' : '10px 20px')};
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Content = styled.div`
  flex: 1;
  /** {
    color: #444;
    font-size: 0.9rem;
  } */
  color: #555;
  .main {
    font-weight: 1rem;
    font-weight: 600;
    white-space: break-spaces;

    div {
      span {
        font-size: 3rem;
      }
    }
  }

  .small {
    * {
      font-size: 0.8rem;
    }
  }

  .normal {
    * {
      font-size: 1rem;
    }
  }

  .big {
    * {
      font-size: 2rem;
    }
  }

  .bigger {
    font-size: 3rem !important;
    div {
      font-size: 3rem !important;
      span {
        font-size: 3rem !important;
      }
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const VStatus = styled.div`
  width: 6px;
  background-color: ${props => props.backgroundColor};
`;
