import React, { useState } from 'react';
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';
import TreatError from '~/easy-components/TreatError';
import ConfirmDialog from '~/easy-components/ConfirmDialog';
import TemplatesService from '~/applications/CRM/services/TemplatesService';
import Toast from '~/easy-components/Toast';
import colors from '~/styles/colors';
import { Container, Content, IconButton } from './styles';

function TemplateList({ items, onSelectItem, type, t, showLoading }) {
  const [listItems, setListItems] = useState(items);

  return (
    <Container>
      {listItems.map(item => {
        return (
          <Content
            onClick={async () => {
              await onSelectItem({ item });
            }}
          >
            <h2>{item.name}</h2>
            {type === 'O' && item.isSystem && <span>{t('System')}</span>}
            {type === 'O' && !item.isSystem && (
              <IconButton>
                <FaTrash
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    ConfirmDialog({
                      title: t('DeleteTemplate'),
                      message: t('message.DeleteMessageQuestion'),
                      buttons: [
                        {
                          label: t('Yes'),
                          onClick: async () => {
                            try {
                              showLoading(true);

                              const updatedList = listItems.filter(
                                listItem => listItem.id !== item.id
                              );

                              await TemplatesService.delete(item.id);

                              setListItems(updatedList);

                              Toast.success(t('message.SystemMessageSuccess'));
                            } catch (error) {
                              TreatError.showError(error);
                            } finally {
                              showLoading(false);
                            }
                          },
                        },
                        {
                          label: t('No'),
                        },
                      ],
                    });
                  }}
                  size={15}
                  color={colors.red}
                />
              </IconButton>
            )}
          </Content>
        );
      })}
    </Container>
  );
}

export default TemplateList;
