import styled from 'styled-components';

export const Frame = styled.iframe`
  height: 100%;
  flex: 1;
`;

export const Permission = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    color: #496774;
    font-size: 1.4rem;
    font-weight: 400;
  }
`;
