// @ts-check
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import Button from '~/easy-components/Button';
import useLocale from '~/hooks/useLocale';
import SwipeModalButtons from '../SwipeModalButtons';
import CellContent from '../Components/CellContent';

import { Modal, Container, Line, Title, Body, Footer } from './styles';

import { PageContext } from '../index';
import Header from './Header';
/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {import('../index').ComponentState['mobileSelectedRowKeyValue']} props.rowKeyValue
 */
function Detail({ rowKeyValue, isOpen, onClose }) {
  const t = useLocale('_Global');

  const {
    getSwipeButtonsSettings,
    modalDetailButtonsRef,
    dispatch,
    getCellContentProps,
    tableProps,
  } = useContext(PageContext);

  const rowData =
    tableProps.data &&
    tableProps.data.find(row => row[tableProps.rowKeyField] === rowKeyValue);

  const rowProps = {
    columns: tableProps.columns,
    rowData: rowData || {},
    rowKeyValue,
    selectedRows: tableProps.selectedRows,
    editingMode: tableProps.editingMode,
    dispatch,
    editableCells: tableProps.editableCells,
    isSelectedRow: tableProps.selectedRows
      ? tableProps.selectedRows.includes(rowKeyValue)
      : false,
    isDetailsRowShown: tableProps.detailsRows
      ? tableProps.detailsRows.includes(rowKeyValue)
      : false,
    rowEditableCells: tableProps.editableCells,
    rowKeyField: tableProps.rowKeyField,
    childComponents: {},
  };

  const allButtons = getSwipeButtonsSettings({
    rowProps,
  });

  const buttons = [...allButtons.rightButtons, ...allButtons.leftButtons];

  const hiddenTypes = ['button', 'selection'];

  return (
    <Modal className={isOpen && 'active'} direction="right">
      <Container>
        <Header
          modalDetailButtonsRef={modalDetailButtonsRef}
          data={rowProps.rowData || {}}
          buttons={buttons}
          rowProps={rowProps}
        />
        <Body>
          {rowProps.columns
            .filter(c => c.settings && !hiddenTypes.includes(c.settings.type))
            .filter(c => c.visible)
            .map(column => {
              /** @type {React.PropsWithChildren<import('ka-table/props').ICellTextProps>} */
              const cellProps = {
                column,
                rowData: rowProps.rowData || {},
                value: rowProps.rowData[column.key],
                rowKeyValue: rowProps.rowKeyValue,
                dispatch,
                rowKeyField: rowProps.rowKeyField,
                editingMode: rowProps.editingMode,
                childComponents: {},
                field: column.key,
                isDetailsRowShown: rowProps.isDetailsRowShown,
                isSelectedRow: rowProps.isSelectedRow,
                selectedRows: tableProps.selectedRows,
              };

              const cellContentProps = getCellContentProps({
                props: cellProps,
              });

              return (
                <Line key={column.key}>
                  <Title>{column.title}</Title>
                  <CellContent {...cellContentProps} />
                </Line>
              );
            })}
        </Body>
        <Footer>
          <Button onClick={onClose}>{t('Close')}</Button>
        </Footer>
      </Container>
      <SwipeModalButtons ref={modalDetailButtonsRef} />
    </Modal>
  );
}

export default Detail;
