import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;

  z-index: 10;
  transition: opacity 1s ease 0.5s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  color: #333;
  padding: 20px;
  border-radius: 5px;
`;
