/* eslint-disable import/no-cycle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useCallback, useContext } from 'react';
import clientEvent from '~/applications/ClientEventHandler';
import MasterDetail from '~/components/Pages/MasterDetail';
import Form from '~/easy-components/Form';
import HeaderContent from '~/easy-components/HeaderContent';
import TabContent from '~/easy-components/TabContent';
import TreatError from '~/easy-components/TreatError';
import Toast from '~/easy-components/Toast';
import QueryService from '~/services/QueryService';
import AttachmentService from '~/services/AttachmentService';
import CardTemplate from '~/easy-components/CardTemplate';
import Input from '~/components/Forms/Input';
import { Modal } from '~/easy-components';
import LayoutReportsViewer from '~/applications/CRM/components/LayoutReportsViewer';
import useComponentState from '~/hooks/useComponentState';
import { createSyncFunctionByString } from '~/easy-components/AsyncFunctionString';
import EmailModal from '../EmailModal';
import { JsonPageContext } from '../context';
import StatusHeader from '../StatusHeader';

function MasterDetailLayout({ settings }) {
  const {
    showLoading,
    route,
    // settings,
    isReadOnly,
    dispatch,
    showReport,
    t,
    isUserPage,
    isListOpen,
  } = useContext(JsonPageContext);

  const formRef = useRef();
  const masterDetailRef = useRef();
  const emailModalRef = useRef();

  const [
    componentState,
    setComponentState,
    updateComponent,
  ] = useComponentState({
    header: {},
    formData: {},
    errors: {},
    isDocumentIsReadOnly: false,
    isOpenReportsModal: false,
    menus: [],
  });

  const {
    header,
    formData,
    errors,
    isDocumentIsReadOnly,
    isOpenReportsModal,
    menus,
  } = componentState;

  const onShowLayout = async ({ report }) => {
    setComponentState('isDocumentIsReadOnly', false);
    setComponentState('isOpenReportsModal', false, true);

    try {
      showLoading(true);

      if (report) {
        const data = formRef.current.getData();

        const paramList = [];

        report.parameters.forEach(reportParam => {
          // eslint-disable-next-line prefer-const
          let { value = null, defaultValue = null } = reportParam;

          if (reportParam.screenFieldName) {
            const screenValue = data[reportParam.screenFieldName];

            value =
              screenValue == null || screenValue === undefined
                ? defaultValue
                : screenValue;
          }

          paramList.push({
            name: reportParam.paramFieldName,
            value,
            type: reportParam.type || 'string',
          });
        });

        let externalData = null;

        if (paramList.length > 0) {
          externalData = {};

          paramList.forEach(param => {
            externalData[param.name] = param.value;
          });
        }

        showReport({
          reportCode: report.reportCode,
          externalData,
          dispatch,
        });
      }
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
  };

  const onPrint = async () => {
    if (settings.printLayouts && settings.printLayouts.length > 0) {
      const report = settings.printLayouts.find(rep => rep.isDefault);
      if (report) onShowLayout({ report });
      else Toast.info(t('message.NotDefaultReport'));
    } else {
      Toast.info(t('message.NotDefaultReport'));
    }
  };

  const openMailModal = () => emailModalRef.current.show();

  const onMenus = () => {
    const hasPrintLayouts = !!settings.printLayouts;

    const hasEmail = !!settings.enableEmail;

    setComponentState('menus', [
      {
        icon: 'HiOutlineDocumentReport',
        id: 'reports',
        visible: true,
        disabled: !hasPrintLayouts,
        text: t('Reports'),
        handler: () => {
          setComponentState('isOpenReportsModal', true, true);
        },
      },
      {
        icon: 'AiOutlinePrinter',
        id: 'print',
        visible: true,
        disabled: !hasPrintLayouts,
        text: t('Print'),
        handler: onPrint,
      },
      {
        icon: 'MdMail',
        id: 'email',
        visible: hasEmail,
        disabled: !hasEmail,
        text: t('Email'),
        handler: openMailModal,
      },
    ]);
  };

  const onLoadData = ({ data }) => {
    setComponentState('errors', {});

    onMenus();

    setComponentState('header', data || {});

    setComponentState('formData', data);

    updateComponent();
  };

  const onGetDefault = defaultData => {
    return {
      ...defaultData,
    };
  };

  function onValidate() {
    return true;
  }

  function onSaveError({ error }) {
    TreatError.showError(error, e => {
      setComponentState('errors', e, true);
    });
  }

  const refreshHeader = async () => {
    // colocamos o timeout porque o estado nao contem os valores do jsontable atualizados, dessa forma garantimos que os dados estão atualizados
    setTimeout(async () => {
      const data = formRef.current.getData();

      const newData = await clientEvent({
        eventName: 'onRefreshHeader',
        run: 'before',
        params: {
          settings,
          formRef,
        },
        data,
      });

      setComponentState('header', newData || data, true);

      await clientEvent({
        eventName: 'onRefreshHeader',
        run: 'after',
        params: {
          settings,
          formRef,
        },
        data: newData || data,
      });
    }, 100);
  };

  const onSave = async () => {
    try {
      showLoading(true);
      setComponentState('errors', {}, true);
    } catch (err) {
      TreatError.showError(err, e => {
        setComponentState('errors', e, true);
      });
    } finally {
      showLoading(false);
    }
  };

  const onDelete = async () => {
    return true;
  };

  const isShowDelete = !!header[settings.keyField];

  settings.dynamicFunctionProps.setPageReadOnly = status => {
    const formDataNew = formRef.current.getData();
    for (const prop in formDataNew) {
      const field = formRef.current.getFieldRef(prop);

      if (field && field.setReadOnly) {
        field.setReadOnly(status);
      }
    }

    setComponentState('isDocumentIsReadOnly', status, true);
  };

  const getBestMaxFileSizeString = sizeInKB => {
    const ONE_KB = 1;
    const ONE_MB = ONE_KB * 1000;
    const ONE_GB = ONE_MB * 1000;
    const ONE_BYTE = ONE_KB / 1000;

    if (sizeInKB >= ONE_GB) {
      const sizeInGB = sizeInKB / ONE_GB;

      return `${Number(sizeInGB.toFixed(6))} GB`;
    }

    if (sizeInKB >= ONE_MB) {
      const sizeInMB = sizeInKB / ONE_MB;

      return `${Number(sizeInMB.toFixed(6))} MB`;
    }

    if (sizeInKB >= ONE_KB) {
      return `${Number(sizeInKB.toFixed(6))} KB`;
    }

    const sizeInByte = sizeInKB / ONE_BYTE;

    const plural = sizeInByte > 1 ? 's' : '';

    return `${Number(sizeInByte.toFixed(6))} Byte${plural}`;
  };

  const validateUploadInLine = ({ file, column, tableSettings = {} }) => {
    let { fileMaxSize } = tableSettings;

    if (column && column.settings && column.settings.fileMaxSize) {
      fileMaxSize = column.settings.fileMaxSize;
    }

    if (!!fileMaxSize && fileMaxSize === -1) return true;

    if (file.size <= fileMaxSize * 1000) return true;

    const bestMaxFileSizeString = getBestMaxFileSizeString(fileMaxSize);

    TreatError.showError(
      `Tamanho do arquivo deve ser no máximo de ${bestMaxFileSizeString}.`
    );

    return false;
  };

  const sendAttachmentToServer = async ({ file }) => {
    try {
      showLoading(true);
      const attachmentService = new AttachmentService();

      const { sourcePath, fileName } = await attachmentService.uploadFile({
        fileData: file,
      });

      const fullPath = `${sourcePath}${fileName}`;

      return { fullPath };
    } catch (err) {
      TreatError.showError(err);
      throw err;
    } finally {
      showLoading(false);
    }
  };

  const getAttachmentsDownloadToken = async ({ sourcePath, fileName }) => {
    try {
      showLoading(true);
      const attachmentService = new AttachmentService();

      const token = await attachmentService.getToken({
        sourcePath,
        fileName,
      });

      return token;
    } catch (err) {
      TreatError.showError(err);
      throw err;
    } finally {
      showLoading(false);
    }
  };

  const getInputLinkerData = ({
    filter,
    rowKeyValue,
    rowData,
    queryCode,
    sql,
  }) => {
    try {
      showLoading(true);

      const currentFormData = formRef.current.getData();

      const params = { ...currentFormData, ...rowData, filter, rowKeyValue };

      if (sql) {
        return QueryService.execute(1, sql, params);
      }

      if (queryCode) {
        return QueryService.executeByCode(queryCode, params);
      }
    } catch (err) {
      TreatError.showError(err);
    } finally {
      showLoading(false);
    }

    return [];
  };

  const searchInComponents = useCallback((prop, components = []) => {
    for (let i = 0; i < components.length; i += 1) {
      const component = components[i];
      if (
        typeof component === 'object' &&
        component.type &&
        typeof component.type === 'string' &&
        component.type.startsWith('tab')
      ) {
        return searchInComponents(prop, component.columns);
      }

      if (typeof component === 'object' && component.fields) {
        return searchInComponents(prop, component.fields);
      }

      if (
        typeof component === 'object' &&
        component.name &&
        component.name === prop
      ) {
        return true;
      }
    }

    return false;
  }, []);

  const HiddenInputs = useCallback(() => {
    const hiddenInputsProps = [
      settings.headerTitle,
      settings.headerDescription,
      ...(settings.headerAttributes || []),
      ...(settings.headerInformations || []),
    ];

    const hiddenInputs = hiddenInputsProps.filter(
      prop => !searchInComponents(prop, settings.components)
    );

    return hiddenInputs.map(hiddenInput => (
      <Input name={hiddenInput} hidden value={header[hiddenInput]} />
    ));
  }, [
    header,
    searchInComponents,
    settings.components,
    settings.headerAttributes,
    settings.headerDescription,
    settings.headerInformations,
    settings.headerTitle,
  ]);

  const onLoadExternalData = async ({ data, loadData }) => {
    if (typeof data === 'object') {
      loadData(data);
    } else {
      const dataObj = {};

      dataObj[settings.keyField] = data;
      await masterDetailRef.current.executeSelectionItem(dataObj);
    }
  };

  const onSelectedItem = async ({ selectedItem }) => {
    if (settings.queryGet) {
      const responseSql = await QueryService.execute(
        1,
        settings.queryGet,
        selectedItem
      );

      if (responseSql.length > 0) {
        const pageData = responseSql[0];

        /* const queriesResponse = await Promise.all(
          (settings.queryAux || []).map(aux => {
            return QueryService.execute(1, aux.query, selectedItem);
          })
        );

        queriesResponse.forEach((queryResponse, idx) => {
          const { prop } = settings.queryAux[idx];
          pageData[prop] = queryResponse;
        }); */

        return pageData;
      }

      return null;
    }

    Toast.warn(t('message.QueryGetNotFound'));
    return null;
  };

  const gridAuxScope = {
    refreshHeader,
  };

  if (settings.isShowSave === undefined) {
    settings.isShowSave = true;
  }
  if (settings.isShowDelete === undefined) {
    settings.isShowDelete = true;
  }
  if (settings.isShowNew === undefined) {
    settings.isShowNew = true;
  }

  const formatHeaderContent = prop => {
    let divContent = header[prop];

    if (!divContent) {
      return null;
    }

    if (typeof divContent === 'object' && !Array.isArray(divContent)) {
      return null;
    }

    if (settings.headerFormatters && settings.headerFormatters[prop]) {
      const dynamicFunction = createSyncFunctionByString({
        functionString: settings.headerFormatters[prop],
      });
      divContent = dynamicFunction({
        ...settings.dynamicFunctionProps,
        value: header[prop],
      });
    }

    return <div>{divContent}</div>;
  };

  return (
    <>
      <MasterDetail
        isUserPage={isUserPage}
        ref={masterDetailRef}
        t={t}
        enableDesignMode
        showLoading={showLoading}
        route={route}
        forceTitle={settings.pageName}
        keyField={settings.keyField}
        settings={settings}
        isReadOnly={isReadOnly}
        menus={menus}
        sideWidth={350}
        isShowDelete={
          !isReadOnly &&
          !isDocumentIsReadOnly &&
          isShowDelete &&
          settings.isShowDelete
        }
        isShowNew={settings.isShowNew}
        isShowSave={!isReadOnly && !isDocumentIsReadOnly && settings.isShowSave}
        onSearch={async ({ filter, auxFilters }) => {
          if (settings.queryList) {
            const responseSql = await QueryService.execute(
              1,
              settings.queryList,
              {
                filter,
                ...auxFilters,
              }
            );

            return responseSql;
          }
          Toast.warn(t('message.QueryListNotFound'));
          return [];
        }}
        onRenderListItem={item => {
          return (
            <CardTemplate
              key={item}
              template={settings.listTemplate}
              formatters={settings.listFormatters}
              data={item}
              listColor={settings.listColor}
              style={{
                ...settings.listTemplateStyle,
              }}
              auxScope={{
                ...settings.dynamicFunctionProps,
              }}
            />
          );
        }}
        formRef={formRef}
        onValidate={onValidate}
        onLoadData={onLoadData}
        onLoadExternalData={onLoadExternalData}
        onGetDefault={onGetDefault}
        onSaveError={onSaveError}
        onPrint={onPrint}
        onSelectedItem={onSelectedItem}
        onSave={onSave}
        onDelete={onDelete}
        isListOpen={isListOpen}
        auxScope={{
          refreshHeader,
        }}
      >
        <StatusHeader
          templateData={header}
          headerColor={settings.headerColor}
        />
        <HeaderContent
          height="90px"
          title={header[settings.headerTitle]}
          template={settings.headerTemplate}
          templateData={header}
          formatters={settings.headerFormatters}
          description={header[settings.headerDescription]}
          attributes={(settings.headerAttributes || []).map(
            formatHeaderContent
          )}
          informations={(settings.headerInformations || []).map(
            formatHeaderContent
          )}
          style={{
            ...settings.headerTemplateStyle,
          }}
          auxScope={{
            ...settings.dynamicFunctionProps,
          }}
        />
        <Form ref={formRef} data={formData} errors={errors}>
          <HiddenInputs />

          <TabContent
            formRef={formRef}
            settings={settings}
            t={t}
            isReadOnly={isReadOnly || isDocumentIsReadOnly}
            onTabGridUploadInLine={sendAttachmentToServer}
            onTabGridValidateUploadInLine={validateUploadInLine}
            onTabGridDeleteUploadInLine={() => true}
            onTabGridGetDownloadToken={getAttachmentsDownloadToken}
            onTabGridOpenInputLinkerInLine={getInputLinkerData}
            gridAuxScope={gridAuxScope}
            showLoading={showLoading}
          />
        </Form>
        <Modal isOpened={isOpenReportsModal}>
          <LayoutReportsViewer
            onConfirm={onShowLayout}
            reports={settings.printLayouts}
          />
        </Modal>
      </MasterDetail>
      <EmailModal
        preview
        ref={emailModalRef}
        emailSettings={settings.emailSettings}
        formRef={formRef}
        formData={formData}
        settings={settings}
        showLoading={showLoading}
        t={t}
      />
    </>
  );
}

export default MasterDetailLayout;
