const createMenu = ({ isUserField, auxContextEvents = [] }) => {
  const menus = [
    ...auxContextEvents,
    {
      icon: 'TiDeleteOutline',
      iconColor: '#ff9999',
      title: 'Delete',
      prop: 'footerElements',
      type: 'deleteElement',
      visible: isUserField,
    },
    {
      type: 'boolean',
      prop: 'hidden',
      defaultValue: false,
      options: [
        {
          icon: 'FaEye',
          title: 'ShowField',
          value: false,
        },
        {
          icon: 'FaEyeSlash',
          title: 'HiddenField',
          value: true,
        },
      ],
    },
    {
      icon: 'MdSettings',
      title: 'CustomView',
      type: 'custom',
      prop: 'hidden',
      disabled: false,
      value: null,
    },
    {
      type: 'separator',
    },
    {
      title: 'onClick',
      prop: 'onClick',
      type: 'event',
      visible: true,
      disabled: false,
    },
  ];

  return menus;
};

export default createMenu;
