/* eslint-disable no-restricted-syntax */
import request from '../../Request';

class BatchNumberDetailsService {
  async list({ filter, auxFilters }) {
    let filters = '';

    // eslint-disable-next-line guard-for-in
    for (const prop in auxFilters) {
      filters += `&${prop}=${auxFilters[prop]}`;
    }

    return request({
      url: `APP/CRM/BatchNumberDetails?$filter=${filter}${filters}`,
    });
  }

  async get({ absEntry, itemCode, batchNum, sysNumber }) {
    return request({
      url: `APP/CRM/BatchNumberDetails/${absEntry}?$itemCode=${itemCode}&$batchNum=${batchNum}&$sysNumber=${sysNumber}`,
    });
  }

  async save({ settings, data }) {
    if (data.AbsEntry) {
      return request({
        url: `APP/CRM/BatchNumberDetails/${data.AbsEntry}`,
        method: 'PUT',
        settings,
        data,
      });
    }
    return null;
  }

  async getBatchQuantities({ itemCode, whsCode, filter }) {
    return request({
      url: `APP/CRM/BatchNumberDetails/Quantities/${encodeURIComponent(
        itemCode
      )}?$whsCode=${whsCode}&$filter=${filter || ''}`,
    });
  }

  async getWarehouseInfo({ whsCode }) {
    return request({
      url: `APP/CRM/BatchNumberDetails/getWarehouseInfo?$whsCode=${whsCode}`,
    });
  }
}

export default new BatchNumberDetailsService();
