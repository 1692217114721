/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import { PageContext } from '../../index';

import CellContent from '../CellContent';
import { Container } from './styles';

function CellGroup({ cellProps }) {
  const { getCellContentProps } = useContext(PageContext);

  const [title, value] = cellProps.text.split(':');

  if (!value) {
    return <div>{cellProps.text}</div>;
  }

  const cellContentProps = getCellContentProps({
    props: {
      ...cellProps,
      value: value.trim(),
    },
  });

  return (
    <Container>
      <div>{title}:</div>
      <CellContent {...cellContentProps} />
    </Container>
  );
}

export default CellGroup;
