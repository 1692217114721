import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;

  > iframe {
    flex: 1;
    border: 0;
  }
`;
