export const outSignSucess = data => {
  return {
    type: 'OUTSIGN_SUCCESS',
    payload: {
      ...data,
    },
  };
};

export const loginSuccess = (authorizationToken, msalRef) => {
  return {
    type: 'LOGIN_SUCCESS',
    payload: {
      authorizationToken,
      msalRef,
    },
  };
};

export const logoffSuccess = () => {
  document.title = 'Aster';
  return {
    type: 'LOGOFF_SUCCESS',
    payload: {
      authorizationToken: null,
      msalRef: null,
    },
  };
};
