import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

function TreatError({ children }) {
  function fallbackRender({ error }) {
    return <div style={{ color: '#ff9999' }}>{error.message}</div>;
  }

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onError={_error => {
        setTimeout(() => {
          const iframes = document.querySelectorAll('iframe');

          // Verifica se existem iframes antes de tentar remover
          if (iframes.length > 0) {
            // Seleciona o último iframe da lista
            const lastIframe = iframes[iframes.length - 1];

            // Remove o último iframe do DOM
            lastIframe.parentNode.removeChild(lastIframe);
          }
        }, 500);
      }}
      onReset={() => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export default TreatError;
