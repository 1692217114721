/* eslint-disable react/prop-types */
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';

import Toast from '~/easy-components/Toast';
import Modal from '~/easy-components/Modal';

import Page from './Page';
import useLocale from '~/hooks/useLocale';

function BatchNumberSelection(props, ref) {
  const t = useLocale('BatchNumberSelection');

  const itemLinesRef = useRef([]);

  const [state, setState] = useState({
    title: '',
    isReadOnly: false,
    isOpen: false,
    selectedIndex: null,
    onConfirm: () => {},
    // t: () => {},
    showBinPosition: false,
  });

  const parseItemLines = itemLines => {
    return itemLines.map(item => {
      const itemLine = {
        ItemCode: item.ItemCode,
        WarehouseCode: item.WarehouseCode,
        _IsManagedByBatch: item._IsManagedByBatch,
        Quantity: item.Quantity,
        UnitQuantity: item.UnitQuantity || item._UnitQuantity,
        BatchNumbers: item.BatchNumbers,
        ...item,
      };

      return {
        ...itemLine,
      };
    });
  };

  useImperativeHandle(ref, () => {
    return {
      show: ({
        // t,
        title,
        isReadOnly,
        itemLines,
        selectedIndex,
        onConfirm,
        showBinPosition,
      }) => {
        const itemsParsed = parseItemLines(itemLines);
        const itemsManagedByBatch = itemsParsed.filter(
          item => item._IsManagedByBatch === 'Y'
        );

        if (itemsManagedByBatch.length > 0) {
          itemLinesRef.current = JSON.parse(JSON.stringify(itemsParsed));

          setState({
            title,
            isReadOnly,
            isOpen: true,
            selectedIndex,
            onConfirm,
            // t,
            showBinPosition,
          });
        } else {
          Toast.warn(t('message.BatchNumberSelection'));
        }
      },
    };
  });

  return (
    <Modal isOpened={state.isOpen}>
      <Page
        itemLinesRef={itemLinesRef}
        title={state.title}
        t={t}
        isReadOnly={state.isReadOnly}
        selectedIndex={state.selectedIndex}
        onClose={() => setState({ ...state, isOpen: false })}
        onConfirm={state.onConfirm}
        showBinPosition={state.showBinPosition}
      />
    </Modal>
  );
}

export default forwardRef(BatchNumberSelection);
