// import request from '../../Request';
import BaseMasterDataService from './BaseMasterDataService';
import request from '../../Request';

class TagSignatureService extends BaseMasterDataService {
  constructor() {
    super({
      route: 'TagSignature',
      primaryKeyField: 'Code',
    });
  }

  async getData({ tagValue }) {
    return request({
      url: `APP/CRM/TagSignature/${tagValue}/data`,
    });
  }
}

export default new TagSignatureService();
