/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { FiSave as SaveIcon } from '@react-icons/all-files/fi/FiSave';
import CodeEditor from '~/easy-components/CodeEditor';
import colors from '~/styles/colors';
import Icon from '~/easy-components/Icon';

function SqlModal({ title, code, t, onSave, onConfirm, onView }, ref) {
  const codeEditorRef = useRef();
  const contentRef = useRef();

  const [editorValue, setEditorValue] = useState(code);

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return editorValue;
    },
    setVisible: visible => {
      contentRef.current.style.display = visible ? 'flex' : 'none';
    },
  }));

  return (
    <div ref={contentRef} style={{ flex: 1 }}>
      <CodeEditor
        t={t}
        ref={codeEditorRef}
        title={title}
        editorType="sql"
        value={editorValue || ''}
        onChange={setEditorValue}
        onCtrlS={async () => {
          if (onSave) {
            await onSave({ code: editorValue });
          } else if (onConfirm) {
            await onConfirm({ code: editorValue });
          }
        }}
        buttons={[
          onSave && (
            <div
              key="save"
              data-tip={t('Save')}
              data-for="toolbarTooltipQueries"
              onClick={async () => {
                await onSave({ code: editorValue });
              }}
            >
              <SaveIcon size={24} color={colors.mainMenuIcons} />
            </div>
          ),
          onView && (
            <div
              key="view"
              data-tip={t('View')}
              data-for="toolbarTooltipQueries"
              onClick={async () => {
                await onView();
              }}
            >
              <Icon name="FaEye" size={24} color={colors.mainMenuIcons} />
            </div>
          ),
        ]}
      />
    </div>
  );
}

export default forwardRef(SqlModal);
