/* eslint-disable react/prop-types */
// @ts-ignore
import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
} from 'react';
import Button from '~/easy-components/Button';
import AttachmentView from './AttachmentView';
import SelectAll from '../SelectAll';

import { Container, Header, Body, Footer } from './styles';

/**
 * @typedef {Object} Props
 * @property {string} newAttachmentButtonLabel
 * @property {string} headerLabel
 * @property {(files: FileList) => Promise<{sourcePath: string, fileName: string}[]>} onNewAttachments
 */

/** @type {React.FC<Props>} */
const AttachmentsPanel = forwardRef(
  ({ newAttachmentButtonLabel, onNewAttachments, headerLabel, t }, ref) => {
    /** @type {import('react').MutableRefObject<HTMLInputElement>} */
    const uploadInputRef = useRef();

    /** @type {ReturnType<typeof useState<{sourcePath: string, fileName: string}[]>>} */
    const [data, setData] = useState([]);

    /** @type {ReturnType<typeof useState<number[]>>} */
    const [
      selectedAttachmentsIndexes,
      setSelectedAttachmentsIndexes,
    ] = useState([]);

    useImperativeHandle(ref, () => {
      return {
        setAttachments: attachments => setData(attachments),
        getSelectedAttachments: () => {
          return selectedAttachmentsIndexes.map(
            attachmentIndex => data[attachmentIndex]
          );
        },
      };
    });

    return (
      <Container>
        <input
          ref={uploadInputRef}
          hidden
          type="file"
          multiple
          onChange={e => {
            const { files } = e.target;

            onNewAttachments(files).then(newAttachments => {
              const newSelectionRange = {
                from: data.length,
                to: data.length + newAttachments.length - 1,
              };

              setData(oldData => [...oldData, ...newAttachments]);

              setSelectedAttachmentsIndexes(oldSelection => {
                const newSelection = [...oldSelection];

                for (
                  let i = newSelectionRange.from;
                  i <= newSelectionRange.to;
                  i += 1
                ) {
                  newSelection.push(i);
                }

                return newSelection;
              });
            });
          }}
        />
        <Header>
          <h3>{headerLabel}</h3>
        </Header>
        <Body>
          <SelectAll
            title={t('SelectAll')}
            onChange={isChecked => {
              setSelectedAttachmentsIndexes(() => {
                if (isChecked) {
                  return data.map((i, idx) => idx);
                }
                return [];
              });
            }}
          />
          {data.map((file, index) => (
            <AttachmentView
              fileName={file.fileName}
              isChecked={selectedAttachmentsIndexes.includes(index)}
              onChange={isChecked => {
                setSelectedAttachmentsIndexes(oldSelected => {
                  if (isChecked) {
                    return [...oldSelected, index];
                  }

                  return oldSelected.filter(s => s !== index);
                });
              }}
            />
          ))}
        </Body>
        <Footer>
          <Button
            onClick={() => {
              if (uploadInputRef.current) {
                uploadInputRef.current.click();
              }
            }}
          >
            {newAttachmentButtonLabel}
          </Button>
        </Footer>
      </Container>
    );
  }
);

export default AttachmentsPanel;
