import request from '../../Request';

class UserService {
  async getSalesperson() {
    return request({
      url: `APP/CRM/Users/Salesperson`,
    });
  }

  async getUsers({ filter }) {
    return request({
      url: `APP/CRM/Users?$filter=${filter || ''}`,
    });
  }

  async getSapUserPortal() {
    return request({
      url: `APP/CRM/Users/SapUserPortal`,
    });
  }

  async me() {
    return request({
      url: `APP/Me`,
    });
  }

  async getSettings() {
    return request({
      url: `User/Settings`,
    });
  }
}

export default new UserService();
