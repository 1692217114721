export default async function getUtilDayDate(
  year,
  month,
  utilDayCount,
  holidays = []
) {
  function isHoliday(date) {
    const dateString = date.toISOString().split('T')[0];
    return holidays.includes(dateString);
  }

  async function getDate() {
    const date = new Date(year, month - 1, 1);
    let utilDay = 0;

    while (utilDay < utilDayCount) {
      date.setDate(date.getDate() + 1);
      if (
        date.getDay() !== 0 &&
        date.getDay() !== 6 &&
        !isHoliday(date, holidays)
      ) {
        utilDay++;
      }
    }

    return date;
  }

  const utilDay = await getDate();

  return utilDay;
}
