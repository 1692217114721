/* eslint-disable react/prop-types */
import React, { memo, useRef, useState, useEffect } from 'react';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import { RiArrowUpSLine } from '@react-icons/all-files/ri/RiArrowUpSLine';
import { isMobile } from 'react-device-detect';

import PageHeader from '~/easy-components/PageHeader';
import PageBody from '~/easy-components/PageBody';
import PageFooter from '~/easy-components/PageFooter';
import FlexSpace from '~/easy-components/FlexSpace';
import Button from '~/easy-components/Button';
import TreatError from '~/easy-components/TreatError';
import Form from '~/easy-components/Form';
import Panel from '~/easy-components/Panel';
import Input from '~/easy-components/Form/Input';
import Linker from '~/easy-components/Form/Linker';
import Loading from '~/easy-components/Loading';

import TinyMCEText from '~/easy-components/TinyMCEText';
import ClientEventHandler from '~/applications/ClientEventHandler';
import { replacePlaceholdersText } from '~/easy-components/Helpers/replacePlaceholdersText';
import EmailService from '~/applications/CRM/services/EmailService';
import { ModalAbsolute, Container, Content, HideHeader, Main } from './styles';
import AttachmentsPanel from './AttachmentsPanel';
import EmailListPanel from './EmailListPanel';
import Icon from '../Icon';

/**
 * @typedef {Object} SendEmailProps
 * @property {Object} data
 * @property {string} data.toEmail
 * @property {string} data.copy
 * @property {string} data.subject
 * @property {string} data.body
 * @property {string} [data.reportCode]
 * @property {string} [data.fileName]
 * @property {boolean} [data.uniqueFileName]
 * @property {{fileName: string, sourcePath: string}[]} data.attachments
 *
 * @typedef {Object} Props
 * @property {(text: string) => string} t
 * @property {Object} settings
 * @property {string} title
 * @property {Object} data
 * @property {() => void} onClose
 * @property {(props: SendEmailProps) => void} onSendEmail
 * @property {Object} emailSettings
 * @property {string} emailSettings.toEmail
 * @property {string} emailSettings.copy
 * @property {string} emailSettings.subject
 * @property {string} emailSettings.body
 * @property {boolean} [emailSettings.uniqueFileName]
 * @property {boolean} [enableAttachments=false]
 * @property {{fileName: string, sourcePath: string}[]} [attachments=[]]
 * @property {(file: FileList) => Promise<{sourcePath: string, fileName: string}[]>} [onNewAttachments]
 *
 * @param {Props} props
 * @returns {ReturnType<React.FC<Props>>}
 */
function ModalEmail({
  t,
  settings,
  baseService,
  title,
  data,
  onClose,
  onSendEmail,
  emailSettings,
  attachments = [],
  enableAttachments = false,
  enableEmailsList = false,
  onNewAttachments,
  formatters,
}) {
  const showLayouts =
    emailSettings.printLayouts &&
    Array.isArray(emailSettings.printLayouts) &&
    emailSettings.printLayouts.length;

  const defaultPrintLayout =
    showLayouts && emailSettings.printLayouts.some(pl => pl.isDefault)
      ? emailSettings.printLayouts.find(pl => pl.isDefault)
      : null;

  const modalFormRef = useRef();
  const bodyRef = useRef();
  const isLoadingRef = useRef();
  const attachmentsPanelRef = useRef();
  const emailListPanelRef = useRef();

  const [isShow, setIsShow] = useState(!isMobile);
  const [isAttachmentsVisible, showAttachments] = useState(false);
  const [isEmailListVisible, showEmailList] = useState(false);

  const [state, setState] = useState({});

  const replaceEventsData = async () => {
    const eventResponseData = await ClientEventHandler({
      eventName: 'onGetData',
      run: 'before',
      data,
      params: {
        ...settings.dynamicFunctionProps,
        settings: {
          ...settings,
          ...emailSettings,
        },
      },
    });

    const email = replacePlaceholdersText({
      text: emailSettings.toEmail || '',
      placeholderTemplate: '{?}',
      data: {
        ...eventResponseData,
      },
      formatters,
      auxScope:
        settings && settings.dynamicFunctionProps
          ? settings.dynamicFunctionProps
          : null,
    });

    const copy = replacePlaceholdersText({
      text: emailSettings.copy || '',
      placeholderTemplate: '{?}',
      data: {
        ...eventResponseData,
      },
      formatters,
      auxScope:
        settings && settings.dynamicFunctionProps
          ? settings.dynamicFunctionProps
          : null,
    });

    const subject = replacePlaceholdersText({
      text: emailSettings.subject || '',
      placeholderTemplate: '{?}',
      data: {
        ...eventResponseData,
      },
      formatters,
      auxScope:
        settings && settings.dynamicFunctionProps
          ? settings.dynamicFunctionProps
          : null,
    });

    const body = replacePlaceholdersText({
      text: emailSettings.body || '',
      placeholderTemplate: '{?}',
      data: {
        ...eventResponseData,
      },
      formatters,
      auxScope:
        settings && settings.dynamicFunctionProps
          ? settings.dynamicFunctionProps
          : null,
    });

    const fileName = emailSettings.fileName
      ? replacePlaceholdersText({
          text: emailSettings.fileName,
          placeholderTemplate: '{?}',
          data: {
            ...eventResponseData,
          },
        }).trim()
      : null;

    return {
      email,
      copy,
      subject,
      body,
      fileName,
    };
  };

  const handleSendClick = async emailData => {
    const formData = emailData || modalFormRef.current.getData();

    const { _ToEmail, _Copy, _Subject, _Layout, _FileName, _Body } = formData;

    const body = _Body || bodyRef.current.getValue();

    let attachmentsToSend = [];
    let emailsListToSend = [];

    if (enableAttachments && attachmentsPanelRef.current) {
      attachmentsToSend = attachmentsPanelRef.current.getSelectedAttachments();
    }

    if (enableEmailsList && emailListPanelRef.current) {
      emailsListToSend = emailListPanelRef.current.getSelectedEmails();
    }

    onSendEmail({
      data: {
        toEmail: _ToEmail,
        copy: _Copy,
        subject: _Subject,
        body,
        reportCode: _Layout,
        attachments: attachmentsToSend,
        fileName: _FileName,
        uniqueFileName: emailSettings.uniqueFileName,
        emailsList: emailsListToSend,
      },
    });
  };

  // eslint-disable-next-line consistent-return
  const formatEmailFields = async () => {
    try {
      isLoadingRef.current.show();

      const {
        email,
        copy,
        subject,
        body,
        fileName,
      } = await replaceEventsData();

      const emailData = {
        _Body: body,
        _ToEmail: email.trimStart(),
        _Copy: copy.trimStart(),
        _Subject: subject.trimStart(),
        _FileName: fileName,
        _Layout: defaultPrintLayout ? defaultPrintLayout.reportCode : null,
        PrintLayout: defaultPrintLayout ? defaultPrintLayout.reportName : null,
      };

      setState(emailData);
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            TreatError.showError(error);
            onClose();
            break;

          default:
            TreatError.showError(error);
            onClose();
            break;
        }
      } else {
        TreatError.showError(error);
      }
    } finally {
      isLoadingRef.current.hide();
    }
  };

  const fieldProps = {
    labelWidth: 130,
    formRef: modalFormRef,
  };

  useEffect(() => {
    if (enableAttachments && attachmentsPanelRef.current) {
      if (baseService && !attachments && attachments.length === 0) {
        baseService
          .getAttachmentsByDocument({ docEntry: data.DocEntry })
          .then(attchs => {
            attachmentsPanelRef.current.setAttachments(
              attchs.map(a => ({
                fileName: `${a.FileName}.${a.FileExt}`,
                sourcePath: a.Path,
              }))
            );
          })
          .catch(e => {
            TreatError.showError(e);
          });
      }

      if (attachments.length && attachments.length > 0) {
        attachmentsPanelRef.current.setAttachments(attachments);
      }
    }

    if (enableEmailsList && emailListPanelRef.current) {
      EmailService.getEmailsDistribuitionList()
        .then(response => {
          emailListPanelRef.current.setEmailList(response);
        })
        .catch(e => {
          TreatError.showError(e);
        });
    }
  });

  useEffect(() => {
    formatEmailFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalAbsolute>
      <Container>
        <Loading ref={isLoadingRef} />
        <PageHeader>
          <div>{title}</div>
          <FlexSpace />
          {enableEmailsList && (
            <Icon
              name="TbMailPlus"
              size={20}
              color="#fff"
              onClick={() => {
                showEmailList(prev => !prev);
                if (isMobile && isAttachmentsVisible)
                  showAttachments(prev => !prev);
              }}
            />
          )}
          {enableAttachments && (
            <Icon
              name="MdAttachFile"
              size={20}
              color="#fff"
              onClick={() => {
                showAttachments(prev => !prev);
                if (isMobile && isEmailListVisible)
                  showEmailList(prev => !prev);
              }}
              style={{ marginLeft: '5px' }}
            />
          )}
        </PageHeader>
        <Main>
          <PageBody>
            <Content>
              <Form
                ref={modalFormRef}
                data={state}
                style={
                  isShow
                    ? { flex: 'initial', marginBottom: isMobile ? 0 : 20 }
                    : { display: 'none' }
                }
              >
                <Panel style={{ padding: 0, margin: 0 }}>
                  <Linker
                    label={t('_Layout')}
                    name="PrintLayout"
                    valueField="_Layout"
                    {...fieldProps}
                    hidden={!showLayouts}
                    method={() => {
                      const reportsData = emailSettings.printLayouts.map(
                        report => {
                          return {
                            value: report.reportCode,
                            label: report.reportName,
                          };
                        }
                      );
                      return reportsData;
                    }}
                  />
                  <Input
                    label={t('_ToEmail')}
                    name="_ToEmail"
                    {...fieldProps}
                  />
                  <Input label={t('_Copy')} name="_Copy" {...fieldProps} />
                  <Input
                    label={t('_Subject')}
                    name="_Subject"
                    {...fieldProps}
                  />
                  <Input
                    label={t('FileName')}
                    name="_FileName"
                    {...fieldProps}
                    hidden
                  />
                </Panel>
              </Form>
              <HideHeader>
                <button
                  type="button"
                  onClick={() => setIsShow(!isShow)}
                  style={isShow ? null : { marginTop: '10px' }}
                >
                  {isShow ? (
                    <RiArrowUpSLine size={22} color="#fff" />
                  ) : (
                    <RiArrowDownSLine
                      size={22}
                      color="#fff"
                      style={{ paddingTop: '5px' }}
                    />
                  )}
                </button>
              </HideHeader>
              <TinyMCEText text={state._Body} ref={bodyRef} />
            </Content>
          </PageBody>
          {enableEmailsList && isEmailListVisible && (
            <EmailListPanel
              t={t}
              ref={emailListPanelRef}
              headerLabel={t('DistribuitionList')}
            />
          )}
          {enableAttachments && isAttachmentsVisible && (
            <AttachmentsPanel
              t={t}
              ref={attachmentsPanelRef}
              headerLabel={t('Attachments')}
              newAttachmentButtonLabel={t('AddAttachment')}
              onNewAttachments={onNewAttachments}
            />
          )}
        </Main>
        <div>
          <PageFooter>
            <FlexSpace />
            <Button
              buttonType="Emphasized"
              onClick={e => {
                e.stopPropagation();
                handleSendClick();
              }}
            >
              {t('Send')}
            </Button>
            <Button
              onClick={e => {
                e.stopPropagation();
                onClose();
              }}
            >
              {t('Close')}
            </Button>
          </PageFooter>
        </div>
      </Container>
    </ModalAbsolute>
  );
}

export default memo(ModalEmail);
