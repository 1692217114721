export const selected = ({ companyToken, companyId, companyName }) => {
  return {
    type: 'COMPANY_SELECTED',
    payload: {
      companyToken,
      companyId,
      companyName,
    },
  };
};

export const change = ({ name }) => {
  return {
    type: 'COMPANY_CHANGE',
    payload: {
      name,
    },
  };
};

export const deleted = () => {
  return {
    type: 'COMPANY_DELETED',
    payload: null,
  };
};
