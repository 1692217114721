import React, { useState } from 'react';
import Icon from '~/easy-components/Icon';

import { Container } from './styles';

/**
 * @typedef {Object} Props
 * @property {React.RefObject<HTMLDivElement>} containerRef
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
function FullScreenButton({ containerRef }) {
  const [icon, changeIcon] = useState('FaExpandAlt');

  const isFullScreen = () => {
    if (!containerRef.current) return false;

    return containerRef.current.classList.contains('fullscreen');
  };

  const goFullscreen = () => {
    if (!containerRef.current) return;

    containerRef.current.classList.add('fullscreen');
    changeIcon('FaCompressAlt');

    const kaTableWrapper = containerRef.current.querySelector(
      '.ka-table-wrapper'
    );

    if (!kaTableWrapper) return;

    const contentDiv = containerRef.current.querySelector('#JsonTableContent');

    const contentDivHeight = contentDiv ? contentDiv.offsetHeight : 0;

    kaTableWrapper.style.maxHeight = `${contentDivHeight}px`;
  };

  const quitFullScreen = () => {
    if (!containerRef.current) return;

    containerRef.current.classList.remove('fullscreen');
    changeIcon('FaExpandAlt');
  };

  return (
    <Container
      type="button"
      onClick={() => (isFullScreen() ? quitFullScreen() : goFullscreen())}
    >
      <Icon name={icon} size="0.9rem" color="#728591" />
    </Container>
  );
}

export default FullScreenButton;
