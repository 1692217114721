/* eslint-disable import/no-cycle */
import React from 'react';

import CompareRender from '~/easy-components/Helpers/compareRender';

import LinkerCell from '../../CustomCells/LinkerCell';
import ButtonCell from '../../CustomCells/ButtonCell';
import JsonTableCell from '../../CustomCells/JsonTableCell';
import AttachmentCell from '../../CustomCells/AttachmentCell';
import InputText from '../../CustomCells/InputText';
import InputTextArea from '../../CustomCells/InputTextArea';
import InputNumber from '../../CustomCells/InputNumber';
import InputDate from '../../CustomCells/InputDate';
import InputTimer from '../../CustomCells/InputTimer';
import DisplayTimerCell from '../../CustomCells/DisplayTimerCell';
import DisplayNumberCell from '../../CustomCells/DisplayNumberCell';
import DisplayCell from '../../CustomCells/DisplayCell';
import DisplayDateCell from '../../CustomCells/DisplayDateCell';
import OptionCell from '../../CustomCells/OptionCell';
import SelectionCell from '../../CustomCells/SelectionCell';
import InputLinkerCell from '../../CustomCells/InputLinkerCell';
import validateAndSelectRow from '../../CustomActions/validateAndSelectRow';
import TemplateCell from '../../CustomCells/TemplateCell';
import ReadOnlyLinker from '../../CustomCells/ReadOnlyLinker';
import JSXCell from '../../CustomCells/JSXCell';

function CellContent({
  field,
  column,
  rowData,
  value,
  settings,
  reportSettings,
  onRefresh,
  showLoading,
  rowKeyValue,
  dispatch,
  props: cellProps,
  isModal = false,
  paramsModal,
  style,
  items,
  rawItems,
  readOnly,
  getSelectedData,
  isFocused,
  onAddNewLine,
  isSelectedRow,
  isOpenEditor,
  auxScope,
}) {
  const props = {
    field,
    column,
    rowData,
    value,
    settings,
    isModal,
    rowKeyValue,
    dispatch,
    style,
    cellProps,
    items,
    rawItems,
    getSelectedData,
    readOnly,
    isFocused,
    onAddNewLine,
    isSelectedRow,
    isOpenEditor,
    auxScope,
    reportSettings,
  };

  if (!column.settings) return null;

  if (!isOpenEditor) {
    switch (column.settings.type) {
      case 'attachment':
        return (
          <AttachmentCell
            {...props}
            settings={settings}
            showLoading={showLoading}
            reportSettings={reportSettings}
          />
        );

      case 'inputText':
        return <DisplayCell {...props} isFakeInput />;

      case 'inputTextArea':
        return <DisplayCell {...props} isFakeInput />;

      case 'inputNumber':
        return <DisplayNumberCell {...props} isFakeInput />;

      case 'inputDate':
        return <DisplayDateCell {...props} isFakeInput />;

      case 'inputTimer':
        return <InputTimer {...props} inputType="number" isFakeInput />;

      case 'timer':
        return <DisplayTimerCell {...props} />;

      case 'inputLinker': {
        return (
          <ReadOnlyLinker
            {...props}
            fixedData={column && column.settings && column.settings.data}
          />
        );
      }

      default:
        break;
    }
  }

  switch (column.settings.type) {
    case 'selection':
      return (
        <SelectionCell
          {...cellProps}
          {...props}
          settings={settings}
          onClick={async e => {
            e.stopPropagation();
            dispatch(
              validateAndSelectRow({
                callingFromCell: cellProps,
              })
            );
          }}
        />
      );

    case 'link':
      return <LinkerCell {...props} settings={settings} />;

    case 'attachment':
      return (
        <AttachmentCell
          {...props}
          settings={settings}
          showLoading={showLoading}
          reportSettings={reportSettings}
        />
      );

    case 'button':
      return (
        <ButtonCell
          {...props}
          onRefresh={onRefresh}
          showLoading={showLoading}
          settings={settings}
          reportSettings={reportSettings}
          paramsModal={paramsModal}
        />
      );

    case 'showJsonTable':
      return (
        <JsonTableCell
          {...props}
          onRefresh={onRefresh}
          showLoading={showLoading}
          settings={settings}
          reportSettings={reportSettings}
          paramsModal={paramsModal}
        />
      );

    case 'inputText':
      return <InputText {...props} onRefresh={onRefresh} inputType="text" />;

    case 'inputTextArea':
      return (
        <InputTextArea {...props} onRefresh={onRefresh} inputType="text" />
      );

    case 'inputNumber':
      return (
        <InputNumber {...props} onRefresh={onRefresh} inputType="number" />
      );

    case 'inputDate':
      return <InputDate {...props} inputType="number" />;

    case 'inputTimer':
      return <InputTimer {...props} onRefresh={onRefresh} inputType="number" />;

    case 'timer':
      return <DisplayTimerCell {...props} />;

    case 'number':
      return <DisplayNumberCell {...props} />;

    case 'date':
      return <DisplayDateCell {...props} />;

    case 'option':
      return <OptionCell {...props} />;

    case 'inputLinker':
      return <InputLinkerCell {...props} />;

    case 'template':
      return (
        <TemplateCell
          {...props}
          settings={settings}
          showLoading={showLoading}
          reportSettings={reportSettings}
        />
      );

    case 'jsx':
      return <JSXCell {...props} />;

    default:
      return <DisplayCell {...props} />;
  }
}

export default CompareRender(CellContent, [
  'value',
  'column',
  'isFocused',
  'isSelectedRow',
  'rowData',
  'reportSettings.globalFunctions',
]);
