import styled, { css } from 'styled-components';

const mobileProps = css`
  color: #333;
`;

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  display: ${props => (props.isMobile ? 'none' : 'flex')};
  flex-direction: row;
  padding: 5px 10px;
  border-radius: 8px;
  align-items: center;
  margin-right: 43px;

  svg {
    color: ${props => (props.isMobile ? '#999' : '#ddd')} !important;
  }

  @media (max-width: 500px) {
    display: ${props => (props.isMobile ? 'flex' : 'none')};
  }

  input {
    flex: 1;
    background-color: transparent;
    border: none;
    padding: 5px 10px;
    color: #fff;
    width: 200px;

    ${props => props.isMobile && mobileProps}

    &::placeholder {
      color: #aaa;
    }
  }
`;
