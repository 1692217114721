const INITIAL_STATE = null;

export default function companyToken(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'COMPANY_CHANGE':
      return action.payload.companyToken;

    case 'COMPANY_DELETED':
      return INITIAL_STATE;

    /* case 'OUTSIGN_SUCCESS':
      return {
        ...state,
        companyToken: action.payload.token,
        id: action.payload.company.id,
        name: action.payload.company.name,
        applications: action.payload.applications,
      }; */

    case 'COMPANY_SELECTED':
      return action.payload.companyToken;

    case 'LOGOFF_SUCCESS':
      return INITIAL_STATE;

    default:
      return state;
  }
}
