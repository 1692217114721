// import RequestService from './RequestService';
import request from '~/applications/Request';
import CompanyService from './CompanyService';

class CompanyQueryService {
  async create({ queryConnectionId, command }) {
    const response = await request({
      url: `APP/CRM/company_queries`,
      method: 'POST',
      data: { queryConnectionId, command },
    });

    return response;
  }

  async update({ id, command }) {
    const response = await request({
      url: `APP/CRM/company_queries/${id}`,
      method: 'PUT',
      data: { command },
    });

    return response;
  }

  async createOrUpdate({ queryConnectionId, id, command }) {
    if (id === '' || id === null || id === undefined)
      return this.create({ queryConnectionId, command });

    return this.update({ id, command });
  }

  async downloadQueries() {
    const token = await CompanyService.getCompanyToken();

    window.open(
      `${process.env.REACT_APP_SERVER_URL}/files/companyQueries/download/${token}/customQueries`
    );
  }

  async attachFile({ file, fileName }) {
    const response = await request({
      method: 'POST',
      url: `APP/CRM/company_queries/uploadZip`,
      data: {
        fileName,
      },
      file,
    });

    return response;
  }
}

export default new CompanyQueryService();
