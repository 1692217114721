import styled from 'styled-components';

export const Container = styled.div`
  // position: relative;
  background-color: #ffffff;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
`;

export const ElementHeader = styled.div`
  display: flex;
  align-items: end;
  padding: 8px 10px;
  border: 1px solid #f0f0f0;
  border-right: none;
  border-left: none;
  color: #555;

  /* Aplicar borda direita em todas as divs internas, exceto na última */
  &:not(:last-child) {
    // border-right: 1px solid #e0e0e0;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
`;

export const Element = styled.div`
  display: flex;
  border: 1px solid #f0f0f0;
  border-right: none;
  border-left: none;
  color: #555;

  /* Aplicar borda direita em todas as divs internas, exceto na última */
  &:not(:last-child) {
    // border-right: 1px solid #e0e0e0;
  }
`;

export const RenderText = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  width: 100%;
  flex: 1;
`;

export const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #999;
  font-size: 14px;
  flex: 1;
  height: 100%;
  background-color: #0000000d;
  border-top: 1px solid #e9e9e9;
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  width: 100%;
  flex: 1;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
`;

export const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #555;
  background-color: transparent;
  padding-left: 10px;
`;
