import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 30px;
`;

export const View = styled.div`
  background-color: #fff;
  color: #449dc1;
  padding: 5px 20px;
  border-bottom: 3px solid #a7cee7;
  cursor: pointer;
  transition: all 0.5s;

  ${props =>
    props.selected &&
    css`
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color: #449dc1;
      border-bottom: 3px solid #449dc1;
      color: #fff;
    `} /* &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }*/
`;
