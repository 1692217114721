const INITIAL_STATE = {
  error: null,
  lastMessageDate: null,
};

export default function alertMessage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'COMPANY_SELECTED':
    case 'LOGOFF_SUCCESS':
      return { ...INITIAL_STATE };

    case 'ALERT_MESSAGE_SET_ERROR':
      return {
        error: action.payload.error,
      };

    case 'ALERT_MESSAGE_NEW_ALERT_MESSAGE':
      return {
        lastMessageDate: action.payload.lastMessageDate,
      };

    default:
      return state;
  }
}
