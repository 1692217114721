import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
  }

  video {
    object-fit: fill;
    flex: 1;
    width: 100%;
  }
`;
