const INITIAL_STATE = {
  id: null,
  name: null,
};

export default function company(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'BRANCH_DEFAULT_SELECTED':
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
      };

    case 'COMPANY_SELECTED':
    case 'LOGOFF_SUCCESS':
      return {
        ...state,
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
}
