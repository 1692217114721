/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable react/button-has-type */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';
import ModalHandle from '~/easy-components/ModalHandle';
import Button from '~/easy-components/Button';
import FlexSpace from '~/easy-components/FlexSpace';
import FooterPage from '~/easy-components/FooterPage';
import useLocale from '~/hooks/useLocale';
import { Container, Content } from './styles';
import Editor from './Editor';

// eslint-disable-next-line no-unused-vars
function SqlModal(_, ref) {
  const t = useLocale('_Global');

  const modalRef = useRef();
  const editorRef = useRef();
  // const exampleRef = useRef();
  // const view = useRef(false);

  const [properties, setProperties] = useState({});

  useImperativeHandle(ref, () => {
    return {
      show: props => {
        setProperties(props);
        modalRef.current.handleOpen();

        /* setTimeout(() => {
          view.current =
            props.code === undefined ||
            props.code === '' ||
            props.code === null;

          exampleRef.current.setVisible(view.current);
        }, 300); */
      },
      close: () => {
        modalRef.current.handleClose();
      },
    };
  });

  const onSave = async ({ code }) => {
    if (properties.onSave) {
      await properties.onSave({ code });
    }
  };

  const onSaveAndClose = async () => {
    const code = editorRef.current.getValue();
    await onSave({ code });
    modalRef.current.handleClose();
  };

  const onConfirm = async () => {
    const code = editorRef.current.getValue();
    if (properties.onConfirm) {
      await properties.onConfirm({ code });
    }
    modalRef.current.handleClose();
  };

  /*
  select    top 10
          "CardCode" as "value", 
          "CardCode" as "label", 
          "CardName" as "auxInfo" 
from      OCRD
where     upper("CardCode") like upper('%<filter>%')
          or upper("CardName") like upper('%<filter>%')
  */

  return (
    <ModalHandle
      ref={modalRef}
      contentStyle={{ style: { width: '95%', height: '95%' } }}
    >
      <Container>
        <Content>
          <Editor
            title={t('Query')}
            ref={editorRef}
            code={properties.code || properties.defaultCode || ''}
            onSave={properties.onSave ? onSave : null}
            onConfirm={properties.onConfirm ? onConfirm : null}
            /* onView={() => {
              view.current = !view.current;
              exampleRef.current.setVisible(view.current);
            }} */
            t={t}
          />
          {/* <div style={{ backgroundColor: '#444', width: '4px' }} />
          <Editor
            ref={exampleRef}
            title={t('Example')}
            code={exampleCode}
            view={view.current}
            t={t}
          /> */}
        </Content>
        <FooterPage>
          <FlexSpace />
          <Button
            onClick={() => {
              modalRef.current.handleClose();
            }}
          >
            {t('Cancel')}
          </Button>
          {properties.onSave ? (
            <>
              <Button buttonType="Emphasized" onClick={onSave}>
                {t('Save')}
              </Button>
              <Button buttonType="Emphasized" onClick={onSaveAndClose}>
                {t('SaveAndClose')}
              </Button>
            </>
          ) : (
            <Button buttonType="Emphasized" onClick={onConfirm}>
              {t('Confirm')}
            </Button>
          )}
        </FooterPage>
      </Container>
    </ModalHandle>
  );
}

export default forwardRef(SqlModal);
