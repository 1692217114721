/* eslint-disable import/no-cycle */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';
import Form from '~/easy-components/Form';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Panel from '~/easy-components/Panel';
import Linker from '~/easy-components/Form/Linker';
import Input from '~/easy-components/Form/Input';
import Number from '~/easy-components/Form/Number';
import InputIcon from '~/easy-components/Form/InputIcon';
import useLocale from '~/hooks/useLocale';
import { Content } from './styles';

function Fields({ data }, ref) {
  const t = useLocale('_Global');

  const formRef = useRef();

  const [componentType, setComponentType] = useState('tab');

  const clearFormData = formData => {
    Object.keys(formData).forEach(key => {
      if (formData[key] === null) {
        delete formData[key];
      }
    });

    return formData;
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const formData = clearFormData(formRef.current.getData());

        return {
          target: data.target,
          ...formData,
        };
      },
    };
  });

  const fieldProps = {
    settings: null,
    formRef,
    labelWidth: 150,
  };

  return (
    <Content>
      <Form ref={formRef}>
        <ColumnLayout>
          <Panel width="330px">
            <Linker
              {...fieldProps}
              name="type"
              valueField="type"
              label={t('Type')}
              fixedData={[
                // { label: t('TabTimeline'), value: 'tabTimeline' },
                // { label: t('TabList'), value: 'tabList' },
                // { label: t('TabGrid'), value: 'tabGrid' },
                // { label: t('TabSchedule'), value: 'tabSchedule' },
                { label: t('Tab'), value: 'tab' },
              ]}
              fieldDefaultValue={componentType}
              onChange={selected => {
                if (selected) {
                  setComponentType(selected.value);
                } else {
                  setComponentType(null);
                }
              }}
            />
            <Input {...fieldProps} name="name" label={t('Name')} />
            <Input {...fieldProps} name="title" label={t('Title')} />
            <InputIcon {...fieldProps} name="icon" label={t('Icon')} />

            <Input
              {...fieldProps}
              name="listTemplate"
              label={t('ListTemplate')}
              hidden={!(componentType === 'tabList')}
              fullScreen
            />
            <Input
              {...fieldProps}
              name="listTemplateFunction"
              label={t('ListTemplateFunction')}
              hidden={!(componentType === 'tabList')}
              fullScreen
            />

            <Input
              {...fieldProps}
              name="settings"
              label={t('TableSettings')}
              hidden={!(componentType === 'tabGrid')}
              fullScreen
            />

            <Input
              {...fieldProps}
              name="defaultView"
              label={t('DefaultView')}
              hidden={!(componentType === 'tabSchedule')}
              fullScreen
            />

            <Linker
              {...fieldProps}
              name="isDraggable"
              valueField="isDraggable"
              label={t('IsDraggable')}
              fixedData={[
                { label: t('Yes'), value: true },
                { label: t('No'), value: false },
              ]}
              hidden={
                !(
                  componentType === 'tabSchedule' || componentType === 'tabList'
                )
              }
            />

            <Linker
              {...fieldProps}
              name="defaultView"
              valueField="defaultView"
              label={t('DefaultView')}
              fixedData={[
                { label: t('Month'), value: 'month' },
                { label: t('Week'), value: 'week' },
                { label: t('Agenda'), value: 'agenda' },
                { label: t('Day'), value: 'day' },
                { label: t('WorkWeek'), value: 'work_week' },
              ]}
              hidden={!(componentType === 'tabSchedule')}
            />
            <Input
              {...fieldProps}
              name="endAccessor"
              label={t('EndAccessor')}
              hidden={!(componentType === 'tabSchedule')}
            />
            <Input
              {...fieldProps}
              name="endTimeAccessor"
              label={t('EndTimeAccessor')}
              hidden={!(componentType === 'tabSchedule')}
            />
            <Input
              {...fieldProps}
              name="startAccessor"
              label={t('StartAccessor')}
              hidden={!(componentType === 'tabSchedule')}
            />
            <Input
              {...fieldProps}
              name="startTimeAccessor"
              label={t('StartTimeAccessor')}
              hidden={!(componentType === 'tabSchedule')}
            />
            <Input
              {...fieldProps}
              name="eventTemplate"
              label={t('EventTemplate')}
              hidden={!(componentType === 'tabSchedule')}
              fullScreen
            />
            <Number
              {...fieldProps}
              name="columnCount"
              label={t('ColumnCount')}
              hidden={!(componentType === 'tab')}
              type="integer"
            />
            <Number
              {...fieldProps}
              name="columnWidth"
              label={t('ColumnWidth')}
              hidden={!(componentType === 'tab')}
              type="integer"
            />
            <Number
              {...fieldProps}
              name="columnLabelWidth"
              label={t('ColumnLabelWidth')}
              hidden={!(componentType === 'tab')}
              type="integer"
            />
          </Panel>
        </ColumnLayout>
      </Form>
    </Content>
  );
}

export default forwardRef(Fields);
