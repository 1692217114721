/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useContext, useMemo, useEffect } from 'react';

import { updateCellValue, deselectRow } from 'ka-table/actionCreators';

import { TiUpload as IconUpload } from '@react-icons/all-files/ti/TiUpload';
import { TiDelete as IconDelete } from '@react-icons/all-files/ti/TiDelete';
import { Input } from '../../styles';
import {
  AttachmentInputContainer,
  IconsContainer,
  IconPanel,
  HiddenFileInput,
} from './styles';
import DownloadButton from './components/DownloadButton';
import {
  splitFileName,
  splitSourcePath,
  normalizeFileName,
} from '~/easy-components/Helpers/fileHandler';
import dynamicFunction from '../../dynamicFunction';
import validateAndSelectRow from '../../CustomActions/validateAndSelectRow';

import { PageContext } from '../../index';

function Attachment({
  column,
  rowKeyValue,
  value,
  settings,
  showLoading,
  reportSettings,
  cellProps,
  items,
  rawItems,
}) {
  const [isUpdate, showUpdate] = useState(!!value);

  useEffect(() => {
    showUpdate(() => !!value);
  }, [value]);

  const fileRef = useRef(null);

  const {
    dispatchOnUploadInLine,
    dispatchOnSuccessUploadInLine,
    dispatchOnValidateUploadInLine,
    dispatchOnDeleteUploadInLine,
    dispatchOnSuccessDeleteUploadInLine,
    token,
    dispatch,
    onGetDownloadToken,
    auxScope,
    setRowFieldValue,
    removeLine,
  } = useContext(PageContext);

  const linkRef = useRef({
    token: null,
    fileName: null,
    sourcePath: null,
  });

  const acceptFiles = useMemo(() => {
    if (column && column.settings && column.settings.acceptFiles) {
      return column.settings.acceptFiles;
    }

    if (reportSettings && reportSettings.acceptFiles) {
      return reportSettings.acceptFiles;
    }

    return '*';
  }, [column, reportSettings]);

  const { readOnly } = column.settings;

  const eventsProps = {
    ...auxScope,
    rowKeyValue,
    value,
    showLoading,
    setFieldValue: (columnKey, newValue, forceUpdate) => {
      setRowFieldValue(rowKeyValue, columnKey, newValue, forceUpdate);
    },
    setRowFieldValue,
    selectRow: ({ force = false }) => {
      dispatch(
        validateAndSelectRow({
          callingFromCell: cellProps,
          forceSelection: force,
        })
      );
    },
    deselectRow: () => {
      dispatch(deselectRow(rowKeyValue));
    },
    removeLine,
    items,
    rawItems,
    reportSettings,
  };

  const onDelete = async () => {
    linkRef.current.fileName = null;
    linkRef.current.sourcePath = null;
    linkRef.current.token = null;

    showUpdate(false);
    dispatch(updateCellValue(rowKeyValue, column.key, undefined));

    const fullPath = linkRef.current.sourcePath
      ? `${linkRef.current.sourcePath}${linkRef.current.fileName}`
      : value;

    await dispatchOnDeleteUploadInLine({
      rowKeyValue,
      column,
      value: fullPath,
    });

    if (column.settings && column.settings.onDelete) {
      await dynamicFunction({
        functionString: column.settings.onDelete,
        settings,
        params: eventsProps,
      });
    }

    dispatchOnSuccessDeleteUploadInLine({
      rowKeyValue,
      column,
      value: fullPath,
    });
  };

  const askForFile = () => {
    fileRef.current.click();
  };

  const uploadFile = async e => {
    const { files } = e.target;
    const file = files[0];

    const isValid = await dispatchOnValidateUploadInLine({
      file,
      rowKeyValue,
      column,
      tableSettings: reportSettings,
    });

    if (isValid) {
      try {
        const { fullPath } = await dispatchOnUploadInLine({
          file,
          rowKeyValue,
          column,
        });

        if (column.settings && column.settings.onUpload) {
          await dynamicFunction({
            functionString: column.settings.onUpload,
            settings,
            params: { ...eventsProps, file, fullPath },
          });
        }

        linkRef.current.fileName = normalizeFileName(splitFileName(fullPath));
        linkRef.current.sourcePath = splitSourcePath(fullPath);
        linkRef.current.token = token;

        showUpdate(true);
        dispatch(updateCellValue(rowKeyValue, column.key, fullPath));
        dispatchOnSuccessUploadInLine({
          file,
          rowKeyValue,
          column,
        });
      } catch (err) {
        showUpdate(false);
      }
    }
  };

  const getDownloadTokenForFile = () => {
    return (
      onGetDownloadToken &&
      onGetDownloadToken({
        fileName:
          linkRef.current.fileName || normalizeFileName(splitFileName(value)),
        sourcePath: linkRef.current.sourcePath || splitSourcePath(value),
      })
    );
  };

  return (
    <AttachmentInputContainer
      minWidth={
        column &&
        column.settings &&
        column.settings.style &&
        column.settings.style.width
      }
    >
      <HiddenFileInput
        ref={fileRef}
        type="file"
        onChange={uploadFile}
        accept={acceptFiles}
      />
      <Input
        value={
          (linkRef.current && linkRef.current.fileName) ||
          normalizeFileName(splitFileName(value))
        }
        readOnly
      />

      {isUpdate && (
        <IconsContainer>
          <IconPanel>
            <DownloadButton
              fileName={
                linkRef.current.fileName ||
                normalizeFileName(splitFileName(value))
              }
              onGetToken={getDownloadTokenForFile}
            />
          </IconPanel>
          {!readOnly && (
            <IconPanel onClick={() => onDelete()}>
              <IconDelete size={18} color="#ff9191" />
            </IconPanel>
          )}
        </IconsContainer>
      )}

      {!isUpdate && !readOnly && (
        <IconsContainer>
          <IconPanel onClick={() => askForFile()}>
            <IconUpload size={14} color="#3B8FB1" />
          </IconPanel>
        </IconsContainer>
      )}
    </AttachmentInputContainer>
  );
}

export default Attachment;
