import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  background-color: #74ceba;
  text-align: center;
  opacity: 1;
  transition: opacity 8s ease;
  font-size: 12px !important;
  display: ${props => (props.isQA ? 'block' : 'none')};

  b {
    font-size: 14px !important;
  }

  @media (max-width: 500px) {
    font-size: 12px !important;
    b {
      font-size: 10px !important;
    }
  }
`;
