/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
import React, { useContext } from 'react';
import { deselectRow } from 'ka-table/actionCreators';
import { isMobile } from 'react-device-detect';

import validateAndSelectRow from '../CustomActions/validateAndSelectRow';
import useCompany from '~/hooks/useCompany';

import { DisplayCell, Cell, AppendButton, FakeInput } from '../styles';
import dynamicFunction from '../dynamicFunction';
import Icon from '../../Icon';
import { PageContext } from '../index';

function DisplayDateCell({
  value,
  isModal,
  style,
  column,
  dispatch,
  rowData,
  rowKeyValue,
  onRefresh,
  cellProps,
  isFakeInput,
  readOnly,
  items,
  rawItems,
}) {
  const { dateToString } = useCompany();

  const { auxScope, setRowFieldValue, removeLine } = useContext(PageContext);

  const hasAppendButton =
    !isMobile &&
    column &&
    column.settings &&
    column.settings.appendButton &&
    column.settings.appendButton.icon &&
    dateToString(value);

  const appendButton = hasAppendButton ? column.settings.appendButton : {};
  // eslint-disable-next-line no-unused-vars
  const { onClick, icon, ...iconProps } = appendButton;

  const Content = () => (
    <>
      <span>{dateToString(value)}</span>
      {hasAppendButton && (
        <AppendButton
          onClick={async () => {
            if (appendButton && appendButton.onClick) {
              await dynamicFunction({
                functionString: appendButton.onClick,
                dispatch,
                params: {
                  ...auxScope,
                  column,
                  line: rowData,
                  items,
                  rawItems,
                  value,
                  rowKeyValue,
                  refresh: onRefresh,
                  setFieldValue: (columnKey, newValue, forceUpdate) => {
                    setRowFieldValue(
                      rowKeyValue,
                      columnKey,
                      newValue,
                      forceUpdate
                    );
                  },
                  setRowFieldValue,
                  selectRow: ({ force = false }) => {
                    dispatch(
                      validateAndSelectRow({
                        callingFromCell: cellProps,
                        forceSelection: force,
                      })
                    );
                  },
                  deselectRow: () => {
                    dispatch(deselectRow(rowKeyValue));
                  },
                  removeLine,
                },
              });
            }
          }}
        >
          <Icon name={appendButton.icon} {...iconProps} />
        </AppendButton>
      )}
    </>
  );

  return (
    <Cell align="center">
      <DisplayCell
        style={{
          justifyContent: isModal ? 'flex-start' : 'center',
          ...style,
        }}
      >
        {isFakeInput ? (
          <FakeInput
            readOnly={readOnly}
            style={{
              display: 'flex',
              justifyContent: isModal ? 'flex-start' : 'flex-end',
              ...style,
            }}
          >
            <Content />
          </FakeInput>
        ) : (
          <Content />
        )}
      </DisplayCell>
    </Cell>
  );
}

export default DisplayDateCell;
