import React from 'react';
import Icon from '~/easy-components/Icon';

function HasEvent({ viewMode, selfField }) {
  const hasEvent =
    (selfField.events && selfField.events.length > 0) ||
    selfField.hidden !== undefined ||
    selfField.readOnly !== undefined ||
    selfField.required !== undefined ||
    selfField.readOnly !== undefined ||
    selfField.title !== undefined ||
    selfField.label !== undefined;

  if (hasEvent && viewMode === 'design') {
    return (
      <Icon
        name="FaExclamation"
        size={14}
        color="#49746570"
        style={{ marginRight: 4 }}
      />
    );
  }

  return null;
}

export default HasEvent;
