/* eslint-disable react/prop-types */
import React from 'react';
import GridLayout from 'react-grid-layout';
import { v1 as uuidv1 } from 'uuid';

import '../../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../../node_modules/react-resizable/css/styles.css';

import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle';

import ConfirmDialog from '~/easy-components/ConfirmDialog';
import ErrorBoundary from '~/easy-components/ErrorBoundary';
import Widget from './components/Widget';
import { Container } from './styles';

function Dashboard({
  readOnly = false,
  widgets,
  setWidgets,
  layouts,
  setLayouts,
  mergeWidgets,
  onCtrlSave,
  settings,
  params,
  t,
}) {
  const mergePropsLayout = newLayout => {
    return newLayout.map(layout => {
      const props = widgets.find(widget => widget.i === layout.i);

      return {
        ...layout,
        ...props,
      };
    });
  };

  const onLayoutChange = newLayout => {
    if (!readOnly) {
      setLayouts(mergePropsLayout(newLayout));
    }
  };

  const handlerChange = ({ data, layout }) => {
    const idx = widgets.findIndex(w => w.i === layout.i);

    widgets[idx] = {
      ...data,
    };

    setWidgets(widgets);
    setLayouts(mergeWidgets({ newWidgets: widgets, newLayouts: layouts }));
  };

  const onAdd = () => {
    const uuid = uuidv1();

    setWidgets([
      ...widgets,
      {
        i: uuid,
        title: '',
      },
    ]);

    setLayouts([
      ...layouts,
      {
        i: uuid,
        x: (layouts.length * 2) % (layouts.cols || 12),
        y: Infinity,
        w: 2,
        h: 4,
        minW: 2,
        minH: 4,
      },
    ]);
  };

  const onDelete = layout => {
    ConfirmDialog({
      title: layout.title,
      message: t('message.DeleteMessageQuestion'),
      buttons: [
        {
          label: t('Yes'),
          onClick: () => {
            setWidgets(widgets.filter(widget => widget.i !== layout.i));
            setLayouts(layouts.filter(l => l.i !== layout.i));
          },
        },
        {
          label: t('No'),
        },
      ],
    });
  };

  return (
    <ErrorBoundary>
      <Container>
        {!readOnly && (
          <header>
            <FaPlusCircle
              cursor="pointer"
              onClick={onAdd}
              size={24}
              color="#75b575"
            />
          </header>
        )}
        <aside>
          <GridLayout
            className="layout"
            layout={layouts}
            cols={12}
            rowHeight={30}
            width={1400}
            onLayoutChange={onLayoutChange}
          >
            {layouts.map(layout => {
              layout.static = readOnly;
              return (
                <div key={layout.i}>
                  <Widget
                    readOnly={readOnly}
                    t={t}
                    data={layout}
                    settings={settings}
                    onDelete={() => onDelete(layout)}
                    params={params}
                    onChange={({ data }) => {
                      handlerChange({ layout, data });
                    }}
                    onCtrlSave={onCtrlSave}
                  >
                    {layout.i}
                  </Widget>
                </div>
              );
            })}
          </GridLayout>
        </aside>
      </Container>
    </ErrorBoundary>
  );
}

export default Dashboard;
