import RequestService from './RequestService';
import CompanyService from './CompanyService';

class ApplicationSettingService {
  async getSettings(applicationId) {
    const response = await RequestService.request({
      url: `application_settings?$filter=applicationId eq ${applicationId}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response.length > 0 ? response[0] : null;
  }

  async create({ applicationId, settings }) {
    const response = await RequestService.request({
      url: `application_settings`,
      method: 'POST',
      largeData: {
        applicationId,
        settings,
      },
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async update({ id, settings }) {
    const response = await RequestService.request({
      url: `application_settings/${id}`,
      method: 'PUT',
      largeData: { settings },
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async createOrUpdate({ applicationId, id, settings }) {
    if (id === '' || id === null || id === undefined)
      return this.create({
        applicationId,
        settings,
      });

    return this.update({ id, settings });
  }

  async updateComponentSettings({
    route,
    settings,
    componentType,
    componentName,
    fieldNameRef,
  }) {
    const response = await RequestService.request({
      url: `application_settings/${route}/component`,
      method: 'PUT',
      largeData: { settings, componentType, componentName, fieldNameRef },
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async addUserField({ route, userField, componentName, fieldNameRef }) {
    const response = await RequestService.request({
      url: `application_settings/${route}/userField`,
      method: 'POST',
      largeData: { route, userField, componentName, fieldNameRef },
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async deleteUserField({ route, userField }) {
    const response = await RequestService.request({
      url: `application_settings/${route}/userField/${userField}`,
      method: 'DELETE',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }
}

export default new ApplicationSettingService();
