/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-new-func */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, {
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';
import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle';
import { useField } from '@unform/core';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

/* import CardTemplate from '~/easy-components/CardTemplate'; */
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ModalHandle from '~/easy-components/ModalHandle';
import MenuButton from '~/easy-components/MenuButton';
import { sendData } from '~/store/modules/log/actions';
import TreatError from '~/easy-components/TreatError';
import QueryService from '~/services/QueryService';
import CardTemplate from '~/easy-components/CardTemplate';
import Icon from '~/easy-components/Icon';
import {
  createSyncFunctionByString,
  createAsyncFunctionByString,
} from '~/easy-components/AsyncFunctionString';

import useUiDesigner from '~/hooks/useUiDesigner';
import Detail from './Detail';
import {
  Container,
  Item,
  ErrorInfo,
  Items,
  Group,
  GroupTitle,
  GroupContent,
} from './styles';
import { fireEvent } from '../sharedFunctions';
import { sendEvent } from '../../HandlerEvent/index';

const AsyncFunction = new Function(
  `return Object.getPrototypeOf(async function(){}).constructor`
)();

function List(
  {
    name,
    renderItem,
    editPage,
    editPageTitle,
    validate,
    readOnly,
    confirmEnabled,
    settings,
    showAddButton = true,
    defaultData,
    onChange,
    onSelected,
    onValidAddItem,
    title,
    headerItem,
    footerItem,
    menus,
    onOpenMenu,
    onBeforeShow,
    onShowContextMenu,
    onError,
    itemBackgroundColor,
    enableSelection = true,
    formRef: mainForm,
    headerButtons,
    footer,
    onBeforeAddItem,
    onAfterAddItem,
    isDraggable,
    auxScope: listAuxScope,
    toolbar,
    queryCode,
  },
  ref
) {
  const dispatch = useDispatch();

  const formRef = useRef(null);
  const elementRef = useRef(null);
  const containerRef = useRef(null);
  const oldListRef = useRef([]);
  const dataRef = useRef([]);

  const itemRef = useRef({});
  const selectedIndexRef = useRef(-1);
  const modalRef = useRef(null);

  const [data, setData] = useState([]);

  const [errorList, setErrorList] = useState([]);
  const [lineErrors, setLineErrors] = useState({});
  const [errorDescription, setErrorDescription] = useState('');

  const { selfField, viewMode, showContextMenu } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'list',
    settings,
    baseName: null,
    name,
    title: null,
  });

  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  function onKeydown(e) {
    if (e.keyCode === 27) modalRef.current.handleClose();
  }

  const openDetail = async ({ data: itemData, index }) => {
    await sendEvent({
      settings,
      eventName: 'onShowDetail',
      run: 'before',
      mainFormRef: mainForm,
      events: selfField.events,
      formRef: formRef.current,
      params: {
        data: itemData,
        index,
      },
    });

    modalRef.current.handleOpen();
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('keydown', onKeydown);
    }

    if (selfField.events) {
      selfField.events = selfField.events.map(event => {
        const dynamicFunction = new AsyncFunction('scope', event.handler);
        event.dynamicFunction = dynamicFunction;
        return event;
      });
    }

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        containerRef.current.removeEventListener('keydown', onKeydown);
      }
    };
  }, [dispatch, selfField.events, settings]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: elementRef.current,
      path: 'value',
      clearValue: el => {
        el.value = null;

        setData([]);

        dataRef.current = [];

        oldListRef.current = [];

        setErrorList([]);
      },
      setValue: async (r, v) => {
        selectedIndexRef.current = -1;

        r.value = v;

        setErrorList([]);

        let newList = v;

        if (!Array.isArray(v)) {
          newList = v ? JSON.parse(v) : [];
        }

        setData(newList);
        dataRef.current = newList;

        const old = JSON.stringify(oldListRef.current);

        if (old !== v && selfField.events) {
          const event = selfField.events.find(
            field => field.name.toUpperCase() === 'ONCHANGE'
          );

          let formData = null;

          if (mainForm.current) formData = mainForm.current.getData();

          if (event)
            await event.dynamicFunction({
              form: mainForm.current,
              element: elementRef.current,
              executeQuery: QueryService.execute,
              executeScalar: async sql => {
                const responseSql = await QueryService.execute(1, sql);
                if (responseSql.length > 0) return responseSql[0];
                return {};
              },
              executeSql: async sql => {
                const responseSql = await QueryService.execute(1, sql);
                return responseSql;
              },
              showError: message => {
                throw new Error(message);
              },
              fireEvent,
              toast,
              formData,
              settings,
              params: {
                oldList: oldListRef.current,
                newList,
              },
            });

          oldListRef.current = newList;
        }
      },
      getValue: () => {
        return dataRef.current;
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValue !== '') {
      let defaultV = defaultValue;

      if (typeof defaultValue === 'string') {
        defaultV = JSON.parse(defaultValue);
      }

      setData(defaultV);

      dataRef.current = defaultV;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, fieldName]);

  useEffect(() => {
    elementRef.current.addItem = async newItem => {
      // eslint-disable-next-line no-use-before-define
      await onAddItem({ updatedData: newItem });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selfField]);

  async function onSelect(line, idx) {
    try {
      const element = idx >= 0 ? { ...data[idx] } : null;
      itemRef.current = element;
      selectedIndexRef.current = idx;
      const lineError = errorList.find(errorLine => +errorLine.line === +idx);
      setLineErrors(lineError || {});
      onSelected(element);
      await onBeforeShow({ data: element, index: idx });
      await openDetail({ data: element, index: idx });
    } catch (e) {
      onError(e);
    }
  }

  function onMouseHover(idx) {
    dispatch(sendData({ name, value: idx }));
  }

  useEffect(() => {
    if (error) {
      if (error.indexOf('[{') >= 0) {
        const list = JSON.parse(error);
        setErrorList(list);
        setErrorDescription('Verifique os erros');
      } else {
        setErrorDescription(error);
      }
    } else {
      setErrorList([]);
    }
  }, [error]);

  const onAdd = async ({ externalData }) => {
    const validateAddItem = await onValidAddItem({ data, setErrorDescription });

    if (validateAddItem) {
      let newData = externalData;

      if (newData === null || newData === undefined) {
        switch (typeof defaultData) {
          case 'string':
            const functionConverted = await createAsyncFunctionByString({
              functionString: defaultData,
            });

            newData = await functionConverted({
              ...settings.dynamicFunctionProps,
              form: mainForm.current,
              formData: mainForm.current ? mainForm.current.getData() : null,
            });

            break;

          case 'object':
            newData = defaultData;
            break;

          default:
            newData = await defaultData({ pageRef: formRef });
        }
      }

      itemRef.current = newData;
      selectedIndexRef.current = -1;

      setLineErrors({});

      await openDetail({ data: newData, index: -1 });
    }
  };

  if (!Array.isArray(data)) {
    setData([]);
    dataRef.current = [];
  }

  useImperativeHandle(ref, () => {
    return {
      detailFormRef: formRef,
      open: async ({ externalData }) => {
        await onAdd({ externalData });
      },
    };
  });

  const renderListItem = (d, idx, listaData) => {
    /* if (settings.listTemplate) {
      return (
        <CardTemplate key={idx} template={settings.listTemplate} data={d} />
      );
    } */

    return renderItem(d, idx, listaData);
  };

  const reorderListData = ({
    listData: list,
    sourceIndex,
    destinationIndex,
  }) => {
    const [removed] = list.splice(sourceIndex, 1);

    list.splice(destinationIndex, 0, removed);

    return list;
  };

  const onDragEnd = async result => {
    try {
      const { destination, source } = result;

      if (!destination) return;

      if (destination.index === source.index) return;

      await sendEvent({
        settings,
        eventName: 'onDragEnd',
        run: 'before',
        mainFormRef: mainForm,
        events: selfField.events,
        formRef: formRef.current,
      });

      const newListData = reorderListData({
        listData: [...data],
        sourceIndex: source.index,
        destinationIndex: destination.index,
      });

      setData(newListData);

      dataRef.current = newListData;

      await sendEvent({
        settings,
        eventName: 'onDragEnd',
        run: 'after',
        mainFormRef: mainForm,
        formRef,
        events: selfField.events,
      });
    } catch (err) {
      TreatError.showError(err);
    }
  };

  function groupByPropName(array, propriedade) {
    return array.reduce((grupos, objeto) => {
      const valorPropriedade = objeto[propriedade];

      if (valorPropriedade !== undefined) {
        if (!grupos[valorPropriedade]) {
          grupos[valorPropriedade] = [];
        }

        grupos[valorPropriedade].push(objeto);
      } else {
        if (!grupos.others) {
          grupos.others = [];
        }

        grupos.others.push(objeto);
      }

      return grupos;
    }, {});
  }

  let visibleList = data;

  if (selfField.groupByProperty) {
    const groups = groupByPropName(data, selfField.groupByProperty);
    visibleList = groups;
  }

  const auxScope = {
    ...listAuxScope,
    listData: visibleList,
    setData,
    formRef: mainForm.current,
    form: mainForm.current,
  };

  function formatTemplate({ data: d, value }) {
    if (selfField.groupHeaderTemplate) {
      const dynamicFunction = createSyncFunctionByString({
        functionString: selfField.groupHeaderTemplate,
      });

      return dynamicFunction({
        data: d,
        value,
        ...auxScope,
        ...settings.dynamicFunctionProps,
      });
    }

    return value;
  }

  const RenderLine = ({ idx, item }) => {
    return (
      <Draggable
        draggableId={`index_${idx}`}
        index={idx}
        isDragDisabled={!isDraggable || readOnly}
      >
        {providerDrag => (
          <Item
            {...providerDrag.draggableProps}
            {...providerDrag.dragHandleProps}
            ref={providerDrag.innerRef}
            backgroundColor={itemBackgroundColor}
            onClick={async () => enableSelection && onSelect(item, idx)}
            onMouseEnter={() => onMouseHover(idx)}
            withError={errorList.findIndex(e => +e.line === +idx) >= 0}
          >
            {renderListItem(item, idx, data)}
          </Item>
        )}
      </Draggable>
    );
  };

  async function onGroupHeadeerShowContextMenu(myMenus, { data: myData }) {
    await sendEvent({
      eventName: 'onGroupHeaderRefreshMenu',
      run: 'before',
      events: selfField.events,
      formRef: mainForm,
      data: {
        menus: myMenus,
        item: myData,
      },
    });
  }

  const onClickGroupHeader = async myData => {
    await sendEvent({
      eventName: 'onGroupHeaderClick',
      run: 'before',
      events: selfField.events,
      formRef: mainForm,
      data: myData,
      ...settings.dynamicFunctionProps,
    });
  };

  const RenderItems = () => {
    const type = Array.isArray(visibleList) ? 'array' : typeof visibleList;

    let lineNumber = -1;

    switch (type) {
      case 'object':
        return Object.keys(visibleList).map(prop => {
          if (prop && prop !== 'null') {
            const myData = {
              data: visibleList[prop],
              value: prop,
            };

            const headerTemplate = formatTemplate(myData);

            return (
              <Group key={prop}>
                <GroupTitle
                  onClick={() => onClickGroupHeader(myData)}
                  hasCursor={
                    selfField.events &&
                    selfField.events.find(e => e.name === 'onGroupHeaderClick')
                  }
                >
                  <CardTemplate
                    key={lineNumber + 1}
                    template={headerTemplate}
                    data={myData}
                    isShowMenus={!!selfField.groupHeaderMenus}
                    customMenus={selfField.groupHeaderMenus}
                    onShowContextMenu={myMenus => {
                      return onGroupHeadeerShowContextMenu(myMenus, myData);
                    }}
                    isShowDelete={false}
                    auxScope={auxScope}
                  />
                </GroupTitle>
                <GroupContent>
                  {visibleList[prop].map(item => {
                    lineNumber += 1;
                    return <RenderLine idx={lineNumber} item={item} />;
                  })}
                </GroupContent>
              </Group>
            );
          }

          return visibleList[prop].map(item => {
            lineNumber += 1;
            return <RenderLine idx={lineNumber} item={item} />;
          });
        });

      default:
        return visibleList.map((item, idx) => {
          return <RenderLine idx={idx} item={item} />;
        });
    }
  };

  const getToolbar = () => {
    /* const customMenus = [
      {
        icon: 'BiLayerPlus',
        color: '#75b575',
        handler: () => {
          alert('Clicou');
        },
      },
    ]; */

    const toolbarItems = selfField.toolbar || [];

    const customMenus = toolbarItems.map(button => {
      const dynamicFunction = createAsyncFunctionByString({
        functionString: button.handler,
      });

      return {
        ...button,
        handler: async () => {
          await dynamicFunction({
            form: mainForm.current,
            formData: mainForm.current.getData(),
            ...auxScope,
            ...settings.dynamicFunctionProps,
          });
        },
      };
    });

    return (
      <div>
        {customMenus.map(menu => {
          return (
            <button
              type="button"
              onClick={() => {
                menu.handler();
              }}
            >
              <Icon size={24} color={menu.color} name={menu.icon} />
            </button>
          );
        })}
      </div>
    );
  };

  const onAddItem = async ({ updatedData, oldData, isCloseModal }) => {
    try {
      const newData = await onBeforeAddItem(updatedData);

      // TODO: Ajustar o push e atualização, não devemos alterar o próprio objeto do estado, isso não causa a renderização

      if (selectedIndexRef.current >= 0)
        data[selectedIndexRef.current] = newData;
      else data.push(newData);

      setData(data);

      dataRef.current = data;

      setLineErrors({});

      if (isCloseModal) {
        modalRef.current.handleClose();
      }

      onChange({
        data,
        setData: props => {
          setData(props);
          dataRef.current = props;
        },
        selectedIndex: selectedIndexRef.current,
        newData,
        oldData,
      });

      await onAfterAddItem(newData);
    } catch (e) {
      onError(e);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <input
          type="hidden"
          ref={elementRef}
          id={fieldName}
          defaultValue={defaultValue}
        />

        <Container
          ref={containerRef}
          onContextMenu={event => {
            showContextMenu({
              event,
              auxContextMenus: [
                {
                  title: 'EditQuery',
                  prop: queryCode,
                  type: 'query',
                  visible: !!queryCode,
                },
              ],
            });
          }}
        >
          <header>
            {title ? <div className="header">{title}</div> : <div />}
            <div>
              {getToolbar()}
              {toolbar}
              {!readOnly && (
                <div>
                  {showAddButton && (
                    <button type="button" onClick={onAdd}>
                      <FaPlusCircle size={24} color="#75b575" />
                    </button>
                  )}

                  {headerButtons &&
                    headerButtons.map(headerButton => {
                      return headerButton;
                    })}

                  {menus && menus.length > 0 && (
                    <MenuButton
                      menus={menus}
                      onOpen={onOpenMenu}
                      icon={FaEllipsisV}
                      color="#777"
                      size={16}
                      position="bottom right"
                      auxScope={auxScope}
                      onBeforeShow={m => {
                        return onShowContextMenu(m);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </header>

          {error && <ErrorInfo>{errorDescription}</ErrorInfo>}
          {headerItem && headerItem()}

          <Droppable droppableId="droppable-list">
            {providerDrop => (
              <Items
                ref={providerDrop.innerRef}
                {...providerDrop.droppableProps}
              >
                <RenderItems />
              </Items>
            )}
          </Droppable>

          {footerItem && footerItem(visibleList)}
        </Container>
        <ModalHandle ref={modalRef}>
          <Detail
            viewMode={viewMode}
            route={settings._route}
            name={name}
            index={selectedIndexRef.current}
            data={itemRef.current}
            errors={lineErrors}
            readOnly={readOnly}
            validate={validate}
            mainFormRef={mainForm}
            formRef={formRef}
            onClose={() => {
              modalRef.current.handleClose();
            }}
            onError={onError}
            onConfirm={async (updatedData, oldData) => {
              await onAddItem({ updatedData, oldData, isCloseModal: true });
            }}
            confirmEnabled={confirmEnabled}
            editPage={editPage}
            editPageTitle={editPageTitle}
            settings={settings}
            footerElement={footer}
            onRendered={async ({ data: itemData, index, detailFormRef }) => {
              await sendEvent({
                settings,
                eventName: 'onShowDetail',
                run: 'after',
                mainFormRef: mainForm,
                events: selfField.events,
                formRef: detailFormRef,
                params: {
                  data: itemData,
                  index,
                },
              });
            }}
          />
        </ModalHandle>
      </DragDropContext>
    </>
  );
}

const listComponent = forwardRef(List);

listComponent.propTypes = {
  name: PropTypes.string.isRequired,
  renderItem: PropTypes.func.isRequired,
  editPage: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  editPageTitle: PropTypes.string.isRequired,
  validate: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  confirmEnabled: PropTypes.bool,
  settings: PropTypes.oneOfType([
    PropTypes.shape,
    PropTypes.func,
    PropTypes.any,
  ]),
  showAddButton: PropTypes.bool,
  defaultData: PropTypes.oneOfType([PropTypes.shape, PropTypes.func]),
  onChange: PropTypes.func,
  title: PropTypes.string,
  headerItem: PropTypes.func,
  footerItem: PropTypes.func,
  menus: PropTypes.arrayOf(PropTypes.shape()),
  onOpenMenu: PropTypes.func,
  onValidAddItem: PropTypes.func,
  onSelected: PropTypes.func,
  onBeforeShow: PropTypes.func,
  onError: PropTypes.func,
  formRef: PropTypes.shape(),
  onBeforeAddItem: PropTypes.func,
  onAfterAddItem: PropTypes.func,
  onShowContextMenu: PropTypes.func,
};

listComponent.defaultProps = {
  defaultData: null,
  showAddButton: true,
  validate: () => true,
  onChange: () => {},
  settings: null,
  confirmEnabled: true,
  title: null,
  headerItem: null,
  footerItem: null,
  menus: [],
  onOpenMenu: null,
  onValidAddItem: () => true,
  onSelected: () => {},
  onBeforeShow: () => {},
  onError: error => {
    // eslint-disable-next-line no-console
    console.error(error);
  },
  formRef: {},
  footer: () => {
    return null;
  },
  onBeforeAddItem: data => data,
  onAfterAddItem: () => {},
  onShowContextMenu: () => {},
};

export default listComponent;
