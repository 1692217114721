/* eslint-disable import/no-cycle */
import request from '../applications/Request';

class ControllerService {
  async create({ name, code }) {
    const response = await request({
      url: `controllers`,
      method: 'POST',
      data: {
        name,
        code,
      },
    });

    return response;
  }

  async update({ id, code, name }) {
    const response = await request({
      url: `controllers/${id}`,
      method: 'PUT',
      data: {
        code,
        name,
      },
    });

    return response;
  }

  async delete(id) {
    const response = await request({
      url: `controllers/${id}`,
      method: 'DELETE',
    });

    return response;
  }

  async save({ id, name, code }) {
    const controller = id
      ? await this.update({ id, code, name })
      : await this.create({ code, name });

    return controller;
  }

  async list({ filter }) {
    const response = await request({
      url: `controllers?$filter=${filter || ''}`,
      method: 'GET',
    });

    return response;
  }

  async get(id) {
    const response = await request({
      url: `controllers/${id}`,
      method: 'GET',
    });

    return response;
  }

  async getByName(name) {
    const response = await request({
      url: `controllers/getByName/${name}`,
      method: 'GET',
    });

    return response;
  }
}

export default new ControllerService();
