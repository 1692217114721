import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-end;
  top: 0;
  right: 75px;
  z-index: 10;
`;

export const Button = styled.div`
  /* width: 150px; */
  background-color: #fff4;
  padding: 6px 10px;
  color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  opacity: 0.6;
  text-align: center;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  background-color: #fff;
  flex-direction: column;
  border: 1px solid #ddd;
  margin-top: 10px;
  overflow: auto;
  max-height: 30vh;
  box-shadow: 0px 0px 5px #888888;

  > h1 {
    background-color: #ebebeb;
    padding: 10px 10px;
    font-weight: 400;
  }

  > section {
    height: 100px;
  }
`;

export const Tasks = styled.div`
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;
