import React, { useEffect } from 'react';
import useCompany from '~/hooks/useCompany';
import { useSelector, useDispatch } from 'react-redux';

import { setInactivityStatus } from '~/store/modules/inactivity/actions';
import DisconnectedPage from './DisconnectedPage';

const InactivityBlockApplication = ({ children }) => {
  const dispatch = useDispatch();

  const { companySettings } = useCompany();

  const authorizationToken = useSelector(
    ({ authorizationToken: authToken }) => authToken
  );
  const inactivityStatus = useSelector(({ inactivity }) => inactivity.status);

  useEffect(() => {
    let inactivityTimeout;

    const resetTimer = () => {
      if (companySettings && companySettings.inactivityTimeout) {
        clearTimeout(inactivityTimeout);
        inactivityTimeout = setTimeout(() => {
          dispatch(setInactivityStatus(true));
        }, companySettings.inactivityTimeout * 60000);
      }
    };

    const handleUserActivity = () => {
      if (!inactivityStatus) {
        resetTimer();
        dispatch(setInactivityStatus(false));
      }
    };

    resetTimer();

    // Adiciona event listeners para rastrear interações do usuário
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    // Limpa os event listeners quando o componente é desmontado
    return () => {
      clearTimeout(inactivityTimeout);
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, [
    companySettings,
    companySettings.inactivityTimeout,
    dispatch,
    inactivityStatus,
  ]);

  if (!authorizationToken) {
    return children;
  }

  if (!inactivityStatus) {
    return children;
  }

  return (
    <DisconnectedPage
      reconnect={() => {
        window.location.href = '/Login';
      }}
    />
  );
};

export default InactivityBlockApplication;
