/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import CardTemplate from '~/easy-components/CardTemplate';

function CustomCells(props) {
  const { column, rowData } = props;

  const template = useMemo(() => {
    try {
      return column.settings.template;
    } catch (e) {
      return null;
    }
  }, [column.settings.template]);

  return (
    <CardTemplate
      template={template}
      data={rowData}
      style={{
        padding: 0,
        background: 'transparent',
        display: 'block',
      }}
    />
  );
}

export default CustomCells;
