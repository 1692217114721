/* eslint-disable react/prop-types */
import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import { Mobile, Computer } from './styles';

export default function Switch({
  mobile,
  computer,
  treatTabletAsMobile = false,
  breakpoint = '500px',
}) {
  return (
    <>
      {isMobile && (treatTabletAsMobile || !isTablet) ? (
        <Mobile breakpoint={breakpoint}>{mobile}</Mobile>
      ) : (
        <Computer breakpoint={breakpoint}>{computer}</Computer>
      )}
    </>
  );
}
