export const showMenu = status => ({
  type: 'SHOW_MENU',
  payload: {
    status,
  },
});

export const setRoute = route => ({
  type: 'MAIN_MENU_SET_ROUTE_SELECTED',
  payload: {
    route,
  },
});

export const setVisibleMenu = status => ({
  type: 'SET_VISIBLE_MENU',
  payload: {
    status,
  },
});
