/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import useLocale from '~/hooks/useLocale';
import { Container, View } from './styles';

const viewMode = {
  Hour: 'Hour',
  QuarterDay: 'Quarter Day',
  HalfDay: 'Half Day',
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  Year: 'Year',
};

function ViewMode({ views, selectedView, onChange }) {
  const t = useLocale('_Global');

  if (!views) {
    return null;
  }

  return (
    <Container>
      {views.map(view => (
        <View
          key={view}
          selected={selectedView === viewMode[view]}
          onClick={() => {
            onChange(viewMode[view]);
          }}
        >
          {t(view)}
        </View>
      ))}
    </Container>
  );
}

export default ViewMode;
