import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';

import { Editor } from '@tinymce/tinymce-react';

import { Container } from './styles';

function TinyMCEText({ text }, ref) {
  const [value, setValue] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const element = document.querySelector('.tox-notifications-container');
      if (element) {
        element.style.display = 'none';
        clearInterval(interval); // Limpa o intervalo quando o elemento é encontrado
      }
    }, 1); // Verifica a cada 100ms

    return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setValue(text || '');
    }, 700);
  }, [text]);

  function getValue() {
    return value;
  }

  useImperativeHandle(ref, () => {
    return {
      getValue,
    };
  });

  function handleEditorChange(content) {
    setValue(content || '');
  }

  const locale = localStorage.getItem('i18nextLng');

  function transformLanguageCode(languageCode) {
    switch (languageCode) {
      case 'en-US':
        return 'en';
      case 'es-ES':
        return 'es';
      case 'es-BO':
        return 'es';
      default:
        return 'pt_BR';
    }
  }

  return (
    <Container>
      <Editor
        apiKey={process.env.REACT_APP_TINY_KEY}
        init={{
          selector: '#mytextarea',
          plugins:
            'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
          imagetools_cors_hosts: ['picsum.photos'],
          menubar: 'file edit view insert format tools table help',
          toolbar:
            'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
          toolbar_sticky: true,
          image_advtab: true,
          importcss_append: true,
          image_caption: true,
          autosave_ask_before_unload: false,
          quickbars_selection_toolbar:
            'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
          toolbar_mode: 'sliding',
          contextmenu: 'link image imagetools table',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px}',
          language: transformLanguageCode(locale),
        }}
        inline={false}
        value={value}
        onEditorChange={handleEditorChange}
      />
    </Container>
  );
}

export default forwardRef(TinyMCEText);
