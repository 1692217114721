import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 50vw;

  @media screen and (max-width: 768px) {
    max-width: unset;
  }
`;

export const OptionView = styled.button`
  padding: 16px;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  margin: 8px;
  border: none;
  background-color: #fff;
  outline: none;
  color: #496774;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 0.1s cubic-bezier(0.71, -0.01, 0.33, 0.99);
  position: relative;
  bottom: 0;
  box-shadow: 0 0 transparent;

  &:hover {
    background-color: #fdfdfd;
    bottom: 4px;
    box-shadow: 0 2px 8px #bbb;
  }

  @media screen and (max-width: 500px) {
    width: calc(50vw - 28px);
    height: calc(50vw - 28px);

    &:nth-child(odd) {
      margin-left: 0;
    }

    &:nth-child(even) {
      margin-right: 0;
    }
  }
`;

export const OptionTitle = styled.p``;
