import BaseMasterDataService from './BaseMasterDataService';
import request from '../../Request';

class TruckService extends BaseMasterDataService {
  constructor() {
    super({ route: 'Trucks', primaryKeyField: 'Code' });
  }

  async searchTrucks({ filter, truckTypeId, BPLId }) {
    return request({
      url: `APP/CRM/${this.route}/searchTrucks?$filter=${filter}&$BPLId=${BPLId}&$truckTypeId=${truckTypeId}`,
    });
  }

  async getWarehouses({ bplId, filter }) {
    return request({
      url: `APP/CRM/${this.route}/getWarehouses?$BPLId=${bplId}&$filter=${filter}`,
    });
  }

  async getLocations({ filter, whsCode, bplId }) {
    return request({
      url: `APP/CRM/${this.route}/getLocations?$filter=${filter}&$whsCode=${whsCode}&$BPLId=${bplId}`,
    });
  }

  async listBusinessPartners(filter) {
    return request({
      url: `APP/CRM/${this.route}/BusinessPartners?$filter=${filter || ''}`,
    });
  }

  async searchBranches({ filter }) {
    return request({
      url: `APP/CRM/${this.route}/searchBranches?$filter=${filter || ''}`,
    });
  }
}

export default new TruckService();
