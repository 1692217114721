/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { isMobile } from 'react-device-detect';
import { Decimal } from 'decimal.js';

import PageHeader from '~/easy-components/PageHeader';
import FlexSpace from '~/easy-components/FlexSpace';
import Button from '~/easy-components/Button';
import ModalFooter from '~/easy-components/ModalFooter';
import useCompany from '~/hooks/useCompany';
import { HeaderCell, ColumnCell } from '../styles';
import { Container, Content, ItemsContent, ItemLine } from './styles';
import BatchesList from '../BatchesList';

function BatchNumberSelection({
  title,
  isReadOnly,
  itemLinesRef,
  selectedIndex,
  onClose,
  onConfirm,
  t,
  showBinPosition,
}) {
  const { numberToString } = useCompany();
  const [, refresh] = useState(null);

  const [state, setState] = useState({
    selectedItem: null,
    selectedLineIndex: null,
    lineIndex: null,
  });

  const batchesListRef = useRef();

  const showBatches = useCallback(({ lineIndex, selectedItem }) => {
    setState({
      lineIndex,
      selectedItem,
      selectedLineIndex: lineIndex,
    });
  }, []);

  const confirmSelectedBinLocations = ({
    direction,
    binLocationsAttributed,
    lineIndex,
    selectedItemData,
  }) => {
    if (binLocationsAttributed) {
      const binActionType =
        direction === 'from' ? 'batFromWarehouse' : 'batToWarehouse';

      const allStockTransferLines = selectedItemData.StockTransferLinesBinAllocations.filter(
        all => all.BinActionType !== binActionType
      );

      const newStockTransferLines = selectedItemData.StockTransferLinesBinAllocations.filter(
        s =>
          s.BinActionType === binActionType &&
          s.SerialAndBatchNumbersBaseLine !==
            binLocationsAttributed[0].SerialAndBatchNumbersBaseLine
      );

      binLocationsAttributed.forEach(binLocAttributed => {
        newStockTransferLines.push(binLocAttributed);
      });

      const mergedStockTransferLines = [
        ...allStockTransferLines,
        ...newStockTransferLines,
      ];

      itemLinesRef.current[
        lineIndex
      ].StockTransferLinesBinAllocations = mergedStockTransferLines;

      batchesListRef.current.updateSTLNumbers(
        binLocationsAttributed[0].SerialAndBatchNumbersBaseLine
      );
    }
  };

  useEffect(() => {
    if (selectedIndex !== null && selectedIndex !== undefined) {
      const selectedItem = itemLinesRef.current[selectedIndex];
      showBatches({
        lineIndex: selectedIndex,
        selectedItem,
      });
    }
  }, [itemLinesRef, selectedIndex, showBatches]);

  const titles = useMemo(() => {
    if (isMobile) {
      return {
        warehouse: t('Whs'),
        quantity: t('Qtd'),
        released: t('Req'),
        selected: t('Selec'),
      };
    }
    return {
      warehouse: t('WhsCode'),
      quantity: t('Quantity'),
      released: t('Required'),
      selected: t('Selected'),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {title && <PageHeader>{title}</PageHeader>}

      <Content>
        <h1>{t('Items')}</h1>
        <ItemsContent>
          <table>
            <thead>
              <tr>
                <HeaderCell>{t('Item')}</HeaderCell>
                <HeaderCell>{titles.warehouse}</HeaderCell>
                <HeaderCell contentType="number">{titles.quantity}</HeaderCell>
                <HeaderCell contentType="number">{titles.released}</HeaderCell>
                <HeaderCell contentType="number">{titles.selected}</HeaderCell>
              </tr>
            </thead>
            <tbody>
              {itemLinesRef.current.map((item, idx) => {
                if (item._IsManagedByBatch !== 'Y') return null;

                if (selectedIndex >= 0) {
                  if (selectedIndex !== idx) return null;
                }

                let selectedTotal = new Decimal(0);
                let necessaryTotal = new Decimal(item.Quantity);

                item.BatchNumbers.forEach(batch => {
                  const batchQuantity = new Decimal(batch.Quantity);

                  selectedTotal = selectedTotal.plus(batchQuantity);
                  necessaryTotal = necessaryTotal.minus(batchQuantity);
                });

                return (
                  <ItemLine
                    key={idx}
                    selected={state.selectedLineIndex === idx}
                    lineColor={() => {
                      let lineColor = '#f9f9f9';

                      if (selectedTotal === item.Quantity) {
                        lineColor = '#dcf0dc';
                      } else if (selectedTotal > 0) {
                        lineColor = '#fff0dc';
                      }
                      return lineColor;
                    }}
                    onClick={async e => {
                      e.stopPropagation();
                      e.preventDefault();

                      setState({
                        ...state,
                        selectedLineIndex: idx,
                      });

                      showBatches({
                        lineIndex: idx,
                        selectedItem: item,
                      });
                    }}
                  >
                    <ColumnCell>{item.ItemCode}</ColumnCell>
                    <ColumnCell>{item.WarehouseCode}</ColumnCell>
                    <ColumnCell contentType="number">
                      {numberToString(item.Quantity, 'quantity')}
                    </ColumnCell>
                    <ColumnCell contentType="number">
                      {numberToString(necessaryTotal.toNumber(), 'quantity')}
                    </ColumnCell>
                    <ColumnCell contentType="number">
                      {numberToString(selectedTotal.toNumber(), 'quantity')}
                    </ColumnCell>
                  </ItemLine>
                );
              })}
            </tbody>
          </table>
        </ItemsContent>
        <BatchesList
          ref={batchesListRef}
          t={t}
          isReadOnly={isReadOnly}
          lineIndex={state.lineIndex}
          selectedItemData={state.selectedItem}
          itemLinesRef={itemLinesRef}
          refreshPage={refresh}
          showBinPosition={showBinPosition}
          confirmSelectedBinLocations={confirmSelectedBinLocations}
        />
      </Content>
      <ModalFooter>
        {!isReadOnly && (
          <>
            <Button
              buttonType="Default"
              onClick={() => {
                batchesListRef.current.automaticSelection();
              }}
              style={{ marginLeft: '0px' }}
            >
              {t('AutoSelect')}
            </Button>
            <Button
              buttonType="Default"
              onClick={() => {
                batchesListRef.current.clear();
              }}
            >
              {t('Clear')}
            </Button>
            <FlexSpace />
          </>
        )}
        <Button
          buttonType="Default"
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        >
          {t('Cancel')}
        </Button>

        {!isReadOnly && (
          <Button
            buttonType="Emphasized"
            onClick={e => {
              e.stopPropagation();

              if (selectedIndex >= 0) {
                onConfirm(itemLinesRef.current[selectedIndex]);
              } else {
                onConfirm(itemLinesRef.current);
              }
              onClose();
            }}
          >
            {t('Confirm')}
          </Button>
        )}
      </ModalFooter>
    </Container>
  );
}

export default BatchNumberSelection;
