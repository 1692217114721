/* eslint-disable import/no-cycle */
/* eslint-disable react/button-has-type */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';
import ModalHandle from '~/easy-components/ModalHandle';
import HeaderPage from '~/easy-components/HeaderPage';
import Button from '~/easy-components/Button';
import FlexSpace from '~/easy-components/FlexSpace';
import FooterPage from '~/easy-components/FooterPage';
import colors from '~/styles/colors';
import useLocale from '~/hooks/useLocale';
import GenericListItem from '~/applications/CRM/components/GenericListItem';
import { Container, Content, Item } from './styles';

// eslint-disable-next-line no-unused-vars
function ListModal(_, ref) {
  const t = useLocale('_Global');

  const modalRef = useRef();

  const [data, setData] = useState({
    list: [],
  });

  useImperativeHandle(ref, () => {
    return {
      show: ({ title, menus, list = [], onNew }) => {
        setData({ title, menus, list, onNew });

        modalRef.current.handleOpen();
      },
      close: () => {
        modalRef.current.handleClose();
      },
    };
  });

  return (
    <ModalHandle
      ref={modalRef}
      contentStyle={{ style: { width: 'auto', height: 'auto' } }}
    >
      <Container>
        <HeaderPage
          backgroundColor={colors.headerBg}
          color={colors.headerTitle}
          hideMainMenu
        >
          {data.title}
        </HeaderPage>
        <Content>
          {data.list.map(item => {
            return (
              <Item>
                <GenericListItem
                  {...item}
                  style={{
                    backgroundColor: item._backgroundColor,
                  }}
                  menus={data.menus}
                  auxData={{
                    ...item,
                    closeModal: () => {
                      modalRef.current.handleClose();
                    },
                  }}
                />
              </Item>
            );
          })}
        </Content>
        <FooterPage>
          <FlexSpace />
          <Button
            onClick={() => {
              modalRef.current.handleClose();
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            buttonType="Emphasized"
            onClick={async () => {
              // const userField = fieldsRef.current.getValue();
              // await data.onConfirm(userField);
              // modalRef.current.handleClose();
              data.onNew({
                modal: modalRef.current,
                list: data,
              });
            }}
          >
            {t('New')}
          </Button>
        </FooterPage>
      </Container>
    </ModalHandle>
  );
}

export default forwardRef(ListModal);
