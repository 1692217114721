import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ffffff;
  position: relative;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(1, 0.04, 0.13, 1.09);
  animation-name: swipeLeft;
  width: 300px;

  @media (max-width: 500px) {
    animation-name: swipeLeftMobile;
    width: 100vw;
  }

  @keyframes swipeLeft {
    from {
      position: absolute;
      right: -300px;
      height: 0px;
    }

    to {
      position: relative;
      right: 0;
      height: 100%;
    }
  }

  @keyframes swipeLeftMobile {
    from {
      position: absolute;
      right: -100vw;
      height: 50vh;
    }

    to {
      position: relative;
      right: 0;
      height: 100%;
    }
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;

  h3 {
    color: #8197a5;
    font-weight: 400;
  }
`;
export const Body = styled.div`
  padding: 0px 20px 20px 20px;
  height: calc(100vh - 44px - 57px - 75px - 61px);
  overflow-y: auto;
`;
export const Footer = styled.div`
  width: 100%;
  padding: 20px;

  button {
    width: 100%;
  }
`;
