import styled from 'styled-components';

export const Container = styled.div`
  opacity: 0;
  position: fixed;
  padding: 4px;
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.71, -0.01, 0.33, 0.99);
  box-shadow: 0 2px 4px #e5e5e5;
  min-width: 200px;

  &.hidden {
    opacity: 0;
    button {
      cursor: default;
    }
  }

  &.visible {
    opacity: 1;

    button {
      cursor: pointer;
    }
  }
`;

export const Menu = styled.button`
  width: 100%;
  display: ${props => (props.visible === false ? 'none' : 'flex')};
  justify-content: flex-start;
  background-color: transparent;
  outline: none;
  border: none;
  align-items: center;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }

  svg {
    margin-right: 16px;
  }
  span {
    font-size: 1rem;
    color: ${props => (props.disabled === true ? '#d5d5d5' : '#4a4a4a')};
  }

  padding: 4px 16px;
  transition: all 0.1s cubic-bezier(0.71, -0.01, 0.33, 0.99);
  &:hover {
    background-color: ${props => (props.disabled ? 'transparent' : '#f5f5f5')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
`;
