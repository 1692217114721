import button from './buttons';
import field from './fields';
import list from './list';
import listPage from './listPage';
import page from './page';
import tabSchedule from './tabSchedule';
import tabColumn from './tabColumn';
import tabContent from './tabContent';
import tabGrid from './tabGrid';

const menus = {
  button,
  field,
  list,
  listPage,
  page,
  tabSchedule,
  tabColumn,
  tabContent,
  tabGrid,
};

export default menus;
