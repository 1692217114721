/* eslint-disable import/no-cycle */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';
import Form from '~/easy-components/Form';
import { Content } from './styles';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Panel from '~/easy-components/Panel';
import Linker from '~/easy-components/Form/Linker';
import Input from '~/easy-components/Form/Input';
import useLocale from '~/hooks/useLocale';
import { normalizeName, clearFormData } from '../../index';
import ApplicationGroupService from '~/services/ApplicationGroupService';
import Button from '~/easy-components/Button';
import useHelpers from '~/hooks/useHelpers';

function Fields({ data }, ref) {
  const t = useLocale('_Global');

  const {
    dynamicFunctionProps: { showModalSql },
  } = useHelpers();

  const formRef = useRef();

  const [componentType, setComponentType] = useState('text');

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const formData = clearFormData(formRef.current.getData());
        formData.type = componentType;
        formData.name = normalizeName(formData.name);
        return {
          target: data.target,
          ...formData,
        };
      },
    };
  });

  const fieldProps = {
    settings: null,
    formRef,
  };

  return (
    <Content>
      <Form ref={formRef}>
        <ColumnLayout>
          <Panel width="330px">
            <Linker
              {...fieldProps}
              name="type"
              valueField="type"
              label={t('Type')}
              fixedData={[
                { label: t('UserField'), value: 'userField' },
                // { label: t('Combo'), value: 'combo' },
                { label: t('Date'), value: 'date' },
                { label: t('Timer'), value: 'timer' },
                { label: t('Number'), value: 'number' },
                { label: t('Integer'), value: 'integer' },
                { label: t('Quantity'), value: 'quantity' },
                { label: t('Measure'), value: 'measure' },
                { label: t('Price'), value: 'price' },
                { label: t('Percent'), value: 'percent' },
                { label: t('Rate'), value: 'rate' },
                { label: t('Value'), value: 'value' },
                { label: t('QueryField'), value: 'queryField' },
                { label: t('Tag'), value: 'tag' },
                { label: t('FixedData'), value: 'fixedData' },
                { label: t('Display'), value: 'display' },
                { label: t('Checkbox'), value: 'checkbox' },
                { label: t('Attachment'), value: 'attachment' },
                // { label: t('Hidden'), value: 'hidden' },
                { label: t('MaskField'), value: 'maskField' },
                { label: t('Text'), value: 'text' },
              ]}
              fieldDefaultValue={componentType}
              onChange={selected => {
                if (selected) {
                  setComponentType(selected.value);
                } else {
                  setComponentType(null);
                }
              }}
            />
            <Input {...fieldProps} name="name" label={t('Name')} />
            <Input
              {...fieldProps}
              name="label"
              label={t('Label')}
              hidden={componentType === 'userField'}
            />
            <Input
              {...fieldProps}
              name="refTable"
              label={t('ReferenceTable')}
              hidden={componentType !== 'userField'}
            />
            <Input
              {...fieldProps}
              name="mask"
              label={t('Mask')}
              hidden={componentType !== 'maskField'}
            />
            <Input
              {...fieldProps}
              name="data"
              label={t('Data')}
              hidden={
                !(componentType === 'fixedData' || componentType === 'tag')
              }
              fullScreen
            />
            <Input
              {...fieldProps}
              name="sql"
              label={t('Sql')}
              // hidden={!(componentType === 'queryField')}
              hidden
              fullScreen
            />
            <Linker
              {...fieldProps}
              name="_queryCode"
              valueField="queryCode"
              label={t('QueryCode')}
              hidden={!(componentType === 'queryField')}
              method={async filter => {
                const response = await ApplicationGroupService.getQueries({
                  filter,
                });

                return response.map(item => {
                  return {
                    label: item.name,
                    value: item.code,
                    auxInfo: item.code,
                  };
                });
              }}
            />
            <Linker
              {...fieldProps}
              name="showLink"
              valueField="showLink"
              label={t('ShowLink')}
              fixedData={[
                { label: t('Yes'), value: true },
                { label: t('No'), value: false },
              ]}
              hidden={
                !(
                  componentType === 'number' ||
                  componentType === 'integer' ||
                  componentType === 'quantity' ||
                  componentType === 'measure' ||
                  componentType === 'price' ||
                  componentType === 'percent' ||
                  componentType === 'rate' ||
                  componentType === 'value'
                )
              }
            />
            <div style={{ height: '10px' }} />
            <Button
              buttonType="Emphasized"
              hidden={!(componentType === 'queryField')}
              onClick={() => {
                const code = formRef.current.getData().sql;

                showModalSql({
                  code,
                  onConfirm: async ({ code: newCode }) => {
                    formRef.current.setFieldValue('sql', newCode);
                  },
                });
              }}
            >
              Sql
            </Button>
          </Panel>
        </ColumnLayout>
      </Form>
    </Content>
  );
}

export default forwardRef(Fields);
