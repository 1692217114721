import { app_key } from '../config/application';

class StorageService {
  get(storeName) {
    const store =
      JSON.parse(sessionStorage.getItem(`persist:${app_key}`)) || {};
    // const store = JSON.parse(localStorage.getItem(`persist:${app_key}`));
    const data = JSON.parse(store[storeName] || '{}');
    return data;
  }
}

export default new StorageService();
