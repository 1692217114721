/* eslint-disable react/prop-types */
import React from 'react';
import { BsCheck as SingleCheckIcon } from '@react-icons/all-files/bs/BsCheck';
import { BiCheckDouble as DobleCheckIcon } from '@react-icons/all-files/bi/BiCheckDouble';
import { MdError as ErrorIcon } from '@react-icons/all-files/md/MdError';

// import { Container } from './styles';

const ENUM = {
  TO_SEND: 'TO_SEND',
  SENDING: 'SENDING',
  READ: 'READ',
  ALL_READ: 'ALL_READ',
  PARCIAL_READ: 'PARCIAL_READ',
  NOT_READ: 'NOT_READ',
  ERROR: 'ERROR',
};

export const STATUS = ENUM;

function Status({ destCount, status }) {
  if (destCount > 1) {
    switch (status) {
      case ENUM.TO_SEND:
        return <SingleCheckIcon size={14} color="#999" />;

      case ENUM.SENDING:
        return <SingleCheckIcon size={14} color="#999" />;

      case ENUM.ALL_READ:
        return <DobleCheckIcon size={14} color="#34b7f1" />;

      case ENUM.PARCIAL_READ:
        return <DobleCheckIcon size={16} color="#f0be7c" />;

      case ENUM.NOT_READ:
        return <DobleCheckIcon size={14} color="#AAA" />;

      default:
        return <ErrorIcon size={14} color="#ff7777" />;
    }
  } else {
    switch (status) {
      case ENUM.TO_SEND:
        return <SingleCheckIcon size={14} color="#999" />;

      case ENUM.SENDING:
        return <SingleCheckIcon size={14} color="#999" />;

      case ENUM.READ:
        return <SingleCheckIcon size={14} color="#34b7f1" />;

      case ENUM.NOT_READ:
        return <SingleCheckIcon size={14} color="#AAA" />;

      default:
        return <ErrorIcon size={14} color="#ff7777" />;
    }
  }
}

export default Status;
