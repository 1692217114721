import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import Icon from '~/easy-components/Icon';
import LoadIcon from '~/easy-components/LoadIcon';

import { Container, Menu } from './styles';

const MenuPopUp = forwardRef(({ menus: menusProps }, ref) => {
  const rowRef = useRef();
  const [isOpen, open] = useState(false);
  const [menus, changeMenus] = useState(menusProps);
  const [coordinates, placeMenu] = useState([0, 0]);
  const [x, y] = coordinates;
  const [isLoading, setLoading] = useState(true);

  const placeMenuPosition = (newX, newY) => {
    placeMenu([newX, newY]);
  };

  const resetLineMark = () => {
    const table = rowRef.current.rowElement.closest('table');
    const linesWithMarks = table.getElementsByClassName('persist-row-hover');

    for (let lineIndex = 0; lineIndex < linesWithMarks.length; lineIndex++) {
      const element = linesWithMarks[lineIndex];

      element.classList.remove('persist-row-hover');
    }
  };

  const setAClickListenerToClose = rowElement => {
    const closeMenuOnClickOutside = e => {
      e.stopPropagation();
      open(false);
      rowElement.classList.remove('persist-row-hover');
      document.removeEventListener('click', closeMenuOnClickOutside);
    };

    document.addEventListener('click', closeMenuOnClickOutside);
  };

  useImperativeHandle(ref, () => ({
    open: ({
      clientX,
      clientY,
      rowElement,
      loading: loadingOption,
      ...rowProps
    }) => {
      rowRef.current = {
        rowElement,
        ...rowProps,
      };

      placeMenuPosition(clientX, clientY);

      resetLineMark();

      rowElement.classList.add('persist-row-hover');

      setLoading(loadingOption);
      open(true);

      setAClickListenerToClose(rowElement);
    },
    changeMenus,
    setLoading,
  }));

  const onClickInMenu = (e, menu) => {
    if (isOpen && !menu.disabled) {
      menu.onClick({
        ...rowRef.current,
        event: e,
      });
    }
  };

  if (menus.length === 0) {
    return null;
  }

  return (
    <Container
      style={{ left: `${x}px`, top: `${y}px` }}
      className={isOpen ? 'visible' : 'hidden'}
    >
      {isLoading ? (
        <LoadIcon />
      ) : (
        menus.map(menu => {
          return (
            <Menu
              visible={menu.visible}
              disabled={menu.disabled}
              onClick={e => onClickInMenu(e, menu)}
              type="button"
            >
              <Icon
                name={menu.icon}
                size={12}
                color={menu.disabled ? '#e5e5e5' : '#b8b8b8'}
              />
              <span>{menu.text}</span>
            </Menu>
          );
        })
      )}
    </Container>
  );
});

MenuPopUp.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

MenuPopUp.defaultProps = {
  menus: [],
};

export default MenuPopUp;
