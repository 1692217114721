import { toast } from 'react-toastify';

const createMenu = ({
  queryCode,
  elementType,
  isUserField,
  isShowLinkClick,
}) => {
  const menus = [
    {
      type: 'queryEditor',
      title: 'EditQuery',
      prop: queryCode,
      disabled: true,
      visible: isUserField && elementType === 'Linker',
    },
    {
      type: 'queryEditor',
      title: 'EditQueryCode',
      prop: queryCode,
      disabled: true,
      visible: isUserField && elementType === 'Linker',
    },
    {
      type: 'query',
      title: 'EditQuery',
      prop: queryCode,
      defaultValue: false,
      visible: !!queryCode,
    },
    {
      icon: 'LuTextCursorInput',
      title: 'AddCustomField',
      prop: 'userFields',
      type: 'newField',
    },
    {
      icon: 'TbLetterCaseUpper',
      title: 'ChangeTitle',
      prop: 'title',
      type: 'text',
      disabled: false,
    },
    {
      icon: 'TiDeleteOutline',
      iconColor: '#ff9999',
      title: 'Delete',
      prop: 'userFields',
      type: 'deleteElement',
      visible: isUserField,
    },
    {
      type: 'separator',
    },
    {
      icon: 'FiCopy',
      title: 'CopyElementId',
      type: 'handler',
      prop: null,
      disabled: false,
      value: null,
      handler: async ({ fullName, t }) => {
        await navigator.clipboard.writeText(fullName);
        toast.success(t('message.CopiedToClipboard'));
      },
    },
    {
      type: 'boolean',
      prop: 'hidden',
      defaultValue: false,
      options: [
        {
          icon: 'FaEye',
          title: 'ShowField',
          value: false,
        },
        {
          icon: 'FaEyeSlash',
          title: 'HiddenField',
          value: true,
        },
      ],
    },
    {
      icon: 'MdSettings',
      title: 'CustomView',
      type: 'custom',
      prop: 'hidden',
      disabled: false,
      value: null,
    },
    {
      type: 'boolean',
      prop: 'readOnly',
      defaultValue: false,
      options: [
        {
          icon: 'TbLock',
          title: 'ReadOnly',
          value: true,
        },
        {
          icon: 'FaEdit',
          title: 'AllowEditing',
          value: false,
        },
      ],
    },
    {
      icon: 'MdSettings',
      title: 'CustomReadOnly',
      type: 'custom',
      prop: 'readOnly',
      disabled: false,
      value: null,
    },
    {
      type: 'separator',
    },
    {
      title: 'onChange',
      prop: 'onChange',
      type: 'event',
      visible: true,
      disabled: false,
    },
    {
      title: 'onList',
      prop: 'onList',
      type: 'event',
      visible: elementType === 'Linker',
      defaultRunType: 'before',
      disabled: false,
    },
    {
      title: 'onLinkClick',
      prop: 'onLinkClick',
      type: 'event',
      visible: isShowLinkClick,
      defaultRunType: 'before',
      disabled: false,
    },
  ];

  return menus;
};

export default createMenu;
