/* eslint-disable no-case-declarations */
/* eslint-disable no-empty */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useContext, useEffect } from 'react';

import Button from '~/easy-components/Button';
import Toast from '~/easy-components/Toast';

import { Container, Content, Message } from './styles';
import ComponentContext from '../../context';

function Manual() {
  const { confirm, t, inputManualRef, message } = useContext(ComponentContext);

  useEffect(() => {
    setTimeout(() => {
      if (inputManualRef.current) {
        inputManualRef.current.focus();
      }
    }, 400);
  }, [inputManualRef]);

  function formatTagValue(tagValue) {
    const tagNoSpacesAndBreakLines = tagValue.trim().replace(/[\r\n]/gm, '');

    return tagNoSpacesAndBreakLines;
  }

  return (
    <Container>
      <Content>
        <h1>{t('InformTheCode')}</h1>
        {message && <Message>{message}</Message>}

        <textarea
          ref={inputManualRef}
          // eslint-disable-next-line react/no-unknown-property
          // inputMode="none"
          onKeyDown={e => {
            switch (e.keyCode) {
              case 13: // ENTER
                e.preventDefault();
                inputManualRef.current.blur();

                const tagValue = inputManualRef.current.value.trim();
                if (tagValue != null && tagValue !== '') {
                  const formattedTagValue = formatTagValue(tagValue);

                  confirm({
                    tagValue: formattedTagValue,
                    elementRef: inputManualRef,
                  });
                }
                return false;

              default:
                return true;
            }
          }}
        />
      </Content>

      <Button
        buttonType="Emphasized"
        type="button"
        style={{ height: 60 }}
        onClick={() => {
          const tagValue = inputManualRef.current.value;
          if (tagValue && tagValue.trim !== '' && tagValue !== null) {
            const formattedTagValue = formatTagValue(tagValue);

            confirm({
              tagValue: formattedTagValue,
              elementRef: inputManualRef,
            });
          } else {
            Toast.warn(t('message.InformTheValue'));
          }
        }}
      >
        {t('Confirm')}
      </Button>
      <Button
        type="button"
        onClick={() => {
          inputManualRef.current.value = '';
          inputManualRef.current.focus();
        }}
      >
        {t('Clear')}
      </Button>
    </Container>
  );
}

export default Manual;
