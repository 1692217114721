export default function createGlobalFunctions(settings) {
  // eslint-disable-next-line no-new-func
  const AsyncFunction = new Function(
    `return Object.getPrototypeOf(async function(){}).constructor`
  )();

  const { globalFunctions } = settings || {};

  if (globalFunctions) {
    Object.keys(globalFunctions).forEach(prop => {
      const globalFunction = settings.globalFunctions[prop];

      if (typeof globalFunction === 'function') {
        return;
      }

      const dynamicFunction = new AsyncFunction('param', globalFunction);
      settings.globalFunctions[prop] = dynamicFunction;
      if (!settings.settings) {
        settings.settings = {};
      }
      settings.settings.globalFunctions = settings.globalFunctions;
    });
  }
}
