import styled from 'styled-components';

export const Container = styled.div`
  background-color: #e9edef;
  display: flex;
  flex-direction: column;
  width: ${props => `${props.paramsPanelWidth}px` || '100%'};
  overflow-x: 'hidden';
  overflow-y: 'scroll';

  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
    flex: 1;
  }
`;

export const Separator = styled.div`
  margin-top: 20px;
  margin-bottom: 10px !important;
  border-bottom: 2px solid #8197a53d;
  color: #8197a5;
  padding: 5px 0;

  @media (max-width: 500px) {
    padding: 15px;
  }
`;

export const TitleMultipleChoice = styled.div`
  color: #8197a5;
  padding: 15px 0;

  @media (max-width: 500px) {
    padding: 15px;
  }
`;
