/* eslint-disable react/button-has-type */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-new-func */
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import Icon from '~/easy-components/Icon';
import Button from '~/easy-components/Button';

import { PageContext } from '../index';
import dynamicFunction from '../dynamicFunction';

import { Cell } from '../styles';

function ButtonCell({
  column,
  value,
  style,
  settings,
  dispatch,
  onRefresh,
  showLoading,
  paramsModal,
  items,
  rawItems,
}) {
  const { customModalRef, promptRef, auxScope, removeLine } = useContext(
    PageContext
  );
  const userInfo = useSelector(({ user }) => user);

  const buttonProps = {
    buttonType: column.settings.buttonType,
    style: column.settings.style,
    disabled: value === 'N',
    onClick: async e => {
      e.preventDefault();
      e.stopPropagation();

      if (column.settings) {
        const { message, dataToReplace, tableOptions } = column.settings;

        if (tableOptions) {
          let onConfirm;

          if (
            tableOptions.settings.onConfirm &&
            typeof tableOptions.settings.onConfirm === 'string'
          ) {
            onConfirm = async ({ data }) => {
              await dynamicFunction({
                functionString: tableOptions.settings.onConfirm,
                settings,
                dispatch,
                promptRef,
                customModalRef,
                params: {
                  ...auxScope,
                  refresh: onRefresh,
                  showLoading,
                  userInfo,
                  paramsModal,
                  data,
                  removeLine,
                  items,
                  rawItems,
                },
              });
            };
          }

          customModalRef.current.show({
            type: 'showReport',
            title: tableOptions.title,
            message,
            dataToReplace,
            onConfirm,
            reportOptions: {
              title: tableOptions.title,
              data: value,
              settings: tableOptions.settings,
              auxScope: {
                mainRefresh: onRefresh,
                showLoading,
              },
            },
          });
        }
      }
    },
  };

  function getIcon() {
    if (column.settings.buttonType === 'iconButton') {
      const { options } = column.settings;

      return (
        <Icon
          {...buttonProps}
          style={{
            cursor: buttonProps.disabled ? 'not-allowed' : 'pointer',
            pointerEvents: buttonProps.disabled ? 'none' : 'all',
            ...style,
          }}
          name={options.icon}
          size={options.size}
          color={buttonProps.disabled ? '#ddd' : options.color}
        />
      );
    }

    return null;
  }

  function getButton() {
    switch (column.settings.buttonType) {
      case 'iconButton':
        return getIcon();

      default:
        return <Button {...buttonProps}>{column.settings.title}</Button>;
    }
  }

  return <>{value !== 'H' && <Cell align="center">{getButton()}</Cell>}</>;
}

export default ButtonCell;
