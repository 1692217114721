/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { createPortal } from 'react-dom';

import { Container, Content } from './styles';

function ModalHandle(
  {
    direction = 'left',
    children,
    contentStyle = {},
    isCloseOnClick = true,
    onClose,
    ...rest
  },
  ref
) {
  const [open, setOpen] = useState(false);
  const modalRoot = document.getElementById('modal');
  const el = document.createElement('div');
  const containerRef = useRef();

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    if (onClose) onClose();
    setOpen(false);
  }

  useImperativeHandle(ref, () => {
    return {
      isOpenModal: open,
      handleOpen,
      handleClose,
      setVisible: status => {
        if (containerRef.current) {
          containerRef.current.style.display = status ? 'flex' : 'none';
        }
      },
    };
  });

  useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el, modalRoot]);

  return (
    <>
      {open &&
        createPortal(
          <Container
            ref={containerRef}
            direction={direction}
            onMouseDown={event => {
              if (isCloseOnClick) handleClose(event);
            }}
            {...rest}
          >
            <Content
              {...contentStyle}
              onMouseDown={e => {
                e.stopPropagation();
                // não incluir o e.preventDefault(); que os inputs param de funcionar
              }}
            >
              {children}
            </Content>
          </Container>,
          el
        )}
    </>
  );
}

export default forwardRef(ModalHandle);
