import styled from 'styled-components';

export const AttachmentInputContainer = styled.div`
  display: flex;
  border: 1px solid #ddd;
  background-color: #fff;
  min-width: ${props => (props.minWidth ? props.minWidth : '144px')};
  input {
    border: none !important;
    color: #888;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    border: none;

    input:read-only {
      background-color: transparent;
      font-size: 0.9rem;
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
`;

export const IconPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1px;
  cursor: pointer;
  background-color: #fff;
  &:last-child {
    padding-right: 4px;
  }

  @media (max-width: 500px) {
    background-color: transparent;
    &:last-child {
      padding-right: 2px;
    }

    &:first-child {
      margin-left: 8px;
    }

    svg {
      color: #777 !important;
      min-width: 20px !important;
      min-height: 20px !important;
    }
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;
