/* eslint-disable import/no-cycle */
import React from 'react';
import history from '~/routes/history';
import { showPage, showReport } from '~/applications/ControlPage';
import downloadLink from '~/easy-components/Helpers/downloadLink';
import Icon from '~/easy-components/Icon';
import {
  splitSourcePath,
  splitFileName,
  normalizeFileName,
} from '~/easy-components/Helpers/fileHandler';
import getReportSettings from './Helpers/getReportSettings';
import {
  createAsyncFunctionByString,
  createSyncFunctionByString,
} from '../AsyncFunctionString';

const getDynamicProps = ({
  settings,
  params,
  dispatch,
  promptRef,
  customModalRef,
  auxScope,
}) => {
  const getIcon = iconProps => {
    return <Icon {...iconProps} />;
  };

  const dynamicFunctionProps = settings && settings.dynamicFunctionProps;

  const dynamicProps = {
    ...dynamicFunctionProps,
    ...params,
    ...auxScope,
    getIcon,
    history,
    showPage: (route, externalData, options) => {
      showPage({ route, externalData, dispatch, ...options });
    },
    showReport: async (hash, externalData, options) => {
      showReport({ reportCode: hash, externalData, dispatch, ...options });
    },
    getReportSettings,
    prompt: ({ type, labelButtons, title, message, onConfirm, onCancel }) => {
      promptRef.current.show({
        type,
        labelButtons,
        title,
        message,
        onConfirm,
        onCancel,
      });
    },
    customModal: ({
      type,
      title,
      message,
      dataToReplace,
      reportOptions,
      iconsContent,
      htmlContent,
      onConfirm,
      onCancel,
    }) => {
      customModalRef.current.show({
        type,
        title,
        message,
        dataToReplace,
        reportOptions,
        iconsContent,
        htmlContent,
        onConfirm,
        onCancel,
      });
    },
    downloadLink,
    splitSourcePath,
    splitFileName,
    normalizeFileName,
  };

  return dynamicProps;
};

const dynamicFunction = async ({
  functionString,
  params,
  settings,
  dispatch,
  promptRef,
  customModalRef,
  auxScope,
}) => {
  if (functionString) {
    const dynamic = createAsyncFunctionByString({
      functionString,
    });

    const dynamicProps = getDynamicProps({
      settings,
      params,
      dispatch,
      promptRef,
      customModalRef,
      auxScope,
    });

    const response = await dynamic(dynamicProps);

    return response;
  }

  return null;
};

export default dynamicFunction;

export const syncDynamicFunction = ({
  functionString,
  params,
  settings,
  dispatch,
  promptRef,
  customModalRef,
  auxScope,
}) => {
  if (functionString) {
    const dynamic = createSyncFunctionByString({
      functionString,
    });

    const dynamicProps = getDynamicProps({
      settings,
      params,
      dispatch,
      promptRef,
      customModalRef,
      auxScope,
    });

    const response = dynamic(dynamicProps);

    return response;
  }

  return null;
};
