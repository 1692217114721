import styled, { css } from 'styled-components';

const ownerProps = css`
  margin-left: 40px;
  margin-right: 20px;
  background-color: #dcf8c6;
  box-shadow: -1px 1px 2px #00000017;

  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    right: -8px;
    top: calc(50% - 8px);
    background: #dcf8c6;
    -webkit-transform: rotate(45deg);
  }
`;

export const Container = styled.div`
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 40px;
  margin-left: 20px;
  border-radius: 10px;
  position: relative;
  color: #333;
  box-shadow: 1px 1px 2px #00000017;
  position: relative;

  svg.eye {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    cursor: pointer;

    &:hover {
      color: #6fabc4 !important;
    }
  }

  &:hover {
    svg.eye {
      display: flex;
    }
  }

  ${props =>
    !props.isOwner &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        left: -8px;
        top: calc(50% - 8px);
        background: #fff;
        -webkit-transform: rotate(45deg);
      }
    `}

  .mention {
    color: #67a8ca;
    font-weight: 500;
  }

  ${props => props.isOwner && ownerProps}
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  > h1 {
    font-size: 0.8rem;
    font-weight: 500;
    color: #6fabc4;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;

  > h1 {
    font-size: 0.8rem;
    font-weight: 500;
    color: #aaa;
    margin-right: 4px;
  }
`;

export const ErrorDescription = styled.div`
  color: #ff7777;
  padding: 5px;
  overflow-wrap: anywhere;
  font-size: 0.9rem;
  display: ${props => (props.isVisible ? 'flex' : 'none')};
`;

export const MessageText = styled.div`
  overflow-wrap: anywhere;
  color: #777;
`;
