/* eslint-disable import/no-cycle */
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  memo,
} from 'react';

import MonacoEditor from '~/easy-components/Editor';

function Editor(
  { code, hidden = false, onKeyDown, language = 'javascript' },
  ref
) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(code);
  }, [code]);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
    };
  });

  return (
    <MonacoEditor
      hidden={hidden}
      language={language}
      value={value || ''}
      onChange={setValue}
      onKeyDown={onKeyDown}
    />
  );
}

export default memo(forwardRef(Editor));
