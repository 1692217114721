import styled from 'styled-components';

export const Container = styled.section`
  height: 80px;
  min-height: 80px;
  width: 250px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #f0f0f0;

  button {
    border: none;
    background-color: transparent;
    padding: 4px;
    margin-right: 5px;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  > svg {
    margin-left: 10px;
  }

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  h2 {
    font-weight: normal;
    font-size: 0.7rem;
    color: #999999;
    text-align: right;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  line-height: 18px;
  color: #333;
  font-size: 1.1rem;
  font-weight: 400;

  > div {
    color: #999;
    font-size: 0.9rem;
  }
`;
