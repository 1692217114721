/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* import React, { useRef, useState, useMemo } from 'react';

import { FiEdit, FiPhone } from 'react-icons/fi';
import { IoIosPeople } from 'react-icons/io';

import { add } from 'date-fns';

import TextArea from '~/easy-components/Form/TextArea';
import Linker from '~/easy-components/Form/Linker';
import DateField from '~/easy-components/Form/DateField';
import MaskField from '~/easy-components/Form/MaskField';
import Checkbox from '~/easy-components/Form/Checkbox';
import Input from '~/easy-components/Form/Input';
// import useLocale from '~/hooks/useLocale';

import {
  formatHourMask,
  validateHour,
  setStartEndTimeByText,
  setDurationTime,
  setReminderData,
  formatReminderData,
} from '~/easy-components/DateHourHelpers';

import ActivityTypesService from '~/applications/CRM/services/ActivityTypesService';
import { Container } from './styles';
import ActivityButton from './ActivityButton'; */

// eslint-disable-next-line react/prop-types
function ActivityToolbar() {
  /* const formRef = useRef();
  const [formData, setFormData] = useState();

  const configurations = useMemo(() => {
    const currentDate = new Date();

    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');

    const time = `${hours}:${minutes}:00`;

    const endDate = add(currentDate, { minutes: 15 });

    const endHours = String(endDate.getHours()).padStart(2, '0');
    const endMinutes = String(endDate.getMinutes()).padStart(2, '0');

    const endTime = `${endHours}:${endMinutes}:00`;

    return [
      {
        name: 'Note',
        title: 'Nota',
        icon: <FiEdit size={20} color="#fff" />,
        defaultConfig: {
          Activity: 'E',
          ActivityType: -1,
          _ActivityType: 'Reuniões',
          Subject: 1,
          _Subject: 'Planejamento',
          CardCode: keyValue,
          Duration: 900,
          DurationType: 'S',
          Reminder: 'N',
          ReminderPeriod: 900,
          ReminderType: 'S',
          StartDate: currentDate,
          StartTime: time,
        },
        fields: [
          <TextArea label="Observações" name="Details" formRef={formRef} />,
          <TextArea label="Conteúdo" name="Notes" formRef={formRef} />,
        ],
      },
      {
        name: 'Phone',
        title: 'Telefonema',
        icon: <FiPhone size={20} color="#fff" />,
        defaultConfig: {
          Activity: 'C',
          ActivityType: -1,
          _ActivityType: 'Reuniões',
          Subject: 1,
          _Subject: 'Planejamento',
          CardCode: keyValue,
          Duration: 900,
          DurationType: 'S',
          Reminder: 'Y',
          ReminderPeriod: 900,
          ReminderType: 'S',
          StartDate: currentDate,
          StartTime: time,
        },
        fields: [
          <Linker
            label="Tipo"
            name="_ActivityType"
            valueField="ActivityType"
            formRef={formRef}
            method={async filter => {
              const response = await ActivityTypesService.list(filter);
              return response;
            }}
            onChange={async filter => {
              formRef.current.setFieldValue('_Subject', null);
              formRef.current.setFieldValue('Subject', null);

              const currentFormData = formRef.current.getData();

              const response = await ActivityTypesService.listSubjects({
                filter,
                activityTypeCode: currentFormData.ActivityType,
              });

              formRef.current.setFieldValue('_Subject', response[0].label);
              formRef.current.setFieldValue('Subject', response[0].value);
            }}
          />,
          <Linker
            label="Assunto"
            name="_Subject"
            valueField="Subject"
            formRef={formRef}
            method={async filter => {
              const currentFormData = formRef.current.getData();

              const response = await ActivityTypesService.listSubjects({
                filter,
                activityTypeCode: currentFormData.ActivityType,
              });
              return response;
            }}
          />,
          <DateField
            label="Data Inicial"
            name="StartDate"
            formRef={formRef}
            formatYear
          />,

          <MaskField
            label="Horário Inicial"
            name="StartTime"
            formRef={formRef}
            mask="99:99"
            converter={formatHourMask}
            onChange={e => {
              validateHour(e, 'StartTime', formRef);
            }}
          />,
          <Input
            label="Duração Hide"
            name="Duration"
            formRef={formRef}
            hidden
          />,

          <Input
            label="Duração"
            name="_DurationFormatted"
            formRef={formRef}
            hidden
            onChange={e => setStartEndTimeByText(e, formRef)}
          />,
          <TextArea label="Observações" name="Details" formRef={formRef} />,
          <Checkbox
            labelWidth={200}
            name="Reminder"
            label="Lembrete"
            formRef={formRef}
          />,

          <Input
            label="Tipo Hide"
            name="ReminderType"
            formRef={formRef}
            fieldDefaultValue="S"
            hidden
          />,
          <Input
            label="Lembrar Hide"
            name="ReminderPeriod"
            formRef={formRef}
            hidden
          />,
          <Input
            label="Lembrar"
            name="_ReminderPeriod"
            formRef={formRef}
            onChange={e => setReminderData(e, formRef)}
          />,
        ],
      },
      {
        name: 'Meeting',
        title: 'Reunião',
        icon: <IoIosPeople size={24} color="#fff" />,
        defaultConfig: {
          Activity: 'M',
          ActivityType: -1,
          _ActivityType: 'Reuniões',
          Subject: 1,
          _Subject: 'Planejamento',
          CardCode: keyValue,
          Reminder: 'Y',
          ReminderPeriod: 900,
          _ReminderPeriod: '15 Minutos',
          ReminderType: 'S',
          StartDate: currentDate,
          StartTime: time,
          EndDueDate: endDate,
          EndTime: endTime,
          DurationType: 'S',
          Duration: 900,
          _DurationFormatted: '15 Minutos',
        },
        fields: [
          <Linker
            label="Tipo"
            name="_ActivityType"
            valueField="ActivityType"
            formRef={formRef}
            method={async filter => {
              const response = await ActivityTypesService.list(filter);
              return response;
            }}
            onChange={async filter => {
              formRef.current.setFieldValue('_Subject', null);
              formRef.current.setFieldValue('Subject', null);

              const currentFormData = formRef.current.getData();

              const response = await ActivityTypesService.listSubjects({
                filter,
                activityTypeCode: currentFormData.ActivityType,
              });

              formRef.current.setFieldValue('_Subject', response[0].label);
              formRef.current.setFieldValue('Subject', response[0].value);
            }}
          />,
          <Linker
            label="Assunto"
            name="_Subject"
            valueField="Subject"
            formRef={formRef}
            method={async filter => {
              const currentFormData = formRef.current.getData();

              const response = await ActivityTypesService.listSubjects({
                filter,
                activityTypeCode: currentFormData.ActivityType,
              });
              return response;
            }}
          />,
          <DateField
            label="Data Inicial"
            name="StartDate"
            formRef={formRef}
            onChange={() => setDurationTime(formRef)}
            formatYear
          />,
          <MaskField
            label="Horário Inicial"
            name="StartTime"
            formRef={formRef}
            mask="99:99"
            converter={formatHourMask}
            onChange={e => {
              const validHour = validateHour(e, 'StartTime', formRef);
              if (validHour) setDurationTime(formRef);
            }}
          />,
          <DateField
            label="Data"
            name="EndDueDate"
            formRef={formRef}
            onChange={() => setDurationTime(formRef)}
            formatYear
          />,
          <MaskField
            label="Horário"
            name="EndTime"
            formRef={formRef}
            mask="99:99"
            converter={formatHourMask}
            onChange={e => {
              const validHour = validateHour(e, 'EndTime', formRef);
              if (validHour) setDurationTime(formRef);
            }}
          />,
          <Input
            label="Tipo Duração Hide"
            name="DurationType"
            formRef={formRef}
            hidden
          />,
          <Input
            label="Duração Hide"
            name="Duration"
            formRef={formRef}
            hidden
          />,
          <Input
            label="Duração"
            name="_DurationFormatted"
            formRef={formRef}
            onChange={e => setStartEndTimeByText(e, formRef)}
          />,

          <TextArea label="Observações" name="Details" formRef={formRef} />,
          <Checkbox
            labelWidth={200}
            name="Reminder"
            label="Lembrete"
            formRef={formRef}
          />,

          <Input
            label="Tipo Hide"
            name="ReminderType"
            formRef={formRef}
            fieldDefaultValue="S"
            hidden
          />,
          <Input
            label="Lembrar Hide"
            name="ReminderPeriod"
            formRef={formRef}
            hidden
          />,
          <Input
            label="Lembrar"
            name="_ReminderPeriod"
            formRef={formRef}
            onChange={e => {
              setReminderData(e, formRef);
              formatReminderData(formRef);
            }}
          />,
        ],
      },
    ];
  }, [keyValue]); */

  return null;

  /* return (
    <Container isVisible={isVisible}>
      {configurations.map(config => {
        return (
          <ActivityButton
            key={config.name}
            formData={formData}
            setFormData={setFormData}
            formRef={formRef}
            config={config}
          />
        );
      }) }
    </Container>
  ); */
}

export default ActivityToolbar;
