/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React from 'react';

import { Container } from './styles';

const Scanner = () => {
  return (
    <Container>
      <div id="reader" />
    </Container>
  );
};
export default Scanner;
