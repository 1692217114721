/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';

import { Decimal } from 'decimal.js';
import { isMobile } from 'react-device-detect';

import PageHeader from '~/easy-components/PageHeader';
import FlexSpace from '~/easy-components/FlexSpace';
import Button from '~/easy-components/Button';
import ModalFooter from '~/easy-components/ModalFooter';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Panel from '~/easy-components/Panel';
import Toast from '~/easy-components/Toast';
import Form from '~/easy-components/Form';

import useCompany from '~/hooks/useCompany';

import BinLocationService from '~/applications/CRM/services/BinLocationService';
import Input from '~/easy-components/Form/Input';
import Number from '~/easy-components/Form/Number';
import { Container, Content, ItemsContent, ItemLine } from './styles';
import { HeaderCell, ColumnCell } from '../styles';

function BinPage({
  title,
  isReadOnly,
  data,
  batchNumber,
  loteLineIndex,
  direction,
  onClose,
  onConfirm,
  t,
}) {
  const quantityInputsRefs = useRef([]);
  const formModalRef = useRef();
  const binLocationAttributedRef = useRef([]);
  const charPermitted = '0123456789,.';

  const { numberToString, stringToNumber } = useCompany();

  const [binLocations, setBinLocations] = useState([]);
  const [errors, setErrors] = useState({});
  const [quantityTotal, setQuantityTotal] = useState(new Decimal(0));

  const quantityReceive = Decimal(data.Quantity);
  const unitQuantityReceive = Decimal(data.UnitQuantity);
  const valueRequired = quantityReceive.div(unitQuantityReceive);

  let qtdNecessary = Decimal(data.Quantity).div(Decimal(data.UnitQuantity));
  const qtdNecessaryString = numberToString(qtdNecessary);
  qtdNecessary = stringToNumber(qtdNecessaryString);

  const [state, setState] = useState({
    valueSelected: new Decimal(0),
    valueRequired,
  });

  const whsCode =
    direction === 'from' ? data.FromWarehouseCode : data.WarehouseCode;

  const handleFocus = e => e.target.select();

  const handleRefs = (el, quantity) => {
    if (el && !quantityInputsRefs.current.includes(el)) {
      el.value = quantity;
      quantityInputsRefs.current.push(el);
    }
  };

  const refreshBinLocations = ({ binsLoaded, reload = false }) => {
    const listBinLocationMerged = binsLoaded.map(bin => {
      const binAllocationItem = binLocationAttributedRef.current.find(
        binAttributed => {
          return binAttributed.BinAbsEntry == bin.AbsEntry;
        }
      );

      let stockTransferLines = data.StockTransferLinesBinAllocations;
      if (typeof stockTransferLines === 'string') {
        stockTransferLines = JSON.parse(data.StockTransferLinesBinAllocations);
      }

      const loadDataStockTransfer = stockTransferLines.find(
        stockTransferLineBinAllocation => {
          return (
            stockTransferLineBinAllocation.BinAbsEntry == bin.AbsEntry &&
            stockTransferLineBinAllocation.BinActionType ==
              'batFromWarehouse' &&
            stockTransferLineBinAllocation.SerialAndBatchNumbersBaseLine ==
              loteLineIndex
          );
        }
      );

      let _CachedQuantity = 0;

      if (loadDataStockTransfer) {
        _CachedQuantity = loadDataStockTransfer.Quantity;
      }

      if (
        (bin.CommitQty === 0 && bin.OnHandQty > 0) ||
        binAllocationItem ||
        loadDataStockTransfer ||
        reload ||
        bin.CommitQty === undefined
      ) {
        bin.CommitQty = bin.OnHandQty;
      }

      const result =
        bin.CommitQty -
        (binAllocationItem ? binAllocationItem.Quantity || 0 : 0) -
        _CachedQuantity;

      return {
        ...bin,
        CommitQty: result,
        _CachedQuantity,
      };
    });

    setBinLocations(listBinLocationMerged);
  };

  const getSelectedTotal = () => {
    let selectedTotal = new Decimal(0);

    binLocations.forEach(binDataLine => {
      let binQuantity = 0;

      for (let i = 0; i < quantityInputsRefs.current.length; i++) {
        const el = quantityInputsRefs.current[i];

        if (el.name == binDataLine.AbsEntry) {
          binQuantity = el.value;
        }
      }
      const binQuantityDec = new Decimal(binQuantity);
      selectedTotal = selectedTotal.plus(binQuantityDec);
    });

    return selectedTotal;
  };

  function handleOnBlur({ name, value }) {
    const qtd = stringToNumber(value);
    const total = getSelectedTotal();

    if (qtd > qtdNecessary || qtd > state.valueRequired) {
      Toast.error(t('QuantityLTNecessary'));
    } else {
      const binLocationAttributed = binLocationAttributedRef.current.filter(
        bin => {
          return (
            bin.BinAbsEntry == name &&
            bin.SerialAndBatchNumbersBaseLine == loteLineIndex
          );
        }
      );

      if (binLocationAttributed.length === 0) {
        const binAllocationLine = {
          SerialAndBatchNumbersBaseLine: loteLineIndex,
          BinAbsEntry: stringToNumber(name),
          Quantity: qtd,
          BinActionType:
            direction === 'from' ? 'batFromWarehouse' : 'batToWarehouse',
          _direction: direction,
        };

        if (qtd > 0) binLocationAttributedRef.current.push(binAllocationLine);
      } else if (qtd > 0) binLocationAttributed[0].Quantity = qtd;
      else {
        binLocationAttributedRef.current.pop(binLocationAttributed[0]);
      }
      setQuantityTotal(total);
      refreshBinLocations({ binsLoaded: binLocations });
    }
  }

  const loadBinLocationData = useCallback(async () => {
    try {
      const binsLoaded = await BinLocationService.getBinLocales({
        itemCode: data.ItemCode,
        whsCode,
        batchNum: batchNumber || null,
      });
      refreshBinLocations({ binsLoaded });
    } catch (error) {
      Toast.error('Não existe Estoque para esse Item no Depósito');
    }
  }, []);

  useEffect(() => {
    loadBinLocationData();
  }, []);

  useEffect(() => {
    setState({
      valueSelected: quantityTotal,
      valueRequired: qtdNecessary - quantityTotal,
    });
    formModalRef.current.setFieldValue('_Selected', quantityTotal);
    formModalRef.current.setFieldValue(
      '_Required',
      qtdNecessary - quantityTotal
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityTotal]);

  const titles = useMemo(() => {
    if (isMobile) {
      return {
        document: t('Doc'),
        warehouse: t('Whs'),
        quantity: t('Qtd'),
        released: t('Req'),
        selected: t('Selec'),
      };
    }
    return {
      document: t('Document'),
      warehouse: t('WhsCode'),
      quantity: t('Quantity'),
      required: t('Required'),
      selected: t('Selected'),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const automaticSelection = async () => {
    for (let i = 0; i < quantityInputsRefs.current.length; i++) {
      const total = getSelectedTotal();
      const inputElement = quantityInputsRefs.current[i];
      const inputValue = inputElement.value || 0;
      inputElement.value = qtdNecessary - (total - inputValue);

      if (inputElement.value > 0) {
        handleOnBlur({
          name: inputElement.name,
          value: Decimal(inputElement.value),
        });
      }
    }
    const total = getSelectedTotal();
    setQuantityTotal(total);
  };

  const clear = () => {
    for (let i = 0; i < quantityInputsRefs.current.length; i++) {
      const el = quantityInputsRefs.current[i];
      el.value = 0;
      binLocationAttributedRef.current = [];
    }
    setQuantityTotal(Decimal(0));
    refreshBinLocations({ binsLoaded: binLocations, reload: true });
  };

  const fieldProps = {
    labelWidth: 150,
  };

  return (
    <Container>
      {title && <PageHeader>{title}</PageHeader>}
      <Form ref={formModalRef} data={data} errors={errors}>
        <Content>
          <ColumnLayout>
            <Panel width="400px">
              {batchNumber && (
                <Input
                  label={t('BatchNumber')} /* Lote */
                  name="BatchNumber"
                  value={batchNumber}
                  {...fieldProps}
                />
              )}

              <Input
                label={t('WhsCode')} /* Código do Depósito */
                name="WhsCode"
                value={whsCode}
                {...fieldProps}
              />

              <Input
                label={t('ItemCode')}
                name="ItemCode"
                value={data.ItemCode}
                {...fieldProps}
              />
            </Panel>
            <Panel width="400px">
              <Number
                isNumber
                label={titles.quantity}
                name="_Quantity"
                value={qtdNecessary}
                {...fieldProps}
              />

              <Number
                isNumber
                label={titles.selected}
                name="_Selected"
                value={state.valueSelected || 0}
                {...fieldProps}
              />

              <Number
                isNumber
                label={titles.required}
                name="_Required"
                value={state.valueRequired}
                {...fieldProps}
              />
            </Panel>
          </ColumnLayout>

          <ItemsContent>
            <table>
              <thead>
                <tr>
                  <HeaderCell>{t('WarehousePosition')}</HeaderCell>
                  <HeaderCell contentType="number">{t('EnStock')}</HeaderCell>
                  <HeaderCell contentType="number">{t('Available')}</HeaderCell>
                  <HeaderCell contentType="number">
                    {t('Attributed')}
                  </HeaderCell>
                </tr>
              </thead>
              <tbody>
                {binLocations.map((item, idx) => {
                  const quantity = item._CachedQuantity || 0;

                  return (
                    <ItemLine
                      key={idx}
                      display="table-row"
                      lineColor={() => {
                        const lineColor = '#f9f9f9';
                        return lineColor;
                      }}
                    >
                      <ColumnCell>{item.BinCode}</ColumnCell>
                      <ColumnCell contentType="number">
                        {item.OnHandQty}
                      </ColumnCell>
                      <ColumnCell contentType="number">
                        {item.CommitQty}
                      </ColumnCell>
                      <ColumnCell contentType="number" input>
                        <input
                          name={item.AbsEntry}
                          ref={el => handleRefs(el, quantity)}
                          onBlur={e =>
                            handleOnBlur({
                              value: e.target.value,
                              name: e.target.name,
                            })
                          }
                          onKeyPress={e => {
                            if (charPermitted.indexOf(e.key) < 0) {
                              e.preventDefault();
                            }
                          }}
                          onFocus={handleFocus}
                        />
                      </ColumnCell>
                    </ItemLine>
                  );
                })}
              </tbody>
            </table>
          </ItemsContent>
        </Content>
      </Form>
      <ModalFooter>
        {!isReadOnly && (
          <>
            <Button
              buttonType="Default"
              onClick={async () => {
                await automaticSelection();
              }}
              style={{ marginLeft: '0px' }}
            >
              {t('AutoSelect')}
            </Button>
            <Button
              buttonType="Default"
              onClick={() => {
                clear();
              }}
            >
              {t('Clear')}
            </Button>
            <FlexSpace />
          </>
        )}
        <Button
          buttonType="Default"
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        >
          {t('Cancel')}
        </Button>

        {!isReadOnly && (
          <Button
            buttonType="Emphasized"
            onClick={e => {
              e.stopPropagation();

              const selectedValue = formModalRef.current.getFieldValue(
                '_Selected'
              );

              if (qtdNecessary !== selectedValue) {
                Toast.error(
                  t(
                    'error.NecessaryAmountDifferentSelectedOne'
                  ) /* A quantidade necessária é diferente da selecionada */
                );
              } else {
                onConfirm({
                  direction,
                  binLocationsAttributed: binLocationAttributedRef.current,
                });

                onClose();
              }
            }}
          >
            {t('Confirm')}
          </Button>
        )}
      </ModalFooter>
    </Container>
  );
}

export default BinPage;
