import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 10px;
  position: relative;
`;

export const Number = styled.div`
  padding: 10px;
  position: fixed;
  bottom: 30px;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    color: ${props => props.color};
  }
  // align-items: baseline;
`;

export const Value = styled.div`
  font-size: 2.8rem;
  margin-left: 10px;
  // color: #888;
`;

export const Unit = styled.div`
  font-size: 2rem;
  color: #888;
  font-weight: 200;
  margin-top: 10px;
`;

export const Aux = styled.div`
  font-size: 1rem;
  color: #aaa;
  position: fixed;
  bottom: 25px;
  right: 20px;
`;

export const Error = styled.div`
  color: #ef8080;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow-wrap: anywhere;
`;
