import styled from 'styled-components';

export const Content = styled.div`
  flex: 1;
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  overflow: auto;

  h1 {
    margin: 10px 0 15px;
  }
`;

export const SimpleListItem = styled.div`
  padding: 20px 20px;
  /*transition: 0.2s; */

  pointer-events: none;
  cursor: pointer;

  background-color: ${props => (props.selected ? '#496774' : '#627e8d')};
  color: #fff;
  transition: background 0.4s;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-weight: 300;
  }

  > section {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`;
