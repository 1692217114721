/* eslint-disable no-restricted-syntax */
import i18n from 'i18next';
import Axios from 'axios';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nHttpLoader from 'i18next-http-backend';

const serverUrl = process.env.REACT_APP_SERVER_URL;

// import ptBr from './pt-BR';
// import enUS from './en-US';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(i18nHttpLoader)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    initImmediate: false,
    // do not load a fallback
    lng: localStorage.getItem('i18nextLng') || 'pt-BR',
    fallbackLng: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    /* resources: {
      'pt-BR': ptBr,
      'en-US': enUS,
    }, */
    react: {
      // useSuspense: false,
      // Mark this false if you dont want to use suspense else
      // you will end up getting a no fallback ui error for sure
    },
    load: 'currentOnly',
    backend: {
      loadPath: `${serverUrl}/translations/{{lng}}`,
      // See {{lng}} here? This loops between your languages. So for ex if you
      // click on "es" then your url will become
      // 'http://localhost:3000/public/translations/es.json'
      // if "en"
      // 'http://localhost:3000/public/translations/en.json'
      // This url points to my server where my translations are being stored
      // This is how you will store strings inside server's es.json and others
      // This is done by loadPath so One less thing
      // es.json:
      // {
      // "Hey Yo Im at home": "Hey yo estoy en casa",
      // "Hey Yo Im inside Room": "Hola, yo estoy dentro de la habitación"
      // }

      request: (options, url, payload, callback) => {
        Axios.get(url)
          .then(res => {
            const translations = {};
            // eslint-disable-next-line no-restricted-syntax
            // eslint-disable-next-line guard-for-in
            for (const route in res.data.translations) {
              const routeTranslations = res.data.translations[route];
              translations[route] = {
                ...res.data.global,
                ...routeTranslations,
              };
            }
            res.data = translations;
            callback(null, res);
          })
          .catch(err => {
            console.error(err);
            callback(err, null);
          });
      },
      // This is used to create a get request and you can use anything to make request
      // I prefer Axios because it is really flexible with interceptors and tons of things
      // You can log everything to see what they return and frankly speaking their name itself
      // tells whole story yet if you want to find out, then it is a good thing.
      // Pass your response to callback which takes (err, succ) as parameter.
    },
  });

export default i18n;
