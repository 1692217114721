import Toast from '../Toast';

class TreatError {
  isValidationError(err) {
    if (err.response && typeof err.response.data === 'object') {
      const { data } = err.response;
      return !!this.getValidationErrors(data);
    }
    return false;
  }

  getValidationErrors(data) {
    let { validationErrors } = data;

    if (typeof data.message === 'object') {
      validationErrors = data.message.validationErrors;
    }
    return validationErrors;
  }

  getDescription(err) {
    if (typeof err === 'string') return err;

    let { message } = err;

    if (err.response) {
      /* if (err.response.status && err.response.status === 404)
        message = `Recurso ${err.response.config.method.toUpperCase()} || ${
          err.response.config.url
        } não encontrado`;
      else if (err.response.data) { */
      if (typeof err.response.data === 'object') {
        if (err.response.data.name) {
          if (err.response.data.name === 'ValidationError') {
            message = 'Verifique os erros e tente novamente';
          } else
            message =
              err.response.data.message || err.response.data.error || message;
        } else
          message =
            err.response.data.message || err.response.data.error || message;
      } else if (err.response.data.indexOf('<html>') === -1)
        message = err.response.data || err.response.data.error || message;
      // }
    }

    if (typeof message === 'string') {
      if (message.indexOf('timeout') >= 0)
        return 'Servidor excedeu o tempo de resposta';

      if (message.indexOf('Internal server error') >= 0)
        return 'Erro interno no servidor';

      if (message.indexOf('Network Error') >= 0)
        return 'Servidor não encontrado';

      if (message === 'Request failed with status code 404')
        return 'Não foi possível encontrar o recurso no servidor';

      if (message.indexOf('Fail to get DB Credentials from SLD.') >= 0)
        return 'Usuário ou senha inválido';
    } else if (message && message.originalError) {
      if (message.originalError && message.originalError.info)
        message = message.originalError.info.message;
      else message = message.originalError.message;
    }

    return message;
  }

  showError(error, setErrors, type = 'error') {
    // err.response.data.errorData
    if (setErrors && this.isValidationError(error)) {
      Toast[type]('Não foi possível realizar a operação');
      const response = error.response || {};
      const data = response.data || {};
      setErrors(this.getValidationErrors(data));
    } else {
      const message = this.getDescription(error);
      Toast[type](message);
    }
  }
}

export default new TreatError();
