import styled, { css } from 'styled-components';

export const Container = styled.div`
  font-size: 0.9rem;
  color: #666;

  > div {
    margin-bottom: 2px;
    font-size: 0.9rem;
    color: #666;

    ${props =>
      props.direction === 'row' &&
      css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      `}
  }
`;

export const Div = styled.div`
  ${props => props.isGenericListItem && css `
    width: 150px;
    white-space: nowrap; 
    overflow:hidden;
    text-overflow: ellipsis;
  `}
`;
