export default function isEmptyField(field) {
  if (field === null || field === undefined) {
    return true;
  }

  if (field === '') {
    return true;
  }

  if (typeof field === 'string' && field.trim() === '') {
    return true;
  }

  if (Array.isArray(field) && field.length === 0) {
    return true;
  }

  if (typeof field === 'number' && field === 0) {
    return true;
  }

  if (typeof field === 'boolean' && field === false) {
    return true;
  }

  if (field instanceof Date && Number.isNaN(field)) {
    return true;
  }

  if (typeof field === 'object' && Object.keys(field).length === 0) {
    return true;
  }

  return false;
}
