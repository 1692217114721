import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    margin: 10px 20px 10px 20px;
  }

  > aside {
    flex: 1;
    padding: 10px;
  }

  aside {
    display: flex;
    overflow: auto;
  }
`;
