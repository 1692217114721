/* eslint-disable react/prop-types */
// @ts-ignore
import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
} from 'react';
import EmailListView from './EmailListView';
import SelectAll from '../SelectAll';

import { Container, Header, Body } from './styles';

/**
 * @typedef {Object} Props
 * @property {string} headerLabel
 * @property {(files: FileList) => Promise<{sourcePath: string, itemName: string}[]>} onLoadEmailList
 */

/** @type {React.FC<Props>} */
const EmailListPanel = forwardRef(
  ({ onLoadEmailList, headerLabel, t }, ref) => {
    /** @type {import('react').MutableRefObject<HTMLInputElement>} */
    const uploadInputRef = useRef();

    /** @type {ReturnType<typeof useState<{sourcePath: string, fileName: string}[]>>} */
    const [data, setData] = useState([]);

    /** @type {ReturnType<typeof useState<number[]>>} */
    const [selectedEmailListIndexes, setSelectedEmailListIndexes] = useState(
      []
    );

    useImperativeHandle(ref, () => {
      return {
        setEmailList: emailList => setData(emailList),
        getSelectedEmails: () => {
          return selectedEmailListIndexes.map(emailIndex => data[emailIndex]);
        },
      };
    });

    return (
      <Container>
        <input
          ref={uploadInputRef}
          hidden
          type="file"
          multiple
          onChange={e => {
            const { files } = e.target;

            onLoadEmailList(files).then(newEmails => {
              const newSelectionRange = {
                from: data.length,
                to: data.length + newEmails.length - 1,
              };

              setData(oldData => [...oldData, ...newEmails]);

              setSelectedEmailListIndexes(oldSelection => {
                const newSelection = [...oldSelection];

                for (
                  let i = newSelectionRange.from;
                  i <= newSelectionRange.to;
                  i += 1
                ) {
                  newSelection.push(i);
                }

                return newSelection;
              });
            });
          }}
        />
        <Header>
          <h3>{headerLabel}</h3>
        </Header>
        <Body>
          <SelectAll
            title={t('SelectAll')}
            onChange={isChecked => {
              setSelectedEmailListIndexes(() => {
                if (isChecked) {
                  return data.map((i, idx) => idx);
                }
                return [];
              });
            }}
          />
          {data.map((item, index) => (
            <EmailListView
              itemName={item.email}
              isChecked={selectedEmailListIndexes.includes(index)}
              onChange={isChecked => {
                setSelectedEmailListIndexes(oldSelected => {
                  if (isChecked) {
                    return [...oldSelected, index];
                  }
                  return oldSelected.filter(s => s !== index);
                });
              }}
            />
          ))}
        </Body>
      </Container>
    );
  }
);

export default EmailListPanel;
