import styled from 'styled-components';

export const IconPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1px;
  cursor: pointer;
  background-color: transparent;
  &:last-child {
    padding-right: 4px;
  }

  @media (max-width: 500px) {
    background-color: transparent;
    &:last-child {
      padding-right: 2px;
    }
  }
`;
