import styled from 'styled-components';

export const QueriesPanel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;
  background-color: #555;

  > div {
    overflow: auto;
    flex: 1;
    flex-direction: column;
    display: flex;
  }

  > div:first-child {
    margin-right: 2px;
  }
`;

export const ModalBody = styled.div`
  padding: 10px 20px;

  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f0f0f0;

  span {
    margin-bottom: ${props => (props.type === 'question' ? '25px' : 0)};
    color: #888;
  }

  @media (max-width: 500px) {
    padding: 20px;
  }
`;

export const Footer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  margin-left: 10px;
`;
