/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import mimeType from 'mime-types';
import Icon from '~/easy-components/Icon';
import colors from '~/styles/colors';
import Upload from '~/easy-components/Upload';
import IFrame from '~/easy-components/IFrame';
import { normalizeFileName } from '~/easy-components/Helpers/fileHandler';
import { Container, FileInfo, ImageContent } from './styles';
import CompanyService from '../../services/CompanyService';

function SingleUpload({
  fileInfo,
  accept,
  message,
  onUpload,
  onRemove,
  isReadOnly,
  isShowPreview = false,
  onDownloadClick,
  onImageClick,
}) {
  const [documentUrl, setDocumentUrl] = useState();

  const fileName = useMemo(() => {
    let name;

    const filepath = fileInfo.fileName || '';

    if (filepath.includes('\\')) {
      name = filepath.split('\\').pop();
    } else {
      name = filepath;
    }

    return normalizeFileName(name);
  }, [fileInfo.fileName]);

  async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = error => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  const getUrlPreview = async () => {
    const token = await CompanyService.getCompanyToken();

    if (fileInfo.fileName) {
      const fullPathBase64 = btoa(fileInfo.fileName);

      if (fileInfo.file) {
        const base64 = await fileToBase64(fileInfo.file);

        const contentType = mimeType.lookup(fileInfo.fileName);

        const dataUrl = `data:${contentType};base64,${base64}`;

        return dataUrl;
      }

      const url = `${process.env.REACT_APP_SERVER_URL}/files/${token}/dowload/${fullPathBase64}`;

      return url;
    }

    return null;
  };

  const refreshUrlPreview = async () => {
    const urlPreview = await getUrlPreview();
    setDocumentUrl(urlPreview);
  };

  const handleImageClick = () => {
    if (onImageClick) onImageClick({ url: documentUrl });
  };

  const renderBasedOnFileExtension = () => {
    const fileExtension = fileInfo.fileName.split('.').pop();

    if (fileExtension === 'pdf') {
      return <IFrame src={documentUrl} />;
    }

    return (
      <ImageContent>
        <img src={documentUrl} onClick={handleImageClick} />
      </ImageContent>
    );
  };

  useEffect(() => {
    refreshUrlPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileInfo.fileName]);

  return (
    <Container>
      {fileInfo.fileName && (
        <FileInfo>
          <div>
            <Icon name="HiOutlineDocumentReport" size={28} color="#555" />
            <h1>{fileName}</h1>

            {!isReadOnly && (
              <Icon
                name="IoMdClose"
                onClick={onRemove}
                size={20}
                color={colors.red}
              />
            )}
          </div>
          {!!onDownloadClick && (
            <Icon
              name="AiOutlineDownload"
              size={20}
              onClick={onDownloadClick}
              color={colors.mainActiveTab}
            />
          )}
        </FileInfo>
      )}
      {isShowPreview && fileInfo.fileName ? (
        renderBasedOnFileExtension()
      ) : (
        <Upload
          message={fileInfo.fileName || message}
          accept={accept}
          onUpload={onUpload}
          isReadOnly={isReadOnly}
        />
      )}
    </Container>
  );
}

export default SingleUpload;
