import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { lighten } from 'polished';

export const Container = styled.section`
  flex: 1;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
  height: 100%;
`;

export const Group = styled.div`
  margin-bottom: 20px;
  color: ${props => props.color};

  h1 {
    font-size: 1.1rem;
    color: ${props => props.color};
    margin-bottom: 20px;
    padding-left: 10px;
  }

  footer {
    text-align: right;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Tile = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  box-shadow: 4px 4px 5px 0.5px rgba(0, 0, 0, 0.03);
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: ${props => props.color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px;
  padding: 15px;
  width: 150px;
  height: 150px;
  transition: margin 0.2s, border 0.5s;

  @media (max-width: 500px) {
    width: 40vw;
    height: 150px;
    padding: 15px;
  }

  ${!isMobile &&
    css`
      &:hover {
        background-color: ${props => lighten(0.6, props.color)};
        box-shadow: 4px 4px 5px 0.5px rgba(0, 0, 0, 0.1);
        margin-top: 0px !important;
        margin: 0px 5px 10px 5px !important;
        cursor: pointer;
      }

      &:active {
        background-color: ${props => lighten(0.3, props.color)};
        color: #fff;
        border: none;
      }
    `}
`;

export const TileTitle = styled.div`
  flex: 1;
  word-wrap: break-word;

  @media (max-width: 500px) {
    font-size: 0.9rem;
  }
`;
