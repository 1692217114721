import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  > div {
    margin-top: 10px;
    text-align: center;
    color: #777;
  }
`;
