import styled, { css } from 'styled-components';

export const Menus = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #f7f7f7;
`;

export const ContextMenu = styled.div`
  /* position: fixed;
  z-index: 99999999; */
  overflow: auto;
  position: absolute;
  z-index: 9999999999999;
  min-width: 230px;

  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}

  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;

    ${top > window.innerHeight / 2 &&
      left > window.innerWidth / 2 &&
      css`
        transform: translate(-100%, -100%);
      `}

    ${top > window.innerHeight / 2 &&
      left < window.innerWidth / 2 &&
      css`
        transform: translateY(-100%);
      `}

    ${top < window.innerHeight / 2 &&
      left > window.innerWidth / 2 &&
      css`
        transform: translateX(-100%);
      `}
  `}
`;

export const Item = styled.div`
  padding: 10px 30px 10px 10px;
  margin-right: 2px;
  display: ${props => (props.visible ? 'flex' : 'none')};

  background-color: #f7f7f7;
  color: ${props => (props.disabled ? '#C0C0C0;' : '#666;')};

  flex-direction: row;
  align-items: center;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: #deeff6;
    cursor: pointer;
  }
`;

export const ItemIcon = styled.div`
  display: flex;
  min-width: 20px;
  margin-right: 10px;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: ${props => (props.changed ? 'bold' : 'normal')};
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin-left: 10px;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: normal;
    color: #a0a0a0;
  }
`;

export const MenuTitle = styled.div`
  padding: 10px;
  background-color: #496775;
  color: #f0f0f0;
`;

export const Separator = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 2px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-top: none;
  max-height: 325px !important;
  overflow: auto;
`;
