import styled from 'styled-components';

export const TextArea = styled.textarea`
  width: 100% !important;
  padding: 8px;
  /* border: 1px solid #ddd !important; */
  background-color: ${props => (props.readOnly ? '#fafafa' : '#fff')};
  color: #555;

  text-align: ${props =>
    props.inputType === 'inputNumber' ? 'right' : 'left'};

  @media (max-width: 768px) {
    font-size: 0.9rem;
    border: none !important;
    padding: 8px;
    border-radius: 0;
  }

  resize: none;
  font-size: 0.9rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PanelInput = styled.div`
  /* position: relative; */
  /* flex: 1; */
  border-radius: 2px;
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 2px;

    border: ${props =>
      props.hideBorder ? 'none' : `1px solid #ddd`} !important;

    svg {
      margin: 5px;
    }

    input,
    textarea {
      border: none !important;
    }

    &:focus-within {
      border: 1px solid #9cc3d4 !important;

      input,
      textarea {
        border: none !important;
      }
    }

    &.disabled {
      background-color: #f5f5f5;
    }

    @media (max-width: 500px) {
      background-color: transparent !important ;
      border: none !important;
    }
  }
`;

export const UpdateIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
  padding-left: 5px;
  border: none;
  background-color: #fff;

  &:hover {
    cursor: pointer;
  }
  /* background-color: transparent; */
`;
