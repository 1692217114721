/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import QueryService from '~/services/QueryService';
import FormParams from '../FormParams';

function ParamsPanel(
  {
    title,
    t,
    params = [],
    paramsData,
    paramsPanelWidth,
    visible,
    settings,
    onCancel = () => {},
    onConfirm,
  },
  ref
) {
  const FormParamsRef = useRef();

  const [formData, setFormData] = useState(paramsData || {});
  const [errors, setErrors] = useState({});

  function isDataExists(data) {
    return Object.keys(data).length > 0;
  }

  async function getDefaultData(data = {}, parameters) {
    let defaultData = data;

    if (!isDataExists(data)) {
      for (let cont = 0; cont < parameters.length; cont++) {
        const param = parameters[cont];
        if (param.sqlDefault) {
          // eslint-disable-next-line no-await-in-loop
          const response = await QueryService.execute(1, param.sqlDefault);
          if (response.length > 0) {
            const dataResponse = response[0];
            defaultData = {
              ...defaultData,
              ...dataResponse,
            };
          }
        } else {
          defaultData[param.name] = param.defaultValue || param.value || null;
        }
      }
    }

    const multipleChoiceParams = parameters.filter(param => {
      const type = param.type || '';
      return type.toUpperCase() === 'MULTIPLECHOICE';
    });

    if (multipleChoiceParams.length) {
      multipleChoiceParams.forEach(param => {
        const paramData = param.data || [];

        const isSelected = option => {
          const defaultDataOfParam = param.default || [];
          return defaultDataOfParam.find(opt => opt === option.value)
            ? 'Y'
            : 'N';
        };

        paramData.forEach(option => {
          defaultData[
            `_multipleChoice-${param.name}-${option.value}`
          ] = isSelected(option);
        });
      });
    }

    return defaultData;
  }

  useImperativeHandle(ref, () => {
    return {
      setData: async ({ data }) => {
        const defaultData = await getDefaultData(data, params);

        setFormData(defaultData);

        setErrors({});
      },
      getData: () => {
        return FormParamsRef.current.getData();
      },
      validate: async () => {
        FormParamsRef.current.validate();
      },
    };
  });

  useEffect(() => {
    setFormData(paramsData);
  }, [paramsData]);

  return (
    visible &&
    params.length > 0 && (
      <FormParams
        ref={FormParamsRef}
        data={formData}
        title={title}
        params={params}
        paramsPanelWidth={paramsPanelWidth}
        settings={settings}
        onCancel={onCancel}
        setIsShow={() => {}}
        onConfirm={onConfirm}
        t={t}
        errors={errors}
      />
    )
  );
}

export default forwardRef(ParamsPanel);
