import React from 'react';
import PropTypes from 'prop-types';

import HtmlParser from 'react-html-parser';
import { renderToString } from 'react-dom/server';

import { Container } from './styles';
import Icon from '~/easy-components/Icon';
import { replacePlaceholdersText } from '~/easy-components/Helpers/replacePlaceholdersText';

function CustomHtml({ htmlText, htmlData, iconsContent }) {
  function renderIcons(iconsData) {
    let renderedData = {};

    if (iconsData) {
      iconsData.forEach(icon => {
        const strIcon = renderToString(
          <Icon
            key={`${icon.name}-${icon.color}`}
            name={icon.name}
            size={icon.size}
            color={icon.color}
          />
        );

        renderedData = {
          ...renderedData,
          [icon.name]: strIcon,
        };
      });
    }

    return renderedData;
  }

  let iconsData = {};

  if (iconsContent) {
    iconsData = renderIcons(iconsContent);
  }

  const htmlContent = replacePlaceholdersText({
    text: htmlText,
    placeholderTemplate: '{?}',
    data: {
      ...htmlData,
      ...iconsData,
    },
  });

  const parsedHtml = HtmlParser(htmlContent);

  return <Container>{parsedHtml}</Container>;
}

export default CustomHtml;

CustomHtml.propTypes = {
  htmlText: PropTypes.string,
  htmlData: PropTypes.objectOf(PropTypes.element),
  iconsContent: PropTypes.arrayOf(PropTypes.element),
};

CustomHtml.defaultProps = {
  htmlText: '',
  htmlData: {},
  iconsContent: [],
};
