/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
import { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setViewMode,
  updateSettings as updateSettingsAction,
} from '~/store/modules/uiDesign/actions';
import useLocale from '~/hooks/useLocale';
import useHelpers from '~/hooks/useHelpers';
import applicationSettingsService from '~/services/ApplicationSettingsService';
import ConfirmDialog from '~/easy-components/ConfirmDialog';
import { showLog } from '~/store/modules/log/actions';
import createGlobalFunctions from '~/easy-components/Helpers/createGlobalFunctions';
import useCompany from '../useCompany';
import useStateCache from '../useStateCache';
import contextMenus from './ContextMenus';
import HasEventComponent from './HasEvent';
import userService from '../../applications/CRM/services/UserService';
import reportQueriesService from '../../applications/CRM/services/ReportQueriesService';

export const normalizeName = name => {
  // Remove caracteres especiais
  const text = name.replace(/[^\w\s]/gi, '');

  // Substitui espaços por underline
  const newText = text.replace(/\s+/g, '_');

  return newText;
};

export const clearFormData = formData => {
  Object.keys(formData).forEach(key => {
    if (formData[key] === null) {
      delete formData[key];
    }
  });

  return formData;
};

function useUiDesigner({
  pageId,
  enableDesignMode = true,
  componentType,
  baseName = null,
  name,
  valueField,
  title,
  contextProps = {},
  isUserField = false,
  customTabName = null,
  auxContextEvents = [],
}) {
  const t = useLocale('_Global');

  const dispatch = useDispatch();

  const rawSettingsRef = useRef({});

  const fullName = baseName ? `${baseName}.${name}` : name;
  const fullValueField = valueField
    ? baseName
      ? `${baseName}.${valueField}`
      : valueField
    : null;

  const viewMode = useSelector(({ uiDesign }) => {
    if (pageId) {
      const ret = uiDesign.find(
        p => p.pageId.toUpperCase() === (pageId || '').toUpperCase()
      );

      return (ret ? ret.viewMode : 'normal') || 'normal';
    }

    return 'normal';
  });

  const settings = useSelector(({ uiDesign }) => {
    if (pageId) {
      const ret = uiDesign.find(
        p => p.pageId.toUpperCase() === (pageId || '').toUpperCase()
      );

      return ret ? ret.settings : null;
    }
    return null;
  });

  createGlobalFunctions(settings);

  const [globalSettings, setGlobalSettings] = useStateCache({});

  const mainTitle = title || fullName;

  const defaultSettings = useHelpers();

  useEffect(() => {
    if (settings) {
      if (settings && settings._rawSettings) {
        rawSettingsRef.current = JSON.parse(
          JSON.stringify(settings._rawSettings)
        );
      }

      setGlobalSettings({ ...defaultSettings, ...settings } || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const { process, dynamicFunctionProps } = defaultSettings;

  const {
    showContextMenu: showContextMenuGlobal,
    showModalQuery,
    showModalUserfield,
    showModalApplication,
    showModalComponent,
    showModalFields,
    showModalList,
    showModalSql,
    // showModalEditor,
    logRef,
  } = dynamicFunctionProps || {};

  const { companySettings } = useCompany();

  const setViewModeHandler = useCallback(
    viewModeProp => {
      dispatch(showLog(true));

      dispatch(
        setViewMode({
          pageId,
          viewMode: viewModeProp,
        })
      );
    },
    [dispatch, pageId]
  );

  const extractParamsFromURL = url => {
    const queryString = url.split('?')[1];
    const params = {};

    if (queryString) {
      const keyValuePairs = queryString.split('&');
      keyValuePairs.forEach(keyValuePair => {
        const [key, value] = keyValuePair.split('=');
        params[key] = value;
      });
    }

    return JSON.stringify(params) === '{}' ? null : params;
  };

  const getMyRote = () => {
    const params = extractParamsFromURL(pageId);
    const route = params ? `${params.from}/${params.hash}` : pageId;
    return route;
  };

  const getDesignMenus = useCallback(() => {
    const designMenus = [
      {
        id: 'designMode',
        visible: enableDesignMode,
        disabled:
          !enableDesignMode || !!rawSettingsRef.current._errorRawSettings,
        text: t('DesignMode'),
        icon: 'MdEdit',
        handler: async () => {
          setViewModeHandler('design');
        },
      },
      {
        id: 'designApplication',
        visible: enableDesignMode,
        disabled: false,
        text: t('ApplicationOpen'),
        icon: 'FiSquare',
        handler: async () => {
          const route = getMyRote();
          showModalApplication({
            isModal: true,
            initialData: route,
          });
        },
      },
    ];

    if (companySettings.accessType === 1) {
      return designMenus.filter(
        menu =>
          menu.visible || menu.visible === undefined || menu.disabled === null
      );
    }

    return [];
  }, [
    companySettings.accessType,
    enableDesignMode,
    pageId,
    setViewModeHandler,
    showModalApplication,
    t,
  ]);

  const findFieldByName = (components, fieldName) => {
    if (Array.isArray(components) && fieldName) {
      for (let i = 0; i < components.length; i++) {
        const component = components[i];

        if (component.columns) {
          for (let j = 0; j < component.columns.length; j++) {
            const column = component.columns[j];

            for (let k = 0; k < column.fields.length; k++) {
              const field = column.fields[k];

              if (field.name === fieldName) {
                return field;
              }
            }
          }
        }
      }
    }

    return {};
  };

  const getField = useCallback(
    (isRaw = false) => {
      const originSettings = isRaw ? rawSettingsRef.current : globalSettings;

      if (fullName) {
        let customField = null;
        const elements = originSettings.fields || [];
        const baseField = elements.find(f => f.name === fullName);

        if (customTabName) {
          customField = findFieldByName(originSettings.components, fullName);
        }

        return {
          ...baseField,
          ...customField,
        };
      }

      return originSettings;
    },
    [customTabName, fullName, globalSettings]
  );

  const getRawSettingsField = useCallback(
    (isMain = false) => {
      if (fullName && !isMain) {
        const rawSettingsField = getField(true);

        const newField = {
          name: fullName,
        };

        return rawSettingsField || newField;
      }

      return rawSettingsRef.current;
    },
    [fullName, getField]
  );

  const updateSettings = useCallback(
    async (newSettings, isMain) => {
      await process(async () => {
        const selfFieldSettings = await applicationSettingsService.updateComponentSettings(
          {
            route: getMyRote(),
            componentType: isMain ? 'page' : componentType,
            settings: newSettings,
            componentName: customTabName,
            fieldNameRef: fullName,
          }
        );

        rawSettingsRef.current = selfFieldSettings._rawSettings;

        dispatch(
          updateSettingsAction({
            pageId,
            settings: { ...selfFieldSettings, __updatedSettings: new Date() },
          })
        );
      });
    },
    [componentType, customTabName, dispatch, fullName, pageId, process]
  );

  const setFieldProp = useCallback(
    ({ prop, value }) => {
      if (customTabName) {
        const rawSettingsField = rawSettingsRef.current;

        if (rawSettingsField.components) {
          for (let i = 0; i < rawSettingsField.components.length; i++) {
            const component = rawSettingsField.components[i];

            if (component.columns) {
              for (let j = 0; j < component.columns.length; j++) {
                const column = component.columns[j];

                for (let k = 0; k < column.fields.length; k++) {
                  const field = column.fields[k];

                  if (field.name === fullName) {
                    if (value === null || value === undefined || value === '') {
                      delete field[prop];
                    } else {
                      field[prop] = value;
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        if (!rawSettingsRef.current.fields) {
          rawSettingsRef.current.fields = [];
        }

        const idx = rawSettingsRef.current.fields.findIndex(
          field => field.name === fullName
        );

        if (idx >= 0) {
          if (value === null || value === undefined || value === '') {
            delete rawSettingsRef.current.fields[idx][prop];
          } else {
            rawSettingsRef.current.fields[idx][prop] = value;
          }
        } else if (value !== null && value !== undefined && value !== '') {
          rawSettingsRef.current.fields.push({
            name: fullName,
            [prop]: value,
          });
        }
      }
    },
    [customTabName, fullName]
  );

  const treatFieldProp = useCallback(
    async ({ menu, value }) => {
      setFieldProp({ prop: menu.prop, value });

      const rawSettingsField = getRawSettingsField(true);

      await updateSettings(rawSettingsField, true);
    },
    [getRawSettingsField, setFieldProp, updateSettings]
  );

  const deleteEvent = useCallback(
    ({ eventName, run }) => {
      const rawSettingsField = rawSettingsRef.current;

      if (rawSettingsField.fields) {
        const field = rawSettingsField.fields.find(f => f.name === fullName);

        if (field) {
          const idx = field.events.findIndex(
            e => e.name === eventName && run === e.run
          );

          if (idx >= 0) {
            field.events.splice(idx, 1);
          }
        }
      }
    },
    [fullName]
  );

  const updateEvent = useCallback(
    ({ eventName, run, handler }) => {
      const rawSettingsField = rawSettingsRef.current;

      if (fullName) {
        if (!rawSettingsField.fields) {
          rawSettingsField.fields = [];
        }

        const field = rawSettingsField.fields.find(f => f.name === fullName);

        if (!field) {
          rawSettingsField.fields.push({
            name: fullName,
            events: [
              {
                name: eventName,
                run,
                handler,
              },
            ],
          });
        } else {
          if (!field.events) {
            field.events = [];
          }

          const idx = field.events.findIndex(
            e => e.name === eventName && run === e.run
          );

          if (idx >= 0) {
            field.events[idx].handler = handler;
          } else {
            field.events.push({
              name: eventName,
              run,
              handler,
            });
          }
        }
      } else {
        if (!rawSettingsField.events) {
          rawSettingsField.events = [];
        }

        const idx = rawSettingsField.events.findIndex(
          e => e.name === eventName && run === e.run
        );

        if (idx >= 0) {
          rawSettingsField.events[idx].handler = handler;
        } else {
          rawSettingsField.events.push({
            name: eventName,
            run,
            handler,
          });
        }
      }
    },
    [fullName]
  );

  const getRunType = useCallback((events, menu) => {
    if (events) {
      if (events.length === 1) {
        return events[0].run;
      }

      return menu.defaultRunType || 'after';
    }

    return menu.defaultRunType || 'after';
  }, []);

  const treatEvent = useCallback(
    ({ menu }) => {
      let field = null;

      let fieldEvents = [];

      if (fullName) {
        const rawSettings = rawSettingsRef.current;

        if (rawSettings && rawSettings.fields) {
          field = rawSettings.fields.find(f => f.name === fullName);
        }

        if (field) {
          fieldEvents = field.events || [];
        }
      } else {
        fieldEvents = rawSettingsRef.current.events || [];
      }

      const events = fieldEvents.filter(
        evt => evt.name.toUpperCase() === menu.prop.toUpperCase()
      );

      const runType = getRunType(events, menu);

      logRef.current.addEvent({
        settings: rawSettingsRef.current,
        events,
        menu,
        runType,
        title: mainTitle,
        onConfirmGlobalFunctions: async globalFunctions => {
          await process(async () => {
            const rawSettingsField = getRawSettingsField(true);

            rawSettingsField.globalFunctions = globalFunctions;

            await updateSettings(rawSettingsField, true);
          });
        },
        onConfirm: async ({ newEvents, globalFunctions }) => {
          await process(async () => {
            // eslint-disable-next-line no-restricted-syntax
            for (const event of newEvents) {
              if (
                event.handler === undefined ||
                event.handler === null ||
                event.handler.trim() === ''
              ) {
                deleteEvent({
                  eventName: menu.prop,
                  run: event.run,
                });
              } else {
                updateEvent({
                  eventName: menu.prop,
                  run: event.run,
                  handler: event.handler,
                });
              }
            }

            const rawSettingsField = getRawSettingsField(true);

            rawSettingsField.globalFunctions = globalFunctions;

            await updateSettings(rawSettingsField, true);
          });
        },
      });
    },
    [
      deleteEvent,
      fullName,
      getRawSettingsField,
      getRunType,
      mainTitle,
      process,
      updateEvent,
      updateSettings,
    ]
  );

  const treatQuery = useCallback(
    ({ menu }) => {
      showModalQuery({
        // isModal: true,
        isListOpen: false,
        initialData: menu.prop,
      });
    },
    [showModalQuery]
  );

  const checkIfThereIsChange = useCallback(
    ({ menu, field }) => {
      if (!field) {
        return { changed: false };
      }

      switch (menu.type) {
        case 'event':
          let rawField = {};

          if (fullName) {
            rawField =
              (rawSettingsRef.current.fields
                ? rawSettingsRef.current.fields.find(
                    f => f.name.toUpperCase() === fullName.toUpperCase()
                  )
                : {}) || {};
          } else {
            rawField = rawSettingsRef.current;
          }

          const evts = (rawField.events || []).filter(
            evt => evt.name.toUpperCase() === menu.prop.toUpperCase()
          );

          let auxInfo = '';

          if (evts.length === 1) {
            auxInfo = t(evts[0].run || 'before');
          }

          return { changed: evts.length > 0, auxInfo };

        case 'boolean':
        case 'code':
        case 'text':
          const prop = field[menu.prop];
          if (prop !== undefined && prop !== null) {
            return { changed: JSON.stringify(prop) !== '{}' };
          }

          return { changed: false };

        default:
          return { changed: false };
      }
    },
    [fullName, t]
  );

  const getColumnFieldIndex = useCallback(fieldName => {
    const rawSettingsField = rawSettingsRef.current;

    if (rawSettingsField.components) {
      for (let i = 0; i < rawSettingsField.components.length; i++) {
        const component = rawSettingsField.components[i];

        if (component.columns) {
          for (let j = 0; j < component.columns.length; j++) {
            const column = component.columns[j];

            for (let k = 0; k < column.fields.length; k++) {
              const field = column.fields[k];

              if (field.name === fieldName) {
                return { columnIndex: j, fieldIndex: k };
              }
            }
          }
        }
      }
    }

    return { columnIndex: -1, fieldIndex: -1 };
  }, []);

  const createComponent = useCallback(({ data }) => {
    const rawSettingsField = rawSettingsRef.current;

    if (!rawSettingsField.components) {
      rawSettingsField.components = [];
    }

    rawSettingsField.components.push(data);
  }, []);

  const createUserField = useCallback(
    ({ data, menu }) => {
      if (customTabName) {
        const rawSettingsField = rawSettingsRef.current;

        if (!rawSettingsField.components) {
          rawSettingsField.components = [];
        }

        const component = rawSettingsField.components.find(
          c => c.name === customTabName
        );

        const field = {
          ...data,
          componentType: 'field',
        };

        delete field.target;

        if (!component) {
          rawSettingsField.components.push({
            name: customTabName,
            columns: [field],
          });
        } else {
          const { columnIndex, fieldIndex } = getColumnFieldIndex(fullName);

          const colIdx =
            menu.columnIndex !== null && menu.columnIndex !== undefined
              ? menu.columnIndex
              : columnIndex;

          if (fieldIndex >= 0) {
            component.columns[colIdx].fields.splice(fieldIndex + 1, 0, field);
          } else {
            component.columns[colIdx].fields.push(field);
          }
        }
      } else {
        if (!rawSettingsRef.current.userFields) {
          rawSettingsRef.current.userFields = [];
        }

        rawSettingsRef.current.userFields.push(data);
      }
    },
    [customTabName, fullName, getColumnFieldIndex]
  );

  const treatAddComponent = useCallback(
    ({ menu }) => {
      showModalComponent({
        title: t('AddComponent'),
        menu,
        target: fullName,
        onConfirm: async data => {
          await process(async () => {
            createComponent({ data, menu });
            const rawSettingsField = getRawSettingsField(true);
            await updateSettings(rawSettingsField, true);
          });
        },
      });
    },
    [
      createComponent,
      fullName,
      getRawSettingsField,
      process,
      showModalComponent,
      t,
      updateSettings,
    ]
  );

  const treatNewField = useCallback(
    ({ menu }) => {
      showModalUserfield({
        title: t('NewField'),
        menu,
        target: fullName,
        onConfirm: async data => {
          await process(async () => {
            createUserField({ data, menu });
            const rawSettingsField = getRawSettingsField(true);
            await updateSettings(rawSettingsField, true);
          });
        },
      });
    },
    [
      createUserField,
      fullName,
      getRawSettingsField,
      process,
      showModalUserfield,
      t,
      updateSettings,
    ]
  );

  const treatDeleteElement = useCallback(
    async ({ menu }) => {
      ConfirmDialog({
        title: t('DeleteQuestion'),
        message: t('message.DeleteMessageQuestion'),
        buttons: [
          {
            label: t('Yes'),
            onClick: async () => {
              const elementName = fullValueField || fullName;

              const rawSettingsField = rawSettingsRef.current || {};

              await process(async () => {
                if (customTabName) {
                  if (rawSettingsField.components) {
                    for (
                      let i = 0;
                      i < rawSettingsField.components.length;
                      i++
                    ) {
                      const component = rawSettingsField.components[i];

                      if (component.columns) {
                        for (let j = 0; j < component.columns.length; j++) {
                          const column = component.columns[j];

                          for (let k = 0; k < column.fields.length; k++) {
                            const field = column.fields[k];

                            if (field.name === elementName) {
                              column.fields.splice(k, 1);
                            }
                          }
                        }
                      }
                    }
                  }
                  await updateSettings(rawSettingsField, true);
                } else {
                  if (rawSettingsField.fields) {
                    const fieldIdx = rawSettingsField.fields.findIndex(
                      field => field.name === fullName
                    );

                    if (fieldIdx >= 0) {
                      rawSettingsField.fields.splice(fieldIdx, 1);
                    }
                  }

                  if (rawSettingsField[menu.prop]) {
                    const userFieldIdx = rawSettingsField[menu.prop].findIndex(
                      field => {
                        const targetSpplited = field.target.split('.');

                        let newFullName = field.name;

                        if (targetSpplited.length > 1) {
                          newFullName = `${targetSpplited[0]}.${field.name}`;
                        }

                        return newFullName === elementName;
                      }
                    );

                    if (userFieldIdx >= 0) {
                      rawSettingsField[menu.prop].splice(userFieldIdx, 1);
                    }
                  }

                  await updateSettings(rawSettingsField, true);
                }
              });
            },
          },
          {
            label: t('No'),
          },
        ],
      });
    },
    [customTabName, fullName, getRawSettingsField, process, t, updateSettings]
  );

  const treatDeleteTab = useCallback(
    async ({ menu }) => {
      ConfirmDialog({
        title: t('DeleteQuestion'),
        message: t('message.DeleteMessageQuestion'),
        buttons: [
          {
            label: t('Yes'),
            onClick: async () => {
              await process(async () => {
                const rawSettingsField = rawSettingsRef.current;

                if (rawSettingsField.components) {
                  const idx = rawSettingsField.components.findIndex(
                    c => c.name === menu.tabName
                  );

                  rawSettingsField.components[idx].columns.forEach(column => {
                    column.fields.forEach(field => {
                      const fieldIdx = rawSettingsField.fields.findIndex(
                        f => f.name === field.name
                      );

                      if (fieldIdx >= 0) {
                        rawSettingsField.fields.splice(fieldIdx, 1);
                      }

                      const userFieldIdx = rawSettingsField.userFields.findIndex(
                        f => f.name === field.name
                      );

                      if (userFieldIdx >= 0) {
                        rawSettingsField.userFields.splice(userFieldIdx, 1);
                      }
                    });
                  });

                  rawSettingsField.components.splice(idx, 1);
                }

                await updateSettings(rawSettingsField, true);
              });
            },
          },
          {
            label: t('No'),
          },
        ],
      });
    },
    [process, t, updateSettings]
  );

  const extractBooleanProperties = objectData => {
    const booleanProperties = [];

    for (const key in objectData) {
      if (typeof objectData[key] === 'boolean') {
        booleanProperties.push({
          label: key,
          value: `<${key}>`,
          auxInfo: `${t('value')}: ${objectData[key] ? 'true' : 'false'}`,
        });
      }
    }

    return booleanProperties;
  };

  const createMenus = useCallback(
    menus => {
      const field = getField();
      const rawField = getField(true);

      /* menus = menus.sort((a, b) => {
        const typeOrder = { query: 1, boolean: 2, event: 3 };
        return (typeOrder[a.type] || 100) <= (typeOrder[b.type] || 100);
      }); */

      const newMenus = menus.map(menu => {
        // eslint-disable-next-line prefer-const
        let { changed, auxInfo } = checkIfThereIsChange({ menu, field });

        let text = menu.title;
        let { icon } = menu;
        let { disabled } = menu;
        let value = null;

        switch (menu.type) {
          case 'boolean':
            const self = field;

            changed = false;

            let propValue = menu.defaultValue;

            if (self) {
              propValue = self[menu.prop];

              if (
                propValue === undefined ||
                propValue === null ||
                typeof propValue === 'string'
              ) {
                propValue = menu.defaultValue;
                changed = false;
              } else {
                changed = true;
              }
            }

            const option = menu.options.find(opt => opt.value !== propValue);

            text = option.title;
            disabled = option.disabled;
            value = option.value;
            icon = option.icon;
            break;

          case 'custom':
            changed = false;

            if (rawField) {
              const pValue = rawField[menu.prop];

              if (typeof pValue === 'string') {
                changed = true;
                auxInfo = pValue.replace(/</g, '').replace(/>/g, '');
              }
            }

            break;

          case 'query':
          case 'queryEditor':
            icon = icon || 'BiSearch';
            menu.iconColor = menu.iconColor || '#70afe2';
            break;

          case 'event':
            icon = icon || 'VscSymbolEvent';
            menu.iconColor = menu.iconColor || '#cece36';
            break;

          default:
            break;
        }

        return {
          text: t(text),
          auxInfo,
          changed,
          ...menu,
          disabled,
          icon,
          handler: async () => {
            switch (menu.type) {
              case 'event':
                treatEvent({ menu, value });
                break;

              case 'boolean':
                treatFieldProp({ menu, value });
                break;

              case 'query':
                treatQuery({ menu });
                break;

              case 'queryEditor':
                let v = '';
                if (menu.fieldName) {
                  if (rawSettingsRef.current[menu.prop]) {
                    const aux = rawSettingsRef.current[menu.prop].find(
                      q => q.prop === menu.fieldName
                    );

                    if (aux) {
                      v = aux.query;
                    }
                  }
                } else {
                  v = rawSettingsRef.current[menu.prop] || '';
                }

                showModalSql({
                  code: v,
                  defaultCode: null,
                  onSave: async ({ code }) => {
                    if (menu.fieldName) {
                      if (!rawSettingsRef.current[menu.prop]) {
                        rawSettingsRef.current[menu.prop] = [];
                      }

                      const queryAuxIndex = rawSettingsRef.current[
                        menu.prop
                      ].findIndex(q => q.prop === menu.fieldName);

                      if (queryAuxIndex < 0) {
                        rawSettingsRef.current[menu.prop].push({
                          prop: menu.fieldName,
                          query: code,
                        });
                      } else {
                        rawSettingsRef.current[menu.prop][
                          queryAuxIndex
                        ].query = code;
                      }
                    } else {
                      rawSettingsRef.current[menu.prop] = code || '';
                    }

                    const rawSettingsField = getRawSettingsField(true);

                    await updateSettings(rawSettingsField, true);
                  },
                });
                break;

              case 'newField':
                treatNewField({ menu });
                break;

              case 'deleteElement':
                treatDeleteElement({ menu });
                break;

              case 'deleteTab':
                treatDeleteTab({ menu });
                break;

              case 'addComponent':
                treatAddComponent({ menu });
                break;

              case 'custom':
                let userSettings = [];

                await process(async () => {
                  userSettings = await userService.getSettings();
                });

                const fixedData = extractBooleanProperties(userSettings);

                showModalFields({
                  title: t(menu.title),
                  fields: [
                    {
                      name: 'value',
                      label: t('Setting'),
                      type: 'fixedData',
                      data: fixedData,
                    },
                  ],
                  data: {
                    value:
                      typeof rawField[menu.prop] === 'boolean'
                        ? null
                        : rawField[menu.prop],
                  },
                  onConfirm: async data => {
                    treatFieldProp({
                      menu,
                      value: data.value,
                    });
                  },
                });
                break;

              case 'array':
                showModalFields({
                  title: t('NewElement'),
                  data: {
                    type: 'Default',
                  },
                  fields: [
                    {
                      name: 'type',
                      label: t('Type'),
                      type: 'fixedData',
                      fixedData: [
                        {
                          label: t('DefaultButton'),
                          value: 'Default',
                        },
                        {
                          label: t('AcceptButton'),
                          value: 'Accept',
                        },
                        {
                          label: t('RejectButton'),
                          value: 'Reject',
                        },
                        {
                          label: t('Custom'),
                          value: 'Custom',
                        },
                        {
                          label: t('Icon'),
                          value: 'icon',
                        },
                      ],
                      onChange: ({ data, form }) => {
                        form.getFieldRef('iconName').setVisible(false);
                        form.getFieldRef('size').setVisible(false);
                        form.getFieldRef('color').setVisible(false);

                        if (data) {
                          switch (data.value) {
                            case 'Custom':
                              form.getFieldRef('color').setVisible(true);
                              break;

                            case 'icon':
                              form.getFieldRef('iconName').setVisible(true);
                              form.getFieldRef('size').setVisible(true);
                              break;

                            default:
                              break;
                          }
                        }
                      },
                    },
                    {
                      name: 'name',
                      label: t('Name'),
                      type: 'text',
                    },
                    {
                      name: 'text',
                      label: t('Text'),
                      type: 'text',
                    },
                    {
                      name: 'iconName',
                      label: t('IconName'),
                      type: 'inputIcon',
                    },
                    {
                      name: 'size',
                      label: t('Size'),
                      type: 'number',
                    },
                    {
                      name: 'color',
                      label: t('Color'),
                      type: 'text',
                    },
                  ],
                  onOpen: ({ form }) => {
                    form.getFieldRef('iconName').setVisible(false);
                    form.getFieldRef('size').setVisible(false);
                    form.getFieldRef('color').setVisible(false);
                  },
                  onConfirm: async newElement => {
                    if (!rawSettingsRef.current[menu.prop]) {
                      rawSettingsRef.current[menu.prop] = [];
                    }

                    newElement = clearFormData(newElement);

                    newElement.name = `btn${normalizeName(newElement.name)}`;

                    rawSettingsRef.current[menu.prop].push(newElement);

                    const rawSettingsField = getRawSettingsField(true);

                    await updateSettings(rawSettingsField, true);
                  },
                });
                break;

              case 'list':
                const rawSettings = rawSettingsRef.current;

                if (!rawSettings[menu.prop]) {
                  rawSettings[menu.prop] = [];
                }

                const list = rawSettings[menu.prop].map(item => {
                  if (menu.propertiesList) {
                    const newItem = {};
                    menu.propertiesList.forEach(prop => {
                      for (let propName in prop) {
                        newItem[propName] = item[prop[propName]];
                      }
                    });
                    return {
                      ...item,
                      ...newItem,
                      _backgroundColor: item.default ? '#deeff6' : null,
                    };
                  }

                  return item;
                });

                showModalList({
                  title: t(menu.title),
                  list,
                  menus: [
                    {
                      id: 'setDefault',
                      text: t('SetDefault'),
                      visible: menu.prop === 'printLayouts',
                      handler: async (props, auxData) => {
                        ConfirmDialog({
                          title: t('SetDefault'),
                          message: t('message.SetDefaultQuestion'),
                          buttons: [
                            {
                              label: t('Yes'),
                              onClick: async () => {
                                const elementIndex = rawSettingsRef.current[
                                  menu.prop
                                ].findIndex(
                                  el => el.reportCode === auxData.auxInfo
                                );

                                for (
                                  let i = 0;
                                  i < rawSettingsRef.current[menu.prop].length;
                                  i++
                                ) {
                                  rawSettingsRef.current[menu.prop][i].default =
                                    i === elementIndex;
                                }

                                const rawSettingsField = getRawSettingsField(
                                  true
                                );

                                await updateSettings(rawSettingsField, true);

                                auxData.closeModal();
                              },
                            },
                            {
                              label: t('No'),
                            },
                          ],
                        });
                      },
                    },
                    {
                      id: 'delete',
                      text: t('Delete'),
                      handler: async (props, auxData) => {
                        ConfirmDialog({
                          title: t('DeleteQuestion'),
                          message: t('message.DeleteMessageQuestion'),
                          buttons: [
                            {
                              label: t('Yes'),
                              onClick: async () => {
                                const compareField =
                                  menu.prop === 'printLayouts'
                                    ? 'reportCode'
                                    : 'name';

                                const elementIndex = rawSettingsRef.current[
                                  menu.prop
                                ].findIndex(
                                  el => el[compareField] === auxData.auxInfo
                                );

                                if (elementIndex >= 0) {
                                  rawSettingsRef.current[menu.prop].splice(
                                    elementIndex,
                                    1
                                  );

                                  const rawSettingsField = getRawSettingsField(
                                    true
                                  );

                                  await updateSettings(rawSettingsField, true);

                                  auxData.closeModal();
                                }
                              },
                            },
                            {
                              label: t('No'),
                            },
                          ],
                        });
                      },
                    },
                  ],
                  onNew: async ({ modal }) => {
                    switch (menu.prop) {
                      case 'printLayouts':
                        showModalFields({
                          title: t('NewElement'),
                          data: {
                            parameters: `[
                              {
                                  "paramFieldName": "DocKey@",
                                  "screenFieldName": "DocEntry"
                              },
                              {
                                  "paramFieldName": "ObjectId@",
                                  "value": "23"
                              }
                          ]`,
                          },
                          fields: [
                            {
                              name: 'reportCode',
                              label: t('ReportCode'),
                              type: 'queryfield',
                              method: async filter => {
                                const reports = await reportQueriesService.listPrintLayouts(
                                  filter
                                );
                                return reports;
                              },
                              onChange: ({ data, form }) => {
                                if (data) {
                                  form.setFieldValue('reportName', data.label);
                                  form.setFieldValue(
                                    'description',
                                    data.auxInfo
                                  );
                                } else {
                                  form.clearField('reportName');
                                  form.clearField('description');
                                }
                              },
                            },
                            {
                              name: 'reportName',
                              label: t('ReportName'),
                              type: 'text',
                            },
                            {
                              name: 'description',
                              label: t('Description'),
                              type: 'text',
                            },
                            {
                              name: 'parameters',
                              label: t('Parameters'),
                              type: 'text',
                              fullScreen: true,
                            },
                          ],
                          onConfirm: async newPrintLayout => {
                            if (!rawSettingsRef.current[menu.prop]) {
                              rawSettingsRef.current[menu.prop] = [];
                            }

                            rawSettingsRef.current[menu.prop].push(
                              newPrintLayout
                            );

                            const rawSettingsField = getRawSettingsField(true);

                            await updateSettings(rawSettingsField, true);

                            modal.handleClose();
                          },
                        });

                        break;

                      case 'auxFilters':
                        showModalUserfield({
                          title: t('NewField'),
                          menu,
                          target: fullName,
                          onConfirm: async data => {
                            await process(async () => {
                              if (!rawSettingsRef.current[menu.prop]) {
                                rawSettingsRef.current[menu.prop] = [];
                              }

                              rawSettingsRef.current[menu.prop].push(data);

                              const rawSettingsField = getRawSettingsField(
                                true
                              );

                              await updateSettings(rawSettingsField, true);

                              modal.handleClose();
                            });
                          },
                        });
                        break;

                      default:
                        break;
                    }
                  },
                });

                break;

              case 'handler':
                if (menu.handler) {
                  await menu.handler({
                    t,
                    fullName,
                    fullValueField,
                    menu,
                  });
                }
                break;

              case 'code':
                let handlerCode = rawField[menu.prop];

                if (typeof handlerCode === 'object') {
                  handlerCode = `return ${JSON.stringify(
                    handlerCode,
                    null,
                    2
                  )};`;
                }

                logRef.current.addEvent({
                  settings: rawSettingsRef.current,
                  events: null,
                  code: handlerCode || '',
                  menu,
                  runType: null,
                  title: mainTitle,
                  onConfirmGlobalFunctions: async globalFunctions => {
                    await process(async () => {
                      const rawSettingsField = getRawSettingsField(true);

                      rawSettingsField.globalFunctions = globalFunctions;

                      await updateSettings(rawSettingsField, true);
                    });
                  },
                  onConfirm: async ({ code }) => {
                    await process(async () => {
                      // eslint-disable-next-line no-restricted-syntax
                      if (
                        code === null ||
                        code === undefined ||
                        code.trim() === ''
                      ) {
                        delete rawSettingsRef.current[menu.prop];
                      } else {
                        rawField[menu.prop] = code;
                      }

                      const rawSettingsField = getRawSettingsField(true);

                      await updateSettings(rawSettingsField, true);
                    });
                  },
                });

                /* showModalEditor({
                  title: t(menu.title),
                  menu,
                  code: handlerCode,
                  language: 'javascript',
                  // isShowMinimalize: true,
                  onConfirm: async ({ code }) => {
                    if (
                      code === null ||
                      code === undefined ||
                      code.trim() === ''
                    ) {
                      delete rawSettingsRef.current[menu.prop];
                    } else {
                      rawField[menu.prop] = code;
                    }

                    const rawSettingsField = getRawSettingsField(true);

                    await updateSettings(rawSettingsField, true);
                  },
                }); */
                break;

              case 'text':
                showModalFields({
                  title: `${t(menu.title)} | ${fullName}`,
                  fields: [
                    {
                      name: 'value',
                      label: t('Label'),
                      type: 'text',
                    },
                  ],
                  data: {
                    value: rawField[menu.prop] || '',
                  },
                  onConfirm: async data => {
                    treatFieldProp({
                      menu,
                      value: data.value,
                    });
                  },
                });
                break;

              default:
                break;
            }
          },
        };
      });

      return newMenus;
    },
    [
      getField,
      checkIfThereIsChange,
      t,
      treatEvent,
      treatFieldProp,
      treatQuery,
      treatNewField,
      treatDeleteElement,
      treatDeleteTab,
      treatAddComponent,
      process,
      extractBooleanProperties,
      showModalFields,
    ]
  );

  const getSelfFielf = useCallback(() => {
    const selfField = getField();

    return selfField || {};
  }, [getField]);

  const getContextMenus = useCallback(
    props => {
      const menusRaw =
        contextMenus[componentType]({
          ...contextProps,
          ...props,
          isUserField: isUserField || !!customTabName,
        }) || [];

      const menus = createMenus([...menusRaw, ...auxContextEvents]);

      return menus;
    },
    [
      auxContextEvents,
      componentType,
      contextProps,
      createMenus,
      customTabName,
      isUserField,
    ]
  );

  const showContextMenu = useCallback(
    ({ event, ...props }) => {
      if (viewMode === 'design') {
        event.preventDefault();
        event.stopPropagation();

        const menus = getContextMenus(props);

        showContextMenuGlobal({
          event,
          data: menus,
          title: mainTitle,
        });
      }
    },
    [getContextMenus, mainTitle, showContextMenuGlobal, viewMode]
  );

  const selfField = getSelfFielf();

  delete selfField.type;

  return {
    viewMode,
    selfField,
    setViewMode: setViewModeHandler,
    getDesignMenus,
    showContextMenu,
    globalSettings,
    settings: {
      ...defaultSettings,
      ...settings,
    },
    HasEventComponent,
  };
}

export default useUiDesigner;
