/* eslint-disable import/no-cycle */
import axios from 'axios';
import { toast } from 'react-toastify';
import ClientEventHandler from '~/applications/ClientEventHandler';
// import LocalStorageService from '~/services/LocalStorage';
import { server_url } from '~/config/application';
import QueryService from '~/services/QueryService';

import { fireEvent } from '~/easy-components/Form/sharedFunctions';
import TreatError from '~/easy-components/TreatError';

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          const [key, value] = param.split('=');
          params[key.replace('$', '')] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
};

class RequestService {
  async request({
    formRef = null,
    eventAlias = null,
    settings = null,
    url,
    token = null,
    method = 'get',
    data = null,
    timeout = 0,
    headers = {},
    file = null,
    largeData = null,
    cancelToken,
  }) {
    const serverUrl = await server_url();
    const uri = `${serverUrl}/${url}`;

    let dataToSend = null;

    const urlSplitted = uri.split('?');

    if (urlSplitted.length > 1 && urlSplitted[1] !== '') {
      let encodedParams = '';

      const paramsList = urlSplitted[1].split('&');

      paramsList.forEach((param, index) => {
        const [prop, value] = param.split('=');

        const propAndValue = `${prop}=${encodeURIComponent(value)}`;

        encodedParams += index === 0 ? propAndValue : `&${propAndValue}`;
      });

      urlSplitted[1] = encodedParams;
    }

    let urlEncoded = urlSplitted.join('?');

    const hasHashTagUrl = urlEncoded.includes('#');

    if (hasHashTagUrl) {
      const hashtagEncode = '%23';
      urlEncoded = urlEncoded.split('#').join(hashtagEncode);
    }

    if (file) {
      dataToSend = new FormData();

      dataToSend.append('file', file, file.name || file.originalname);

      if (largeData) {
        const jsonse = JSON.stringify(largeData);
        const blob = new Blob([jsonse], { type: 'application/json' });

        // TODO: Alterar para criar o nome dinamicamente
        dataToSend.append('largeData', blob);
      } else {
        dataToSend.append('data', JSON.stringify(data));
      }
    } else if (largeData) {
      dataToSend = new FormData();

      const jsonse = JSON.stringify(largeData);
      const blob = new Blob([jsonse], { type: 'application/json' });

      // TODO: Alterar para criar o nome dinamicamente
      dataToSend.append('largeData', blob);
    } else {
      dataToSend = data;
    }

    const locale = localStorage.getItem('i18nextLng');

    /** @type {import('axios').AxiosRequestConfig} */
    const requestParams = {
      method,
      url: urlEncoded,
      data: dataToSend,
      timeout,
      headers: {
        locale,
        eventName: eventAlias || '',
        authorization: `Bearer ${token}`,
        ...headers,
      },
      cancelToken,
    };

    const response = await axios(requestParams);

    if (eventAlias && eventAlias !== 'onSave' && eventAlias !== 'onLoadData') {
      let queryParams = null;

      const urlParams = url.split('?');

      if (urlParams.length > 0)
        queryParams = getQueryStringParams(urlParams[1]);

      const routeParams = urlParams[0].split('/');

      const clientResponse = await ClientEventHandler({
        eventName: eventAlias,
        data: response.data,
        params: {
          queryParams,
          routeParams,
          settings,
          formRef,

          executeQuery: QueryService.execute,
          executeScalar: async sql => {
            const responseSql = await QueryService.execute(1, sql);
            if (responseSql.length > 0) return responseSql[0];
            return {};
          },
          executeSql: async sql => {
            const responseSql = await QueryService.execute(1, sql);
            return responseSql;
          },
          showError: message => {
            throw new Error(message);
          },
          TreatError,
          fireEvent,
          toast,
        },
      });

      return clientResponse;
    }

    return response.data;
  }
}

export default new RequestService();
