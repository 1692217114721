import axios from 'axios';

const getUrl = async () => {
  try {
    const response = await axios.get('/server_url');
    return response.data;
  } catch (error) {
    return 'ERRO';
  }
};

export const title = 'Aster';
export const app_key = 'SPS_AHS';
export const server_url = async () => {
  if (process.env.REACT_APP_SERVER_URL.indexOf('standalone') >= 0) {
    const url = await getUrl();
    return url;
  }

  return process.env.REACT_APP_SERVER_URL;
};

export default {};
