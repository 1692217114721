/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import ModalHandle from '~/easy-components/ModalHandle';
import HeaderPage from '~/easy-components/HeaderPage';
import FooterPage from '~/easy-components/FooterPage';
import FlexSpace from '~/easy-components/FlexSpace';
import Button from '~/easy-components/Button';
import GoogleMaps from '~/easy-components/GoogleMaps';
import colors from '~/styles/colors';

import { Container, Content, Title } from './styles';

function ModalMap(props, ref) {
  const myModal = useRef();
  const mapRef = useRef();
  const titleRef = useRef();
  const stateRef = useRef({});

  /* const setRoute = async ({ origin, destination, waypoints }) => {
    await mapRef.current.setRoute({
      origin,
      destination,
      waypoints,
      optimizeWaypoints: true,
      travelMode: 'DRIVING',
    });
  }; */

  useImperativeHandle(ref, () => {
    return {
      show: ({ title, points }) => {
        const newPoints = {
          origin: points.origin,
          destination: points.destination,
          waypoints: points.waypoints.slice(0, 25),
        };

        stateRef.current = {
          points: newPoints,
          title,
        };

        myModal.current.handleOpen();
      },
      hide: () => {
        myModal.current.handleClose();
      },
    };
  });

  return (
    <ModalHandle ref={myModal}>
      <Container>
        <HeaderPage
          backgroundColor={colors.headerBg}
          color={colors.headerTitle}
          hideMainMenu
        >
          <Title ref={titleRef} />
        </HeaderPage>
        <Content>
          <GoogleMaps
            ref={mapRef}
            onReady={async googleRef => {
              titleRef.current.innerText = stateRef.current.title;
              await googleRef.setRoute({
                ...stateRef.current.points,
                optimizeWaypoints: true,
                travelMode: 'DRIVING',
              });
            }}
          />
        </Content>
        <FooterPage>
          <FlexSpace />
          <Button
            buttonType="Emphasized"
            onClick={() => myModal.current.handleClose()}
          >
            Fechar
          </Button>
        </FooterPage>
      </Container>
    </ModalHandle>
  );
}

export default forwardRef(ModalMap);
