import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;

  > section {
    overflow: auto;
    flex: 1;
  }

  > div {
    flex: 1;
    overflow: auto;
  }
`;

export const Content = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
