/* eslint-disable react/prop-types */
// @ts-check
import React, { useRef, forwardRef } from 'react';

import moment from 'moment';
import { format } from 'date-fns';
import 'moment/locale/pt';

import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import { Container } from './styles';

import ModalFields from '~/easy-components/ModalFields';
import DefaultEventView from './Components/DefaultEventView';

const lang = {
  fr: {
    week: 'La semaine',
    work_week: 'Semaine de travail',
    day: 'Jour',
    month: 'Mois',
    previous: 'Antérieur',
    next: 'Prochain',
    today: `Aujourd'hui`,
    agenda: 'Ordre du jour',
    showMore: total => `+${total} plus`,
  },
  'pt-BR': {
    week: 'Semana',
    work_week: 'Semana de trabalho',
    day: 'Dia',
    month: 'Mês',
    previous: 'Anterior',
    next: 'Próximo',
    today: 'Hoje',
    agenda: 'Agenda',
    showMore: total => `+${total} mais`,
  },
  'en-US': {
    week: 'Week',
    work_week: 'Work Week',
    day: 'Day',
    month: 'Month',
    previous: 'Previous',
    next: 'Next',
    today: 'Today',
    agenda: 'Agenda',
    showMore: total => `+${total} more`,
  },
  'es-BO': {
    week: 'Semana',
    work_week: 'Semana de trabajo',
    day: 'Día',
    month: 'Mes',
    previous: 'Atrás',
    next: 'Después',
    today: 'Hoy',
    agenda: 'El Diario',
    showMore: total => `+${total} más`,
  },
  'es-ES': {
    week: 'Semana',
    work_week: 'Semana de trabajo',
    day: 'Día',
    month: 'Mes',
    previous: 'Atrás',
    next: 'Después',
    today: 'Hoy',
    agenda: 'El Diario',
    showMore: total => `+${total} más`,
  },
};

/**
 * @typedef {Object} Props
 * @property {boolean} [readOnly]
 * @property {any[]} [events]
 * @property {string} [keyField]
 * @property {import('react-big-calendar').View} [defaultView]
 * @property {string} [titleAccessor]
 * @property {string} [startAccessor]
 * @property {string} [endAccessor]
 * @property {string} [startTimeAccessor]
 * @property {string} [endTimeAccessor]
 * @property {import('react-big-calendar').CalendarProps['components']['event']} [onEventTemplate]
 * @property {import('react-big-calendar').CalendarProps['onSelectSlot']} [onAddEvent]
 * @property {import('react-big-calendar').CalendarProps['onSelectEvent']} [onSelectEvent]
 * @property {import('react-big-calendar').CalendarProps['onNavigate']} [onNavigate]
 * @property {(context: {start: string, end: string}) => void} [onRangeChange]
 * @property {boolean} [isDraggable]
 * @property {string} [locale]
 *
 * @param {Props} params
 */
function Schedule(
  {
    readOnly = false,
    events = [],
    keyField = 'Id',
    defaultView = Views.MONTH,
    titleAccessor = 'title',
    startAccessor = 'start',
    endAccessor = 'end',
    startTimeAccessor,
    endTimeAccessor,
    onEventTemplate,
    onAddEvent = () => {},
    onSelectEvent = () => {},
    onNavigate = () => {},
    isDraggable = false,
    locale = 'pt-BR',
    onRangeChange = () => {},
  },
  ref
) {
  const modalScheduleEventRef = useRef();

  const localizer = momentLocalizer(moment);

  /** @type {typeof Calendar | ReturnType<withDragAndDrop>} */
  let EasyCalendar = Calendar;

  if (isDraggable) {
    // @ts-ignore
    EasyCalendar = withDragAndDrop(Calendar);
  }

  const useTemplate = !!onEventTemplate;

  const eventPropGetter = () => {
    return {
      style: {
        backgroundColor: 'transparent',
        padding: 0,
      },
    };
  };

  /**
   * @param {number} [int]
   * @returns {string}
   */
  const convertIntegerInTime = (int = 0) => {
    const intString = int === null ? '' : int.toString();

    const fullTime = intString.padStart(4, '0');

    const formattedTime = `${fullTime.slice(0, 2)}:${fullTime.slice(2)}`;

    return formattedTime;
  };

  /**
   * @param {object} event
   * @param {string} accessor
   * @param {string} timeAccessor
   * @returns {Date}
   */
  const getUnifiedDate = (event, accessor, timeAccessor) => {
    if (!timeAccessor) {
      return event[accessor];
    }

    let eventDate = event[accessor];

    if (!eventDate) {
      return new Date();
    }

    if (typeof eventDate === 'string') eventDate = new Date(eventDate);

    eventDate = format(eventDate, 'yyyy-MM-dd');

    const eventTime = convertIntegerInTime(event[timeAccessor]);

    const isoDate = `${eventDate}T${eventTime}`;

    const unifiedDate = new Date(isoDate);

    return unifiedDate;
  };

  return (
    <Container>
      {/* @ts-ignore */}
      <EasyCalendar
        ref={ref}
        onNavigate={onNavigate}
        components={{
          event: useTemplate
            ? onEventTemplate
            : props => (
                <DefaultEventView {...props} titleAccessor={titleAccessor} />
              ),
        }}
        culture={locale}
        messages={lang[locale]}
        defaultDate={new Date()}
        localizer={localizer}
        defaultView={defaultView}
        events={events}
        selectable={!readOnly}
        resizable={!readOnly}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onAddEvent}
        key={keyField}
        titleAccessor={event => event[titleAccessor]}
        startAccessor={event =>
          getUnifiedDate(event, startAccessor, startTimeAccessor)
        }
        endAccessor={event =>
          getUnifiedDate(event, endAccessor, endTimeAccessor)
        }
        eventPropGetter={eventPropGetter}
        onRangeChange={props => {
          let start = null;
          let end = null;

          if (Array.isArray(props)) {
            start = props.shift();
            end = props.pop();
          } else {
            start = props.start;
            end = props.end;
          }

          if (!end) {
            end = start;
          }

          start = format(start, 'yyyy-MM-dd');
          end = format(end, 'yyyy-MM-dd');

          onRangeChange({ start, end });
        }}
        drilldownView="agenda"
      />

      <ModalFields ref={modalScheduleEventRef} />
    </Container>
  );
}

export default forwardRef(Schedule);
