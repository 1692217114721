import styled from 'styled-components';

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #0f0;
  overflow: auto;
  position: relative;
`;
export const Container = styled.div`
  flex: 1;
  background-color: #000;
  display: flex;
  /* flex-direction: column;
  justify-content: center; */
  overflow: auto;
`;

export const Message = styled.div`
  color: #fff;
  animation: animate 1.5s linear infinite;
  font-size: 1.4rem;
  text-align: center;
  padding: 30px;

  position: absolute;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;

  @keyframes animate {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
`;
