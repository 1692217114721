// @ts-check

import React, { useEffect, useState } from 'react';

import Button from '~/easy-components/Button';
import Icon from '~/easy-components/Icon';
import systemColors from '~/styles/colors';

import { IconButton } from './styles';

export default function FooterButton({
  type,
  toolTip,
  text,
  onClick,
  translateKey,
  translateRoute,
  t,
  iconName,
  size,
  color,
  isHidden: isHiddenProp,
  onCheckIsHidden,
}) {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const checkIsHidden = async () => {
      if (typeof isHiddenProp === 'boolean') {
        setIsHidden(isHiddenProp);
        return;
      }

      if (typeof isHiddenProp === 'string' && onCheckIsHidden) {
        const newIsHidden = await onCheckIsHidden();

        setIsHidden(newIsHidden);
      }
    };

    checkIsHidden();
  }, [isHiddenProp, onCheckIsHidden]);

  if (isHidden) {
    return null;
  }

  switch (type) {
    case 'customDisplay':
      return (
        <Button
          data-tip={toolTip}
          data-for="footerButtonsTooltip"
          key={text}
          buttonType="Default"
          onClick={onClick}
        >
          {text}
        </Button>
      );

    case 'icon':
      return (
        <IconButton
          data-tip={toolTip}
          data-for="footerButtonsTooltip"
          type="button"
        >
          <Icon
            name={iconName}
            size={size || 25}
            color={color || systemColors.blue}
            onClick={onClick}
          />
        </IconButton>
      );

    default:
      return (
        <Button
          data-tip={toolTip}
          data-for="footerButtonsTooltip"
          key={text}
          buttonType={type}
          onClick={onClick}
        >
          {translateKey ? t(translateKey, translateRoute) : text}
        </Button>
      );
  }
}
