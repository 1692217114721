import React, { useState } from 'react';
import { CSVLink } from 'react-csv';

import Icon from '~/easy-components/Icon';

// import { Container } from './styles';

export default function CSVExportButton({
  excelColumns,
  fileName,
  t,
  onExportLines,
}) {
  const [exportData, setExportData] = useState([]);

  return (
    <CSVLink
      data={exportData}
      asyncOnClick
      onClick={async (event, done) => {
        const newData = await onExportLines(setExportData);

        setExportData(newData);

        done();
      }}
      headers={excelColumns
        .filter(c => {
          const notExports = ['button', 'selection'];
          if (c.settings && notExports.includes(c.settings.type)) {
            return false;
          }

          return true;
        })
        .map(c => {
          return {
            label: c.title,
            key: c.key,
          };
        })}
      filename={`${fileName}.csv`}
      enclosingCharacter=""
      separator=";"
      data-tip={t('XLSXDownload')}
      data-for="json-table-tooltip"
    >
      <Icon size={28} color="#353c44" name="RiFileExcel2Line" />
    </CSVLink>
  );
}
