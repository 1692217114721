/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import JsonTable from '~/easy-components/JsonTable';
import CompareRender from '~/easy-components/Helpers/compareRender';
import tableMountService from '~/applications/CRM/services/TableMountService';
import useStateCache from '~/hooks/useStateCache';
import useUiDesigner from '~/hooks/useUiDesigner';
import { Container } from './styles';

/**
 *
 * @param {object} params
 * @param {string} params.title
 * @param {string} params.name
 * @param {boolean} params.hidden
 * @param {object} params.settings
 * @param {import('react').MutableRefObject<import('@unform/core').FormHandles>} [params.formRef]
 * @param {object} params.auxScope
 * @param {(text: string) => string} params.t
 * @param {() => void} [params.onRefresh]
 * @param {(isLoading: bool) => void} params.showLoading
 * @param {object} [params.tableSettings]
 * @returns
 */
function InputTable(
  {
    title,
    name,
    formRef,
    settings,
    onRefresh,
    showLoading,
    tableSettings: userTableSettings,
    hidden,
    auxScope,
    onGetTableSettings,
    onValidadeDelete,
    onOpenInputLinkerInLine,
    t,
    isReadOnly = false,
    isUserField,
    queryCode,
    ...props
  },
  ref
) {
  const tableSettingsRef = useRef(userTableSettings);
  const hasValeuWithTableSettingsRef = useRef(false);

  const tableRef = useRef();

  const { selfField, showContextMenu } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'tabGrid',
    baseName: null,
    name,
    title: null,
    settings,
    isUserField,
    contextProps: {
      fieldName: name,
    },
  });

  const elementRef = useRef(null);

  const defaultData = {
    data: [],
    columns: [],
    reportSettings: {},
  };

  const [state, setState, getState] = useStateCache({
    ...defaultData,
  });

  const { fieldName, defaultValue = '', registerField } = useField(name);

  const getTableSettings = useCallback(async () => {
    if (onGetTableSettings) {
      try {
        const newTableSettings = await onGetTableSettings();

        return newTableSettings;
      } catch (error) {
        // TODO: o que fazer com o erros
      }
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshSettings = useCallback(
    async v => {
      let newState;

      const parsedValue = typeof v === 'string' ? JSON.parse(v) : v;

      if (!tableSettingsRef.current) {
        const tableSet = await getTableSettings();
        tableSettingsRef.current = tableSet;
      }

      if (
        tableSettingsRef.current &&
        !tableSettingsRef.current.reportSettings
      ) {
        const inputTableSettings = await tableMountService.execute({
          data: tableSettingsRef.current.data,
          settings: userTableSettings,
        });

        tableSettingsRef.current = inputTableSettings;
      }

      if (tableSettingsRef.current && Array.isArray(parsedValue)) {
        newState = {
          ...tableSettingsRef.current,
          data: parsedValue,
        };
      } else if (tableSettingsRef.current && parsedValue === undefined) {
        newState = {
          ...tableSettingsRef.current,
          data: [],
        };
      } else {
        newState =
          parsedValue === undefined || parsedValue === null ? {} : parsedValue;
      }

      setState(newState);
    },
    [getTableSettings, setState, userTableSettings]
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: elementRef.current,
      path: 'value',
      clearValue: el => {
        el.value = null;

        const stateValue = getState();

        setState({
          ...stateValue,
          ...defaultData,
          data: [],
        });
      },
      setValue: async (r, v) => {
        refreshSettings(v);
        /*
        let newState;

        let parsedValue = v;

        let typeData = typeof v;

        if (Array.isArray(v)) {
          typeData = 'array';
        }

        switch (typeData) {
          case 'array':
            parsedValue = v;
            break;

          case 'string':
            parsedValue = JSON.parse(v);
            break;

          case 'object':
            hasValeuWithTableSettingsRef.current = true;
            parsedValue = v;
            break;

          default:
            break;
        }

        if (
          !tableSettingsRef.current &&
          !hasValeuWithTableSettingsRef.current
        ) {
          const tableSet = await getTableSettings();

          tableSettingsRef.current = tableSet || {};
        }

        if (
          !hasValeuWithTableSettingsRef.current &&
          !tableSettingsRef.current.reportSettings
        ) {
          const inputTableSettings = await tableMountService.execute({
            data: tableSettingsRef.current.data,
            settings: userTableSettings,
          });

          tableSettingsRef.current = inputTableSettings;
        }

        if (tableSettingsRef.current && Array.isArray(parsedValue)) {
          newState = {
            ...tableSettingsRef.current,
            data: parsedValue,
          };
        } else if (tableSettingsRef.current && parsedValue === undefined) {
          newState = {
            ...tableSettingsRef.current,
            data: [],
          };
        } else {
          newState =
            parsedValue === undefined || parsedValue === null
              ? {}
              : parsedValue;
        }

        setState(newState); */
      },
      getValue: () => {
        if (!hasValeuWithTableSettingsRef.current || onGetTableSettings) {
          const currentData =
            tableRef && tableRef.current && tableRef.current.getData();

          if (Array.isArray(currentData)) {
            return currentData;
          }

          return currentData.data;
        }

        const stateValue = getState();
        return stateValue;
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTableSettings, onGetTableSettings, settings]);

  useEffect(() => {
    elementRef.current.setColumnReadOnly = (columnName, status) => {
      tableRef.current.setColumnReadOnly(columnName, status);
    };

    elementRef.current.setColumnHidden = (columnName, status) => {
      tableRef.current.setColumnHidden(columnName, status);
    };

    elementRef.current.getNormalizedData = () => {
      return tableRef.current.getNormalizedData();
    };

    elementRef.current.selectRow = rowKeyValue => {
      return tableRef.current.selectRow(rowKeyValue);
    };

    elementRef.current.deselectRow = rowKeyValue => {
      return tableRef.current.deselectRow(rowKeyValue);
    };

    elementRef.current.getSelectedData = () => {
      return tableRef.current.getSelectedData();
    };

    elementRef.current.clearSelections = () => {
      return tableRef.current.clearSelections();
    };

    elementRef.current.getData = () => {
      return tableRef.current.getData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfField]);

  let hideToolbar = true;

  const myState = getState();

  if (
    myState.reportSettings &&
    (myState.reportSettings.hideToolbar !== undefined ||
      myState.reportSettings.hideToolbar !== null)
  ) {
    hideToolbar = myState.reportSettings.hideToolbar;
  }

  useImperativeHandle(ref, () => {
    return {
      setSettings: newSettings => {
        setState(newSettings);
      },
      getData: () => {
        return tableRef.current.getNormalizedData();
      },
      setData: data => {
        setState({
          ...state,
          data,
        });
      },
    };
  });

  return (
    <Container
      onContextMenu={event => {
        showContextMenu({
          event,
          auxContextMenus: [
            {
              title: 'EditQuery',
              prop: queryCode,
              type: 'query',
              visible: !!queryCode,
            },
          ],
        });
      }}
    >
      <textarea
        hidden
        ref={elementRef}
        id={fieldName}
        defaultValue={defaultValue}
      />

      <JsonTable
        ref={tableRef}
        title={title}
        t={t}
        isReadOnly={isReadOnly}
        columns={myState.columns || []}
        reportSettings={myState.reportSettings || {}}
        groups={myState.groups}
        {...props}
        data={JSON.parse(JSON.stringify(myState.data || []))}
        onRefresh={onRefresh}
        settings={settings}
        showLoading={showLoading}
        hideToolbar={hideToolbar}
        hasFilter={false}
        onValidadeDelete={onValidadeDelete}
        onOpenInputLinkerInLine={onOpenInputLinkerInLine}
        auxScope={{
          form: formRef?.current,
          formData: formRef?.current && formRef?.current.getData(),
          globalFunctions: settings && settings.globalFunctions,
          ...auxScope,
        }}
      />
    </Container>
  );
}

InputTable.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  settings: PropTypes.object.isRequired,
  onRefresh: PropTypes.func,
  showLoading: PropTypes.func,
  tableSettings: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        dataType: PropTypes.string,
      })
    ),
    reportSettings: PropTypes.shape({
      newLine: PropTypes.oneOf(['before', 'after']),
      newLineDefaultData: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    }),
  }),
  onGetNewLineFromString: PropTypes.func,
  formRef: PropTypes.object,
};

InputTable.defaultProps = {
  hidden: false,
  title: '',
  onRefresh: () => {},
  showLoading: () => {},
  tableSettings: null,
  onGetNewLineFromString: () => {},
  formRef: {},
};

export default CompareRender(forwardRef(InputTable));
