/* eslint-disable react/prop-types */
import React, {
  useState,
  useImperativeHandle,
  useCallback,
  forwardRef,
  useRef,
} from 'react';

import { Container, Panel } from './styles';

function PanelControls({ mainPanel, onChangePanel, children }, ref) {
  const mainPanelRef = useRef();
  const [selected, setSelected] = useState(mainPanel);

  const getPanel = useCallback(
    panelName => children.find(panel => panel.props.name === panelName),
    [children]
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        setPanel: panelName => {
          setSelected(panelName);
          const selectedPanel = getPanel(panelName);
          onChangePanel({ name: panelName, panel: selectedPanel });
        },
        setScrollToBottom: () => {
          setTimeout(() => {
            if (mainPanelRef && mainPanelRef.current) {
              mainPanelRef.current.scrollTop =
                mainPanelRef.current.scrollHeight;

              mainPanelRef.current.animate(
                { scrollTop: mainPanelRef.current.scrollHeight },
                500
              );
            }
          }, 200);
        },
      };
    },
    [getPanel, onChangePanel]
  );

  return (
    <Container ref={mainPanelRef}>
      {children.map(panel => {
        return (
          <Panel isShow={selected === panel.props.name} key={panel.props.name}>
            {panel}
          </Panel>
        );
      })}
    </Container>
  );
}

export default forwardRef(PanelControls);
