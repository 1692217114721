/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import { FiSave as SaveIcon } from '@react-icons/all-files/fi/FiSave';
import CodeEditor from '~/easy-components/CodeEditor';

import colors from '~/styles/colors';

// eslint-disable-next-line import/no-cycle
import { PageContext } from '../index';
import { Container } from '../styles';

export default function SettingsTab() {
  const {
    masterDetailRef,
    pageSettings,
    setPageSettings,
    t,
    isSettingsReadOnly,
  } = useContext(PageContext);

  return (
    <Container flexible>
      <CodeEditor
        readOnly={isSettingsReadOnly}
        editorType="json"
        value={pageSettings || ''}
        onChange={value => {
          setPageSettings(value);
        }}
        onCtrlS={async () => {
          await masterDetailRef.current.save({ hasQuestion: false });
        }}
        buttons={[
          <div
            key="save"
            data-tip={t('Save')}
            data-for="ToolTipApplications"
            onClick={async () => {
              await masterDetailRef.current.save();
            }}
          >
            <SaveIcon size={24} color={colors.mainMenuIcons} />
          </div>,
        ]}
      />
    </Container>
  );
}
