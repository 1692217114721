import styled from 'styled-components';

export const Container = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Panel = styled.div`
  display: ${props => (props.isShow ? 'flex' : 'none')} !important;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;
