import RequestService from './RequestService';
import CompanyService from './CompanyService';

class ConfigurationsService {
  async list({ filter }) {
    const response = await RequestService.request({
      url: `configurations?$filter=${filter || ''}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async get(configurationId) {
    const response = await RequestService.request({
      url: `configurations/${configurationId}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async getCustomSettings(configurationId) {
    const response = await RequestService.request({
      url: `company_configurations/${configurationId}/configurations`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });
    return response;
  }

  async createOrUpdate({ configurationId, settings, id }) {
    if (id) {
      const response = await RequestService.request({
        url: `company_configurations/${id}`,
        method: 'PUT',
        token: await CompanyService.getCompanyToken(),
        data: {
          configurationId,
          settings,
        },
      });
      return response;
    }
    const response = await RequestService.request({
      url: `company_configurations`,
      method: 'POST',
      token: await CompanyService.getCompanyToken(),
      data: {
        configurationId,
        settings,
      },
    });
    return response;
  }

  async getField({ configurationCode, property }) {
    const response = await RequestService.request({
      url: `company_configurations/${configurationCode}/${property}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }
}

export default new ConfigurationsService();
