import React from 'react';
import Icon from '~/easy-components/Icon';

function HelpButton({ url, customUrl }) {
  const onShowUrl = () => {
    window.open(customUrl || url, '_blank');
  };

  return (
    <>
      {(url || customUrl) && (
        <button type="button" onClick={onShowUrl}>
          <Icon name="IoMdHelp" size="18" color="#ffffff" />
        </button>
      )}
    </>
  );
}

export default HelpButton;
