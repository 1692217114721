/* eslint-disable react/prop-types */
import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';

import { Container, Content, ModalBody, PageHeader } from './styles';
import Upload from '~/easy-components/Upload';
import ModalFooter from '~/easy-components/ModalFooter';
// eslint-disable-next-line import/no-cycle
import Button from '~/easy-components/Button';
import useLocale from '~/hooks/useLocale';

function AttachmentModal(props = {}, ref) {
  const { name, settings, auxScope } = props;

  const t = useLocale('_Global');

  const [state, setState] = useState({
    open: false,
    title: 'Anexar Arquivo',
    message: t('message.SelectFile'),
    accept: '',
    onUpload: () => {},
    isReadOnly: true,
    isSetFileName: true,
    onValidateFile: () => null,
  });

  const modalRoot = document.getElementById('modal');

  const el = document.createElement('div');

  useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el, modalRoot]);

  function close() {
    setState({
      ...state,
      open: false,
    });
  }

  useImperativeHandle(ref, () => {
    return {
      show: async ({
        title,
        message,
        accept,
        onUpload,
        isReadOnly,
        isSetFileName,
        onValidateFile,
      }) => {
        setState({
          open: true,
          title,
          message,
          accept,
          onUpload,
          isReadOnly,
          isSetFileName,
          onValidateFile,
        });
      },
      close,
    };
  });

  return (
    <>
      {state.open &&
        createPortal(
          <Container onMouseDown={close}>
            <Content
              onMouseDown={e => {
                e.stopPropagation();
              }}
            >
              <PageHeader>{state.title}</PageHeader>
              <ModalBody>
                <Upload
                  message={state.message}
                  onUpload={state.onUpload}
                  accept={state.accept}
                  isReadOnly={state.isReadOnly}
                  isSetFileName={state.isSetFileName}
                  onValidateFile={state.onValidateFile}
                  name={name}
                  settings={settings}
                  auxScope={auxScope}
                />
              </ModalBody>
              <ModalFooter className="footer">
                <Button onClick={() => close()}>{t('Close')}</Button>
              </ModalFooter>
            </Content>
          </Container>,
          el
        )}
    </>
  );
}

export default forwardRef(AttachmentModal);
