import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Summary = styled.div`
  background-color: #f1f5f7;
  padding: 5px 10px;
  border-top: 1px solid #f0f0f0;
  font-size: 0.9rem;
  color: #728591;
  display: flex;
  justify-content: space-between;
`;

export const TitleContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 0;

  h1 {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 2px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      color: #666;
      font-size: 0.9rem;
    }
  }
`;
