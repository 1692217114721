import { useCallback } from 'react';
import ImplementationProjectService from '~/applications/CRM/services/ImplementationProjectService';
import useLocale from '~/hooks/useLocale';
import Toast from '~/easy-components/Toast';
import TreatError from '~/easy-components/TreatError';

export default function useProjectLinker({
  type,
  showModalFields,
  showLoading,
  keyCode,
}) {
  const t = useLocale('ImplementationProjects');

  const linkProject = useCallback(
    async key => {
      const implementationProjects = await ImplementationProjectService.listToLink();

      showModalFields({
        title: t('LinkProject'),
        fields: [
          {
            name: 'implementationProjectId',
            translateKey: 'Process',
            translateRoute: 'ImplementationProjects',
            type: 'fixedData',
            data: implementationProjects,
            required: true,
          },
        ],
        onConfirm: async data => {
          try {
            showLoading(true);

            let finalKey;

            if (key !== '' && typeof key === 'string') {
              finalKey = key;
            } else {
              finalKey = keyCode;
            }

            const code = await ImplementationProjectService.getImplementation({
              type,
              keyCode: finalKey,
            });

            await ImplementationProjectService.addItem(
              data.implementationProjectId,
              {
                type,
                keyCode: finalKey,
                code,
              }
            );

            Toast.success(t('message.SystemMessageSuccess'));
          } catch (error) {
            TreatError.showError(error);
          } finally {
            showLoading(false);
          }
        },
      });
    },
    [keyCode, showLoading, showModalFields, t, type]
  );

  return { linkProject };
}
