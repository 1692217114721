import React from 'react';
import PropTypes from 'prop-types';

import { Container, Div } from './styles';

function AuxInfo({ isGenericListItem=false, text, direction, ...rest }) {
  function ShowTexts() {
    if (text) {
      const textString = text.toString();
      if (textString.indexOf('|') >= 0) {
        const textList = textString.split('|');
        return textList.map(t => {
          if (t == null || t.trim() === '' || t.trim() === 'null') {
            return null;
          }
          return <Div isGenericListItem={isGenericListItem} title={t.trim()} key={t.trim()}>{t.trim()}</Div>;
        });
      }
      return <Div isGenericListItem={isGenericListItem} title={text}>{text}</Div>;
    }

    return null;
  }
  return (
    <>
      {text && (
        <Container direction={direction} {...rest}>
          <div>
            <ShowTexts />
          </div>
        </Container>
      )}
    </>
  );
}

AuxInfo.propTypes = {
  text: PropTypes.string,
  direction: PropTypes.string,
};

AuxInfo.defaultProps = {
  text: null,
  direction: 'column',
};

export default AuxInfo;
