import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  cursor: ${props => (props.isClickable ? 'pointer' : 'default')};

  svg {
    width: ${props => props.size || 24}px;
    height: ${props => props.size || 24}px;
    color: ${props => props.color} !important;
  }
`;
