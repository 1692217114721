/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, {
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { createPortal } from 'react-dom';

import Button from '~/easy-components/Button';
import Icon from '~/easy-components/Icon';

import PageHeader from '~/easy-components/PageHeader';
import ModalFooter from '~/easy-components/ModalFooter';
import useLocale from '~/hooks/useLocale';
import FlexSpace from '~/easy-components/FlexSpace';
import { Container, Content, ModalBody } from './styles';

import Builder from './AllowedTypes/Builder';
import ConfirmButton from './Components/ConfirmButton';

/**
 * @typedef {Object} OnOpenInputLinkerInLineProps
 * @property {string} filter
 * @property {import('ka-table/models').Column} column
 * @property {string | number} [rowKeyValue]
 * @property {Record<string, any>} rowData
 * @property {string} [queryCode]
 * @property {string} [sql]
 * @property {Object[]} items
 *
 *
 * @typedef {Object} ReportOptions
 * @property {Object[]} data
 * @property {Object} [settings]
 * @property {string} [title]
 * @property {string} [hash]
 * @property {Record<string, string>} [hash]
 * @property {OnOpenInputLinkerInLineProps} [onOpenInputLinkerInLine]
 *
 *
 * @typedef {Object} Icon
 * @property {string} name
 * @property {number} size
 * @property {string} color
 *
 *
 * @typedef {Object} OnConfirmProps
 * @property {Record<string, any>[]} [data]
 * @property {string} [message]
 *
 *
 * @typedef {'textarea' | 'question' | 'showContent' | 'showReport' | 'options'} ModalTypes
 *
 *
 * @typedef {Object} ShowProps
 * @property {ModalTypes} type
 * @property {string} title
 * @property {string} [message]
 * @property {Record<string, string>} [dataToReplace]
 * @property {ReportOptions} [reportOptions]
 * @property {Icon[]} [iconsContent]
 * @property {string} [htmlContent]
 * @property {(props: OnConfirmProps) => any} [onConfirm]
 * @property {() => any} [onCancel]
 * @property {React.FC[] | React.ClassicComponent[]} [footer]
 * @property {(data: any) => Promise<boolean>} [canConfirm]
 * @property {{title: string, icon: string, handler: () => Promise<any>}[]} [options]
 *
 * @typedef {import('react').MutableRefObject<{show: (props: ShowProps) => void}>} ComponentRef
 *
 *
 * @typedef {Object} ComponentProps
 * @property {ModalTypes} [props.fixedType]
 * @property {"right" | "left"} [props.direction]
 * @property {"right" | "left"} [props.direction]
 * @property {Object} [settings]
 */

const getComponentType = type => {
  return new Builder().build(type);
};

/**
 * @param {import('react').PropsWithChildren<ComponentProps>} props
 * @param {import('react').Ref} ref

 */
function DisplayModal(
  {
    fixedType = 'showContent',
    direction = 'left',
    children,
    settings,
    showLoading,
  },
  ref
) {
  const componentTypeRef = useRef(null);
  const confirmButtonRef = useRef();

  const [state, setState] = useState({
    open: false,
    title: '',
    message: '',
    type: fixedType,
    dataToReplace: {},
    reportOptions: {
      title: '',
      hash: '',
      params: [],
      data: [],
      settings: {},
      onOpenInputLinkerInLine: () => {},
    },
    iconsContent: [],
    htmlContent: '',
    onConfirm: () => {},
    onCancel: () => {},
    footer: [],
    canConfirm: () => true,
    options: [],
    translationRoute: '_Global',
  });
  const modalRoot = document.getElementById('modal');
  const el = document.createElement('div');

  const ComponentType = getComponentType(state.type);

  const t = useLocale('_Global');

  function handleClose() {
    setState({
      ...state,
      open: false,
    });
  }

  useImperativeHandle(ref, () => {
    return {
      show: async ({
        type,
        title,
        message,
        dataToReplace,
        reportOptions,
        iconsContent,
        htmlContent,
        onConfirm,
        onCancel,
        footer = [],
        canConfirm,
        options = [],
        footerElements,
        footerFlex = true,
      }) => {
        setState({
          type,
          open: true,
          title,
          message,
          dataToReplace,
          reportOptions,
          iconsContent,
          htmlContent,
          onConfirm,
          onCancel,
          footer,
          canConfirm,
          options,
          footerElements,
          footerFlex,
        });
      },
      handleClose,
    };
  });

  useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el, modalRoot]);

  const dispatchConfirmValidation = async props => {
    if (!state.canConfirm) {
      return;
    }

    const canConfirm = await state.canConfirm(props);

    if (confirmButtonRef.current) {
      confirmButtonRef.current.disable(!canConfirm);
    }
  };

  return (
    <>
      {state.open &&
        createPortal(
          <Container direction={direction} onMouseDown={handleClose}>
            <Content
              onMouseDown={e => {
                e.stopPropagation();
                // não incluir o e.preventDefault(); que os inputs param de funcionar
              }}
            >
              <PageHeader>{state.title}</PageHeader>
              <ModalBody type={state.type}>
                <ComponentType
                  ref={componentTypeRef}
                  message={state.message}
                  onConfirm={state.onConfirm}
                  htmlContent={state.htmlContent}
                  dataToReplace={state.dataToReplace}
                  iconsContent={state.iconsContent}
                  reportOptions={state.reportOptions}
                  settings={settings}
                  dispatchConfirmValidation={dispatchConfirmValidation}
                  options={state.options}
                  onOptionSelect={async optionSelected => {
                    await optionSelected.handler();

                    handleClose();
                  }}
                  t={t}
                  showLoading={showLoading}
                >
                  {children}
                </ComponentType>
              </ModalBody>

              <ModalFooter>
                {state.footer.map(element => {
                  return element;
                })}

                {state.footerElements &&
                  state.footerElements.map(footerElement => {
                    if (footerElement.icon) {
                      return (
                        <Icon
                          name={footerElement.icon}
                          size={footerElement.size || 22}
                          color={footerElement.color || '#449dc1'}
                          onClick={footerElement.handler}
                        />
                      );
                    }
                    return (
                      <Button
                        name={footerElement.name}
                        buttonType={footerElement.type}
                        onClick={() => {
                          return footerElement.handler({
                            close: handleClose,
                          });
                        }}
                        settings={settings}
                        hidden={footerElement.hidden}
                      >
                        {footerElement.text}
                      </Button>
                    );
                  })}
                {state.footerElements && state.footerFlex ? (
                  <FlexSpace />
                ) : null}
                {state.onConfirm && state.type !== 'options' && (
                  <ConfirmButton
                    ref={confirmButtonRef}
                    onClick={() => {
                      const refCurrent = componentTypeRef.current;
                      // O Close deve ser fechado antes de qualquer ação
                      handleClose();

                      refCurrent.onConfirm();
                    }}
                  >
                    {t('Confirm')}
                  </ConfirmButton>
                )}
                <Button
                  onClick={() => {
                    // O Close deve ser fechado antes de qualquer ação
                    handleClose();

                    if (state.onCancel) state.onCancel();
                  }}
                >
                  {t('Close')}
                </Button>
              </ModalFooter>
            </Content>
          </Container>,
          el
        )}
    </>
  );
}

export default forwardRef(DisplayModal);
