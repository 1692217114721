import React, { useEffect, useState } from 'react';

import { createAsyncFunctionByString } from '~/easy-components/AsyncFunctionString';

import { Container } from './styles';

function StatusHeader({ templateData, headerColor }) {
  const [status, setStatus] = useState('');

  async function changeHeaderColor() {
    const dynamicFunction = createAsyncFunctionByString({
      functionString: headerColor,
    });
    const response = await dynamicFunction({ data: templateData });
    return response;
  }

  useEffect(() => {
    if (!headerColor) return;

    changeHeaderColor().then(color => setStatus(color));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData]);

  return <Container backgroundColor={status} />;
}

export default StatusHeader;
