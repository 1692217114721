/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  memo,
} from 'react';

import Modal from '~/easy-components/Modal';
import Form from '~/easy-components/Form';
import HeaderPage from '~/easy-components/HeaderPage';
import FooterPage from '~/easy-components/FooterPage';
import FlexSpace from '~/easy-components/FlexSpace';
import Button from '~/easy-components/Button';
import Panel from '~/easy-components/Panel';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Field from '~/easy-components/Form/UserField/Field';
import colors from '~/styles/colors';
import useLocale from '~/hooks/useLocale';

import { Container, Content } from './styles';

function ModalFields(props, ref) {
  const t = useLocale('_Global');

  const formRef = useRef();
  const onOpenRef = useRef();

  const [state, setState] = useState({
    title: t('Parameters'),
    isShow: false,
    requiredFields: [],
    fields: [],
    onConfirm: () => {},
    onClose: () => {},
    data: {},
    errors: {},
    settings: {},
    footer: [],
    auxInfo: null,
  });

  function handlerClose(e) {
    e.stopPropagation();

    e.preventDefault();

    state.onClose();

    setState({
      ...state,
      data: {},
      settings: {},
      isShow: false,
    });
  }

  async function handlerConfirm(e) {
    e.stopPropagation();
    e.preventDefault();

    const data = formRef.current.getData();

    let isValid = true;

    state.requiredFields.forEach(field => {
      if (data[field] === undefined || data[field] === null) {
        formRef.current.setFieldError(field, t('RequiredField'));

        isValid = false;
      }
    });

    if (isValid) {
      let isValidData = true;

      if (
        state.onValidate &&
        state.onValidate.toString() !== 'function () {}'
      ) {
        isValidData = await state.onValidate(data);

        if (isValidData === null || isValidData === undefined) {
          isValidData = true;
        }
      }

      if (isValidData) {
        setState({
          ...state,
          isShow: false,
        });

        state.onConfirm(data, formRef);
      }
    }
  }

  function onClearFilter() {
    formRef.current.reset();
  }

  useImperativeHandle(ref, () => {
    return {
      show: ({
        title,
        data,
        fields,
        settings,
        onOpen = () => {},
        onConfirm = () => {},
        onClose = () => {},
        onValidate = () => {},
        isShowClearFields = true,
        isShowConfirm = true,
        footer = [],
        auxInfo = null,
        auxScope,
      }) => {
        const requiredFields = [];

        onOpenRef.current = onOpen;

        fields.forEach(field => {
          if (field.required) {
            requiredFields.push(field.name);
          }
        });

        setState({
          ...state,
          requiredFields,
          fields,
          onOpen,
          onConfirm,
          onClose,
          onValidate,
          data,
          title,
          settings,
          isShow: true,
          isShowClearFields,
          isShowConfirm,
          footer,
          auxInfo,
          form: formRef.current,
          auxScope,
        });
      },
      hide: () => {
        setState({
          ...state,
          isShow: false,
        });
      },
    };
  });

  return (
    <Modal isOpened={state.isShow}>
      <Container>
        <HeaderPage
          backgroundColor={colors.headerBg}
          color={colors.headerTitle}
          hideMainMenu
        >
          {state.title}
        </HeaderPage>
        <Content>
          <Form
            enableAuxSettings={false}
            ref={formRef}
            data={state.data}
            errors={state.errors}
            onOpen={() => {
              if (state.isShow) {
                setTimeout(() => {
                  const modalData = formRef.current.getData();
                  state.onOpen({ form: formRef.current, formData: modalData });
                }, 400);
              }
            }}
          >
            <ColumnLayout>
              <Panel width="350px">
                {state.fields.map((field, idx) => {
                  const title =
                    field.label ||
                    t(field.translateKey || field.name, field.translateRoute);

                  field.title = title;

                  return (
                    <Field
                      {...field}
                      auxScope={state.auxScope}
                      key={`${field.name}`}
                      target={field}
                      formRef={formRef}
                      autoFocus={idx === 0}
                      onChange={async e => {
                        if (field.onChange) {
                          const formData = formRef.current.getData();
                          const settings = state.settings || {};
                          await field.onChange({
                            data: e,
                            form: formRef.current,
                            formData,
                            ...settings.dynamicFunctionProps,
                          });
                        }
                      }}
                      onSelectedSymbol={async selectedSymbol => {
                        const formData = formRef.current.getData();
                        const settings = state.settings || {};
                        await field.onSelectedSymbol({
                          form: formRef.current,
                          formData,
                          ...settings.dynamicFunctionProps,
                          selectedSymbol,
                        });
                      }}
                    />
                  );
                })}
              </Panel>
            </ColumnLayout>
          </Form>
        </Content>
        {state.auxInfo}
        <FooterPage>
          {state.footer.map(element => {
            return element;
          })}
          {state.isShowClearFields && (
            <Button type="button" onClick={onClearFilter}>
              {t('Clear')}
            </Button>
          )}
          <FlexSpace />
          {state.isShowConfirm && (
            <Button
              buttonType="Emphasized"
              type="button"
              onClick={e => {
                handlerConfirm(e);
              }}
            >
              {t('Confirm')}
            </Button>
          )}
          <Button type="button" onClick={handlerClose}>
            {t('Close')}
          </Button>
        </FooterPage>
      </Container>
    </Modal>
  );
}

export default memo(forwardRef(ModalFields));
