/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable import/no-extraneous-dependencies */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import { pdfjs } from 'react-pdf';
import App from './App';
import packageJson from '../package.json';

import * as serviceWorker from './serviceWorker';

import './locale/i18n';
// import { init as initApm } from '@elastic/apm-rum';

// const apm = initApm({
//   // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
//   serviceName: process.env.REACT_APP_APM_SERVICE_NAME,

//   // Set custom APM Server URL (default: http://localhost:8200)
//   serverUrl: process.env.REACT_APP_APM_SERVER_URL,

//   // Set service version (required for sourcemap feature)
//   serviceVersion: packageJson.version,

//   distributedTracingOrigins: [
//     process.env.REACT_APP_SERVER_URL,
//     process.env.REACT_APP_SERVER_URL_SOCKET,
//   ],
// });

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

global.appVersion = packageJson.version;

ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
  <ToastContainer autoClose={5000} />,
  document.getElementById('messages')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
