/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState } from 'react';

import { AiOutlineEyeInvisible } from '@react-icons/all-files/ai/AiOutlineEyeInvisible';
import { AiOutlineEye } from '@react-icons/all-files/ai/AiOutlineEye';
import { FiSave as SaveIcon } from '@react-icons/all-files/fi/FiSave';

import colors from '~/styles/colors';

import CodeEditor from '~/easy-components/CodeEditor';

import PageContext from '../context';
import { CommandPanel } from '../styles';

function QueriesTab() {
  const {
    t,
    widgetSettings,
    setWidgetSettings,
    query,
    setQuery,
    onCtrlSave,
  } = useContext(PageContext);

  const [isDefaultVisible, setIsDefaultVisible] = useState(true);

  return (
    <CommandPanel>
      <div>
        <CodeEditor
          onCtrlS={onCtrlSave}
          title={t('Settings')}
          editorType="json"
          value={widgetSettings}
          onChange={setWidgetSettings}
          buttons={[
            <div
              key="save"
              data-tip={t('Save')}
              data-for="toolbarTooltipQueries"
              onClick={onCtrlSave}
            >
              <SaveIcon size={24} color={colors.mainMenuIcons} />
            </div>,
            <div
              key="visible"
              data-tip={t('DefaultSettings')}
              data-for="toolbarTooltipQueries"
              onClick={() => setIsDefaultVisible(!isDefaultVisible)}
            >
              {isDefaultVisible ? (
                <div
                  data-tip={t('HideDefaultSetting')}
                  data-for="toolbarTooltipQueries"
                >
                  <AiOutlineEyeInvisible
                    size={24}
                    color={colors.mainMenuIcons}
                  />
                </div>
              ) : (
                <div
                  data-tip={t('ShowDefaultSetting')}
                  data-for="toolbarTooltipQueries"
                >
                  <AiOutlineEye size={24} color={colors.mainMenuIcons} />
                </div>
              )}
            </div>,
          ]}
        />
      </div>
      {isDefaultVisible && (
        <div>
          <CodeEditor
            onCtrlS={onCtrlSave}
            title={t('Queries')}
            editorType="sql"
            value={query}
            onChange={setQuery}
          />
        </div>
      )}
    </CommandPanel>
  );
}
export default QueriesTab;
