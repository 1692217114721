/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Panel from '~/easy-components/Panel';
import Field from '~/easy-components/Form/UserField/Field';
import TextArea from '~/easy-components/Form/TextArea';
import useUiDesigner from '~/hooks/useUiDesigner';
import { Comments } from '../styles';
import { TitleColumn } from './styles';

// eslint-disable-next-line react/prop-types
function CustomTab({
  name,
  baseName,
  settings,
  formRef,
  mainFormRef,
  isReadOnly,
  columns,
  textArea,
  textAreaName,
  auxScope,
  pageId,
  t,
}) {
  const { viewMode, showContextMenu } = useUiDesigner({
    pageId,
    componentType: 'tabColumn',
    customTabName: name,
  });

  return textArea ? (
    <Comments>
      <TextArea
        name={textAreaName}
        settings={settings}
        formRef={formRef}
        readOnly={isReadOnly}
        customTabName={name}
        isUserField
      />
    </Comments>
  ) : (
    <ColumnLayout>
      {!textArea &&
        columns.map((col, idx) => {
          return (
            <Panel
              key={idx}
              width={`${col.width}px`}
              style={{ marginBottom: viewMode === 'design' ? '30px' : null }}
            >
              {viewMode === 'design' && (
                <TitleColumn
                  onContextMenu={event => {
                    showContextMenu({
                      event,
                      columnIndex: idx,
                      column: col,
                    });
                  }}
                >
                  {t('Column')} - {idx + 1}
                </TitleColumn>
              )}
              {col.fields.map(userField => {
                const fieldSettings = {
                  labelWidth: col.labelWidth,
                  width: col.width,
                  ...userField,
                };

                return (
                  <Field
                    baseName={baseName}
                    key={fieldSettings.name}
                    target={fieldSettings}
                    mainFormRef={mainFormRef}
                    formRef={formRef}
                    settings={settings}
                    readOnly={isReadOnly}
                    auxScope={auxScope}
                    customTabName={name}
                    isCustomTab
                    isUserField
                  />
                );
              })}
            </Panel>
          );
        })}
    </ColumnLayout>
  );
}

export default CustomTab;
