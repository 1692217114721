import RequestService from './RequestService';
import CompanyService from './CompanyService';

class MainMenuService {
  async list(filter) {
    const response = await RequestService.request({
      url: `main_menu?$filter=${filter || ''}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async listByUser(token) {
    const menus = await RequestService.request({
      url: `main_menu/user`,
      method: 'GET',
      token: token || (await CompanyService.getCompanyToken()),
    });

    return menus;
  }
}

export default new MainMenuService();
