// eslint-disable-next-line import/no-cycle
import dynamicFunction from '../dynamicFunction';

export async function formatCell({
  value,
  column,
  rowData,
  field,
  appSettings,
  auxScope,
}) {
  if (column) {
    const { settings } = column;

    if (settings) {
      if (settings.format) {
        const newValue = await dynamicFunction({
          functionString: settings.format,
          params: { value, column, data: rowData },
          settings: appSettings,
          auxScope,
        });

        return newValue;
      }
    }
  }
  if (value === undefined) {
    return ``;
  }
  return value !== null ? value.toString() : '';
}
