import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  background-color: #ffffff;
  min-width: 500px;
`;

export const Content = styled.div`
  overflow: auto;
`;

export const Item = styled.div`
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;
