import styled from 'styled-components';
import { getStatusInfo } from './components/DocumentStatus';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StatusHeader = styled.div`
  height: 4px;
  background-color: ${props => getStatusInfo(props.status).color};
`;

export const Comments = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  > div {
    padding: 8px 0 8px 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.9rem;
  }
`;
