/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import React, { useState, useCallback, useRef, useContext } from 'react';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { MdModeEdit as EditIcon } from '@react-icons/all-files/md/MdModeEdit';
import { BiTrash as RemoveIcon } from '@react-icons/all-files/bi/BiTrash';

import { isMobile } from 'react-device-detect';

import Button from '../../../../Button';
import Switch from '../../../../Switch';
import FlexSpace from '../../../../FlexSpace';
import TreatError from '../../../../TreatError';
import DebounceEvent from '../../../../DebounceEvent';
import PageHeader from '../../../../PageHeader';
import PageBody from '../../../../PageBody';
import PageFooter from '../../../../PageFooter';
import Loading from '../../../../Loading';
import AddNew from './AddNew';
import PageContext from '../../context';

import {
  Container,
  SearchPanel,
  ButtonIcon,
  Content,
  ItemElement,
  InputSearch,
  Page,
  Footer,
} from './styles';

export default function Detail({
  t,
  title,
  onConfirm,
  onClose,
  fixedData,
  renderItem,
  selValues = [],
  propFieldLabel,
  propFieldValue,
  values,
  canAddNew,
  // canEdit,
  // canDelete,
  onEdit,
  onDelete,
}) {
  const inputRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(fixedData);
  const [selectedValues, setSelectedValues] = useState(selValues);

  const { setOptions, settings, onRemove } = useContext(PageContext);

  const { showModalFields } = settings.dynamicFunctionProps;

  const refresh = useCallback(
    async filter => {
      try {
        if (filter) {
          setIsLoading(true);

          const filterData = fixedData.filter(
            d =>
              d[propFieldLabel].toUpperCase().indexOf(filter.toUpperCase()) >= 0
          );

          setData(filterData);

          setIsLoading(false);
        } else {
          setData(fixedData);
        }
      } catch (err) {
        TreatError.showError(err);
        setIsLoading(false);
      }
    },
    [fixedData, propFieldLabel]
  );

  const onClear = e => {
    e.stopPropagation();
    // setDisplayText('');
    inputRef.current.value = null;
    refresh(null);
  };

  const onClickItem = item => {
    const filtereds = selectedValues.filter(v => v === item[propFieldValue]);
    if (filtereds.length > 0) {
      const list = selectedValues.filter(v => v !== item[propFieldValue]);
      setSelectedValues([...list]);
    } else {
      setSelectedValues([...selectedValues, item[propFieldValue]]);
    }
  };

  const onAddNew = async e => {
    e.stopPropagation();
    const inputValue = inputRef.current.value;
    showModalFields({
      title: 'New Record',
      fields: [
        {
          name: 'value',
          type: 'text',
          label: 'Código',
          defaultValue: inputValue,
        },
        {
          name: 'label',
          type: 'text',
          label: 'Descrição',
          defaultValue: inputValue,
        },
      ],
      onClose: () => {},
      onConfirm: async modalFieldData => {
        try {
          setIsLoading(true);
          if (modalFieldData) {
            const hasField = fixedData.find(
              elem => elem.value === modalFieldData.value
            );

            if (!hasField) {
              const newElement = {
                value: modalFieldData.value,
                label: modalFieldData.label,
              };

              const mergedElements = [...fixedData, newElement];

              setData(mergedElements);
              setOptions(mergedElements);
            }
          }
        } catch (error) {
          TreatError.showError(error);
        } finally {
          setIsLoading(false);
        }
      },
    });
  };
  const showEditButtons = () => {
    if (selectedValues.length === 1) {
      return (
        <>
          <ButtonIcon
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              showModalFields({
                title: 'Edit Record',
                fields: [
                  {
                    name: 'value',
                    type: 'text',
                    label: 'Código',
                    defaultValue: selectedValues,
                  },
                  {
                    name: 'label',
                    type: 'text',
                    label: 'Descrição',
                    defaultValue: selectedValues,
                  },
                ],
                onClose: () => {},
                onConfirm: async modalFieldData => {
                  try {
                    setIsLoading(true);
                    const hasField = fixedData.find(
                      elem => elem.value === modalFieldData.value
                    );
                    if (!hasField) {
                      onRemove(selectedValues[0]);

                      fixedData.forEach(elem => {
                        if (elem.value === selectedValues[0]) {
                          elem.value = modalFieldData.value;
                          elem.name = modalFieldData.value;
                          elem.label = modalFieldData.label;
                        }
                      });

                      const mergedElements = [...fixedData];

                      setData(mergedElements);
                      setOptions(mergedElements);
                      onEdit({
                        oldName: selectedValues[0],
                        newName: modalFieldData.value,
                      });
                      onConfirm([]);
                    }
                  } catch (error) {
                    TreatError.showError(error);
                  } finally {
                    setIsLoading(false);
                  }
                },
              });
            }}
          >
            <EditIcon size={25} color="#449dc1" />
          </ButtonIcon>
          <ButtonIcon
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setSelectedValues([]);
              onConfirm([]);
              onDelete([selectedValues]);
            }}
          >
            <RemoveIcon size={28} color="#ff9999" />
          </ButtonIcon>
        </>
      );
    }
    return null;
  };
  return (
    <Container
      onEscapeOutside={() => {
        // onClose();
      }}
    >
      <>
        <PageHeader>{title}</PageHeader>
        <Page>
          <SearchPanel>
            <Switch
              mobile={
                <Button
                  buttonType="Emphasized"
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedValues(values);
                    onClose();
                  }}
                >
                  Fechar
                </Button>
              }
            />

            <InputSearch
              ref={inputRef}
              flexible
              hideBorder
              placeholder={t('Search')}
              onChange={DebounceEvent(e => {
                refresh(e.target.value);
              })}
              style={{
                height: '44px',
                paddingLeft: '20px',
                border: '0',
              }}
            />
            <ButtonIcon
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                onClear(e);
              }}
              style={{ width: '30px', height: '40px' }}
            >
              <MdClose size={18} color="#AAA" />
            </ButtonIcon>
          </SearchPanel>
          {isMobile && (
            <Footer>
              <Switch
                mobile={
                  <PageFooter>
                    {showEditButtons()}
                    <FlexSpace />
                    <Button
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedValues([]);
                      }}
                    >
                      Remover Seleção
                    </Button>
                    <Button
                      buttonType="Emphasized"
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        onConfirm(selectedValues);
                      }}
                    >
                      Confirmar
                    </Button>
                  </PageFooter>
                }
              />
            </Footer>
          )}
          <Content>
            <Loading isLoading={isLoading} />
            <PageBody>
              {data && data.length <= 0 && canAddNew && (
                <AddNew title="Novo" handle={onAddNew} />
              )}
              {data &&
                data.map((item, idx) => {
                  const selected = selectedValues.includes(
                    item[propFieldValue]
                  );
                  return (
                    <ItemElement
                      selected={selected}
                      key={`${item[propFieldValue]}_${idx}`}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        onClickItem(item, selectedValues);
                      }}
                    >
                      {renderItem(item, selected)}
                    </ItemElement>
                  );
                })}
            </PageBody>
          </Content>
          <span>
            <Switch
              computer={
                <PageFooter>
                  {showEditButtons()}
                  <Button
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSelectedValues([]);
                    }}
                  >
                    Remover Todos
                  </Button>
                  <FlexSpace />
                  <>
                    <Button
                      buttonType="Emphasized"
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        onConfirm(selectedValues);
                      }}
                    >
                      Confirmar
                    </Button>

                    <Button
                      buttonType="Default"
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        onClose();
                      }}
                    >
                      Fechar
                    </Button>
                  </>
                </PageFooter>
              }
            />
          </span>
        </Page>
      </>
    </Container>
  );
}

Detail.defaultProp = {
  isMult: false,
};
