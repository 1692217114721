import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';
import { colors } from '../styles';

export const Container = styled.button`
  background-color: transparent;
  border: 1px solid;
  padding: 8px;
  border-radius: 5px;
  transition: background 0.3s, width 0.5s;
  outline: none;
  display: ${props =>
    props.hiddenProp && props.viewMode !== 'design' ? 'none' : 'flex'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  justify-content: center;

  ${props =>
    props.isUserField &&
    props.viewMode === 'design' &&
    css`
      /* background-color: #49746570 !important;
      color: #fff !important;
      border-color: #49746570 !important; */
      border-width: 3px;
    `}

  ${props =>
    props.hiddenProp &&
    props.viewMode === 'design' &&
    css`
      opacity: 0.4;
    `}

  &:disabled {
    border: 0;
    background-color: #ddd !important;
    color: #fff !important;
  }

  /* &:focus {
    border: 1px solid #00bfff !important;
  } */

  &.Emphasized {
    border-color: ${colors.main};
    background-color: ${colors.main};
    color: #fff;

    &:hover {
      background-color: ${darken(0.05, colors.main)};
    }
  }

  &.Default {
    border-color: ${colors.main};
    color: ${colors.main};

    &:hover {
      background-color: ${lighten(0.63, colors.main)};
    }
  }

  &.Icon {
    border: solid 1px rgb(68, 157, 193) !important;
    background-color: transparent !important;
    border-radius: 5px;
    padding: 5px;
    margin-right: 4px;
  }

  &.Accept {
    border-color: #107e3e;
    color: #107e3e;

    &:hover {
      background-color: ${lighten(0.65, '#107e3e')};
    }
  }

  &.Reject {
    border-color: ${colors.red};
    color: ${colors.red};
    &:hover {
      border-color: ${darken(0.2, colors.red)};
      color: ${darken(0.2, colors.red)};
    }
  }

  ${props =>
    props.color &&
    css`
      color: ${props.color};
      border-color: ${props.color};
    `}
`;
