/* eslint-disable react/prop-types */
import React, { forwardRef, useCallback } from 'react';
import { BiMessageSquareDetail as Icon } from '@react-icons/all-files/bi/BiMessageSquareDetail';

import { BiMessageRoundedError as ErrorIcon } from '@react-icons/all-files/bi/BiMessageRoundedError';
import { differenceInDays } from 'date-fns';

import Loading from '~/easy-components/Loading';
import useLocale from '~/hooks/useLocale';
import Message from './Message';
import { Container, GroupMessage, NoMessages, ErrorMessage } from './styles';
import useCompany from '~/hooks/useCompany';

const dayNames = [
  'Domingo',
  'Segunda-Feira',
  'Terça-Feira',
  'Quarta-Feira',
  'Quinta-Feira',
  'Sexta-Feira',
  'Sábado',
];

function Messages({ isLoading, messages, errorMessage }, ref) {
  const t = useLocale('Mentions');
  const { stringToDate, dateToString } = useCompany();

  const getDayName = useCallback(
    messageDay => {
      const today = new Date();

      const day = stringToDate(messageDay);

      const dif = differenceInDays(today, day);

      switch (dif) {
        case 0:
          return 'Hoje';

        case 1:
          return 'Ontem';

        default:
          if (dif > 6) return dateToString(day);
          return dayNames[day.getDay()];
      }
    },
    [dateToString, stringToDate]
  );

  return (
    <Container ref={ref}>
      <Loading isLoading={isLoading} />
      {errorMessage ? (
        <ErrorMessage>
          <ErrorIcon size={42} color="#ff7777" />
          {errorMessage}
        </ErrorMessage>
      ) : (
        <>
          <NoMessages isVisible={messages.length === 0 && !isLoading}>
            <Icon size={42} color="#449dc1 " />
            <div>{t('message.NoMessage')}</div>
          </NoMessages>
          {messages.map(message => {
            return (
              <GroupMessage key={message.day}>
                <h1>{getDayName(message.day)}</h1>
                {message.messages.map(msg => {
                  return (
                    <Message
                      key={msg.Code}
                      code={msg.Code}
                      objectCode={msg.objectCode}
                      keyValue={msg.keyValue}
                      keyText={msg.keyText}
                      isOwner={msg.IsOwner}
                      message={msg.Message}
                      time={msg.Time}
                      from={msg.UserName}
                      status={msg.Status}
                      mentionsInsideMessage={msg.MentionsInsideMessage || []}
                    />
                  );
                })}
              </GroupMessage>
            );
          })}
        </>
      )}
    </Container>
  );
}

export default forwardRef(Messages);
