import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
  overflow: auto;

  @media (max-width: 500px) {
    flex-direction: column;
  }

  &:first-child {
    flex: 1;
    display: flex;
  }
`;

export const Content = styled.div`
  flex: 1;
  height: 100%;
  display: flex;

  #googleMapId {
    flex-basis: 0;
    flex-grow: 4;
    height: 100%;
    flex: 1;
  }
`;

export const Actions = styled.div`
  height: 45px;
  display: none;
  flex-direction: row;

  @media (max-width: 500px) {
    display: flex;
  }
`;

export const Button = styled.button`
  flex: 1;
  border: none;
  background-color: #f0f0f0;
`;
