import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f0f0f0;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  padding: 20px;
  flex: 1;
`;

export const Status = styled.div`
  width: 6px;
  background-color: ${props => props.color};
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 0px;

  h1 {
    overflow-wrap: anywhere;
    font-weight: 600;
  }

  h2 {
    font-size: 0.9rem;
    margin-bottom: 10px;
    font-weight: normal;
    color: #999;
  }
`;
