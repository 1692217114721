import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  flex: 1;
  height: 100%;
  z-index: 10;

  .tox-statusbar {
    display: none !important;
  }

  .tox-tinymce {
    border: 0 !important;
  }

  .tox-tinymce--toolbar-sticky-off {
    height: 100% !important;
  }

  .tox-platform-touch {
    height: 100% !important;
  }
`;
