import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import SingleUpload from '~/easy-components/SingleUpload';
import {
  normalizeFileName,
  getRandomFileName,
  convertBase64ToImage,
} from '~/easy-components/Helpers/fileHandler';

function AttachmentTab({
  name,
  accept = ['image/*', '.pdf'],
  message = 'Selecione o Arquivo',
  isShowPreview = true,
  isReadOnly = false,
}) {
  const elementRef = useRef(null);

  const [attachment, setAttachment] = useState({
    fileName: null,
    file: null,
  });

  const { registerField, fieldName } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: elementRef.current,
      getValue: () => {
        return attachment;
      },
      setValue: (_, value) => {
        if (
          typeof value === 'string' ||
          value === null ||
          value === undefined
        ) {
          setAttachment({
            file: attachment.file || null,
            fileName: value || null,
          });
        } else {
          setAttachment(value);
        }
      },
    });

    return () => {};
  }, [attachment, fieldName, registerField]);

  async function onUploadFile(files) {
    const fileUploaded = files[0];

    const renamedFile = new File(
      [fileUploaded],
      getRandomFileName(fileUploaded)
    );

    const fileName = normalizeFileName(renamedFile.name);

    setAttachment({
      fileName,
      file: renamedFile,
    });
  }

  function onImageClick({ url }) {
    let imageUrl = url;

    if (attachment.file) {
      if (imageUrl.startsWith('data:image')) {
        imageUrl = convertBase64ToImage(imageUrl);
      } else {
        imageUrl = URL.createObjectURL(attachment.file);
      }
    }
    window.open(imageUrl);
  }

  return (
    <SingleUpload
      message={message}
      accept={accept}
      isShowPreview={isShowPreview}
      isReadOnly={isReadOnly}
      fileInfo={attachment}
      onUpload={onUploadFile}
      onRemove={() => {
        setAttachment({
          fileName: null,
          file: null,
        });
      }}
      onImageClick={onImageClick}
    />
  );
}

export default AttachmentTab;
