import React from 'react';
import TemplateJSX from '~/easy-components/TemplateJSX';
import { Container } from './styles';

function Element({
  line,
  col,
  colStyle,
  paddingLeft,
  onClick,
  onContextMenu,
  settings,
}) {
  if (col.jsx) {
    return (
      <TemplateJSX
        jsxString={col.jsx}
        auxScope={{ ...settings, data: line }}
        onClick={event => onClick({ line, col, event })}
        onContextMenu={event => {
          onContextMenu({ line, col, event });
        }}
      />
    );
  }

  return (
    <Container
      style={{
        backgroundColor: line.type === 'folder' ? '#e8eff3' : '#ffffff',
        ...colStyle,
        cursor: col.onClick ? 'pointer' : 'default',
        paddingLeft,
      }}
      onClick={event => {
        if (onClick) {
          onClick({ line, col, event });
        }
      }}
      onContextMenu={event => {
        if (onContextMenu) {
          onContextMenu({ line, col, event });
        }
      }}
    >
      {line[col.name]}
    </Container>
  );
}

export default Element;
