/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable default-case */
import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import TreatError from '../TreatError';
import ConfigurationService from '~/services/ConfigurationsService';
import Map from './Map';
import { Container, Content, Actions, Button } from './styles';
import Information from './Information';
import useLocale from '~/hooks/useLocale';

function GoogleMaps({ onReady, withRoute = true }, ref) {
  const t = useLocale('_Global');

  const mapRef = useRef();
  const panelMapRef = useRef();
  const panelInfoRef = useRef();
  const [settings, setSettings] = useState({});

  const initialize = useCallback(async () => {
    try {
      const configurations = await ConfigurationService.getField({
        configurationCode: 'maps',
        property: 'mapSettings',
      });
      setSettings(configurations);
    } catch (error) {
      TreatError.showError(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useImperativeHandle(ref, () => {
    return {
      setMarkers: () => {},
      setInformations: data => {
        panelInfoRef.current.setData(data);
      },
      setRoute: async route => {
        const response = await mapRef.current.setRoute(route);
        if (withRoute) panelInfoRef.current.setData(response);
        return response;
      },
      clearRoute: async () => {
        await mapRef.current.clearRoute();
      },
    };
  });

  return (
    <Container>
      {process.env.REACT_APP_GOOGLE_API_KEY && (
        <>
          <Content ref={panelMapRef}>
            <Map
              center={settings.center}
              zoom={settings.zoom}
              ref={mapRef}
              onReady={() => {
                if (onReady) {
                  onReady(ref.current);
                }
              }}
            />
          </Content>
          {withRoute && <Information ref={panelInfoRef} t={t} />}
        </>
      )}
      {withRoute && (
        <Actions>
          <Button
            onClick={() => {
              panelInfoRef.current.element.style.display = 'none';
              panelMapRef.current.style.display = 'flex';
            }}
          >
            {t('Map')}
          </Button>
          <Button
            onClick={() => {
              panelMapRef.current.style.display = 'none';
              panelInfoRef.current.element.style.display = 'flex';
            }}
          >
            {t('Information')}
          </Button>
        </Actions>
      )}
    </Container>
  );
}

export default forwardRef(GoogleMaps);
