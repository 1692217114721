import styled from 'styled-components';

export const FieldContainer = styled.div`
  display: flex;
  border: 1px solid #ddd;
  justify-content: space-between;
  padding: 8px;
  background-color: ${props => (props.readOnly ? '#fafafa' : '#fff')};
  min-width: ${props => (props.minWidth ? props.minWidth : '120px')};

  @media screen and (max-width: 768px) {
    width: 100%;
    border: none;
    color: #555;
    padding: 4px 0;
  }

  input {
    border: none !important;
    padding: 0px !important;
    text-align: right;
  }
`;
