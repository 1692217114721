/* eslint-disable react/prop-types */
// @ts-nocheck
import React, { useRef } from 'react';

import IFrame from '~/easy-components/IFrame';
import { Button, ConfirmDialog, ModalHandle } from '~/easy-components';
import ClientEventHandler from '~/applications/ClientEventHandler';
import { Container, Content, Footer } from './styles';
import WizardSignature from './Wizard';

function SignaturePage({
  t,
  settings,
  params,
  reportSettings,
  url,
  reportData,
  showLoading,
}) {
  const modalRef = useRef();
  const reportSettingsCache = useRef();

  async function sendClientEvent({ eventName, data, run }) {
    const newSettings = {
      ...reportSettings,
      dynamicFunctionProps: settings.dynamicFunctionProps,
    };

    const clientResponse = await ClientEventHandler({
      eventName,
      run,
      data,
      params: {
        settings: newSettings,
      },
    });

    return clientResponse;
  }

  async function onSend() {
    if (reportSettings.events) {
      reportSettingsCache.current = JSON.parse(JSON.stringify(reportSettings));

      const reportSettingsRef = {
        signers: [...reportSettings.signers],
        copies: [...reportSettings.copies],
        description: reportSettings.description,
        subject: reportSettings.subject,
      };

      await sendClientEvent({
        eventName: 'onSend',
        run: 'before',
        data: { params, reportSettings: reportSettingsRef },
      });

      reportSettings.signers = reportSettingsRef.signers;
      reportSettings.copies = reportSettingsRef.copies;
      reportSettings.description = reportSettingsRef.description;
      reportSettings.subject = reportSettingsRef.subject;

      modalRef.current.handleOpen();
    }
    return null;
  }

  function onCancel({ confirm = true }) {
    reportSettings.signers = reportSettingsCache.current.signers;
    reportSettings.copies = reportSettingsCache.current.copies;
    reportSettings.description = reportSettingsCache.current.description;
    reportSettings.subject = reportSettingsCache.current.subject;

    if (confirm) {
      ConfirmDialog({
        title: t('Signatures'),
        message: 'Deseja cancelar operação ?', // TODO: Tradução pendente
        buttons: [
          {
            label: t('Yes'),
            onClick: async () => {
              modalRef.current.handleClose();
            },
          },
          {
            label: t('No'),
            onClick: async () => {},
          },
        ],
      });
    } else {
      modalRef.current.handleClose();
    }
  }

  return (
    <>
      <Container>
        <Content>
          <IFrame
            src={url}
            // src={`${process.env.REACT_APP_SERVER_URL}/pdf/view/${token}/proposta.pdf`}
          />
        </Content>
        <Footer>
          <Button onClick={() => onSend()} disabled={!url}>
            Enviar
          </Button>
        </Footer>
      </Container>
      <ModalHandle
        ref={modalRef}
        contentStyle={{
          style: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      >
        <WizardSignature
          t={t}
          settings={settings}
          showLoading={showLoading}
          reportSettings={reportSettings}
          onCancel={onCancel}
          documentUrl={url}
          reportData={reportData}
        />
      </ModalHandle>
    </>
  );
}

export default SignaturePage;
