import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 2px;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  :last-child {
    margin-bottom: 0;
  }

  input {
    width: 20px;
  }
`;
export const Title = styled.p`
  color: #555;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;
