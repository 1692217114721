import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: ${props =>
    props.componentView === 'globalFunctions' ? 'flex' : 'none'};
  background-color: #222;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const Code = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Empty = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #aaaaaa;
`;
