/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import QueryService from '../../../services/QueryService';
import Linker from '../Linker';
import useLocale from '~/hooks/useLocale';

function QueryField({ sql, queryCode, ...props }) {
  const t = useLocale('_Global');

  function getMergeData(filter) {
    const { mainFormRef, formRef, disableUnform, dataToReplace = {} } = props;

    if (disableUnform) {
      return { ...dataToReplace, filter };
    }

    let mainData = null;
    if (mainFormRef && mainFormRef.current)
      mainData = mainFormRef.current.getData();

    let formData = null;
    if (formRef && formRef.current) formData = formRef.current.getData();

    const mergeData = {
      filter,
      ...mainData,
      ...formData,
    };

    return mergeData;
  }

  function replacePlaceholders(filter, commandQuery) {
    let command = commandQuery;

    const re2 = new RegExp(`#<`, 'g');
    command = command.replace(re2, '%<');

    const re3 = new RegExp(`>#`, 'g');
    command = command.replace(re3, '>%');

    /* const { mainFormRef, formRef } = props;

    let mainData = null;
    if (mainFormRef && mainFormRef.current)
      mainData = mainFormRef.current.getData();

    let formData = null;
    if (formRef && formRef.current) formData = formRef.current.getData();

    const mergeData = {
      filter,
      ...mainData,
      ...formData,
    }; */

    const mergeData = getMergeData(filter);

    const commandParams = command.match(/<[^>]+>/g) || [];

    commandParams.forEach(param => {
      const paramNotBlackets = param.substring(1, param.length - 1);

      if (mergeData[paramNotBlackets] || paramNotBlackets === 'filter') {
        const re = new RegExp(`<${paramNotBlackets}>`, 'gi');

        command = command.replace(
          re,
          mergeData[paramNotBlackets] ||
            (paramNotBlackets === 'filter' ? '' : null)
        );
      }
    });

    // Altera a string 'null' para um valor null
    const reNull = new RegExp(`'null'`, 'g');
    command = command.replace(reNull, null);

    return command;
  }
  return (
    <Linker
      {...props}
      method={async filter => {
        if (props.method) {
          return props.method(filter);
        }

        if (sql) {
          const command = replacePlaceholders(filter, sql);

          const response = await QueryService.execute(1, command);

          return response;
        }

        if (queryCode) {
          const mergeData = getMergeData(filter);

          const response = await QueryService.executeByCode(
            queryCode,
            mergeData
          );

          return response;
        }

        throw new Error(t('error.QueryCodeOrCommand'));
      }}
    />
  );
}

export default QueryField;
