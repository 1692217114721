import styled from 'styled-components';

export const Container = styled.div`
  color: #333;
  min-width: ${props => props.width}px;
  max-width: ${props => props.width}px;
  background-color: #f0f0f0;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 10px 10px;
  transition: 0.4s;

  > h1 {
    margin-bottom: 10px;
  }

  &.board-enter {
    background-color: #e5e5e5;
  }

  @media (max-width: 500px) {
    flex: 1;
    min-width: 100%;
    padding: 0 0 0 0;
  }
`;

export const Content = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  overflow: none;
  max-height: 100%;
  overflow-y: auto;

  /* &:hover {
    overflow-y: overlay;
  } */

  > div:first-child {
    margin-top: 10px;
  }

  > div:last-child {
    margin-bottom: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 500px) {
    background: #fafafa;
    padding: 0 10px;
    border-bottom: 2px solid #ddd;
    margin-bottom: 0px;

    section {
      padding: 10px;
    }
  }

  @media (min-width: 500px) {
    > svg {
      display: none !important;
    }
  }

  section {
    flex: 1;
  }
`;

export const Bar = styled.div`
  position: relative;
  height: 6px;
  background-color: #ddd;
  display: flex;
  border-radius: 2px;
  margin-top: 2px;

  &::before {
    content: '';
    width: ${props => `${props.percent}%`};
    border-radius: 2px;
    background-color: ${props =>
      `hsl(calc(${props.percent} * 1.2) , 40%, 50%)`};
    transition: width 2s, background-color 1s;
  }

  span {
    position: absolute;
    left: 50%;
    margin-left: -5px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;

  svg {
    cursor: pointer;
  }
`;
