/* eslint-disable import/no-cycle */
import React, {
  useEffect,
  useRef,
  memo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import EventModal from '~/hooks/useUiDesigner/Modals/EventModal';
import { Container } from './styles';

function EventButton({ title, eventName, event }, ref) {
  const eventModalRef = useRef();

  useEffect(() => {
    eventModalRef.current.show(event.event);
  }, [event]);

  const setVisible = visible => {
    eventModalRef.current.setVisible(visible);
  };

  useImperativeHandle(ref, () => {
    return {
      setVisible,
    };
  });

  return (
    <>
      <Container type="button" onClick={setVisible}>
        <h1>{title}</h1>
        <h2>| {eventName}</h2>
      </Container>
      <EventModal ref={eventModalRef} />
    </>
  );
}

export default memo(forwardRef(EventButton));
