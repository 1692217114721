import styled from 'styled-components';

export const Container = styled.section`
  padding: 20px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const GroupMessage = styled.section`
  display: flex;
  flex-direction: column;

  > h1 {
    background-color: #f0f0f0;
    padding: 10px 20px;
    margin-bottom: 10px;
    text-align: center;
    align-self: center;
    border-radius: 10px;
    font-weight: 400;
    font-size: 0.9rem;
    color: #888;
  }
`;

export const NoMessages = styled.div`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    margin-top: 10px;
    color: #777;
    text-align: center;
  }
`;

export const ErrorMessage = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ff7777;

  svg {
    margin-bottom: 10px;
  }
`;
