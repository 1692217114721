/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import BaseMasterDataService from './BaseMasterDataService';
import request from '../../Request';

class TemplatesService extends BaseMasterDataService {
  constructor() {
    super({ route: 'Templates', primaryKeyField: 'id', isLargeData: true });
  }

  async list({ isSaveMode, route }) {
    return request({
      url: `APP/CRM/${this.route}?$isSaveMode=${isSaveMode}&$route=${route}`,
    });
  }
}

export default new TemplatesService();
