/* eslint-disable react/prop-types */
// @ts-check

import React, { useRef } from 'react';
import Icon from '~/easy-components/Icon';

import { Container, ClickableArea } from './styles';

/**
 * @typedef {"emptyCSV"} ImportClipboardErrors
 *
 * @typedef {Object} ImportColumn
 * @property {string} key
 * @property {string} label
 *
 * @typedef {Object} Props
 * @property {ImportColumn[]} importColumns
 * @property {() => Promise<void>} onExportStart
 * @property {() => Promise<void>} onExportSuccess
 * @property {(errorString: ImportClipboardErrors) => Promise<void>} onImportError
 * @property {(newData: {[k: string]: string}[]) => any} onImportSuccess
 * @property {boolean} [hasDivision=true]
 * @property {(text: string) => string} t
 */

/** @type {React.FC<Props>} */
const ImportationTool = ({
  importColumns,
  onExportStart,
  onExportSuccess,
  onImportError,
  onImportSuccess,
  hasDivision = true,
  t,
}) => {
  /** @type {import('react').MutableRefObject<HTMLInputElement>} */
  const uploadInputRef = useRef();

  const exportModelToClipboard = async () => {
    await onExportStart();

    const labels = importColumns.map(importColumn => importColumn.label);

    const modelTsv = labels.join('\t');

    if (navigator.clipboard) {
      await navigator.clipboard.writeText(modelTsv);
    }

    await onExportSuccess();
  };

  const mountLineEntries = header => (entries, colValue, index) => {
    const lineColumn = importColumns.find(col => col.label === header[index]);

    if (!lineColumn) return entries;

    return [...entries, [lineColumn.key, colValue]];
  };

  /** @type {(header: any) => (line: string[]) => any} */
  const transformLineInObject = header => line => {
    const entries = line.reduce(mountLineEntries(header), []);

    return Object.fromEntries(entries);
  };

  /**
   * @param {string} csv
   * @returns {Promise<void>}
   */
  const readCsv = async csv => {
    if (!csv) {
      await onImportError('emptyCSV');
      return;
    }

    const stringLines = csv.split('\n');

    const allLines = stringLines.map(line =>
      line.replace(/\r/g, '').split(/[,;]/g)
    );

    const lines = allLines.filter(line =>
      line.some(col => !!col && col !== '\r')
    );

    const header = lines.shift();

    const newData = lines.map(transformLineInObject(header));

    await onImportSuccess(newData);
  };

  /* const replaceAll = (string, searchValue, replaceValue) => {

    const newString = string.replace(searchValue, replaceValue);

    const hasSearchValue = newString.includes(searchValue);

    if (hasSearchValue) return replaceAll(newString, searchValue, replaceValue);

    return newString;
  }; */

  const importFromClipboard = async () => {
    const tsv = navigator.clipboard ? await navigator.clipboard.readText() : '';

    const csvReplaced = tsv.replace(/\t/g, ';');

    // const csv = replaceAll(csvReplaced, '\t', ';');

    await readCsv(csvReplaced);
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  const importFromFile = async e => {
    const { files } = e.target;

    const file = files.item(0);

    const csv = await file.text();

    await readCsv(csv);
  };

  return (
    <Container
      // @ts-ignore
      hasDivision={hasDivision}
    >
      <input
        type="file"
        hidden
        accept=".csv"
        ref={uploadInputRef}
        onChange={importFromFile}
      />

      <ClickableArea
        type="button"
        onClick={exportModelToClipboard}
        data-tip={t('CopyCSVModel')}
        data-for="json-table-tooltip"
      >
        <Icon name="FiCopy" size={28} color="#353c44" />
      </ClickableArea>
      <ClickableArea
        type="button"
        onClick={importFromClipboard}
        data-tip={t('PasteCSV')}
        data-for="json-table-tooltip"
      >
        <Icon name="MdContentPaste" size={28} color="#353c44" />
      </ClickableArea>
      <ClickableArea
        type="button"
        onClick={() => uploadInputRef.current.click()}
        data-tip={t('UploadCSV')}
        data-for="json-table-tooltip"
      >
        <Icon name="BsUpload" size={28} color="#353c44" />
      </ClickableArea>
    </Container>
  );
};

export default ImportationTool;
