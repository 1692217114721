/* eslint-disable import/no-cycle */
/* eslint-disable no-new-func */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, memo, useState } from 'react';
import { useField } from '@unform/core';
import { TiUpload as IconUpload } from '@react-icons/all-files/ti/TiUpload';
import { TiDelete as IconDelete } from '@react-icons/all-files/ti/TiDelete';
import { isMobile } from 'react-device-detect';
import AttachmentModal from '~/components/AttachmentModal';
import clientEvent from '~/applications/ClientEventHandler';
import AttachmentService from '~/services/AttachmentService';
import useLocale from '~/hooks/useLocale';
import DownloadButton from '~/components/DownloadButton';
import TreatError from '~/easy-components/TreatError';
import {
  normalizeFileName,
  splitFileName,
  splitSourcePath,
} from '~/easy-components/Helpers/fileHandler';
import CustomEvent from '~/easy-components/Form/components/CustomEvent';
import useUiDesigner from '~/hooks/useUiDesigner';
import useFieldBase from '~/hooks/useField';
import UserField from '../UserField';
import { PanelInput, IconPanel, IconsContainer } from './styles';
import { FieldContainer, Title, Input } from '../styles';

function Attachment({
  baseName,
  name,
  label,
  hidden,
  formRef,
  mainFormRef,
  settings,
  labelWidth,
  readOnlyProp,
  placeholder,
  onGetDownloadToken: customGetDownloadToken = false,
  uploadOn,
  deleteOn,
  onUploadSuccess,
  onUploadFail,
  onDeleteSuccess,
  required = false,
  auxScope,
  isUserField,
  customTabName,
  ...rest
}) {
  const [isUpdate, setIsUpdate] = useState(false);
  const attachModalRef = useRef();
  const linkRef = useRef({
    token: null,
    fileName: null,
    sourcePath: null,
  });

  const t = useLocale('FormAttachment');

  const {
    selfField,
    viewMode,
    showContextMenu,
    HasEventComponent,
  } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'field',
    settings,
    baseName,
    name,
    title: label,
    isUserField,
    customTabName,
  });

  const attachmentService = new AttachmentService();

  const fieldTitle = selfField.title || label;

  const style = selfField.style || {};

  const inputRef = useRef(null);

  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  const { isHidden, isRequired, isReadOnly } = useFieldBase({
    hidden,
    readOnly: readOnlyProp,
    required,
    selfField,
    formRef,
    inputRef,
    getLogData: () => {
      return {
        baseName,
        name: fieldName,
        value: inputRef.current.value,
      };
    },
  });

  const openAttachmentModal = () => {
    if (attachModalRef.current) {
      attachModalRef.current.show({
        title: t('Attachment'),
        message: t('message.SelectOrDrag'),
        onUpload: files => {
          const fileData = files[0];

          attachmentService
            .uploadFile({ fileData, url: uploadOn })
            .then(
              async ({ token, sourcePath }) => {
                const eventParams = {
                  fileName: normalizeFileName(fileData.name),
                  formRef,
                  mainFormRef,
                  jsonStringfy: value => JSON.stringify(value),
                  settings: {
                    ...settings,
                    ...selfField,
                  },
                };
                await clientEvent({
                  run: 'before',
                  params: eventParams,
                  eventName: 'onUpload',
                });
                formRef.current.setFieldValue(fieldName, fileData.name);

                linkRef.current = {
                  token,
                  fileName: fileData.name,
                  sourcePath,
                };

                if (onUploadSuccess) {
                  onUploadSuccess({ token, sourcePath });
                }

                await clientEvent({
                  run: 'after',
                  params: eventParams,
                  eventName: 'onUpload',
                });
              },
              async err => {
                const eventParams = {
                  error: err,
                  fileName: fileData.name,
                  formRef,
                  mainFormRef,
                  settings: {
                    ...settings,
                    ...selfField,
                  },
                };
                await clientEvent({
                  run: 'before',
                  params: eventParams,
                  eventName: 'onError',
                });

                if (onUploadFail) {
                  onUploadFail();
                }
              }
            )
            .catch(async err => {
              const eventParams = {
                error: err,
                fileName: fileData.name,
                formRef,
                mainFormRef,
                settings: {
                  ...settings,
                  ...selfField,
                },
              };

              await clientEvent({
                run: 'before',
                params: eventParams,
                eventName: 'onError',
              });
            })
            .finally(() => {
              if (attachModalRef.current) {
                attachModalRef.current.close();
              }
            });
        },
        isReadOnly,
      });
    }
  };

  const getDownloadToken = async () => {
    if (customGetDownloadToken) return customGetDownloadToken();

    let { token, fileName } = linkRef.current;

    fileName = fileName || splitFileName(defaultValue);

    if (!token) {
      const sourcePath = splitSourcePath(defaultValue);

      token = await attachmentService.getToken({ fileName, sourcePath });
    }

    return token;
  };

  const onDelete = async () => {
    const eventParams = {
      formRef,
      mainFormRef,
      settings: {
        ...settings,
        ...selfField,
      },
    };

    await clientEvent({
      run: 'before',
      params: eventParams,
      eventName: 'onDelete',
    });

    if (deleteOn) {
      try {
        await attachmentService.deleteFile({ url: deleteOn });
      } catch (err) {
        TreatError.showError(err);
        return false;
      }
    }

    setIsUpdate(false);
    formRef.current.setFieldValue(fieldName, '');

    await clientEvent({
      run: 'after',
      params: eventParams,
      eventName: 'onDelete',
    });

    if (onDeleteSuccess) {
      onDeleteSuccess();
    }

    return true;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue: el => {
        return el.value === '' ? null : el.value;
      },
      setValue: (el, v) => {
        el.value =
          v !== 'undefined' && v !== undefined ? splitFileName(v) : null;
        setIsUpdate(!!el.value);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputRef.current && inputRef.current.value) {
      setIsUpdate(!!inputRef.current.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfField]);

  return (
    <>
      <FieldContainer
        hidden={isHidden}
        viewMode={!hidden || isUserField ? viewMode : 'normal'}
        onContextMenu={event => showContextMenu({ event })}
        readOnly={isReadOnly}
        style={
          isMobile
            ? {
                ...(selfField && selfField.style ? selfField.style : {}),
                ...(rest && rest.style ? rest.style : {}),
              }
            : {}
        }
      >
        {fieldTitle && (
          <Title
            highlight={isUserField && viewMode === 'design'}
            labelWidth={
              (selfField && selfField.labelWidth) || labelWidth || 130
            }
          >
            <div className="title">
              <div className="required-field">
                {fieldTitle}
                {isRequired && <b>*</b>}
              </div>
              <div className="title-icon">
                <CustomEvent
                  settings={settings}
                  selfField={selfField}
                  inputRef={inputRef}
                  formRef={formRef}
                  mainFormRef={mainFormRef}
                  auxScope={auxScope}
                />
              </div>
              <HasEventComponent selfField={selfField} viewMode={viewMode} />
            </div>
            {error && <div className="error">{error}</div>}
          </Title>
        )}
        <PanelInput>
          <div>
            <Input
              ref={inputRef}
              id={fieldName}
              defaultValue={splitFileName(defaultValue)}
              style={style}
              {...rest}
              hidden={false}
              readOnly
              placeholder={placeholder}
            />

            {error && <span style={{ color: '#f00' }}>{error}</span>}
            {isUpdate && (
              <IconsContainer>
                {/* <IconPanel onClick={() => onDownload()}>
                  <IconDownload size={14} color="#3B8FB1" />
                </IconPanel> */}
                <DownloadButton
                  fileName={
                    linkRef.current.fileName ||
                    splitFileName(
                      defaultValue || formRef.current.getFieldValue(fieldName)
                    )
                  }
                  onGetToken={getDownloadToken}
                />
                {!isReadOnly && (
                  <IconPanel onClick={() => onDelete()}>
                    <IconDelete size={18} color="#ff9191" />
                  </IconPanel>
                )}
              </IconsContainer>
            )}

            {!isUpdate && !isReadOnly && (
              <IconsContainer>
                <IconPanel onClick={() => openAttachmentModal()}>
                  <IconUpload size={14} color="#3B8FB1" />
                </IconPanel>
              </IconsContainer>
            )}
          </div>
        </PanelInput>
      </FieldContainer>

      <UserField
        baseName={baseName}
        fieldName={fieldName}
        formRef={formRef}
        mainFormRef={mainFormRef}
        settings={settings}
        labelWidth={(selfField && selfField.labelWidth) || labelWidth || 130}
      />

      <AttachmentModal
        name={fieldName}
        settings={settings}
        auxScope={{
          form: formRef.current,
          mainForm: mainFormRef ? mainFormRef.current : null,
        }}
        ref={attachModalRef}
      />
    </>
  );
}

export default memo(Attachment);
