/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState, createContext } from 'react';
import MasterDetail from '~/components/Pages/MasterDetail';
import Form from '~/easy-components/Form';
import HeaderContent from '~/easy-components/HeaderContent';
import Button from '~/easy-components/Button';
import TabContent from '~/easy-components/TabContent';
import FlexSpace from '~/easy-components/FlexSpace';
import TreatError from '~/easy-components/TreatError';
import Toast from '~/easy-components/Toast';
import UserPageService from '~/applications/CRM/services/UserPageService';
import useLocale from '~/hooks/useLocale';
import useStateCache from '~/hooks/useStateCache';
import useHelpers from '~/hooks/useHelpers';
import { updateSettings as updateSettingsAction } from '~/store/modules/uiDesign/actions';
import useProjectLinker from '~/easy-components/Helpers/linkProject';
import ListItem from './components/ListItem';
import GeneralTab from './tabs/General';
import RemarksTab from './tabs/Remarks';
import SettingsTab from './tabs/Settings';
import TabUsersPermissions from '../../components/UsersPermissions';
import { StatusHeader } from './styles';

export const PageContext = createContext();

function UserPages({ settings, initialData, ...props }) {
  const { dynamicFunctionProps } = useHelpers();
  const {
    dispatch,
    showLoading,
    showPage,
    showModalFields,
    isReadOnly,
  } = dynamicFunctionProps;

  const t = useLocale('UserPages', settings.translations);
  const tMenus = useLocale('MainMenu');

  const formRef = useRef();
  const masterDetailRef = useRef();
  const usersPermissionsRef = useRef();
  const tabContentRef = useRef();

  const [header, setHeader] = useState({});
  const [menus, setMenus] = useState([]);
  const [pageSettings, setPageSettings, getPageSettings] = useStateCache(null);
  const [isSettingsReadOnly, setIsSettingsReadOnly] = useState(false);
  const [formData, setFormData] = useState({});

  const [errors, setErrors] = useState({});
  const [isShowOpen, setIsShowOpen] = useState(false);
  const [state, setState] = useState({
    external_hidden: true,
    internal_hidden: true,
    userTableHidden: true,
    udoHidden: true,
  });

  const { linkProject } = useProjectLinker({
    type: 'userPage',
    showModalFields,
    showLoading,
    keyCode: '',
  });

  const defaultSettings = `{
    "pageName": "Minha Página",
    "keyField": "CardCode",
    "queryList": "Select top 3 \\"CardCode\\", \\"CardName\\" from OCRD where UPPER(\\"CardCode\\") like '#<filter>#'",
    "queryGet": "select \\"CardCode\\", \\"CardName\\" from OCRD where \\"CardCode\\" = '<CardCode>'",
    "queryAux": [
      {
        "prop": "Addresses",
        "query": "select \\"Street\\", \\"State\\" from CRD1 where \\"CardCode\\" = '<CardCode>'"
      }
    ],
    "listTemplateStyle": {
        "borderBottom": "1px solid #DDD"
    },
    "listTemplate": "<div class=\\"main\\">{CardCode}</div><div class=\\"small\\">{CardName}</div>",
    "headerTitle": "CardCode",
    "headerDescription": "CardName",
    "headerAttributes": [
      "CardType"
    ],
    "headerInformations": [
      "CardFName"
    ],
    "components": [
      {
        "type": "tab",
        "name": "tabGeneral",
        "icon": "AiOutlineInfoCircle",
        "title": "Geral",
        "columns": [
          {
            "width": 420,
            "labelWidth": 210,
            "fields": [
              {
                "name": "CardCode",
                "title": "Código do Parceiro",
                "type": "string"
              },
              {
                "name": "CardName",
                "title": "Nome do Parceiro",
                "type": "string"
              },
              {
                "name": "U_SPS_SubType",
                "title": "Sub Tipo",
                "type": "queryField",
                "queryCode": "Clic_SubType_Search"
              }
            ]
          }
        ]
      },
      {
        "type": "tabList",
        "name": "tabAddress",
        "icon": "MdLocationOn",
        "title": "Endereços",
        "fieldName": "Addresses",
        "listTemplate": "<div>{Street} {State}</div>",
        "columns": [
          {
            "width": 420,
            "labelWidth": 210,
            "fields": [
              {
                "name": "Street",
                "title": "Endereço",
                "type": "string"
              },
              {
                "name": "State",
                "title": "UF",
                "type": "string"
              }
            ]
          }
        ]
      }
    ],
    "fields": [
      {
        "name": "CardCode",
        "events": [
          {
            "name": "onchange",
            "run": "after",
            "handler": "alert('evento alterar campo CardCode');"
          }
        ]
      }
    ],
    "events": [
      {
        "name": "onLoadData",
        "handler": "
        "
      },
      {
        "name": "onSave",
        "handler": "
        "
      },
      {
        "name": "onDelete",
        "handler": "
            alert('teste do onDelete retornando false');
            return false;
        "
      },
      {
          "name": "onRefreshMenu",
          "handler": "
              scope.data.push({
                  id: 'editMenu',
                  text: 'Novo Menu',
                  disabled: false,
                  icon: 'FaEdit',
                  visible: true,
                  handler: () => {
                      alert('clicou no novo menu');
                  }
              });
          "
      }
    ]
}`;

  const onSelectedItem = async ({ selectedItem }) => {
    try {
      const userPage = await UserPageService.get(selectedItem.id);
      return userPage;
    } catch (error) {
      TreatError.showError(error);
      return {};
    }
  };

  async function onLoadExternalData({ data }) {
    try {
      const userPage = await UserPageService.getByHash({
        hash: data,
      });

      await masterDetailRef.current.executeSelectionItem(userPage);
      setTimeout(async () => {
        await masterDetailRef.current.refreshList(userPage.hash);
      }, 200);
    } catch (error) {
      TreatError.showError(error);
    }
  }

  const onLoadData = ({ data }) => {
    setErrors({});

    setHeader(data || {});
    setPageSettings(data.settings || defaultSettings);
    setFormData(data);
    setIsShowOpen(!!data.hash);

    switch (data.type) {
      case 'internal':
        setState({
          external_hidden: true,
          internal_hidden: false,
          userTableHidden: true,
          udoHidden: true,
        });
        break;
      case 'external':
        setState({
          external_hidden: false,
          internal_hidden: true,
          userTableHidden: true,
          udoHidden: true,
        });
        break;

      case 'userTable':
        setState({
          external_hidden: true,
          internal_hidden: true,
          userTableHidden: false,
          udoHidden: true,
        });
        break;

      case 'udo':
        setState({
          external_hidden: true,
          internal_hidden: true,
          userTableHidden: true,
          udoHidden: false,
        });
        break;
      default:
        setState({
          external_hidden: true,
          internal_hidden: true,
          userTableHidden: true,
          udoHidden: true,
        });
        break;
    }

    const userList = data.users ? JSON.parse(data.users) : [];
    usersPermissionsRef.current.setUsers(
      userList.map(user => ({ id: user.userId || user, status: 1 }))
    );
    usersPermissionsRef.current.setPermissionType(data.permissionType || 0);

    setMenus([
      {
        id: 'mnuLinkToProject',
        icon: 'FaFileImport',
        disabled: !data.id,
        text: t('mnuLinkToProject'),
        handler: () => {
          linkProject(data.hash);
        },
      },
    ]);
  };

  const onGetDefault = defaultData => {
    return {
      status: 1,
      type: 'custom',
      permissionType: 1,
      menu: {
        id: 7,
        name: tMenus('BusinessPartners'),
      },
      icon: 'MdContactPage',
      ...defaultData,
    };
  };

  const isValidUrl = url => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      url
    );
  };

  function onValidate({ data }) {
    const { type, externalUrl, internalUrl, openMode } = data;

    if (type === 'external') {
      if (!openMode) {
        Toast.info(
          t('message.RequiredField', '', { fieldName: t('openMode') })
        );
        return false;
      }
      if (isValidUrl(externalUrl)) {
        return true;
      }

      Toast.info(
        t('message.RequiredField', '', { fieldName: t('ExternalUrl') })
      );
      return false;
    }

    if (type === 'internal' && !internalUrl) {
      Toast.info(
        t('message.RequiredField', '', { fieldName: t('InternalUrl') })
      );
      return false;
    }

    return true;
  }

  function onSaveError({ error }) {
    TreatError.showError(error, setErrors);
  }

  const refreshHeader = () => {
    const data = formRef.current.getData();
    setHeader({
      ...data,
    });
  };

  const onGetDataToSave = () => {
    const data = formRef.current.getData();

    const { menu, ...rest } = data;

    const newUsers = usersPermissionsRef.current.getUsers();

    const usersToSave = newUsers
      .filter(user => user.status === 1)
      .map(user => user.id);

    const pageSettingsCache = getPageSettings();

    const dataToSave = {
      ...rest,
      menuId: menu ? menu.id : null,
      menu,
      settings: pageSettingsCache,
      users: usersToSave,
    };

    return dataToSave;
  };

  const isShowDelete = !!header.id;

  const handleInternalAndExternalUrl = ({ openMode }) => {
    if (openMode.toLowerCase() === 'external') {
      window.open(formData.externalUrl, '_blank');
      return;
    }

    showPage(`ExternalPage/${formData.hash}`);
  };

  const onShow = () => {
    switch (formData.type) {
      case 'internal':
        showPage(`${formData.internalUrl}?from=userPage&hash=${formData.hash}`);
        break;

      case 'external':
        handleInternalAndExternalUrl({
          openMode: formData.openMode,
        });
        break;

      default:
        showPage(`UserPage/${formData.hash}`);
        break;
    }
  };

  const onSave = async ({ data }) => {
    const response = await UserPageService.save({ settings, data });

    dispatch(
      updateSettingsAction({
        pageId: `UserPage/${response.hash}`,
        settings: {
          ...response._settings,
        },
      })
    );

    return response;
  };

  useEffect(() => {
    if (initialData) {
      const hash = initialData.split('/')[1];
      setTimeout(() => tabContentRef.current.setActiveTab('SettingsTab'), 100);
      onLoadExternalData({ data: hash });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  return (
    <PageContext.Provider
      value={{
        formRef,
        settings,
        isReadOnly,
        t,
        tMenus,
        refreshHeader,
        UserPageService,
        masterDetailRef,
        pageSettings,
        setPageSettings,
        state,
        setState,
        setIsSettingsReadOnly,
        isSettingsReadOnly,
        defaultSettings,
      }}
    >
      <MasterDetail
        ref={masterDetailRef}
        showLoading={showLoading}
        route="UserPages"
        keyField="id"
        settings={settings}
        isReadOnly={isReadOnly}
        menus={menus}
        sideWidth={350}
        isShowDelete={isShowDelete}
        onRenderListItem={item => (
          <ListItem data={item} t={t} tMenus={tMenus} />
        )}
        formRef={formRef}
        onValidate={onValidate}
        onLoadData={onLoadData}
        onLoadExternalData={onLoadExternalData}
        onSelectedItem={onSelectedItem}
        onGetDefault={onGetDefault}
        onGetDataToSave={onGetDataToSave}
        onSave={onSave}
        onSaveError={onSaveError}
        baseService={UserPageService}
        isFlexSpace={false}
        isShowSystemLogs
        systemLogsRef="UserPage"
        systemLogskey="hash"
        showPage={showPage}
        enableDesignMode={false}
        footerElements={() => {
          return [
            <Button hidden={!isShowOpen} onClick={onShow}>
              {t('Show')}
            </Button>,
            <FlexSpace />,
          ];
        }}
        {...props}
      >
        <StatusHeader status={header.status} />
        <HeaderContent
          height="90px"
          title={header.name}
          status={header.status}
          attributes={[header.hash]}
        />

        <Form ref={formRef} data={formData} errors={errors}>
          <TabContent
            ref={tabContentRef}
            formRef={formRef}
            settings={settings}
            t={t}
          >
            <GeneralTab
              title={t('General')}
              name="GenneralTab"
              icon="FaInfoCircle"
            />
            <SettingsTab
              title={t('Settings')}
              name="SettingsTab"
              icon="BiCodeBlock"
            />
            <TabUsersPermissions
              name="PermissionsTab"
              title={t('Permissions')}
              icon="MdSecurity"
              ref={usersPermissionsRef}
              formRef={formRef}
              settings={settings}
              isReadOnly={isReadOnly}
              t={t}
            />

            <RemarksTab
              title={t('Remarks')}
              name="RemarksTab"
              icon="FaCommentDots"
            />
          </TabContent>
        </Form>
      </MasterDetail>
    </PageContext.Provider>
  );
}

export default UserPages;
