/* eslint-disable no-useless-escape */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */

import { createSyncFunctionByString } from '~/easy-components/AsyncFunctionString';

function getStyleTag(text) {
  const startIdx = text.indexOf('<style>');

  if (startIdx >= 0) {
    const endIdx = text.indexOf('</style>');

    const styleTag = text.substring(startIdx, endIdx + 8);

    return styleTag;
  }

  return null;
}

function removeStyleTag(text) {
  const endIdx = text.indexOf('</style>');

  if (endIdx >= 0) {
    const textWithoutStyleTag = text.substring(endIdx + 8);

    return textWithoutStyleTag;
  }

  return text;
}

const replaceTemplateInlineFunction = ({ text, dataToReplace, auxScope }) => {
  const getTemplateDynamicFunctions = () => {
    const regex = /\{\#(.*?)\#\}/gs;
    const matches = [];
    let i = 1;

    const templateWithFunctionName = text.replace(regex, (match, group) => {
      const functionName = `function${i}`;
      matches.push({
        functionName,
        dynamicFunction: group,
      });
      i++;
      return `{#${functionName}#}`;
    });

    return {
      matches,
      templateWithFunctionName,
    };
  };

  const replaceResultMatches = (originalText, matches) => {
    let textoFinal = originalText;

    matches.forEach(match => {
      const regex = new RegExp(`\\{#${match.functionName}#\\}`, 'g');
      textoFinal = textoFinal.replace(regex, match.result);
    });

    return textoFinal;
  };

  const dynamicFunctions = getTemplateDynamicFunctions();

  for (let cont = 0; cont < dynamicFunctions.matches.length; cont++) {
    const match = dynamicFunctions.matches[cont];

    const dynamicFunction = createSyncFunctionByString({
      functionString: match.dynamicFunction,
    });

    const newValue = dynamicFunction({
      data: dataToReplace,
      ...auxScope,
    });

    match.result = newValue;
  }

  const newText = replaceResultMatches(
    dynamicFunctions.templateWithFunctionName,
    dynamicFunctions.matches
  );

  return newText;
};

export function replacePlaceholdersText({
  text = '',
  placeholderTemplate,
  data,
  isReturnObj = false,
  format,
  formatters,
  auxScope,
}) {
  let rawText = replaceTemplateInlineFunction({
    text,
    dataToReplace: data,
    auxScope,
  });

  const styleTag = getStyleTag(rawText);

  const textWithoutStyleTag = removeStyleTag(rawText);

  rawText = textWithoutStyleTag;

  // eslint-disable-next-line guard-for-in
  if (!Array.isArray(data)) {
    for (const prop in data) {
      const placeholder = placeholderTemplate.replace('?', prop);

      const re = new RegExp(placeholder, 'gi');

      let value = data[prop];

      if (format) {
        const newValue = format({
          data,
          value,
          propName: prop,
          ...auxScope,
        });

        if (newValue !== undefined) {
          value = newValue;
        }
      }

      if (formatters) {
        if (formatters[prop] !== null && formatters[prop] !== undefined) {
          const dynamicFunction = createSyncFunctionByString({
            functionString: formatters[prop],
          });
          const newValue = dynamicFunction({
            data,
            value,
            propName: prop,
            ...auxScope,
          });

          if (newValue !== undefined) {
            value = newValue;
          }
        }
      }

      rawText = rawText.replace(re, value || '');
    }
  }

  // Altera a string 'null' para um valor null
  const reNull = new RegExp(`'null'`, 'g');
  rawText = rawText.replace(reNull, null);

  if (isReturnObj) {
    return rawText === '' ? null : JSON.parse(rawText);
  }

  rawText = rawText.replace(/{[^}]+}/g, '');

  return `${styleTag || ''} ${rawText}`;
}
