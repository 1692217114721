/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import HtmlParser from 'react-html-parser';
import { FaEye as InfoIcon } from '@react-icons/all-files/fa/FaEye';

import StatusIcon, { STATUS } from '../../Status';

import {
  Container,
  Header,
  Footer,
  MessageText,
  ErrorDescription,
} from './styles';
import MentionService from '~/services/MentionService';
import TreatError from '~/easy-components/TreatError';

import useLocale from '~/hooks/useLocale';

function Message({
  code,
  objectCode,
  isOwner,
  keyValue,
  keyText,
  status,
  message,
  from,
  time,
  mentionsInsideMessage,
}) {
  const t = useLocale('Mentions');

  const [state, setState] = useState({
    code,
    from,
    time,
    status,
    isLoading: false,
    errorMessage: null,
  });

  const formatMessage = useCallback(() => {
    let messageFormatted = message;

    mentionsInsideMessage.forEach(mention => {
      const { UserName } = mention;

      const regex = new RegExp(`@${UserName}`, 'g');

      messageFormatted = messageFormatted.replace(
        regex,
        ` <span class="mention">${UserName}</span>`
      );
    });

    return HtmlParser(messageFormatted);
  }, [mentionsInsideMessage, message]);

  const sendMessage = useCallback(async () => {
    try {
      setState({
        ...state,
        from: t('message.Sending'),
        time: '',
        status: STATUS.SENDING,
        isLoading: true,
      });

      const response = await MentionService.sendMessage({
        objectCode,
        keyValue,
        keyText,
        message,
        mentionsInsideMessage,
      });

      setState(oldState => ({
        ...oldState,
        code: response.Code,
        time: response.Time,
        from: response.UserName,
        status: STATUS.NOT_READ,
        isLoading: false,
      }));
    } catch (error) {
      const errorMessage = TreatError.getDescription(error);

      setState({
        ...state,
        status: STATUS.ERROR,
        time: '',
        errorMessage,
        isLoading: false,
      });
    }
  }, [state, t, objectCode, keyValue, keyText, message, mentionsInsideMessage]);

  const formatTime = useCallback(() => {
    if (!state.time) return '';

    const timeString = state.time.toString().padStart(4, '0');
    return `${timeString.substring(0, 2)}:${timeString.substring(2)}`;
  }, [state.time]);

  useEffect(() => {
    if (state.status === STATUS.TO_SEND) {
      sendMessage();
    }
  }, [sendMessage, state]);

  return (
    <Container isOwner={isOwner}>
      <Header>
        <h1>{state.from}</h1>
        {isOwner && <InfoIcon className="eye" size={18} color="#6fabc473" />}
      </Header>

      <MessageText>{formatMessage(message)}</MessageText>
      <Footer>
        {state.status !== STATUS.SENDING && state.status !== STATUS.ERROR && (
          <h1>{formatTime(state.time)}</h1>
        )}
        <StatusIcon
          status={state.status}
          destCount={mentionsInsideMessage.length}
        />
      </Footer>
      <ErrorDescription isVisible={!!state.errorMessage}>
        {state.errorMessage}
      </ErrorDescription>
    </Container>
  );
}

export default Message;
