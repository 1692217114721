import email from './validEmail';
import cpf from './validCPF';
import cnpj from './validCNPJ';
import b64ToBlobFunction from './b64toBlob';
import validateJsonFunction from './validateJson';
import downloadLinkFunction from './downloadLink';
import JsonParseFunction from './JsonParse';
import isValidJsonFunction from './isValidJson';

export const isValidEmail = email;
export const validCNPJ = cnpj;
export const validCPF = cpf;
export const b64ToBlob = b64ToBlobFunction;
export const validateJson = validateJsonFunction;
export const downloadLink = downloadLinkFunction;
export const JsonParse = JsonParseFunction;
export const isValidJson = isValidJsonFunction;

export default {};
