/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';

import ColumnLayout from '~/easy-components/ColumnLayout';

import WizardPage from '~/components/Pages/Wizard';

import useLocale from '~/hooks/useLocale';

import {
  Column,
  Container,
  Grid,
  Row,
  Spacer,
  TableContainer,
  Text,
} from './styles';
import { JsonTable, Toast } from '~/easy-components';
import TableMountService from '~/applications/CRM/services/TableMountService';
import SignaturesService from '~/applications/CRM/services/SignaturesService';
import Input from '~/easy-components/Form/Input';
import Panel from '~/easy-components/Panel';
import isValidEmail from '~/easy-components/Helpers/validEmail';
import Linker from '~/easy-components/Form/Linker';

function WizardSignature({
  isReadOnly,
  showLoading,
  settings,
  reportSettings,
  onCancel,
  documentUrl,
  reportData,
}) {
  const service = SignaturesService;

  const {
    destinationsTableSettings,
    copiesTableSettings,
    signers,
    copies,
  } = reportSettings;

  const defaultDestinationData = [];
  const defaultCopiesData = [];

  const document = documentUrl.split('/');

  const documentData = {
    description: reportSettings.description || reportData.description,
    subject: reportSettings.subject || '',
    name: '',
    token: '',
  };

  if (document.length > 0) {
    documentData.token = document[4];
    documentData.name = document[5];
  }

  if (signers != null && signers.length > 0) {
    signers.forEach(signer => {
      const data = {
        tag: signer.tag || '',
        name: signer.name || '',
        email: signer.email || '',
      };

      defaultDestinationData.push(data);
    });
  }

  if (copies != null && copies.length > 0) {
    copies.forEach(copy => {
      const data = {
        name: copy.name || '',
        email: copy.email || '',
      };

      defaultCopiesData.push(data);
    });
  }
  const serviceTypeList = [
    { value: 'd4Sign', label: 'D4SIGN' },
    { value: 'docuSign', label: 'DOCUSIGN' },
  ];

  const [reportDataDestinations, setReportDataDestinations] = useState({
    data: defaultDestinationData,
    columns: [],
    reportSettings: destinationsTableSettings,
  });

  const [reportDataCopies, setReportDataCopies] = useState({
    data: defaultCopiesData,
    columns: [],
    reportSettings: copiesTableSettings,
  });

  const t = useLocale('ReportQueries');
  const t_Global = useLocale('_Global');

  const wizardPageRef = useRef(null);
  const jsonTableDestinations = useRef();
  const jsonTableCopies = useRef();

  useEffect(() => {
    const getReportDataDestinations = async () => {
      const result = await TableMountService.execute({
        data: [],
        settings: destinationsTableSettings,
      });
      setReportDataDestinations(result);
    };
    const getReportDataCopies = async () => {
      const result = await TableMountService.execute({
        data: [],
        settings: copiesTableSettings,
      });
      setReportDataCopies(result);
    };
    getReportDataDestinations();
    getReportDataCopies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let dataDestinations = [];
  let dataCopies = [];

  const validateData = async formRef => {
    if (!formRef.current.getFieldValue('serviceType')) {
      throw new Error('Necessário informar o Tipo de Serviço'); // TODO: Tradução pendente
    }
    if (!formRef.current.getFieldValue('description')) {
      throw new Error(t('message.DescriptionIsRequired'));
    }
    if (!formRef.current.getFieldValue('reportName')) {
      throw new Error(t('message.FileIsRequired'));
    }
    return true;
  };

  const validateGridData = async data => {
    if (!data || data.length === 0) {
      throw new Error(t('message.EnterAtLeastOneRecipient'));
    }

    data.forEach(item => {
      if (!item.name || !item.email) {
        // eslint-disable-next-line spaced-comment
        throw new Error(t('message.EmptyMandatoryFields'));
      }
      if (!isValidEmail(item.email)) {
        // eslint-disable-next-line spaced-comment
        throw new Error(t('message.InvalidEmail'));
      }
    });
    return true;
  };

  const validateGridCopiesData = async data => {
    if (data !== null && data.length > 0) {
      data.forEach(item => {
        // se o nome ou email estiver preenchido
        if (!item.name || !item.email) {
          // eslint-disable-next-line spaced-comment
          throw new Error(t('message.EmptyMandatoryFields'));
        }
        if (!item.name && item.email) {
          // eslint-disable-next-line spaced-comment
          throw new Error(t('message.EmptyMandatoryFields'));
        }
        if (item.email && !isValidEmail(item.email)) {
          // eslint-disable-next-line spaced-comment
          throw new Error(t('message.InvalidEmail'));
        }
      });
    }
    return true;
  };

  async function onFinish({ data }) {
    showLoading(true);
    const dataToServer = {
      signers: dataDestinations || [],
      copies: dataCopies || [],
      description: data.description,
      fileName: data.reportName,
      fileToken: documentData.token,
      emailSubject: data.subject || '',
      serviceType: data.serviceType || '',
    };

    const envelopeId = await service.send({ data: dataToServer });
    showLoading(false);

    if (envelopeId) {
      Toast.success('Documento enviado'); // TODO: Tradução pendente
      onCancel({ confirm: false });
    } else {
      Toast.error('Não foi possível enviar documento'); // TODO: Tradução pendente
      wizardPageRef.current.setStep(3);
    }
  }

  const pages = [
    {
      id: 'file',
      title: t('File'),
      icon: 'FaFileSignature',
      onChange: async ({ formRef }) => {
        await validateData(formRef);
      },
      content: ({ formRef }) => {
        const fieldProps = { formRef, labelWidth: 150 };
        return (
          <>
            <Panel width="70%" heigth="500px">
              <ColumnLayout>
                <Text dark fontSize="18px" fontWeight="bold">
                  {t('FillInfoToUpload')}
                </Text>
              </ColumnLayout>
              <ColumnLayout>
                <Panel width="95%">
                  <Linker
                    label="Tipo de Serviço"
                    name="serviceType"
                    valueField="serviceType"
                    required
                    {...fieldProps}
                    fixedData={serviceTypeList}
                  />
                  <Input
                    name="documentUrl"
                    label="Documento Url"
                    {...fieldProps}
                    hidden
                  />
                  <Input
                    name="reportName"
                    label={t('FileName')}
                    required
                    {...fieldProps}
                  />
                  <Input
                    name="description"
                    label={t_Global('ItemDescription')}
                    {...fieldProps}
                    required
                  />
                  <Input
                    name="subject"
                    label={t('EmailSubject')}
                    {...fieldProps}
                  />
                </Panel>
              </ColumnLayout>
            </Panel>
          </>
        );
      },
    },
    {
      id: 'destination',
      title: t('Recipients'),
      icon: 'MdEmail',
      onChange: async () => {
        dataDestinations = [];
        dataDestinations = jsonTableDestinations.current.getData();
        await validateGridData(dataDestinations);
      },
      content: () => {
        return (
          <TableContainer>
            <JsonTable
              ref={jsonTableDestinations}
              title={t('Recipients')}
              t={t}
              showToolbar={false}
              data={defaultDestinationData}
              columns={reportDataDestinations.columns}
              reportSettings={reportDataDestinations.reportSettings}
              onRefresh={() => {}}
              settings={settings}
              showLoading={showLoading}
              hideToolbar
              hasFilter={false}
            />
          </TableContainer>
        );
      },
    },
    {
      id: 'copies',
      title: t('Copies'),
      icon: 'MdAlternateEmail',
      onChange: async () => {
        dataCopies = [];
        dataCopies = jsonTableCopies.current.getData();
        await validateGridCopiesData(dataCopies);
      },
      content: () => {
        return (
          <TableContainer>
            <JsonTable
              ref={jsonTableCopies}
              title={t('Copies')}
              t={t}
              showToolbar={false}
              data={defaultCopiesData}
              columns={reportDataCopies.columns}
              reportSettings={reportDataCopies.reportSettings}
              onRefresh={() => {}}
              settings={settings}
              showLoading={showLoading}
              hideToolbar
              hasFilter={false}
            />
          </TableContainer>
        );
      },
    },
    {
      id: 'summary',
      title: t('Summary'),
      icon: 'MdReceipt',
      onChange: async () => {},
      content: ({ formRef }) => {
        const reportName =
          formRef && formRef.current
            ? formRef.current.getFieldValue('reportName')
            : null;
        const description =
          formRef && formRef.current
            ? formRef.current.getFieldValue('description')
            : null;
        const subject =
          formRef && formRef.current
            ? formRef.current.getFieldValue('subject')
            : null;
        const serviceType =
          formRef && formRef.current
            ? formRef.current.getFieldValue('serviceType')
            : null;
        return (
          <ColumnLayout>
            <Grid width="1000px">
              <Row>
                <Column>
                  <Text dark fontSize="18px" fontWeight="bold">
                    {t('ReviewBeforeSubmit')}
                  </Text>
                  <Spacer />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Text dark fontSize="14px" fontWeight="bold">
                    {t('DocumentData')}
                  </Text>
                  <Spacer />
                  {serviceType ? (
                    <Text fontSize="12px">
                      {`Tipo de Serviço: ${serviceType.toUpperCase()}`}
                    </Text>
                  ) : (
                    <div />
                  )}
                  {reportName ? (
                    <Text fontSize="12px">{`${t(
                      'FileName'
                    )}: ${reportName}`}</Text>
                  ) : (
                    <div />
                  )}
                  {description ? (
                    <Text fontSize="12px">{`${t_Global(
                      'ItemDescription'
                    )}: ${description}`}</Text>
                  ) : (
                    <div />
                  )}
                  {subject ? (
                    <Text fontSize="12px">{`${t(
                      'EmailSubject'
                    )}: ${subject}`}</Text>
                  ) : (
                    <div />
                  )}
                </Column>
              </Row>

              <Row>
                <Column>
                  <Text dark fontSize="14px" fontWeight="bold">
                    {t('Recipients')}
                  </Text>
                  <Spacer />
                  {dataDestinations != null && dataDestinations.length > 0 ? (
                    dataDestinations.map((data, index) => {
                      return (
                        <>
                          <Text fontSize="12px">{`${t_Global('Name')}: ${
                            data.name
                          }`}</Text>
                          <Text fontSize="12px">{`${t_Global('E_Mail')}: ${
                            data.email
                          }`}</Text>
                          {index + 1 < dataDestinations.length ? <br /> : null}
                        </>
                      );
                    })
                  ) : (
                    <div />
                  )}
                </Column>
              </Row>

              {dataCopies != null && dataCopies.length > 0 ? (
                <Row>
                  <Column>
                    <Text dark fontSize="14px" fontWeight="bold">
                      {t('Copies')}
                    </Text>
                    <Spacer />
                    {dataCopies.map(data => {
                      return (
                        <>
                          <Text fontSize="12px">{`${t_Global('Name')}: ${
                            data.name
                          }`}</Text>
                          <Text fontSize="12px">{`${t_Global('E_Mail')}: ${
                            data.email
                          }`}</Text>
                          <br />
                        </>
                      );
                    })}
                  </Column>
                </Row>
              ) : (
                <div />
              )}
            </Grid>
          </ColumnLayout>
        );
      },
    },
  ];

  return (
    <Container>
      <WizardPage
        ref={wizardPageRef}
        route="Signatures"
        title={t('SendDocToSign')}
        keyField="id"
        showLoading={showLoading}
        isReadOnly={isReadOnly}
        settings={settings}
        onFinish={onFinish}
        isShowCancel
        onCancel={onCancel}
        pages={pages}
        sideWidth={200}
        defaultData={{
          documentUrl,
          reportName: documentData.name,
          description: documentData.description,
          subject: documentData.subject,
          serviceType: 'D4SIGN',
        }}
      />
    </Container>
  );
}

export default WizardSignature;
