import { useTranslation } from 'react-i18next';
// import { Container } from './styles';

function hooks(route, translations = []) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  const translateFields = (textCode, auxRoute, replaces = {}) => {
    const textTranslated = translations.find(
      field => field.textCode === textCode
    );

    if (!route) {
      return `[${textCode}]`;
    }

    const routeUpper = auxRoute ? auxRoute.toUpperCase() : route.toUpperCase();

    /** @type {string} */
    let text = textTranslated
      ? textTranslated.text
      : t(`${routeUpper}.${textCode}`);

    const attributesToReplace = Object.keys(replaces);
    for (
      let replaceIndex = 0;
      replaceIndex < attributesToReplace.length;
      replaceIndex += 1
    ) {
      const replaceField = attributesToReplace[replaceIndex];
      const splitted = text.split(`{${replaceField}}`);
      text = splitted.join(replaces[replaceField]);
    }

    return text.includes(`${routeUpper}.`) ? `[${textCode}]` : text;
  };

  return translateFields;
}

export default hooks;
