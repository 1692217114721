const INITIAL_STATE = {
  modalCapture: {
    activeTab: 0,
  },
};

export default function configurations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_CONFIGURATIONS':
      return {
        ...state,
        ...action.payload,
      };

    case 'COMPANY_SELECTED':
    case 'LOGOFF_SUCCESS':
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
}
