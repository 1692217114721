/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-cycle */
/* eslint-disable react/button-has-type */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import App from '~/routes/app';
import ModalHandle from '~/easy-components/ModalHandle';
import Button from '~/easy-components/Button';
import FlexSpace from '~/easy-components/FlexSpace';
import FooterPage from '~/easy-components/FooterPage';
import useLocale from '~/hooks/useLocale';
import useHelpers from '~/hooks/useHelpers';
import { openExternalData } from '~/store/modules/pages/actions';
import { Container, Content } from './styles';
import UserPage from '../../../../applications/CRM/pages/UserPage';

// eslint-disable-next-line no-unused-vars
function UserPageModal(_, ref) {
  const t = useLocale('_Global');
  const dispatch = useDispatch();

  const { process } = useHelpers();

  const modalRef = useRef();

  const [properties, setProperties] = useState(null);

  useImperativeHandle(ref, () => {
    return {
      show: async ({ hash, data, onClose }) => {
        await process(async () => {
          const url = `UserPage/${hash}`;

          if (data) {
            dispatch(
              openExternalData({
                pageId: url,
                externalData: data,
              })
            );
          }

          setProperties({
            onClose,
            computedMatch: {
              url,
              params: {
                hash,
              },
            },
          });

          modalRef.current.handleOpen();
        });
      },
      close: () => {
        modalRef.current.handleClose();
      },
    };
  });

  return (
    <ModalHandle
      ref={modalRef}
      contentStyle={{ style: { width: '95%', height: '95%' } }}
    >
      {properties && (
        <Container>
          <Content>
            <App
              route="UserPage"
              hash={properties.computedMatch.params.hash}
              Component={UserPage}
              computedMatch={properties.computedMatch}
              location={{}}
              isListOpen={false}
              isModal
            />
          </Content>
          <FooterPage>
            <FlexSpace />
            <Button
              onClick={() => {
                modalRef.current.handleClose();
                if (properties.onClose) {
                  properties.onClose();
                }
              }}
            >
              {t('Close')}
            </Button>
          </FooterPage>
        </Container>
      )}
    </ModalHandle>
  );
}

export default forwardRef(UserPageModal);
