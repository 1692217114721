/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';
import { updateFilterRowValue } from 'ka-table/actionCreators';
import DebounceEvent from '~/easy-components/DebounceEvent';

function DateFilter({ column, dispatch, value }) {
  const inputRef = useRef();
  function onChange(event) {
    dispatch(updateFilterRowValue(column.key, event.target.value || null));
  }
  useEffect(() => {
    inputRef.current.value = value || '';
  }, [value]);

  return <input onChange={DebounceEvent(onChange, 1000)} ref={inputRef} />;
}

export default DateFilter;
