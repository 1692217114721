/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { BsPersonFill as PersonIcon } from '@react-icons/all-files/bs/BsPersonFill';

import Loading from '~/easy-components/Loading';
import AuxInfo from '~/easy-components/AuxInfo';
import { Container, Mention, Image } from './styles';

function Mentions({ mentions, isLoading, onSelected, selectedIdx, ...rest }) {
  const onMentionSelected = useCallback(
    (mention, idx) => {
      onSelected({ mention, idx });
    },
    [onSelected]
  );

  return (
    <>
      <Loading isLoading={isLoading} />
      <Container {...rest}>
        {mentions.map((mention, idx) => (
          <Mention
            key={mention.UserCode}
            isSelected={idx === selectedIdx}
            onClick={() => onMentionSelected(mention, idx)}
          >
            <Image>
              <PersonIcon size={42} color="#fff" />
            </Image>
            <section>
              <h1>{mention.UserName}</h1>
              <h2>{mention.Email}</h2>
              {mention.Department && <h3>{mention.Department}</h3>}
              <AuxInfo text={mention.auxInfo || mention.AuxInfo} />
            </section>
          </Mention>
        ))}
      </Container>
    </>
  );
}
export default Mentions;
