/* eslint-disable no-case-declarations */
const INITIAL_STATE = [];

export default function tasks(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'COMPANY_SELECTED':
    case 'LOGOFF_SUCCESS':
      return [...INITIAL_STATE];

    case 'TASK_ADD':
      const listNews = state.filter(
        task => task.id.toString() !== action.payload.id.toString()
      );

      listNews.push(action.payload);

      return [...listNews];

    case 'TASK_REMOVE':
      const list = state.filter(
        task => task.id.toString() !== action.payload.id.toString()
      );
      return [...list];

    case 'TASK_ADD_RESULT':
      const task = state.find(
        t => t.id.toString() === action.payload.id.toString()
      );
      task.result = action.payload.result;
      task.error = null;
      // task.__date = new Date();
      return [...state];

    case 'TASK_ADD_ERROR':
      const taskError = state.find(
        t => t.id.toString() === action.payload.id.toString()
      );
      taskError.error = action.payload.error;
      taskError.__date = new Date();
      return [...state];

    default:
      return state;
  }
}
