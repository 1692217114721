import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FaEllipsisV as MenuIcon } from 'react-icons/fa';
import LoadIcon from '~/easy-components/LoadIcon';

import { Header as Container } from '../styles';

// eslint-disable-next-line import/no-cycle
import { PageContext } from '../../index';
import SwipeButton from '../../SwipeButton';

const Header = ({ data, buttons: buttonsProp, rowProps }) => {
  const [isLoading, showLoading] = useState(false);
  const [buttons, setButtons] = useState([]);
  const {
    dispatchOnContextMenuEvent,
    transformHandlerInOnClick,
    contextMenuOptions,
    modalDetailButtonsRef,
  } = useContext(PageContext);

  const getButtonsJsx = useCallback(
    menus => {
      return transformHandlerInOnClick({
        menus,
        rowData: data,
        rowProps,
        extendedEvent: {},
      }).map(button => ({
        content: (
          <SwipeButton
            settings={{
              title: button.text,
              swipe: { icon: button.icon, direction: 'left' },
            }}
            onClick={e => {
              button.onClick({ event: e, ...rowProps });
            }}
          />
        ),
      }));
    },
    [data, rowProps, transformHandlerInOnClick]
  );

  useEffect(() => {
    showLoading(true);
    dispatchOnContextMenuEvent({ rowData: data })
      .then(menus => {
        if (menus && Array.isArray(menus)) {
          const newButtons = [...buttonsProp, ...getButtonsJsx(menus)];
          setButtons(newButtons);
        } else {
          const newButtons = [...buttonsProp, ...(contextMenuOptions || [])];
          setButtons(newButtons);
        }
      })
      .finally(() => {
        showLoading(false);
      });
  }, [
    buttonsProp,
    contextMenuOptions,
    data,
    dispatchOnContextMenuEvent,
    getButtonsJsx,
    transformHandlerInOnClick,
  ]);

  const HeaderIcon = () => {
    if (isLoading) {
      return <LoadIcon color="#fff" />;
    }

    if (buttons.length) {
      return (
        <MenuIcon
          color="#fff"
          size={18}
          onClick={() => {
            modalDetailButtonsRef.current.open({ buttons });
          }}
        />
      );
    }

    return null;
  };

  return (
    <Container>
      Detalhes
      <HeaderIcon />
    </Container>
  );
};
Header.propTypes = {
  modalDetailButtonsRef: PropTypes.shape({
    current: PropTypes.shape({ open: PropTypes.func }),
  }).isRequired,
  data: PropTypes.array,
  buttons: PropTypes.array,
  rowProps: PropTypes.shape({ rowKeyValue: PropTypes.string }).isRequired,
};

Header.defaultProps = {
  data: [],
  buttons: [],
};

export default Header;
