import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 60px;
    height: 60px;
    display: inline-block;
    position: relative;
  }
  .loader::after,
  .loader::before {
    content: '';
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #2caafc;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }
  .loader::after {
    animation-delay: 1s;
  }

  @keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
`;
