/* eslint-disable func-names */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import Modal from '~/easy-components/Modal';
import HeaderPage from '~/easy-components/HeaderPage';
import FooterPage from '~/easy-components/FooterPage';
import FlexSpace from '~/easy-components/FlexSpace';
import ConfirmDialog from '~/easy-components/ConfirmDialog';
import Toast from '~/easy-components/Toast';
import ModalFields from '../ModalFields';
import Button from '~/easy-components/Button';
import TreatError from '../TreatError';
import useLocale from '~/hooks/useLocale';
import TemplateList from './TemplateList';
import { Container, Content } from './styles';
import colors from '~/styles/colors';
import TemplatesService from '~/applications/CRM/services/TemplatesService';

function TemplatesModal(_props = {}, ref) {
  const t = useLocale('_Global');

  const [state, setState] = useState({
    title: '',
    isShow: false,
    formData: {},
    ignorePropsToSaveTemplate: [],
    showLoading: () => {},
    loadData: () => {},
    route: '',
    items: [],
    isOpenSave: false,
  });
  const modalFieldsRef = useRef();

  function onClose() {
    setState({
      ...state,
      isShow: false,
      isOpenSave: false,
    });
  }

  function removePropsToIgnore({ formData }) {
    state.ignorePropsToSaveTemplate.forEach(function(prop) {
      if (Object.prototype.hasOwnProperty.call(formData, prop)) {
        delete formData[prop];
      }
    });

    return formData;
  }

  function onNew() {
    modalFieldsRef.current.show({
      title: t('NewTemplate'),
      fields: [
        {
          name: 'name',
          label: t('Name'),
          labelWidth: 160,
          type: 'text',
        },
      ],
      onConfirm: data => {
        ConfirmDialog({
          title: t('NewTemplate'),
          message: t('message.SaveMessageQuestion'),
          buttons: [
            {
              label: t('Yes'),
              onClick: async () => {
                try {
                  state.showLoading(true);

                  const dataToSave = {
                    name: data.name,
                    data: removePropsToIgnore({ formData: state.formData }),
                    route: state.route,
                  };

                  await TemplatesService.save({ data: dataToSave });

                  Toast.success(t('message.SystemMessageSuccess'));

                  onClose();
                } catch (error) {
                  TreatError.showError(error);
                } finally {
                  state.showLoading(false);
                }
              },
            },
            {
              label: t('No'),
            },
          ],
        });
      },
    });
  }

  async function onSelectItem({ item }) {
    if (state.type === 'O') {
      state.showLoading(true);
      try {
        const data = await TemplatesService.get(item.id);

        state.loadData(data);

        onClose();
      } catch (error) {
        TreatError.showError(error);
      } finally {
        state.showLoading(false);
      }

      return;
    }

    ConfirmDialog({
      title: t('SaveTemplate'),
      message: t('message.SaveMessageQuestion'),
      buttons: [
        {
          label: t('Yes'),
          onClick: async () => {
            try {
              state.showLoading(true);

              const dataToSave = {
                id: item.id,
                name: item.name,
                data: removePropsToIgnore({ formData: state.formData }),
                route: state.route,
              };

              await TemplatesService.save({ data: dataToSave });

              onClose();

              Toast.success(t('message.SystemMessageSuccess'));
            } catch (error) {
              TreatError.showError(error);
            } finally {
              state.showLoading(false);
            }
          },
        },
        {
          label: t('No'),
        },
      ],
    });
  }

  async function listItems({ isSaveMode, route }) {
    try {
      const list = await TemplatesService.list({ isSaveMode, route });

      return list;
    } catch (error) {
      return TreatError.showError(error);
    }
  }

  useImperativeHandle(ref, () => {
    return {
      show: async ({
        title,
        type,
        formData,
        route,
        ignorePropsToSaveTemplate,
        showLoading,
        loadData,
      }) => {
        let isSaveMode = false;

        if (type === 'S') isSaveMode = true;

        const items = await listItems({ isSaveMode, route });

        if (type === 'O' && items.length === 0) {
          Toast.info(t('message.EmptyTemplateList'));
          return;
        }

        setState({
          ...state,
          title,
          ignorePropsToSaveTemplate,
          type,
          formData,
          loadData,
          showLoading,
          route,
          items,
          isOpenSave: items.length === 0,
          isShow: true,
        });
      },
    };
  });

  if (state.isOpenSave) onNew();

  return (
    <>
      <Modal isOpened={state.isShow}>
        <Container>
          <HeaderPage
            backgroundColor={colors.headerBg}
            color={colors.headerTitle}
            hideMainMenu
          >
            {state.title}
          </HeaderPage>
          <Content>
            <TemplateList
              type={state.type}
              items={state.items}
              t={t}
              showLoading={state.showLoading}
              onSelectItem={onSelectItem}
              deleteTemplate={async ({ id }) => {
                await TemplatesService.delete(id);
              }}
              onNew={onNew}
            />
          </Content>
          <FooterPage>
            <FlexSpace />
            <Button type="button" onClick={onClose}>
              {t('Close')}
            </Button>
            {state.type === 'S' && (
              <Button buttonType="Emphasized" onClick={onNew}>
                {t('New')}
              </Button>
            )}
          </FooterPage>
        </Container>
      </Modal>
      <ModalFields ref={modalFieldsRef} />
    </>
  );
}

export default forwardRef(TemplatesModal);
