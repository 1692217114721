/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-new-func */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, memo, useState } from 'react';
import { useField } from '@unform/core';
import { isMobile } from 'react-device-detect';
import CustomEvent from '~/easy-components/Form/components/CustomEvent';
import useUiDesigner from '~/hooks/useUiDesigner';
import useFieldBase from '~/hooks/useField';
import { sendEvent } from '../../HandlerEvent';
import { FieldContainer, Title, Input } from '../styles';
// eslint-disable-next-line import/no-cycle
import UserField from '../UserField';
import LinkIcon from '../../LinkIcon';

function DisplayElement({
  baseName,
  name,
  label,
  hidden,
  formRef,
  mainFormRef,
  onLinkClick,
  auxScope,
  showLink,
  settings,
  labelWidth,
  labelPosition = 'left',
  isUserField,
  isCustomTab,
  customTabName,
  ...rest
}) {
  const { selfField, viewMode, showContextMenu } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'field',
    baseName,
    name,
    title: label,
    isUserField,
    customTabName,
  });

  const fieldTitle = selfField.title || label;

  const inputRef = useRef(null);
  const [isShowLink, setIsShowLink] = useState(showLink);

  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  const { isHidden } = useFieldBase({
    hidden,
    selfField,
    formRef,
    inputRef,
    getLogData: () => {
      return {
        baseName,
        name: fieldName,
        value: inputRef.current.value,
      };
    },
  });

  const handlerOnLinkClick = async ({ value }) => {
    await sendEvent({
      settings,
      eventName: 'onlinkclick',
      data: value,
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
    });
  };

  async function onLink() {
    if (onLinkClick)
      onLinkClick({
        value: inputRef.current.value,
        selfField,
      });

    if (isUserField || isCustomTab)
      await handlerOnLinkClick({ value: inputRef.current.value });
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue: el => {
        return el.value === '' ? null : el.value;
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    inputRef.current.showLink = status => {
      setIsShowLink(status);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfField]);

  useEffect(() => {
    setIsShowLink(showLink);
  }, [showLink]);

  return (
    <>
      <FieldContainer
        hidden={isHidden}
        viewMode={!hidden || isUserField ? viewMode : 'normal'}
        onContextMenu={event => showContextMenu({ event })}
        readOnly
        style={
          isMobile
            ? {
                ...(selfField && selfField.style ? selfField.style : {}),
                ...(rest && rest.style ? rest.style : {}),
              }
            : labelPosition === 'top'
            ? { flexDirection: 'column' }
            : {}
        }
      >
        {fieldTitle && (
          <Title
            highlight={isUserField && viewMode === 'design'}
            labelWidth={
              (selfField && selfField.labelWidth) || labelWidth || 130
            }
            style={labelPosition === 'top' ? { marginBottom: '5px' } : {}}
          >
            <div className="title">
              {fieldTitle}
              <div className="title-icon">
                <CustomEvent
                  settings={settings}
                  selfField={selfField}
                  inputRef={inputRef}
                  formRef={formRef}
                  mainFormRef={mainFormRef}
                  auxScope={auxScope}
                />
                {isShowLink && (
                  <div>
                    <span>
                      <LinkIcon onClick={onLink} />
                    </span>
                  </div>
                )}
              </div>
            </div>

            {error && <div className="error">{error}</div>}
          </Title>
        )}

        <Input
          ref={inputRef}
          id={fieldName}
          defaultValue={defaultValue}
          {...selfField}
          {...rest}
          hidden={false}
          readOnly
        />

        {error && !hidden && <span style={{ color: '#f00' }}>{error}</span>}
      </FieldContainer>

      <UserField
        baseName={baseName}
        fieldName={fieldName}
        formRef={formRef}
        mainFormRef={mainFormRef}
        settings={settings}
        labelWidth={(selfField && selfField.labelWidth) || labelWidth || 130}
      />
    </>
  );
}

export default memo(DisplayElement);
