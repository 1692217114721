/* eslint-disable import/no-cycle */
import RequestService from './RequestService';
import CompanyService from './CompanyService';

class AuthenticationService {
  async login({
    email,
    password,
    locale,
    company,
    authType = 'aster',
    oauth2ClientId = null,
  }) {
    const response = await RequestService.request({
      url: 'Authenticate',
      method: 'POST',
      data: {
        email,
        password,
        locale,
        company,
        authType,
        oauth2ClientId,
      },
    });

    return response;
  }

  async outSign(authorizationKey) {
    const response = await RequestService.request({
      url: `OutSign/${authorizationKey}`,
      method: 'POST',
    });

    return response;
  }

  async getAuthorizationKey() {
    const companyToken = CompanyService.getCompanyToken();

    const response = await RequestService.request({
      url: `Authorization_Key`,
      method: 'GET',
      token: companyToken,
    });

    return response;
  }

  async createAuthorizationKey() {
    const companyToken = CompanyService.getCompanyToken();

    const response = await RequestService.request({
      url: `Authorization_Key`,
      method: 'POST',
      token: companyToken,
    });

    return response;
  }

  async logoff() {
    return true;
  }

  async forgotPassword(email) {
    const response = await RequestService.request({
      url: `PasswordRecovery`,
      method: 'POST',
      data: {
        email,
      },
    });

    return response;
  }

  async expiredPassword({ email, password }) {
    const response = await RequestService.request({
      url: `ExpiredPassword`,
      method: 'POST',
      data: {
        password,
        email,
      },
      token: CompanyService.getCompanyToken(),
    });

    return response;
  }

  async isPasswordExpired({ companyToken }) {
    const response = await RequestService.request({
      url: `PasswordExpireDate`,
      method: 'GET',
      token: companyToken,
    });

    if (response.expire_at !== null) {
      return response.expire_at <= new Date().toISOString();
    }
    return false;
  }

  async validatePasswordRecoveryToken(token) {
    const response = await RequestService.request({
      url: `PasswordRecovery/${token}`,
      method: 'GET',
    });

    return response;
  }

  async passwordRecoveryWasNotMe(token) {
    const response = await RequestService.request({
      url: `PasswordRecovery/${token}`,
      method: 'DELETE',
    });

    return response;
  }

  async resetPassword(token, password) {
    const response = await RequestService.request({
      url: `PasswordRecovery/${token}`,
      method: 'POST',
      data: {
        password,
      },
    });

    return response;
  }

  async inactivityValidatePassword({ token, password }) {
    const response = await await RequestService.request({
      url: `inactivity/validatePassword/${token}`,
      method: 'POST',
      data: { password },
    });

    return response;
  }
}

export default new AuthenticationService();
