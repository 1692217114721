/* eslint-disable react/prop-types */
import React, { useImperativeHandle, forwardRef } from 'react';

function Nothing({ children, onConfirm }, ref) {
  useImperativeHandle(ref, () => ({ onConfirm }));

  return <>{children}</>;
}

export default forwardRef(Nothing);
