/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';
import ImageViewer from '~/easy-components/ImageViewer';
import AuxInfo from '~/easy-components/AuxInfo';
import MenuButton from '~/easy-components/MenuButton';
import colors from '~/styles/colors';
import { Container, Content, Line, VStatus, Body, Menu, Span } from './styles';

function GenericListItem({
  title,
  subTitle,
  status,
  imageName,
  number,
  auxInfo,
  statusColors,
  isShowStatusBar = true,
  menus,
  auxScope,
  auxData,
  onClick = () => {},
  ...rest
}) {
  const statusInfo = status
    ? // eslint-disable-next-line eqeqeq
      statusColors.find(item => item.value == status)
    : {};

  const Image = () => {
    if (imageName) {
      return (
        <div
          style={{
            display: 'flex',
            width: '90px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ImageViewer disableViewer src={imageName} alt="" />
        </div>
      );
    }

    return null;
  };

  const Status = () => {
    if (status) {
      return (
        <div
          style={{
            color: statusInfo.color,
          }}
        >
          {statusInfo.label}
        </div>
      );
    }

    return null;
  };

  return (
    <Container onClick={onClick} {...rest}>
      <Content>
        {isShowStatusBar && <VStatus backgroundColor={statusInfo.color} />}
        <Image />
        <Body>
          <Line>
            <div>
              <h1>{title}</h1>
            </div>
          </Line>
          <Line>
            <Span title={subTitle}>{subTitle}</Span>
            <h2>{number}</h2>
          </Line>
          <Line>
            {auxInfo ? <AuxInfo isGenericListItem={true} text={auxInfo} /> : <span />}
            <Status />
          </Line>
        </Body>
        {menus && (
          <Menu>
            <MenuButton
              icon={FaEllipsisV}
              color={colors.primary}
              menus={menus}
              auxScope={auxScope}
              auxData={auxData}
            />
          </Menu>
        )}
      </Content>
    </Container>
  );
}

GenericListItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  imageName: PropTypes.string,
};

GenericListItem.defaultProps = {
  imageName: null,
};

export default GenericListItem;
