export const addTask = ({
  id,
  name,
  data,
  method,
  params,
  onClick,
  onError,
}) => {
  return {
    type: 'TASK_ADD',
    payload: {
      id,
      name,
      data,
      method,
      params,
      result: null,
      onClick,
      onError,
    },
  };
};

export const addTaskResult = (id, result) => {
  return {
    type: 'TASK_ADD_RESULT',
    payload: {
      id,
      result,
    },
  };
};

export const addTaskError = (id, error) => {
  return {
    type: 'TASK_ADD_ERROR',
    payload: {
      id,
      error,
    },
  };
};

export const removeTask = id => {
  return {
    type: 'TASK_REMOVE',
    payload: {
      id,
    },
  };
};
