const INITIAL_STATE = {
  isShow: false,
};

export default function log(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOG_SEND_DATA':
      return {
        ...state,
        ...action.payload,
      };

    case 'LOG_SHOW':
      return {
        ...state,
        isShow: action.payload.status,
      };

    case 'COMPANY_SELECTED':
    case 'LOGOFF_SUCCESS':
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
}
